import { Form, Input, Select } from "antd"

export default () => {
  return [
    {
      label: 'Орлогын төрөл',
      name: 'name',
      rules: [{required: true, message: 'Орлогын төрөл оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}