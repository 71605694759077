import React, { useEffect ,useState} from "react";
import { Header, Footer, Sidebar } from "./components";


const Layout = ({ children }) => {




 
  return (
    <div id="layout">
      <Header ></Header>
      <Sidebar></Sidebar>
      <main>{children}</main>
    
    </div>
  );
};

export default Layout;
