import { createBrowserRouter } from "react-router-dom";
import { Register, Login, Print } from 'modules/public';
import adminRoutes from './adminRoutes';
import { ProtectedRoute } from 'components';
import RootPage from "modules/core";
import {Caller} from "modules/admin"
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    errorElement: <div className="h-screen flex items-center justify-center text-xl font-bold italic">ERROR</div>,
    children: [...adminRoutes].map(foo => {
      return {
        ...foo,
        element: <ProtectedRoute routeItem={foo}>
          {foo.element}
        </ProtectedRoute>
      }
    })
  },
  {
    path: '/login',
    element: <Login></Login>
  },
  {
    path: '/register',
    element: <Register></Register>
  },
  {
    path: '/bookingresult/:guid',
    element: <Print></Print>
  },
  {
    path: '/public/caller',
    element: <Caller></Caller>
  },
])

export default router
