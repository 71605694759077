import React, { useState } from "react";

import { Button, List, Segmented, Table, Typography, Tag } from "antd";
import { MainTable } from "components";
function TechnicalCount({ data, className = "" }) {
  const [viewMode, setViewMode] = useState("table");
  const { Text } = Typography;

  const columns = [
    {
      title: "Нэр",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "MRI Тоо",
      dataIndex: "cntMRI",
      key: "cntMRI",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntMRI - b.cntMRI,
    },
    {
      title: "MRI CE",
      dataIndex: "cntMRICe",
      key: "cntMRICe",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCE - b.cntCE,
    },
    {
      title: "CT Тоо",
      dataIndex: "cntCT",
      key: "cntCT",
      sorter: (a, b) => a.cntCT - b.cntCT,
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
    },
    {
      title: "CT CE",
      dataIndex: "cntCTCe",
      key: "cntCTCe",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCE - b.cntCE,
    },

    {
      title: "PET",
      dataIndex: "cntPET",
      key: "cntPET",
      sorter: (a, b) => a.cntCT - b.cntCT,
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
    },
    {
      title: "Рентген тоо",
      dataIndex: "cntXray",
      key: "cntXray",
      sorter: (a, b) => a.cntCT - b.cntCT,
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
    },
  ];
  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex gap-5">
        <div className="border rounded-lg overflow-hidden flex-1">
          {/* <div className="py-2 px-2 border-b">Дундаж онш бичилт</div> */}
          <MainTable
            dataTable={data}
            columns={columns}
            title={"Техникчийн үйлчлэглүүлэгчийн тоо"}
            summary={(pageData) => {
              let totalMRI = 0;
              let totalCT = 0;
              let totalPET = 0;
              let totalXray = 0;
              let totalMRICE = 0;
              let totalCTCE = 0;
              if (data)
                data.forEach(
                  ({ cntMRI, cntCT, cntMRICe, cntCTCe, cntXray, cntPET }) => {
                    totalMRI += cntMRI;
                    totalCT += cntCT;
                    totalPET += cntPET;
                    totalXray += cntXray;
                    totalMRICE += cntMRICe;
                    totalCTCE += cntCTCe;
                  }
                );

              return (
                <>
                  <Table.Summary.Row rowClassName={"table-row-dark"}>
                    <Table.Summary.Cell>
                      {" "}
                      <Tag color="success">Total</Tag>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalMRI}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalMRICE}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalCT}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalCTCE}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalPET}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalXray}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  {/* <Table.Summary.Row>
                    <Table.Summary.Cell>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2}>
                      <Text type="danger">{totalMRI - totalCT}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TechnicalCount;
