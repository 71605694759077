import { Bar } from "@ant-design/charts";
import React from "react";
import { MainTable } from "components";
function ExpenseIncomeLog({ data, className = "" }) {
  // const config = {
  //   data: data ? data : [],
  //   isGroup: true,
  //   xField: "amount",
  //   yField: "date",
  //   seriesField: "type",
  //   label: {
  //     content: (originData) => {
  //       const val = parseFloat(originData.value);
  //       return Intl.NumberFormat().format(originData.amount);
  //     },

  //     position: "middle",

  //     layout: [
  //       {
  //         type: "interval-adjust-position",
  //       },
  //       {
  //         type: "interval-hide-overlap",
  //       },
  //       {
  //         type: "adjust-color",
  //       },
  //     ],
  //   },
  // };
  return (
    <div className={`bg-white rounded-lg p-3 ${className}`}>
      <div className="font-semibold mb-4">Орлого, Зарлагын харьцаа</div>
      <div className="py-2 px-2 border-b">Орлого</div>
      <MainTable
        dataTable={data}
        columns={[
          {
            title: "Өдөр",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "MRI дүн",
            dataIndex: "MRIAmount",
            key: "MRIAmount",
            render: (text, row) => (
              <div>{Intl.NumberFormat().format(text) + " ₮"} </div>
            ),
            sorter: (a, b) => a.MRIAmount - b.MRIAmount,
          },
          {
            title: "CT дүн",
            dataIndex: "CTAmount",
            key: "CTAmount",
            render: (text, row) => (
              <div>{Intl.NumberFormat().format(text) + " ₮"} </div>
            ),
            sorter: (a, b) => a.MRIAmount - b.MRIAmount,
          },
          {
            title: "Нийт дүн",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text, row) => (
              <div>{Intl.NumberFormat().format(text) + " ₮"} </div>
            ),
            sorter: (a, b) => a.totalAmount - b.totalAmount,
          },
          {
            title: "Зарлага",
            dataIndex: "expense",
            key: "expense",
            render: (text, row) => (
              <div>{Intl.NumberFormat().format(text) + " ₮"} </div>
            ),
            sorter: (a, b) => a.expense - b.expense,
          },
        ]}
        title=""
      />
      {/* <List
        dataSource={data}
        header={false}
        footer={false}
        size="small"
        renderItem={(item) => (
          <List.Item className="text-xs">
            <div className="w-full flex justify-between gap-5">
              <span>{item.date}</span>
              <span>{Intl.NumberFormat().format(item.MRIAmount)} ₮</span>
              <span>{Intl.NumberFormat().format(item.CTAmount)} ₮</span>
              <span>{Intl.NumberFormat().format(item.totalAmount)} ₮</span>
              <span>{Intl.NumberFormat().format(item.expense)} ₮</span>
            </div>
          </List.Item>
        )}
      /> */}
      {/* <Bar {...config} height={250} /> */}
    </div>
  );
}

export default ExpenseIncomeLog;
