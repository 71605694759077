import { Form, Input, Select } from "antd";

export default ({ serviceTypes, hospitals }) => {
  return [
    {
      label: "Аппаратны нэр",
      name: "deviceName",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Эмнэлэг",
      name: "hospitalId",
      type: "select",

      rules: [{ required: true, message: "Төхөөрөмж сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: hospitals,
      },
    },
    {
      label: "Төхөөрөмж",
      name: "serviceTypeId",
      type: "select",
      rules: [{ required: true, message: "Төхөөрөмж сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: serviceTypes,
      },
    },

    {
      label: "Дэлгэрэнгүй мэдээлэл",
      name: "note",
      type: "textarea",
      // rules: [{required: true, message: 'Төрсөн огноо сонгоно уу!'}],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
