import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Button,
  Modal,
  Form as AntForm,
  Drawer,
  DatePicker,
  Select,
  TimePicker,
  message,
  Checkbox,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, MainTable, CompanySelect } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import columns, { columnNames } from "./columns";
import moment from "moment";
import ls from "utils/ls";
import { AuthContext } from "contexts";

function PreBooking() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { action } = authContext
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const [selectedHostpital, setSelectedHospital] = useState(
    ls.get("selectedHospital") ?? hospitalId
  );
  const role = authContext.state.userInfo.profile.role;
  const userId = authContext.state.userInfo.profile.id;
  const [hiddenColumns, setSetHiddenColumns] = useState(
    ls.get("preBookingHiddenColumns") ?? []
  );
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [serviceType, setServiceType] = useState(ls.get("servicetypeid") ?? -1);
  const [startTime, setStartTime] = useState(
    moment(serviceType == 1 ? "00:00" : "07:30", "HH:mm")
  );
  const [endTime, setEndTime] = useState(moment("23:59", "HH:mm"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [status, setStatus] = useState(-1);
  const [openRecep, setOpenRecep] = useState(false);
  const [form] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageHolder] = message.useMessage();

  useEffect(() => {
    action.handleSidebarKey("prebooking");
    fetchData();
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    status,
    serviceType,
    selectedHostpital,
  ]);
  useEffect(() => {
    instance({
      method: "get",
      url: `/servicetype?active=1`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          if (item.id != 3)
            tmp.push({ label: item.name, value: item.id, text: item.name });
        });
        let tmp2 = [];
        if (res.data.length != 1) {
          tmp2.push({ label: "Бүгд", value: -1 });
          setServiceType(ls.get("servicetypeid") ?? -1);
        } else setServiceType(res.data[0].id);

        res.data.map((item) => {
          if (item.id != 3)
            tmp2.push({ label: item.name, value: item.id, text: item.name });
        });
        setServiceTypes(tmp);
        setServiceTypeOptions(tmp2);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    instance({
      method: "get",
      url: `/service?hospital=${selectedHostpital}`,
    })
      .then((res) => {
        setServices(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => {});
  }, [selectedHostpital]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/prebooking?startdate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}T${moment(startTime).format("HH:mm:ss")}&enddate=${moment(
        endDate
      ).format("YYYY-MM-DD")}T${moment(endTime).format(
        "HH:mm:ss"
      )}&servicetype=${serviceType}&status=${status}&hospital=${selectedHostpital}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    // if (role == "Ресейпшн")
  };

  const handleReceived = (values) => {
    if (values.status != 4) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Шинжилгээний хариу гараагүй байна.",
      });
      return;
    }
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Энэ үйлдлийг хийхдээ итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id
          instance({
            method: "post",
            url: `/prebooking/receive/${values.id}`,
            data: {
              receiver: values.receiver,
            },
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleSubmit = (values) => {
   
    setLoading(true);

    if (!editData.isAdd) {
      instance({
        method: "put",
        url: "/prebooking",
        data: {
          ...values,
          bookingData: {
            ...values.bookingData,
            id: editData.bookingData.id,
            hospitalId: selectedHostpital,
            // ebarimtStatus: values.ebarimtStatus ? 1 : 0,
          },
          // id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",

        url: "/prebooking",
        data: {
          ...values,
          bookingData: {
            ...values.bookingData,
            ebarimtStatus: values.ebarimtStatus ? 1 : 0,
            hospitalId: selectedHostpital,
          },
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleSaveCdCall = (values) => {
    instance({
      method: "put",
      url: `/reception/cdcall`,
      data: {
        id: editData.id,
        receptionNote: values.receptionNote,
        cdGivenUserId: values.isCd ? userId : null,
        callUserId: values.isCall ? userId : null,
        answerPhone: values.answerPhone,
        amount: values.amount,
        addition: values.addition,
        descr: values.descr,
      },
    })
      .then((res) => {
        setOpenRecep(false);
        form.resetFields();
        fetchData();
      })
      .catch((err) => {
        if (err.response.status == 403) {
          modal.warning({
            title: "Анхаарна уу !",
            content: "Хэрэглэгчийн эрх хүрэхгүй байна",
          });
        } else {
          modal.error({
            title: "Анхаарна уу !",
            content: "Хадгалж чадсангүй",
          });
        }
      });

    //  reception / cdcall;
  };
  const handleDeleteService = (id, remove) => {
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Энэхүү мөр бичлэгийг устгах гэж байна",
      okText: "Устгах",
      cancelText: "Буцах",
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: "delete",
            url: `/bookingservice/${id}`,
          })
            .then((res) => {
              resolve();
              remove();
            })
            .catch((err) => {
              reject();
            });
          // .then(() => {
          //   // fetchData();
          // });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleCancel = (values) => {
    if (values.status > 1) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Захиалгыг цуцлах боломжгүй байна.",
      });
      return;
    }
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Захиалгыг цуцлахдаа итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id

          instance({
            method: "put",
            url: `/prebooking/cancel/${values.id}`,
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleRecCloseModal = (values) => {
    setOpenRecep(false);
  };

  const handleAdd = () => {
    var coeff = 1000 * 60 * 10;
    var date = new Date(); //or use any other date
    var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);

    let data = {
      isAdd: true,
      bookingData: {
        bookingDateTime: rounded,
        bookingDate: new Date(),
      },
    };
    if (serviceType != -1) data.bookingData.servicetypeid = serviceType;
    setEditData(data);
    setOpen(true);
  };

  const handleEdit = (row) => {
    if (row.status > 2) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Шинжилгээнд орсон захиалга өөрчлөх боломжгүй.",
      });
      return;
    }
    if (row.id != null) {
      setLoading(true);
      instance({
        method: "get",
        url: `/prebooking/${row.id}`,
      })
        .then((res) => {
          // console.log(res.data);
          setEditData({
            isAdd: false,
            customerData: res.data.customer,
            bookingData: res.data,
          });
        })
        .catch((err) => {})
        .then(() => setLoading(false));

      setOpen(true);
    } else {
      // if (serviceType != -1) row.servicetypeid = serviceType;
      let data = {
        isAdd: true,
        bookingData: {
          bookingDateTime: row.bookingDateTime,
          bookingDate: row.bookingDateTime,
          servicetypeid: serviceType,
        },
      };

      setEditData(data);
      setOpen(true);
    }
  };
  const handleConfirm = (values) => {
    if (values.status > 1) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Шинжилгээнд орсон хүн байна.",
      });
      return;
    }
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Баталгаажуулахдаа итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: "put",
            url: `/prebooking/confirm/${values.id}`,
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleRefresh = () => {
    fetchData();
  };
  const onRowClick = (row) => {
    if (row.status == 1) {
      navigate("/booking?bookingId=" + row.id, { replace: true });
      ls.set("currentMenuKey", "booking");
    } else {
      modal.warning({
        title: "Анхаарна уу !",
        content: "Шинжилгээ рүү шилжих боломжгүй байна",
      });
    }
  };
  const handleColumnChange = (value) => {
    setSetHiddenColumns(value);
    ls.set("preBookingHiddenColumns", value);
  };
  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="flex gap-5 mb-3">
        <div>
          Эмнэлэг:
          <CompanySelect
            className="mb-0 w-[150px]"
            value={selectedHostpital}
            onChange={(e) => {
              ls.set("selectedHospital", e);
              setSelectedHospital(e);
            }}
          />
        </div>
        <div>
          Огноо:
          <DatePicker value={startDate} onChange={(e) => setStartDate(e)} />
          <TimePicker
            minuteStep={10}
            className="w-[100px]"
            // className="mb-0 w-[100px]"
            format="HH:mm"
            value={startTime}
            onChange={(e) => {
              setStartTime(e);
            }}
            changeOnBlur
          />
          {"  -  "}
          <DatePicker value={endDate} onChange={(e) => setEndDate(e)} />
          <TimePicker
            minuteStep={10}
            // className="mb-0 w-[100px]"
            className=" w-[100px]"
            format="HH:mm"
            value={endTime}
            onChange={(e) => {
              setEndTime(e);
            }}
            changeOnBlur
          />
        </div>
        <div>
          Төхөөрөмж:
          <Select
            className="mb-0 w-[100px]"
            value={serviceType}
            options={serviceTypeOptions}
            onChange={(e) => {
              ls.set("servicetypeid", e);
              setServiceType(e);
            }}
          />
        </div>

        <Select
          mode="multiple"
          size={"middle"}
          placeholder="Багана хасах"
          value={hiddenColumns}
          onChange={handleColumnChange}
          style={{ width: "10%" }}
          options={columnNames()}
          maxTagCount={"responsive"}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span>Хассан баганууд</span>
            </Tooltip>
          )}
        />
      </div>

      <MainTable
        dataTable={data}
        columns={columns({
          currentPage,
          serviceTypes,
          handleCancel,
          handleEdit,
          handleConfirm,
          handleReceived,
          hiddenColumns,
        }).filter((col) => col.visible != false)}
        setCurrentPage={setCurrentPage}
        loading={loading}
        scroll={{ y: "calc(100vh - 300px)" }}
        // pagination={true}
        rowKey={false}
        title={`Цаг захиалга/${moment(startDate).format("YYYY-MM-DD")}/`}
        handleAdd={handleAdd}
        handleRefresh={handleRefresh}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              onRowClick(record);
              /// // setVisible(true);
            },
          };
        }}
      />
      <Modal
        title={"Цаг захиалга"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({
            customers,
            services,
            handleConfirm,
            removeFunc: (id) => handleDeleteService(id),
            form,
            serviceTypes,
          })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>

      <Modal
        title={"Шинжилгээ"}
        open={openRecep}
        destroyOnClose
        width={500}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleRecCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={[
            {
              label: "CD авсан",
              name: "isCd",
              type: "checkbox",

              className: "col-span-6 md:col-span-6 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Утсаар ярьсан",
              name: "isCall",
              type: "checkbox",
              className: "col-span-6 md:col-span-6 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Нэмэлт хариу",
              name: "addition",
              className: "col-span-6 md:col-span-3 mb-0",
              type: "checkbox",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Утас",
              name: "answerPhone",
              type: "input",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },

            {
              label: "Дүн",
              name: "amount",
              type: "number",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Тэмдэглэл",
              name: "receptionNote",
              type: "textarea",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Тайлбар",
              name: "descr",
              type: "textarea",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
          ]}
          layout="vertical"
          onFinish={handleSaveCdCall}
          editData={editData}
          className="gap-4"
        />
      </Modal>
      {contextHolder}
      {messageHolder}
    </div>
  );
}

export default PreBooking;
