import {
  Dashboard,
  Employee,
  Customer,
  Report,
  Services,
  Organization,
  Booking,
  ServiceType,
  IncomeType,
  ExpenseType,
  Budget,
  Expense,
  FullCalendarPage,
  Device,
  Stats,
  CeItem,
  PreBooking,
  PETBooking,
  Inpatient,
  RoomBed,
  Room,
  Ward,
  Document,
  SurgeryPlan,
  InpatientDashboard,
  SurgeryType,
  ShiftSchedule,
  Caller,
  CallerReport,
} from "modules/admin";

export default [
  {
    name: "Хянах самбар",
    path: "/",
    element: <Dashboard />,
    role: ["Админ", "CEO", "Гишүүн"],
  },
  {
    name: "Дуудлага",
    path: "/callreport",
    element: <CallerReport />,
    role: ["Админ", "CEO"],
  },
  {
    name: "Хэвтэн самбар",
    path: "/inpatient-dashboard",
    element: <InpatientDashboard />,
    role: ["Админ", "CEO"],
  },
  {
    name: "Цаг захиалга",
    path: "/prebooking",
    element: <PreBooking />,
    role: [
      "Ресейпшн",
      "Эмч",
      "Техникч",
      "Нягтлан",
      "Админ",
      "CEO",
      "Эрхлэгч",
      "Менежер",
      "Physician",
      "Нягтлан",
    ],
  },

  {
    name: "Шинжилгээ",
    path: "/booking",
    element: <Booking />,
    role: [
      "Ресейпшн",
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Менежер",
      "Нягтлан",
    ],
  },
  {
    name: "PET Шинжилгээ",
    path: "/pet-booking",
    element: <PETBooking />,
    role: [
      "Ресейпшн",
      "Эмч",
      "Нягтлан",
      "Техникч",
      "Админ",
      "CEO",
      "Эрхлэгч",
      "Менежер",
      "Physician",
    ],
  },
  {
    name: "Үзүүлэлт",
    path: "/stats",
    element: <Stats />,
    role: [
      "Ресейпшн",
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Менежер",
      "Physician",
      "Нягтлан",
    ],
  },
  {
    name: "Мэс засал",
    path: "/surgery",
    element: <SurgeryPlan />,
    role: ["Админ", "CEO", "Менежер", "Хэвтэн", "Ресейпшн", "Нягтлан"],
  },
  {
    name: "Мэс заслын нэр",
    path: "/surgery-type",
    element: <SurgeryType />,
    role: ["Админ", "CEO", "Менежер", "Хэвтэн", "Нягтлан"],
  },
  {
    name: "Ажлын хуваарь",
    path: "/shift-schedule",
    element: <ShiftSchedule />,
    role: ["CEO", "Админ", "Менежер", "Нягтлан", "Нягтлан"],
  },
  {
    name: "Тайлан",
    path: "/report",
    element: <Report />,
    role: ["CEO", "Админ", "Менежер", "Нягтлан", "Эмч", "Эрхлэгч"],
  },
  {
    name: "Орлого зарлага",
    path: "/incomeandexpense",
    element: <Budget />,
    role: ["Админ", "CEO", "Менежер"],
  },
  // Хүний нөөц
  {
    name: "Ажилчид",
    path: "/employee",
    role: ["Админ", "CEO", "Менежер"],
    element: <Employee />,
  },
  {
    name: "Харилцагч",
    path: "/customer",
    role: ["Ресейпшн", "Админ", "CEO", "Менежер"],
    element: <Customer />,
  },
  {
    name: "Хуваарь",
    path: "/calendar",
    role: ["Ресейпшн", "Админ", "CEO", "Эмч", "Менежер", "Нягтлан"],
    element: <FullCalendarPage />,
  },
  //Хэвтэн эмчлүүлэх
  {
    name: "Хэвтэн эмчлүүлэх",
    path: "/inpatient",
    role: [
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Менежер",
      "Нягтлан",
      "Хэвтэн",
    ],
    element: <Inpatient />,
  },
  {
    name: "Тасаг",
    path: "/ward",
    role: [
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Нягтлан",
      "Хэвтэн",
    ],
    element: <Ward />,
  },
  {
    name: "Өрөө",
    path: "/room",
    role: [
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Нягтлан",
      "Хэвтэн",
    ],
    element: <Room />,
  },
  {
    name: "ор ",
    path: "/room-bed",
    role: [
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Нягтлан",
      "Хэвтэн",
    ],
    element: <RoomBed />,
  },
  {
    name: "бичиг баримт ",
    path: "/document",
    role: [
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Нягтлан",
      "Хэвтэн",
    ],
    element: <Document />,
  },
  {
    name: "Хуваарь",
    path: "/calendar",
    role: ["Ресейпшн", "Админ", "CEO", "Эмч", "Менежер", "Нягтлан"],
    element: <FullCalendarPage />,
  },
  // Тохиргоо
  {
    name: "Үйлчилгээ",
    path: "/services",
    role: ["Админ", "CEO", "Менежер"],
    element: <Services />,
  },
  {
    name: "Аппарат",
    path: "/devices",
    role: ["Админ", "CEO", "Менежер"],
    element: <Device />,
  },
  {
    name: "Бодис",
    path: "/ceitem",
    role: ["Админ", "CEO", "Менежер"],
    element: <CeItem />,
  },
  {
    path: "/caller",
    element: <Caller></Caller>,
    role: ["Админ", "CEO", "Менежер"],
  },
  {
    name: "Үйлчилгээний төрөл",
    path: "/servicetype",
    role: ["Админ", "CEO", "Менежер"],
    element: <ServiceType />,
  },
  {
    name: "Компаний мэдээлэл",
    path: "/organization",
    role: ["Админ", "CEO", "Менежер"],
    element: <Organization />,
  },
  {
    name: "Орлогын төрөл",
    path: "/incometype",
    role: ["Админ", "CEO", "Менежер"],
    element: <IncomeType />,
  },
  {
    name: "Зарлагын төрөл",
    path: "/expensetype",
    role: ["Админ", "CEO", "Менежер"],
    element: <ExpenseType />,
  },
  {
    name: "Дэд Зарлага",
    path: "/expense",
    role: ["Админ", "CEO", "Менежер"],
    element: <Expense />,
  },
];
