import { Button, Form, Input, Select } from "antd";

export default ({ types, doctors, handleDelete }) => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [
        { required: true, message: "Үйлчлүүлэгчийн мэдээлэлэл оруулна уу!" },
      ],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нас",
      name: "age",
      type: "number",

      className: "col-span-6 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хүйс",
      name: "gender",
      type: "select",
      rules: [
        { required: true, message: "Үйлчлүүлэгчийн мэдээлэлэл оруулна уу!" },
      ],
      className: "col-span-6 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        optionFilterProp: "label",
        options: [
          {
            label: "Эр",
            value: "Эр",
          },
          {
            label: "Эм",
            value: "Эм",
          },
        ],
      },
    },
    {
      label: "Тасаг",
      name: "typeId",
      type: "select",

      rules: [{ required: true, message: "Төрөл сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: types,
      },
    },
    {
      label: "Огноо",
      name: "surgeryDate",
      type: "date",
      disabled: true,
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-6 md:col-span-2 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Эхлэх цаг",
      name: "startTime",
      type: "time",
      minuteStep: 10,
      className: "col-span-3 md:col-span-2 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Дуусах цаг",
      name: "endTime",
      type: "time",
      minuteStep: 10,
      className: "col-span-3 md:col-span-2 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Утас",
      name: "phone",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Онош",
      name: "diagnose",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Ажиллагаа",
      name: "operation",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Эмч",
      name: "doctorIds",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        optionFilterProp: "label",
        mode: "multiple",
        options: doctors,
      },
    },
    {
      label: "Төлөв",
      name: "status",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        optionFilterProp: "label",

        options: [
          {
            label: "Төлөвлөсөн",
            value: "Төлөвлөсөн",
          },
          {
            label: "Орсон",
            value: "Орсон",
          },
        ],
      },
    },
    {
      label: "Дэлгэрэнгүй мэдээлэл",
      name: "description",
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
