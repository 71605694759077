import { Button, Form, Input, Select } from "antd";

export default ({ configTypeOptions,technicians}) => {
  return [
    {
      label: "Нэр",
      name: "userId",
      type:"select",
      rules: [
        { required: true, message: "Үйлчлүүлэгчийн мэдээлэлэл оруулна уу!" },
      ],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: technicians,
      },
    },
    {
        label: "Хуваарь",
        name: "configId",
        type: "select",
        rules: [{ required: true, message: "Тасаг сонгоно уу!" }],
        className: "col-span-12 md:col-span-6 mb-0",
        inputProps: {
          className: "w-full",
          options: configTypeOptions,
        },
      },
      {
        label: "Огноо",
        name: "date",
        type: "date",
        disabled: true,
        rules: [{ required: true, message: "Огноо сонгоно уу!" }],
        className: "col-span-12 md:col-span-3 mb-0",
        inputProps: {
          className: "w-full",
        },
      },
  ];
};
