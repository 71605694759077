import React, { useState } from "react";

import { Button, List, Segmented, Table } from "antd";
import { MainTable } from "components";
function ReceptionCount({ data, className = "" }) {
  const [viewMode, setViewMode] = useState("table");

  const columns = [
    {
      title: "Нэр",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Хариу гарсныг мэдэгдсэн Тоо",
      dataIndex: "cntCall",
      key: "cntCall",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCall - b.cntCall,
    },
    {
      title: "Хүлээлгэж өгсөн тоо",
      dataIndex: "cntGiven",
      key: "cntGiven",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntGiven - b.cntGiven,
    },
  ];
  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex gap-5">
        <div className="border rounded-lg overflow-hidden flex-1">
          {/* <div className="py-2 px-2 border-b">Дундаж онш бичилт</div> */}
          <MainTable
            dataTable={data}
            columns={columns}
            title={"Хариу хүлээн өгcөн мэдээлэл"}
          />
        </div>
      </div>
    </div>
  );
}

export default ReceptionCount;
