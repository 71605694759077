export default ({ form }) => {
  return [
    {
      label: "Time",
      name: "npTime",
      type: "time",
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        onSelect: (e) => {
        
          const selectedTime = e; // assuming e is a Moment object

          // Clone the Moment object to avoid modifying the original
          const currentTime = selectedTime.clone();

          // Add one minute to the current time
          currentTime.add(1, "minutes");

          // Update the form field with the modified time
          form.setFieldValue("timeOfInjection", currentTime);
          form.setFieldValue("npTime", e);
          // form.setFieldValue("npTime", e.toDate());
        },
      },
    },
    {
      label: "mCI",
      name: "npDoseMCi",
      className: "col-span-6 md:col-span-6 mb-0",
      type: "number",
      inputProps: {
        className: "w-full",
      },
      onChange: (e) => {
        form.setFieldValue("npDoseMBq", e.target.value * 37);
      },
    },
    {
      label: "MBq",
      name: "npDoseMBq",
      // rules: [{ required: true, message: "MBq оруулна уу!" }],
      className: "col-span-6 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Time of injection",
      name: "timeOfInjection",
      rules: [{ required: true, message: "Огноо оруулна уу!" }],
      className: "col-span-6 md:col-span-6 mb-0",
      type: "time",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
