import { Form, Input, Select } from "antd";

export default ({ wards }) => {
  return [
    {
      label: "Өрөөний дугаар",
      name: "room_number",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Тасаг",
      name: "wardId",
      type: "select",

      rules: [{ required: true, message: "Тасаг сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: wards,
      },
    },
    {
      label: "Тайлбар",
      name: "description",
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
