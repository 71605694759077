import { Pie } from '@ant-design/charts';
import React from 'react'

function PieChart({data}) {
  // const data = [
  //   {
  //     type: '分类一',
  //     value: 27,
  //   },
  //   {
  //     type: '分类二',
  //     value: 25,
  //   },
  //   {
  //     type: '分类三',
  //     value: 18,
  //   },
  //   {
  //     type: '分类四',
  //     value: 15,
  //   },
  //   {
  //     type: '分类五',
  //     value: 10,
  //   },
  //   {
  //     type: '其他',
  //     value: 5,
  //   },
  // ];
const config = {
  appendPadding: 10,
  data: data ? data : [],
  angleField: 'amount',
  colorField: 'name',
  radius: 0.9,
  legend: {
    position: 'top',
  },
  label: {
    type: 'outer',
    offset: '-30%',
    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: 'center',
    },
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
};
  return (
    <div className='flex-1'>
      <Pie {...config} />
    </div>
  )
}

export default PieChart