import React, { useEffect, useState, useContext, useModal } from "react";
import { Button, Modal, Tag, Form as AntForm, Select, Upload } from "antd";
import { EditOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, MainTable, CompanySelect } from "components";
import { instance } from "utils/axios";
import * as XLSX from "xlsx";
function Caller() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    // fetchData();
   
  }, []);
  // const fetchData = () => {
  //   setLoading(true);
  //   instance({
  //     method: "get",
  //     url: `/devices?hospital=${selectedHostpital}&servicetypeid=${serviceType}`,
  //   })
  //     .then((res) => {
  //       setData(res.data);
  //     })
  //     .catch((err) => {})
  //     .then(() => setLoading(false));
  // };


const handleFileUpload = ({ file }) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const binaryStr = event.target.result;
    const workbook = XLSX.read(binaryStr, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const json = XLSX.utils.sheet_to_json(sheet);

    // Normalize column titles by removing special characters
    const normalizeTitle = (title) =>
      title.replace(/[\s.-]/g, "").toLowerCase();

    // Extract titles and dataIndex values from columns variable
    const columnsMap = columns.reduce((acc, col) => {
      acc[normalizeTitle(col.title)] = col.dataIndex;
      return acc;
    }, {});
    const dateColumns = ["startTime", "answerTime", "endTime"];
    const csvColumns = Object.keys(json[0]);
    const normalizedCsvColumns = csvColumns.map(normalizeTitle);
    const missingColumns = normalizedCsvColumns.filter(
      (col) => !columnsMap[col]
    );
    if (missingColumns.length > 0) {
      console.warn(
        `CSV contains unknown columns: ${missingColumns.join(", ")}`
      );
    }

    const mappedData = json.map((row) => {
      const mappedRow = {};
      csvColumns.forEach((col, index) => {
        const normalizedCol = normalizedCsvColumns[index];
        if (columnsMap[normalizedCol]) {
          let value = row[col];      
          if (value&&dateColumns.includes(columnsMap[normalizedCol])) {
            value = XLSX.SSF.parse_date_code(value);
    

             value = `${value.y}-${value.m}-${ value.d} ${ value.H}:${value.M}:${ value.S}`
          }

          mappedRow[columnsMap[normalizedCol]] = value;
        }
      });
      return mappedRow;
    });
 
    setData(mappedData);
  };
  reader.readAsBinaryString(file);
};




const columns = [
  {
    title: "Unique ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Caller Number",
    dataIndex: "callerNumber",
    key: "callerNumber",
  },
  {
    title: "Caller NAT",
    dataIndex: "callerNAT",
    key: "callerNAT",
  },
  {
    title: "Callee Number",
    dataIndex: "calleeNumber",
    key: "calleeNumber",
  },
  {
    title: "Callee NAT",
    dataIndex: "calleeNAT",
    key: "calleeNAT",
  },
  {
    title: "Context",
    dataIndex: "context",
    key: "context",
  },
  {
    title: "Account Code",
    dataIndex: "accountCode",
    key: "accountCode",
  },
  {
    title: "Caller ID",
    dataIndex: "callerID",
    key: "callerID",
  },
  {
    title: "Source Channel",
    dataIndex: "sourceChannel",
    key: "sourceChannel",
  },
  {
    title: "Dest. Channel",
    dataIndex: "destChannel",
    key: "destChannel",
  },
  {
    title: "Last app.",
    dataIndex: "lastApp",
    key: "lastApp",
  },
  {
    title: "Last data",
    dataIndex: "lastData",
    key: "lastData",
  },
  {
    title: "Start Time",
    dataIndex: "startTime",
    key: "startTime",
    // render: (value) => value ? new Intl.DateTimeFormat('en-US', { timeZone: 'Etc/GMT-8', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(value)) : '',

  },
  {
    title: "Answer Time",
    dataIndex: "answerTime",
    key: "answerTime",
    // render: (value) => (value ?value.toLocaleString() : ""),
  },
  {
    title: "End Time",
    dataIndex: "endTime",
    key: "endTime",
    // render: (value) => (value ? new Date(value).toLocaleString() : ""),
  },
  {
    title: "Call Time",
    dataIndex: "callTime",
    key: "callTime",
  },
  {
    title: "Talk Time",
    dataIndex: "talkTime",
    key: "talkTime",
  },
  {
    title: "Call Status",
    dataIndex: "callStatus",
    key: "callStatus",
  },
  {
    title: "AMA Flags",
    dataIndex: "amaFlags",
    key: "amaFlags",
  },
  {
    title: "Call Type",
    dataIndex: "callType",
    key: "callType",
  },
  {
    title: "Dest Channel Extension",
    dataIndex: "destChannelExtension",
    key: "destChannelExtension",
  },
  {
    title: "Caller Name",
    dataIndex: "callerName",
    key: "callerName",
  },
  {
    title: "Answered By",
    dataIndex: "answeredBy",
    key: "answeredBy",
  },
  {
    title: "Session",
    dataIndex: "session",
    key: "session",
  },
  {
    title: "Premier Caller",
    dataIndex: "premierCaller",
    key: "premierCaller",
  },
  {
    title: "Action Type",
    dataIndex: "actionType",
    key: "actionType",
  },
  {
    title: "Source Trunk Name",
    dataIndex: "sourceTrunkName",
    key: "sourceTrunkName",
  },
  {
    title: "Destination Trunk Name",
    dataIndex: "destinationTrunkName",
    key: "destinationTrunkName",
  },
];

  const handleSubmit = (values) => {
    setLoading(true);
     instance({
        method: "post",
        url: "/caller",
        data:data
      })
        .then((res) => {
        
        })
        .catch((err) => {})
        .then(() => setLoading(false));
  };
  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="flex gap-5 mb-3 ">
        <div>
          {/* <Button
            className="mb-0 w-[100px]"
            value={serviceType}
            options={serviceTypes}
            onChange={(e) => {
              setServiceType(e);
            }}
          /> */}
        </div>

        <div className="flex gap-5 mb-3">
          <Upload
            accept=".csv,.xlsx"
            showUploadList={false}
            beforeUpload={() => false}
            onChange={handleFileUpload}
          >
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>
          <Button onClick={handleSubmit}><SaveOutlined />Хадгалах</Button>
        </div>
      </div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Дуудлагын мэдээлэл"}
      />
    </div>
  );
}

export default Caller;
