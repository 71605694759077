import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import logo from "../../assets/images/logo2.png";
import moment from "moment";
import { Modal } from "antd";
const PrintComponent = ({ openPrint = false, data, setOpenPrint }) => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => setOpenPrint(false),
    removeAfterPrint: true,
    pageStyle: `body
    {
      margin: 25mm 25mm 25mm 25mm;
    }
    @page {size:portrait;}`,
  });
  return (
    <Modal
      title={"Хэвлэх"}
      open={openPrint}
      destroyOnClose
      width="3.14in"
      okText="Хэвлэх"
      // confirmLoading={loading}
      onOk={() => handlePrint(null, () => contentToPrint.current)}
      onCancel={() => setOpenPrint(false)}
      // cancelButtonProps={{ disabled: loading }}
    >
      {data ? (
        <div
          className="flex flex-col items-center justify-center px-4 pb-2"
          style={{ fontFamily: "Arial, sans-serif" }}
          ref={contentToPrint}
        >
          <img
            className="mt-3"
            style={{
              width: "0.48in",
              height: "0.48in",
            }}
            src={
              data?.hospitalId == 2
                ? logo
                : `https://images-ext-1.discordapp.net/external/18m9Nov9RgLBQ3tPLU-MjIomlfjHulI1FoyXxR5FVVI/https/i.ibb.co/HpMck2m/MED-TRAUMA-02-1-removebg-preview.png`
            }
          />
          <span>{data.serviceName}</span>
          <span className="mt-1">{data.name}</span>

          <span>{moment(data.currentDate).format("YYYY-MM-DD")}</span>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default PrintComponent;
