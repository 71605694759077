import { Select } from "antd";
import React from "react";
import { useEffect, useContext } from "react";
import { useState } from "react";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";
function CompanySelect(props) {
  const { setFieldValue, _field } = props;
  const [options, setOptions] = useState([]);

  const [init, setInit] = useState(true);
  const authContext = useContext(AuthContext);
  const hospitalDisable =
    authContext.state.userInfo.profile.hospitalSecondId == null;
  useEffect(() => {
    getOptions();
  }, [init]);

  const getOptions = () => {
    instance({
      method: "get",
      url: `/hospitals`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          tmp.push({ label: item.name, value: item.id, text: item.name });
        });
        setOptions(tmp);
      })
      .catch((err) => {});
  };

  return (
    <Select
      disabled={hospitalDisable}
      //size="small"
      // loading={loading}
      options={options}
      {...props}
    ></Select>
  );
}

export default CompanySelect;
