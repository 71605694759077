import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Form as AntForm, Select } from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import { Link } from "react-router-dom";
import moment from "moment";

function Expense({ expenseType, expense, startDate, endDate }) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage, startDate, endDate]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/transactionexpense?startDate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(endDate).format("YYYY-MM-DD")}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "Төрөл",
      dataIndex: "expensetype",
      key: "expensetype",
    },
    {
      title: "Төрөл",
      dataIndex: "expense",
      key: "expense",
    },
    {
      title: "Огноо",
      dataIndex: "expenseDate",
      key: "expenseDate",
    },
    {
      title: "Дүн",
      dataIndex: "amount",
      key: "amount",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} ₮</div>,
    },
    {
      title: "Тайлбар",
      dataIndex: "descr",
      key: "descr",
    },
    // {
    //   title: "Огноо",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },
    {
      title: "Бүртгэсэн ажилтан",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleDelete(row)}>
          <div className="flex items-center gap-2">
            <DeleteOutlined /> Цуцлах
          </div>
        </Button>
      ),
    },
  ];

  const handleSubmit = (values) => {

    console.log(values.expenseDate);
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: "/transactionexpense",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/transactionexpense",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };
  const handleDelete = (row) => {
    setLoading(true);
    instance({
      method: "delete",
      url: `/transactionexpense/${row.id}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const fields = [
    {
      label: "Тайлбар",
      name: "descr",
      className: "col-span-12 md:col-span-12 mb-0",
      type: "textarea",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Өдөр",
      name: "expenseDate",
      rules: [{ required: true, message: "Өдөр оруулна уу!" }],
      className: "col-span-12 md:col-span-12 mb-0",
      type: "date",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Дүн",
      name: "amount",
      rules: [{ required: true, message: "Дүн оруулна уу!" }],
      className: "col-span-12 md:col-span-12 mb-0",
      type: "number",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Зарлагын төрөл",
      name: "expenseTypeId",
      rules: [{ required: true, message: "Зарлагын төрөл оруулна уу!" }],
      className: "col-span-12 md:col-span-12 mb-0",
      type: "select",
      inputProps: {
        className: "w-full",
        options: expenseType,
      },
    },
    {
      type: "component",
      component: (
        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) =>
            prev.expenseTypeId !== cur.expenseTypeId ||
            prev.expenseTypeId !== cur.expenseTypeId
          }
        >
          {() => {
            return (
              <div className="col-span-12 flex items-center gap-4">
                <Form.Item
                  label="Зарлага сонгоно уу"
                  name="expenseId"
                  className="mb-0 w-[300px]"
                  rules={[
                    {
                      required: true,
                      message: "Зарлага сонгоно уу!",
                    },
                  ]}
                >
                  <Select
                    options={expense.filter((expense) => {
                      return (
                        expense.typeId == form.getFieldValue("expenseTypeId")
                      );
                    })}
                    placeholder="Зарлага сонгох"
                    className="w-full"
                  />
                </Form.Item>
              </div>
            );
          }}
        </Form.Item>
      ),
    },
  ];

  return (
    <div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        // title={"Зарлагын төрөл"}
        handleAdd={handleAdd}
      />
      <Modal
        title={editData ? "Зарлага засах" : "Зарлага нэмэх"}
        open={open}
        destroyOnClose
        width={600}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Expense;
