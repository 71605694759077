import React, { useState } from "react";

import { Button, List, Segmented, Table, Typography, Tag } from "antd";
import { MainTable } from "components";
function DoctorAvg({ data, className = "" }) {
  const { Text } = Typography;
  const [viewMode, setViewMode] = useState("table");

  const columns = [
    {
      title: "Нэр",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "MRI Тоо",
      dataIndex: "cntMRI",
      key: "cntMRI",
      visible: false,
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntMRI - b.cntMRI,
    },
    {
      title: "MRI тодосгогч тоо",
      dataIndex: "cntCEMRI",
      key: "cntCEMRI",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCEMRI - b.cntCEMRI,
    },
    {
      title: "MRI Дундаж цаг",
      dataIndex: "avgMRI",
      key: "avgMRI",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.avgMRI - b.avgMRI,
    },
    {
      title: "CT Тоо",
      dataIndex: "cntCT",
      key: "cntCT",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCT - b.cntCT,
    },
    {
      title: "CT Тодосгогч Тоо",
      dataIndex: "cntCECT",
      key: "cntCECT",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCECT - b.cntCECT,
    },
    {
      title: "CT Дундаж цаг",
      dataIndex: "avgCT",
      key: "avgCT",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.avgCT - b.avgCT,
    },
    {
      title: "PET Тоо",
      dataIndex: "cntPET",
      key: "cntPET",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntPET - b.cntPET,
    },
    {
      title: "PET Дундаж цаг",
      dataIndex: "avgPET",
      key: "avgPET",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.avgPET - b.avgPET,
    },
    {
      title: "Xray Тоо",
      dataIndex: "cntXray",
      key: "cntXray",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntXray - b.cntXray,
    },
    {
      title: "Xray Дундаж цаг",
      dataIndex: "avgXray",
      key: "avgXray",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.avgXray - b.avgXray,
    },
    {
      title: "CITO",
      dataIndex: "urgent",
      key: "urgent",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.urgent - b.urgent,
    },

    {
      title: "Нэмэлт",
      dataIndex: "addition",
      key: "addition",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.addition - b.addition,
    },
    {
      title: "Англи",
      dataIndex: "english",
      key: "english",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.english - b.english,
    },
    {
      title: "Bonus",
      dataIndex: "bonus",
      key: "bonus",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.english - b.english,
    },
    {
      title: "Зөвөлгөө",
      dataIndex: "read2",
      key: "read2",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.read2 - b.read2,
    },
    {
      title: "Only CE /MRI/",
      dataIndex: "cntMRIOnlyCe",
      key: "cntMRIOnlyCe",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntMRIOnlyCe - b.cntMRIOnlyCe,
    },
    {
      title: "Only CE /CT/",
      dataIndex: "cntCTOnlyCe",
      key: "cntCTOnlyCe",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cntCTOnlyCe - b.cntCTOnlyCe,
    },
  ];
  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex gap-5">
        <div className="border rounded-lg overflow-hidden flex-1">
          {/* <div className="py-2 px-2 border-b">Дундаж онш бичилт</div> */}
          <MainTable
            dataTable={data}
            columns={columns}
            title={"Хариу гаргалтын дундаж хугацаа"}
            summary={(pageData) => {
              let totalMRI = 0;
              let totalCT = 0;
              let totalCEMRI = 0;
              let totalCECT = 0;
              let totalXRay = 0;
              let totalPet = 0;
              let totalUrgent = 0;
              let totalAddition = 0;
              let totalEnglish = 0;
              let totalRead2 = 0;
              let totalBonus = 0;
              let totalCTOnlyCE = 0;
              let totalMRIOnlyCE = 0;
              if (data)
                data.forEach(
                  ({
                    cntMRI,
                    cntCT,
                    cntCEMRI,
                    cntCECT,
                    cntXray,
                    cntPET,
                    urgent,
                    addition,
                    english,
                    read2,
                    bonus,
                    cntMRIOnlyCe,
                    cntCTOnlyCe,
                  }) => {
                    totalMRI += cntMRI;
                    totalCT += cntCT;
                    totalCEMRI += cntCEMRI;
                    totalCECT += cntCECT;
                    totalUrgent += urgent;
                    totalPet += cntPET;
                    totalXRay += cntXray;
                    totalAddition += addition;
                    totalEnglish += english;
                    totalRead2 += read2;
                    totalBonus += bonus;
                    totalMRIOnlyCE += cntMRIOnlyCe;
                    totalCTOnlyCE += cntCTOnlyCe;
                  }
                );

              return (
                <>
                  <Table.Summary.Row rowClassName={"table-row-dark"}>
                    <Table.Summary.Cell >
                      <Tag color="success">Total</Tag>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalMRI}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalCEMRI}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                      <Text type="success">{totalCT}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalCECT}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                      <Text type="success">{totalPet}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />

                    <Table.Summary.Cell>
                      <Text type="success">{totalXRay}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                      <Text type="success">{totalUrgent}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalAddition}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalEnglish}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalBonus}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalRead2}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalMRIOnlyCE}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalCTOnlyCE}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  {/* <Table.Summary.Row>
                    <Table.Summary.Cell>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2}>
                      <Text type="danger">{totalMRI - totalCT}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DoctorAvg;
