import React, { useContext, useEffect, useState } from "react";
import { instance } from "utils/axios";
import { FaBuilding, FaUserCog, FaUsers } from "react-icons/fa";
import { FaBellConcierge, FaBuildingUser, FaIdCardClip } from "react-icons/fa6";
import { DatePicker, Segmented, Skeleton, Select, Tabs } from "antd";
import { AuthContext } from "contexts";

import moment from "moment";

import ColumnChart from "./ColumnChart";

const InpatientDashboard = ({}) => {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const { state, action } = useContext(AuthContext);
  useEffect(() => {
    action.handleSidebarKey("inpatientdashboard");
  }, []);

  useEffect(() => {
    fetchData();
  }, [date]);

  const fetchData = () => {
    // if (serviceType != null) {
    setLoading(true);

    instance({
      method: "get",
      url: `/inpatient-dashboard?date=${moment(date)
        .startOf("month")
        .format("YYYY-MM-DD")}`,
    })
      .then((res) => {

        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  // };

  return (
    <div className="px-1  md:px-5">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <DatePicker picker="month" value={date} onChange={(e) => setDate(e)} />
        <div></div>
      </div>

      <div className="grid grid-cols-12 gap-5 mt-5"></div>
      <div className="grid grid-cols-24 gap-2">
        {data?.systemCountData?.map((item) => (
          <div
            className={`col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6 `}
          >
            <div>
              <div className="text-base text-muted">{item.name}</div>
              <div className="font-medium text-3xl">
                {Intl.NumberFormat().format(item.cnt)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-12 gap-5 mt-5">
        <ColumnChart
          data={data?.monthData}
          className="col-span-12"
          alias="Үйлчлүүлэгчидийн тоо/Тасаг/"
        />
      </div>
    </div>
  );
};

export default InpatientDashboard;
