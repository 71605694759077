export default () => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Өнгө",
      name: "color",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-12 mb-0",
      type: "tagselect",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Magenta", value: "magenta" },
          { label: "Red", value: "red" },
          { label: "Orange", value: "orange" },
          { label: "Lime", value: "lime" },
          { label: "Green", value: "green" },
          { label: "Cyan", value: "cyan" },
          { label: "Blue", value: "blue" },
          { label: "Geekblue", value: "geekblue" },
          { label: "Purple", value: "purple" },
          { label: "Brown", value: "brown" },
          { label: "#f50", value: "#f50" },
          { label: "#2db7f5", value: "#2db7f5" },
          { label: "#87d068", value: "#87d068" },
          { label: " #108ee9", value: " #108ee9" },
        ],
      },
    },
  ];
};
