import { Form, Input, Select } from "antd";

export default () => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Дэлгэрэнгүй мэдээлэл",
      name: "description",
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
