export default () => {
  return [
    {
      label: "Time",
      name: "npTime",
      type: "time",
      rules: [{ required: true, message: "Цаг сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Time of procedure done",
      name: "currentDateTime",
      rules: [{ required: true, message: "Цаг оруулна уу!" }],
      className: "col-span-6 md:col-span-6 mb-0",
      type: "time",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
