import { Button, Divider, Form, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomerForm from "./customerForm";
import React, { useEffect, useState } from "react";
export default ({
  services = [],
  removeFunc,
  form,
  serviceTypes,
  devices,
  ceItems,
}) => {
  const handleRemove = (remove, selectedServices, setFieldValue) => {
    if (selectedServices?.length === 0) {
      setFieldValue(["bookingData", "amount"], 0);
    }
    // remove();
  };

  return [
    {
      type: "component",
      component: (
        <Divider className="col-span-12" style={{ marginTop: 0 }}>
          Үйлчлүүлэгчийн мэдээлэл
        </Divider>
      ),
    },

    {
      type: "component",
      component: <CustomerForm form={form} />,
    },
    {
      type: "component",
      component: <Divider className="col-span-12">Мэдээлэл</Divider>,
    },
    // : null,
    {
      label: "Огноо",
      name: ["bookingData", "currentDate"],
      type: "date",
      disabled: true,
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Цаг",
      name: ["bookingData", "currentDateTime"],
      type: "time",
      rules: [{ required: true, message: "Цаг сонгоно уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      disabled: true,
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төхөөрөмж",
      name: ["bookingData", "servicetypeid"],
      type: "select",
      rules: [{ required: true, message: "Төхөөрөмж сонгоно уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",

      inputProps: {
        className: "w-full",
        options: serviceTypes,

        onChange: () => {
          form.setFieldValue(["bookingData", "ceitemid"], null);
          form.setFieldValue(["bookingData", "isCe"], false);
          form.setFieldValue(["bookingData", "deviceId"], null);
        },
      },
    },

    {
      type: "component",
      component: (
        <Form.Item
          name={["bookingData", "deviceId"]}
          label="Аппарат"
          rules={[{ required: true, message: "Төхөөрөмж сонгоно уу!" }]}
          className="col-span-12 md:col-span-3 mb-0"
        >
          <Form.Item
            shouldUpdate={(prev, cur) =>
              prev.bookingData?.servicetypeid !== cur.bookingData?.servicetypeid
            }
          >
            {() => {
              return (
                <Select
                  value={form.getFieldValue(["bookingData", "deviceId"])}
                  options={devices.filter(
                    (service) =>
                      service.servicetypeid ===
                      form.getFieldValue(["bookingData", "servicetypeid"])
                  )}
                  onChange={(value) => {
                    form.setFieldValue(["bookingData", "deviceId"], value);
                  }}
                  placeholder="Аппарат сонгох"
                  className="w-full"
                />
              );
            }}
          </Form.Item>
        </Form.Item>
      ),
    },
    {
      type: "component",
      component: (
        <Form.List
          name={["bookingData", "bookingservices"]}
          className="col-span-6"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, cur) =>
                    prev.bookingData.servicetypeid !==
                      cur.bookingData.servicetypeid ||
                    prev.bookingData.bookingservices !==
                      cur.bookingData.bookingservices
                  }
                >
                  {({ getFieldValue, setFieldValue }) => {
                    return (
                      <div className="col-span-12 flex items-center gap-4">
                        <Form.Item
                          label="Үйлчилгээ"
                          name={[name, "serviceId"]}
                          className="mb-0 w-[300px]"
                          rules={[
                            {
                              required: true,
                              message: "Үйлчилгээ сонгоно уу!",
                            },
                          ]}
                        >
                          <Select
                            options={services.filter(
                              (service) =>
                                service.servicetypeid ===
                                form.getFieldValue([
                                  "bookingData",
                                  "servicetypeid",
                                ])
                            )}
                            placeholder="Үйлчилгээ сонгох"
                            className="w-full"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Тал"
                          name={[name, "side"]}
                          className="mb-0 w-[100px]"
                        >
                          <Select
                            options={[
                              { label: "Left", value: "Left" },
                              { label: "Right", value: "Right" },
                              { label: "Both", value: "Both" },
                              { label: "None", value: null },
                            ]}
                            placeholder="Тал"
                            className="w-full"
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="mt-5"
                          onClick={() =>
                            getFieldValue(["bookingData", "bookingservices"])[
                              key
                            ]?.id == null
                              ? handleRemove(
                                  remove(name),
                                  getFieldValue([
                                    "bookingData",
                                    "bookingservices",
                                  ]),
                                  setFieldValue
                                )
                              : removeFunc(
                                  getFieldValue([
                                    "bookingData",
                                    "bookingservices",
                                  ])[key]?.id,
                                  remove(name)
                                )
                          }
                        />
                      </div>
                    );
                  }}
                </Form.Item>
              ))}
              <Form.Item className="col-span-12">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Үйлчилгээ нэмэх
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      ),
    },

    {
      label: "Зовиур",
      name: ["bookingData", "painful"],
      type: "textarea",
      rules: [{ required: true, message: "Зовиур оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      type: "component",
      component: (
        <Form.Item
          name={["bookingData", "ceitemid"]}
          label="Тодосгогч бодис"
          className="col-span-12 md:col-span-3 mb-0"
        >
          <Form.Item
            shouldUpdate={(prev, cur) =>
              prev.bookingData?.servicetypeid !== cur.bookingData?.servicetypeid
            }
          >
            {() => {
              return (
                <Select
                  value={form.getFieldValue(["bookingData", "ceitemid"])}
                  options={ceItems.filter(
                    (service) =>
                      service.servicetypeid ===
                      form.getFieldValue(["bookingData", "servicetypeid"])
                  )}
                  onChange={(value) => {
                    form.setFieldValue(["bookingData", "ceitemid"], value);
                    form.setFieldValue(["bookingData", "isCe"], true);
                  }}
                  placeholder="Тодосгогч сонгох"
                  className="w-full"
                />
              );
            }}
          </Form.Item>
        </Form.Item>
      ),
    },
    {
      label: "Тодосгогч хэмжээ",
      name: ["bookingData", "ceGram"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-3 md:col-span-3 mb-0",
      type: "number",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэмэлт тайлбар",
      name: ["bookingData", "descr"],
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хариу авах утас",
      name: ["bookingData", "answerPhone"],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "ЭМД",
      name: ["bookingData", "insurance"],

      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Яаралтай",
      name: ["bookingData", "urgent"],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Англи хариу",
      name: ["bookingData", "isEnglishResponse"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Давтан",
      name: ["bookingData", "isRepeat"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Тодосгогч",
      name: ["bookingData", "isCe"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
        onChange: (e) => {
          if (!e.target.checked) {
            form.setFieldValue(["bookingData", "ceitemid"], null);
          }
        },
      },
    },

    {
      label: "Унтуулга",
      name: ["bookingData", "isAns"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Bonus",
      name: ["bookingData", "isBonus"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэмэлт хариу",
      name: ["bookingData", "addition"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Only CE",
      name: ["bookingData", "onlyCe"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
