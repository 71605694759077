import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import events from "./event";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import styles from "./calendar.css";
export default function FullCalendarPage() {
  const handleDateClick = (arg) => {
    alert(arg.dateStr);
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <h1>Хуваарь</h1>
      <FullCalendar
        aspectRatio={2}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        weekends={false}
        dateClick={(e) => handleDateClick(e)}
        events={events}
        eventContent={renderEventContent}
        // eventRender={renderEventContent}
      />
    </div>
  );
}

function renderEventContent(eventInfo) {

  return (
    <div
      className="fc-event"
      // style={{ height: "100%" }} // Adjust the height as needed
    >
      <b>{eventInfo.timeText}</b>
      <b>{eventInfo.event.title}</b>

      <span
        style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {eventInfo.event.extendedProps.description}
      </span>
    </div>
  );
}
