import { Button, Divider, Form, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomerForm from "./customerForm";
import React, { useState } from "react";
import moment from "moment";
export default ({ services = [], removeFunc, form, serviceTypes }) => {
  const handleRemove = (remove, selectedServices, setFieldValue) => {
    if (selectedServices?.length === 0) {
      setFieldValue(["bookingData", "amount"], 0);
    }
    // remove();
  };
  return [
    {
      type: "component",
      component: (
        <Divider className="col-span-12" style={{ marginTop: 0 }}>
          Үйлчлүүлэгчийн мэдээлэл
        </Divider>
      ),
    },

    {
      type: "component",
      component: <CustomerForm form={form} />,
    },
    {
      type: "component",
      component: <Divider className="col-span-12">Мэдээлэл</Divider>,
    },
    {
      label: "Огноо",
      name: ["bookingData", "bookingDate"],
      type: "date",
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
        onChange: () => {
          let date = moment(
            form.getFieldValue(["bookingData", "bookingDate"])
          ).format("YYYY-MM-DD");
          let time = moment(
            form.getFieldValue(["bookingData", "bookingDateTime"])
          ).format("HH:mm");
          console.log(moment(date + "T" + time));
          form.setFieldValue(
            ["bookingData", "bookingDateTime"],
            moment(date + "T" + time)
          );
        },
      },
    },
    {
      label: "Цаг",
      name: ["bookingData", "bookingDateTime"],
      type: "time",

      rules: [{ required: true, message: "Цаг сонгоно уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      minuteStep: 10,
      inputProps: {
        className: "w-full",
      },
    },
    // {
    //   label: "Зовиур",
    //   name: ["bookingData", "painful"],
    //   type: "textarea",
    //   rules: [{ required: true, message: "Зовиур оруулна уу!" }],
    //   className: "col-span-12 md:col-span-6 mb-0",
    //   inputProps: {
    //     className: "w-full",
    //   },
    // },
    {
      label: "Тайлбар",
      name: ["bookingData", "descr"],
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Төлбөрийн төрөл",
      name: ["bookingData", "paymentType"],
      rules: [
        { required: true, message: "Төлбөрийн төрөл сонгон уу оруулна уу!" },
      ],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "select",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Төлбөрт", value: "pay" },
          { label: "Даатгал*100%", value: "insurance100" },
          { label: "Даатгал*30%", value: "insurance70" },
        ],
      },
    },
    {
      label: "Хариу авах утас 1",
      name: ["bookingData", "answerPhone"],
      rules: [{ required: true, message: "Хариу авах утас оруулна уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хариу авах утас 2",
      name: ["bookingData", "answerPhone2"],
      // rules: [{ required: true, message: "Хариу авах утас оруулна уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хариу авах Email",
      name: ["bookingData", "answerMail"],
      // rules: [{ required: true, message: "Хариу авах утас оруулна уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Илгээсэн эмч",
      name: ["bookingData", "sendDoctor"],
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      type: "component",
      component: (
        <Divider className="col-span-12" style={{ marginTop: 0 }}></Divider>
      ),
    },
    {
      label: "Яаралтай",
      name: ["bookingData", "urgent"],
      className: "col-span-6 md:col-span-2 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Англи хариу",
      name: ["bookingData", "isEnglishResponse"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-2 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Нэмэлт хариу",
      name: ["bookingData", "addition"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-2 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Тодосгогч",
      name: ["bookingData", "isCe"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-2 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Унтуулга",
      name: ["bookingData", "isAns"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-2 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Only CE",
      name: ["bookingData", "onlyCe"],
      // rules: [{ required: true, message: "Үйлчилгээний дүн оруулна уу!" }],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      type: "component",
      component: (
        <Divider className="col-span-12" style={{ marginTop: 0 }}></Divider>
      ),
    },
    {
      label: "Төхөөрөмж",
      name: ["bookingData", "servicetypeid"],
      type: "select",
      rules: [{ required: true, message: "Төхөөрөмж сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: serviceTypes,
      },
    },

    {
      type: "component",
      component: (
        <Form.List
          name={["bookingData", "bookingservices"]}
          className="col-span-12"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, cur) =>
                    prev.bookingData.servicetypeid !==
                      cur.bookingData.servicetypeid ||
                    prev.bookingData.bookingservices !==
                      cur.bookingData.bookingservices
                  }
                >
                  {({ getFieldValue, setFieldValue }) => {
                    return (
                      <div className="col-span-12 flex items-center gap-4">
                        <Form.Item
                          label="Үйлчилгээ"
                          name={[name, "serviceId"]}
                          className="mb-0 w-[300px]"
                          rules={[
                            {
                              required: true,
                              message: "Үйлчилгээ сонгоно уу!",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={services.filter(
                              (service) =>
                                service.servicetypeid ===
                                form.getFieldValue([
                                  "bookingData",
                                  "servicetypeid",
                                ])
                            )}
                            placeholder="Үйлчилгээ сонгох"
                            className="w-full"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Тал"
                          name={[name, "side"]}
                          className="mb-0 w-[100px]"
                        >
                          <Select
                            options={[
                              { label: "Left", value: "Left" },
                              { label: "Right", value: "Right" },
                              { label: "None", value: null },
                            ]}
                            placeholder="Тал"
                            className="w-full"
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="mt-5"
                          onClick={() =>
                            getFieldValue(["bookingData", "bookingservices"])[
                              key
                            ]?.id == null
                              ? handleRemove(
                                  remove(name),
                                  getFieldValue([
                                    "bookingData",
                                    "bookingservices",
                                  ]),
                                  setFieldValue
                                )
                              : removeFunc(
                                  getFieldValue([
                                    "bookingData",
                                    "bookingservices",
                                  ])[key]?.id,
                                  remove(name)
                                )
                          }
                        />
                      </div>
                    );
                  }}
                </Form.Item>
              ))}
              <Form.Item className="col-span-12">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Үйлчилгээ нэмэх
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      ),
    },
  ];
};
