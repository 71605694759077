import { Button, Divider, Form, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomerForm from "./customerForm";
import React, { useEffect, useState } from "react";
export default ({ wards = [], rooms, roomBeds, form }) => {
  return [
    {
      type: "component",
      component: (
        <Divider className="col-span-12" style={{ marginTop: 0 }}>
          Үйлчлүүлэгчийн мэдээлэл
        </Divider>
      ),
    },

    {
      type: "component",
      component: <CustomerForm form={form} />,
    },
    {
      type: "component",
      component: <Divider className="col-span-12">Мэдээлэл</Divider>,
    },
    {
      label: "Хэвтсэн огноо",
      name: ["inpatientData", "startDate"],
      type: "date",
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Гарах огноо",
      name: ["inpatientData", "endDate"],
      type: "date",
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төлбөрийн төрөл",
      name: ["inpatientData", "paymentType"],
      className: "col-span-12 md:col-span-6 mb-0",
      rules: [{ required: true, message: "Төлбөрийн төрөл сонгоно уу!" }],
      type: "select",
      inputProps: {
        className: "w-full",
        options: [{ value: "Төлбөртэй" }, { value: "Даатгал" }],
      },
    },
    {
      label: "Тасаг",
      name: ["inpatientData", "wardId"],
      type: "select",

      className: "col-span-12 md:col-span-6 mb-0",

      inputProps: {
        className: "w-full",
        options: wards,

        onChange: () => {
          form.setFieldValue(["inpatientData", "roomId"], null);
          form.setFieldValue(["inpatientData", "bedId"], null);
        },
      },
    },
    {
      label: "ICD10",
      name: ["inpatientData", "icd10"],
      // rules: [{ required: true, message: "ICD10 оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "ICD9",
      name: ["inpatientData", "icd9"],
      // rules: [{ required: true, message: "ICD9 оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Онош",
      name: ["inpatientData", "diagnose"],
      type: "textarea",
      // rules: [{ required: true, message: "Онош оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төлөв",
      name: ["inpatientData", "diagnoseStatus"],
      className: "col-span-12 md:col-span-6 mb-0",
      // rules: [{ required: true, message: "Төлөв сонгоно уу!" }],
      type: "select",
      inputProps: {
        className: "w-full",
        options: [
          { value: "Маш хүнд" },
          { value: "Хүнд" },
          { value: "Хүндэвтэр" },
          { value: "Дунд" },
          { value: "Хөнгөн" },
        ],
      },
    },

    {
      label: "Дүн",
      name: ["inpatientData", "amount"],
      className: "col-span-12 md:col-span-6 mb-0",

      type: "number",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Тайлбар",
      name: ["inpatientData", "note"],
      className: "col-span-12 md:col-span-6 mb-0",

      type: "textarea",
      inputProps: {
        className: "w-full",
      },
    },

    {
      type: "component",
      component: (
        <Form.Item
          name={["inpatientData", "roomId"]}
          label="Өрөө"
          className="col-span-12 md:col-span-6 mb-0"
        >
          <Form.Item
            shouldUpdate={(prev, cur) =>
              prev.inpatientData?.wardId !== cur.inpatientData?.wardId
            }
          >
            {() => {
              return (
                <Select
                  value={form.getFieldValue(["inpatientData", "roomId"])}
                  options={rooms.filter(
                    (room) =>
                      room.wardId ===
                      form.getFieldValue(["inpatientData", "wardId"])
                  )}
                  onChange={(value) => {
                    form.setFieldValue(["inpatientData", "roomId"], value);
                  }}
                  placeholder="Тодосгогч сонгох"
                  className="w-full"
                />
              );
            }}
          </Form.Item>
        </Form.Item>
      ),
    },
    {
      type: "component",
      component: (
        <Form.Item
          name={["inpatientData", "bedId"]}
          label="Ор"
          className="col-span-12 md:col-span-6 mb-0"
        >
          <Form.Item
            shouldUpdate={(prev, cur) =>
              prev.inpatientData?.roomId !== cur.inpatientData?.roomId
            }
          >
            {() => {
              return (
                <Select
                  value={form.getFieldValue(["inpatientData", "bedId"])}
                  options={roomBeds.filter(
                    (bed) =>
                      bed.roomId ===
                      form.getFieldValue(["inpatientData", "roomId"])
                  )}
                  onChange={(value) => {
                    form.setFieldValue(["inpatientData", "bedId"], value);
                  }}
                  placeholder="Ор сонгох"
                  className="w-full"
                />
              );
            }}
          </Form.Item>
        </Form.Item>
      ),
    },
  ];
};
