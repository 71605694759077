import { Form, Input, Select, Upload } from "antd";
import { EditOutlined } from "@ant-design/icons";

export default ({ documemt }) => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
      component: <Input />
    },
    {
      label: "File",
      name: "fileUrl",
      type: "file",
      rules: [{ required: true, message: "file оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

  ];
};
