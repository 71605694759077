import { Button, DatePicker, Form, Input, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { instance } from "utils/axios";

function CustomerForm({ form }) {
  const [loading, setLoading] = useState(false);
  const [isNotFount, setIsNotFound] = useState(false);

  const handleRegChange = (e) => {
    if (e.target.value.length === 10) {
      getCustomerInfo();
    } else {
      form.setFieldValue(["customerData", "firstname"], null);
      form.setFieldValue(["customerData", "lastname"], null);
      form.setFieldValue(["customerData", "age"], null);
      form.setFieldValue(["customerData", "gender"], null);
    }
  };
  const getCustomerInfo = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `customer/find/${form.getFieldValue(["customerData", "regno"])}`,
    })
      .then((res) => {
        if (res.data) {
          setIsNotFound(false);
          form.setFieldValue("customerData", {
            ...res.data,
            // dob: moment(res.data.dob),
          });
        } else {
          form.setFieldValue(["customerData", "id"], 0);
          if (
            form.getFieldValue(["customerData", "regno"]).toString().length ==
            10
          ) {
            let regno = form
              .getFieldValue(["customerData", "regno"])
              .toString()
              .substring(2, 10);

            form.setFieldValue(
              ["customerData", "gender"],
              parseInt(regno[6]) % 2
            );
            let year = parseInt(regno.substring(0, 2));
            let birthyear = year < 30 ? 2000 + year : 1900 + year;

            let today = new Date();

            let age = today.getFullYear() - birthyear;
      
            form.setFieldValue(["customerData", "age"], age);
          }
          form.setFieldValue(["customerData", "firstname"], null);
          form.setFieldValue(["customerData", "lastname"], null);
          setIsNotFound(true);
        }
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  return (
    <>
      <Form.Item className="col-span-12 mb-0">
        <div className="grid grid-cols-12 gap-x-4 gap-y-4">
          <Form.Item className="mb-0 col-span-6" label="Үйлчлүүлэгч">
            <div className="flex">
              <Form.Item
                name={["customerData", "regno"]}
                className="mb-0 flex-1"
              >
                <Input
                  placeholder="Регистрийн дугаар оруулах"
                  onChange={handleRegChange}
                />
              </Form.Item>
              <Form.Item className="mb-0 ml-1">
                <Button onClick={getCustomerInfo}>Шалгах</Button>
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item name={["customerData", "id"]} noStyle></Form.Item>

          <Form.Item
            name={["customerData", "lastname"]}
            label="Овог"
            // rules={[{ required: true, message: "Овог оруулна уу!" }]}
            className="col-span-12 md:col-span-6 mb-0"
          >
            <Input disabled={!isNotFount} />
          </Form.Item>
          <Form.Item
            name={["customerData", "firstname"]}
            label="Нэр"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
            className="col-span-12 md:col-span-6 mb-0"
          >
            <Input disabled={!isNotFount} />
          </Form.Item>

          <Form.Item
            name={["customerData", "gender"]}
            label="Хүйс"
            rules={[{ required: true, message: "Хүйс сонгоно уу!" }]}
            className="col-span-12 md:col-span-6 mb-0"
          >
            <Select
              options={[
                { label: "Эр", value: 1 },
                { label: "Эм", value: 0 },
              ]}
            />
          </Form.Item>
          <Form.Item
            name={["customerData", "age"]}
            label="Нас"
            rules={[{ required: true, message: "Нас оруулна уу!" }]}
            className="col-span-12 md:col-span-6 mb-0"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name={["customerData", "hasAllergy"]}
            label="Харшилтай эсэх"
            // rules={[{ required: true, message: "Харшилтай эсэх сонгоно уу!" }]}
            className="col-span-12 md:col-span-6 mb-0"
          >
            <Select
              defaultValue={0}
              options={[
                { label: "Тийм", value: 1 },
                { label: "Үгүй", value: 0 },
              ]}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) =>
              prev.customerData?.hasAllergy !== cur.customerData?.hasAllergy
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue(["customerData", "hasAllergy"]) === 1 ? (
                <Form.Item
                  label="Харшлын тайлбар"
                  name={["customerData", "allergyDescr"]}
                  rules={[
                    { required: true, message: "Харшлын тайлбар оруулна уу!" },
                  ]}
                  className="col-span-12 md:col-span-6 mb-0"
                >
                  <Input.TextArea className="w-full" />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
}

export default CustomerForm;
