import React, { useContext, useEffect, useState } from "react";
import { instance } from "utils/axios";
import { FaBuilding, FaUserCog, FaUsers } from "react-icons/fa";
import { FaBellConcierge, FaBuildingUser, FaIdCardClip } from "react-icons/fa6";
import { DatePicker, Segmented, Skeleton, Select, Tabs } from "antd";
import { AuthContext } from "contexts";
import ExpenseIncome from "./ExpenseIncome";
import ExpenseIncomeLog from "./ExpenseIncomeLog";
import DoctorHour from "./DoctorHour";
import moment from "moment";
import Customer from "./Customer";
import ServiceTable from "./Service/ServiceTable";
import { CompanySelect } from "components";

const Dashboard = ({}) => {
  const authContext = useContext(AuthContext);
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const [selectedHostpital, setSelectedHospital] = useState(hospitalId);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const { state, action } = useContext(AuthContext);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceType, setServiceType] = useState(null);
  // const authContext = useContext(AuthContext);
  useEffect(() => {
    action.handleSidebarKey("dashboard");
    instance({
      method: "get",
      url: `/servicetype`,
    })
      .then((res) => {
        let tmp = [];
        if (res.data.length != 1) {
          tmp.push({ label: "Бүгд", value: -1 });
          setServiceType(-1);
        } else setServiceType(res.data[0].id);

        res.data.map((item) => {
          tmp.push({ label: item.name, value: item.id, text: item.name });
        });
        setServiceTypes(tmp);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetchData();
  }, [date, serviceType, selectedHostpital]);

  const fetchData = () => {
    if (serviceType != null) {
      setLoading(true);

      instance({
        method: "get",
        url: `/dashboard?date=${moment(date)
          .startOf("month")
          .format(
            "YYYY-MM-DD"
          )}&servicetype=${serviceType}&hospital=${selectedHostpital}`,
      })
        .then((res) => {
        
          setData(res.data);
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  return (
    <div className="px-1  md:px-5">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <CompanySelect
          // className="mb-0 w-[150px]"
          value={selectedHostpital}
          onChange={(e) => {
            setSelectedHospital(e);
          }}
        />
        <DatePicker picker="month" value={date} onChange={(e) => setDate(e)} />
        <div>
          <Select
            className="mb-0 w-[100px]"
            value={serviceType}
            options={serviceTypes}
            onChange={(e) => {
              setServiceType(e);
            }}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5 mt-5"></div>
      <div className="grid grid-cols-24 gap-2">
        {data?.systemCountData?.map((item) => (
          <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
            <div>
              <div className="text-base text-muted">{item.name}</div>
              <div className="font-medium text-3xl">
                {Intl.NumberFormat().format(item.cnt)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-12 gap-5 mt-5">
        <Customer
          data={data?.monthBookingData}
          className="col-span-12"
          alias="Үйлчлүүлэгчидийн тоо/Өдөр/"
        />
        <DoctorHour
          data={data?.doctorAvgHour}
          className="col-span-12 md:col-span-12 "
          title="Эмч нарын хариу гаргалт"
          servicetypeid={1}
          // totalCount={500}
        />

        <Customer
          data={data?.hourCustomerData}
          className="col-span-12"
          alias="Үйлчлүүлэгчидийн тоо/Цаг/"
        />
        <Tabs
          className="col-span-12"
          items={[
            {
              label: "Дундаж тоо",
              key: "avgCount",
              children: (
                <Customer
                  data={data?.weekAvgData}
                  className="col-span-12"
                  alias="Долоо хоног /дундаж/"
                  start={0}
                  end={1}
                  xField={"dayName"}
                  yField={"avgCount"}
                />
              ),
            },
            {
              label: "Дундаж дүн",
              key: "avgAmount",
              children: (
                <Customer
                  data={data?.weekAvgData}
                  className="col-span-12"
                  alias="Долоо хоног /дундаж/"
                  start={0}
                  end={1}
                  xField={"dayName"}
                  yField={"avgAmount"}
                  // seriesField={"type"}
                />
              ),
            },
            {
              label: "7 хоног/дүн/",
              key: "amount",
              children: (
                <Customer
                  data={data?.weekSumData}
                  className="col-span-12"
                  alias="Долоо хоног дүнгийн график"
                  start={0}
                  end={1}
                  xField={"weekNumber"}
                  yField={"amount"}
                />
              ),
            },
            {
              label: "7 хоног/Тоо/",
              key: "cnt",
              children: (
                <Customer
                  data={data?.weekSumData}
                  className="col-span-12"
                  alias="Долоо хоног дүнгийн график"
                  start={0}
                  end={1}
                  xField={"weekNumber"}
                  yField={"cnt"}
                />
              ),
            },
          ]}
        />
        <Customer
          data={data?.weekBookingData}
          className="col-span-12"
          alias="7 хоногоор"
          start={0}
          end={1}
        />
        <ServiceTable
          data={data?.serviceData}
          className="col-span-12 md:col-span-6 "
          title="MRI Дата"
          servicetypeid={1}
          // totalCount={500}
        />
        {serviceType == 2 || serviceType == -1 ? (
          <ServiceTable
            data={data?.serviceData}
            className="col-span-12 md:col-span-6 "
            title="CT Дата"
            servicetypeid={2}
          />
        ) : (
          ""
        )}

        {state?.userInfo?.profile.role === "Админ" ||
        // state?.userInfo?.profile.role === "CEO" ||
        state?.userInfo?.profile.role === "Ресейпшн" ? (
          <>
            {" "}
            <ExpenseIncomeLog
              data={data?.monthIncomeExpenseLog}
              className="col-span-12 md:col-span-6 "
            />{" "}
            <ExpenseIncome data={data} className="col-span-12 md:col-span-6 " />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
