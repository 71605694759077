import {
  EditOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  SearchOutlined,
  PrinterOutlined,
  CameraOutlined,
} from "@ant-design/icons";

import React, { useRef, useState } from "react";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";

export default ({
  currentPage = 1,
  doctors = [],
  serviceTypes,
  handleSetDoctor,
  role,
  handleResponse,
  handleCancel,
  handleEdit,
  handleCdCall,
  handleReceived,
  handleMore,
  messageApi,
  hiddenColumns,
  openPrint,
  openImage,
}) => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [fixed, setFixed] = useState("left");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm({ closeDropdown: false });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={dataIndex == "regno" ? `РД-р хайх` : `Нэрээр хайх`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            setSearchText(e.target.value);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Хайх
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Цэвэрлэх
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setFixed(fixed == false ? "left" : false);
            }}
          >
            Түгжих
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Хаах
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] == null
        ? ""
        : record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 70,
      fixed: "left",
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Үйлчлүүлэгчийн овог нэр",
      dataIndex: "customerFirstname",
      key: "customerFirstname",
      fixed: fixed,
      width: 150,

      render: (text) => <span>{text}</span>,
      ...getColumnSearchProps("customerFirstname"),
    },
    {
      title: "Регистер",
      dataIndex: "regno",
      key: "regno",
      width: 120,
      render: (text) => <span>{text}</span>,
      ...getColumnSearchProps("regno"),
    },
    {
      title: "Нас",
      dataIndex: "age",
      key: "age",
      width: 60,
    },
    {
      title: "Хүйс",
      dataIndex: "gender",
      key: "gender",
      width: 70,
      render: (age) => (
        <Tag color={age == 1 ? "green" : "pink"}>{age == 1 ? "Эр" : "Эм"}</Tag>
      ),
    },
    {
      title: "Хэвлэх",
      key: "print",
      width: 80,
      align: "center",
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button size="small" onClick={() => openPrint(row)}>
              <div className="flex items-center gap-2">
                <PrinterOutlined />
              </div>
            </Button>
          </div>
        );
      },
    },
    {
      title: "Зураг",
      key: "image",
      width: 80,
      align: "center",
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button
              className={row.bookingimages?.length > 0 ? "bg-green-600" : ""}
              size="small"
              onClick={() => openImage(row.id, row.status)}
            >
              <div className="flex items-center gap-2 ">
                <CameraOutlined />
              </div>
            </Button>
          </div>
        );
      },
    },
    {
      title: "Эмнэлэг",
      dataIndex: "hospitalName",
      key: "hospitalName",
      width: 120,
    },
    {
      title: "Төхөөрөмж",
      dataIndex: "serviceTypeName",
      key: "serviceTypeName",
      width: 110,
      render: (text, row) => (
        <span>
          {text} {text == "CT" ? "/" + row.device + "/" : ""}
        </span>
      ),
      filters: serviceTypes,
      onFilter: (value, record) => record.serviceTypeId === value,
    },

    {
      title: "Шинжлүүлэх эрхтэн",
      dataIndex: "serviceNames",
      width: 150,
      key: "serviceNames",
      render: (text, row) => (
        <span>{`${text}${row.isCe ? "+CE" : ""}${
          row.isAns ? "+ANS" : ""
        }`}</span>
      ),
    },
    {
      title: "Тодосгогч бодис",
      dataIndex: "ceitem",
      width: 120,
      key: "ceitem",
    },
    {
      title: "Огноо",
      dataIndex: "currentDateTime",
      width: 100,
      key: "currentDateTime",
      render: (text, row) => (
        <span> {moment(text).format("yyyy-MM-DD HH:mm")}</span>
      ),
    },
    {
      title: "Зовиур",
      dataIndex: "painful",
      key: "painful",
      width: 250,
      visible: role != "Ресейпшн",
      render: (text) => (
        <div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(text);
              messageApi.open({
                type: "success",
                content: "Зовиурын мэдээлэл хуулагдлаа",
                duration: 1,
              });
            }}
            icon={<CopyOutlined />}
          ></Button>{" "}
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        </div>
      ),
      onCell: () => {
        return {
          style: {
            // whiteSpace: "nowrap",
            maxWidth: 250,
          },
        };
      },
      ellipsis: true,
    },
    {
      title: "ЭМД",
      dataIndex: "insurance",
      width: 60,
      key: "insurance",
      render: (status) => (
        <Tag color={status == 1 ? "green" : "orange"}>
          {status == 1 ? "Тийм" : "Үгүй"}
        </Tag>
      ),
    },
    {
      title: "Тайлбар",
      dataIndex: "descr",
      key: "descr",
      width: 200,

      // visible: role != "Ресейпшн",

      render: (text, row) => (
        <div>
          {/* <Tooltip placement="topLeft" title={text}> */}
          {row.urgent ? <Tag color={"red"}>{"CITO"}</Tag> : ""}
          {row.addition ? <Tag color={"orange"}>{"Нэмэлттэй"}</Tag> : ""}
          {row.isEnglishResponse ? <Tag color={"red"}>{"Англи"}</Tag> : ""}
          {row.isRepeat ? <Tag color={"green"}>{"Давтан"}</Tag> : ""}
          {row.isBonus ? <Tag color={"pink"}>{"Bonus"}</Tag> : ""}

          {text}
          {/* </Tooltip> */}
        </div>
      ),
      onCell: () => {
        return {
          style: {
            // whiteSpace: "nowrap",
            maxWidth: 250,
          },
        };
      },
    },
    {
      title: "Техникч",
      dataIndex: "technicalUser",
      key: "technicalUser",
      // visible: role != "Ресейпшн",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Эмч",
      dataIndex: "doctor",
      width: 180,
      key: "doctor",
      ellipsis: true,
      filters: doctors,
      onFilter: (value, record) => record.doctorId === value,
      render: (text, row) => {
        return row.status != 1 &&
          (role == "Эмч" || role == "Эрхлэгч" || "Админ") ? (
          <Select
            className="mb-0 w-[150px]"
            value={row.doctorId}
            options={doctors}
            allowClear
            onChange={(e) => {
              handleSetDoctor(e, row, true);
            }}
          ></Select>
        ) : (
          <Tag color={"green"}>{text}</Tag>
        );
      },
    },
    {
      title: "Зөвлөх эмч",
      dataIndex: "doctor2",
      width: 180,
      key: "doctor2",
      ellipsis: true,
      filters: doctors,
      onFilter: (value, record) => record.doctor2Id === value,
      render: (text, row) => {
        return role == "Эмч" || role == "Эрхлэгч" || "Админ" ? (
          <Select
            className="mb-0 w-[150px]"
            value={row.doctor2Id}
            options={doctors}
            allowClear
            onChange={(e) => {
              handleSetDoctor(e, row, false);
            }}
          ></Select>
        ) : (
          <Tag color={"green"}>{text}</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      width: 100,
      visible: role == "Эмч" || role == "Эрхлэгч" || role == "Админ",
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button
              disabled={status}
              size="small"
              onClick={() => handleResponse(row)}
            >
              <div className="flex items-center gap-2">
                <CheckCircleOutlined /> Хариу
              </div>
            </Button>
          </div>
        );
      },
    },

    {
      title: "Гарсан эсэх",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (status) => (
        <Tag color={status == 1 ? "green" : status == 2 ? "red" : "orange"}>
          {status == 1 ? "Тийм" : status == 2 ? "Цуцалсан" : "Үгүй"}
        </Tag>
      ),
    },

    {
      title: "Гарсан цаг",
      dataIndex: "docHour",
      width: 80,
      key: "docHour",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 210,
      visible: role != "Ресейпшн",
      render: (text, row) => (
        <div className="flex items-center gap-2">
          <Button
            size="small"
            onClick={() => handleCancel(row)}
            hidden={role == "Эмч" || role == "Эрхлэгч"}
          >
            <div className="flex items-center gap-2">
              <DeleteOutlined /> Цуцлах
            </div>
          </Button>
          <Button size="small" onClick={() => handleEdit(row)}>
            <div className="flex items-center gap-2">
              <EditOutlined /> Засах
            </div>
          </Button>
          {/* <Button size="small" onClick={() => handleMore(row)}>
            <div className="flex items-center gap-2">Дэлгэрэнгүй</div>
          </Button> */}
        </div>
      ),
    },
    {
      title: "Хариу авах утас",
      dataIndex: "answerPhone",
      key: "answerPhone",
      width: 100,
    },
    {
      title: "Reception",
      dataIndex: "reception",
      width: 120,
      key: "reception",
      visible: role != "Эмч" && role != "Эрхлэгч",
    },
    {
      title: "Action",

      visible: role == "Ресейпшн" || "Админ" || "Техникч",
      width: 90,
      render: (isCd, row) => (
        <div className="flex items-center gap-5">
          <Button size="small" onClick={() => handleCdCall(row)}>
            <div className="flex items-center gap-2">CD&Call</div>
          </Button>
        </div>
      ),
    },
    {
      title: "Үнийн дүн",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      ellipsis: true,
      visible: role == "Ресейпшн" || role == "Админ",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },

    {
      title: "Емэйлээр",
      dataIndex: "ebarimtStatus",
      key: "ebarimtStatus",
      width: 80,
      visible: role != "Эмч" && role != "Эрхлэгч",
      render: (val, row, rowIndex) =>
        row.isReceived ? (
          <Checkbox checked={val}></Checkbox>
        ) : (
          <Checkbox
            onChange={(e) => {
              row.ebarimtStatus = e.target.checked;
            }}
          ></Checkbox>
        ),
    },
    {
      title: "Хүлээж авсан хүн",
      dataIndex: "receiver",
      key: "receiver",
      width: 150,
      visible: role != "Эмч" && role != "Эрхлэгч",
      render: (text, row) =>
        row.isReceived ? (
          <span>{text}</span>
        ) : (
          <Input
            // value={row.receiver}
            onChange={(e) => {
              row.receiver = e.target.value;
            }}
          />
        ),
      onCell: () => {
        return {
          style: {
            // whiteSpace: "nowrap",
            maxWidth: 250,
          },
        };
      },
      ellipsis: true,
    },
    {
      title: "Хүлээж авсан",
      dataIndex: "isReceived",
      key: "isReceived",
      visible: role != "Эмч" && role != "Эрхлэгч",
      width: 100,
      render: (isReceived, row) => (
        <div className="flex items-center gap-5">
          <Button
            disabled={isReceived}
            size="small"
            onClick={() => handleReceived(row)}
          >
            <div className="flex items-center gap-2">
              <CheckCircleOutlined /> Авсан
            </div>
          </Button>
        </div>
      ),
    },
    {
      title: "Тэмдэглэл",
      dataIndex: "receptionNote",
      key: "receptionNote",
      width: 150,
      ellipsis: true,
      visible: role == "Ресейпшн" || role == "Админ" || "Техникч",
      render: (text, row) => (
        <div>
          {row.isCd ? <Tag color={"red"}>{"CD"}</Tag> : ""}
          {row.isCall ? <Tag color={"green"}>{"Call"}</Tag> : ""}
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        </div>
      ),
      onCell: () => {
        return {
          style: {
            // whiteSpace: "nowrap",
            maxWidth: 150,
          },
        };
      },
    },
    {
      title: "Тайлбар",
      dataIndex: "descr",
      key: "descr",
      width: 100,
      visible: role == "Ресейпшн",
      render: (text, row) => (
        <div>
          {row.urgent ? <Tag color={"red"}>{"CITO"}</Tag> : ""}
          {row.addition ? <Tag color={"orange"}>{"Нэмэлттэй"}</Tag> : ""}
          {row.isBonus ? <Tag color={"pink"}>{"Bonus"}</Tag> : ""}
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        </div>
      ),
    },
  ];
  const showedColumns = columns.map((column) => {
    if (hiddenColumns.includes(column.title)) {
      return {
        ...column,
        visible: false,
      };
    } else {
      return column;
    }
  });
  return showedColumns;
};
export function columnNames() {
  return [
    {
      value: "Регистер",
      labe: "Регистер",
    },
    { value: "Нас", label: "Нас" },
    { value: "Хүйс", label: "Хүйс" },
    { value: "Хэвлэх", label: "Хэвлэх" },
    { value: "Эмнэлэг", label: "Эмнэлэг" },
    { value: "Төхөөрөмж", label: "Төхөөрөмж" },
    { value: "Шинжлүүлэх эрхтэн", label: "Шинжлүүлэх эрхтэн" },
    { value: "Тодосгогч бодис", label: "Тодосгогч бодис" },
    { value: "Огноо", label: "Огноо" },
    { value: "Зовиур", label: "Зовиур" },
    { value: "ЭМД", label: "ЭМД" },
    { value: "Тайлбар", label: "Тайлбар" },
    { value: "Техникч", label: "Техникч" },
    { value: "Эмч", label: "Эмч" },
    { value: "Зөвлөх эмч", label: "Зөвлөх эмч" },
    { value: "Гарсан эсэх", label: "Гарсан эсэх" },
    { value: "Гарсан цаг", label: "Гарсан цаг" },
    { value: "Хариу авах утас", label: "Хариу авах утас" },
    { value: "Reception", label: "Reception" },
    { value: "Емэйлээр", label: "Емэйлээр" },
    { value: "Хүлээж авсан хүн", label: "Хүлээж авсан хүн" },
    { value: "Хүлээж авсан", label: "Хүлээж авсан" },
    { value: "Тэмдэглэл", label: "Тэмдэглэл" },
    { value: "Action", label: "Action" },
  ];
}
