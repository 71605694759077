import { Column } from "@ant-design/charts";
import React from "react";

function Customer({
  data,
  className = "",
  alias = "Үйлчлүүлэгчид",
  start = 0.5,
  end = 1,
  xField = "date",
  yField = "cnt",
  seriesField = "type",
}) {
  const config = {
    data: data ? data : [],
    xField: xField,
    yField: yField,
    seriesField: seriesField,
    isStack: true,
    color: ({ type }) => {
      if (type === "ANSWERED") {
        return "#ff9255";
      }
      if (type === "NO ANSWER") {
        return "#5085ed";
      }
      if (type === "FAILED") {
        return "#8217DB";
      }
    
      return "#0A8449";
    },
    legend: {
      position: "top",

      itemName: {
        formatter: (text) => {
          const oldLabel = text;
          const labelLength = oldLabel.length;
          let newLabel = "";
          if (labelLength > 6) {
            const firstStr = oldLabel.substr(0, 6);
            const lastStr = oldLabel.substr(6);
            newLabel = `${firstStr}\n${lastStr}`;
          } else {
            newLabel = oldLabel;
          }
          return newLabel;
        },
      },
    },
    slider: {
      start: start,
      end: end,
    },
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "#FFFFFF",
      },
      formatter: (val) => {
        if (val[yField] > 1000000)
          return (val[yField] / 1000000).toFixed(1) + "\nсая";
        return val[yField].toLocaleString();
      },
    },

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      cnt: {
        alias: alias,
      },
    },
  };

  return (
    <div className={`bg-white rounded-lg p-3 ${className}`}>
      <div className="font-semibold mb-4">{alias}</div>
      <Column {...config} height={250} />
    </div>
  );
}

export default Customer;
