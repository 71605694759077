import React, { useEffect, useState } from "react";
import { Upload, Modal, Button, } from "antd";
import {
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import Compressor from "compressorjs";
import { instance } from "utils/axios";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const CustomerImages = ({ open = false, bookingId, handleOpenImage, role }) => {
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modal, contextHolder] = Modal.useModal();
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  const fetchData = () => {
    instance({
      method: "get",
      url: `/booking/images/${bookingId}`,
    }).then((res) => {
      const tmp = res.data.map((item, index) => ({
        id: item.id,
        name: "image.png",
        status: "done",
        url: `${process.env.REACT_APP_CDN_URL}${item.imageUrl}`,
        index,
      }));
      setFileList(tmp);
      setCurrentIndex(0);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setCurrentIndex(file.index);
    setZoom(1); // Reset zoom
    setRotate(0); // Reset rotation
  };

  const handleChange = ({ fileList: newFileList, file }) => {
    if (file.status === "removed") {
      if (role === "Эмч" || role === "Эрхлэгч") return;

      modal.confirm({
        title: "Анхаарна уу !",
        content: "Зураг устгахтаа итгэлтэй байна уу",
        okText: "Тийм",
        cancelText: "Үгүй",
        onOk() {
          return new Promise((resolve) => {
            const id = file.id || file.response?.id;
            instance({
              method: "delete",
              url: `/booking/image`,
              data: { id },
            }).then(() => {
              resolve("ok");
              setFileList(newFileList);
            });
          });
        },
        onCancel: fetchData,
      });
    } else if (file.status === "error") {
      alert(JSON.stringify(file));
    }

    setFileList(newFileList);
  };

  const uploadAction = (file) => {
    if (role === "Эмч" || role === "Эрхлэгч") return;

    new Compressor(file.file, {
      quality: 0.6,
      mimeType: "image/jpeg",
      success(result) {
        const formData = new FormData();
        formData.set("image", result);

        instance(`/booking/image/${bookingId}`, {
          method: "post",
          data: formData,
        }).then(fetchData);
      },
      error(err) {
        alert(JSON.stringify(err));
        throw new Error("Upload failed");
      },
    });
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleNext = () => {
    if (currentIndex < fileList.length - 1) {
      const nextIndex = currentIndex + 1;
      setPreviewImage(fileList[nextIndex].url);
      setCurrentIndex(nextIndex);
      setZoom(1); // Reset zoom
      setRotate(0); // Reset rotation
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setPreviewImage(fileList[prevIndex].url);
      setCurrentIndex(prevIndex);
      setZoom(1); // Reset zoom
      setRotate(0); // Reset rotation
    }
  };

  const zoomIn = () => setZoom(zoom + 0.1);
  const zoomOut = () => setZoom(zoom > 0.1 ? zoom - 0.1 : zoom);
  const rotateRight = () => setRotate(rotate + 90);

  return (
    <Modal
      title={"Зураг"}
      open={open}
      destroyOnClose
      centered
      width={1000}
      okButtonProps={{ hidden: true }}
      cancelText="Хаах"
      onCancel={() => {
        setPreviewImage("");
        handleOpenImage(false);
      }}
    >
      <Upload
        customRequest={uploadAction}
        listType="picture-card"
        name="image"
        accept="image/png, image/jpeg"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                transform: `scale(${zoom}) rotate(${rotate}deg)`,
              }}
              src={previewImage}
            />
            <div style={{ marginTop: "10px" }}>
              <Button
                shape="circle"
                style={{ margin: "0 10px" }}
                icon={<LeftOutlined />}
                onClick={handlePrev}
                disabled={currentIndex === 0}
              />
              <Button icon={<ZoomInOutlined />} onClick={zoomIn} />
              <Button
                icon={<ZoomOutOutlined />}
                onClick={zoomOut}
                style={{ margin: "0 10px" }}
              />
              <Button icon={<RotateRightOutlined />} onClick={rotateRight} />
              <Button
                style={{ margin: "0 10px" }}
                shape="circle"
                icon={<RightOutlined />}
                onClick={handleNext}
                disabled={currentIndex === fileList.length - 1}
              />
            </div>
          </div>
        </div>
      )}
      {contextHolder}
    </Modal>
  );
};

export default CustomerImages;
