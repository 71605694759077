const reducer = (prevState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...prevState,
        token: action.token,
        userInfo: action.userInfo,
      };
    case "LOGOUT":
      return {
        ...prevState,
        token: null,
        userInfo: null,
      };
    case "CHANGE_TAB_INDEX_SETTING":
      return {
        ...prevState,
        tabIndexSetting: action.index,
      };
    case "CHANGE_LOGO":
      return {
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          profile: {
            ...prevState.userInfo.profile,
            hospitallogo: action.image,
          },
        },
      };
    case "CHANGE_SIDEBAR_WIDTH":
      return {
        ...prevState,
        sidebarWidth: action.sidebarWidth,
        sidebarCollapsed: action.sidebarCollapsed,
      };
    case "CHANGE_SIDEBAR_KEY":
      return {
        ...prevState,
        sidebarKey: action.sidebarKey,
      };
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        token: action.token,
        userInfo: action.userInfo,
        loading: false,
      };
    default:
      return prevState;
  }
};

export default reducer;
