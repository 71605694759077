const events = [
  { title: "Заяадэлгэр", start: getDate("YEAR-MONTH-02") },
  { title: "Болор", start: getDate("YEAR-MONTH-02") },
  {
    title: "Болор",
    start: getDate("YEAR-MONTH-06"),
    backgroundColor: "green",
    textColor: "black",
  },
  { title: "Заяадэлгэр", start: getDate("YEAR-MONTH-07") },
  { title: "Болор", start: getDate("YEAR-MONTH-07") },
  {
    title: "Заяадэлгэр",
    start: getDate("YEAR-MONTH-09"),
    backgroundColor: "yellow",
    textColor: "black",
  },
  {
    title: "Туяа, 62/эм ",
    description:
      ", DS: HCC in right liver, Oper: Right hepatectomy, 88220729, 88937247  ",
    age: "3343",
    start: getDate("YEAR-MONTH-19"),
    backgroundColor: "purple",
    textColor: "black",
    // extendedProps: {
    //   description: "BioChemistry",
    // },
    // description: "Lecture",
    // default: false,
  },

  { title: "Бат", start: getDate("YEAR-MONTH-28"), backgroundColor: "orange" },
  {
    title: "Ард",
    start: getDate("YEAR-MONTH-22"),
    backgroundColor: "azure",
    textColor: "black",
  },
  {
    start: getDate("YEAR-MONTH-23"),
    end: getDate("YEAR-MONTH-24"),
    allDay: true,
    textColor: "black",
  },
];

function getDate(dayString) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
}

export default events;
