import React, { useEffect, useState } from 'react';
import { Button, Modal, Tag, Form as AntForm } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import fields from './fields';
import { Link } from 'react-router-dom';

function IncomeType() {
  const [ data, setData ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ open, setOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  },[currentPage])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/incometype`
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Төрөл',
      dataIndex: 'name',
      key: 'name',
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Button size='small' onClick={() => handleEdit(row)}>
          <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
        </Button>
      )
    },
  ]

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/incometype',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {
        
      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/incometype',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }

  return (
      <div className='flex flex-col px-4 pb-4'>
        <MainTable
          dataTable={data} 
          columns={columns} 
          setCurrentPage={setCurrentPage} 
          loading={loading} 
          pagination={true}
          title={"Орлогын төрөл"}
          handleAdd={handleAdd}
        />
        <Modal 
          title={editData ? ' төрөл засах' : 'Орлогын төрөл нэмэх'} 
          open={open} 
          destroyOnClose 
          width={600}
          okText='Хадгалах'
          confirmLoading={loading}
          onOk={form.submit}
          onCancel={loading ? null : handleCloseModal}
          cancelButtonProps={{disabled: loading}}
        >
          <Form
            form={form}
            fields={fields()}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
      </div>
  )
}

export default IncomeType