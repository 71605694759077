import {
  EditOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import React, { useRef, useState } from "react";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";

export default ({
  currentPage = 1,
  serviceTypes,
  handleCancel,
  handleEdit,
  handleConfirm,
  handleReceived,
  hiddenColumns,
}) => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm({ closeDropdown: false });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={dataIndex == "regno" ? `РД-р хайх` : `Нэрээр хайх`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            setSearchText(e.target.value);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Хайх
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Цэвэрлэх
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Хаах
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null
        ? record[dataIndex].toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 80,
      fixed: "left",
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },

    {
      title: "З/Дугаар",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 80,
      render: (text, row) => <span>{row.id > 0 ? text : ""}</span>,
    },
    {
      title: "Үйлчлүүлэгчийн овог нэр",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      width: 150,
      render: (text) => <span>{text}</span>,
      ...getColumnSearchProps("customerName"),
    },
    {
      title: "Регистер",
      dataIndex: "regno",
      key: "regno",
      // fixed: "left",
      width: 120,
      render: (text) => <span>{text}</span>,
      ...getColumnSearchProps("regno"),
    },
    {
      title: "Огноо",
      dataIndex: "bookingDateTime",
      width: 120,
      key: "bookingDateTime",

      render: (text, row) => (
        <Tag color={row.id > 0 ? "green" : "red"}>
          <span>{moment(text).format("YYYY-MM-DD")}</span>
        </Tag>
      ),
    },
    {
      title: "Цаг",
      dataIndex: "bookingDateTime",
      width: 80,

      key: "bookingDateTime",
      render: (text, row) => (
        <Tag color={row.id > 0 ? "green" : "red"}>
          <span>{moment(text).format("HH:mm")}</span>
        </Tag>
      ),
    },
    {
      title: "Төлбөрийн төрөл",
      dataIndex: "paymentType",
      key: "paymentType",
      width: 110,
      render: (text) =>
        text ? (
          <Tag
            color={
              text == "pay"
                ? "green"
                : text == "insurance70"
                ? "blue"
                : "orange"
            }
          >
            {text == "pay"
              ? "Төлбөр"
              : text == "insurance70"
              ? "Даатгал*30%"
              : "Даатгал*100%"}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Шинжлүүлэх эрхтэн",
      dataIndex: "serviceNames",
      width: 150,
      key: "serviceNames",
      render: (text, row) =>
        text ? (
          <span>{`${row.serviceTypeName} ${text}${row.isCe ? "+CE" : ""}${
            row.isAns ? "+ANS" : ""
          }`}</span>
        ) : (
          ""
        ),
    },

    {
      title: "Үнийн дүн",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      width: 130,
      filters: [
        {
          text: "Захиалсан",
          value: 0,
        },
        {
          text: "Баталгаажуулсан",
          value: 1,
        },
        {
          text: "Орсон",
          value: 3,
        },
        {
          text: "Хариу гарсан",
          value: 4,
        },
        {
          text: "Өгсөн",
          value: 5,
        },

        {
          text: "Хоосон",
          value: -1,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, row) =>
        row.id > 0 ? (
          <Tag
            color={
              status == 1
                ? "green"
                : status == 3
                ? "blue"
                : status == 4
                ? "red"
                : "orange"
            }
          >
            {status == 1
              ? "Баталгаажуулсан"
              : status == 2
              ? "Цуцалсан"
              : status == 3
              ? "Орсон"
              : status == 4
              ? "Хариу гарсан"
              : status == 5
              ? "Өгсөн"
              : "Захиалсан"}
          </Tag>
        ) : (
          <Tag color="red"> Хоосон цаг</Tag>
        ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 350,

      render: (text, row) =>
        row.id ? (
          <div className="flex items-center gap-2">
            <Button size="small" onClick={() => handleConfirm(row)}>
              <div className="flex items-center gap-2">
                <CheckCircleOutlined /> Баталгаажуулах
              </div>
            </Button>
            <Button size="small" onClick={() => handleCancel(row)}>
              <div className="flex items-center gap-2">
                <DeleteOutlined /> Цуцлах
              </div>
            </Button>
            <Button size="small" onClick={() => handleEdit(row)}>
              <div className="flex items-center gap-2">
                <EditOutlined /> Засах
              </div>
            </Button>
          </div>
        ) : (
          <Button size="small" onClick={() => handleEdit(row)}>
            <div className="flex items-center gap-2">
              <EditOutlined /> Нэмэх
            </div>
          </Button>
        ),
    },

    {
      title: "Хариу авах утас",
      dataIndex: "answerPhone",
      key: "answerPhone",
      width: 150,
      render: (text, row) => (
        <span>
          {text} {row.answerPhone2}{" "}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "answerMail",
      width: 120,
      key: "answerMail",
    },
    {
      title: "Тайлбар",
      dataIndex: "descr",
      key: "descr",
      width: 200,

      // visible: role != "Ресейпшн",

      render: (text, row) => (
        <div>
          {/* <Tooltip placement="topLeft" title={text}> */}
          {row.urgent ? <Tag color={"red"}>{"CITO"}</Tag> : ""}
          {row.addition ? <Tag color={"orange"}>{"Нэмэлттэй"}</Tag> : ""}
          {row.isEnglishResponse ? <Tag color={"red"}>{"Англи"}</Tag> : ""}
          {row.isRepeat ? <Tag color={"green"}>{"Давтан"}</Tag> : ""}
          {row.isBonus ? <Tag color={"pink"}>{"Bonus"}</Tag> : ""}
          {text}
          {/* </Tooltip> */}
        </div>
      ),
      onCell: () => {
        return {
          style: {
            // whiteSpace: "nowrap",
            maxWidth: 250,
          },
        };
      },
      // ellipsis: true,
    },
    {
      title: "Илгээсэн эмч",
      dataIndex: "sendDoctor",
      key: "sendDoctor",
      width: 200,
    },
    {
      title: "Reception",
      dataIndex: "reception",
      width: 120,
      key: "reception",
    },

    {
      title: "Үнийн дүн",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      ellipsis: true,
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },

    // {
    //   title: "Email",
    //   dataIndex: "answerEmail",
    //   key: "answerEmail",
    //   width: 120,
    // },
    {
      title: "Хүлээж авсан хүн",
      dataIndex: "receiver",
      key: "receiver",
      width: 150,
      render: (text, row) =>
        row.isReceived ? (
          <span>{text}</span>
        ) : (
          <Input
            // value={row.receiver}
            onChange={(e) => {
              row.receiver = e.target.value;
            }}
          />
        ),
      onCell: () => {
        return {
          style: {
            // whiteSpace: "nowrap",
            maxWidth: 250,
          },
        };
      },
      ellipsis: true,
    },
    {
      title: "Хүлээж авсан",
      dataIndex: "isReceived",
      key: "isReceived",
      width: 100,
      render: (isReceived, row) => (
        <div className="flex items-center gap-5">
          <Button
            disabled={isReceived}
            size="small"
            onClick={() => handleReceived(row)}
          >
            <div className="flex items-center gap-2">
              <CheckCircleOutlined /> Авсан
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const showedColumns = columns.map((column) => {
    if (hiddenColumns.includes(column.title)) {
      return {
        ...column,
        visible: false,
      };
    } else {
      return column;
    }
  });
  return showedColumns;
};
export function columnNames() {
  return [
    { label: "З/Дугаар", value: "З/Дугаар" },
    { label: "Үйлчлүүлэгчийн овог нэр", value: "Үйлчлүүлэгчийн овог нэр" },
    { label: "Регистер", value: "Регистер" },
    { label: "Огноо", value: "Огноо" },
    { label: "Цаг", value: "Цаг" },
    { label: "Төлбөрийн төрөл", value: "Төлбөрийн төрөл" },
    { label: "Шинжлүүлэх эрхтэн", value: "Шинжлүүлэх эрхтэн" },
    { label: "Үнийн дүн", value: "Үнийн дүн" },
    { label: "Төлөв", value: "Төлөв" },
    { label: "(Empty)", value: "(Empty)" },
    { label: "Хариу авах утас", value: "Хариу авах утас" },
    { label: "Email", value: "Email" },
    { label: "Тайлбар", value: "Тайлбар" },
    { label: "Илгээсэн эмч", value: "Илгээсэн эмч" },
    { label: "Reception", value: "Reception" },
    { label: "Хүлээж авсан хүн", value: "Хүлээж авсан хүн" },
    { label: "Хүлээж авсан", value: "Хүлээж авсан" },
  ];
}
