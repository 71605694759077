import React, { useEffect, useState } from "react";
import { AuthContext } from "contexts";
import menu from "../../menu";
import { Menu } from "antd";
import { IoChevronBack } from "react-icons/io5";
import logo from "../../../assets/images/logo2.png";

const Sidebar = () => {
  const { state, action } = React.useContext(AuthContext);
  const [roleMenu, setRoleMenu] = useState([]);

  useEffect(() => {
    let tmp = [];
    menu.map((item) => {
      if (
        item?.role.includes(state.userInfo?.profile.role) &&
          (item?.hospital.includes(state.userInfo?.profile.hospitalId) ||
           item?.hospital.includes(state.userInfo?.profile.hospitalSecondId))
      ) {
        tmp.push(item);
      }
    });
    setRoleMenu(tmp);
  }, [state.userInfo]);

  const handleClickMenu = (e) => {
    action.handleSidebarKey(e.key);
  };

  const handleChangeCollapse = () => {
    action.handleSidebarCollapse(!state.sidebarCollapsed);
  };

  return (
    <div className="sidebar w-full overflow-hidden">
      <div className="flex justify-end">
        <button
          className="p-4 bg-transparent cursor-pointer"
          onClick={handleChangeCollapse}
        >
          <IoChevronBack
            size={20}
            className={`transition-all duration-500 ${
              state.sidebarCollapsed ? "rotate-180" : "rotate-0"
            }`}
          />
        </button>
      </div>
      <div className="flex justify-center">
        <img
          src={
            state.userInfo?.profile.hospitalId == 1
              ? `https://images-ext-1.discordapp.net/external/18m9Nov9RgLBQ3tPLU-MjIomlfjHulI1FoyXxR5FVVI/https/i.ibb.co/HpMck2m/MED-TRAUMA-02-1-removebg-preview.png`
              : logo
          }
          height={state.sidebarCollapsed ? 50 : 100}
          alt="logo"
        />
      </div>
      <Menu
        defaultSelectedKeys={["menu-item-0"]}
        mode={"inline"}
        theme={"light"}
        selectedKeys={[state.sidebarKey]}
        onClick={handleClickMenu}
        className="text-base"
        key={"menu"}
        inlineCollapsed={state.sidebarCollapsed}
        items={roleMenu}
      />
    </div>
  );
};

export default Sidebar;
