import { Form, Input, Select } from "antd";

export default () => {
  return [
    {
      label: "Овог",
      name: "lastname",
      rules: [{ required: true, message: "Овог оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэр",
      name: "firstname",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Регистрийн дугаар",
      name: "regno",
      // rules: [{ required: true, message: "Регистрийн дугаар оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    // {
    //   label: 'Төрсөн огноо',
    //   name: 'dob',
    //   type: 'date',
    //   rules: [{required: true, message: 'Төрсөн огноо сонгоно уу!'}],
    //   className: 'col-span-12 md:col-span-6 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //   }
    // },
    {
      label: "Утасны дугаар",
      name: "mobile",
      // rules: [{ required: true, message: "Утасны дугаар оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хүйс",
      name: "gender",
      type: "select",
      rules: [{ required: true, message: "Хүйс сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Эр", value: 1 },
          { label: "Эм", value: 0 },
        ],
      },
    },
    {
      label: "Харшилтай эсэх",
      name: "hasAllergy",
      type: "select",
      rules: [{ required: true, message: "Харшилтай эсэх сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Тийм", value: 1 },
          { label: "Үгүй", value: 0 },
        ],
      },
    },
    {
      label: "Нэмэлт тайлбар",
      name: "descr",
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      type: "component",
      component: (
        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) => prev.hasAllergy !== cur.hasAllergy}
        >
          {({ getFieldValue }) => {
         
            return getFieldValue("hasAllergy") === 1 ? (
              <Form.Item
                label="Харшлын тайлбар"
                name="allergyDescr"
                rules={[
                  { required: true, message: "Харшлын тайлбар оруулна уу!" },
                ]}
                className="col-span-12 md:col-span-6 mb-0"
              >
                <Input.TextArea className="w-full" />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
      ),
    },
  ];
};
