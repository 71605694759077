import {
  EditOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  SearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

import React, { useRef, useState } from "react";
import {
  Button,
  Tag,
  Tooltip,
  Select,
  Space,
  Input,
  Checkbox,
  TimePicker,
} from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";

export default ({
  currentPage = 1,
  doctors = [],
  handleSetDoctor,
  role,
  handleResponse,
  handleCancel,
  handleEdit,
  handleOpenPhysician,
  handleOpenTechnician,
  openPrint,
}) => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [fixed, setFixed] = useState("left");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm({ closeDropdown: false });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={dataIndex == "regno" ? `РД-р хайх` : `Нэрээр хайх`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            setSearchText(e.target.value);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Хайх
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Цэвэрлэх
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setFixed(fixed == false ? "left" : false);
            }}
          >
            Түгжих
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Хаах
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] == null
        ? ""
        : record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  return [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 70,
      fixed: "left",
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Үйлчлүүлэгчийн овог нэр",
      dataIndex: "customerName",
      key: "customerName",
      fixed: fixed,
      width: 150,

      render: (text) => <span>{text}</span>,
      ...getColumnSearchProps("customerName"),
    },
    {
      title: "Огноо",
      dataIndex: "currentDate",
      width: 100,
      key: "currentDate",
      render: (text, row) => <span> {moment(text).format("yyyy-MM-DD")}</span>,
    },
    {
      title: "Регистер",
      dataIndex: "regno",
      key: "regno",
      width: 120,
      render: (text) => <span>{text}</span>,
      ...getColumnSearchProps("regno"),
    },
    {
      title: "Нас",
      dataIndex: "age",
      key: "age",
      width: 60,
    },

    {
      title: "Хүйс",
      dataIndex: "gender",
      key: "gender",
      width: 70,
      render: (age) => (
        <Tag color={age == 1 ? "green" : "pink"}>{age == 1 ? "Эр" : "Эм"}</Tag>
      ),
    },
    {
      title: "Хэвлэх",

      key: "print",
      width: 80,

      align: "center",
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button size="small" onClick={() => openPrint(row)}>
              <div className="flex items-center gap-2">
                <PrinterOutlined />
              </div>
            </Button>
          </div>
        );
      },
    },
    {
      title: "Жин",
      dataIndex: "weight",
      key: "weight",
      width: 70,
    },
    {
      title: "Өндөр",
      dataIndex: "height",
      key: "height",
      width: 110,
    },
    {
      title: "Contact number",
      dataIndex: "answerPhone",
      key: "answerPhone",
      // visible: role != "Ресейпшн",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Dx",
      dataIndex: "dx",
      key: "dx",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Aim",
      dataIndex: "aim",
      width: 150,
      key: "aim",
    },
    {
      title: "Pathological result",
      dataIndex: "pathologicalResult",
      key: "pathologicalResult",
      width: 150,
    },
    {
      title: "Repeat",
      dataIndex: "repeatNote",
      key: "repeatNote",
      width: 150,
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: 200,
    },
    {
      title: "Шинжлүүлэх эрхтэн",
      dataIndex: "serviceNames",
      width: 150,
      key: "serviceNames",
    },
    {
      title: "Card",
      dataIndex: "isCard",
      key: "isCard",
      width: 80,
    },
    // {
    //   title: "isCd",
    //   dataIndex: "isCd",
    //   key: "isCd",
    //   width: 80,
    // },
    {
      title: "Шинжилгээ",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button
              disabled={status}
              size="small"
              onClick={() => handleOpenPhysician(row)}
            >
              <div className="flex items-center gap-2">
                <CheckCircleOutlined /> Injection
              </div>
            </Button>
          </div>
        );
      },
    },

    {
      title: "Time",
      dataIndex: "npTime",
      key: "npTime",
      width: 80,
      render: (text, row) => (
        <span> {text ? moment(text).format("HH:mm") : ""}</span>
      ),
    },
    {
      title: "mCI",
      dataIndex: "npDoseMCi",
      key: "npDoseMCi",
      width: 80,
    },
    {
      title: "MBq",
      dataIndex: "npDoseMBq",
      key: "npDoseMBq",
      width: 80,
    },
    {
      title: "Time of injection",
      dataIndex: "timeOfInjection",
      key: "timeOfInjection",
      width: 100,
      render: (text, row) => (
        <span> {text ? moment(text).format("HH:mm") : ""}</span>
      ),
    },
    {
      title: "Үзлэгийн эмч",
      dataIndex: "petDoctor",
      key: "petDoctor",
      width: 150,
    },
    {
      title: "Nuclear physician",
      dataIndex: "nuclearPhysician",
      key: "nuclearPhysician",
      width: 120,
    },
    {
      title: "Time of procedure done",
      dataIndex: "currentDateTime",
      key: "currentDateTime",
      width: 100,
      render: (text, row) => (
        <span> {text ? moment(text).format("HH:mm") : ""}</span>
      ),
    },

    {
      title: "SUM - minute",
      dataIndex: "sumNPTime",
      key: "sumNPTime",
      width: 100,
    },
    {
      title: "Техникч",
      dataIndex: "status",
      key: "status",
      width: 120,
      visible: role == "Техникч" || role == "Админ",
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button
              disabled={status}
              size="small"
              onClick={() => handleOpenTechnician(row)}
            >
              <div className="flex items-center gap-2">
                <CheckCircleOutlined /> Техникч
              </div>
            </Button>
          </div>
        );
      },
    },

    {
      title: "Technician",
      dataIndex: "technicalUser",
      key: "technicalUser",
      width: 120,
    },

    {
      title: "Report",
      dataIndex: "isCe",
      key: "isCe",
      width: 80,
    },
    {
      title: "Эмч",
      dataIndex: "doctor",
      width: 200,
      key: "doctor",
      ellipsis: true,
      filters: doctors,
      onFilter: (value, record) => record.doctorId === value,
      render: (text, row) => {
        return row.status != 1 ? ( //&& (role == "Эмч" || role == "Эрхлэгч")
          <Select
            className="mb-0 w-[150px]"
            value={row.doctorId}
            options={doctors}
            allowClear
            onChange={(e) => {
              handleSetDoctor(e, row, true);
            }}
          ></Select>
        ) : (
          <Tag color={"green"}>{text}</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      width: 100,
      visible: role == "Эмч" || role == "Эрхлэгч" || role == "Админ",
      render: (status, row) => {
        return (
          <div className="flex items-center gap-5">
            <Button
              disabled={status}
              size="small"
              onClick={() => handleResponse(row)}
            >
              <div className="flex items-center gap-2">
                <CheckCircleOutlined /> Хариу
              </div>
            </Button>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 210,
      render: (text, row) => (
        <div className="flex items-center gap-2">
          <Button size="small" onClick={() => handleCancel(row)}>
            <div className="flex items-center gap-2">
              <DeleteOutlined /> Цуцлах
            </div>
          </Button>
          <Button size="small" onClick={() => handleEdit(row)}>
            <div className="flex items-center gap-2">
              <EditOutlined /> Засах
            </div>
          </Button>
          {/* <Button size="small" onClick={() => handleMore(row)}>
            <div className="flex items-center gap-2">Дэлгэрэнгүй</div>
          </Button> */}
        </div>
      ),
    },
  ];
};
