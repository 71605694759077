export default [
  {
    label: 'Дүгнэлт',
    name: 'response',
    rules: [{required: true, message: 'Хариу авах мейл оруулна уу!'}],
    className: 'col-span-12 mb-0',
    type: 'editor',
    inputProps: {
      className: 'w-full',
    }
  },
]