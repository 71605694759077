import { DatePicker, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Expense from "./expense";
import Income from "./income";
import { instance } from "utils/axios";
import moment from "moment";

function Budget() {
  const [incomeType, setIncomeType] = useState([]);
  const [expenseType, setExpenseType] = useState([]);
  const [expense, setExpense] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));

  useEffect(() => {
    fetchExpenseType();
    fetchIncomeType();
    fetchExpense();
  }, []);

  const fetchExpenseType = () => {
    instance({
      method: "get",
      url: `/expensetype`,
    })
      .then((res) => {
        setExpenseType(
          res.data.map((type) => ({ value: type.id, label: type.name }))
        );
      })
      .catch((err) => {});
  };
  const fetchExpense = () => {
    instance({
      method: "get",
      url: `/expense`,
    })
      .then((res) => {
        setExpense(
          res.data.map((exs) => ({
            value: exs.id,
            label: exs.name,
            typeId: exs.typeId,
          }))
        );
      })
      .catch((err) => {});
  };

  const fetchIncomeType = () => {
    instance({
      method: "get",
      url: `/incometype`,
    })
      .then((res) => {
        setIncomeType(
          res.data.map((type) => ({ value: type.id, label: type.name }))
        );
      })
      .catch((err) => {});
  };

  const items = [
    {
      label: "Зарлага",
      key: "expense",
      children: (
        <Expense
          expenseType={expenseType}
          expense={expense}
          startDate={startDate}
          endDate={endDate}
        />
      ),
    },
    {
      label: "Орлого",
      key: "income",
      children: (
        <Income
          incomeType={incomeType}
          startDate={startDate}
          endDate={endDate}
        />
      ),
    },
  ];
  return (
    <div className="p-4">
      <div className="bg-white rounded-md p-5">
        <div className="flex gap-5 mb-3">
          <div>
            Эхлэх огноо:{" "}
            <DatePicker value={startDate} onChange={(e) => setStartDate(e)} />
          </div>
          <div>
            Дуусах огноо:{" "}
            <DatePicker value={endDate} onChange={(e) => setEndDate(e)} />
          </div>
        </div>
        <Tabs items={items} />
      </div>
    </div>
  );
}

export default Budget;
