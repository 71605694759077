import React, { useState } from "react";
import { MainTable } from "components";
import { Button, List, Segmented, Table } from "antd";
import { CalendarOutlined, BarsOutlined } from "@ant-design/icons";
import PieChart from "./PieChart";

function ExpenseIncome({ data, className = "" }) {
  const [viewMode, setViewMode] = useState("table");

  // const columns = [
  //   {
  //     title: 'Төрөл',
  //     dataIndex: 'name',
  //     key: 'name',
  //     // render:(text, row) => (
  //     //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
  //     // )
  //   },
  //   {
  //     title: 'Дүн',
  //     dataIndex: 'amount',
  //     key: 'amount',
  //     render:(text, row) => (
  //       <div>{Intl.NumberFormat().format(text)} ₮</div>
  //     )
  //   },
  // ]

  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex mb-5">
        <Segmented
          value={viewMode}
          onChange={(e) => setViewMode(e)}
          options={[
            {
              icon: (
                <div className="flex items-center gap-2">
                  <CalendarOutlined /> Хүснэгт
                </div>
              ),
              value: "table",
            },
            {
              icon: (
                <div className="flex items-center gap-2">
                  <BarsOutlined /> График
                </div>
              ),
              value: "chart",
            },
          ]}
        ></Segmented>
      </div>
      {viewMode === "table" ? (
        <div className="flex gap-5">
          <div className="border rounded-lg overflow-hidden flex-1">
            <div className="py-2 px-2 border-b">Орлого</div>
            <List
              dataSource={data?.incomeData}
              header={false}
              footer={false}
              size="small"
              renderItem={(item) => (
                <List.Item className="text-xs">
                  <div className="w-full flex justify-between gap-5">
                    <span>{item.name}</span>
                    <span>{Intl.NumberFormat().format(item.amount)} ₮</span>
                  </div>
                </List.Item>
              )}
            />
          </div>
          <div className="border rounded-lg overflow-hidden flex-1">
            <div className="py-2 px-2 border-b">Зарлага</div>
            <List
              dataSource={data?.expenseData}
              header={false}
              className="w-full"
              size="small"
              renderItem={(item) => (
                <List.Item className="text-xs">
                  <div className="w-full flex justify-between gap-5">
                    <span>{item.name}</span>
                    <span>{Intl.NumberFormat().format(item.amount)} ₮</span>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      ) : (
        <div className="flex gap-2 items-start h-full">
          <PieChart data={data.incomeData} />
          <PieChart data={data.expenseData} />
        </div>
      )}
    </div>
  );
}

export default ExpenseIncome;
