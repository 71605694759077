import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import React, { useRef, useState } from "react";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";

export default ({
  handleEdit,
  currentPage = 0,
  doctors,
  handleCancel,
  handleSetDoctor,
  handleDischarge,
}) => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [fixed, setFixed] = useState("left");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm({ closeDropdown: false });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={dataIndex == "regno" ? `РД-р хайх` : `Нэрээр хайх`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            setSearchText(e.target.value);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Хайх
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Цэвэрлэх
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setFixed(fixed == false ? "left" : false);
            }}
          >
            Түгжих
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Хаах
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] == null
        ? ""
        : record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  return [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 70,
      fixed: "left",
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Үйлчлүүлэгчийн овог нэр",
      dataIndex: "customerFirstname",
      key: "customerFirstname",
      fixed: fixed,
      width: 150,

      render: (text, row) => (
        <span>
          {row.customer.lastname} {row.customer.firstname}
        </span>
      ),
      // ...getColumnSearchProps("customerFirstname"),
    },
    {
      title: "Регистер",
      dataIndex: ["customer", "regno"],
      key: "regno",
      width: 120,
      // render: (text) => <span>{text}</span>,
      // ...getColumnSearchProps("regno"),
    },
    {
      title: "Нас",
      dataIndex: ["customer", "age"],
      key: "age",
      width: 60,
    },
    {
      title: "Хүйс",
      dataIndex: ["customer", "gender"],
      key: "gender",
      width: 70,
      render: (age) => (
        <Tag color={age == 1 ? "green" : "pink"}>{age == 1 ? "Эр" : "Эм"}</Tag>
      ),
    },
    {
      title: "Утас",
      dataIndex: ["customer", "mobile"],
      key: "mobile",
      width: 80,
    },
    {
      title: "ICD10",
      dataIndex: "icd10",
      key: "icd10",
    },
    {
      title: "ICD9",
      dataIndex: "icd9",
      key: "icd9",
    },
    {
      title: "Тасаг",
      dataIndex: ["ward", "name"],
      width: 100,
      key: "ward_name",
      render: (text, row) => <span>{text}</span>,
    },
    {
      title: "Өрөө",
      dataIndex: ["room", "room_number"],
      width: 120,
      key: "room_number",
      render: (text, row) => <span>{text}</span>,
    },
    {
      title: "Ор",
      dataIndex: ["room_bed", "bed_number"],
      width: 200,
      key: "bed_number",
      render: (text, row) => <span>{text}</span>,
    },
    {
      title: "Хэвтсэн огноо",
      dataIndex: "startDate",
      width: 200,
      key: "startDate",
      render: (text, row) => <span> {moment(text).format("yyyy-MM-DD")}</span>,
    },
    {
      title: "Гарах огноо",
      dataIndex: "endDate",
      width: 220,
      key: "endDate",
      render: (text, row) => <span> {moment(text).format("yyyy-MM-DD")}</span>,
    },
    {
      title: "Гарсан огноо",
      dataIndex: "dischargeDate",
      width: 220,
      key: "dischargeDate",
      render: (text, row) => (
        <span> {text ? moment(text).format("yyyy-MM-DD") : ""}</span>
      ),
    },
    {
      title: "Үнийн дүн",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
    {
      title: "Онш",
      dataIndex: "diagnose",
      width: 200,
      key: "diagnose",
    },
    {
      title: "Төлөв",
      dataIndex: "diagnoseStatus",
      width: 100,
      key: "diagnoseStatus",
    },
    {
      title: "Тайлбар",
      dataIndex: "note",
      key: "note",
      width: 200,
    },
    {
      title: "Эмч",
      dataIndex: "doctorId",
      width: 180,
      key: "doctorId",
      ellipsis: true,
      filters: doctors,
      onFilter: (value, record) => record.doctorId == value,
      render: (text, row) => {
        return row.status != "Гарсан" ? (
          <Select
            className="mb-0 w-[150px]"
            value={row.doctorId}
            options={doctors}
            allowClear
            onChange={(e) => {
              handleSetDoctor(e, row);
            }}
          ></Select>
        ) : (
          <Tag color={"green"}>{text}</Tag>
        );
      },
    },

    {
      title: "Хэвтсэн төлөв",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (status) => (
        <Tag color={status == "Хэвтсэн" ? "green" : "orange"}>{status}</Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 210,
      render: (text, row) => (
        <div className="flex items-center gap-2">
          <Button
            size="small"
            disabled={row.status == "Гарсан"}
            onClick={() => {
              handleCancel(row);
            }}
          >
            <div className="flex items-center gap-2">
              <DeleteOutlined /> Цуцлах
            </div>
          </Button>
          <Button
            size="small"
            onClick={() => handleEdit(row)}
            disabled={row.status == "Гарсан"}
          >
            <div className="flex items-center gap-2">
              <EditOutlined /> Засах
            </div>
          </Button>
          <Button
            size="small"
            onClick={() => handleDischarge(row)}
            disabled={row.status == "Гарсан"}
          >
            <div className="flex items-center gap-2">
              <CheckOutlined /> Гарсан
            </div>
          </Button>
          {/* <Button size="small" onClick={() => handleMore(row)}>
            <div className="flex items-center gap-2">Дэлгэрэнгүй</div>
          </Button> */}
        </div>
      ),
    },
  ];
};
