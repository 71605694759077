import React, { useEffect, useState } from "react";

import { Button, List, Segmented, Table } from "antd";
import { MainTable } from "components";
function CallerTable({ data, className = "", title }) {

  const columns = [
    {
      title: "Өдөр",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Тоо",
      dataIndex: "cnt",
      key: "cnt",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
    {
      title: "Хариулсан",
      dataIndex: "cntAnswer",
      key: "cntAnswer",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
    {
      title: "Хариулаагүй",
      dataIndex: "cntNoAnswer",
      key: "cntNoAnswer",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
    {
      title: "Завгүй",
      dataIndex: "cntBusy",
      key: "cntBusy",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
    {
      title: "Алдаа гарсан",
      dataIndex: "cntFail",
      key: "cntFail",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
  ];
  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex gap-5">
        <div className="border rounded-lg overflow-hidden flex-1">
          {/* <div className="py-2 px-2 border-b">Дундаж онш бичилт</div> */}
          <MainTable
            dataTable={
              data 
            }
            columns={columns}
            title={title}
          />
        </div>
      </div>
    </div>
  );
}

export default CallerTable;
