import { Button, Divider, Form, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomerForm from "./customerForm";
import React, { useEffect, useState } from "react";
export default ({ removeFunc, form, services }) => {
  return [
    {
      type: "component",
      component: (
        <Divider className="col-span-12" style={{ marginTop: 0 }}>
          Үйлчлүүлэгчийн мэдээлэл
        </Divider>
      ),
    },

    {
      type: "component",
      component: <CustomerForm form={form} />,
    },
    {
      type: "component",
      component: <Divider className="col-span-12">Мэдээлэл</Divider>,
    },

    {
      label: "Огноо",
      name: ["bookingData", "currentDate"],
      type: "date",
      // disabled: true,
      rules: [{ required: true, message: "Огноо сонгоно уу!" }],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Өндөр",
      name: ["bookingData", "height"],
      className: "col-span-6 md:col-span-3 mb-0",
      inputProps: {
        type: "number",
        className: "w-full",
      },
    },
    {
      label: "Жин",
      name: ["bookingData", "weight"],
      className: "col-span-6 md:col-span-3 mb-0",

      inputProps: {
        type: "number",
        className: "w-full",
      },
    },
    {
      label: "Хариу авах утас",
      name: ["bookingData", "answerPhone"],
      className: "col-span-6 md:col-span-3 mb-0",
      type: "number",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Dx",
      name: ["bookingData", "dx"],
      className: "col-span-6 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "MTS",
      name: ["bookingData", "MTS"],
      className: "col-span-6 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Aim",
      name: ["bookingData", "aim"],
      className: "col-span-6 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    //

    {
      label: "Pathological result",
      name: ["bookingData", "pathologicalResult"],
      className: "col-span-12 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Давтан",
      name: ["bookingData", "repeatNote"],
      className: "col-span-6 md:col-span-3 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Тайлбар",
      name: ["bookingData", "note"],
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "ЭМД",
      name: ["bookingData", "insurance"],

      className: "col-span-6 md:col-span-3 mb-0",
      type: "checkbox",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Шинжилгээ",
      name: ["bookingData", "serviceId"],
      type: "select",
      // rules: [{ required: true, message: "Үйлчилгээ сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: services.filter((service) => service.servicetypeid === 3),
      },
    },
  ];
};
