import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Form as AntForm, DatePicker, message } from "antd";
import { Form, MainTable, CompanySelect } from "components";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";

import fields from "./fields";
export default function ShiftSchedule() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const role = authContext.state.userInfo.profile.role;
  const userId = authContext.state.userInfo.profile.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [config, setConfig] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  // const [selectedConfig, setSelectedConfig] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [configTypeOptions, setConfigTypeOptions] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [form] = AntForm.useForm();
  const [selectedConfig, setSelectedConfig] = useState(-1);

  const [technicians, setTechnicians] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [serviceSelectedConfig, setServiceSelectedConfig] = useState(-1);
  useEffect(() => {
    action.handleSidebarKey("shift_schedlue");
    instance({
      method: "get",
      url: `/shift_schedule/config`,
    })
      .then((res) => {
        let tmp2 = [];

        tmp2.push({ label: "Бүгд", value: -1 });

        res.data.map((item) => {
          tmp2.push({ label: item.name, value: item.id, text: item.name });
        });
        setConfigTypeOptions(tmp2);
      })

      .catch((err) => {})
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/systemusers?role=Техникч&status=Идэвхитэй&hospital=2`,
    })
      .then((res) => {
        setTechnicians(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.lastname.charAt(0)} ${item.firstname}`,
          }))
        );
      })
      .catch((err) => {});

    instance({
      method: "get",
      url: `/servicetype`,
    })
      .then((res) => {
        let tmp2 = [];

        tmp2.push({ label: "Бүгд", value: -1 });

        res.data.map((item) => {
          tmp2.push({ label: item.name, value: item.id, text: item.name });
        });

        setServiceTypeOptions(tmp2);
      })
      .catch((err) => {});

    fetchData();
  }, []);
  useEffect(() => {
    if (selectedConfig == -1) {
      setServiceSelectedConfig(-1);
    } else {
      const mine = configTypeOptions
        .find((item) => item.value == selectedConfig)
        ?.label?.split(" ")?.[0];
      const newSeletced = serviceTypeOptions.find((item) =>
        item.label.includes(mine)
      )?.value;
      if (newSeletced) setServiceSelectedConfig(newSeletced);
      else setServiceSelectedConfig(-1);
    }
    // setServiceSelectedConfig(())
    fetchData();
  }, [selectedConfig]);

  const fetchData = () => {
    instance({
      method: "get",
      url: `/shift_schedule?configId=${selectedConfig}`,
    })
      .then((res) => {
     
        setData(
          res.data.map((item) => ({
            data: item,
            id: item.id,
            title: `${item.systemuser?.lastname.charAt(0)}. ${
              item.systemuser?.firstname
            }`,
            start: item?.date,
            end: item?.date,
            color: "transparent",
          }))
        );
      })
      .catch((err) => {});
  };
  const handleDateClick = (arg) => {
    // setIsEdit(true);
    // setEditData({
    //   id: null,
    //   date: arg.date,
    // });
    // setOpen(true);
  };
  const handleEventClick = (clickInfo) => {
    const data = clickInfo.event.extendedProps.data;
    // let data = clickInfo.event._def.data;
    setEditData(data);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleSubmit = (values) => {
    setLoading(true);
    if (editData.id) {
      instance({
        method: "put",
        url: "/shift_schedule",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {
       
        })
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/shift_schedule",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };
  const handleDelete = () => {
    setLoading(true);
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Устгахдаа итгэлтэй байна уу",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id
          instance({
            method: "delete",
            url: "/shift_schedule/" + editData.id,
          })
            .then((res) => {
              handleCloseModal();
              fetchData();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        handleCloseModal();
      },
    });
  };
  const renderEventContent = (eventInfo) => {
    
    const data = eventInfo.event.extendedProps.data;

    return (
      <>
        <Tag color={"blue"} onClick={() => handleEventClick(eventInfo)}>
          <b>{eventInfo.event.title}</b>

          <br></br>
          <span
            style={{
              width: "100%",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <Tag color="red">{data?.shift_config?.name}</Tag>{" "}
            <Tag color="magenta">
              {data?.shift_config?.startTime}- {data.shift_config?.endTime}
            </Tag>
            {/* <Tag color="grey">{data.phone}</Tag> */}
            {/* {data.doctors.map((doctor, index) => (
              <span key={index}>
                {doctor.lastName.charAt(0)}.{doctor.firstName},
              </span>
            ))} */}
            <br></br>
            {/* {data.description} */}
          </span>
        </Tag>
      </>
    );
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <h1>Хуваарь</h1>

      <div className="flex gap-4">
        <div>
          Төхөөрөмж:
          <Select
            className="mb-0 w-[200px]"
            value={serviceSelectedConfig}
            options={serviceTypeOptions}
            onChange={(e) => {
              setServiceSelectedConfig(e);
            }}
            // onChange={(e) => {
            //   ls.set("servicetypeid", e);
            //   setServiceType(e);
            // }}
          />
        </div>
        <div>
          Тохиргоо:
          <Select
            className="mb-0 w-[200px]"
            value={selectedConfig}
            options={configTypeOptions}
            onChange={(e) => {
              setSelectedConfig(e);
            }}
          />
        </div>
      </div>
      <FullCalendar
        aspectRatio={2}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        weekends={false}
        dateClick={(e) => handleDateClick(e)}
        events={data}
        eventContent={(e) => renderEventContent(e)}
      />
      <Modal
        title={
          editData ? "Мэс заслын мэдээлэл засах" : "Мэс заслын мэдээлэл оруулах"
        }
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
        footer={[
          <Button key="cancel" onClick={loading ? null : handleCloseModal}>
            Цуцлах
          </Button>,

          <Button
            key="delete"
            type="danger"
            onClick={loading ? null : handleDelete}
            hidden={editData?.id == null}
          >
            Устгах
          </Button>,
          <Button key="save" type="primary" onClick={form.submit}>
            Хадгалах
          </Button>,
        ]}
      >
        {
          <Form
            form={form}
            fields={fields({ configTypeOptions, technicians })}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className="gap-4"
          />
        }
      </Modal>
      {contextHolder}
    </div>
  );
}
