import React, { useContext, useLayoutEffect } from "react";
import axios from "axios";
import ls from "./ls";
import { AuthContext } from "contexts";

const instance = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const userInfo = ls.get("userInfo");
    const token = userInfo && userInfo.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

function MainInstance(props) {
  const { action } = useContext(AuthContext);

  useLayoutEffect(() => {
    instance.interceptors.response.use(
      function (response) {
        const method = response.config.method;
        const url = response.config.url;
        
        if (method === "delete") {
          action.onSuccess("Амжилттай устгалаа!");
        } else if (method === "post") {
          if (url.includes("login")) {
            action.onSuccess("Амжилттай нэвтэрлээ");
          } else if (url.includes("forgotpassword")) {
            action.onSuccess("Үйлдэл амжилттай!");
          } else {
            action.onSuccess("Үйлдэл амжилттай!");
          }
        } else if (method === "put") {
          action.onSuccess("Амжилттай заслаа!");
        } else if (method === "patch") {
          action.onSuccess("Үйлдэл амжилттай!");
        }
        return response;
      },
      function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            action.logout();
          } else if (error.response.data) {
            const errorMsg = error.response.data.value
              ? Array.isArray(error.response.data.value)
                ? error.response.data.value[0].msg
                : error.response.data.value
              : error.response.data.error || "Үйлдэл амжилтгүй боллоо!";
            action.onError(errorMsg);
          }
        }
        return Promise.reject(error);
      }
    );
  }, [action]);

  return <>{props.children}</>;
}

export { instance };

export default MainInstance;
