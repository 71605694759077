import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Form as AntForm, Button, Dropdown, Menu } from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  LeftOutlined,
  DeleteOutlined,
  MoreOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { BiDish } from "react-icons/bi";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import nullImage from "../../../assets/images/download.png";
import Chart from "components/Chart";
import { AuthContext } from "contexts";

function Organization() {
  const [data, setData] = useState({});
  const [materialType, setMaterialType] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const [form] = AntForm.useForm();
  const { id } = useParams();
  const { action } = React.useContext(AuthContext);

  const status = [
    {
      value: "гр",
      text: "гр",
    },
    {
      value: "ш",
      text: "ш",
    },
    {
      value: "мл",
      text: "мл",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/hospital`,
    })
      .then((res) => {
        setData(res.data);
        action.changeLogo(res.data.logo);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "№",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Түүхий эдийн нэр",
      dataIndex: "materialName",
      key: "materialName",
      filterType: "search",
    },
    {
      title: "Хэмжих нэгж",
      dataIndex: "uom",
      key: "uom",
      filterType: "status",
      filters: status,
      align: "center",
    },
    {
      title: "Нэг порц хоолны орц",
      children: [
        {
          title: "Бохир жин",
          dataIndex: "raw_weight",
          key: "raw_weight",
          filterType: "search",
          align: "center",
        },
        {
          title: "Цэвэр жин",
          dataIndex: "pure_weight",
          key: "pure_weight",
          filterType: "search",
          align: "center",
        },
      ],
    },
    {
      title: "Шимт бодис, г",
      children: [
        {
          title: "Уураг",
          dataIndex: "proteins",
          key: "proteins",
          filterType: "search",
          align: "center",
        },
        {
          title: "Нүүрс ус",
          dataIndex: "carbohydrates",
          key: "carbohydrates",
          filterType: "search",
          align: "center",
        },
        {
          title: "Өөх тос",
          dataIndex: "fats",
          key: "fats",
          filterType: "search",
          align: "center",
        },
      ],
    },
    {
      title: "Нийт илчлэг, ккал",
      dataIndex: "calories",
      key: "calories",
      filterType: "search",
      align: "center",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 80,
      render: (text, row) => (
        <div className="flex flex-row gap-2">
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleEdit(row)} key="action-1">
                  <div className="flex items-center gap-2">
                    <EditOutlined /> Засах
                  </div>
                </Menu.Item>
                <Menu.Item
                  danger
                  className="flex items-center gap-2"
                  onClick={() => handleDelete(row.id)}
                  key="action-2"
                >
                  <div className="flex items-center gap-2">
                    <DeleteOutlined /> Устгах
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button size="small" className="flex items-center">
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleDelete = (id) => {
    modal.confirm({
      icon: <WarningOutlined style={{ color: "red" }} />,
      title: "Анхаарна уу !",
      content: "Энэхүү мөр бичлэгийг устгах гэж байна",
      okText: "Устгах",
      cancelText: "Буцах",
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: "delete",
            url: `hospital/${id}`,
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              reject();
            })
            .then(() => fetchData());
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    let keys = Object.keys(values);
    const formData = new FormData();
    keys.map((item) => {
      formData.append(item, values[`${item}`] || "");
    });
    formData.set("image", values.image?.file ? values.image?.file : "");
    if (editData) {
      formData.append("id", editData.id);
      instance({
        method: "put",
        url: "/hospital",
        data: formData,
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/hospital",
        data: formData,
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (fooItem) => {
    setEditData(fooItem);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="px-5">
      <div className="grid grid-cols-12 my-4 gap-6 w-full items-start">
        <div className="relative flex flex-col col-span-4 gap-4 shadow p-4 rounded-lg bg-white">
          {data?.logo ? (
            <img
              className="object-cover rounded-lg"
              src={`${process.env.REACT_APP_CDN_URL}${data?.logo}`}
              alt="food-detail-image"
            />
          ) : (
            <img className="rounded-lg" src={nullImage} />
          )}
          <div>
            <div className="flex items-center gap-1 text-gray-900 font-bold text-lg">
              {data?.name}
            </div>
            <div className="text-center py-2">{data?.slogan}</div>
            <p className="text-sm text-gray-600">{data?.foodTypeName}</p>
            <div className="flex items-center">
              <div className="flex-auto">
                <div className="text-gray-500">Хаяг</div>
                <div className="text-gray-900">{data?.address}</div>
                <div className="text-gray-500">И-Мэйл хаяг</div>
                <div className="text-gray-900">{data?.email}</div>
                <div className="text-gray-500">Утасны дугаар</div>
                <div className="text-gray-900">{data?.mobile}</div>
                <div className="text-gray-500">Веб сайт</div>
                <a className="hover:underline text-blue-500">{data?.website}</a>
              </div>
            </div>
            <div className="flex justify-center">
              <Button onClick={() => setOpen(true)}>Засах</Button>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col col-span-8 gap-2 shadow p-4 rounded-lg bg-white">
          <div
            className="text-gray-700 text-base"
            dangerouslySetInnerHTML={{ __html: data?.descr }}
          />
        </div>
      </div>
      {/* Food CREATE / EDIT */}
      <Modal
        title={"Компаний мэдээлэл"}
        open={open}
        destroyOnClose
        width={900}
        okText="Хадгалах"
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          form={form}
          fields={fields()}
          layout="vertical"
          onFinish={handleSubmit}
          editData={data}
          // isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default Organization;
