export default {
  "type":"FeatureCollection",
  "features":[
     {
        "type":"Feature",
        "geometry":{
           "type":"Polygon",
           "coordinates":[
              [
                 [
                    99.00460930000004,
                    52.074732300000036
                 ],
                 [
                    99.00246220000002,
                    52.076437199999965
                 ],
                 [
                    99.0072518,
                    52.077874200000025
                 ],
                 [
                    99.010139,
                    52.079728399999965
                 ],
                 [
                    99.00915740000003,
                    52.080486100000044
                 ],
                 [
                    99.00538620000002,
                    52.08220240000003
                 ],
                 [
                    98.998601,
                    52.08473169999996
                 ],
                 [
                    98.9973877,
                    52.08651110000002
                 ],
                 [
                    98.99989340000002,
                    52.0885182
                 ],
                 [
                    99.00173660000003,
                    52.08991850000004
                 ],
                 [
                    99.00339669999998,
                    52.09230360000003
                 ],
                 [
                    99.00204690000004,
                    52.09393239999997
                 ],
                 [
                    98.99977520000002,
                    52.094425600000015
                 ],
                 [
                    98.9983636,
                    52.0954106
                 ],
                 [
                    98.99879450000005,
                    52.097038899999966
                 ],
                 [
                    99.0005777,
                    52.09957530000004
                 ],
                 [
                    98.99781569999998,
                    52.1022649
                 ],
                 [
                    98.996683,
                    52.10537439999998
                 ],
                 [
                    98.99315029999995,
                    52.1065457
                 ],
                 [
                    98.98995709999998,
                    52.108591399999966
                 ],
                 [
                    98.98737859999999,
                    52.11169740000001
                 ],
                 [
                    98.98295589999998,
                    52.11340249999996
                 ],
                 [
                    98.97959939999998,
                    52.1153854
                 ],
                 [
                    98.9792711,
                    52.11817460000001
                 ],
                 [
                    98.979641,
                    52.12184769999999
                 ],
                 [
                    98.9811623,
                    52.12721819999999
                 ],
                 [
                    98.978967,
                    52.127679499999964
                 ],
                 [
                    98.97687770000003,
                    52.128588799999974
                 ],
                 [
                    98.97362080000003,
                    52.13112649999998
                 ],
                 [
                    98.971347,
                    52.13419410000004
                 ],
                 [
                    98.9702412,
                    52.1369965
                 ],
                 [
                    98.9701185,
                    52.13957149999999
                 ],
                 [
                    98.96882760000001,
                    52.141010600000044
                 ],
                 [
                    98.96919659999999,
                    52.14263890000004
                 ],
                 [
                    98.954231,
                    52.143392200000015
                 ],
                 [
                    98.9345,
                    52.1489
                 ],
                 [
                    98.9111,
                    52.1496
                 ],
                 [
                    98.898,
                    52.1451
                 ],
                 [
                    98.8834,
                    52.1361
                 ],
                 [
                    98.8615,
                    52.1361
                 ],
                 [
                    98.8542,
                    52.129
                 ],
                 [
                    98.8572,
                    52.1136
                 ],
                 [
                    98.8514,
                    52.1064
                 ],
                 [
                    98.8397,
                    52.0983
                 ],
                 [
                    98.8413,
                    52.0794
                 ],
                 [
                    98.8413,
                    52.0588
                 ],
                 [
                    98.8312,
                    52.0453
                 ],
                 [
                    98.8268,
                    52.039
                 ],
                 [
                    98.8065,
                    52.0255
                 ],
                 [
                    98.8196,
                    52.0147
                 ],
                 [
                    98.8167,
                    52.0059
                 ],
                 [
                    98.8071,
                    52
                 ],
                 [
                    98.8059,
                    51.9868
                 ],
                 [
                    98.8103,
                    51.9743
                 ],
                 [
                    98.8104,
                    51.9662
                 ],
                 [
                    98.809,
                    51.9581
                 ],
                 [
                    98.7988,
                    51.95
                 ],
                 [
                    98.7872,
                    51.9467
                 ],
                 [
                    98.7799,
                    51.9418
                 ],
                 [
                    98.78,
                    51.932
                 ],
                 [
                    98.7771,
                    51.9239
                 ],
                 [
                    98.77454150000003,
                    51.9154487
                 ],
                 [
                    98.7699,
                    51.9104
                 ],
                 [
                    98.7685,
                    51.8996
                 ],
                 [
                    98.7686,
                    51.8931
                 ],
                 [
                    98.7672,
                    51.8851
                 ],
                 [
                    98.7657,
                    51.8789
                 ],
                 [
                    98.7672,
                    51.8735
                 ],
                 [
                    98.7643,
                    51.8699
                 ],
                 [
                    98.76,
                    51.8683
                 ],
                 [
                    98.7559,
                    51.8663
                 ],
                 [
                    98.7513,
                    51.8654
                 ],
                 [
                    98.7469,
                    51.8609
                 ],
                 [
                    98.7368,
                    51.8537
                 ],
                 [
                    98.7122,
                    51.8437
                 ],
                 [
                    98.7065,
                    51.8374
                 ],
                 [
                    98.7021,
                    51.8338
                 ],
                 [
                    98.6891,
                    51.8248
                 ],
                 [
                    98.6805,
                    51.8203
                 ],
                 [
                    98.6645,
                    51.8258
                 ],
                 [
                    98.6587,
                    51.8202
                 ],
                 [
                    98.65,
                    51.8157
                 ],
                 [
                    98.6384,
                    51.8158
                 ],
                 [
                    98.6312,
                    51.8139
                 ],
                 [
                    98.6124,
                    51.8063
                 ],
                 [
                    98.6111,
                    51.7966
                 ],
                 [
                    98.6038,
                    51.7937
                 ],
                 [
                    98.5981,
                    51.792
                 ],
                 [
                    98.5894,
                    51.7927
                 ],
                 [
                    98.585,
                    51.7938
                 ],
                 [
                    98.5778,
                    51.7938
                 ],
                 [
                    98.5546,
                    51.7902
                 ],
                 [
                    98.5458,
                    51.7981
                 ],
                 [
                    98.54,
                    51.799
                 ],
                 [
                    98.5328,
                    51.7981
                 ],
                 [
                    98.5214,
                    51.7784
                 ],
                 [
                    98.5156,
                    51.7739
                 ],
                 [
                    98.5056,
                    51.7674
                 ],
                 [
                    98.4811,
                    51.752
                 ],
                 [
                    98.4739,
                    51.7475
                 ],
                 [
                    98.4523,
                    51.7366
                 ],
                 [
                    98.4379,
                    51.7365
                 ],
                 [
                    98.4176,
                    51.7365
                 ],
                 [
                    98.3886,
                    51.739
                 ],
                 [
                    98.3785,
                    51.7398
                 ],
                 [
                    98.3655,
                    51.738
                 ],
                 [
                    98.354,
                    51.7315
                 ],
                 [
                    98.3527,
                    51.7189
                 ],
                 [
                    98.3557,
                    51.7092
                 ],
                 [
                    98.3559,
                    51.7011
                 ],
                 [
                    98.34,
                    51.7008
                 ],
                 [
                    98.3284,
                    51.6973
                 ],
                 [
                    98.3271,
                    51.6919
                 ],
                 [
                    98.3172,
                    51.6773
                 ],
                 [
                    98.3129,
                    51.6712
                 ],
                 [
                    98.2918,
                    51.637
                 ],
                 [
                    98.2748,
                    51.6161
                 ],
                 [
                    98.2477,
                    51.5971
                 ],
                 [
                    98.2419,
                    51.5972
                 ],
                 [
                    98.226,
                    51.5978
                 ],
                 [
                    98.2232,
                    51.5951
                 ],
                 [
                    98.2204,
                    51.587
                 ],
                 [
                    98.2248,
                    51.5843
                 ],
                 [
                    98.2335,
                    51.5836
                 ],
                 [
                    98.2407,
                    51.5817
                 ],
                 [
                    98.245,
                    51.58
                 ],
                 [
                    98.2451,
                    51.5771
                 ],
                 [
                    98.2381,
                    51.562
                 ],
                 [
                    98.2354,
                    51.5549
                 ],
                 [
                    98.2341,
                    51.5476
                 ],
                 [
                    98.237,
                    51.5449
                 ],
                 [
                    98.2472,
                    51.5395
                 ],
                 [
                    98.2544,
                    51.5351
                 ],
                 [
                    98.2574,
                    51.5296
                 ],
                 [
                    98.2563,
                    51.5117
                 ],
                 [
                    98.2506,
                    51.5054
                 ],
                 [
                    98.2449,
                    51.5
                 ],
                 [
                    98.2436,
                    51.4955
                 ],
                 [
                    98.2408,
                    51.4902
                 ],
                 [
                    98.2409,
                    51.4829
                 ],
                 [
                    98.2353,
                    51.4739
                 ],
                 [
                    98.2311,
                    51.4675
                 ],
                 [
                    98.2269,
                    51.4621
                 ],
                 [
                    98.2241,
                    51.4567
                 ],
                 [
                    98.2111,
                    51.4602
                 ],
                 [
                    98.201,
                    51.4583
                 ],
                 [
                    98.1967,
                    51.4583
                 ],
                 [
                    98.1924,
                    51.4592
                 ],
                 [
                    98.1866,
                    51.4618
                 ],
                 [
                    98.1809,
                    51.4546
                 ],
                 [
                    98.1651,
                    51.4572
                 ],
                 [
                    98.152,
                    51.4625
                 ],
                 [
                    98.1376,
                    51.4658
                 ],
                 [
                    98.1317,
                    51.4704
                 ],
                 [
                    98.126,
                    51.4713
                 ],
                 [
                    98.1188,
                    51.4705
                 ],
                 [
                    98.1131,
                    51.4685
                 ],
                 [
                    98.103,
                    51.4675
                 ],
                 [
                    98.0959,
                    51.4628
                 ],
                 [
                    98.0873,
                    51.4593
                 ],
                 [
                    98.073,
                    51.4585
                 ],
                 [
                    98.0587,
                    51.4537
                 ],
                 [
                    98.0501,
                    51.4502
                 ],
                 [
                    98.0502,
                    51.4446
                 ],
                 [
                    98.049,
                    51.4365
                 ],
                 [
                    98.0434,
                    51.4293
                 ],
                 [
                    98.0395955,
                    51.42495980000003
                 ],
                 [
                    98.0363,
                    51.4212
                 ],
                 [
                    98.0307,
                    51.4148
                 ],
                 [
                    98.0252,
                    51.4058
                 ],
                 [
                    98.0239,
                    51.3995
                 ],
                 [
                    98.0211,
                    51.3941
                 ],
                 [
                    98.0169,
                    51.3895
                 ],
                 [
                    98.0041,
                    51.3849
                 ],
                 [
                    97.9999,
                    51.3795
                 ],
                 [
                    97.9942,
                    51.3759
                 ],
                 [
                    97.9873,
                    51.365
                 ],
                 [
                    97.9772,
                    51.3649
                 ],
                 [
                    97.9759,
                    51.3604
                 ],
                 [
                    97.9731,
                    51.355
                 ],
                 [
                    97.969,
                    51.3478
                 ],
                 [
                    97.9676,
                    51.3451
                 ],
                 [
                    97.962,
                    51.3405
                 ],
                 [
                    97.9577,
                    51.3387
                 ],
                 [
                    97.9535,
                    51.3342
                 ],
                 [
                    97.9566,
                    51.3225
                 ],
                 [
                    97.9611,
                    51.3145
                 ],
                 [
                    97.9612,
                    51.3098
                 ],
                 [
                    97.9599,
                    51.3053
                 ],
                 [
                    97.9629,
                    51.2983
                 ],
                 [
                    97.9645,
                    51.292
                 ],
                 [
                    97.9618,
                    51.2849
                 ],
                 [
                    97.962,
                    51.2758
                 ],
                 [
                    97.9408,
                    51.2632
                 ],
                 [
                    97.9396,
                    51.254
                 ],
                 [
                    97.9427,
                    51.244
                 ],
                 [
                    97.9456,
                    51.2388
                 ],
                 [
                    97.9458,
                    51.2324
                 ],
                 [
                    97.9414,
                    51.2272
                 ],
                 [
                    97.9347,
                    51.2179
                 ],
                 [
                    97.9393,
                    51.202
                 ],
                 [
                    97.9283,
                    51.1847
                 ],
                 [
                    97.9256,
                    51.1784
                 ],
                 [
                    97.9214,
                    51.1748
                 ],
                 [
                    97.9186,
                    51.1702
                 ],
                 [
                    97.9203,
                    51.1604
                 ],
                 [
                    97.9175,
                    51.155
                 ],
                 [
                    97.8991,
                    51.1494
                 ],
                 [
                    97.8992,
                    51.1431
                 ],
                 [
                    97.9008,
                    51.1386
                 ],
                 [
                    97.898,
                    51.1332
                 ],
                 [
                    97.8939,
                    51.1267
                 ],
                 [
                    97.89662409999995,
                    51.1213914
                 ],
                 [
                    97.89798280000002,
                    51.12107749999996
                 ],
                 [
                    97.89827859999998,
                    51.121020400000035
                 ],
                 [
                    97.89871109999997,
                    51.12099179999998
                 ],
                 [
                    97.89899180000002,
                    51.120925199999974
                 ],
                 [
                    97.89922690000002,
                    51.12080140000003
                 ],
                 [
                    97.8992876,
                    51.1206109
                 ],
                 [
                    97.89922690000002,
                    51.120429900000026
                 ],
                 [
                    97.89868070000003,
                    51.12020609999998
                 ],
                 [
                    97.89853660000001,
                    51.12003470000001
                 ],
                 [
                    97.89846830000005,
                    51.11985369999996
                 ],
                 [
                    97.89849109999997,
                    51.119668
                 ],
                 [
                    97.89869590000005,
                    51.1192252
                 ],
                 [
                    97.89884,
                    51.1190585
                 ],
                 [
                    97.89906,
                    51.1189204
                 ],
                 [
                    97.9000994,
                    51.118687
                 ],
                 [
                    97.900327,
                    51.11856320000003
                 ],
                 [
                    97.90071390000004,
                    51.118287
                 ],
                 [
                    97.90096419999996,
                    51.117977499999974
                 ],
                 [
                    97.901078,
                    51.117687
                 ],
                 [
                    97.9011008,
                    51.11736320000004
                 ],
                 [
                    97.90100980000001,
                    51.11706790000003
                 ],
                 [
                    97.8999856,
                    51.11618689999997
                 ],
                 [
                    97.89972769999997,
                    51.1157773
                 ],
                 [
                    97.89951519999998,
                    51.11554870000001
                 ],
                 [
                    97.8992042,
                    51.115382
                 ],
                 [
                    97.898658,
                    51.11519629999999
                 ],
                 [
                    97.89804349999999,
                    51.115063
                 ],
                 [
                    97.89775520000002,
                    51.11502959999996
                 ],
                 [
                    97.89745929999997,
                    51.115063
                 ],
                 [
                    97.89699650000004,
                    51.11530110000004
                 ],
                 [
                    97.89670069999998,
                    51.11535819999997
                 ],
                 [
                    97.89599509999996,
                    51.115401099999964
                 ],
                 [
                    97.8949254,
                    51.11539159999998
                 ],
                 [
                    97.894622,
                    51.11534390000003
                 ],
                 [
                    97.89437160000001,
                    51.11523440000003
                 ],
                 [
                    97.8940985,
                    51.1146296
                 ],
                 [
                    97.89406060000003,
                    51.11444379999998
                 ],
                 [
                    97.8941289,
                    51.11425809999999
                 ],
                 [
                    97.8943413,
                    51.1139438
                 ],
                 [
                    97.89490269999999,
                    51.1134389
                 ],
                 [
                    97.89509989999996,
                    51.11304360000004
                 ],
                 [
                    97.8951454,
                    51.1128531
                 ],
                 [
                    97.89589649999998,
                    51.1120006
                 ],
                 [
                    97.89617720000001,
                    51.11143379999998
                 ],
                 [
                    97.8962,
                    51.11124809999998
                 ],
                 [
                    97.8960406,
                    51.110895600000035
                 ],
                 [
                    97.89589649999998,
                    51.11072419999998
                 ],
                 [
                    97.89546409999998,
                    51.11056219999998
                 ],
                 [
                    97.89491790000001,
                    51.11025739999999
                 ],
                 [
                    97.8946978,
                    51.11007159999998
                 ],
                 [
                    97.89459160000001,
                    51.10990020000001
                 ],
                 [
                    97.89451579999995,
                    51.10971920000004
                 ],
                 [
                    97.89453090000005,
                    51.109338100000045
                 ],
                 [
                    97.89460680000002,
                    51.10897620000003
                 ],
                 [
                    97.8945613,
                    51.108790400000025
                 ],
                 [
                    97.8943413,
                    51.108671300000026
                 ],
                 [
                    97.89393160000002,
                    51.1085379
                 ],
                 [
                    97.893742,
                    51.1083951
                 ],
                 [
                    97.89363569999996,
                    51.10821410000003
                 ],
                 [
                    97.89371920000004,
                    51.107837799999984
                 ],
                 [
                    97.89397709999997,
                    51.10743289999997
                 ],
                 [
                    97.89404540000002,
                    51.1072519
                 ],
                 [
                    97.89399229999998,
                    51.1070661
                 ],
                 [
                    97.8936737,
                    51.106670799999975
                 ],
                 [
                    97.89362060000005,
                    51.106485
                 ],
                 [
                    97.89365849999999,
                    51.106299200000045
                 ],
                 [
                    97.89397709999997,
                    51.1058896
                 ],
                 [
                    97.89404540000002,
                    51.105703799999986
                 ],
                 [
                    97.89404540000002,
                    51.105518
                 ],
                 [
                    97.89395440000004,
                    51.1053418
                 ],
                 [
                    97.8930274,
                    51.104351
                 ],
                 [
                    97.89217770000003,
                    51.10376030000003
                 ],
                 [
                    97.89195010000003,
                    51.103641200000034
                 ],
                 [
                    97.891237,
                    51.103436299999956
                 ],
                 [
                    97.89072869999995,
                    51.10326479999998
                 ],
                 [
                    97.890357,
                    51.10304569999997
                 ],
                 [
                    97.88989420000001,
                    51.10264080000004
                 ],
                 [
                    97.88968180000002,
                    51.10231210000002
                 ],
                 [
                    97.88960590000005,
                    51.102131099999966
                 ],
                 [
                    97.889568,
                    51.10194529999996
                 ],
                 [
                    97.8896363,
                    51.10064950000004
                 ],
                 [
                    97.889697,
                    51.10046370000003
                 ],
                 [
                    97.88947690000003,
                    51.10038750000003
                 ],
                 [
                    97.88912040000002,
                    51.100411299999955
                 ],
                 [
                    97.88883209999997,
                    51.100459
                 ],
                 [
                    97.88856660000003,
                    51.10054
                 ],
                 [
                    97.88734519999996,
                    51.10104970000002
                 ],
                 [
                    97.8870569,
                    51.101097299999964
                 ],
                 [
                    97.886761,
                    51.10111159999999
                 ],
                 [
                    97.88646509999997,
                    51.101092600000015
                 ],
                 [
                    97.8854789,
                    51.1009163
                 ],
                 [
                    97.88504650000002,
                    51.100868699999964
                 ],
                 [
                    97.88475060000005,
                    51.10087820000004
                 ],
                 [
                    97.8836885,
                    51.10118310000004
                 ],
                 [
                    97.88340020000004,
                    51.10117359999997
                 ],
                 [
                    97.88310429999999,
                    51.1011354
                 ],
                 [
                    97.88282360000002,
                    51.101064
                 ],
                 [
                    97.8825657,
                    51.100963900000025
                 ],
                 [
                    97.88235330000002,
                    51.10082579999997
                 ],
                 [
                    97.88211859999996,
                    51.1005406
                 ],
                 [
                    97.88205419999996,
                    51.10040590000004
                 ],
                 [
                    97.88203269999995,
                    51.10026779999998
                 ],
                 [
                    97.88209170000005,
                    51.10013970000004
                 ],
                 [
                    97.88219370000004,
                    51.10002179999997
                 ],
                 [
                    97.88234920000004,
                    51.099924199999975
                 ],
                 [
                    97.88293390000001,
                    51.099698500000045
                 ],
                 [
                    97.8834221,
                    51.0994424
                 ],
                 [
                    97.88430190000004,
                    51.098826
                 ],
                 [
                    97.88442530000003,
                    51.0987114
                 ],
                 [
                    97.88444130000003,
                    51.09858
                 ],
                 [
                    97.88429110000004,
                    51.097751299999956
                 ],
                 [
                    97.88430720000004,
                    51.0976166
                 ],
                 [
                    97.88451640000002,
                    51.0973504
                 ],
                 [
                    97.88457010000003,
                    51.097219
                 ],
                 [
                    97.88442530000003,
                    51.09696640000003
                 ],
                 [
                    97.88425359999995,
                    51.09689560000003
                 ],
                 [
                    97.88348170000003,
                    51.09673369999996
                 ],
                 [
                    97.88318589999999,
                    51.09673369999996
                 ],
                 [
                    97.88212380000003,
                    51.096824199999986
                 ],
                 [
                    97.8818355,
                    51.0967718
                 ],
                 [
                    97.88155829999997,
                    51.096601699999965
                 ],
                 [
                    97.88069869999998,
                    51.0956199
                 ],
                 [
                    97.87991709999997,
                    51.094290100000016
                 ],
                 [
                    97.8792776,
                    51.0936207
                 ],
                 [
                    97.87934860000001,
                    51.0929692
                 ],
                 [
                    97.8799313,
                    51.09245150000003
                 ],
                 [
                    97.881196,
                    51.092165900000026
                 ],
                 [
                    97.8819634,
                    51.091764300000015
                 ],
                 [
                    97.88204870000004,
                    51.09125550000002
                 ],
                 [
                    97.8817076,
                    51.0908806
                 ],
                 [
                    97.88018710000001,
                    51.0901755
                 ],
                 [
                    97.87866649999997,
                    51.0900059
                 ],
                 [
                    97.8775296,
                    51.089631
                 ],
                 [
                    97.87680490000004,
                    51.089639900000016
                 ],
                 [
                    97.87649220000002,
                    51.089425699999964
                 ],
                 [
                    97.8765349,
                    51.089042
                 ],
                 [
                    97.87733070000003,
                    51.0884439
                 ],
                 [
                    97.87860089999997,
                    51.08799330000002
                 ],
                 [
                    97.87931149999996,
                    51.087207800000044
                 ],
                 [
                    97.87935410000001,
                    51.086806100000025
                 ],
                 [
                    97.87908409999997,
                    51.0867168
                 ],
                 [
                    97.8783877,
                    51.086788199999965
                 ],
                 [
                    97.87740719999996,
                    51.0867436
                 ],
                 [
                    97.87723080000002,
                    51.086356
                 ],
                 [
                    97.8781236,
                    51.08517780000003
                 ],
                 [
                    97.87816629999998,
                    51.0846243
                 ],
                 [
                    97.87755520000003,
                    51.08401730000003
                 ],
                 [
                    97.8766741,
                    51.08354419999996
                 ],
                 [
                    97.8765746,
                    51.08263360000002
                 ],
                 [
                    97.87546620000003,
                    51.082017600000036
                 ],
                 [
                    97.87522460000002,
                    51.081357
                 ],
                 [
                    97.87465619999998,
                    51.081205199999964
                 ],
                 [
                    97.87388879999999,
                    51.08127660000001
                 ],
                 [
                    97.8731498,
                    51.08142839999996
                 ],
                 [
                    97.87224030000002,
                    51.08119630000003
                 ],
                 [
                    97.87160080000004,
                    51.08070519999998
                 ],
                 [
                    97.87147290000003,
                    51.08018740000004
                 ],
                 [
                    97.87174289999999,
                    51.079482100000035
                 ],
                 [
                    97.87260980000003,
                    51.07927680000002
                 ],
                 [
                    97.8731782,
                    51.0789643
                 ],
                 [
                    97.87323509999999,
                    51.07830359999996
                 ],
                 [
                    97.87329189999998,
                    51.077830399999975
                 ],
                 [
                    97.8740167,
                    51.0774465
                 ],
                 [
                    97.87557990000002,
                    51.07758940000002
                 ],
                 [
                    97.8769868,
                    51.077982200000015
                 ],
                 [
                    97.87807690000004,
                    51.0778982
                 ],
                 [
                    97.87849889999995,
                    51.077500500000035
                 ],
                 [
                    97.8788406,
                    51.07729220000003
                 ],
                 [
                    97.87848890000002,
                    51.0770144
                 ],
                 [
                    97.87729310000002,
                    51.0765661
                 ],
                 [
                    97.8767304,
                    51.07614939999998
                 ],
                 [
                    97.8767605,
                    51.0757075
                 ],
                 [
                    97.87710220000004,
                    51.0748679
                 ],
                 [
                    97.8770921,
                    51.0741734
                 ],
                 [
                    97.87680070000005,
                    51.07380719999998
                 ],
                 [
                    97.87560489999996,
                    51.073396799999955
                 ],
                 [
                    97.87552449999997,
                    51.0727907
                 ],
                 [
                    97.87570540000002,
                    51.07249390000003
                 ],
                 [
                    97.87668010000002,
                    51.072127700000024
                 ],
                 [
                    97.8774539,
                    51.07226029999998
                 ],
                 [
                    97.87793290000002,
                    51.0720597
                 ],
                 [
                    97.87824550000005,
                    51.07137210000003
                 ],
                 [
                    97.87800390000004,
                    51.07078280000003
                 ],
                 [
                    97.87735860000002,
                    51.07033889999999
                 ],
                 [
                    97.8763436,
                    51.0700232
                 ],
                 [
                    97.87623309999998,
                    51.06951170000002
                 ],
                 [
                    97.87637970000003,
                    51.06922759999996
                 ],
                 [
                    97.87721810000004,
                    51.0691614
                 ],
                 [
                    97.877545,
                    51.068947100000024
                 ],
                 [
                    97.87753079999997,
                    51.06830410000003
                 ],
                 [
                    97.877005,
                    51.06792009999996
                 ],
                 [
                    97.87431640000003,
                    51.06718670000003
                 ],
                 [
                    97.87218609999998,
                    51.0669468
                 ],
                 [
                    97.87140110000004,
                    51.06652589999998
                 ],
                 [
                    97.87106950000005,
                    51.065705
                 ],
                 [
                    97.87060729999999,
                    51.06553450000002
                 ],
                 [
                    97.86998420000005,
                    51.0655471
                 ],
                 [
                    97.8692708,
                    51.06574920000002
                 ],
                 [
                    97.86876350000003,
                    51.065683
                 ],
                 [
                    97.86772850000001,
                    51.065297799999975
                 ],
                 [
                    97.86740690000005,
                    51.06483680000002
                 ],
                 [
                    97.8674974,
                    51.064116899999966
                 ],
                 [
                    97.86855249999998,
                    51.06332750000002
                 ],
                 [
                    97.8690348,
                    51.06253809999998
                 ],
                 [
                    97.86869309999997,
                    51.0617107
                 ],
                 [
                    97.8673667,
                    51.0608582
                 ],
                 [
                    97.86706530000002,
                    51.06029620000003
                 ],
                 [
                    97.86703510000001,
                    51.059506699999986
                 ],
                 [
                    97.867638,
                    51.0591151
                 ],
                 [
                    97.8683113,
                    51.0590646
                 ],
                 [
                    97.86898460000002,
                    51.0591846
                 ],
                 [
                    97.8697336,
                    51.05906959999997
                 ],
                 [
                    97.87017410000003,
                    51.058542599999974
                 ],
                 [
                    97.8700888,
                    51.05758690000004
                 ],
                 [
                    97.86923619999997,
                    51.056916900000026
                 ],
                 [
                    97.86768719999996,
                    51.05634529999998
                 ],
                 [
                    97.86672090000005,
                    51.05606839999999
                 ],
                 [
                    97.86637979999998,
                    51.0556039
                 ],
                 [
                    97.86646510000001,
                    51.05508580000004
                 ],
                 [
                    97.8668203,
                    51.054791
                 ],
                 [
                    97.86791460000005,
                    51.05471950000002
                 ],
                 [
                    97.8685752,
                    51.0545141
                 ],
                 [
                    97.87080459999997,
                    51.05266810000002
                 ],
                 [
                    97.87140149999998,
                    51.05191770000001
                 ],
                 [
                    97.871231,
                    51.0514442
                 ],
                 [
                    97.8702788,
                    51.050952900000034
                 ],
                 [
                    97.86858770000005,
                    51.050765299999966
                 ],
                 [
                    97.8673656,
                    51.05005960000002
                 ],
                 [
                    97.86591609999998,
                    51.04881780000003
                 ],
                 [
                    97.86551820000001,
                    51.047281099999985
                 ],
                 [
                    97.865774,
                    51.04600359999998
                 ],
                 [
                    97.86512030000003,
                    51.045610500000045
                 ],
                 [
                    97.864026,
                    51.04552120000003
                 ],
                 [
                    97.863145,
                    51.045556899999966
                 ],
                 [
                    97.86237760000003,
                    51.04594110000004
                 ],
                 [
                    97.86035960000004,
                    51.04604830000003
                 ],
                 [
                    97.85785850000005,
                    51.04586069999996
                 ],
                 [
                    97.85636640000004,
                    51.045271
                 ],
                 [
                    97.85575530000001,
                    51.04433290000003
                 ],
                 [
                    97.85564160000003,
                    51.04380570000002
                 ],
                 [
                    97.85680689999998,
                    51.04345719999996
                 ],
                 [
                    97.85781589999998,
                    51.04304619999997
                 ],
                 [
                    97.85787269999997,
                    51.0421706
                 ],
                 [
                    97.8575885,
                    51.041402200000014
                 ],
                 [
                    97.856878,
                    51.04082140000001
                 ],
                 [
                    97.85489470000005,
                    51.04065860000004
                 ],
                 [
                    97.85202080000002,
                    51.0394076
                 ],
                 [
                    97.84866459999998,
                    51.03806810000002
                 ],
                 [
                    97.8461066,
                    51.03617360000003
                 ],
                 [
                    97.84458610000003,
                    51.034341599999976
                 ],
                 [
                    97.84454340000005,
                    51.0337965
                 ],
                 [
                    97.84508339999996,
                    51.03337649999997
                 ],
                 [
                    97.84708720000002,
                    51.03326919999997
                 ],
                 [
                    97.848423,
                    51.03305469999999
                 ],
                 [
                    97.84948880000005,
                    51.0322594
                 ],
                 [
                    97.84930410000004,
                    51.031499800000034
                 ],
                 [
                    97.848693,
                    51.03098140000004
                 ],
                 [
                    97.84745670000004,
                    51.030731200000034
                 ],
                 [
                    97.84661820000002,
                    51.0310172
                 ],
                 [
                    97.84529660000001,
                    51.03112439999997
                 ],
                 [
                    97.84329289999997,
                    51.03070440000003
                 ],
                 [
                    97.83893010000003,
                    51.028680800000025
                 ],
                 [
                    97.83591739999997,
                    51.0280998
                 ],
                 [
                    97.83431159999998,
                    51.02759929999998
                 ],
                 [
                    97.8337858,
                    51.02651780000002
                 ],
                 [
                    97.83279430000003,
                    51.026117400000025
                 ],
                 [
                    97.83213869999996,
                    51.025618400000035
                 ],
                 [
                    97.83177220000002,
                    51.0252348
                 ],
                 [
                    97.83162660000005,
                    51.0249577
                 ],
                 [
                    97.8310688,
                    51.02445929999997
                 ],
                 [
                    97.83072770000001,
                    51.02408840000001
                 ],
                 [
                    97.83072060000005,
                    51.02390070000002
                 ],
                 [
                    97.8309089,
                    51.02369289999997
                 ],
                 [
                    97.83114689999996,
                    51.023585599999976
                 ],
                 [
                    97.83146310000002,
                    51.023610199999965
                 ],
                 [
                    97.83175439999997,
                    51.0237823
                 ],
                 [
                    97.83217720000005,
                    51.0238575
                 ],
                 [
                    97.83262939999997,
                    51.0237627
                 ],
                 [
                    97.83310670000003,
                    51.0234056
                 ],
                 [
                    97.83334290000003,
                    51.02282850000004
                 ],
                 [
                    97.83339620000001,
                    51.02242629999998
                 ],
                 [
                    97.83327180000002,
                    51.0221514
                 ],
                 [
                    97.83288809999998,
                    51.02195919999996
                 ],
                 [
                    97.83228420000002,
                    51.02184749999996
                 ],
                 [
                    97.83163050000002,
                    51.021827399999985
                 ],
                 [
                    97.83114020000004,
                    51.02179160000004
                 ],
                 [
                    97.83073310000002,
                    51.02157120000001
                 ],
                 [
                    97.83035650000004,
                    51.02087170000004
                 ],
                 [
                    97.82981850000002,
                    51.020380399999986
                 ],
                 [
                    97.82889479999997,
                    51.01958479999997
                 ],
                 [
                    97.8280138,
                    51.0188294
                 ],
                 [
                    97.8271895,
                    51.017671700000044
                 ],
                 [
                    97.82693330000004,
                    51.01688780000002
                 ],
                 [
                    97.8269147,
                    51.0163486
                 ],
                 [
                    97.82675119999998,
                    51.015937399999984
                 ],
                 [
                    97.8267406,
                    51.0157228
                 ],
                 [
                    97.82691820000002,
                    51.01548370000002
                 ],
                 [
                    97.82792930000002,
                    51.0142887
                 ],
                 [
                    97.82840529999997,
                    51.013273899999966
                 ],
                 [
                    97.82840529999997,
                    51.01284029999997
                 ],
                 [
                    97.828114,
                    51.01186420000003
                 ],
                 [
                    97.82773219999997,
                    51.011383699999975
                 ],
                 [
                    97.827069,
                    51.011055
                 ],
                 [
                    97.82581790000003,
                    51.0108685
                 ],
                 [
                    97.82508939999997,
                    51.010659800000035
                 ],
                 [
                    97.82466229999999,
                    51.01021090000002
                 ],
                 [
                    97.82457690000003,
                    51.00982839999999
                 ],
                 [
                    97.82476580000002,
                    51.00950450000001
                 ],
                 [
                    97.82513760000005,
                    51.009144100000036
                 ],
                 [
                    97.8257958,
                    51.00889749999997
                 ],
                 [
                    97.82669509999995,
                    51.00854349999999
                 ],
                 [
                    97.82787079999997,
                    51.00791119999999
                 ],
                 [
                    97.82883049999998,
                    51.0073896
                 ],
                 [
                    97.82940320000003,
                    51.00696910000002
                 ],
                 [
                    97.82962430000002,
                    51.0064664
                 ],
                 [
                    97.82949870000002,
                    51.00613449999997
                 ],
                 [
                    97.82911360000004,
                    51.005781299999974
                 ],
                 [
                    97.82814390000001,
                    51.005326
                 ],
                 [
                    97.82778220000002,
                    51.0047222
                 ],
                 [
                    97.82777209999998,
                    51.004292200000044
                 ],
                 [
                    97.828169,
                    51.003764199999964
                 ],
                 [
                    97.8288574,
                    51.003173
                 ],
                 [
                    97.83009330000002,
                    51.00265759999998
                 ],
                 [
                    97.831264,
                    51.002360399999986
                 ],
                 [
                    97.832304,
                    51.002332
                 ],
                 [
                    97.83323860000002,
                    51.00236989999998
                 ],
                 [
                    97.83362039999999,
                    51.002256099999975
                 ],
                 [
                    97.83391270000001,
                    51.00199959999998
                 ],
                 [
                    97.83442270000003,
                    51.0012795
                 ],
                 [
                    97.83475230000003,
                    51.001119099999954
                 ],
                 [
                    97.83556690000002,
                    51.0010016
                 ],
                 [
                    97.83700349999995,
                    51.000911600000016
                 ],
                 [
                    97.83791770000002,
                    51.0007277
                 ],
                 [
                    97.8388256,
                    51.000289399999964
                 ],
                 [
                    97.83976469999998,
                    50.99986280000002
                 ],
                 [
                    97.84097109999996,
                    50.999115299999985
                 ],
                 [
                    97.84160540000005,
                    50.9984617
                 ],
                 [
                    97.84209670000001,
                    50.998035100000045
                 ],
                 [
                    97.8431291,
                    50.9976084
                 ],
                 [
                    97.84382559999999,
                    50.9971349
                 ],
                 [
                    97.84470870000004,
                    50.99679830000003
                 ],
                 [
                    97.8457783,
                    50.996594699999974
                 ],
                 [
                    97.84787410000003,
                    50.996493
                 ],
                 [
                    97.84908050000001,
                    50.99637950000002
                 ],
                 [
                    97.84970240000004,
                    50.99614069999998
                 ],
                 [
                    97.84999469999998,
                    50.9958628
                 ],
                 [
                    97.85024970000003,
                    50.99546749999997
                 ],
                 [
                    97.85043,
                    50.994684700000036
                 ],
                 [
                    97.85084670000005,
                    50.9943559
                 ],
                 [
                    97.85190390000002,
                    50.99390970000004
                 ],
                 [
                    97.85248230000002,
                    50.993596599999975
                 ],
                 [
                    97.85268129999996,
                    50.99312290000004
                 ],
                 [
                    97.8525755,
                    50.99209740000001
                 ],
                 [
                    97.85235170000004,
                    50.99152589999998
                 ],
                 [
                    97.85232680000003,
                    50.9912676
                 ],
                 [
                    97.8525133,
                    50.99087610000004
                 ],
                 [
                    97.8536327,
                    50.9899601
                 ],
                 [
                    97.8540494,
                    50.98949819999998
                 ],
                 [
                    97.85425460000002,
                    50.98889540000002
                 ],
                 [
                    97.8543541,
                    50.98782669999997
                 ],
                 [
                    97.85393749999999,
                    50.98718860000002
                 ],
                 [
                    97.8537198,
                    50.98678539999997
                 ],
                 [
                    97.85361410000004,
                    50.98642130000004
                 ],
                 [
                    97.8538504,
                    50.986029799999976
                 ],
                 [
                    97.85424219999997,
                    50.9853878
                 ],
                 [
                    97.85424840000003,
                    50.984362
                 ],
                 [
                    97.85432929999997,
                    50.98387270000002
                 ],
                 [
                    97.8546589,
                    50.98319540000002
                 ],
                 [
                    97.854833,
                    50.982565
                 ],
                 [
                    97.8551937,
                    50.98198169999996
                 ],
                 [
                    97.8555233,
                    50.98168019999998
                 ],
                 [
                    97.85592749999996,
                    50.981594100000045
                 ],
                 [
                    97.857022,
                    50.98152750000004
                 ],
                 [
                    97.85741380000002,
                    50.9813983
                 ],
                 [
                    97.85763769999998,
                    50.98110469999997
                 ],
                 [
                    97.8580855,
                    50.98043909999996
                 ],
                 [
                    97.85860159999997,
                    50.97987530000003
                 ],
                 [
                    97.859323,
                    50.979495499999956
                 ],
                 [
                    97.86054190000003,
                    50.97907650000002
                 ],
                 [
                    97.86070979999997,
                    50.9788455
                 ],
                 [
                    97.8608529,
                    50.9781799
                 ],
                 [
                    97.86114510000002,
                    50.977788300000014
                 ],
                 [
                    97.86143740000004,
                    50.97742030000003
                 ],
                 [
                    97.8614561,
                    50.976852500000035
                 ],
                 [
                    97.86148719999998,
                    50.97587750000001
                 ],
                 [
                    97.8619349,
                    50.9749886
                 ],
                 [
                    97.8619349,
                    50.97461269999999
                 ],
                 [
                    97.86179189999997,
                    50.97424849999996
                 ],
                 [
                    97.8611825,
                    50.973763
                 ],
                 [
                    97.86078440000001,
                    50.9734889
                 ],
                 [
                    97.8601937,
                    50.9728858
                 ],
                 [
                    97.8592049,
                    50.972271
                 ],
                 [
                    97.85858920000001,
                    50.97207130000003
                 ],
                 [
                    97.85821609999996,
                    50.9717854
                 ],
                 [
                    97.85778950000002,
                    50.971306
                 ],
                 [
                    97.85724850000003,
                    50.97097320000003
                 ],
                 [
                    97.85691890000001,
                    50.97075779999997
                 ],
                 [
                    97.85639650000005,
                    50.97028390000002
                 ],
                 [
                    97.8560478,
                    50.96988040000003
                 ],
                 [
                    97.85589390000003,
                    50.96954540000002
                 ],
                 [
                    97.85563879999997,
                    50.96889180000004
                 ],
                 [
                    97.85571450000002,
                    50.968404699999965
                 ],
                 [
                    97.85573649999998,
                    50.968133299999984
                 ],
                 [
                    97.85557820000002,
                    50.9679755
                 ],
                 [
                    97.8552484,
                    50.967759499999964
                 ],
                 [
                    97.85514910000005,
                    50.9674916
                 ],
                 [
                    97.85520189999997,
                    50.966935700000015
                 ],
                 [
                    97.85538659999997,
                    50.9667916
                 ],
                 [
                    97.85561529999998,
                    50.9667584
                 ],
                 [
                    97.85636720000002,
                    50.96680830000004
                 ],
                 [
                    97.85716749999997,
                    50.96671690000003
                 ],
                 [
                    97.857726,
                    50.96655350000001
                 ],
                 [
                    97.85801620000004,
                    50.96628479999998
                 ],
                 [
                    97.8580866,
                    50.9660134
                 ],
                 [
                    97.85793709999997,
                    50.9656645
                 ],
                 [
                    97.85764690000003,
                    50.96527119999996
                 ],
                 [
                    97.85684209999997,
                    50.96460660000004
                 ],
                 [
                    97.85660470000005,
                    50.9643656
                 ],
                 [
                    97.8564947,
                    50.963822800000024
                 ],
                 [
                    97.85664860000003,
                    50.9627759
                 ],
                 [
                    97.85656949999998,
                    50.962366
                 ],
                 [
                    97.8564389,
                    50.9619547
                 ],
                 [
                    97.85651350000005,
                    50.96172760000004
                 ],
                 [
                    97.8571603,
                    50.960952
                 ],
                 [
                    97.857521,
                    50.960129399999964
                 ],
                 [
                    97.85799360000003,
                    50.9595889
                 ],
                 [
                    97.85846619999997,
                    50.95942830000004
                 ],
                 [
                    97.8591503,
                    50.95933429999999
                 ],
                 [
                    97.86018269999997,
                    50.959393
                 ],
                 [
                    97.861557,
                    50.959267699999984
                 ],
                 [
                    97.86306819999997,
                    50.958958199999955
                 ],
                 [
                    97.86367769999995,
                    50.958699699999976
                 ],
                 [
                    97.8644177,
                    50.957939799999984
                 ],
                 [
                    97.8648095,
                    50.9572229
                 ],
                 [
                    97.8653754,
                    50.9567528
                 ],
                 [
                    97.86644040000002,
                    50.95621760000004
                 ],
                 [
                    97.86723319999997,
                    50.955592099999976
                 ],
                 [
                    97.8675255,
                    50.95512590000004
                 ],
                 [
                    97.86793,
                    50.9540788
                 ],
                 [
                    97.86814989999998,
                    50.9530428
                 ],
                 [
                    97.86833459999998,
                    50.9519458
                 ],
                 [
                    97.8682006,
                    50.9507306
                 ],
                 [
                    97.868269,
                    50.94946909999997
                 ],
                 [
                    97.868325,
                    50.94882650000001
                 ],
                 [
                    97.86854269999996,
                    50.94839950000003
                 ],
                 [
                    97.86895929999997,
                    50.948082099999965
                 ],
                 [
                    97.87024659999999,
                    50.94780390000004
                 ],
                 [
                    97.87165210000002,
                    50.9475532
                 ],
                 [
                    97.87288970000002,
                    50.9470595
                 ],
                 [
                    97.87390960000002,
                    50.94700859999996
                 ],
                 [
                    97.87557,
                    50.9470242
                 ],
                 [
                    97.87694440000004,
                    50.94684789999999
                 ],
                 [
                    97.87871049999997,
                    50.94644040000002
                 ],
                 [
                    97.87934489999996,
                    50.94611520000003
                 ],
                 [
                    97.87952519999996,
                    50.94580959999997
                 ],
                 [
                    97.87956250000003,
                    50.9454922
                 ],
                 [
                    97.87948170000001,
                    50.94509650000003
                 ],
                 [
                    97.87895309999998,
                    50.944402900000014
                 ],
                 [
                    97.878524,
                    50.94381910000003
                 ],
                 [
                    97.87807,
                    50.9430824
                 ],
                 [
                    97.87759109999999,
                    50.94231830000002
                 ],
                 [
                    97.87756,
                    50.94195
                 ],
                 [
                    97.8776782,
                    50.94159729999996
                 ],
                 [
                    97.87822549999999,
                    50.94126029999997
                 ],
                 [
                    97.87925159999998,
                    50.94075479999997
                 ],
                 [
                    97.87983610000002,
                    50.94064119999999
                 ],
                 [
                    97.88042689999996,
                    50.940727400000014
                 ],
                 [
                    97.88134110000003,
                    50.9410761
                 ],
                 [
                    97.88219929999998,
                    50.94131910000004
                 ],
                 [
                    97.88308860000002,
                    50.94140920000003
                 ],
                 [
                    97.88359860000003,
                    50.941299500000014
                 ],
                 [
                    97.883953,
                    50.941037
                 ],
                 [
                    97.88408360000001,
                    50.940696
                 ],
                 [
                    97.88389080000005,
                    50.93991620000004
                 ],
                 [
                    97.88376649999998,
                    50.93941460000002
                 ],
                 [
                    97.8837292,
                    50.938576
                 ],
                 [
                    97.88385349999997,
                    50.937960699999984
                 ],
                 [
                    97.88412089999998,
                    50.93686730000002
                 ],
                 [
                    97.88457489999998,
                    50.93593460000003
                 ],
                 [
                    97.8849916,
                    50.935382
                 ],
                 [
                    97.885657,
                    50.935045
                 ],
                 [
                    97.88677639999997,
                    50.93497050000002
                 ],
                 [
                    97.88714330000003,
                    50.93482939999998
                 ],
                 [
                    97.8872117,
                    50.934586400000036
                 ],
                 [
                    97.88686970000005,
                    50.933928
                 ],
                 [
                    97.8866458,
                    50.93325
                 ],
                 [
                    97.886509,
                    50.932376
                 ],
                 [
                    97.88637839999998,
                    50.93147059999998
                 ],
                 [
                    97.8864344,
                    50.9309179
                 ],
                 [
                    97.88668930000004,
                    50.93039660000002
                 ],
                 [
                    97.88704379999997,
                    50.93011840000001
                 ],
                 [
                    97.8875786,
                    50.929891
                 ],
                 [
                    97.88836219999999,
                    50.929793
                 ],
                 [
                    97.88964950000002,
                    50.92973420000002
                 ],
                 [
                    97.89016329999998,
                    50.92968639999997
                 ],
                 [
                    97.8907067,
                    50.9295892
                 ],
                 [
                    97.89114390000003,
                    50.9296116
                 ],
                 [
                    97.89193539999997,
                    50.92981109999999
                 ],
                 [
                    97.89259940000002,
                    50.92984720000004
                 ],
                 [
                    97.89324140000002,
                    50.92978619999997
                 ],
                 [
                    97.89361080000003,
                    50.929653199999976
                 ],
                 [
                    97.89374270000002,
                    50.929509
                 ],
                 [
                    97.8936768,
                    50.929049
                 ],
                 [
                    97.89368560000001,
                    50.92856110000003
                 ],
                 [
                    97.8938175,
                    50.9283976
                 ],
                 [
                    97.8940989,
                    50.92832
                 ],
                 [
                    97.8945035,
                    50.928339399999984
                 ],
                 [
                    97.8951367,
                    50.92856110000003
                 ],
                 [
                    97.89604259999999,
                    50.928879900000034
                 ],
                 [
                    97.89648669999995,
                    50.92892419999997
                 ],
                 [
                    97.89676819999998,
                    50.92888820000001
                 ],
                 [
                    97.89698360000004,
                    50.928769
                 ],
                 [
                    97.897375,
                    50.928381
                 ],
                 [
                    97.89822809999998,
                    50.928004
                 ],
                 [
                    97.89956930000001,
                    50.9275689
                 ],
                 [
                    97.90025970000003,
                    50.927203
                 ],
                 [
                    97.90038720000001,
                    50.92684269999996
                 ],
                 [
                    97.90028609999999,
                    50.926523899999964
                 ],
                 [
                    97.899798,
                    50.926133100000044
                 ],
                 [
                    97.8992439,
                    50.92595020000001
                 ],
                 [
                    97.89887009999998,
                    50.92590310000002
                 ],
                 [
                    97.89846119999999,
                    50.925989
                 ],
                 [
                    97.8981533,
                    50.926244
                 ],
                 [
                    97.89797740000002,
                    50.926621
                 ],
                 [
                    97.89776639999997,
                    50.927122600000025
                 ],
                 [
                    97.89756410000001,
                    50.927446900000035
                 ],
                 [
                    97.89733980000003,
                    50.92752730000003
                 ],
                 [
                    97.89693970000005,
                    50.927510699999985
                 ],
                 [
                    97.89635919999998,
                    50.927269500000016
                 ],
                 [
                    97.89589309999995,
                    50.926801099999985
                 ],
                 [
                    97.89558529999998,
                    50.92646020000002
                 ],
                 [
                    97.89513229999999,
                    50.926027800000035
                 ],
                 [
                    97.89478490000002,
                    50.9256592
                 ],
                 [
                    97.89466179999995,
                    50.92539860000002
                 ],
                 [
                    97.8947322,
                    50.924838699999974
                 ],
                 [
                    97.89489050000005,
                    50.92437020000002
                 ],
                 [
                    97.89508829999998,
                    50.924256600000035
                 ],
                 [
                    97.89533020000002,
                    50.92422330000004
                 ],
                 [
                    97.8958095,
                    50.92431760000002
                 ],
                 [
                    97.8965923,
                    50.9244783
                 ],
                 [
                    97.89756410000001,
                    50.92448109999996
                 ],
                 [
                    97.8981973,
                    50.92444789999997
                 ],
                 [
                    97.89857550000004,
                    50.92445620000003
                 ],
                 [
                    97.89884370000001,
                    50.92456150000004
                 ],
                 [
                    97.8992043,
                    50.92474170000003
                 ],
                 [
                    97.89957810000003,
                    50.924777699999986
                 ],
                 [
                    97.9000574,
                    50.9246779
                 ],
                 [
                    97.9005235,
                    50.9243869
                 ],
                 [
                    97.9007434,
                    50.92395170000003
                 ],
                 [
                    97.90071260000002,
                    50.9236024
                 ],
                 [
                    97.90063349999996,
                    50.9229787
                 ],
                 [
                    97.90079620000002,
                    50.922798600000014
                 ],
                 [
                    97.90098970000004,
                    50.9227209
                 ],
                 [
                    97.9013898,
                    50.92268209999997
                 ],
                 [
                    97.90179,
                    50.9227265
                 ],
                 [
                    97.90214179999997,
                    50.922887299999985
                 ],
                 [
                    97.90229129999999,
                    50.923122900000024
                 ],
                 [
                    97.90219889999999,
                    50.92356359999999
                 ],
                 [
                    97.90223409999997,
                    50.924101399999984
                 ],
                 [
                    97.90243640000001,
                    50.92440069999997
                 ],
                 [
                    97.9027838,
                    50.924547599999954
                 ],
                 [
                    97.9031048,
                    50.9245781
                 ],
                 [
                    97.9039359,
                    50.924439499999984
                 ],
                 [
                    97.90464829999996,
                    50.92413459999998
                 ],
                 [
                    97.90527710000003,
                    50.92383250000003
                 ],
                 [
                    97.90597629999998,
                    50.92366339999998
                 ],
                 [
                    97.90675029999998,
                    50.92362740000002
                 ],
                 [
                    97.9074011,
                    50.9237549
                 ],
                 [
                    97.90804750000002,
                    50.924037600000034
                 ],
                 [
                    97.9084037,
                    50.9243786
                 ],
                 [
                    97.90857519999999,
                    50.924691799999984
                 ],
                 [
                    97.90883460000005,
                    50.92526
                 ],
                 [
                    97.90927880000002,
                    50.9256148
                 ],
                 [
                    97.9098988,
                    50.9260389
                 ],
                 [
                    97.91039129999997,
                    50.9262163
                 ],
                 [
                    97.91070349999995,
                    50.92624120000002
                 ],
                 [
                    97.91095860000001,
                    50.92619129999998
                 ],
                 [
                    97.91110810000004,
                    50.926088800000024
                 ],
                 [
                    97.91115650000005,
                    50.92594190000003
                 ],
                 [
                    97.91115650000005,
                    50.925739499999985
                 ],
                 [
                    97.91097180000004,
                    50.925418
                 ],
                 [
                    97.9104485,
                    50.9249163
                 ],
                 [
                    97.9103122,
                    50.924680700000046
                 ],
                 [
                    97.910255,
                    50.924450600000014
                 ],
                 [
                    97.91029020000005,
                    50.92428709999999
                 ],
                 [
                    97.9105056,
                    50.924123500000036
                 ],
                 [
                    97.910853,
                    50.924054199999986
                 ],
                 [
                    97.91140269999998,
                    50.9240875
                 ],
                 [
                    97.91218990000002,
                    50.9241208
                 ],
                 [
                    97.91248009999995,
                    50.924068099999985
                 ],
                 [
                    97.9127791,
                    50.92390179999999
                 ],
                 [
                    97.91308689999998,
                    50.92339179999998
                 ],
                 [
                    97.91329360000003,
                    50.923020299999976
                 ],
                 [
                    97.91345389999998,
                    50.92289579999998
                 ],
                 [
                    97.91365259999998,
                    50.9228403
                 ],
                 [
                    97.91382390000004,
                    50.9228606
                 ],
                 [
                    97.91412240000004,
                    50.9230644
                 ],
                 [
                    97.91454219999996,
                    50.923121199999976
                 ],
                 [
                    97.9150397,
                    50.923052599999984
                 ],
                 [
                    97.91537830000004,
                    50.92285580000004
                 ],
                 [
                    97.9155366,
                    50.9225537
                 ],
                 [
                    97.91555860000004,
                    50.9222044
                 ],
                 [
                    97.9153533,
                    50.9218773
                 ],
                 [
                    97.91486080000003,
                    50.92152250000004
                 ],
                 [
                    97.91419239999998,
                    50.9211261
                 ],
                 [
                    97.9140253,
                    50.9208877
                 ],
                 [
                    97.91392419999998,
                    50.9205689
                 ],
                 [
                    97.9140473,
                    50.92032770000003
                 ],
                 [
                    97.91441229999995,
                    50.92010320000002
                 ],
                 [
                    97.91478169999996,
                    50.9200449
                 ],
                 [
                    97.91532259999995,
                    50.92007540000003
                 ],
                 [
                    97.91614929999997,
                    50.920336
                 ],
                 [
                    97.91709910000004,
                    50.920782299999985
                 ],
                 [
                    97.91779389999998,
                    50.920932
                 ],
                 [
                    97.9182688,
                    50.9209403
                 ],
                 [
                    97.91877890000004,
                    50.920829399999974
                 ],
                 [
                    97.91937260000003,
                    50.920527300000025
                 ],
                 [
                    97.92044109999998,
                    50.919828699999954
                 ],
                 [
                    97.92137340000002,
                    50.91924660000002
                 ],
                 [
                    97.92224849999995,
                    50.91882519999997
                 ],
                 [
                    97.9227278,
                    50.91838160000004
                 ],
                 [
                    97.92320709999996,
                    50.917624800000034
                 ],
                 [
                    97.92365120000002,
                    50.91711749999998
                 ],
                 [
                    97.92420090000003,
                    50.9166711
                 ],
                 [
                    97.92452190000003,
                    50.91653249999999
                 ],
                 [
                    97.9248254,
                    50.91651309999998
                 ],
                 [
                    97.92517269999996,
                    50.916549100000026
                 ],
                 [
                    97.92559049999998,
                    50.9166905
                 ],
                 [
                    97.9261006,
                    50.91689839999997
                 ],
                 [
                    97.92645679999998,
                    50.916962200000015
                 ],
                 [
                    97.9267382,
                    50.9169095
                 ],
                 [
                    97.92725710000003,
                    50.91662950000002
                 ],
                 [
                    97.92838290000002,
                    50.9162664
                 ],
                 [
                    97.92964489999999,
                    50.91595310000001
                 ],
                 [
                    97.93046719999998,
                    50.91572020000001
                 ],
                 [
                    97.93087619999999,
                    50.915332099999965
                 ],
                 [
                    97.93173809999998,
                    50.91451419999997
                 ],
                 [
                    97.93213380000003,
                    50.91413160000002
                 ],
                 [
                    97.93268789999996,
                    50.913968
                 ],
                 [
                    97.93346629999998,
                    50.913347
                 ],
                 [
                    97.93373010000005,
                    50.91294770000003
                 ],
                 [
                    97.93396319999997,
                    50.91238210000004
                 ],
                 [
                    97.93445570000004,
                    50.911916300000044
                 ],
                 [
                    97.93501409999996,
                    50.91148929999998
                 ],
                 [
                    97.93542749999997,
                    50.9113285
                 ],
                 [
                    97.935986,
                    50.911334
                 ],
                 [
                    97.93642130000005,
                    50.911483799999964
                 ],
                 [
                    97.9369006,
                    50.91177489999998
                 ],
                 [
                    97.93736240000003,
                    50.912249
                 ],
                 [
                    97.93783290000005,
                    50.9127509
                 ],
                 [
                    97.93826820000001,
                    50.9130364
                 ],
                 [
                    97.93864640000004,
                    50.91309189999997
                 ],
                 [
                    97.93910370000003,
                    50.9130503
                 ],
                 [
                    97.9396248,
                    50.91285779999996
                 ],
                 [
                    97.94022379999998,
                    50.91244220000004
                 ],
                 [
                    97.94066350000004,
                    50.912298
                 ],
                 [
                    97.94107689999996,
                    50.912298
                 ],
                 [
                    97.94142870000003,
                    50.9124117
                 ],
                 [
                    97.9416441,
                    50.91258079999996
                 ],
                 [
                    97.94172769999999,
                    50.91285249999997
                 ],
                 [
                    97.9416485,
                    50.91319079999998
                 ],
                 [
                    97.94127039999998,
                    50.91374530000003
                 ],
                 [
                    97.9412264,
                    50.91411130000003
                 ],
                 [
                    97.94147260000001,
                    50.91478779999996
                 ],
                 [
                    97.94167929999998,
                    50.91497630000001
                 ],
                 [
                    97.94196960000002,
                    50.9150373
                 ],
                 [
                    97.9422774,
                    50.9150234
                 ],
                 [
                    97.942616,
                    50.914829300000015
                 ],
                 [
                    97.942849,
                    50.9144856
                 ],
                 [
                    97.94302930000003,
                    50.91384229999998
                 ],
                 [
                    97.94339430000004,
                    50.91340710000003
                 ],
                 [
                    97.94368889999998,
                    50.91322680000003
                 ],
                 [
                    97.94416830000004,
                    50.91309929999996
                 ],
                 [
                    97.94439690000003,
                    50.91299390000003
                 ],
                 [
                    97.94448050000003,
                    50.91287470000003
                 ],
                 [
                    97.9444233,
                    50.91273610000002
                 ],
                 [
                    97.9441902,
                    50.9125448
                 ],
                 [
                    97.94338990000003,
                    50.912237
                 ],
                 [
                    97.9430161,
                    50.91208729999998
                 ],
                 [
                    97.9425984,
                    50.911715799999975
                 ],
                 [
                    97.94240489999997,
                    50.91123890000004
                 ],
                 [
                    97.94211470000003,
                    50.9107703
                 ],
                 [
                    97.9417321,
                    50.91063440000003
                 ],
                 [
                    97.94143750000005,
                    50.91051239999997
                 ],
                 [
                    97.941288,
                    50.91028780000004
                 ],
                 [
                    97.94130549999996,
                    50.909969
                 ],
                 [
                    97.9415078,
                    50.909408899999974
                 ],
                 [
                    97.94221140000003,
                    50.9087351
                 ],
                 [
                    97.94267730000004,
                    50.90858679999999
                 ],
                 [
                    97.94299759999998,
                    50.90854760000003
                 ],
                 [
                    97.94330229999997,
                    50.908610399999986
                 ],
                 [
                    97.94349820000002,
                    50.9087652
                 ],
                 [
                    97.943576,
                    50.90900050000002
                 ],
                 [
                    97.94349070000001,
                    50.90935270000003
                 ],
                 [
                    97.94313310000001,
                    50.90982849999996
                 ],
                 [
                    97.94306470000004,
                    50.91018140000003
                 ],
                 [
                    97.94330530000005,
                    50.91057650000003
                 ],
                 [
                    97.9436351,
                    50.910848199999954
                 ],
                 [
                    97.94403090000004,
                    50.9109508
                 ],
                 [
                    97.9444222,
                    50.9109979
                 ],
                 [
                    97.944818,
                    50.910959099999985
                 ],
                 [
                    97.9452006,
                    50.91083429999996
                 ],
                 [
                    97.9456491,
                    50.9105321
                 ],
                 [
                    97.9457942,
                    50.9102271
                 ],
                 [
                    97.94585580000005,
                    50.909814
                 ],
                 [
                    97.94571510000003,
                    50.90950070000002
                 ],
                 [
                    97.94506430000001,
                    50.9090958
                 ],
                 [
                    97.9443299,
                    50.908746499999964
                 ],
                 [
                    97.9440089,
                    50.90854130000004
                 ],
                 [
                    97.94391050000003,
                    50.90819809999998
                 ],
                 [
                    97.94404680000002,
                    50.907834800000025
                 ],
                 [
                    97.94443809999999,
                    50.90765179999998
                 ],
                 [
                    97.94496580000003,
                    50.90752980000001
                 ],
                 [
                    97.9457706,
                    50.907560299999965
                 ],
                 [
                    97.94665879999995,
                    50.90772669999996
                 ],
                 [
                    97.9475955,
                    50.90807330000004
                 ],
                 [
                    97.94844419999998,
                    50.9081898
                 ],
                 [
                    97.94933250000003,
                    50.908106600000046
                 ],
                 [
                    97.950357,
                    50.907773800000044
                 ],
                 [
                    97.95073020000002,
                    50.90762650000002
                 ],
                 [
                    97.950761,
                    50.907450400000016
                 ],
                 [
                    97.95064389999999,
                    50.90724
                 ],
                 [
                    97.95032360000005,
                    50.9070949
                 ],
                 [
                    97.9499054,
                    50.907054
                 ],
                 [
                    97.949127,
                    50.9071926
                 ],
                 [
                    97.94814640000003,
                    50.9073396
                 ],
                 [
                    97.94715259999995,
                    50.90737840000001
                 ],
                 [
                    97.94663810000002,
                    50.90727020000003
                 ],
                 [
                    97.94636980000003,
                    50.9070956
                 ],
                 [
                    97.94625109999997,
                    50.906834899999986
                 ],
                 [
                    97.94633030000004,
                    50.906457799999956
                 ],
                 [
                    97.94671279999999,
                    50.905942
                 ],
                 [
                    97.94730209999999,
                    50.90549829999997
                 ],
                 [
                    97.94779459999997,
                    50.905343
                 ],
                 [
                    97.94825629999997,
                    50.90531809999998
                 ],
                 [
                    97.9487708,
                    50.90547340000004
                 ],
                 [
                    97.94932050000001,
                    50.9056564
                 ],
                 [
                    97.94983940000004,
                    50.9056897
                 ],
                 [
                    97.95021319999998,
                    50.9056425
                 ],
                 [
                    97.95065730000003,
                    50.90543729999999
                 ],
                 [
                    97.950864,
                    50.90518770000003
                 ],
                 [
                    97.950908,
                    50.904952
                 ],
                 [
                    97.95078480000002,
                    50.9045305
                 ],
                 [
                    97.95050780000001,
                    50.904134
                 ],
                 [
                    97.9503627,
                    50.9037125
                 ],
                 [
                    97.95043739999996,
                    50.90303860000002
                 ],
                 [
                    97.9505034,
                    50.902589299999974
                 ],
                 [
                    97.95041550000002,
                    50.9022954
                 ],
                 [
                    97.95012079999997,
                    50.901954300000014
                 ],
                 [
                    97.9497251,
                    50.901746300000035
                 ],
                 [
                    97.949149,
                    50.90164369999999
                 ],
                 [
                    97.94806719999995,
                    50.901660300000025
                 ],
                 [
                    97.94756589999996,
                    50.90159930000004
                 ],
                 [
                    97.94732229999997,
                    50.901489
                 ],
                 [
                    97.9472539,
                    50.90130859999997
                 ],
                 [
                    97.94735339999997,
                    50.901069299999975
                 ],
                 [
                    97.947739,
                    50.9007399
                 ],
                 [
                    97.94819920000005,
                    50.90050459999997
                 ],
                 [
                    97.94909469999997,
                    50.90034379999999
                 ],
                 [
                    97.95096659999996,
                    50.900084899999975
                 ],
                 [
                    97.95232230000005,
                    50.899700499999966
                 ],
                 [
                    97.95318669999997,
                    50.899178899999974
                 ],
                 [
                    97.95353180000002,
                    50.898829800000044
                 ],
                 [
                    97.953566,
                    50.89857290000001
                 ],
                 [
                    97.95350080000003,
                    50.89819439999996
                 ],
                 [
                    97.95361269999997,
                    50.89795710000004
                 ],
                 [
                    97.95392670000001,
                    50.89774530000002
                 ],
                 [
                    97.9542626,
                    50.8976806
                 ],
                 [
                    97.9546544,
                    50.89772959999996
                 ],
                 [
                    97.9552361,
                    50.8979554
                 ],
                 [
                    97.95571979999995,
                    50.898316
                 ],
                 [
                    97.95597930000002,
                    50.8986543
                 ],
                 [
                    97.95600249999998,
                    50.89885880000004
                 ],
                 [
                    97.95586260000005,
                    50.8991412
                 ],
                 [
                    97.9558782,
                    50.89928040000002
                 ],
                 [
                    97.95607089999996,
                    50.8995
                 ],
                 [
                    97.95664390000003,
                    50.899676
                 ],
                 [
                    97.95764210000003,
                    50.89982580000003
                 ],
                 [
                    97.9581258,
                    50.9000144
                 ],
                 [
                    97.95854360000001,
                    50.9004359
                 ],
                 [
                    97.95886460000001,
                    50.90060510000003
                 ],
                 [
                    97.9591812,
                    50.90064390000004
                 ],
                 [
                    97.9595286,
                    50.9006467
                 ],
                 [
                    97.95983640000004,
                    50.90055240000002
                 ],
                 [
                    97.960131,
                    50.90033059999996
                 ],
                 [
                    97.96020140000005,
                    50.900081
                 ],
                 [
                    97.96018380000001,
                    50.899823
                 ],
                 [
                    97.96002989999997,
                    50.89956510000004
                 ],
                 [
                    97.95978360000002,
                    50.899468
                 ],
                 [
                    97.95907569999999,
                    50.8993627
                 ],
                 [
                    97.95857,
                    50.89927390000002
                 ],
                 [
                    97.95815659999997,
                    50.89909920000004
                 ],
                 [
                    97.95779159999996,
                    50.89876359999998
                 ],
                 [
                    97.95748379999998,
                    50.898084099999984
                 ],
                 [
                    97.957154,
                    50.89754880000001
                 ],
                 [
                    97.95654280000002,
                    50.896947
                 ],
                 [
                    97.956327,
                    50.8966759
                 ],
                 [
                    97.956299,
                    50.89637
                 ],
                 [
                    97.95650110000003,
                    50.89607969999996
                 ],
                 [
                    97.95685870000004,
                    50.895944399999955
                 ],
                 [
                    97.95720689999999,
                    50.89588950000003
                 ],
                 [
                    97.9576889,
                    50.89588750000004
                 ],
                 [
                    97.9581367,
                    50.8959836
                 ],
                 [
                    97.95843210000002,
                    50.896162100000026
                 ],
                 [
                    97.9587181,
                    50.8964504
                 ],
                 [
                    97.95871189999998,
                    50.89670530000003
                 ],
                 [
                    97.9587679,
                    50.8969976
                 ],
                 [
                    97.95903530000001,
                    50.89721530000004
                 ],
                 [
                    97.95933559999999,
                    50.897246400000036
                 ],
                 [
                    97.95964190000002,
                    50.89720379999998
                 ],
                 [
                    97.9599466,
                    50.89698420000001
                 ],
                 [
                    97.960155,
                    50.8969293
                 ],
                 [
                    97.96042860000001,
                    50.89692139999997
                 ],
                 [
                    97.96106909999997,
                    50.896927300000016
                 ],
                 [
                    97.96140809999996,
                    50.8968155
                 ],
                 [
                    97.96152929999995,
                    50.89665859999999
                 ],
                 [
                    97.96155729999997,
                    50.89644479999997
                 ],
                 [
                    97.96146089999998,
                    50.89616640000003
                 ],
                 [
                    97.9612153,
                    50.89605060000004
                 ],
                 [
                    97.960867,
                    50.89591139999998
                 ],
                 [
                    97.960724,
                    50.895729
                 ],
                 [
                    97.9606431,
                    50.895452500000026
                 ],
                 [
                    97.9606711,
                    50.89521320000003
                 ],
                 [
                    97.96081730000002,
                    50.8951151
                 ],
                 [
                    97.96115,
                    50.8950465
                 ],
                 [
                    97.96159460000003,
                    50.895072
                 ],
                 [
                    97.96224759999997,
                    50.89525439999998
                 ],
                 [
                    97.96282910000004,
                    50.895272
                 ],
                 [
                    97.96380290000005,
                    50.8949841
                 ],
                 [
                    97.9643701,
                    50.8946096
                 ],
                 [
                    97.964911,
                    50.8944099
                 ],
                 [
                    97.96559259999998,
                    50.8943766
                 ],
                 [
                    97.96599279999997,
                    50.89421580000002
                 ],
                 [
                    97.96622579999996,
                    50.8940493
                 ],
                 [
                    97.96635340000005,
                    50.89377470000002
                 ],
                 [
                    97.96640609999996,
                    50.89348910000002
                 ],
                 [
                    97.96627419999997,
                    50.8931257
                 ],
                 [
                    97.96580370000004,
                    50.8927928
                 ],
                 [
                    97.9651924,
                    50.8924101
                 ],
                 [
                    97.96500339999999,
                    50.891799800000044
                 ],
                 [
                    97.96481869999998,
                    50.891231199999986
                 ],
                 [
                    97.96452840000003,
                    50.890604299999985
                 ],
                 [
                    97.96433060000001,
                    50.88991079999998
                 ],
                 [
                    97.96431209999999,
                    50.889574500000045
                 ],
                 [
                    97.964536,
                    50.88942740000004
                 ],
                 [
                    97.96488739999998,
                    50.88932339999996
                 ],
                 [
                    97.9651517,
                    50.88928030000004
                 ],
                 [
                    97.965444,
                    50.889360700000026
                 ],
                 [
                    97.96585129999998,
                    50.88970589999999
                 ],
                 [
                    97.96628790000004,
                    50.890341099999965
                 ],
                 [
                    97.96647260000005,
                    50.890990200000026
                 ],
                 [
                    97.9666705,
                    50.89127590000004
                 ],
                 [
                    97.9670795,
                    50.89148119999997
                 ],
                 [
                    97.96760710000004,
                    50.89148119999997
                 ],
                 [
                    97.9681348,
                    50.8913065
                 ],
                 [
                    97.96882520000001,
                    50.890932
                 ],
                 [
                    97.96933970000003,
                    50.89066290000004
                 ],
                 [
                    97.9704127,
                    50.89040769999997
                 ],
                 [
                    97.97152959999995,
                    50.890202400000035
                 ],
                 [
                    97.97279170000002,
                    50.890155300000046
                 ],
                 [
                    97.97419450000001,
                    50.890183
                 ],
                 [
                    97.97516190000002,
                    50.8902995
                 ],
                 [
                    97.97607210000001,
                    50.890601899999965
                 ],
                 [
                    97.97681969999996,
                    50.890834899999966
                 ],
                 [
                    97.97727260000005,
                    50.89087650000003
                 ],
                 [
                    97.977664,
                    50.89083770000002
                 ],
                 [
                    97.9779806,
                    50.8907461
                 ],
                 [
                    97.97816530000001,
                    50.8905464
                 ],
                 [
                    97.97823129999996,
                    50.89019690000003
                 ],
                 [
                    97.9784292,
                    50.88971979999999
                 ],
                 [
                    97.9787018,
                    50.88946180000004
                 ],
                 [
                    97.9792471,
                    50.889251
                 ],
                 [
                    97.9797528,
                    50.889209400000034
                 ],
                 [
                    97.98027609999995,
                    50.88927590000003
                 ],
                 [
                    97.9812523,
                    50.889495099999955
                 ],
                 [
                    97.98183709999998,
                    50.88975580000003
                 ],
                 [
                    97.982765,
                    50.89023570000004
                 ],
                 [
                    97.98364890000005,
                    50.89052139999996
                 ],
                 [
                    97.98423370000003,
                    50.89058250000004
                 ],
                 [
                    97.98468230000002,
                    50.89055750000002
                 ],
                 [
                    97.98514840000004,
                    50.890466
                 ],
                 [
                    97.98594429999999,
                    50.89014970000003
                 ],
                 [
                    97.98693369999997,
                    50.889509
                 ],
                 [
                    97.98747460000004,
                    50.8891178
                 ],
                 [
                    97.9875977,
                    50.88894029999997
                 ],
                 [
                    97.98778240000001,
                    50.888407700000045
                 ],
                 [
                    97.9879979,
                    50.887941600000026
                 ],
                 [
                    97.9882222,
                    50.88781959999997
                 ],
                 [
                    97.9886443,
                    50.887753
                 ],
                 [
                    97.9894535,
                    50.88778910000002
                 ],
                 [
                    97.9908694,
                    50.887869500000015
                 ],
                 [
                    97.991573,
                    50.887747500000046
                 ],
                 [
                    97.99179290000002,
                    50.88757269999997
                 ],
                 [
                    97.99220989999998,
                    50.88632290000003
                 ],
                 [
                    97.9921351,
                    50.88600669999998
                 ],
                 [
                    97.9918237,
                    50.885750500000015
                 ],
                 [
                    97.99160610000003,
                    50.885630899999974
                 ],
                 [
                    97.99157190000004,
                    50.885483799999975
                 ],
                 [
                    97.991631,
                    50.88524439999997
                 ],
                 [
                    97.99201650000005,
                    50.885016899999975
                 ],
                 [
                    97.99238789999995,
                    50.8846616
                 ],
                 [
                    97.99253739999999,
                    50.884251
                 ],
                 [
                    97.99247150000004,
                    50.88388759999997
                 ],
                 [
                    97.99228680000003,
                    50.8835658
                 ],
                 [
                    97.99204129999997,
                    50.88336130000004
                 ],
                 [
                    97.9916775,
                    50.883282800000025
                 ],
                 [
                    97.99137889999996,
                    50.8833221
                 ],
                 [
                    97.99115510000001,
                    50.8833809
                 ],
                 [
                    97.9909716,
                    50.883345599999984
                 ],
                 [
                    97.99089079999999,
                    50.88323770000002
                 ],
                 [
                    97.99089079999999,
                    50.88300820000004
                 ],
                 [
                    97.99102450000004,
                    50.88284930000004
                 ],
                 [
                    97.9913385,
                    50.88270410000002
                 ],
                 [
                    97.99170229999999,
                    50.88263350000004
                 ],
                 [
                    97.9921843,
                    50.88265509999996
                 ],
                 [
                    97.99283110000003,
                    50.882814
                 ],
                 [
                    97.9932602,
                    50.88306109999998
                 ],
                 [
                    97.99322910000002,
                    50.8833672
                 ],
                 [
                    97.9933876,
                    50.88369280000002
                 ],
                 [
                    97.99369239999999,
                    50.883863500000025
                 ],
                 [
                    97.99422410000001,
                    50.8840028
                 ],
                 [
                    97.99460029999996,
                    50.8842088
                 ],
                 [
                    97.99499830000003,
                    50.88422449999996
                 ],
                 [
                    97.99525329999999,
                    50.88416950000003
                 ],
                 [
                    97.99573839999998,
                    50.883918400000034
                 ],
                 [
                    97.99638820000001,
                    50.88354179999996
                 ],
                 [
                    97.9965468,
                    50.883377
                 ],
                 [
                    97.996553,
                    50.883212200000045
                 ],
                 [
                    97.99629810000002,
                    50.88277080000003
                 ],
                 [
                    97.995897,
                    50.882402
                 ],
                 [
                    97.9953652,
                    50.882174399999975
                 ],
                 [
                    97.99458480000001,
                    50.882094
                 ],
                 [
                    97.9939567,
                    50.88196250000003
                 ],
                 [
                    97.99324459999997,
                    50.881529
                 ],
                 [
                    97.99318239999997,
                    50.8813524
                 ],
                 [
                    97.99316070000005,
                    50.881185699999975
                 ],
                 [
                    97.99324149999998,
                    50.8810366
                 ],
                 [
                    97.99343740000002,
                    50.8809659
                 ],
                 [
                    97.9942583,
                    50.88087180000002
                 ],
                 [
                    97.99482420000001,
                    50.88074820000001
                 ],
                 [
                    97.99526890000004,
                    50.88052060000001
                 ],
                 [
                    97.99552689999997,
                    50.880089
                 ],
                 [
                    97.99585649999999,
                    50.8796221
                 ],
                 [
                    97.99623280000003,
                    50.87940230000003
                 ],
                 [
                    97.99656240000003,
                    50.87934540000003
                 ],
                 [
                    97.99692310000003,
                    50.879418
                 ],
                 [
                    97.99764140000005,
                    50.87961030000001
                 ],
                 [
                    97.99778749999997,
                    50.87986730000004
                 ],
                 [
                    97.99781239999999,
                    50.8803519
                 ],
                 [
                    97.997915,
                    50.88045
                 ],
                 [
                    97.998114,
                    50.880481399999965
                 ],
                 [
                    97.99855240000002,
                    50.88045
                 ],
                 [
                    97.99898459999999,
                    50.88035970000002
                 ],
                 [
                    97.99912460000003,
                    50.880279300000026
                 ],
                 [
                    97.99920229999996,
                    50.880143900000014
                 ],
                 [
                    97.9992272,
                    50.879810399999954
                 ],
                 [
                    97.99915250000002,
                    50.879624
                 ],
                 [
                    97.99891310000002,
                    50.87948080000004
                 ],
                 [
                    97.99843110000002,
                    50.8793768
                 ],
                 [
                    97.9979243,
                    50.8793709
                 ],
                 [
                    97.99773150000004,
                    50.87931010000004
                 ],
                 [
                    97.99746720000003,
                    50.87902760000002
                 ],
                 [
                    97.99734910000002,
                    50.87868430000004
                 ],
                 [
                    97.99736510000001,
                    50.87828820000004
                 ],
                 [
                    97.997585,
                    50.877797
                 ],
                 [
                    97.99785770000001,
                    50.877428
                 ],
                 [
                    97.99795,
                    50.8769674
                 ],
                 [
                    97.9979764,
                    50.876418
                 ],
                 [
                    97.9980907,
                    50.87616270000004
                 ],
                 [
                    97.99868,
                    50.875749300000024
                 ],
                 [
                    97.999577,
                    50.87529979999996
                 ],
                 [
                    98.00034659999997,
                    50.87498070000002
                 ],
                 [
                    98.0013184,
                    50.8748225
                 ],
                 [
                    98.00247930000003,
                    50.8747753
                 ],
                 [
                    98.0031389,
                    50.874861400000015
                 ],
                 [
                    98.00362269999997,
                    50.87496130000002
                 ],
                 [
                    98.00376339999997,
                    50.875152700000044
                 ],
                 [
                    98.00387770000002,
                    50.87571879999999
                 ],
                 [
                    98.003649,
                    50.876123900000024
                 ],
                 [
                    98.00336140000002,
                    50.876345
                 ],
                 [
                    98.00282959999998,
                    50.87637830000002
                 ],
                 [
                    98.00241920000003,
                    50.8764411
                 ],
                 [
                    98.00217670000005,
                    50.8766314
                 ],
                 [
                    98.002068,
                    50.876873300000035
                 ],
                 [
                    98.0020592,
                    50.8771424
                 ],
                 [
                    98.002134,
                    50.87738940000001
                 ],
                 [
                    98.00235819999996,
                    50.8775115
                 ],
                 [
                    98.002732,
                    50.87755309999997
                 ],
                 [
                    98.00304859999999,
                    50.877519799999966
                 ],
                 [
                    98.0034048,
                    50.8773838
                 ],
                 [
                    98.00358070000004,
                    50.8772229
                 ],
                 [
                    98.0037434,
                    50.876751199999966
                 ],
                 [
                    98.00409959999999,
                    50.87643210000003
                 ],
                 [
                    98.00471520000004,
                    50.87612410000004
                 ],
                 [
                    98.00575740000002,
                    50.876018700000024
                 ],
                 [
                    98.0074328,
                    50.875929900000045
                 ],
                 [
                    98.00840909999995,
                    50.8757995
                 ],
                 [
                    98.00898950000001,
                    50.87554970000003
                 ],
                 [
                    98.00936330000003,
                    50.875180699999966
                 ],
                 [
                    98.00957,
                    50.87465069999999
                 ],
                 [
                    98.009592,
                    50.87422610000003
                 ],
                 [
                    98.0093941,
                    50.8738876
                 ],
                 [
                    98.00915659999998,
                    50.8736934
                 ],
                 [
                    98.0084398,
                    50.87366279999996
                 ],
                 [
                    98.00776260000002,
                    50.8735324
                 ],
                 [
                    98.00754720000005,
                    50.873402
                 ],
                 [
                    98.0075208,
                    50.8732189
                 ],
                 [
                    98.0075692,
                    50.87303570000003
                 ],
                 [
                    98.00776260000002,
                    50.872872
                 ],
                 [
                    98.00853220000002,
                    50.87273319999996
                 ],
                 [
                    98.00968430000003,
                    50.8725834
                 ],
                 [
                    98.01017680000002,
                    50.8726167
                 ],
                 [
                    98.0108013,
                    50.87283590000002
                 ],
                 [
                    98.01124539999998,
                    50.87324380000002
                 ],
                 [
                    98.0117203,
                    50.873540699999985
                 ],
                 [
                    98.0128065,
                    50.87392650000002
                 ],
                 [
                    98.01402019999996,
                    50.874209499999985
                 ],
                 [
                    98.01468420000002,
                    50.874467600000024
                 ],
                 [
                    98.01580549999998,
                    50.8745425
                 ],
                 [
                    98.0333428,
                    50.864706199999986
                 ],
                 [
                    98.0268,
                    50.8546
                 ],
                 [
                    98.017,
                    50.8481
                 ],
                 [
                    98.0114,
                    50.8427
                 ],
                 [
                    98.0087,
                    50.8365
                 ],
                 [
                    98.0016,
                    50.8338
                 ],
                 [
                    97.9945,
                    50.8337
                 ],
                 [
                    97.9889,
                    50.8327
                 ],
                 [
                    97.9847,
                    50.8293
                 ],
                 [
                    97.9849,
                    50.8212
                 ],
                 [
                    97.9525,
                    50.8108
                 ],
                 [
                    97.9497,
                    50.8081
                 ],
                 [
                    97.974,
                    50.7902
                 ],
                 [
                    97.9771,
                    50.7866
                 ],
                 [
                    97.9786,
                    50.7841
                 ],
                 [
                    97.9746,
                    50.7742
                 ],
                 [
                    97.9719,
                    50.765
                 ],
                 [
                    97.9707,
                    50.7553
                 ],
                 [
                    97.9708,
                    50.7499
                 ],
                 [
                    97.9766,
                    50.7436
                 ],
                 [
                    97.981,
                    50.7381
                 ],
                 [
                    97.991,
                    50.733
                 ],
                 [
                    97.994,
                    50.7258
                 ],
                 [
                    97.9944,
                    50.7105
                 ],
                 [
                    97.9959,
                    50.7043
                 ],
                 [
                    97.9974,
                    50.6989
                 ],
                 [
                    98.0046,
                    50.6962
                 ],
                 [
                    98.0117,
                    50.6954
                 ],
                 [
                    98.0202,
                    50.6937
                 ],
                 [
                    98.0245,
                    50.6919
                 ],
                 [
                    98.0245,
                    50.6885
                 ],
                 [
                    98.0134,
                    50.6819
                 ],
                 [
                    98.0106,
                    50.6801
                 ],
                 [
                    98.0078,
                    50.6756
                 ],
                 [
                    98.008,
                    50.6692
                 ],
                 [
                    98.0137,
                    50.6659
                 ],
                 [
                    98.0194,
                    50.664
                 ],
                 [
                    98.0236,
                    50.6639
                 ],
                 [
                    98.0237,
                    50.6586
                 ],
                 [
                    98.0252,
                    50.6551
                 ],
                 [
                    98.0338,
                    50.6515
                 ],
                 [
                    98.0367,
                    50.6471
                 ],
                 [
                    98.0482,
                    50.6398
                 ],
                 [
                    98.0567,
                    50.6364
                 ],
                 [
                    98.0596,
                    50.632
                 ],
                 [
                    98.0668,
                    50.6257
                 ],
                 [
                    98.077,
                    50.6122
                 ],
                 [
                    98.0869,
                    50.6096
                 ],
                 [
                    98.087,
                    50.6043
                 ],
                 [
                    98.09604369999997,
                    50.59901479999997
                 ],
                 [
                    98.0960088,
                    50.5989086
                 ],
                 [
                    98.09602339999996,
                    50.5988574
                 ],
                 [
                    98.096091,
                    50.5987649
                 ],
                 [
                    98.09613910000004,
                    50.5986137
                 ],
                 [
                    98.09612820000002,
                    50.5985575
                 ],
                 [
                    98.0960282,
                    50.59839559999999
                 ],
                 [
                    98.09595750000003,
                    50.5981338
                 ],
                 [
                    98.0960193,
                    50.598100399999986
                 ],
                 [
                    98.09605520000002,
                    50.59805409999998
                 ],
                 [
                    98.09611149999998,
                    50.59789739999998
                 ],
                 [
                    98.0963717,
                    50.59777460000003
                 ],
                 [
                    98.0965316,
                    50.59766969999997
                 ],
                 [
                    98.0965504,
                    50.59761839999999
                 ],
                 [
                    98.09654590000004,
                    50.5975165
                 ],
                 [
                    98.09659190000002,
                    50.5974136
                 ],
                 [
                    98.09663629999997,
                    50.59736990000003
                 ],
                 [
                    98.096962,
                    50.59720030000003
                 ],
                 [
                    98.09726590000001,
                    50.59711159999998
                 ],
                 [
                    98.09777160000002,
                    50.59710250000002
                 ],
                 [
                    98.09789510000003,
                    50.59703309999996
                 ],
                 [
                    98.09797010000001,
                    50.597013
                 ],
                 [
                    98.09829939999999,
                    50.597020499999985
                 ],
                 [
                    98.0983788,
                    50.59700290000004
                 ],
                 [
                    98.09842320000003,
                    50.59695919999998
                 ],
                 [
                    98.09846950000004,
                    50.5968617
                 ],
                 [
                    98.0986247,
                    50.59674879999998
                 ],
                 [
                    98.09878490000001,
                    50.596746
                 ],
                 [
                    98.0992805,
                    50.59669939999998
                 ],
                 [
                    98.09935090000005,
                    50.59667129999996
                 ],
                 [
                    98.0993997,
                    50.59663020000002
                 ],
                 [
                    98.09948360000001,
                    50.59662060000003
                 ],
                 [
                    98.09955260000004,
                    50.596654299999976
                 ],
                 [
                    98.09979789999998,
                    50.5966686
                 ],
                 [
                    98.09985879999996,
                    50.5967105
                 ],
                 [
                    98.0997057,
                    50.59677769999997
                 ],
                 [
                    98.099683,
                    50.596834499999964
                 ],
                 [
                    98.0997023,
                    50.59689049999998
                 ],
                 [
                    98.09982429999995,
                    50.59697690000002
                 ],
                 [
                    98.09990460000003,
                    50.5969808
                 ],
                 [
                    98.09995759999997,
                    50.59693960000004
                 ],
                 [
                    98.099951,
                    50.596886
                 ],
                 [
                    98.09998669999997,
                    50.596837
                 ],
                 [
                    98.10006160000005,
                    50.59681419999997
                 ],
                 [
                    98.10014190000003,
                    50.59681810000003
                 ],
                 [
                    98.1003,
                    50.59686359999997
                 ],
                 [
                    98.10038479999997,
                    50.59687280000002
                 ],
                 [
                    98.10064989999996,
                    50.59686
                 ],
                 [
                    98.10080449999997,
                    50.59682770000002
                 ],
                 [
                    98.10088939999997,
                    50.59683960000003
                 ],
                 [
                    98.10110930000005,
                    50.596948299999966
                 ],
                 [
                    98.10118620000001,
                    50.59696840000003
                 ],
                 [
                    98.10126670000001,
                    50.5969777
                 ],
                 [
                    98.1013417,
                    50.596957600000025
                 ],
                 [
                    98.10150139999999,
                    50.59694390000004
                 ],
                 [
                    98.1015457,
                    50.59699149999999
                 ],
                 [
                    98.10162249999996,
                    50.59701159999996
                 ],
                 [
                    98.1017022,
                    50.597002099999976
                 ],
                 [
                    98.10183409999998,
                    50.5969326
                 ],
                 [
                    98.1020588,
                    50.59686410000001
                 ],
                 [
                    98.10221959999998,
                    50.59687730000004
                 ],
                 [
                    98.1022991,
                    50.596862399999964
                 ],
                 [
                    98.10240350000002,
                    50.596554499999975
                 ],
                 [
                    98.10263750000001,
                    50.59641069999998
                 ],
                 [
                    98.10271429999997,
                    50.59643080000004
                 ],
                 [
                    98.102848,
                    50.59649820000003
                 ],
                 [
                    98.10292840000001,
                    50.59650480000004
                 ],
                 [
                    98.10308740000002,
                    50.59647509999998
                 ],
                 [
                    98.1033371,
                    50.5964947
                 ],
                 [
                    98.1035766,
                    50.5964743
                 ],
                 [
                    98.10364550000001,
                    50.5965053
                 ],
                 [
                    98.10361269999997,
                    50.5966213
                 ],
                 [
                    98.10367350000003,
                    50.59666040000003
                 ],
                 [
                    98.10373089999997,
                    50.5966245
                 ],
                 [
                    98.10396040000005,
                    50.596569399999964
                 ],
                 [
                    98.10404519999996,
                    50.59648459999997
                 ],
                 [
                    98.10411130000001,
                    50.5964539
                 ],
                 [
                    98.104272,
                    50.59646439999998
                 ],
                 [
                    98.1043516,
                    50.59645220000004
                 ],
                 [
                    98.10480620000004,
                    50.59633659999998
                 ],
                 [
                    98.10488609999999,
                    50.5963325
                 ],
                 [
                    98.10505590000001,
                    50.59635630000001
                 ],
                 [
                    98.1052242,
                    50.596347900000026
                 ],
                 [
                    98.10568630000003,
                    50.59621070000004
                 ],
                 [
                    98.1059296,
                    50.596182099999986
                 ],
                 [
                    98.10651290000004,
                    50.59621180000004
                 ],
                 [
                    98.1066789,
                    50.59624370000002
                 ],
                 [
                    98.10682459999998,
                    50.59629750000003
                 ],
                 [
                    98.1069494,
                    50.596445500000016
                 ],
                 [
                    98.1070223,
                    50.59647370000004
                 ],
                 [
                    98.1071019,
                    50.59646160000002
                 ],
                 [
                    98.1072476,
                    50.596512599999976
                 ],
                 [
                    98.107412,
                    50.596512300000036
                 ],
                 [
                    98.1075624,
                    50.596480100000036
                 ],
                 [
                    98.10768610000002,
                    50.596416099999985
                 ],
                 [
                    98.1079262,
                    50.596409100000024
                 ],
                 [
                    98.108085,
                    50.5963767
                 ],
                 [
                    98.10828349999998,
                    50.59628719999998
                 ],
                 [
                    98.10832539999998,
                    50.596187100000044
                 ],
                 [
                    98.10808589999998,
                    50.5961136
                 ],
                 [
                    98.108109,
                    50.59606479999996
                 ],
                 [
                    98.10827310000002,
                    50.59605649999998
                 ],
                 [
                    98.10834830000002,
                    50.596039
                 ],
                 [
                    98.1084287,
                    50.59604559999997
                 ],
                 [
                    98.10857390000004,
                    50.595992
                 ],
                 [
                    98.10881440000004,
                    50.595993
                 ],
                 [
                    98.10909670000004,
                    50.59589390000003
                 ],
                 [
                    98.10929510000003,
                    50.59580170000004
                 ],
                 [
                    98.1094527,
                    50.5958365
                 ],
                 [
                    98.109532,
                    50.59581890000004
                 ],
                 [
                    98.10963970000002,
                    50.59568010000002
                 ],
                 [
                    98.109641,
                    50.59552169999996
                 ],
                 [
                    98.10967249999999,
                    50.59547009999996
                 ],
                 [
                    98.10971270000002,
                    50.59542639999999
                 ],
                 [
                    98.10983630000004,
                    50.59536240000002
                 ],
                 [
                    98.110128,
                    50.595282
                 ],
                 [
                    98.1103222,
                    50.5951925
                 ],
                 [
                    98.1104016,
                    50.595177700000015
                 ],
                 [
                    98.11056129999999,
                    50.59516410000004
                 ],
                 [
                    98.11064179999998,
                    50.5951733
                 ],
                 [
                    98.1107107,
                    50.5952043
                 ],
                 [
                    98.11081579999998,
                    50.595291
                 ],
                 [
                    98.11089619999998,
                    50.59529759999999
                 ],
                 [
                    98.11094310000004,
                    50.595261800000046
                 ],
                 [
                    98.11103420000003,
                    50.5952709
                 ],
                 [
                    98.111114,
                    50.595262800000036
                 ],
                 [
                    98.11116859999996,
                    50.595163800000016
                 ],
                 [
                    98.1112635,
                    50.5951164
                 ],
                 [
                    98.11134379999997,
                    50.5951217
                 ],
                 [
                    98.11144110000004,
                    50.59517360000003
                 ],
                 [
                    98.11154710000001,
                    50.59513949999996
                 ],
                 [
                    98.11174859999998,
                    50.5951667
                 ],
                 [
                    98.11226709999998,
                    50.59506740000003
                 ],
                 [
                    98.11232330000001,
                    50.59505020000003
                 ],
                 [
                    98.11237610000003,
                    50.5950036
                 ],
                 [
                    98.11250529999998,
                    50.59478119999998
                 ],
                 [
                    98.11274290000001,
                    50.59467220000002
                 ],
                 [
                    98.11276289999998,
                    50.594649
                 ],
                 [
                    98.1127585,
                    50.59459810000002
                 ],
                 [
                    98.11278290000003,
                    50.5945775
                 ],
                 [
                    98.112942,
                    50.5945518
                 ],
                 [
                    98.11305890000004,
                    50.594572500000034
                 ],
                 [
                    98.11327189999999,
                    50.594713599999984
                 ],
                 [
                    98.1133461,
                    50.59472160000002
                 ],
                 [
                    98.11342609999996,
                    50.5947202
                 ],
                 [
                    98.1136602,
                    50.594673
                 ],
                 [
                    98.11382879999998,
                    50.5946699
                 ],
                 [
                    98.1140749,
                    50.594703
                 ],
                 [
                    98.11447980000001,
                    50.594797700000015
                 ],
                 [
                    98.1148875,
                    50.5948628
                 ],
                 [
                    98.11505630000002,
                    50.5948651
                 ],
                 [
                    98.11545580000003,
                    50.594839
                 ],
                 [
                    98.11553610000001,
                    50.594842900000025
                 ],
                 [
                    98.11570260000002,
                    50.59488820000004
                 ],
                 [
                    98.11584469999997,
                    50.594952799999966
                 ],
                 [
                    98.11588490000001,
                    50.595003
                 ],
                 [
                    98.11587750000002,
                    50.5951186
                 ],
                 [
                    98.115755,
                    50.59520939999997
                 ],
                 [
                    98.1157238,
                    50.59526639999998
                 ],
                 [
                    98.1157206,
                    50.59538180000003
                 ],
                 [
                    98.11575669999998,
                    50.59543489999998
                 ],
                 [
                    98.11588649999996,
                    50.59550769999996
                 ],
                 [
                    98.11617869999998,
                    50.59562850000001
                 ],
                 [
                    98.11683940000003,
                    50.59578569999996
                 ],
                 [
                    98.11709260000002,
                    50.595789099999976
                 ],
                 [
                    98.11722930000005,
                    50.59573290000003
                 ],
                 [
                    98.1172824,
                    50.59569440000004
                 ],
                 [
                    98.117354,
                    50.59559909999997
                 ],
                 [
                    98.11742030000003,
                    50.595571099999965
                 ],
                 [
                    98.1175798,
                    50.59555480000004
                 ],
                 [
                    98.117741,
                    50.595576
                 ],
                 [
                    98.11790540000003,
                    50.595573
                 ],
                 [
                    98.11806850000002,
                    50.595543199999966
                 ],
                 [
                    98.11829740000005,
                    50.595474599999974
                 ],
                 [
                    98.1184869,
                    50.59537450000004
                 ],
                 [
                    98.11873429999996,
                    50.59534320000003
                 ],
                 [
                    98.1187745,
                    50.59529949999997
                 ],
                 [
                    98.11878069999997,
                    50.5952484
                 ],
                 [
                    98.11868120000001,
                    50.595099799999964
                 ],
                 [
                    98.11867879999998,
                    50.5950462
                 ],
                 [
                    98.11873610000002,
                    50.595007599999974
                 ],
                 [
                    98.11904889999997,
                    50.594929399999984
                 ],
                 [
                    98.1191289,
                    50.5949253
                 ],
                 [
                    98.1192093,
                    50.5949318
                 ],
                 [
                    98.11942789999998,
                    50.5950111
                 ],
                 [
                    98.11950790000002,
                    50.5950069
                 ],
                 [
                    98.1195785,
                    50.5949842
                 ],
                 [
                    98.11969910000003,
                    50.59485050000003
                 ],
                 [
                    98.1197974,
                    50.594599
                 ],
                 [
                    98.11983760000003,
                    50.59455530000003
                 ],
                 [
                    98.11996510000002,
                    50.59448320000001
                 ],
                 [
                    98.1204887,
                    50.594216
                 ],
                 [
                    98.1206433,
                    50.5941836
                 ],
                 [
                    98.12105570000001,
                    50.59416269999996
                 ],
                 [
                    98.12138709999998,
                    50.5941244
                 ],
                 [
                    98.121694,
                    50.59400880000002
                 ],
                 [
                    98.12181729999996,
                    50.5939367
                 ],
                 [
                    98.12202220000005,
                    50.5937102
                 ],
                 [
                    98.122181,
                    50.593583800000026
                 ],
                 [
                    98.12256549999996,
                    50.593507
                 ],
                 [
                    98.122693,
                    50.59343489999996
                 ],
                 [
                    98.12308509999997,
                    50.59333910000003
                 ],
                 [
                    98.12356389999997,
                    50.593295499999975
                 ],
                 [
                    98.12397409999998,
                    50.593226300000026
                 ],
                 [
                    98.12421920000001,
                    50.5932379
                 ],
                 [
                    98.12428539999998,
                    50.5932099
                 ],
                 [
                    98.1244058,
                    50.5930735
                 ],
                 [
                    98.1248149,
                    50.59288610000003
                 ],
                 [
                    98.12499540000002,
                    50.592772799999985
                 ],
                 [
                    98.1251892,
                    50.5926753
                 ],
                 [
                    98.12528659999995,
                    50.59259029999998
                 ],
                 [
                    98.1254553,
                    50.592498600000035
                 ],
                 [
                    98.12561859999998,
                    50.5924715
                 ],
                 [
                    98.12568910000005,
                    50.592446
                 ],
                 [
                    98.1257379,
                    50.5924049
                 ],
                 [
                    98.1257542,
                    50.59229990000002
                 ],
                 [
                    98.125584,
                    50.59217139999996
                 ],
                 [
                    98.1254997,
                    50.592079
                 ],
                 [
                    98.12549289999997,
                    50.5920228
                 ],
                 [
                    98.1256049,
                    50.591886500000015
                 ],
                 [
                    98.1255875,
                    50.59178209999999
                 ],
                 [
                    98.12581120000002,
                    50.591598199999964
                 ],
                 [
                    98.1260257,
                    50.5914923
                 ],
                 [
                    98.1261793,
                    50.59143849999998
                 ],
                 [
                    98.12661520000002,
                    50.59119159999998
                 ],
                 [
                    98.1267085,
                    50.591109300000014
                 ],
                 [
                    98.12693750000004,
                    50.591043399999975
                 ],
                 [
                    98.12742519999998,
                    50.5907312
                 ],
                 [
                    98.12752690000005,
                    50.59064880000001
                 ],
                 [
                    98.127775,
                    50.59025769999998
                 ],
                 [
                    98.12777680000002,
                    50.5902039
                 ],
                 [
                    98.12774919999997,
                    50.5901534
                 ],
                 [
                    98.1276607,
                    50.59006109999997
                 ],
                 [
                    98.12767510000005,
                    50.59000710000003
                 ],
                 [
                    98.1277281,
                    50.5899659
                 ],
                 [
                    98.12806120000002,
                    50.589871199999976
                 ],
                 [
                    98.128225,
                    50.5898575
                 ],
                 [
                    98.12861720000002,
                    50.58976440000002
                 ],
                 [
                    98.12910890000002,
                    50.58972590000003
                 ],
                 [
                    98.1291795,
                    50.589703100000015
                 ],
                 [
                    98.1293029,
                    50.589633799999966
                 ],
                 [
                    98.1293825,
                    50.58962160000002
                 ],
                 [
                    98.12963119999998,
                    50.58961970000004
                 ],
                 [
                    98.12970610000005,
                    50.58959690000002
                 ],
                 [
                    98.12985639999997,
                    50.58947059999996
                 ],
                 [
                    98.1299352,
                    50.589442400000024
                 ],
                 [
                    98.13026190000004,
                    50.5893934
                 ],
                 [
                    98.130491,
                    50.58933019999998
                 ],
                 [
                    98.1307778,
                    50.58914510000003
                 ],
                 [
                    98.13110589999997,
                    50.58884650000002
                 ],
                 [
                    98.1313522,
                    50.588697
                 ],
                 [
                    98.1313855,
                    50.58859440000003
                 ],
                 [
                    98.13142550000003,
                    50.588545299999964
                 ],
                 [
                    98.1315272,
                    50.58846289999998
                 ],
                 [
                    98.13170370000005,
                    50.58835769999998
                 ],
                 [
                    98.13179260000003,
                    50.58827010000002
                 ],
                 [
                    98.13185859999999,
                    50.5881454
                 ],
                 [
                    98.1319,
                    50.58794070000003
                 ],
                 [
                    98.13206769999996,
                    50.58782749999999
                 ],
                 [
                    98.13232710000003,
                    50.58768860000004
                 ],
                 [
                    98.1326929,
                    50.58757179999997
                 ],
                 [
                    98.1328519,
                    50.5874508
                 ],
                 [
                    98.13300110000002,
                    50.5873004
                 ],
                 [
                    98.13311419999995,
                    50.587097
                 ],
                 [
                    98.13315050000003,
                    50.58677949999996
                 ],
                 [
                    98.1330171,
                    50.58644110000003
                 ],
                 [
                    98.13288860000003,
                    50.58630380000004
                 ],
                 [
                    98.1328581,
                    50.586095
                 ],
                 [
                    98.13281110000001,
                    50.58598849999998
                 ],
                 [
                    98.13270329999997,
                    50.5858428
                 ],
                 [
                    98.13243939999998,
                    50.5856946
                 ],
                 [
                    98.13234679999997,
                    50.585605
                 ],
                 [
                    98.13209889999997,
                    50.58543779999997
                 ],
                 [
                    98.13181940000003,
                    50.58522280000004
                 ],
                 [
                    98.1317793,
                    50.5851752
                 ],
                 [
                    98.13168540000004,
                    50.5849648
                 ],
                 [
                    98.13155,
                    50.584766
                 ],
                 [
                    98.13138819999996,
                    50.58445210000004
                 ],
                 [
                    98.13136410000001,
                    50.584291499999985
                 ],
                 [
                    98.13143940000002,
                    50.58409149999996
                 ],
                 [
                    98.13184510000002,
                    50.583831699999955
                 ],
                 [
                    98.132127,
                    50.58372449999997
                 ],
                 [
                    98.132303,
                    50.5836086
                 ],
                 [
                    98.13244349999997,
                    50.58354429999998
                 ],
                 [
                    98.13276530000005,
                    50.583482
                 ],
                 [
                    98.1329108,
                    50.583436400000025
                 ],
                 [
                    98.13324510000004,
                    50.58327450000004
                 ],
                 [
                    98.13363360000002,
                    50.583194900000024
                 ],
                 [
                    98.13403080000003,
                    50.583026599999954
                 ],
                 [
                    98.1341806,
                    50.58298360000004
                 ],
                 [
                    98.13451170000003,
                    50.58293989999998
                 ],
                 [
                    98.13475620000001,
                    50.5829381
                 ],
                 [
                    98.13500660000004,
                    50.582973800000026
                 ],
                 [
                    98.13548760000005,
                    50.583075099999974
                 ],
                 [
                    98.13573740000001,
                    50.583097300000034
                 ],
                 [
                    98.135986,
                    50.58309539999996
                 ],
                 [
                    98.13607020000002,
                    50.5830912
                 ],
                 [
                    98.13645360000004,
                    50.5829929
                 ],
                 [
                    98.13682150000001,
                    50.58292169999998
                 ],
                 [
                    98.136962,
                    50.58286010000003
                 ],
                 [
                    98.1371466,
                    50.58274659999997
                 ],
                 [
                    98.1372927,
                    50.58262050000002
                 ],
                 [
                    98.137437,
                    50.582548
                 ],
                 [
                    98.1380751,
                    50.582300099999976
                 ],
                 [
                    98.13840920000001,
                    50.5822295
                 ],
                 [
                    98.1389382,
                    50.58208830000004
                 ],
                 [
                    98.13952160000004,
                    50.58184130000003
                 ],
                 [
                    98.1397979,
                    50.5817047
                 ],
                 [
                    98.1402962,
                    50.58135190000004
                 ],
                 [
                    98.14061210000003,
                    50.5811582
                 ],
                 [
                    98.14084850000005,
                    50.580885399999985
                 ],
                 [
                    98.14111269999997,
                    50.5806685
                 ],
                 [
                    98.1412525,
                    50.580496799999985
                 ],
                 [
                    98.14131720000002,
                    50.5802513
                 ],
                 [
                    98.14131750000004,
                    50.580071400000044
                 ],
                 [
                    98.14122620000003,
                    50.579823400000045
                 ],
                 [
                    98.1412063,
                    50.579665400000025
                 ],
                 [
                    98.14124169999998,
                    50.579514399999965
                 ],
                 [
                    98.14134190000001,
                    50.5793999
                 ],
                 [
                    98.14141309999995,
                    50.5792053
                 ],
                 [
                    98.141435,
                    50.5790357
                 ],
                 [
                    98.14138830000003,
                    50.5789373
                 ],
                 [
                    98.14139450000002,
                    50.578794900000034
                 ],
                 [
                    98.14145609999996,
                    50.578758799999974
                 ],
                 [
                    98.1417753,
                    50.578731500000025
                 ],
                 [
                    98.14192979999999,
                    50.5786991
                 ],
                 [
                    98.14229659999997,
                    50.57851250000002
                 ],
                 [
                    98.14270139999996,
                    50.578236600000025
                 ],
                 [
                    98.14275170000005,
                    50.5781364
                 ],
                 [
                    98.14274019999998,
                    50.5779755
                 ],
                 [
                    98.14277180000002,
                    50.5779293
                 ],
                 [
                    98.1428292,
                    50.57789330000004
                 ],
                 [
                    98.14333579999997,
                    50.577722899999976
                 ],
                 [
                    98.1438965,
                    50.577629399999964
                 ],
                 [
                    98.143967,
                    50.577603899999986
                 ],
                 [
                    98.14402160000004,
                    50.5775063
                 ],
                 [
                    98.1440112,
                    50.5771843
                 ],
                 [
                    98.14403430000003,
                    50.57713559999996
                 ],
                 [
                    98.14422349999997,
                    50.577032799999984
                 ],
                 [
                    98.1444352,
                    50.57695910000002
                 ],
                 [
                    98.14461590000002,
                    50.5768538
                 ],
                 [
                    98.14499630000005,
                    50.57650310000003
                 ],
                 [
                    98.1451683,
                    50.57639260000004
                 ],
                 [
                    98.145271,
                    50.57624039999997
                 ],
                 [
                    98.1456418,
                    50.575957
                 ],
                 [
                    98.1457804,
                    50.575761200000024
                 ],
                 [
                    98.14586919999998,
                    50.575673599999966
                 ],
                 [
                    98.14612010000003,
                    50.57553749999999
                 ],
                 [
                    98.14623160000002,
                    50.57539310000003
                 ],
                 [
                    98.14637770000003,
                    50.57508170000002
                 ],
                 [
                    98.14656340000003,
                    50.5749011
                 ],
                 [
                    98.14673969999997,
                    50.574793200000045
                 ],
                 [
                    98.14688970000003,
                    50.574752799999985
                 ],
                 [
                    98.14701740000002,
                    50.57468870000001
                 ],
                 [
                    98.14710090000001,
                    50.574671099999975
                 ],
                 [
                    98.14742359999998,
                    50.574721499999974
                 ],
                 [
                    98.14750370000003,
                    50.57472
                 ],
                 [
                    98.1478651,
                    50.574603299999964
                 ],
                 [
                    98.1486036,
                    50.57452790000003
                 ],
                 [
                    98.1488989,
                    50.5744393
                 ],
                 [
                    98.149054,
                    50.5744203
                 ],
                 [
                    98.14928660000002,
                    50.57434349999996
                 ],
                 [
                    98.14960440000003,
                    50.574286699999966
                 ],
                 [
                    98.14976440000004,
                    50.57428369999999
                 ],
                 [
                    98.1498306,
                    50.57425559999997
                 ],
                 [
                    98.14999009999997,
                    50.574239300000045
                 ],
                 [
                    98.15022170000003,
                    50.574326300000045
                 ],
                 [
                    98.15054050000003,
                    50.5743848
                 ],
                 [
                    98.15127289999997,
                    50.57436050000003
                 ],
                 [
                    98.15154850000003,
                    50.57439570000002
                 ],
                 [
                    98.1518367,
                    50.57452189999998
                 ],
                 [
                    98.15191760000002,
                    50.5745392
                 ],
                 [
                    98.15225529999998,
                    50.5745491
                 ],
                 [
                    98.1525141,
                    50.574584600000016
                 ],
                 [
                    98.15275690000001,
                    50.5746391
                 ],
                 [
                    98.1532914,
                    50.574712500000025
                 ],
                 [
                    98.1534767,
                    50.574709100000014
                 ],
                 [
                    98.15381079999999,
                    50.57473239999999
                 ],
                 [
                    98.153965,
                    50.57478589999997
                 ],
                 [
                    98.15402610000004,
                    50.57483040000001
                 ],
                 [
                    98.15404970000004,
                    50.574886400000025
                 ],
                 [
                    98.15411490000001,
                    50.57493079999997
                 ],
                 [
                    98.154196,
                    50.574950800000025
                 ],
                 [
                    98.15444519999998,
                    50.5749623
                 ],
                 [
                    98.15459649999998,
                    50.57495139999999
                 ],
                 [
                    98.15467990000005,
                    50.5749311
                 ],
                 [
                    98.15482059999997,
                    50.574874800000046
                 ],
                 [
                    98.15503659999999,
                    50.57471240000002
                 ],
                 [
                    98.15523910000005,
                    50.57462279999998
                 ],
                 [
                    98.1556625,
                    50.5745693
                 ],
                 [
                    98.15580780000002,
                    50.574521
                 ],
                 [
                    98.15588349999997,
                    50.5744256
                 ],
                 [
                    98.15598520000005,
                    50.57434320000002
                 ],
                 [
                    98.15638479999998,
                    50.57413979999998
                 ],
                 [
                    98.156468,
                    50.5740228
                 ],
                 [
                    98.1565611,
                    50.573937899999976
                 ],
                 [
                    98.15676359999996,
                    50.57385089999997
                 ],
                 [
                    98.15687839999997,
                    50.573779
                 ],
                 [
                    98.15722480000002,
                    50.5734263
                 ],
                 [
                    98.15732770000001,
                    50.573279400000025
                 ],
                 [
                    98.15748240000002,
                    50.57315839999996
                 ],
                 [
                    98.15773910000003,
                    50.57287179999996
                 ],
                 [
                    98.1578406,
                    50.57278670000002
                 ],
                 [
                    98.157914,
                    50.5726404
                 ],
                 [
                    98.1580069,
                    50.572552700000024
                 ],
                 [
                    98.1581015,
                    50.57240870000001
                 ],
                 [
                    98.15826009999998,
                    50.57228230000003
                 ],
                 [
                    98.1583591,
                    50.57214089999997
                 ],
                 [
                    98.15855290000005,
                    50.57195470000002
                 ],
                 [
                    98.15865660000001,
                    50.57164140000003
                 ],
                 [
                    98.1587638,
                    50.5714971
                 ],
                 [
                    98.15885679999997,
                    50.57140950000003
                 ],
                 [
                    98.159006,
                    50.57117049999997
                 ],
                 [
                    98.15914750000003,
                    50.571039
                 ],
                 [
                    98.159328,
                    50.5709283
                 ],
                 [
                    98.15937229999999,
                    50.57088450000002
                 ],
                 [
                    98.15945240000003,
                    50.57079179999999
                 ],
                 [
                    98.15952950000002,
                    50.57063460000004
                 ],
                 [
                    98.159614,
                    50.5705472
                 ],
                 [
                    98.15994040000001,
                    50.57031020000002
                 ],
                 [
                    98.16018479999998,
                    50.5700319
                 ],
                 [
                    98.16023070000004,
                    50.569929
                 ],
                 [
                    98.160275,
                    50.56988520000003
                 ],
                 [
                    98.16059210000002,
                    50.569721
                 ],
                 [
                    98.1607507,
                    50.56959450000004
                 ],
                 [
                    98.16128619999998,
                    50.569321500000015
                 ],
                 [
                    98.16149329999998,
                    50.569242499999966
                 ],
                 [
                    98.16188419999999,
                    50.56912520000003
                 ],
                 [
                    98.1622063,
                    50.56907089999998
                 ],
                 [
                    98.16237649999995,
                    50.569014
                 ],
                 [
                    98.16268650000002,
                    50.56887940000003
                 ],
                 [
                    98.163263,
                    50.568675400000025
                 ],
                 [
                    98.1635063,
                    50.56855820000001
                 ],
                 [
                    98.164007,
                    50.56844680000003
                 ],
                 [
                    98.16435040000003,
                    50.56839750000004
                 ],
                 [
                    98.16468029999997,
                    50.56832960000001
                 ],
                 [
                    98.16491779999998,
                    50.568268799999956
                 ],
                 [
                    98.165718,
                    50.567980099999964
                 ],
                 [
                    98.16618939999995,
                    50.567872
                 ],
                 [
                    98.16643740000005,
                    50.567856699999965
                 ],
                 [
                    98.16658689999998,
                    50.567808300000046
                 ],
                 [
                    98.1668459,
                    50.56766650000004
                 ],
                 [
                    98.16705279999998,
                    50.5675822
                 ],
                 [
                    98.16725029999998,
                    50.56747650000004
                 ],
                 [
                    98.16740780000005,
                    50.56741979999997
                 ],
                 [
                    98.16772090000002,
                    50.567352299999975
                 ],
                 [
                    98.167885,
                    50.56734650000003
                 ],
                 [
                    98.1684088,
                    50.56737169999998
                 ],
                 [
                    98.1684969,
                    50.567362
                 ],
                 [
                    98.168726,
                    50.567304
                 ],
                 [
                    98.16923609999998,
                    50.567122699999985
                 ],
                 [
                    98.169847,
                    50.566931499999974
                 ],
                 [
                    98.17000559999995,
                    50.566899
                 ],
                 [
                    98.1700863,
                    50.566910900000046
                 ],
                 [
                    98.1701265,
                    50.56696120000004
                 ],
                 [
                    98.17005590000004,
                    50.56707519999996
                 ],
                 [
                    98.17005849999998,
                    50.5671315
                 ],
                 [
                    98.17010320000004,
                    50.567187099999984
                 ],
                 [
                    98.1703956,
                    50.56740450000003
                 ],
                 [
                    98.1705374,
                    50.56746360000004
                 ],
                 [
                    98.17063020000003,
                    50.56746450000002
                 ],
                 [
                    98.17095220000003,
                    50.56741019999997
                 ],
                 [
                    98.17118090000004,
                    50.56734149999997
                 ],
                 [
                    98.17135729999998,
                    50.56723619999996
                 ],
                 [
                    98.17164289999998,
                    50.567123499999965
                 ],
                 [
                    98.1718718,
                    50.567060199999965
                 ],
                 [
                    98.17211489999995,
                    50.56702880000003
                 ],
                 [
                    98.17236719999995,
                    50.567016
                 ],
                 [
                    98.1726254,
                    50.56704070000004
                 ],
                 [
                    98.17286480000001,
                    50.56711409999998
                 ],
                 [
                    98.17319460000003,
                    50.56713480000001
                 ],
                 [
                    98.17351809999997,
                    50.567203900000045
                 ],
                 [
                    98.17374870000005,
                    50.56726940000004
                 ],
                 [
                    98.17400630000004,
                    50.5671894
                 ],
                 [
                    98.17449240000003,
                    50.5671267
                 ],
                 [
                    98.1747303,
                    50.5670766
                 ],
                 [
                    98.17501610000002,
                    50.566966600000036
                 ],
                 [
                    98.17545469999997,
                    50.566698
                 ],
                 [
                    98.1757352,
                    50.56656390000003
                 ],
                 [
                    98.17623660000001,
                    50.56619480000004
                 ],
                 [
                    98.17654810000003,
                    50.56600370000004
                 ],
                 [
                    98.17681169999999,
                    50.56586989999998
                 ],
                 [
                    98.1768645,
                    50.565826
                 ],
                 [
                    98.1769374,
                    50.5656716
                 ],
                 [
                    98.17707559999998,
                    50.5654677
                 ],
                 [
                    98.17730370000002,
                    50.565297
                 ],
                 [
                    98.1778981,
                    50.56511670000001
                 ],
                 [
                    98.1783286,
                    50.5649449
                 ],
                 [
                    98.17866539999999,
                    50.56484740000002
                 ],
                 [
                    98.1788062,
                    50.564791
                 ],
                 [
                    98.1791402,
                    50.564631800000036
                 ],
                 [
                    98.1793075,
                    50.5645132
                 ],
                 [
                    98.1795792,
                    50.56437119999997
                 ],
                 [
                    98.1798701,
                    50.5641913
                 ],
                 [
                    98.18000709999997,
                    50.56405449999996
                 ],
                 [
                    98.1803811,
                    50.5639401
                 ],
                 [
                    98.18114049999996,
                    50.563772900000025
                 ],
                 [
                    98.18136470000003,
                    50.56369890000003
                 ],
                 [
                    98.1816724,
                    50.56351860000003
                 ],
                 [
                    98.181862,
                    50.56342649999996
                 ],
                 [
                    98.18199480000003,
                    50.56328970000001
                 ],
                 [
                    98.18206050000005,
                    50.5632509
                 ],
                 [
                    98.18226290000001,
                    50.5631639
                 ],
                 [
                    98.1826128,
                    50.56298290000003
                 ],
                 [
                    98.18301729999999,
                    50.56288940000002
                 ],
                 [
                    98.18307030000003,
                    50.56285080000002
                 ],
                 [
                    98.18341649999998,
                    50.56277179999997
                 ],
                 [
                    98.18366270000001,
                    50.56262760000003
                 ],
                 [
                    98.1839568,
                    50.56251739999997
                 ],
                 [
                    98.184128,
                    50.562482
                 ],
                 [
                    98.18429610000001,
                    50.56247080000002
                 ],
                 [
                    98.18479909999998,
                    50.56250430000004
                 ],
                 [
                    98.18505090000004,
                    50.5624808
                 ],
                 [
                    98.18520489999999,
                    50.56243760000003
                 ],
                 [
                    98.18531170000003,
                    50.5623792
                 ],
                 [
                    98.1854488,
                    50.5622451
                 ],
                 [
                    98.18551040000004,
                    50.56220910000004
                 ],
                 [
                    98.18579250000002,
                    50.56211250000004
                 ],
                 [
                    98.1858719,
                    50.562097599999966
                 ],
                 [
                    98.18638170000001,
                    50.56209339999997
                 ],
                 [
                    98.18652260000003,
                    50.56204240000002
                 ],
                 [
                    98.18658390000004,
                    50.562001
                 ],
                 [
                    98.18661529999997,
                    50.5619521
                 ],
                 [
                    98.18677529999998,
                    50.561584
                 ],
                 [
                    98.18677719999995,
                    50.561532900000046
                 ],
                 [
                    98.1867219,
                    50.561432
                 ],
                 [
                    98.18672130000004,
                    50.5613273
                 ],
                 [
                    98.186933,
                    50.56116760000002
                 ],
                 [
                    98.18718930000004,
                    50.561004799999964
                 ],
                 [
                    98.18755720000001,
                    50.560325200000044
                 ],
                 [
                    98.18757240000002,
                    50.560224
                 ],
                 [
                    98.1875534,
                    50.560125200000016
                 ],
                 [
                    98.18747749999999,
                    50.5600433
                 ],
                 [
                    98.18737509999995,
                    50.559971
                 ],
                 [
                    98.18663549999995,
                    50.5595421
                 ],
                 [
                    98.18654439999997,
                    50.55946249999998
                 ],
                 [
                    98.18651410000004,
                    50.5593662
                 ],
                 [
                    98.18654060000001,
                    50.559269800000024
                 ],
                 [
                    98.1867455,
                    50.55902879999998
                 ],
                 [
                    98.1867796,
                    50.55893720000004
                 ],
                 [
                    98.18674920000004,
                    50.55841190000001
                 ],
                 [
                    98.1867796,
                    50.55831550000003
                 ],
                 [
                    98.1870793,
                    50.55781669999997
                 ],
                 [
                    98.18710960000001,
                    50.5575444
                 ],
                 [
                    98.1871779,
                    50.5574576
                 ],
                 [
                    98.18731820000005,
                    50.55742150000004
                 ],
                 [
                    98.18761410000002,
                    50.55740220000004
                 ],
                 [
                    98.187762,
                    50.5573588
                 ],
                 [
                    98.1879327,
                    50.55726
                 ],
                 [
                    98.1880617,
                    50.557207
                 ],
                 [
                    98.18829690000003,
                    50.557154
                 ],
                 [
                    98.18844859999996,
                    50.55714670000004
                 ],
                 [
                    98.18903660000002,
                    50.55716360000002
                 ],
                 [
                    98.18960550000003,
                    50.55710340000001
                 ],
                 [
                    98.18975729999997,
                    50.55710580000003
                 ],
                 [
                    98.18989760000004,
                    50.55714670000004
                 ],
                 [
                    98.19050449999997,
                    50.55748169999996
                 ],
                 [
                    98.190827,
                    50.557554
                 ],
                 [
                    98.19134660000002,
                    50.557566
                 ],
                 [
                    98.1918132,
                    50.557554
                 ],
                 [
                    98.19196490000002,
                    50.55754189999997
                 ],
                 [
                    98.192348,
                    50.557452799999965
                 ],
                 [
                    98.19273490000005,
                    50.55738529999998
                 ],
                 [
                    98.19301939999997,
                    50.55729609999997
                 ],
                 [
                    98.19336079999997,
                    50.55714430000002
                 ],
                 [
                    98.1935126,
                    50.55711540000003
                 ],
                 [
                    98.1942864,
                    50.55713949999998
                 ],
                 [
                    98.19443810000001,
                    50.55712019999998
                 ],
                 [
                    98.19458220000004,
                    50.55708650000003
                 ],
                 [
                    98.1950754,
                    50.556855099999986
                 ],
                 [
                    98.1952688,
                    50.55673950000001
                 ],
                 [
                    98.19533330000002,
                    50.55665270000002
                 ],
                 [
                    98.1953674,
                    50.556558699999975
                 ],
                 [
                    98.19546230000003,
                    50.5564816
                 ],
                 [
                    98.19568609999999,
                    50.55634910000004
                 ],
                 [
                    98.19579989999998,
                    50.5561683
                 ],
                 [
                    98.1959137,
                    50.556105699999975
                 ],
                 [
                    98.196532,
                    50.5559201
                 ],
                 [
                    98.19665710000004,
                    50.555855
                 ],
                 [
                    98.1968961,
                    50.55561890000002
                 ],
                 [
                    98.19710470000003,
                    50.5554863
                 ],
                 [
                    98.19730579999997,
                    50.555399499999986
                 ],
                 [
                    98.19769649999996,
                    50.55528869999996
                 ],
                 [
                    98.19783680000003,
                    50.55525740000004
                 ],
                 [
                    98.19798480000001,
                    50.55524530000002
                 ],
                 [
                    98.19813650000005,
                    50.55524770000004
                 ],
                 [
                    98.1986372,
                    50.555317600000045
                 ],
                 [
                    98.19878509999998,
                    50.55531039999998
                 ],
                 [
                    98.19889510000002,
                    50.5552429
                 ],
                 [
                    98.19910750000001,
                    50.55499469999997
                 ],
                 [
                    98.19938819999996,
                    50.55485729999997
                 ],
                 [
                    98.20007859999998,
                    50.554589800000045
                 ],
                 [
                    98.2002228,
                    50.55455119999996
                 ],
                 [
                    98.20078039999996,
                    50.55447170000004
                 ],
                 [
                    98.20124690000002,
                    50.55434639999998
                 ],
                 [
                    98.20139110000004,
                    50.55432230000003
                 ],
                 [
                    98.20180830000001,
                    50.55432709999998
                 ],
                 [
                    98.20197520000005,
                    50.5543102
                 ],
                 [
                    98.20210040000002,
                    50.55426440000004
                 ],
                 [
                    98.2027035,
                    50.553941499999965
                 ],
                 [
                    98.2028439,
                    50.55390289999997
                 ],
                 [
                    98.20299179999996,
                    50.553878800000014
                 ],
                 [
                    98.20327250000001,
                    50.55387160000004
                 ],
                 [
                    98.203777,
                    50.55377759999999
                 ],
                 [
                    98.203906,
                    50.553724600000045
                 ],
                 [
                    98.203997,
                    50.5536499
                 ],
                 [
                    98.204198,
                    50.55343540000001
                 ],
                 [
                    98.20453559999999,
                    50.55318960000001
                 ],
                 [
                    98.20518430000001,
                    50.552857
                 ],
                 [
                    98.20530570000001,
                    50.5527967
                 ],
                 [
                    98.20609089999996,
                    50.55259190000003
                 ],
                 [
                    98.20639430000003,
                    50.55257739999998
                 ],
                 [
                    98.20687979999997,
                    50.5526184
                 ],
                 [
                    98.20703539999995,
                    50.552616
                 ],
                 [
                    98.20717949999998,
                    50.5525943
                 ],
                 [
                    98.20778259999996,
                    50.55245929999998
                 ],
                 [
                    98.2079344,
                    50.55244490000003
                 ],
                 [
                    98.20808610000003,
                    50.5524569
                 ],
                 [
                    98.2082264,
                    50.55248819999998
                 ],
                 [
                    98.20854130000004,
                    50.5526087
                 ],
                 [
                    98.2087651,
                    50.55277990000004
                 ],
                 [
                    98.20894719999995,
                    50.55289069999997
                 ],
                 [
                    98.20908369999997,
                    50.55292929999997
                 ],
                 [
                    98.20956540000003,
                    50.5529221
                 ],
                 [
                    98.20971340000003,
                    50.5529341
                 ],
                 [
                    98.20984990000004,
                    50.5529727
                 ],
                 [
                    98.20997889999997,
                    50.55302570000004
                 ],
                 [
                    98.2100661,
                    50.55311
                 ],
                 [
                    98.21010410000002,
                    50.55320639999998
                 ],
                 [
                    98.21005859999998,
                    50.553360699999956
                 ],
                 [
                    98.210051,
                    50.55345229999999
                 ],
                 [
                    98.2101306,
                    50.5535318
                 ],
                 [
                    98.21021030000001,
                    50.5535824
                 ],
                 [
                    98.21067310000002,
                    50.553784899999975
                 ],
                 [
                    98.2108627,
                    50.55388850000002
                 ],
                 [
                    98.21098790000005,
                    50.55391020000004
                 ],
                 [
                    98.211132,
                    50.55388369999998
                 ],
                 [
                    98.21126860000001,
                    50.5538379
                 ],
                 [
                    98.21141270000003,
                    50.553813799999965
                 ],
                 [
                    98.21198170000004,
                    50.553784899999975
                 ],
                 [
                    98.21212970000002,
                    50.55375350000004
                 ],
                 [
                    98.21220930000004,
                    50.553671600000015
                 ],
                 [
                    98.21233069999995,
                    50.55361370000002
                 ],
                 [
                    98.21247479999998,
                    50.55358
                 ],
                 [
                    98.21274419999997,
                    50.5535969
                 ],
                 [
                    98.21289210000005,
                    50.5535969
                 ],
                 [
                    98.21304,
                    50.55358
                 ],
                 [
                    98.2131652,
                    50.553527
                 ],
                 [
                    98.2132449,
                    50.55344259999998
                 ],
                 [
                    98.21324869999997,
                    50.55334860000002
                 ],
                 [
                    98.21315,
                    50.553235399999984
                 ],
                 [
                    98.2130931,
                    50.55314619999997
                 ],
                 [
                    98.2131728,
                    50.55306420000003
                 ],
                 [
                    98.21331690000002,
                    50.5530305
                 ],
                 [
                    98.213719,
                    50.55300879999997
                 ],
                 [
                    98.213867,
                    50.552984700000025
                 ],
                 [
                    98.21418559999995,
                    50.55284009999996
                 ],
                 [
                    98.21461419999997,
                    50.5526859
                 ],
                 [
                    98.21496319999999,
                    50.55261359999996
                 ],
                 [
                    98.21585839999999,
                    50.55248340000002
                 ],
                 [
                    98.2159949,
                    50.55244490000003
                 ],
                 [
                    98.21665880000003,
                    50.55208329999996
                 ],
                 [
                    98.2171102,
                    50.55171219999998
                 ],
                 [
                    98.21726190000003,
                    50.55170249999998
                 ],
                 [
                    98.21737570000002,
                    50.55176520000002
                 ],
                 [
                    98.21751980000005,
                    50.551818199999964
                 ],
                 [
                    98.2177436,
                    50.551866400000044
                 ],
                 [
                    98.21789530000002,
                    50.551854400000025
                 ],
                 [
                    98.21800910000003,
                    50.55178929999997
                 ],
                 [
                    98.217994,
                    50.5516953
                 ],
                 [
                    98.21803570000002,
                    50.55154110000004
                 ],
                 [
                    98.21808879999996,
                    50.55144950000001
                 ],
                 [
                    98.2182405,
                    50.5513
                 ],
                 [
                    98.21835429999999,
                    50.551235
                 ],
                 [
                    98.2184947,
                    50.55119159999999
                 ],
                 [
                    98.21901429999998,
                    50.55115540000001
                 ],
                 [
                    98.21916229999997,
                    50.55118189999998
                 ],
                 [
                    98.21963640000003,
                    50.55131450000003
                 ],
                 [
                    98.21978819999997,
                    50.55133380000003
                 ],
                 [
                    98.22009160000003,
                    50.55131689999996
                 ],
                 [
                    98.22023960000001,
                    50.551341
                 ],
                 [
                    98.22064540000002,
                    50.551288
                 ],
                 [
                    98.220983,
                    50.5512012
                 ],
                 [
                    98.22112720000001,
                    50.551148200000036
                 ],
                 [
                    98.221222,
                    50.55107349999999
                 ],
                 [
                    98.2212903,
                    50.55093609999999
                 ],
                 [
                    98.22137369999997,
                    50.55085659999998
                 ],
                 [
                    98.22187819999998,
                    50.55068540000003
                 ],
                 [
                    98.22201860000004,
                    50.55067580000003
                 ],
                 [
                    98.22214750000005,
                    50.550596300000024
                 ],
                 [
                    98.22233720000003,
                    50.550410700000036
                 ],
                 [
                    98.22257240000003,
                    50.550278099999986
                 ],
                 [
                    98.22273169999998,
                    50.55024440000004
                 ],
                 [
                    98.22295169999998,
                    50.55026609999997
                 ],
                 [
                    98.22305410000001,
                    50.55019619999996
                 ],
                 [
                    98.22324,
                    50.549858700000016
                 ],
                 [
                    98.2236572,
                    50.54948760000004
                 ],
                 [
                    98.22379380000002,
                    50.54943939999996
                 ],
                 [
                    98.2240897,
                    50.5493863
                 ],
                 [
                    98.22452590000002,
                    50.54937910000003
                 ],
                 [
                    98.22484070000004,
                    50.5493068
                 ],
                 [
                    98.2253414,
                    50.549263399999965
                 ],
                 [
                    98.22548560000003,
                    50.54922970000002
                 ],
                 [
                    98.22559940000002,
                    50.549167
                 ],
                 [
                    98.22579280000004,
                    50.54902959999998
                 ],
                 [
                    98.225937,
                    50.548979
                 ],
                 [
                    98.22608490000005,
                    50.54895969999997
                 ],
                 [
                    98.2262366,
                    50.5489718
                 ],
                 [
                    98.22638080000002,
                    50.548998299999965
                 ],
                 [
                    98.22698769999997,
                    50.549174200000046
                 ],
                 [
                    98.22713560000003,
                    50.54920320000004
                 ],
                 [
                    98.2275453,
                    50.5491983
                 ],
                 [
                    98.22797009999998,
                    50.549176599999974
                 ],
                 [
                    98.2281788,
                    50.5491501
                 ],
                 [
                    98.22867570000001,
                    50.549036800000046
                 ],
                 [
                    98.22921429999998,
                    50.5489838
                 ],
                 [
                    98.229366,
                    50.54899590000004
                 ],
                 [
                    98.2298554,
                    50.5491791
                 ],
                 [
                    98.23000329999998,
                    50.54919589999997
                 ],
                 [
                    98.2301474,
                    50.549171800000025
                 ],
                 [
                    98.23028019999998,
                    50.54912839999999
                 ],
                 [
                    98.23055330000001,
                    50.54909709999997
                 ],
                 [
                    98.23089470000002,
                    50.54899590000004
                 ],
                 [
                    98.2309782,
                    50.548938
                 ],
                 [
                    98.2308492,
                    50.5489043
                 ],
                 [
                    98.2310426,
                    50.548694599999976
                 ],
                 [
                    98.23111089999996,
                    50.548521
                 ],
                 [
                    98.2313271,
                    50.548374
                 ],
                 [
                    98.2314182,
                    50.54824389999999
                 ],
                 [
                    98.2315547,
                    50.548200500000036
                 ],
                 [
                    98.23169890000004,
                    50.54824389999999
                 ],
                 [
                    98.23208960000004,
                    50.54828239999998
                 ],
                 [
                    98.2322223,
                    50.548328200000036
                 ],
                 [
                    98.23246889999997,
                    50.54844630000004
                 ],
                 [
                    98.23290129999995,
                    50.54853310000002
                 ],
                 [
                    98.23297720000002,
                    50.548448699999966
                 ],
                 [
                    98.2329089,
                    50.548311299999966
                 ],
                 [
                    98.23292790000002,
                    50.548214899999984
                 ],
                 [
                    98.23303029999997,
                    50.54814020000002
                 ],
                 [
                    98.2331024,
                    50.548051
                 ],
                 [
                    98.23302269999996,
                    50.54796909999999
                 ],
                 [
                    98.23287860000003,
                    50.547998
                 ],
                 [
                    98.23273059999995,
                    50.5480101
                 ],
                 [
                    98.2325827,
                    50.547998
                 ],
                 [
                    98.2324499,
                    50.54795220000001
                 ],
                 [
                    98.23237779999998,
                    50.54782929999997
                 ],
                 [
                    98.2323475,
                    50.54773770000003
                 ],
                 [
                    98.2323475,
                    50.54764369999997
                 ],
                 [
                    98.23235890000002,
                    50.5475473
                 ],
                 [
                    98.2324082,
                    50.547455699999965
                 ],
                 [
                    98.232503,
                    50.54737849999997
                 ],
                 [
                    98.2326548,
                    50.547366500000045
                 ],
                 [
                    98.23280269999997,
                    50.5473906
                 ],
                 [
                    98.23292409999998,
                    50.54744839999998
                 ],
                 [
                    98.2330189,
                    50.54752559999997
                 ],
                 [
                    98.23313649999997,
                    50.54758339999996
                 ],
                 [
                    98.23332989999999,
                    50.54764369999997
                 ],
                 [
                    98.23384489999997,
                    50.547925800000044
                 ],
                 [
                    98.23398909999999,
                    50.5479499
                 ],
                 [
                    98.234137,
                    50.547925800000044
                 ],
                 [
                    98.23426980000004,
                    50.547877599999964
                 ],
                 [
                    98.23438360000003,
                    50.5478126
                 ],
                 [
                    98.2345163,
                    50.5477643
                 ],
                 [
                    98.23487669999997,
                    50.547721
                 ],
                 [
                    98.23502460000005,
                    50.5476944
                 ],
                 [
                    98.23516119999998,
                    50.54765830000003
                 ],
                 [
                    98.23537359999997,
                    50.54751369999997
                 ],
                 [
                    98.23566190000003,
                    50.54738350000003
                 ],
                 [
                    98.23577189999997,
                    50.54731839999997
                 ],
                 [
                    98.23591220000003,
                    50.547279899999985
                 ],
                 [
                    98.2360298,
                    50.547222
                 ],
                 [
                    98.23601090000003,
                    50.5471256
                 ],
                 [
                    98.23588190000001,
                    50.547116
                 ],
                 [
                    98.2357378,
                    50.547050900000045
                 ],
                 [
                    98.2356847,
                    50.54696170000003
                 ],
                 [
                    98.235734,
                    50.54687250000003
                 ],
                 [
                    98.23582879999998,
                    50.54679540000004
                 ],
                 [
                    98.23591980000005,
                    50.5465953
                 ],
                 [
                    98.23601469999998,
                    50.54652060000004
                 ],
                 [
                    98.2361474,
                    50.546474799999984
                 ],
                 [
                    98.23629540000003,
                    50.5464772
                 ],
                 [
                    98.2364205,
                    50.546527800000014
                 ],
                 [
                    98.2367126,
                    50.54672550000003
                 ],
                 [
                    98.23682259999995,
                    50.5467882
                 ],
                 [
                    98.23697060000003,
                    50.5467809
                 ],
                 [
                    98.2370957,
                    50.54672550000003
                 ],
                 [
                    98.23717919999997,
                    50.54660009999996
                 ],
                 [
                    98.23721329999995,
                    50.5465061
                 ],
                 [
                    98.2371678,
                    50.54641210000003
                 ],
                 [
                    98.23700470000001,
                    50.5463302
                 ],
                 [
                    98.23689850000002,
                    50.54626270000002
                 ],
                 [
                    98.23684920000002,
                    50.5461735
                 ],
                 [
                    98.23690610000003,
                    50.54608189999998
                 ],
                 [
                    98.2370388,
                    50.54603129999997
                 ],
                 [
                    98.23718679999998,
                    50.54603850000003
                 ],
                 [
                    98.23731569999998,
                    50.546089100000046
                 ],
                 [
                    98.23753569999998,
                    50.5463398
                 ],
                 [
                    98.23756229999996,
                    50.54653750000002
                 ],
                 [
                    98.237623,
                    50.5466242
                 ],
                 [
                    98.2377292,
                    50.5466917
                 ],
                 [
                    98.23785439999997,
                    50.54673989999998
                 ],
                 [
                    98.23800230000005,
                    50.546759199999975
                 ],
                 [
                    98.238154,
                    50.54674719999996
                 ],
                 [
                    98.238412,
                    50.546641099999974
                 ],
                 [
                    98.23856370000004,
                    50.54662909999996
                 ],
                 [
                    98.2390834,
                    50.54653259999997
                 ],
                 [
                    98.23921989999998,
                    50.546489300000026
                 ],
                 [
                    98.23932990000002,
                    50.54641940000002
                 ],
                 [
                    98.23939440000002,
                    50.5463302
                 ],
                 [
                    98.239383,
                    50.5462313
                 ],
                 [
                    98.239292,
                    50.546108400000044
                 ],
                 [
                    98.23927679999998,
                    50.54600960000004
                 ],
                 [
                    98.23936789999996,
                    50.545932499999964
                 ],
                 [
                    98.2396903,
                    50.5458457
                 ],
                 [
                    98.2398382,
                    50.54582639999998
                 ],
                 [
                    98.2399824,
                    50.5458457
                 ],
                 [
                    98.2401,
                    50.545903499999966
                 ],
                 [
                    98.24016060000001,
                    50.54598790000002
                 ],
                 [
                    98.24016820000001,
                    50.546207199999955
                 ],
                 [
                    98.24019860000004,
                    50.54629879999999
                 ],
                 [
                    98.2402517,
                    50.54638559999997
                 ],
                 [
                    98.2403579,
                    50.546455499999986
                 ],
                 [
                    98.24049069999997,
                    50.5464965
                 ],
                 [
                    98.24063860000004,
                    50.5464965
                 ],
                 [
                    98.24078650000001,
                    50.54647
                 ],
                 [
                    98.24136310000003,
                    50.546327799999986
                 ],
                 [
                    98.24170450000003,
                    50.5463012
                 ],
                 [
                    98.24185619999997,
                    50.54630370000004
                 ],
                 [
                    98.2420004,
                    50.5463398
                 ],
                 [
                    98.24211790000004,
                    50.54640010000002
                 ],
                 [
                    98.2423569,
                    50.546612199999984
                 ],
                 [
                    98.24249350000004,
                    50.546658
                 ],
                 [
                    98.2426452,
                    50.546667600000035
                 ],
                 [
                    98.2429525,
                    50.546626700000026
                 ],
                 [
                    98.24309279999997,
                    50.5465953
                 ],
                 [
                    98.24353660000001,
                    50.54642180000004
                 ],
                 [
                    98.24382489999996,
                    50.546351900000026
                 ],
                 [
                    98.24451910000003,
                    50.54628679999997
                 ],
                 [
                    98.24466319999996,
                    50.546253
                 ],
                 [
                    98.24477320000001,
                    50.54618550000003
                 ],
                 [
                    98.24482629999996,
                    50.546096400000025
                 ],
                 [
                    98.24483389999996,
                    50.54589389999997
                 ],
                 [
                    98.24491730000003,
                    50.545814300000046
                 ],
                 [
                    98.24506149999996,
                    50.545766099999966
                 ],
                 [
                    98.2452132,
                    50.545766099999966
                 ],
                 [
                    98.24535740000003,
                    50.545797499999985
                 ],
                 [
                    98.24547110000002,
                    50.54586010000001
                 ],
                 [
                    98.24557740000002,
                    50.5460241
                 ],
                 [
                    98.2456608,
                    50.5461036
                 ],
                 [
                    98.24580869999997,
                    50.546096400000025
                 ],
                 [
                    98.24594910000005,
                    50.54604820000004
                 ],
                 [
                    98.24604770000003,
                    50.54597339999997
                 ],
                 [
                    98.24610460000004,
                    50.54588180000003
                 ],
                 [
                    98.2461805,
                    50.54561670000004
                 ],
                 [
                    98.24623359999995,
                    50.5455251
                 ],
                 [
                    98.24632079999998,
                    50.545448
                 ],
                 [
                    98.24641949999997,
                    50.545373199999965
                 ],
                 [
                    98.246685,
                    50.545262300000026
                 ],
                 [
                    98.24682910000003,
                    50.545238199999986
                 ],
                 [
                    98.2469771,
                    50.545238199999986
                 ],
                 [
                    98.24737530000002,
                    50.545399700000026
                 ],
                 [
                    98.2479064,
                    50.5455444
                 ],
                 [
                    98.24805810000002,
                    50.545546800000025
                 ],
                 [
                    98.24820989999996,
                    50.545522699999985
                 ],
                 [
                    98.24835780000004,
                    50.5455251
                 ],
                 [
                    98.24850570000001,
                    50.545549200000046
                 ],
                 [
                    98.2486461,
                    50.54558780000004
                 ],
                 [
                    98.24904820000005,
                    50.54564560000003
                 ],
                 [
                    98.249234,
                    50.545648
                 ],
                 [
                    98.24938950000005,
                    50.545638399999966
                 ],
                 [
                    98.24955260000004,
                    50.54566729999996
                 ],
                 [
                    98.2496778,
                    50.54572270000001
                 ],
                 [
                    98.2498258,
                    50.54574440000003
                 ],
                 [
                    98.2501747,
                    50.545696200000044
                 ],
                 [
                    98.2503151,
                    50.545657699999964
                 ],
                 [
                    98.25079680000005,
                    50.54545760000002
                 ],
                 [
                    98.25113060000004,
                    50.54520450000004
                 ],
                 [
                    98.25177169999998,
                    50.54467180000002
                 ],
                 [
                    98.25189309999998,
                    50.544606700000045
                 ],
                 [
                    98.25207510000003,
                    50.544568099999964
                 ],
                 [
                    98.25273139999997,
                    50.54438249999997
                 ],
                 [
                    98.25285269999998,
                    50.54432229999996
                 ],
                 [
                    98.25298550000004,
                    50.5442282
                 ],
                 [
                    98.2531221,
                    50.544182400000025
                 ],
                 [
                    98.25327760000005,
                    50.54418729999998
                 ],
                 [
                    98.25342550000003,
                    50.54420659999998
                 ],
                 [
                    98.25357350000002,
                    50.54418
                 ],
                 [
                    98.25383140000004,
                    50.544076400000044
                 ],
                 [
                    98.25397930000003,
                    50.54405949999998
                 ],
                 [
                    98.2541273,
                    50.544064300000024
                 ],
                 [
                    98.2547,
                    50.54392209999998
                 ],
                 [
                    98.25480250000001,
                    50.54384980000004
                 ],
                 [
                    98.25485940000002,
                    50.5437582
                 ],
                 [
                    98.2549011,
                    50.543606299999965
                 ],
                 [
                    98.25496939999996,
                    50.543519499999974
                 ],
                 [
                    98.25548420000001,
                    50.54321879999996
                 ],
                 [
                    98.255579,
                    50.543144
                 ],
                 [
                    98.25594319999998,
                    50.54279450000003
                 ],
                 [
                    98.25635290000004,
                    50.542577500000014
                 ],
                 [
                    98.25691420000003,
                    50.54231480000004
                 ],
                 [
                    98.2571115,
                    50.5422328
                 ],
                 [
                    98.25722530000002,
                    50.542165300000015
                 ],
                 [
                    98.25753250000002,
                    50.54187120000002
                 ],
                 [
                    98.25765390000004,
                    50.541782
                 ],
                 [
                    98.257946,
                    50.54166149999999
                 ],
                 [
                    98.2581698,
                    50.541610899999974
                 ],
                 [
                    98.2586174,
                    50.5415747
                 ],
                 [
                    98.25955050000002,
                    50.54137459999996
                 ],
                 [
                    98.25968709999995,
                    50.541333700000045
                 ],
                 [
                    98.2597971,
                    50.54126370000002
                 ],
                 [
                    98.2599678,
                    50.541087800000035
                 ],
                 [
                    98.26009680000001,
                    50.54102990000004
                 ],
                 [
                    98.26024090000003,
                    50.54099860000003
                 ],
                 [
                    98.26039259999997,
                    50.5410058
                 ],
                 [
                    98.26054059999996,
                    50.54102990000004
                 ],
                 [
                    98.26069610000003,
                    50.5410058
                 ],
                 [
                    98.26091229999999,
                    50.54086359999996
                 ],
                 [
                    98.26123849999998,
                    50.5408178
                 ],
                 [
                    98.26225890000002,
                    50.54058879999996
                 ],
                 [
                    98.26241440000001,
                    50.540576700000024
                 ],
                 [
                    98.2625699,
                    50.54058149999998
                 ],
                 [
                    98.2631427,
                    50.54067550000003
                 ],
                 [
                    98.26390890000003,
                    50.54075510000004
                 ],
                 [
                    98.2643603,
                    50.54072619999996
                 ],
                 [
                    98.26450829999997,
                    50.54070450000003
                 ],
                 [
                    98.26465620000005,
                    50.5407189
                 ],
                 [
                    98.26507719999998,
                    50.54090210000004
                 ],
                 [
                    98.265229,
                    50.5408973
                 ],
                 [
                    98.26535790000003,
                    50.54084429999996
                 ],
                 [
                    98.2654983,
                    50.540803300000036
                 ],
                 [
                    98.26585490000002,
                    50.5408877
                 ],
                 [
                    98.26615829999999,
                    50.541042
                 ],
                 [
                    98.26630629999998,
                    50.541042
                 ],
                 [
                    98.26646939999998,
                    50.54102269999998
                 ],
                 [
                    98.26715969999998,
                    50.541042
                 ],
                 [
                    98.26731150000002,
                    50.5410347
                 ],
                 [
                    98.267467,
                    50.54099860000003
                 ],
                 [
                    98.26759219999997,
                    50.540940700000036
                 ],
                 [
                    98.26773250000004,
                    50.5408298
                 ],
                 [
                    98.26783870000003,
                    50.54065630000004
                 ],
                 [
                    98.26794869999998,
                    50.54058630000002
                 ],
                 [
                    98.26807009999999,
                    50.540526100000015
                 ],
                 [
                    98.26844940000002,
                    50.540429600000024
                 ],
                 [
                    98.26860109999996,
                    50.54030669999998
                 ],
                 [
                    98.26871490000005,
                    50.540244
                 ],
                 [
                    98.26906389999996,
                    50.54019819999997
                 ],
                 [
                    98.26918529999998,
                    50.54012590000003
                 ],
                 [
                    98.26978839999997,
                    50.539865499999976
                 ],
                 [
                    98.26989460000004,
                    50.539798
                 ],
                 [
                    98.27000839999995,
                    50.53966789999997
                 ],
                 [
                    98.27026260000002,
                    50.5395136
                 ],
                 [
                    98.27108189999996,
                    50.53925559999996
                 ],
                 [
                    98.27144990000004,
                    50.539111
                 ],
                 [
                    98.27158640000005,
                    50.539074800000016
                 ],
                 [
                    98.2719088,
                    50.539101299999984
                 ],
                 [
                    98.27204540000001,
                    50.53905790000004
                 ],
                 [
                    98.27216680000002,
                    50.53899770000003
                 ],
                 [
                    98.2723071,
                    50.5389543
                 ],
                 [
                    98.27251570000003,
                    50.53895189999997
                 ],
                 [
                    98.27266749999997,
                    50.538935
                 ],
                 [
                    98.27279639999998,
                    50.53888190000004
                 ],
                 [
                    98.27289509999997,
                    50.5388096
                 ],
                 [
                    98.27325540000002,
                    50.53847209999997
                 ],
                 [
                    98.27347540000002,
                    50.53833469999997
                 ],
                 [
                    98.27360440000004,
                    50.53828409999996
                 ],
                 [
                    98.27374849999997,
                    50.53825749999998
                 ],
                 [
                    98.2738851,
                    50.53821170000001
                 ],
                 [
                    98.2739913,
                    50.53813460000003
                 ],
                 [
                    98.27445789999996,
                    50.53758490000002
                 ],
                 [
                    98.27492059999996,
                    50.53694119999996
                 ],
                 [
                    98.27502310000001,
                    50.5368665
                 ],
                 [
                    98.27537579999996,
                    50.53669530000004
                 ],
                 [
                    98.27578550000003,
                    50.53651209999998
                 ],
                 [
                    98.27588410000001,
                    50.53643730000001
                 ],
                 [
                    98.27595620000002,
                    50.536350500000026
                 ],
                 [
                    98.2760396,
                    50.5361721
                 ],
                 [
                    98.27611170000002,
                    50.53582250000004
                 ],
                 [
                    98.27615719999996,
                    50.53572130000001
                 ],
                 [
                    98.2764228,
                    50.53555730000003
                 ],
                 [
                    98.2769917,
                    50.53527769999999
                 ],
                 [
                    98.27709419999995,
                    50.53520530000004
                 ],
                 [
                    98.27734830000001,
                    50.534916
                 ],
                 [
                    98.2775456,
                    50.534646
                 ],
                 [
                    98.27773519999997,
                    50.5344459
                 ],
                 [
                    98.2778452,
                    50.534376
                 ],
                 [
                    98.27797039999997,
                    50.53431569999997
                 ],
                 [
                    98.2780728,
                    50.534245799999965
                 ],
                 [
                    98.27815250000003,
                    50.53407460000001
                 ],
                 [
                    98.27858109999997,
                    50.5335056
                 ],
                 [
                    98.27873660000004,
                    50.5331897
                 ],
                 [
                    98.27882770000004,
                    50.5331126
                 ],
                 [
                    98.27897179999997,
                    50.53307640000002
                 ],
                 [
                    98.278987,
                    50.53297990000003
                 ],
                 [
                    98.27876320000003,
                    50.532693
                 ],
                 [
                    98.27872140000002,
                    50.532601399999976
                 ],
                 [
                    98.27880869999997,
                    50.5320324
                 ],
                 [
                    98.278858,
                    50.53191910000001
                 ],
                 [
                    98.27894519999998,
                    50.5318395
                 ],
                 [
                    98.27910079999998,
                    50.53173580000003
                 ],
                 [
                    98.27934730000004,
                    50.531603199999985
                 ],
                 [
                    98.279666,
                    50.53149950000002
                 ],
                 [
                    98.2798139,
                    50.5314778
                 ],
                 [
                    98.27995040000002,
                    50.531521200000036
                 ],
                 [
                    98.28009840000001,
                    50.53149950000002
                 ],
                 [
                    98.280417,
                    50.53119570000002
                 ],
                 [
                    98.28076220000004,
                    50.530985899999976
                 ],
                 [
                    98.2811567,
                    50.5306725
                 ],
                 [
                    98.28126670000005,
                    50.530602499999965
                 ],
                 [
                    98.28162710000002,
                    50.53043130000001
                 ],
                 [
                    98.2816688,
                    50.53033969999998
                 ],
                 [
                    98.28168019999997,
                    50.5302433
                 ],
                 [
                    98.28202149999997,
                    50.52999489999996
                 ],
                 [
                    98.28208220000002,
                    50.52990570000004
                 ],
                 [
                    98.28215049999999,
                    50.52972
                 ],
                 [
                    98.28252230000001,
                    50.529360799999985
                 ],
                 [
                    98.28265880000002,
                    50.5292643
                 ],
                 [
                    98.28277639999999,
                    50.529204
                 ],
                 [
                    98.28344780000003,
                    50.52893879999998
                 ],
                 [
                    98.28359190000005,
                    50.52883750000003
                 ],
                 [
                    98.28406610000003,
                    50.52862050000002
                 ],
                 [
                    98.2842633,
                    50.528471
                 ],
                 [
                    98.28440750000003,
                    50.52821060000003
                 ],
                 [
                    98.2844416,
                    50.5280514
                 ],
                 [
                    98.284582,
                    50.527885
                 ],
                 [
                    98.28467680000001,
                    50.52781030000004
                 ],
                 [
                    98.2850941,
                    50.52758839999998
                 ],
                 [
                    98.28535579999998,
                    50.527383499999985
                 ],
                 [
                    98.28607269999996,
                    50.52695910000004
                 ],
                 [
                    98.2861675,
                    50.52684569999999
                 ],
                 [
                    98.28612959999997,
                    50.526771
                 ],
                 [
                    98.2859893,
                    50.5267396
                 ],
                 [
                    98.28614479999997,
                    50.5265346
                 ],
                 [
                    98.28626990000002,
                    50.52647680000002
                 ],
                 [
                    98.28641409999997,
                    50.5264454
                 ],
                 [
                    98.28653549999997,
                    50.5263875
                 ],
                 [
                    98.28663790000002,
                    50.526291100000016
                 ],
                 [
                    98.28696789999996,
                    50.526059600000046
                 ],
                 [
                    98.28703620000002,
                    50.52597040000004
                 ],
                 [
                    98.2870476,
                    50.5257799
                 ],
                 [
                    98.28744960000003,
                    50.525259
                 ],
                 [
                    98.28754449999997,
                    50.5251842
                 ],
                 [
                    98.28780620000005,
                    50.52508289999997
                 ],
                 [
                    98.28803379999995,
                    50.52485629999996
                 ],
                 [
                    98.28809069999996,
                    50.52476460000001
                 ],
                 [
                    98.28813240000004,
                    50.5245765
                 ],
                 [
                    98.2883069,
                    50.524328099999956
                 ],
                 [
                    98.28833349999998,
                    50.52423169999997
                 ],
                 [
                    98.28833730000002,
                    50.52398569999996
                 ],
                 [
                    98.28826139999995,
                    50.523308
                 ],
                 [
                    98.28825,
                    50.52282090000004
                 ],
                 [
                    98.2882007,
                    50.5227293
                 ],
                 [
                    98.28802620000003,
                    50.5225412
                 ],
                 [
                    98.28799209999997,
                    50.52244710000002
                 ],
                 [
                    98.288049,
                    50.52235790000001
                 ],
                 [
                    98.28817029999998,
                    50.5222397
                 ],
                 [
                    98.28821209999997,
                    50.52212640000004
                 ],
                 [
                    98.28817410000002,
                    50.521914099999975
                 ],
                 [
                    98.2881779,
                    50.5218177
                 ],
                 [
                    98.288269,
                    50.521603
                 ],
                 [
                    98.288296,
                    50.52138570000004
                 ],
                 [
                    98.28826950000001,
                    50.521291599999984
                 ],
                 [
                    98.2882201,
                    50.52120239999997
                 ],
                 [
                    98.28814430000004,
                    50.521118
                 ],
                 [
                    98.2877498,
                    50.52087919999997
                 ],
                 [
                    98.28766630000001,
                    50.5207948
                 ],
                 [
                    98.28762460000003,
                    50.52070319999997
                 ],
                 [
                    98.28743489999997,
                    50.520066499999956
                 ],
                 [
                    98.28741979999995,
                    50.519972399999986
                 ],
                 [
                    98.28747669999996,
                    50.51958650000003
                 ],
                 [
                    98.2874653,
                    50.51949
                 ],
                 [
                    98.28742360000001,
                    50.51939359999997
                 ],
                 [
                    98.28711250000002,
                    50.51910169999998
                 ],
                 [
                    98.28705560000002,
                    50.51901249999997
                 ],
                 [
                    98.2870404,
                    50.518822
                 ],
                 [
                    98.287086,
                    50.518609700000034
                 ],
                 [
                    98.28733629999998,
                    50.51826969999997
                 ],
                 [
                    98.28742360000001,
                    50.5180864
                 ],
                 [
                    98.28743870000001,
                    50.517992300000024
                 ],
                 [
                    98.28735530000004,
                    50.517635299999974
                 ],
                 [
                    98.28737049999997,
                    50.5175389
                 ],
                 [
                    98.2873667,
                    50.5174424
                 ],
                 [
                    98.287287,
                    50.517177099999984
                 ],
                 [
                    98.28723009999999,
                    50.5170878
                 ],
                 [
                    98.2871884,
                    50.516439
                 ],
                 [
                    98.28721489999997,
                    50.51632330000004
                 ],
                 [
                    98.28728320000002,
                    50.516236399999954
                 ],
                 [
                    98.28741979999995,
                    50.516125500000015
                 ],
                 [
                    98.28753360000005,
                    50.51606039999996
                 ],
                 [
                    98.28807980000003,
                    50.51583849999999
                 ],
                 [
                    98.28859950000005,
                    50.51557309999996
                 ],
                 [
                    98.28869810000003,
                    50.51549350000004
                 ],
                 [
                    98.28876260000004,
                    50.51540430000003
                 ],
                 [
                    98.28880809999998,
                    50.51531019999997
                 ],
                 [
                    98.28892570000004,
                    50.514760300000034
                 ],
                 [
                    98.2890091,
                    50.5144926
                 ],
                 [
                    98.28902430000002,
                    50.51421519999997
                 ],
                 [
                    98.28913810000002,
                    50.51387509999998
                 ],
                 [
                    98.289214,
                    50.5134192
                 ],
                 [
                    98.28928980000005,
                    50.51323829999996
                 ],
                 [
                    98.28934669999997,
                    50.5130188
                 ],
                 [
                    98.28952120000001,
                    50.512628
                 ],
                 [
                    98.2895478,
                    50.5125315
                 ],
                 [
                    98.28955910000003,
                    50.51243749999996
                 ],
                 [
                    98.28953259999997,
                    50.51223970000002
                 ],
                 [
                    98.28958949999998,
                    50.511981599999984
                 ],
                 [
                    98.28964260000002,
                    50.51189229999997
                 ],
                 [
                    98.2897336,
                    50.51181509999998
                 ],
                 [
                    98.28979429999995,
                    50.51172829999999
                 ],
                 [
                    98.28988540000003,
                    50.5114629
                 ],
                 [
                    98.289984,
                    50.51104559999999
                 ],
                 [
                    98.29020779999998,
                    50.510729599999955
                 ],
                 [
                    98.29023430000004,
                    50.510633099999964
                 ],
                 [
                    98.290223,
                    50.510095200000045
                 ],
                 [
                    98.29026090000002,
                    50.50999869999997
                 ],
                 [
                    98.29032919999999,
                    50.50990949999996
                 ],
                 [
                    98.29064019999996,
                    50.5095934
                 ],
                 [
                    98.2906857,
                    50.509499400000045
                 ],
                 [
                    98.2907009,
                    50.50900479999998
                 ],
                 [
                    98.2906137,
                    50.50874189999998
                 ],
                 [
                    98.2906137,
                    50.5086454
                 ],
                 [
                    98.2906364,
                    50.5085465
                 ],
                 [
                    98.2906857,
                    50.50845480000003
                 ],
                 [
                    98.29096640000004,
                    50.5081774
                 ],
                 [
                    98.29103089999995,
                    50.50809060000001
                 ],
                 [
                    98.291103,
                    50.50788790000002
                 ],
                 [
                    98.29134950000002,
                    50.50741030000003
                 ],
                 [
                    98.29144820000002,
                    50.50733310000004
                 ],
                 [
                    98.29157710000003,
                    50.50728
                 ],
                 [
                    98.29179710000003,
                    50.507207600000044
                 ],
                 [
                    98.29191850000004,
                    50.50714730000003
                 ],
                 [
                    98.29201710000002,
                    50.5070749
                 ],
                 [
                    98.29209679999995,
                    50.50699050000002
                 ],
                 [
                    98.29221819999997,
                    50.506787899999956
                 ],
                 [
                    98.29230160000003,
                    50.506708300000035
                 ],
                 [
                    98.29241919999998,
                    50.5066431
                 ],
                 [
                    98.29256340000002,
                    50.50659970000002
                 ],
                 [
                    98.29269990000003,
                    50.50654420000004
                 ],
                 [
                    98.29279860000003,
                    50.5064718
                 ],
                 [
                    98.29296170000002,
                    50.506274
                 ],
                 [
                    98.293011,
                    50.506179900000014
                 ],
                 [
                    98.29301860000002,
                    50.506085800000044
                 ],
                 [
                    98.29300719999996,
                    50.5059893
                 ],
                 [
                    98.2929389,
                    50.50566370000002
                 ],
                 [
                    98.29289339999997,
                    50.505572
                 ],
                 [
                    98.2927113,
                    50.505364499999956
                 ],
                 [
                    98.29266579999997,
                    50.505270399999986
                 ],
                 [
                    98.2927113,
                    50.50481690000004
                 ],
                 [
                    98.2927113,
                    50.504525
                 ],
                 [
                    98.29273029999997,
                    50.50443089999998
                 ],
                 [
                    98.29281370000002,
                    50.50435119999996
                 ],
                 [
                    98.29334480000001,
                    50.50415340000003
                 ],
                 [
                    98.29364819999998,
                    50.5039749
                 ],
                 [
                    98.29418689999996,
                    50.503584
                 ],
                 [
                    98.29432339999998,
                    50.503540600000036
                 ],
                 [
                    98.29447139999996,
                    50.503514099999975
                 ],
                 [
                    98.29460789999997,
                    50.50347549999998
                 ],
                 [
                    98.295139,
                    50.503183599999986
                 ],
                 [
                    98.29527549999997,
                    50.5031353
                 ],
                 [
                    98.2957762,
                    50.5030171
                 ],
                 [
                    98.29592040000004,
                    50.502993
                 ],
                 [
                    98.29604930000005,
                    50.502937499999966
                 ],
                 [
                    98.29623140000001,
                    50.50273
                 ],
                 [
                    98.2963224,
                    50.502652800000035
                 ],
                 [
                    98.29644759999996,
                    50.50259250000002
                 ],
                 [
                    98.29673969999997,
                    50.5025128
                 ],
                 [
                    98.297358,
                    50.50206169999998
                 ],
                 [
                    98.29746419999996,
                    50.501994099999976
                 ],
                 [
                    98.2981849,
                    50.5016563
                 ],
                 [
                    98.29827970000002,
                    50.501579100000015
                 ],
                 [
                    98.29835180000005,
                    50.501494700000045
                 ],
                 [
                    98.29846560000004,
                    50.501135199999965
                 ],
                 [
                    98.298458,
                    50.501038699999974
                 ],
                 [
                    98.29820389999998,
                    50.5003607
                 ],
                 [
                    98.298166,
                    50.5001942
                 ],
                 [
                    98.2982266,
                    50.4996731
                 ],
                 [
                    98.298257,
                    50.499579
                 ],
                 [
                    98.2983329,
                    50.49949450000002
                 ],
                 [
                    98.2987046,
                    50.499176
                 ],
                 [
                    98.29877670000003,
                    50.499089199999965
                 ],
                 [
                    98.29895109999998,
                    50.49881410000003
                 ],
                 [
                    98.29903459999996,
                    50.49873450000002
                 ],
                 [
                    98.29910290000002,
                    50.49864760000002
                 ],
                 [
                    98.2990915,
                    50.4984884
                 ],
                 [
                    98.29912939999998,
                    50.49839430000002
                 ],
                 [
                    98.29933050000002,
                    50.498153
                 ],
                 [
                    98.29943670000002,
                    50.497902
                 ],
                 [
                    98.29948979999996,
                    50.49781030000002
                 ],
                 [
                    98.29958080000003,
                    50.497733100000026
                 ],
                 [
                    98.30011950000002,
                    50.497503899999984
                 ],
                 [
                    98.30034710000002,
                    50.497366399999976
                 ],
                 [
                    98.30046840000003,
                    50.49730849999998
                 ],
                 [
                    98.30061259999997,
                    50.497277099999955
                 ],
                 [
                    98.3010678,
                    50.49725779999996
                 ],
                 [
                    98.3015685,
                    50.497180599999965
                 ],
                 [
                    98.3016974,
                    50.4971251
                 ],
                 [
                    98.3021223,
                    50.49690550000003
                 ],
                 [
                    98.30244849999998,
                    50.49678
                 ],
                 [
                    98.3026344,
                    50.496654599999964
                 ],
                 [
                    98.30287329999996,
                    50.4964736
                 ],
                 [
                    98.30326780000001,
                    50.49604169999997
                 ],
                 [
                    98.30338539999997,
                    50.49597890000001
                 ],
                 [
                    98.3036358,
                    50.49588
                 ],
                 [
                    98.30408720000004,
                    50.49564110000004
                 ],
                 [
                    98.30464190000002,
                    50.49528330000001
                 ],
                 [
                    98.30503190000005,
                    50.4949964
                 ],
                 [
                    98.30542619999999,
                    50.49479529999996
                 ],
                 [
                    98.305741,
                    50.4946924
                 ],
                 [
                    98.306419,
                    50.494786300000015
                 ],
                 [
                    98.30696510000004,
                    50.4945929
                 ],
                 [
                    98.307825,
                    50.4942752
                 ],
                 [
                    98.30849089999998,
                    50.49412239999997
                 ],
                 [
                    98.3094847,
                    50.49413490000003
                 ],
                 [
                    98.31021369999998,
                    50.4942386
                 ],
                 [
                    98.31155380000001,
                    50.494448200000015
                 ],
                 [
                    98.312165,
                    50.4945542
                 ],
                 [
                    98.31298119999998,
                    50.494720499999985
                 ],
                 [
                    98.3136996,
                    50.49495319999997
                 ],
                 [
                    98.3143145,
                    50.49513430000003
                 ],
                 [
                    98.31534349999995,
                    50.49517820000002
                 ],
                 [
                    98.31639310000003,
                    50.495296899999985
                 ],
                 [
                    98.3177496,
                    50.495495399999974
                 ],
                 [
                    98.31952279999999,
                    50.4956104
                 ],
                 [
                    98.3205319,
                    50.49559030000001
                 ],
                 [
                    98.32139650000003,
                    50.49571270000001
                 ],
                 [
                    98.3217607,
                    50.49558730000003
                 ],
                 [
                    98.32248390000002,
                    50.4955729
                 ],
                 [
                    98.32274460000002,
                    50.4953958
                 ],
                 [
                    98.32327590000001,
                    50.4952456
                 ],
                 [
                    98.3229345,
                    50.4948374
                 ],
                 [
                    98.32297359999995,
                    50.494231500000026
                 ],
                 [
                    98.32217680000002,
                    50.49385370000003
                 ],
                 [
                    98.3214532,
                    50.493435
                 ],
                 [
                    98.32141580000003,
                    50.49232969999997
                 ],
                 [
                    98.32154719999997,
                    50.4915754
                 ],
                 [
                    98.32262069999997,
                    50.491104600000035
                 ],
                 [
                    98.32396760000005,
                    50.490474699999965
                 ],
                 [
                    98.32470329999998,
                    50.49003049999998
                 ],
                 [
                    98.324863,
                    50.489855499999976
                 ],
                 [
                    98.3249545,
                    50.4896604
                 ],
                 [
                    98.325018,
                    50.4892403
                 ],
                 [
                    98.32494750000004,
                    50.48883359999998
                 ],
                 [
                    98.32485349999997,
                    50.488631499999954
                 ],
                 [
                    98.32433790000005,
                    50.4880727
                 ],
                 [
                    98.324227,
                    50.48787079999999
                 ],
                 [
                    98.32404570000003,
                    50.4872624
                 ],
                 [
                    98.32367569999997,
                    50.486582499999976
                 ],
                 [
                    98.32368090000004,
                    50.486346199999964
                 ],
                 [
                    98.3239661,
                    50.48529879999999
                 ],
                 [
                    98.3242225,
                    50.4846924
                 ],
                 [
                    98.32463180000002,
                    50.484115
                 ],
                 [
                    98.32536989999998,
                    50.4833808
                 ],
                 [
                    98.32607340000001,
                    50.48228219999997
                 ],
                 [
                    98.32667170000005,
                    50.481787
                 ],
                 [
                    98.32674579999997,
                    50.4815815
                 ],
                 [
                    98.32685710000003,
                    50.480763099999955
                 ],
                 [
                    98.3269479,
                    50.4805573
                 ],
                 [
                    98.32710759999996,
                    50.48038229999998
                 ],
                 [
                    98.32718169999997,
                    50.48017680000003
                 ],
                 [
                    98.32709440000004,
                    50.47977049999999
                 ],
                 [
                    98.32716469999998,
                    50.479146200000024
                 ],
                 [
                    98.3272787,
                    50.4787251
                 ],
                 [
                    98.32726429999997,
                    50.477404599999986
                 ],
                 [
                    98.32748119999997,
                    50.47702439999997
                 ],
                 [
                    98.32783419999996,
                    50.4766737
                 ],
                 [
                    98.32805109999997,
                    50.476293599999984
                 ],
                 [
                    98.3280205,
                    50.4756713
                 ],
                 [
                    98.32771040000002,
                    50.474839899999964
                 ],
                 [
                    98.3275996,
                    50.474638099999964
                 ],
                 [
                    98.32745510000004,
                    50.474437
                 ],
                 [
                    98.3272775,
                    50.47424729999996
                 ],
                 [
                    98.32664550000001,
                    50.474055900000025
                 ],
                 [
                    98.32593520000005,
                    50.47398420000004
                 ],
                 [
                    98.32562629999997,
                    50.47386150000001
                 ],
                 [
                    98.32548230000005,
                    50.47367109999998
                 ],
                 [
                    98.32538560000005,
                    50.473071600000026
                 ],
                 [
                    98.325043,
                    50.47226240000004
                 ],
                 [
                    98.32514380000002,
                    50.47122950000002
                 ],
                 [
                    98.32513379999999,
                    50.471025600000026
                 ],
                 [
                    98.32510650000005,
                    50.47081139999998
                 ],
                 [
                    98.325046,
                    50.470608599999984
                 ],
                 [
                    98.32488530000003,
                    50.4704185
                 ],
                 [
                    98.32449760000002,
                    50.47006109999998
                 ],
                 [
                    98.32315590000003,
                    50.46912150000004
                 ],
                 [
                    98.3222871,
                    50.468559
                 ],
                 [
                    98.32172070000003,
                    50.4682289
                 ],
                 [
                    98.32104010000003,
                    50.46783210000003
                 ],
                 [
                    98.32045710000001,
                    50.4672639
                 ],
                 [
                    98.31946120000003,
                    50.466167
                 ],
                 [
                    98.31913289999997,
                    50.465991
                 ],
                 [
                    98.3185011,
                    50.46579949999998
                 ],
                 [
                    98.3167515,
                    50.46545850000001
                 ],
                 [
                    98.3157625,
                    50.46518830000004
                 ],
                 [
                    98.31474310000002,
                    50.464983100000026
                 ],
                 [
                    98.31378509999998,
                    50.4646585
                 ],
                 [
                    98.31350939999999,
                    50.4645244
                 ],
                 [
                    98.3130887,
                    50.464178399999966
                 ],
                 [
                    98.31278420000002,
                    50.4637978
                 ],
                 [
                    98.3119639,
                    50.4631913
                 ],
                 [
                    98.3098282,
                    50.461827
                 ],
                 [
                    98.30871490000004,
                    50.46107590000004
                 ],
                 [
                    98.30813730000004,
                    50.4606149
                 ],
                 [
                    98.30797669999998,
                    50.4604248
                 ],
                 [
                    98.30772209999996,
                    50.46003250000004
                 ],
                 [
                    98.3075841,
                    50.45961649999999
                 ],
                 [
                    98.30755630000002,
                    50.459391500000024
                 ],
                 [
                    98.3075464,
                    50.45918770000004
                 ],
                 [
                    98.3076378,
                    50.45899259999997
                 ],
                 [
                    98.30792480000004,
                    50.45873360000004
                 ],
                 [
                    98.30811949999999,
                    50.458521299999965
                 ],
                 [
                    98.30812640000002,
                    50.45831710000004
                 ],
                 [
                    98.3080661,
                    50.45811430000004
                 ],
                 [
                    98.30735729999998,
                    50.457376600000025
                 ],
                 [
                    98.3073028,
                    50.456948200000014
                 ],
                 [
                    98.30705969999995,
                    50.45679189999996
                 ],
                 [
                    98.3061681,
                    50.4564445
                 ],
                 [
                    98.30443370000002,
                    50.45606009999999
                 ],
                 [
                    98.303494,
                    50.4554129
                 ],
                 [
                    98.30308929999998,
                    50.45504510000003
                 ],
                 [
                    98.30280059999998,
                    50.454642700000036
                 ],
                 [
                    98.3025786,
                    50.45422830000003
                 ],
                 [
                    98.30250839999997,
                    50.453821599999955
                 ],
                 [
                    98.30241450000001,
                    50.45361950000002
                 ],
                 [
                    98.30225340000001,
                    50.4534186
                 ],
                 [
                    98.30179979999996,
                    50.453084
                 ],
                 [
                    98.3012323,
                    50.452826699999974
                 ],
                 [
                    98.30024210000003,
                    50.4525242
                 ],
                 [
                    98.29895620000005,
                    50.452367
                 ],
                 [
                    98.298617,
                    50.452309299999975
                 ],
                 [
                    98.29829260000002,
                    50.45220829999997
                 ],
                 [
                    98.29826289999997,
                    50.4515968
                 ],
                 [
                    98.29799750000004,
                    50.45097919999996
                 ],
                 [
                    98.29796740000003,
                    50.450357
                 ],
                 [
                    98.2981785,
                    50.4502025
                 ],
                 [
                    98.29846,
                    50.450111
                 ],
                 [
                    98.29894469999998,
                    50.4500478
                 ],
                 [
                    98.29922560000004,
                    50.44994559999998
                 ],
                 [
                    98.29987639999997,
                    50.44949239999996
                 ],
                 [
                    98.30040310000004,
                    50.44925649999998
                 ],
                 [
                    98.300597,
                    50.44909160000002
                 ],
                 [
                    98.30063749999998,
                    50.44888680000003
                 ],
                 [
                    98.30044080000005,
                    50.4483
                 ],
                 [
                    98.29872989999997,
                    50.445617
                 ],
                 [
                    98.298304,
                    50.44480929999999
                 ],
                 [
                    98.29816580000002,
                    50.44403890000002
                 ],
                 [
                    98.298291,
                    50.44349949999997
                 ],
                 [
                    98.29867030000001,
                    50.442998
                 ],
                 [
                    98.2993741,
                    50.44225390000003
                 ],
                 [
                    98.2999593,
                    50.44149070000003
                 ],
                 [
                    98.30005070000001,
                    50.44129559999997
                 ],
                 [
                    98.29989659999997,
                    50.44089050000002
                 ],
                 [
                    98.29968610000005,
                    50.440712200000014
                 ],
                 [
                    98.29936229999998,
                    50.4406219
                 ],
                 [
                    98.29900740000002,
                    50.440586
                 ],
                 [
                    98.2986841,
                    50.44050640000004
                 ],
                 [
                    98.29832920000004,
                    50.4404705
                 ],
                 [
                    98.2976074,
                    50.440495500000026
                 ],
                 [
                    98.2964619,
                    50.44045370000003
                 ],
                 [
                    98.29574440000005,
                    50.44022090000004
                 ],
                 [
                    98.29519079999999,
                    50.439898900000046
                 ],
                 [
                    98.2946714,
                    50.43924339999999
                 ],
                 [
                    98.29447489999998,
                    50.438656600000016
                 ],
                 [
                    98.29401219999998,
                    50.4363892
                 ],
                 [
                    98.29354450000001,
                    50.43541050000002
                 ],
                 [
                    98.29329289999998,
                    50.43403020000003
                 ],
                 [
                    98.29286149999999,
                    50.4331045
                 ],
                 [
                    98.2925128,
                    50.4324992
                 ],
                 [
                    98.2915703,
                    50.4314332
                 ],
                 [
                    98.2908293,
                    50.43036320000002
                 ],
                 [
                    98.29075360000002,
                    50.42983859999996
                 ],
                 [
                    98.29050050000004,
                    50.4291241
                 ],
                 [
                    98.28990050000004,
                    50.42853449999998
                 ],
                 [
                    98.28932769999999,
                    50.42815920000001
                 ],
                 [
                    98.2887579,
                    50.42784830000004
                 ],
                 [
                    98.2881094,
                    50.42764630000003
                 ],
                 [
                    98.28705880000003,
                    50.427473600000035
                 ],
                 [
                    98.28670560000002,
                    50.4274698
                 ],
                 [
                    98.28565219999999,
                    50.42758720000002
                 ],
                 [
                    98.28529950000002,
                    50.42759409999997
                 ],
                 [
                    98.28502409999997,
                    50.42745989999997
                 ],
                 [
                    98.28488689999999,
                    50.4270546
                 ],
                 [
                    98.28492749999998,
                    50.42684970000002
                 ],
                 [
                    98.28516129999996,
                    50.42646929999999
                 ],
                 [
                    98.28553159999996,
                    50.4261291
                 ],
                 [
                    98.28565710000004,
                    50.4259441
                 ],
                 [
                    98.28580539999996,
                    50.425533099999974
                 ],
                 [
                    98.285846,
                    50.4253283
                 ],
                 [
                    98.2858047,
                    50.42447
                 ],
                 [
                    98.28557010000003,
                    50.42378740000004
                 ],
                 [
                    98.283591,
                    50.421431599999984
                 ],
                 [
                    98.28283639999998,
                    50.42076990000004
                 ],
                 [
                    98.28263620000003,
                    50.4204517
                 ],
                 [
                    98.282266,
                    50.42009379999996
                 ],
                 [
                    98.28183330000003,
                    50.419833800000035
                 ],
                 [
                    98.28143840000004,
                    50.419659
                 ],
                 [
                    98.28091320000001,
                    50.41957270000001
                 ],
                 [
                    98.28017590000003,
                    50.419619399999966
                 ],
                 [
                    98.27971269999998,
                    50.4194244
                 ],
                 [
                    98.27912380000002,
                    50.41906009999997
                 ],
                 [
                    98.27872159999995,
                    50.418735099999985
                 ],
                 [
                    98.27839769999999,
                    50.41829039999996
                 ],
                 [
                    98.27806479999997,
                    50.418007
                 ],
                 [
                    98.27645039999997,
                    50.41728689999998
                 ],
                 [
                    98.27588650000004,
                    50.4170939
                 ],
                 [
                    98.27534169999998,
                    50.41624549999997
                 ],
                 [
                    98.27458850000002,
                    50.41490719999999
                 ],
                 [
                    98.27292859999999,
                    50.413232200000046
                 ],
                 [
                    98.2725025,
                    50.41275730000001
                 ],
                 [
                    98.27224850000003,
                    50.412365
                 ],
                 [
                    98.27217960000002,
                    50.411979699999975
                 ],
                 [
                    98.272243,
                    50.411549
                 ],
                 [
                    98.2724317,
                    50.41127679999999
                 ],
                 [
                    98.27256740000004,
                    50.410952
                 ],
                 [
                    98.27260539999999,
                    50.410693599999966
                 ],
                 [
                    98.2725412,
                    50.41040489999997
                 ],
                 [
                    98.27265809999999,
                    50.41003750000004
                 ],
                 [
                    98.273278,
                    50.4096495
                 ],
                 [
                    98.273906,
                    50.4093298
                 ],
                 [
                    98.27681360000001,
                    50.40852370000002
                 ],
                 [
                    98.27793719999998,
                    50.40799050000004
                 ],
                 [
                    98.27918569999999,
                    50.40749120000003
                 ],
                 [
                    98.28111430000004,
                    50.407316200000025
                 ],
                 [
                    98.281945,
                    50.4069789
                 ],
                 [
                    98.282765,
                    50.406349499999976
                 ],
                 [
                    98.28306140000004,
                    50.405642899999954
                 ],
                 [
                    98.28314789999999,
                    50.40452720000001
                 ],
                 [
                    98.28254830000003,
                    50.4039376
                 ],
                 [
                    98.28144949999998,
                    50.40327480000004
                 ],
                 [
                    98.27922820000003,
                    50.402587099999984
                 ],
                 [
                    98.2779396,
                    50.402084300000034
                 ],
                 [
                    98.27695020000003,
                    50.401534899999966
                 ],
                 [
                    98.275459,
                    50.40115570000003
                 ],
                 [
                    98.2736049,
                    50.4010309
                 ],
                 [
                    98.2724585,
                    50.40072249999998
                 ],
                 [
                    98.270563,
                    50.398525
                 ],
                 [
                    98.269631,
                    50.3973286
                 ],
                 [
                    98.2693703,
                    50.396234900000024
                 ],
                 [
                    98.26989640000002,
                    50.395165
                 ],
                 [
                    98.27065210000002,
                    50.394087100000036
                 ],
                 [
                    98.272135,
                    50.3927803
                 ],
                 [
                    98.2738048,
                    50.3908147
                 ],
                 [
                    98.27540460000004,
                    50.389449
                 ],
                 [
                    98.27800130000004,
                    50.38795130000004
                 ],
                 [
                    98.27909940000004,
                    50.3872068
                 ],
                 [
                    98.27963080000005,
                    50.38655560000002
                 ],
                 [
                    98.27996679999995,
                    50.3855074
                 ],
                 [
                    98.280459,
                    50.38479889999998
                 ],
                 [
                    98.28119550000001,
                    50.38416239999999
                 ],
                 [
                    98.2817042,
                    50.38357189999998
                 ],
                 [
                    98.28259889999995,
                    50.38261
                 ],
                 [
                    98.2834581,
                    50.381615899999964
                 ],
                 [
                    98.284883,
                    50.380631599999965
                 ],
                 [
                    98.2858474,
                    50.38031260000004
                 ],
                 [
                    98.28708350000002,
                    50.379826499999965
                 ],
                 [
                    98.2881112,
                    50.37924679999996
                 ],
                 [
                    98.28865339999997,
                    50.37824930000003
                 ],
                 [
                    98.289538,
                    50.3772302
                 ],
                 [
                    98.29041359999995,
                    50.37644280000004
                 ],
                 [
                    98.29072570000002,
                    50.37574439999999
                 ],
                 [
                    98.29062439999998,
                    50.3748923
                 ],
                 [
                    98.29009829999998,
                    50.37373210000002
                 ],
                 [
                    98.2898108,
                    50.37242389999996
                 ],
                 [
                    98.28994039999996,
                    50.37062379999997
                 ],
                 [
                    98.29026249999997,
                    50.3691221
                 ],
                 [
                    98.29082020000003,
                    50.36782840000004
                 ],
                 [
                    98.29161589999995,
                    50.366894
                 ],
                 [
                    98.292574,
                    50.366098
                 ],
                 [
                    98.2959672,
                    50.36452080000002
                 ],
                 [
                    98.298178,
                    50.36324460000003
                 ],
                 [
                    98.29916290000001,
                    50.362213299999965
                 ],
                 [
                    98.29974610000004,
                    50.36142859999997
                 ],
                 [
                    98.299782,
                    50.3607152
                 ],
                 [
                    98.29964349999999,
                    50.35930439999998
                 ],
                 [
                    98.29958439999997,
                    50.3585242
                 ],
                 [
                    98.3002985,
                    50.3572297
                 ],
                 [
                    98.30051260000003,
                    50.356530699999986
                 ],
                 [
                    98.30007120000002,
                    50.354957600000034
                 ],
                 [
                    98.29950780000003,
                    50.353401100000035
                 ],
                 [
                    98.29950580000005,
                    50.35194049999998
                 ],
                 [
                    98.2995182,
                    50.350853500000014
                 ],
                 [
                    98.29909440000003,
                    50.35002639999997
                 ],
                 [
                    98.29788660000001,
                    50.349341599999974
                 ],
                 [
                    98.29633560000002,
                    50.3485024
                 ],
                 [
                    98.29347190000004,
                    50.3468558
                 ],
                 [
                    98.2920277,
                    50.34617120000002
                 ],
                 [
                    98.29053070000005,
                    50.34574969999996
                 ],
                 [
                    98.28765140000003,
                    50.3448507
                 ],
                 [
                    98.28573950000002,
                    50.34381969999997
                 ],
                 [
                    98.28520559999998,
                    50.34319130000002
                 ],
                 [
                    98.2851467,
                    50.342224300000034
                 ],
                 [
                    98.28546079999997,
                    50.3411675
                 ],
                 [
                    98.2865605,
                    50.34015690000004
                 ],
                 [
                    98.288214,
                    50.339448799999985
                 ],
                 [
                    98.2902972,
                    50.3390335
                 ],
                 [
                    98.29422960000004,
                    50.3388864
                 ],
                 [
                    98.2971952,
                    50.338842099999965
                 ],
                 [
                    98.29890330000002,
                    50.338969799999965
                 ],
                 [
                    98.30128039999997,
                    50.339352
                 ],
                 [
                    98.30278409999995,
                    50.33916180000004
                 ],
                 [
                    98.30401850000004,
                    50.338374199999976
                 ],
                 [
                    98.30460389999998,
                    50.33778919999998
                 ],
                 [
                    98.30525930000003,
                    50.33627710000001
                 ],
                 [
                    98.30625579999997,
                    50.3344069
                 ],
                 [
                    98.30721429999996,
                    50.331609900000025
                 ],
                 [
                    98.3073458,
                    50.3300198
                 ],
                 [
                    98.3073704,
                    50.3286
                 ],
                 [
                    98.306711,
                    50.3274533
                 ],
                 [
                    98.30656279999997,
                    50.32612470000004
                 ],
                 [
                    98.30689339999996,
                    50.32429249999996
                 ],
                 [
                    98.30724039999998,
                    50.3236531
                 ],
                 [
                    98.30812980000003,
                    50.323294099999956
                 ],
                 [
                    98.30784259999999,
                    50.322936699999964
                 ],
                 [
                    98.30701540000003,
                    50.32248069999998
                 ],
                 [
                    98.30625059999998,
                    50.322275699999985
                 ],
                 [
                    98.30521120000004,
                    50.3221138
                 ],
                 [
                    98.30505840000002,
                    50.32207379999998
                 ],
                 [
                    98.30492120000004,
                    50.32201210000002
                 ],
                 [
                    98.30478319999999,
                    50.32193429999998
                 ],
                 [
                    98.30458139999999,
                    50.32175040000004
                 ],
                 [
                    98.30450919999996,
                    50.321644399999975
                 ],
                 [
                    98.30450380000005,
                    50.32153179999998
                 ],
                 [
                    98.30443160000003,
                    50.3214258
                 ],
                 [
                    98.30434289999997,
                    50.32132559999997
                 ],
                 [
                    98.30422110000002,
                    50.3212367
                 ],
                 [
                    98.30342289999996,
                    50.321032399999964
                 ],
                 [
                    98.30283369999998,
                    50.32081320000003
                 ],
                 [
                    98.30231529999998,
                    50.320673099999986
                 ],
                 [
                    98.30214620000002,
                    50.3206443
                 ],
                 [
                    98.30159260000005,
                    50.32046730000001
                 ],
                 [
                    98.301231,
                    50.3204476
                 ],
                 [
                    98.3009415,
                    50.32035670000001
                 ],
                 [
                    98.3008578,
                    50.32018649999996
                 ],
                 [
                    98.30064430000002,
                    50.319933
                 ],
                 [
                    98.29929070000001,
                    50.3189933
                 ],
                 [
                    98.29905709999996,
                    50.318670400000016
                 ],
                 [
                    98.29897220000004,
                    50.3183016
                 ],
                 [
                    98.29886719999996,
                    50.31821239999996
                 ],
                 [
                    98.29850319999996,
                    50.31796730000002
                 ],
                 [
                    98.2983577,
                    50.31790569999998
                 ],
                 [
                    98.29783049999999,
                    50.31775509999996
                 ],
                 [
                    98.29758789999998,
                    50.3175934
                 ],
                 [
                    98.2974108,
                    50.3173982
                 ],
                 [
                    98.29729770000004,
                    50.31731460000002
                 ],
                 [
                    98.29701319999995,
                    50.31715370000003
                 ],
                 [
                    98.296792,
                    50.31708830000003
                 ],
                 [
                    98.29663180000001,
                    50.31707
                 ],
                 [
                    98.29574010000005,
                    50.3171843
                 ],
                 [
                    98.29525020000001,
                    50.31728519999998
                 ],
                 [
                    98.29448820000002,
                    50.317482799999986
                 ],
                 [
                    98.29399650000002,
                    50.3175462
                 ],
                 [
                    98.293836,
                    50.31752249999999
                 ],
                 [
                    98.2935379,
                    50.31742640000003
                 ],
                 [
                    98.29304579999996,
                    50.31730720000002
                 ],
                 [
                    98.29250870000001,
                    50.3171245
                 ],
                 [
                    98.2912032,
                    50.3170053
                 ],
                 [
                    98.29002,
                    50.31681389999998
                 ],
                 [
                    98.28986,
                    50.31680089999997
                 ],
                 [
                    98.2891728,
                    50.31680910000002
                 ],
                 [
                    98.2890121,
                    50.31678
                 ],
                 [
                    98.28886709999998,
                    50.31672919999998
                 ],
                 [
                    98.28873780000004,
                    50.316656600000016
                 ],
                 [
                    98.288424,
                    50.3165822
                 ],
                 [
                    98.28815190000005,
                    50.31632979999998
                 ],
                 [
                    98.28787739999999,
                    50.316201
                 ],
                 [
                    98.28755719999995,
                    50.31616969999997
                 ],
                 [
                    98.28738789999998,
                    50.3161355
                 ],
                 [
                    98.2870897,
                    50.316039300000014
                 ],
                 [
                    98.28681469999998,
                    50.31589980000002
                 ],
                 [
                    98.28633770000005,
                    50.31557089999998
                 ],
                 [
                    98.28620030000005,
                    50.31550380000002
                 ],
                 [
                    98.28587810000003,
                    50.315429600000016
                 ],
                 [
                    98.28563589999997,
                    50.31527329999996
                 ],
                 [
                    98.28545879999997,
                    50.31507809999997
                 ],
                 [
                    98.28532950000002,
                    50.3150054
                 ],
                 [
                    98.28516850000003,
                    50.314971
                 ],
                 [
                    98.28501490000002,
                    50.314915
                 ],
                 [
                    98.28480509999997,
                    50.31473659999996
                 ],
                 [
                    98.28434449999997,
                    50.3145738
                 ],
                 [
                    98.28373469999997,
                    50.3144462
                 ],
                 [
                    98.28356629999998,
                    50.31443340000001
                 ],
                 [
                    98.28296839999997,
                    50.3142035
                 ],
                 [
                    98.28270989999999,
                    50.31405819999996
                 ],
                 [
                    98.282135,
                    50.313785
                 ],
                 [
                    98.28162649999997,
                    50.31332240000001
                 ],
                 [
                    98.28149720000002,
                    50.313249800000044
                 ],
                 [
                    98.28105239999998,
                    50.31306519999996
                 ],
                 [
                    98.2809581,
                    50.312847
                 ],
                 [
                    98.28086969999997,
                    50.312752
                 ],
                 [
                    98.28070820000002,
                    50.31270689999998
                 ],
                 [
                    98.28032920000001,
                    50.31267139999997
                 ],
                 [
                    98.27971489999999,
                    50.3128015
                 ],
                 [
                    98.27955579999997,
                    50.31280469999999
                 ],
                 [
                    98.2792278,
                    50.31278419999998
                 ],
                 [
                    98.27908249999997,
                    50.312728
                 ],
                 [
                    98.2789216,
                    50.31269360000003
                 ],
                 [
                    98.2784355,
                    50.312697800000024
                 ],
                 [
                    98.2781199,
                    50.312763
                 ],
                 [
                    98.27756740000002,
                    50.31295640000002
                 ],
                 [
                    98.27708459999995,
                    50.3130303
                 ],
                 [
                    98.27675,
                    50.31304760000001
                 ],
                 [
                    98.27639769999996,
                    50.313043700000044
                 ],
                 [
                    98.27590040000003,
                    50.31298909999997
                 ],
                 [
                    98.275586,
                    50.31290390000002
                 ],
                 [
                    98.2752872,
                    50.31279169999998
                 ],
                 [
                    98.275012,
                    50.31264669999997
                 ],
                 [
                    98.2745432,
                    50.3123122
                 ],
                 [
                    98.27406060000004,
                    50.31221429999999
                 ],
                 [
                    98.27340179999997,
                    50.312114399999984
                 ],
                 [
                    98.272902,
                    50.3120061
                 ],
                 [
                    98.27258130000003,
                    50.311964
                 ],
                 [
                    98.27232240000002,
                    50.311808
                 ],
                 [
                    98.27230020000005,
                    50.3116957
                 ],
                 [
                    98.2724238,
                    50.311473100000036
                 ],
                 [
                    98.27246,
                    50.31135429999998
                 ],
                 [
                    98.2724866,
                    50.3110316
                 ],
                 [
                    98.27248120000002,
                    50.310919
                 ],
                 [
                    98.27234510000002,
                    50.310701499999965
                 ],
                 [
                    98.272224,
                    50.310623299999975
                 ],
                 [
                    98.2716266,
                    50.31040409999996
                 ],
                 [
                    98.2709789,
                    50.31000870000002
                 ],
                 [
                    98.2706133,
                    50.309725899999975
                 ],
                 [
                    98.27030909999996,
                    50.309323800000016
                 ],
                 [
                    98.27022170000002,
                    50.309073099999964
                 ],
                 [
                    98.27028219999997,
                    50.30858340000004
                 ],
                 [
                    98.27026839999998,
                    50.30847089999997
                 ],
                 [
                    98.27022959999996,
                    50.30835890000002
                 ],
                 [
                    98.27012469999998,
                    50.30826960000001
                 ],
                 [
                    98.26997949999996,
                    50.30821339999998
                 ],
                 [
                    98.26981910000002,
                    50.30818969999997
                 ],
                 [
                    98.26885579999998,
                    50.30820850000001
                 ],
                 [
                    98.2685179,
                    50.308156
                 ],
                 [
                    98.26817040000003,
                    50.30807689999996
                 ],
                 [
                    98.26788050000002,
                    50.30797519999999
                 ],
                 [
                    98.26678370000005,
                    50.307652899999965
                 ],
                 [
                    98.266244,
                    50.30741110000003
                 ],
                 [
                    98.26617209999998,
                    50.307310499999964
                 ],
                 [
                    98.266119,
                    50.307075300000044
                 ],
                 [
                    98.26599179999998,
                    50.30686839999998
                 ],
                 [
                    98.2659514,
                    50.30672420000004
                 ],
                 [
                    98.26598380000003,
                    50.30652489999998
                 ],
                 [
                    98.2659534,
                    50.306412700000024
                 ],
                 [
                    98.26571069999999,
                    50.30624559999996
                 ],
                 [
                    98.26556720000002,
                    50.30604980000002
                 ],
                 [
                    98.26553720000003,
                    50.30577119999997
                 ],
                 [
                    98.2655712,
                    50.3054269
                 ],
                 [
                    98.26561909999997,
                    50.3052004
                 ],
                 [
                    98.26568909999997,
                    50.305086299999964
                 ],
                 [
                    98.26570030000002,
                    50.30496790000003
                 ],
                 [
                    98.26563070000003,
                    50.304738399999955
                 ],
                 [
                    98.26557529999998,
                    50.30463210000004
                 ],
                 [
                    98.26539130000003,
                    50.3044639
                 ],
                 [
                    98.26527020000005,
                    50.3043857
                 ],
                 [
                    98.26497949999998,
                    50.30426790000002
                 ],
                 [
                    98.26471280000003,
                    50.3041227
                 ],
                 [
                    98.26459170000004,
                    50.30404460000002
                 ],
                 [
                    98.26441450000003,
                    50.303844
                 ],
                 [
                    98.2642693,
                    50.3036106
                 ],
                 [
                    98.26413770000005,
                    50.3031352
                 ],
                 [
                    98.26389910000002,
                    50.30269960000002
                 ],
                 [
                    98.2638107,
                    50.30260469999998
                 ],
                 [
                    98.26328019999997,
                    50.302201600000025
                 ],
                 [
                    98.26307839999997,
                    50.302012199999986
                 ],
                 [
                    98.2629571,
                    50.3019287
                 ],
                 [
                    98.26267459999995,
                    50.30180530000001
                 ],
                 [
                    98.262352,
                    50.3017203
                 ],
                 [
                    98.26168480000004,
                    50.3016152
                 ],
                 [
                    98.26139470000003,
                    50.30150810000002
                 ],
                 [
                    98.260265,
                    50.301197100000046
                 ],
                 [
                    98.25999060000002,
                    50.30106820000004
                 ],
                 [
                    98.25979750000003,
                    50.3008841
                 ],
                 [
                    98.2597254,
                    50.30077810000002
                 ],
                 [
                    98.2595665,
                    50.3004322
                 ],
                 [
                    98.259301,
                    50.300136699999975
                 ],
                 [
                    98.25922459999997,
                    50.2999395
                 ],
                 [
                    98.25922230000005,
                    50.299714
                 ],
                 [
                    98.259085,
                    50.299646900000035
                 ],
                 [
                    98.2589244,
                    50.29961780000003
                 ],
                 [
                    98.25876519999997,
                    50.299620900000015
                 ],
                 [
                    98.25860480000001,
                    50.2995972
                 ],
                 [
                    98.2581452,
                    50.29945040000003
                 ],
                 [
                    98.25785409999999,
                    50.29932179999996
                 ],
                 [
                    98.2576115,
                    50.29915469999999
                 ],
                 [
                    98.25719049999998,
                    50.29876020000003
                 ],
                 [
                    98.257022,
                    50.29856480000003
                 ],
                 [
                    98.2569278,
                    50.2983465
                 ],
                 [
                    98.25684680000005,
                    50.29787550000002
                 ],
                 [
                    98.25679940000003,
                    50.2977583
                 ],
                 [
                    98.25655880000002,
                    50.297457
                 ],
                 [
                    98.2560053,
                    50.29709720000002
                 ],
                 [
                    98.25578729999995,
                    50.2969189
                 ],
                 [
                    98.25540820000003,
                    50.296523599999986
                 ],
                 [
                    98.25499529999998,
                    50.29630070000002
                 ],
                 [
                    98.25456010000002,
                    50.2961373
                 ],
                 [
                    98.25430130000002,
                    50.295981299999966
                 ],
                 [
                    98.254213,
                    50.295886300000014
                 ],
                 [
                    98.25413529999996,
                    50.29566230000004
                 ],
                 [
                    98.25388639999997,
                    50.29536109999999
                 ],
                 [
                    98.25313479999996,
                    50.294887100000025
                 ],
                 [
                    98.25294949999999,
                    50.294692
                 ],
                 [
                    98.25279730000001,
                    50.294485500000015
                 ],
                 [
                    98.25248930000002,
                    50.293820399999966
                 ],
                 [
                    98.2522957,
                    50.2936254
                 ],
                 [
                    98.25212110000001,
                    50.2933013
                 ],
                 [
                    98.25209020000004,
                    50.293178400000045
                 ],
                 [
                    98.25219430000003,
                    50.292542800000035
                 ],
                 [
                    98.2521831,
                    50.29230679999996
                 ],
                 [
                    98.25215269999997,
                    50.2921946
                 ],
                 [
                    98.25152069999999,
                    50.291235
                 ],
                 [
                    98.2512523,
                    50.2910523
                 ],
                 [
                    98.2510947,
                    50.29091039999997
                 ],
                 [
                    98.25100620000005,
                    50.29081010000002
                 ],
                 [
                    98.2509508,
                    50.290703800000024
                 ],
                 [
                    98.25092909999998,
                    50.29024240000004
                 ],
                 [
                    98.25077129999998,
                    50.28991790000001
                 ],
                 [
                    98.2506277,
                    50.28971669999997
                 ],
                 [
                    98.25060560000003,
                    50.2896044
                 ],
                 [
                    98.25067569999997,
                    50.2891359
                 ],
                 [
                    98.25062849999998,
                    50.289024
                 ],
                 [
                    98.25049690000002,
                    50.28889769999998
                 ],
                 [
                    98.2503594,
                    50.28882520000002
                 ],
                 [
                    98.2497624,
                    50.2886059
                 ],
                 [
                    98.24952970000001,
                    50.2882936
                 ],
                 [
                    98.24947439999997,
                    50.2881873
                 ],
                 [
                    98.2494608,
                    50.28807480000001
                 ],
                 [
                    98.24944960000005,
                    50.287838800000024
                 ],
                 [
                    98.249489,
                    50.28760710000004
                 ],
                 [
                    98.24945859999998,
                    50.287495
                 ],
                 [
                    98.2492676,
                    50.28717650000003
                 ],
                 [
                    98.2492729,
                    50.28693480000001
                 ],
                 [
                    98.24915909999999,
                    50.2864752
                 ],
                 [
                    98.24914260000004,
                    50.286126500000016
                 ],
                 [
                    98.2492492,
                    50.285899
                 ],
                 [
                    98.24950070000001,
                    50.28572229999997
                 ],
                 [
                    98.24968360000004,
                    50.28551470000002
                 ],
                 [
                    98.24972830000002,
                    50.28539570000003
                 ],
                 [
                    98.2497509,
                    50.2851644
                 ],
                 [
                    98.24979410000003,
                    50.2850132
                 ],
                 [
                    98.24987269999997,
                    50.284904299999965
                 ],
                 [
                    98.24994,
                    50.284554
                 ],
                 [
                    98.250089,
                    50.28433630000004
                 ],
                 [
                    98.250142,
                    50.28421720000004
                 ],
                 [
                    98.25017,
                    50.28374409999998
                 ],
                 [
                    98.25012330000001,
                    50.28328869999996
                 ],
                 [
                    98.24992910000002,
                    50.28272329999999
                 ],
                 [
                    98.24981010000003,
                    50.2825108
                 ],
                 [
                    98.24972190000001,
                    50.28241590000004
                 ],
                 [
                    98.24960869999997,
                    50.28232680000004
                 ],
                 [
                    98.2494717,
                    50.282265
                 ],
                 [
                    98.24935050000002,
                    50.2821814
                 ],
                 [
                    98.24922290000002,
                    50.28196380000003
                 ],
                 [
                    98.24919219999997,
                    50.281846199999976
                 ],
                 [
                    98.24922019999998,
                    50.281727600000025
                 ],
                 [
                    98.24933279999998,
                    50.28162870000001
                 ],
                 [
                    98.24947129999997,
                    50.281545499999964
                 ],
                 [
                    98.24963569999998,
                    50.2814779
                 ],
                 [
                    98.24997440000003,
                    50.28119750000004
                 ],
                 [
                    98.25006990000003,
                    50.28091109999997
                 ],
                 [
                    98.24997230000004,
                    50.28044050000002
                 ],
                 [
                    98.24994740000002,
                    50.280092
                 ],
                 [
                    98.24987770000003,
                    50.2798571
                 ],
                 [
                    98.2498141,
                    50.27975089999998
                 ],
                 [
                    98.249273,
                    50.27929420000003
                 ],
                 [
                    98.2492094,
                    50.279188099999956
                 ],
                 [
                    98.24922080000003,
                    50.27907510000002
                 ],
                 [
                    98.2492741,
                    50.27896130000002
                 ],
                 [
                    98.24942170000004,
                    50.278894
                 ],
                 [
                    98.249526,
                    50.27879539999997
                 ],
                 [
                    98.249504,
                    50.278683
                 ],
                 [
                    98.24935989999999,
                    50.27847109999996
                 ],
                 [
                    98.24923030000002,
                    50.27838769999999
                 ],
                 [
                    98.24881889999999,
                    50.27819160000002
                 ],
                 [
                    98.24870550000003,
                    50.27809710000002
                 ],
                 [
                    98.24863370000004,
                    50.27799650000004
                 ],
                 [
                    98.24864520000001,
                    50.277883500000016
                 ],
                 [
                    98.24874940000002,
                    50.27778490000003
                 ],
                 [
                    98.24904419999999,
                    50.2776396
                 ],
                 [
                    98.24929739999997,
                    50.27732320000002
                 ],
                 [
                    98.24933369999995,
                    50.2772044
                 ],
                 [
                    98.24933120000001,
                    50.27697359999996
                 ],
                 [
                    98.24927589999997,
                    50.27686729999996
                 ],
                 [
                    98.2491877,
                    50.2767723
                 ],
                 [
                    98.24869610000005,
                    50.276298599999976
                 ],
                 [
                    98.2485668,
                    50.2762206
                 ],
                 [
                    98.2482528,
                    50.27613539999997
                 ],
                 [
                    98.247971,
                    50.27602269999998
                 ],
                 [
                    98.24774529999998,
                    50.275855199999974
                 ],
                 [
                    98.24767319999997,
                    50.2757492
                 ],
                 [
                    98.2476426,
                    50.27563170000004
                 ],
                 [
                    98.247637,
                    50.27551369999996
                 ],
                 [
                    98.2476898,
                    50.275389199999964
                 ],
                 [
                    98.24803890000003,
                    50.27497440000001
                 ],
                 [
                    98.2480922,
                    50.27486060000001
                 ],
                 [
                    98.24809829999997,
                    50.27463489999999
                 ],
                 [
                    98.2480763,
                    50.274522600000026
                 ],
                 [
                    98.2479655,
                    50.274304600000015
                 ],
                 [
                    98.247935,
                    50.27419239999997
                 ],
                 [
                    98.2479462,
                    50.27407410000004
                 ],
                 [
                    98.24799949999996,
                    50.27396030000004
                 ],
                 [
                    98.2483382,
                    50.27367990000002
                 ],
                 [
                    98.24840849999997,
                    50.2735712
                 ],
                 [
                    98.24844470000004,
                    50.273452400000025
                 ],
                 [
                    98.24843920000002,
                    50.27333430000002
                 ],
                 [
                    98.24820669999997,
                    50.27284490000003
                 ],
                 [
                    98.24799639999998,
                    50.2726503
                 ],
                 [
                    98.24768189999998,
                    50.27255429999997
                 ],
                 [
                    98.24735320000005,
                    50.27251239999997
                 ],
                 [
                    98.24696550000003,
                    50.272283600000044
                 ],
                 [
                    98.24652239999996,
                    50.27212570000003
                 ],
                 [
                    98.24636159999999,
                    50.27209130000003
                 ],
                 [
                    98.24622460000002,
                    50.27202949999998
                 ],
                 [
                    98.24600680000002,
                    50.271851099999964
                 ],
                 [
                    98.245935,
                    50.27175049999999
                 ],
                 [
                    98.245801,
                    50.27121620000001
                 ],
                 [
                    98.2457457,
                    50.2711098
                 ],
                 [
                    98.2456164,
                    50.27103180000003
                 ],
                 [
                    98.24466339999998,
                    50.27054010000003
                 ],
                 [
                    98.24451829999997,
                    50.2704839
                 ],
                 [
                    98.24402679999997,
                    50.27036449999997
                 ],
                 [
                    98.2429858,
                    50.26996040000002
                 ],
                 [
                    98.24287240000002,
                    50.269866
                 ],
                 [
                    98.24214150000005,
                    50.2689297
                 ],
                 [
                    98.24192369999996,
                    50.2687513
                 ],
                 [
                    98.24185140000002,
                    50.26864
                 ],
                 [
                    98.24191930000003,
                    50.26830039999999
                 ],
                 [
                    98.2418427,
                    50.268097900000015
                 ],
                 [
                    98.24171569999999,
                    50.26789090000003
                 ],
                 [
                    98.2416191,
                    50.2677961
                 ],
                 [
                    98.2408927,
                    50.2673108
                 ],
                 [
                    98.24077380000003,
                    50.2670983
                 ],
                 [
                    98.2406853,
                    50.266998
                 ],
                 [
                    98.24022029999998,
                    50.266727799999984
                 ],
                 [
                    98.23993860000003,
                    50.26661509999999
                 ],
                 [
                    98.239457,
                    50.26652770000004
                 ],
                 [
                    98.2391299,
                    50.266517900000025
                 ],
                 [
                    98.2388101,
                    50.2664865
                 ],
                 [
                    98.2384806,
                    50.2664284
                 ],
                 [
                    98.23833590000002,
                    50.266377499999955
                 ],
                 [
                    98.237793,
                    50.266055
                 ],
                 [
                    98.237194,
                    50.26542759999997
                 ],
                 [
                    98.23684620000004,
                    50.2651551
                 ],
                 [
                    98.23645210000004,
                    50.26496939999998
                 ],
                 [
                    98.2362291,
                    50.26467839999997
                 ],
                 [
                    98.23614170000002,
                    50.2644223
                 ],
                 [
                    98.236116,
                    50.264052399999954
                 ],
                 [
                    98.23605829999995,
                    50.263715200000036
                 ],
                 [
                    98.23596890000002,
                    50.2635934
                 ],
                 [
                    98.23573510000004,
                    50.26343150000001
                 ],
                 [
                    98.2352163,
                    50.26326430000003
                 ],
                 [
                    98.23492939999998,
                    50.26303890000003
                 ],
                 [
                    98.23464410000001,
                    50.26249140000003
                 ],
                 [
                    98.2343004,
                    50.26159060000001
                 ],
                 [
                    98.23422030000002,
                    50.261490100000046
                 ],
                 [
                    98.23363970000004,
                    50.26125419999998
                 ],
                 [
                    98.23352690000003,
                    50.261170499999984
                 ],
                 [
                    98.23347120000004,
                    50.26105349999998
                 ],
                 [
                    98.23346350000003,
                    50.26071
                 ],
                 [
                    98.233562,
                    50.2604879
                 ],
                 [
                    98.23376190000002,
                    50.26028540000002
                 ],
                 [
                    98.2338238,
                    50.26017680000001
                 ],
                 [
                    98.2338133,
                    50.25995150000002
                 ],
                 [
                    98.233678,
                    50.25974469999996
                 ],
                 [
                    98.23357330000005,
                    50.259655500000044
                 ],
                 [
                    98.23351779999999,
                    50.25954380000004
                 ],
                 [
                    98.23352069999996,
                    50.25942560000003
                 ],
                 [
                    98.2336189,
                    50.25919820000004
                 ],
                 [
                    98.23362509999998,
                    50.25897260000003
                 ],
                 [
                    98.2335641,
                    50.25874290000003
                 ],
                 [
                    98.23322049999999,
                    50.258379
                 ],
                 [
                    98.2328491,
                    50.25813909999999
                 ],
                 [
                    98.23278549999996,
                    50.258033
                 ],
                 [
                    98.2327881,
                    50.25790939999999
                 ],
                 [
                    98.23285839999996,
                    50.25780069999996
                 ],
                 [
                    98.23296269999997,
                    50.257702
                 ],
                 [
                    98.23333480000004,
                    50.2574211
                 ],
                 [
                    98.23361260000001,
                    50.2570936
                 ],
                 [
                    98.23363239999996,
                    50.25698040000004
                 ],
                 [
                    98.23362480000004,
                    50.25663690000004
                 ],
                 [
                    98.23366970000004,
                    50.25652329999996
                 ],
                 [
                    98.23367229999998,
                    50.25639979999996
                 ],
                 [
                    98.2335651,
                    50.25625690000004
                 ],
                 [
                    98.23341249999997,
                    50.2562168
                 ],
                 [
                    98.23327529999999,
                    50.25614969999996
                 ],
                 [
                    98.2331626,
                    50.256065900000046
                 ],
                 [
                    98.2327847,
                    50.25568660000001
                 ],
                 [
                    98.2327376,
                    50.2555747
                 ],
                 [
                    98.23275720000002,
                    50.255456199999955
                 ],
                 [
                    98.23282720000005,
                    50.25534210000002
                 ],
                 [
                    98.23307,
                    50.255160299999986
                 ],
                 [
                    98.23308120000002,
                    50.25504189999996
                 ],
                 [
                    98.23305919999997,
                    50.2549296
                 ],
                 [
                    98.2329459,
                    50.2548351
                 ],
                 [
                    98.23280040000004,
                    50.25476810000004
                 ],
                 [
                    98.2326043,
                    50.2545142
                 ],
                 [
                    98.2325413,
                    50.2542416
                 ],
                 [
                    98.23255149999997,
                    50.2541018
                 ],
                 [
                    98.23272969999996,
                    50.25361510000003
                 ],
                 [
                    98.2328449,
                    50.25339270000002
                 ],
                 [
                    98.23289470000003,
                    50.25320390000003
                 ],
                 [
                    98.23276859999999,
                    50.253013
                 ],
                 [
                    98.232575,
                    50.2528127
                 ],
                 [
                    98.23239540000002,
                    50.25237579999999
                 ],
                 [
                    98.23230679999998,
                    50.252270199999955
                 ],
                 [
                    98.23202439999997,
                    50.25214130000004
                 ],
                 [
                    98.23171109999998,
                    50.2520668
                 ],
                 [
                    98.23156580000004,
                    50.25200519999997
                 ],
                 [
                    98.23139649999997,
                    50.25196549999997
                 ],
                 [
                    98.23122789999996,
                    50.2519419
                 ],
                 [
                    98.2310694,
                    50.251955700000046
                 ],
                 [
                    98.2309203,
                    50.251990700000015
                 ],
                 [
                    98.2307614,
                    50.2519938
                 ],
                 [
                    98.2306324,
                    50.251921100000025
                 ],
                 [
                    98.23052770000002,
                    50.25183180000001
                 ],
                 [
                    98.2304725,
                    50.2517255
                 ],
                 [
                    98.23045890000002,
                    50.251613
                 ],
                 [
                    98.23049540000002,
                    50.25149949999996
                 ],
                 [
                    98.23057429999997,
                    50.251396
                 ],
                 [
                    98.23069559999998,
                    50.2513024
                 ],
                 [
                    98.2308709,
                    50.251111099999974
                 ],
                 [
                    98.23096909999995,
                    50.25088369999999
                 ],
                 [
                    98.23093880000003,
                    50.250771500000035
                 ],
                 [
                    98.23072320000004,
                    50.25045890000001
                 ],
                 [
                    98.23068760000002,
                    50.25023409999998
                 ],
                 [
                    98.23060720000004,
                    50.2501282
                 ],
                 [
                    98.2304704,
                    50.250066400000044
                 ],
                 [
                    98.229974,
                    50.250016900000034
                 ],
                 [
                    98.2297018,
                    50.249925500000025
                 ],
                 [
                    98.22954530000001,
                    50.2496224
                 ],
                 [
                    98.22954870000002,
                    50.24933779999998
                 ],
                 [
                    98.22966990000002,
                    50.24888440000004
                 ],
                 [
                    98.2297705,
                    50.24816840000003
                 ],
                 [
                    98.22990070000002,
                    50.24790809999999
                 ],
                 [
                    98.22993429999995,
                    50.24773030000002
                 ],
                 [
                    98.2300409,
                    50.24750280000003
                 ],
                 [
                    98.23011980000003,
                    50.24739919999998
                 ],
                 [
                    98.23013959999999,
                    50.24728610000004
                 ],
                 [
                    98.23011760000003,
                    50.247173799999985
                 ],
                 [
                    98.229941,
                    50.2469785
                 ],
                 [
                    98.2300594,
                    50.24664329999997
                 ],
                 [
                    98.230007,
                    50.24641880000004
                 ],
                 [
                    98.22983249999997,
                    50.24608929999996
                 ],
                 [
                    98.22982460000003,
                    50.245740400000045
                 ],
                 [
                    98.22974459999999,
                    50.2456399
                 ],
                 [
                    98.2294389,
                    50.24554910000003
                 ],
                 [
                    98.22936720000003,
                    50.245448499999966
                 ],
                 [
                    98.22933969999997,
                    50.2452181
                 ],
                 [
                    98.22945960000003,
                    50.2449152
                 ],
                 [
                    98.22951569999996,
                    50.24468319999997
                 ],
                 [
                    98.229477,
                    50.24457120000003
                 ],
                 [
                    98.22907310000002,
                    50.244348100000046
                 ],
                 [
                    98.22896030000001,
                    50.24426430000004
                 ],
                 [
                    98.22888,
                    50.2441585
                 ],
                 [
                    98.22887479999996,
                    50.24404579999999
                 ],
                 [
                    98.22922980000003,
                    50.24375980000004
                 ],
                 [
                    98.22928309999999,
                    50.243646
                 ],
                 [
                    98.2291866,
                    50.24355120000001
                 ],
                 [
                    98.22890479999995,
                    50.24343310000001
                 ],
                 [
                    98.22881660000003,
                    50.243338199999975
                 ],
                 [
                    98.22882259999999,
                    50.243107199999955
                 ],
                 [
                    98.2289613,
                    50.242669599999985
                 ],
                 [
                    98.22912719999997,
                    50.242457
                 ],
                 [
                    98.229344,
                    50.2422596
                 ],
                 [
                    98.22916120000004,
                    50.2419302
                 ],
                 [
                    98.2291727,
                    50.24181719999998
                 ],
                 [
                    98.229226,
                    50.24170339999998
                 ],
                 [
                    98.229564,
                    50.24141239999997
                 ],
                 [
                    98.2297555,
                    50.24121
                 ],
                 [
                    98.22986210000002,
                    50.240982500000015
                 ],
                 [
                    98.22976819999998,
                    50.24076409999998
                 ],
                 [
                    98.22941320000001,
                    50.2405132
                 ],
                 [
                    98.22931670000001,
                    50.240418399999974
                 ],
                 [
                    98.22926129999996,
                    50.24030669999997
                 ],
                 [
                    98.22912289999996,
                    50.239853
                 ],
                 [
                    98.22910159999998,
                    50.239397
                 ],
                 [
                    98.229158,
                    50.2391704
                 ],
                 [
                    98.22926310000003,
                    50.23891070000001
                 ],
                 [
                    98.22927769999998,
                    50.23868489999998
                 ],
                 [
                    98.22922760000002,
                    50.2385086
                 ],
                 [
                    98.22919589999997,
                    50.2384555
                 ],
                 [
                    98.22889739999997,
                    50.2382492
                 ],
                 [
                    98.22868049999995,
                    50.23799559999997
                 ],
                 [
                    98.22862670000004,
                    50.2376503
                 ],
                 [
                    98.228599,
                    50.2375945
                 ],
                 [
                    98.228483,
                    50.237441
                 ],
                 [
                    98.22840110000003,
                    50.23721170000004
                 ],
                 [
                    98.22833060000005,
                    50.23695529999996
                 ],
                 [
                    98.22829869999998,
                    50.236899499999964
                 ],
                 [
                    98.22828620000001,
                    50.23672259999998
                 ],
                 [
                    98.22837039999997,
                    50.23655189999998
                 ],
                 [
                    98.2283944,
                    50.23643860000002
                 ],
                 [
                    98.228352,
                    50.23615760000004
                 ],
                 [
                    98.228312,
                    50.236107299999965
                 ],
                 [
                    98.2279928,
                    50.23608389999998
                 ],
                 [
                    98.227751,
                    50.23610470000001
                 ],
                 [
                    98.227593,
                    50.2361292
                 ],
                 [
                    98.22734790000001,
                    50.236077499999986
                 ],
                 [
                    98.227191,
                    50.2360348
                 ],
                 [
                    98.22712260000002,
                    50.23600390000002
                 ],
                 [
                    98.22691689999996,
                    50.2359005
                 ],
                 [
                    98.22665379999995,
                    50.2356451
                 ],
                 [
                    98.22648119999998,
                    50.2354444
                 ],
                 [
                    98.22637340000003,
                    50.2352854
                 ],
                 [
                    98.226317,
                    50.23524349999999
                 ],
                 [
                    98.22603889999998,
                    50.23511459999998
                 ],
                 [
                    98.225785,
                    50.235055
                 ],
                 [
                    98.22557130000001,
                    50.23495979999996
                 ],
                 [
                    98.22543879999996,
                    50.234812
                 ],
                 [
                    98.22541109999999,
                    50.2347562
                 ],
                 [
                    98.2253146,
                    50.234661399999965
                 ],
                 [
                    98.22476459999996,
                    50.234443699999986
                 ],
                 [
                    98.22464339999998,
                    50.234357400000036
                 ],
                 [
                    98.22444980000003,
                    50.23424570000003
                 ],
                 [
                    98.2240447,
                    50.23426419999996
                 ],
                 [
                    98.22339809999997,
                    50.23431149999997
                 ],
                 [
                    98.22331840000003,
                    50.2343076
                 ],
                 [
                    98.2232461,
                    50.23428220000003
                 ],
                 [
                    98.22304030000002,
                    50.2341787
                 ],
                 [
                    98.2229714,
                    50.23413710000002
                 ],
                 [
                    98.222872,
                    50.2339779
                 ],
                 [
                    98.22265080000004,
                    50.23372170000002
                 ],
                 [
                    98.22248489999998,
                    50.23357450000001
                 ],
                 [
                    98.2224245,
                    50.23353539999997
                 ],
                 [
                    98.22220649999996,
                    50.23343759999996
                 ],
                 [
                    98.222154,
                    50.233390300000046
                 ],
                 [
                    98.22203790000005,
                    50.2332341
                 ],
                 [
                    98.2219815,
                    50.23318950000004
                 ],
                 [
                    98.22190910000005,
                    50.23316409999998
                 ],
                 [
                    98.2213473,
                    50.233054
                 ],
                 [
                    98.220848,
                    50.2326662
                 ],
                 [
                    98.22081090000003,
                    50.2324978
                 ],
                 [
                    98.22083020000004,
                    50.232194100000015
                 ],
                 [
                    98.22081410000004,
                    50.23211649999999
                 ],
                 [
                    98.2206387,
                    50.23194540000004
                 ],
                 [
                    98.2205013,
                    50.23187010000003
                 ],
                 [
                    98.22042110000002,
                    50.23185559999998
                 ],
                 [
                    98.2203412,
                    50.231846300000015
                 ],
                 [
                    98.2200901,
                    50.23184850000002
                 ],
                 [
                    98.22001010000004,
                    50.2318366
                 ],
                 [
                    98.21985279999998,
                    50.2317859
                 ],
                 [
                    98.21964710000002,
                    50.23168240000004
                 ],
                 [
                    98.21948979999998,
                    50.231629099999985
                 ],
                 [
                    98.21933690000003,
                    50.2314897
                 ],
                 [
                    98.2192969,
                    50.2314394
                 ],
                 [
                    98.21917790000002,
                    50.23122160000002
                 ],
                 [
                    98.219037,
                    50.2310712
                 ],
                 [
                    98.2186644,
                    50.23088770000001
                 ],
                 [
                    98.2184146,
                    50.2307368
                 ],
                 [
                    98.21834219999997,
                    50.23070870000004
                 ],
                 [
                    98.21818090000004,
                    50.23066069999997
                 ],
                 [
                    98.21795919999998,
                    50.230573699999965
                 ],
                 [
                    98.2178713,
                    50.230572699999975
                 ],
                 [
                    98.217797,
                    50.2305956
                 ],
                 [
                    98.21767459999998,
                    50.23066230000001
                 ],
                 [
                    98.217622,
                    50.2307009
                 ],
                 [
                    98.21759059999995,
                    50.230747200000025
                 ],
                 [
                    98.21751610000001,
                    50.23076469999996
                 ],
                 [
                    98.21743250000002,
                    50.2307663
                 ],
                 [
                    98.2172957,
                    50.230704500000044
                 ],
                 [
                    98.21725550000002,
                    50.2306515
                 ],
                 [
                    98.21728240000003,
                    50.2304175
                 ],
                 [
                    98.21748740000002,
                    50.23005649999998
                 ],
                 [
                    98.21745970000005,
                    50.230000599999975
                 ],
                 [
                    98.21732689999999,
                    50.229933400000014
                 ],
                 [
                    98.21716989999996,
                    50.2298881
                 ],
                 [
                    98.21700950000002,
                    50.229858899999975
                 ],
                 [
                    98.21696550000001,
                    50.229811400000045
                 ],
                 [
                    98.21692649999999,
                    50.229694
                 ],
                 [
                    98.21689069999995,
                    50.229643700000025
                 ],
                 [
                    98.21683839999997,
                    50.22959909999997
                 ],
                 [
                    98.21670549999999,
                    50.22952910000004
                 ],
                 [
                    98.216464,
                    50.22946660000002
                 ],
                 [
                    98.2163995,
                    50.22943030000004
                 ],
                 [
                    98.21636909999997,
                    50.22931540000004
                 ],
                 [
                    98.21627660000003,
                    50.2292151
                 ],
                 [
                    98.21621210000002,
                    50.229178800000014
                 ],
                 [
                    98.21613949999997,
                    50.229148
                 ],
                 [
                    98.2160593,
                    50.22913339999999
                 ],
                 [
                    98.21580850000002,
                    50.22913820000003
                 ],
                 [
                    98.21572849999998,
                    50.22912889999997
                 ],
                 [
                    98.2156762,
                    50.22908430000001
                 ],
                 [
                    98.21561779999998,
                    50.2289082
                 ],
                 [
                    98.21556959999998,
                    50.2288608
                 ],
                 [
                    98.21550920000004,
                    50.228821699999955
                 ],
                 [
                    98.21527989999998,
                    50.22875089999996
                 ],
                 [
                    98.21511930000003,
                    50.22871640000004
                 ],
                 [
                    98.21505080000004,
                    50.2286828
                 ],
                 [
                    98.21488919999999,
                    50.22853820000004
                 ],
                 [
                    98.214755,
                    50.22844140000002
                 ],
                 [
                    98.21467830000005,
                    50.22841069999997
                 ],
                 [
                    98.21459880000003,
                    50.2284122
                 ],
                 [
                    98.2141195,
                    50.228453599999966
                 ],
                 [
                    98.214055,
                    50.228417199999974
                 ],
                 [
                    98.21399850000002,
                    50.228372600000014
                 ],
                 [
                    98.2138275,
                    50.22811280000001
                 ],
                 [
                    98.2138331,
                    50.2280536
                 ],
                 [
                    98.21389919999997,
                    50.227945
                 ],
                 [
                    98.213898,
                    50.22782960000003
                 ],
                 [
                    98.213862,
                    50.22777390000004
                 ],
                 [
                    98.21379350000002,
                    50.2277403
                 ],
                 [
                    98.21364049999997,
                    50.22768949999998
                 ],
                 [
                    98.21347170000004,
                    50.22766049999998
                 ],
                 [
                    98.21331080000004,
                    50.227620599999966
                 ],
                 [
                    98.21306859999999,
                    50.22745070000003
                 ],
                 [
                    98.2129012,
                    50.22726869999998
                 ],
                 [
                    98.21286940000003,
                    50.22721560000003
                 ],
                 [
                    98.21286679999999,
                    50.2271593
                 ],
                 [
                    98.212831,
                    50.2271089
                 ],
                 [
                    98.21276239999997,
                    50.227072700000015
                 ],
                 [
                    98.2126823,
                    50.22706080000001
                 ],
                 [
                    98.21243509999998,
                    50.22705470000004
                 ],
                 [
                    98.21226659999998,
                    50.22703110000004
                 ],
                 [
                    98.21218990000003,
                    50.22700029999998
                 ],
                 [
                    98.2121252,
                    50.22695859999999
                 ],
                 [
                    98.21201209999997,
                    50.22686680000003
                 ],
                 [
                    98.21196790000005,
                    50.226816599999964
                 ],
                 [
                    98.21189550000001,
                    50.22678850000004
                 ],
                 [
                    98.2117312,
                    50.22676480000003
                 ],
                 [
                    98.21156329999997,
                    50.22675449999998
                 ],
                 [
                    98.21135939999996,
                    50.22668860000003
                 ],
                 [
                    98.21122659999999,
                    50.22662129999997
                 ],
                 [
                    98.2111214,
                    50.2265186
                 ],
                 [
                    98.21108540000003,
                    50.22646559999997
                 ],
                 [
                    98.21110780000002,
                    50.22622629999997
                 ],
                 [
                    98.21109690000002,
                    50.22617010000003
                 ],
                 [
                    98.2110528,
                    50.22612260000001
                 ],
                 [
                    98.21098430000002,
                    50.226089
                 ],
                 [
                    98.2109004,
                    50.22608520000002
                 ],
                 [
                    98.2108305,
                    50.2261107
                 ],
                 [
                    98.2107512,
                    50.2261149
                 ],
                 [
                    98.2106748,
                    50.22609219999998
                 ],
                 [
                    98.21061850000004,
                    50.226050299999976
                 ],
                 [
                    98.21057970000003,
                    50.2259356
                 ],
                 [
                    98.210471,
                    50.225849100000026
                 ],
                 [
                    98.21023009999996,
                    50.2257973
                 ],
                 [
                    98.2100705,
                    50.2257843
                 ],
                 [
                    98.20985060000001,
                    50.225734699999975
                 ],
                 [
                    98.2096787,
                    50.22572729999999
                 ],
                 [
                    98.20952120000003,
                    50.225671199999965
                 ],
                 [
                    98.209206,
                    50.22545969999996
                 ],
                 [
                    98.20915349999997,
                    50.2254097
                 ],
                 [
                    98.2090981,
                    50.225298
                 ],
                 [
                    98.2089853,
                    50.22521160000004
                 ],
                 [
                    98.20882550000002,
                    50.22519579999997
                 ],
                 [
                    98.208742,
                    50.225200099999974
                 ],
                 [
                    98.2082596,
                    50.22526569999999
                 ],
                 [
                    98.20817180000002,
                    50.22526730000003
                 ],
                 [
                    98.20809160000005,
                    50.22525269999998
                 ],
                 [
                    98.2080192,
                    50.22522459999996
                 ],
                 [
                    98.2079506,
                    50.22518829999998
                 ],
                 [
                    98.20787590000005,
                    50.22511189999996
                 ],
                 [
                    98.20772320000002,
                    50.22506640000002
                 ],
                 [
                    98.2076832,
                    50.225016200000034
                 ],
                 [
                    98.20771409999998,
                    50.22496189999998
                 ],
                 [
                    98.20776209999995,
                    50.2249127
                 ],
                 [
                    98.2077595,
                    50.22485630000004
                 ],
                 [
                    98.20772660000004,
                    50.22468780000004
                 ],
                 [
                    98.20762020000002,
                    50.224467
                 ],
                 [
                    98.2074384,
                    50.2243363
                 ],
                 [
                    98.20728879999996,
                    50.22426929999997
                 ],
                 [
                    98.20712970000004,
                    50.224267
                 ],
                 [
                    98.20698529999999,
                    50.2243127
                 ],
                 [
                    98.2064808,
                    50.22435180000004
                 ],
                 [
                    98.2063176,
                    50.224352199999984
                 ],
                 [
                    98.20624530000005,
                    50.2243267
                 ],
                 [
                    98.20618480000002,
                    50.224284900000015
                 ],
                 [
                    98.20614469999998,
                    50.224232
                 ],
                 [
                    98.20612109999998,
                    50.2239935
                 ],
                 [
                    98.20608530000004,
                    50.22394319999999
                 ],
                 [
                    98.20581919999997,
                    50.223797900000044
                 ],
                 [
                    98.20567420000005,
                    50.22373890000004
                 ],
                 [
                    98.20542769999999,
                    50.22365759999998
                 ],
                 [
                    98.20536710000003,
                    50.22361320000004
                 ],
                 [
                    98.2052948,
                    50.22358769999997
                 ],
                 [
                    98.2052153,
                    50.2235892
                 ],
                 [
                    98.20513649999997,
                    50.2236041
                 ],
                 [
                    98.2048525,
                    50.2237061
                 ],
                 [
                    98.20477369999996,
                    50.2237211
                 ],
                 [
                    98.2044393,
                    50.223730100000026
                 ],
                 [
                    98.204286,
                    50.22376250000004
                 ],
                 [
                    98.20420679999995,
                    50.2237694
                 ],
                 [
                    98.20397710000005,
                    50.22359930000004
                 ],
                 [
                    98.20393310000004,
                    50.22355180000002
                 ],
                 [
                    98.20395689999997,
                    50.223433199999974
                 ],
                 [
                    98.20392909999998,
                    50.22337470000002
                 ],
                 [
                    98.20386850000003,
                    50.22333019999998
                 ],
                 [
                    98.203708,
                    50.2232983
                 ],
                 [
                    98.203469,
                    50.223286700000024
                 ],
                 [
                    98.203231,
                    50.2232993
                 ],
                 [
                    98.20317869999998,
                    50.22325470000004
                 ],
                 [
                    98.20311930000004,
                    50.22314569999999
                 ],
                 [
                    98.20310410000002,
                    50.223087
                 ],
                 [
                    98.20303240000004,
                    50.2229836
                 ],
                 [
                    98.2029116,
                    50.2229054
                 ],
                 [
                    98.2025807,
                    50.22289820000003
                 ],
                 [
                    98.202492,
                    50.22288110000004
                 ],
                 [
                    98.20218630000002,
                    50.22278489999998
                 ],
                 [
                    98.2021217,
                    50.222745800000034
                 ],
                 [
                    98.20200910000001,
                    50.222662100000036
                 ],
                 [
                    98.20164750000002,
                    50.22226079999996
                 ],
                 [
                    98.20160479999996,
                    50.22215159999998
                 ],
                 [
                    98.2016011,
                    50.22197979999998
                 ],
                 [
                    98.2015333,
                    50.221871
                 ],
                 [
                    98.2014808,
                    50.221821
                 ],
                 [
                    98.20141619999998,
                    50.221782
                 ],
                 [
                    98.20126330000004,
                    50.221733899999975
                 ],
                 [
                    98.20110350000004,
                    50.22171540000004
                 ],
                 [
                    98.20102720000004,
                    50.22169539999999
                 ],
                 [
                    98.20088990000005,
                    50.221620099999974
                 ],
                 [
                    98.2007327,
                    50.221478100000034
                 ],
                 [
                    98.20066809999999,
                    50.2214391
                 ],
                 [
                    98.20059159999997,
                    50.221413700000035
                 ],
                 [
                    98.20043379999997,
                    50.22143820000002
                 ],
                 [
                    98.20022439999997,
                    50.22152540000004
                 ],
                 [
                    98.20006010000004,
                    50.221501600000025
                 ],
                 [
                    98.19998780000002,
                    50.22147619999997
                 ],
                 [
                    98.1996537,
                    50.221308
                 ],
                 [
                    98.19955850000002,
                    50.22114869999996
                 ],
                 [
                    98.199539,
                    50.22109
                 ],
                 [
                    98.19956370000001,
                    50.22099019999999
                 ],
                 [
                    98.1995669,
                    50.220877399999985
                 ],
                 [
                    98.19963289999995,
                    50.22076610000001
                 ],
                 [
                    98.1996344,
                    50.220707
                 ],
                 [
                    98.19956989999999,
                    50.22067060000001
                 ],
                 [
                    98.19941079999997,
                    50.2206683
                 ],
                 [
                    98.19932660000002,
                    50.2206564
                 ],
                 [
                    98.19927430000004,
                    50.220611800000036
                 ],
                 [
                    98.1992801,
                    50.2205553
                 ],
                 [
                    98.1993982,
                    50.220394700000014
                 ],
                 [
                    98.19940139999999,
                    50.2202818
                 ],
                 [
                    98.1993779,
                    50.22022589999999
                 ],
                 [
                    98.19924109999997,
                    50.22016139999998
                 ],
                 [
                    98.19915219999997,
                    50.22013889999999
                 ],
                 [
                    98.1990053,
                    50.22003970000004
                 ],
                 [
                    98.19885259999997,
                    50.219903
                 ],
                 [
                    98.19879220000003,
                    50.21986379999996
                 ],
                 [
                    98.19871590000002,
                    50.21984110000004
                 ],
                 [
                    98.1986368,
                    50.21985070000003
                 ],
                 [
                    98.19844010000003,
                    50.21994029999998
                 ],
                 [
                    98.1982915,
                    50.21998610000004
                 ],
                 [
                    98.1979727,
                    50.220059199999966
                 ],
                 [
                    98.1978933,
                    50.2200607
                 ],
                 [
                    98.19782089999995,
                    50.22003259999998
                 ],
                 [
                    98.19775620000003,
                    50.2199908
                 ],
                 [
                    98.19773849999999,
                    50.21987840000001
                 ],
                 [
                    98.19768320000003,
                    50.21976939999997
                 ],
                 [
                    98.197438,
                    50.219532399999984
                 ],
                 [
                    98.19732529999999,
                    50.219445900000025
                 ],
                 [
                    98.19726060000005,
                    50.219404200000035
                 ],
                 [
                    98.19718410000003,
                    50.2193788
                 ],
                 [
                    98.19692870000003,
                    50.21937559999998
                 ],
                 [
                    98.1967082,
                    50.2193126
                 ],
                 [
                    98.1965834,
                    50.2192371
                 ],
                 [
                    98.196487,
                    50.21914230000004
                 ],
                 [
                    98.196399,
                    50.2190473
                 ],
                 [
                    98.196301,
                    50.21882640000001
                 ],
                 [
                    98.196225,
                    50.21872040000004
                 ],
                 [
                    98.19613689999998,
                    50.2186254
                 ],
                 [
                    98.19598009999997,
                    50.21849150000001
                 ],
                 [
                    98.19584890000004,
                    50.21827649999999
                 ],
                 [
                    98.19580480000003,
                    50.21822630000001
                 ],
                 [
                    98.1955897,
                    50.21809880000003
                 ],
                 [
                    98.19556620000002,
                    50.21804290000002
                 ],
                 [
                    98.19560850000005,
                    50.217870300000044
                 ],
                 [
                    98.1955898,
                    50.2176451
                 ],
                 [
                    98.19554009999999,
                    50.21747420000004
                 ],
                 [
                    98.19549589999997,
                    50.21742140000003
                 ],
                 [
                    98.19544349999998,
                    50.21737410000002
                 ],
                 [
                    98.19532270000002,
                    50.217295800000024
                 ],
                 [
                    98.19509969999996,
                    50.2170852
                 ],
                 [
                    98.19468590000001,
                    50.216821899999985
                 ],
                 [
                    98.19445970000002,
                    50.21663289999998
                 ],
                 [
                    98.19395180000002,
                    50.216323
                 ],
                 [
                    98.19376,
                    50.216154800000034
                 ],
                 [
                    98.19366870000005,
                    50.2159901
                 ],
                 [
                    98.1936165,
                    50.215945400000024
                 ],
                 [
                    98.19340289999998,
                    50.215850099999955
                 ],
                 [
                    98.1933589,
                    50.215802600000025
                 ],
                 [
                    98.19335630000003,
                    50.215746299999985
                 ],
                 [
                    98.1933902,
                    50.21557390000002
                 ],
                 [
                    98.19338349999998,
                    50.21551759999998
                 ],
                 [
                    98.19333939999997,
                    50.215470099999955
                 ],
                 [
                    98.193271,
                    50.21543650000002
                 ],
                 [
                    98.19311409999999,
                    50.2153911
                 ],
                 [
                    98.19305360000004,
                    50.2153493
                 ],
                 [
                    98.19300940000002,
                    50.215299200000025
                 ],
                 [
                    98.19291830000003,
                    50.21513710000003
                 ],
                 [
                    98.192803,
                    50.21499430000002
                 ],
                 [
                    98.19281710000001,
                    50.21493769999996
                 ],
                 [
                    98.19297769999997,
                    50.21488089999997
                 ],
                 [
                    98.19306479999999,
                    50.21486320000001
                 ],
                 [
                    98.19310840000004,
                    50.21481139999999
                 ],
                 [
                    98.19308909999995,
                    50.214755299999965
                 ],
                 [
                    98.19311580000003,
                    50.214698499999976
                 ],
                 [
                    98.1931765,
                    50.214654399999965
                 ],
                 [
                    98.1931948,
                    50.214597599999976
                 ],
                 [
                    98.1931508,
                    50.21455010000004
                 ],
                 [
                    98.1929909,
                    50.214529
                 ],
                 [
                    98.1927482,
                    50.2145282
                 ],
                 [
                    98.192668,
                    50.21451360000004
                 ],
                 [
                    98.1925917,
                    50.21449090000003
                 ],
                 [
                    98.19245039999996,
                    50.214421100000024
                 ],
                 [
                    98.19207970000002,
                    50.21427510000002
                 ],
                 [
                    98.19202329999997,
                    50.21423049999998
                 ],
                 [
                    98.1919517,
                    50.2141298
                 ],
                 [
                    98.19177160000001,
                    50.213942600000046
                 ],
                 [
                    98.1916916,
                    50.21383940000003
                 ],
                 [
                    98.19171260000002,
                    50.21365910000004
                 ],
                 [
                    98.19164090000004,
                    50.213555800000016
                 ],
                 [
                    98.1915845,
                    50.21351119999997
                 ],
                 [
                    98.19133250000002,
                    50.2134891
                 ],
                 [
                    98.19126390000002,
                    50.21345280000002
                 ],
                 [
                    98.19109059999998,
                    50.213324599999986
                 ],
                 [
                    98.19078940000001,
                    50.2132336
                 ],
                 [
                    98.19072080000002,
                    50.21319730000002
                 ],
                 [
                    98.19066449999998,
                    50.21315540000002
                 ],
                 [
                    98.19069859999996,
                    50.2129856
                 ],
                 [
                    98.19054280000005,
                    50.212781800000016
                 ],
                 [
                    98.1904178,
                    50.212701
                 ],
                 [
                    98.19027259999997,
                    50.21263659999999
                 ],
                 [
                    98.19002720000002,
                    50.212576800000015
                 ],
                 [
                    98.18993930000002,
                    50.212575800000025
                 ],
                 [
                    98.18985890000003,
                    50.21255579999997
                 ],
                 [
                    98.1897382,
                    50.2124775
                 ],
                 [
                    98.189687,
                    50.212365799999965
                 ],
                 [
                    98.18963890000003,
                    50.212318300000035
                 ],
                 [
                    98.18945740000002,
                    50.21219289999996
                 ],
                 [
                    98.18929730000002,
                    50.21216910000003
                 ],
                 [
                    98.18902910000003,
                    50.2120667
                 ],
                 [
                    98.1888041,
                    50.21190449999999
                 ],
                 [
                    98.1885542,
                    50.21174549999998
                 ],
                 [
                    98.18851150000002,
                    50.2116362
                 ],
                 [
                    98.18834210000003,
                    50.2114998
                 ],
                 [
                    98.18826580000002,
                    50.211477099999975
                 ],
                 [
                    98.1881368,
                    50.21140160000004
                 ],
                 [
                    98.18805679999996,
                    50.211389700000026
                 ],
                 [
                    98.18798829999997,
                    50.2113561
                 ],
                 [
                    98.18793190000002,
                    50.21131150000004
                 ],
                 [
                    98.18774720000002,
                    50.21111370000002
                 ],
                 [
                    98.18761,
                    50.2110411
                 ],
                 [
                    98.187451,
                    50.210858799999976
                 ],
                 [
                    98.18739469999998,
                    50.210816899999976
                 ],
                 [
                    98.18725,
                    50.21076329999998
                 ],
                 [
                    98.1871813,
                    50.21072430000004
                 ],
                 [
                    98.1870685,
                    50.21063520000004
                 ],
                 [
                    98.1869999,
                    50.210598899999965
                 ],
                 [
                    98.18684729999998,
                    50.21055609999998
                 ],
                 [
                    98.18670239999999,
                    50.210497099999984
                 ],
                 [
                    98.186619,
                    50.210504
                 ],
                 [
                    98.18653870000003,
                    50.21048670000001
                 ],
                 [
                    98.18638879999997,
                    50.210409
                 ],
                 [
                    98.1863406,
                    50.21036159999996
                 ],
                 [
                    98.18626810000002,
                    50.21033069999998
                 ],
                 [
                    98.18610820000002,
                    50.2103123
                 ],
                 [
                    98.18571889999997,
                    50.2102149
                 ],
                 [
                    98.185498,
                    50.210141200000024
                 ],
                 [
                    98.18533760000004,
                    50.21010929999996
                 ],
                 [
                    98.18519270000004,
                    50.21005029999996
                 ],
                 [
                    98.18511229999996,
                    50.2100303
                 ],
                 [
                    98.18494929999997,
                    50.210033399999986
                 ],
                 [
                    98.18487250000001,
                    50.21000260000001
                 ],
                 [
                    98.18475169999996,
                    50.20992169999998
                 ],
                 [
                    98.18457369999999,
                    50.20987129999998
                 ],
                 [
                    98.1843882,
                    50.20983989999996
                 ],
                 [
                    98.18404920000002,
                    50.20983559999996
                 ],
                 [
                    98.18381549999995,
                    50.2096654
                 ],
                 [
                    98.18368259999997,
                    50.2095955
                 ],
                 [
                    98.18360629999997,
                    50.20957269999997
                 ],
                 [
                    98.18352689999996,
                    50.2095742
                 ],
                 [
                    98.1834543,
                    50.20954340000003
                 ],
                 [
                    98.18328480000001,
                    50.20940429999998
                 ],
                 [
                    98.183144,
                    50.20934250000002
                 ],
                 [
                    98.18305950000001,
                    50.20932530000002
                 ],
                 [
                    98.18299510000001,
                    50.209288900000026
                 ],
                 [
                    98.18297580000002,
                    50.20923290000001
                 ],
                 [
                    98.18292340000004,
                    50.2091856
                 ],
                 [
                    98.18284290000004,
                    50.20916289999999
                 ],
                 [
                    98.1827865,
                    50.20911830000003
                 ],
                 [
                    98.18271019999999,
                    50.20909560000002
                 ],
                 [
                    98.1825597,
                    50.20909839999998
                 ],
                 [
                    98.18231580000004,
                    50.2090708
                 ],
                 [
                    98.18210679999997,
                    50.20898339999997
                 ],
                 [
                    98.18201889999999,
                    50.208982399999975
                 ],
                 [
                    98.18187379999996,
                    50.208918
                 ],
                 [
                    98.181757,
                    50.20883429999998
                 ],
                 [
                    98.1815437,
                    50.2087443
                 ],
                 [
                    98.1812947,
                    50.208695300000024
                 ],
                 [
                    98.18114619999999,
                    50.20864979999999
                 ],
                 [
                    98.18106649999996,
                    50.20864590000002
                 ],
                 [
                    98.18098629999999,
                    50.20862860000001
                 ],
                 [
                    98.18074840000003,
                    50.20854989999999
                 ],
                 [
                    98.18052579999998,
                    50.208438600000015
                 ],
                 [
                    98.18044939999997,
                    50.208413200000045
                 ],
                 [
                    98.1803971,
                    50.208368499999985
                 ],
                 [
                    98.18013139999995,
                    50.208228599999956
                 ],
                 [
                    98.17988469999997,
                    50.20823050000003
                 ],
                 [
                    98.17981460000003,
                    50.208253300000045
                 ],
                 [
                    98.17968160000004,
                    50.208180599999984
                 ],
                 [
                    98.1794407,
                    50.2081261
                 ],
                 [
                    98.17936130000001,
                    50.20812760000004
                 ],
                 [
                    98.17922840000003,
                    50.20805760000002
                 ],
                 [
                    98.17906430000004,
                    50.20803649999996
                 ],
                 [
                    98.1789008,
                    50.20802880000004
                 ],
                 [
                    98.17880789999995,
                    50.2080091
                 ],
                 [
                    98.17851580000004,
                    50.2078428
                 ],
                 [
                    98.17835910000004,
                    50.20780010000003
                 ],
                 [
                    98.1782222,
                    50.20773279999997
                 ],
                 [
                    98.178146,
                    50.2077128
                 ],
                 [
                    98.17798169999999,
                    50.207689
                 ],
                 [
                    98.1779024,
                    50.2076932
                 ],
                 [
                    98.17782359999997,
                    50.20770809999997
                 ],
                 [
                    98.17769709999997,
                    50.207777600000036
                 ],
                 [
                    98.17745830000003,
                    50.2077686
                 ],
                 [
                    98.1773057,
                    50.20772580000001
                 ],
                 [
                    98.1770963,
                    50.207627699999975
                 ],
                 [
                    98.176605,
                    50.207494699999984
                 ],
                 [
                    98.176277,
                    50.20745789999996
                 ],
                 [
                    98.1761246,
                    50.207417800000016
                 ],
                 [
                    98.17597929999995,
                    50.20735069999997
                 ],
                 [
                    98.17586679999997,
                    50.207266899999965
                 ],
                 [
                    98.1757222,
                    50.2072159
                 ],
                 [
                    98.17556270000003,
                    50.2072028
                 ],
                 [
                    98.175426,
                    50.20714090000002
                 ],
                 [
                    98.17521710000004,
                    50.20696229999999
                 ],
                 [
                    98.17487120000001,
                    50.20671639999998
                 ],
                 [
                    98.1746702,
                    50.20652690000002
                 ],
                 [
                    98.17442069999996,
                    50.206375800000046
                 ],
                 [
                    98.174259,
                    50.206314400000025
                 ],
                 [
                    98.17398939999998,
                    50.2060886
                 ],
                 [
                    98.17386870000003,
                    50.2060103
                 ],
                 [
                    98.17378070000002,
                    50.20591530000004
                 ],
                 [
                    98.1736517,
                    50.205837199999976
                 ],
                 [
                    98.1732018,
                    50.205695200000044
                 ],
                 [
                    98.1728068,
                    50.20547180000003
                 ],
                 [
                    98.17251749999996,
                    50.20536440000003
                 ],
                 [
                    98.1722678,
                    50.205208
                 ],
                 [
                    98.171994,
                    50.205073500000026
                 ],
                 [
                    98.1717686,
                    50.204900500000015
                 ],
                 [
                    98.17169690000001,
                    50.204794500000034
                 ],
                 [
                    98.17160280000004,
                    50.2045654
                 ],
                 [
                    98.1714596,
                    50.204358700000036
                 ],
                 [
                    98.17087570000001,
                    50.20384340000003
                 ],
                 [
                    98.1706959,
                    50.203567500000034
                 ],
                 [
                    98.1703324,
                    50.20330049999997
                 ],
                 [
                    98.16978540000004,
                    50.20267709999999
                 ],
                 [
                    98.16964250000002,
                    50.20247580000003
                 ],
                 [
                    98.1695821,
                    50.20225140000003
                 ],
                 [
                    98.16941070000003,
                    50.2017928
                 ],
                 [
                    98.16941629999997,
                    50.2015457
                 ],
                 [
                    98.16957969999999,
                    50.2010917
                 ],
                 [
                    98.16960290000003,
                    50.20086570000004
                 ],
                 [
                    98.17002719999996,
                    50.199891299999976
                 ],
                 [
                    98.17005040000002,
                    50.19966540000002
                 ],
                 [
                    98.17003,
                    50.199214700000034
                 ],
                 [
                    98.17003550000004,
                    50.19896760000002
                 ],
                 [
                    98.17010840000003,
                    50.19873
                 ],
                 [
                    98.17019840000002,
                    50.19850280000002
                 ],
                 [
                    98.17023840000003,
                    50.19827660000004
                 ],
                 [
                    98.1701446,
                    50.1980528
                 ],
                 [
                    98.16996870000001,
                    50.1978628
                 ],
                 [
                    98.1699245,
                    50.19762739999997
                 ],
                 [
                    98.1698869,
                    50.19716630000001
                 ],
                 [
                    98.16972359999998,
                    50.19683069999996
                 ],
                 [
                    98.1692,
                    50.1895
                 ],
                 [
                    98.1849,
                    50.1725
                 ],
                 [
                    98.1835,
                    50.168
                 ],
                 [
                    98.1823,
                    50.1581
                 ],
                 [
                    98.1768,
                    50.1519
                 ],
                 [
                    98.1727,
                    50.1481
                 ],
                 [
                    98.1629,
                    50.1454
                 ],
                 [
                    98.1602,
                    50.1408
                 ],
                 [
                    98.1547,
                    50.1363
                 ],
                 [
                    98.1491,
                    50.1343
                 ],
                 [
                    98.1394,
                    50.1325
                 ],
                 [
                    98.1296,
                    50.1325
                 ],
                 [
                    98.1211,
                    50.1361
                 ],
                 [
                    98.1127,
                    50.1387
                 ],
                 [
                    98.1113,
                    50.1369
                 ],
                 [
                    98.11,
                    50.1313
                 ],
                 [
                    98.1202,
                    50.1136
                 ],
                 [
                    98.1204,
                    50.1002
                 ],
                 [
                    98.1121,
                    50.0928
                 ],
                 [
                    98.108,
                    50.0892
                 ],
                 [
                    98.1039,
                    50.0865
                 ],
                 [
                    98.1027,
                    50.0775
                 ],
                 [
                    98.0971,
                    50.0755
                 ],
                 [
                    98.0873,
                    50.0747
                 ],
                 [
                    98.0762,
                    50.0737
                 ],
                 [
                    98.0637,
                    50.07
                 ],
                 [
                    98.0624,
                    50.0619
                 ],
                 [
                    98.0669,
                    50.0484
                 ],
                 [
                    98.0699,
                    50.0359
                 ],
                 [
                    98.0641,
                    50.034
                 ],
                 [
                    98.0558,
                    50.0347
                 ],
                 [
                    98.0476,
                    50.0348
                 ],
                 [
                    98.0376,
                    50.0347
                 ],
                 [
                    98.0306,
                    50.0346
                 ],
                 [
                    98.0098,
                    50.0273
                 ],
                 [
                    97.9987,
                    50.0254
                 ],
                 [
                    97.989,
                    50.0226
                 ],
                 [
                    97.9821,
                    50.0189
                 ],
                 [
                    97.9783,
                    50.0018
                 ],
                 [
                    97.97,
                    49.9964
                 ],
                 [
                    97.9425,
                    49.9808
                 ],
                 [
                    97.9342,
                    49.9781
                 ],
                 [
                    97.8978,
                    49.984
                 ],
                 [
                    97.8882,
                    49.9785
                 ],
                 [
                    97.8798,
                    49.9765
                 ],
                 [
                    97.8715,
                    49.9755
                 ],
                 [
                    97.8674,
                    49.972
                 ],
                 [
                    97.8678,
                    49.9539
                 ],
                 [
                    97.875,
                    49.946
                 ],
                 [
                    97.8949,
                    49.929
                 ],
                 [
                    97.8893,
                    49.9273
                 ],
                 [
                    97.8866,
                    49.9235
                 ],
                 [
                    97.8783,
                    49.9234
                 ],
                 [
                    97.8701,
                    49.918
                 ],
                 [
                    97.8617,
                    49.9168
                 ],
                 [
                    97.8522,
                    49.9188
                 ],
                 [
                    97.8296,
                    49.923
                 ],
                 [
                    97.8266,
                    49.9283
                 ],
                 [
                    97.8223,
                    49.9355
                 ],
                 [
                    97.8166,
                    49.939
                 ],
                 [
                    97.815,
                    49.9507
                 ],
                 [
                    97.8107,
                    49.9542
                 ],
                 [
                    97.8009,
                    49.9559
                 ],
                 [
                    97.7925,
                    49.9587
                 ],
                 [
                    97.7896,
                    49.963
                 ],
                 [
                    97.7866,
                    49.9693
                 ],
                 [
                    97.7809,
                    49.9755
                 ],
                 [
                    97.7807,
                    49.9819
                 ],
                 [
                    97.7819,
                    49.9909
                 ],
                 [
                    97.7804,
                    49.9936
                 ],
                 [
                    97.7748,
                    49.9955
                 ],
                 [
                    97.7691,
                    49.9989
                 ],
                 [
                    97.7621,
                    50.0005
                 ],
                 [
                    97.758,
                    49.997
                 ],
                 [
                    97.754,
                    49.9899
                 ],
                 [
                    97.7513,
                    49.9872
                 ],
                 [
                    97.7472,
                    49.9834
                 ],
                 [
                    97.739,
                    49.9788
                 ],
                 [
                    97.7321,
                    49.9762
                 ],
                 [
                    97.721,
                    49.9741
                 ],
                 [
                    97.7098,
                    49.9747
                 ],
                 [
                    97.7071,
                    49.9711
                 ],
                 [
                    97.7045,
                    49.9639
                 ],
                 [
                    97.7004,
                    49.9611
                 ],
                 [
                    97.6948,
                    49.9611
                 ],
                 [
                    97.6781,
                    49.9618
                 ],
                 [
                    97.6726,
                    49.9599
                 ],
                 [
                    97.6643,
                    49.9555
                 ],
                 [
                    97.6312,
                    49.946
                 ],
                 [
                    97.62446109999996,
                    49.943946
                 ],
                 [
                    97.6204931,
                    49.944220599999966
                 ],
                 [
                    97.6193483,
                    49.94397
                 ],
                 [
                    97.61881770000004,
                    49.94303239999997
                 ],
                 [
                    97.61759070000002,
                    49.9381957
                 ],
                 [
                    97.61567189999998,
                    49.93753519999997
                 ],
                 [
                    97.61239410000003,
                    49.938031800000026
                 ],
                 [
                    97.6058028,
                    49.939797900000045
                 ],
                 [
                    97.5993986,
                    49.9404822
                 ],
                 [
                    97.59000960000004,
                    49.94056659999997
                 ],
                 [
                    97.58201159999997,
                    49.940812299999955
                 ],
                 [
                    97.57645050000002,
                    49.940926099999956
                 ],
                 [
                    97.57352550000003,
                    49.94103559999996
                 ],
                 [
                    97.57156019999995,
                    49.940797399999965
                 ],
                 [
                    97.56942539999999,
                    49.93339609999998
                 ],
                 [
                    97.56738760000003,
                    49.929714199999985
                 ],
                 [
                    97.5612899,
                    49.925482
                 ],
                 [
                    97.55483770000002,
                    49.92262360000004
                 ],
                 [
                    97.56869309999996,
                    49.91827109999998
                 ],
                 [
                    97.5757171,
                    49.9153839
                 ],
                 [
                    97.57832920000003,
                    49.91388790000004
                 ],
                 [
                    97.57905690000003,
                    49.911730300000045
                 ],
                 [
                    97.57840249999997,
                    49.91002110000002
                 ],
                 [
                    97.56954850000004,
                    49.907735900000034
                 ],
                 [
                    97.567332,
                    49.905016800000034
                 ],
                 [
                    97.56754469999998,
                    49.90131990000004
                 ],
                 [
                    97.57010990000003,
                    49.89634550000004
                 ],
                 [
                    97.5731634,
                    49.892094600000036
                 ],
                 [
                    97.57484380000004,
                    49.89061129999996
                 ],
                 [
                    97.57771339999996,
                    49.88889709999997
                 ],
                 [
                    97.5816942,
                    49.88660920000003
                 ],
                 [
                    97.58463670000002,
                    49.88510860000004
                 ],
                 [
                    97.58649480000001,
                    49.8829784
                 ],
                 [
                    97.585432,
                    49.88097409999998
                 ],
                 [
                    97.582864,
                    49.877658800000034
                 ],
                 [
                    97.58264269999997,
                    49.87495570000002
                 ],
                 [
                    97.58341559999997,
                    49.870134299999975
                 ],
                 [
                    97.58310959999997,
                    49.86687390000004
                 ],
                 [
                    97.58111739999998,
                    49.86488230000001
                 ],
                 [
                    97.58134350000003,
                    49.85959580000003
                 ],
                 [
                    97.58057510000005,
                    49.85844649999997
                 ],
                 [
                    97.57064850000002,
                    49.855574700000034
                 ],
                 [
                    97.56450830000001,
                    49.854712700000036
                 ],
                 [
                    97.56098310000002,
                    49.85458839999996
                 ],
                 [
                    97.5600921,
                    49.85374130000004
                 ],
                 [
                    97.56134,
                    49.8512332
                 ],
                 [
                    97.56145480000005,
                    49.8486114
                 ],
                 [
                    97.56009780000004,
                    49.845665799999956
                 ],
                 [
                    97.55770630000005,
                    49.8435932
                 ],
                 [
                    97.55555690000003,
                    49.842848900000035
                 ],
                 [
                    97.55144059999996,
                    49.842904099999984
                 ],
                 [
                    97.54597239999997,
                    49.844308800000036
                 ],
                 [
                    97.542272,
                    49.844916499999954
                 ],
                 [
                    97.53931919999997,
                    49.84396779999997
                 ],
                 [
                    97.53708840000004,
                    49.8406469
                 ],
                 [
                    97.53441760000004,
                    49.838105100000014
                 ],
                 [
                    97.53038289999998,
                    49.83652610000002
                 ],
                 [
                    97.51987960000002,
                    49.83408710000001
                 ],
                 [
                    97.51830229999999,
                    49.83037080000001
                 ],
                 [
                    97.5122429,
                    49.82344840000003
                 ],
                 [
                    97.51179059999997,
                    49.81958840000004
                 ],
                 [
                    97.510376,
                    49.8146671
                 ],
                 [
                    97.49674570000002,
                    49.806424899999975
                 ],
                 [
                    97.48546209999998,
                    49.80315519999998
                 ],
                 [
                    97.48252470000003,
                    49.8025915
                 ],
                 [
                    97.47897089999998,
                    49.8024866
                 ],
                 [
                    97.4724469,
                    49.802913300000036
                 ],
                 [
                    97.466043,
                    49.80293
                 ],
                 [
                    97.46278940000005,
                    49.800673099999955
                 ],
                 [
                    97.46250649999999,
                    49.79678930000004
                 ],
                 [
                    97.46096750000002,
                    49.7952624
                 ],
                 [
                    97.4595095,
                    49.79532370000001
                 ],
                 [
                    97.45456110000002,
                    49.79626650000004
                 ],
                 [
                    97.4510185,
                    49.79540880000004
                 ],
                 [
                    97.4404063,
                    49.791095500000026
                 ],
                 [
                    97.43797989999996,
                    49.78869870000004
                 ],
                 [
                    97.431321,
                    49.785624
                 ],
                 [
                    97.42162350000004,
                    49.78069629999997
                 ],
                 [
                    97.41490470000004,
                    49.7800483
                 ],
                 [
                    97.409786,
                    49.78195769999998
                 ],
                 [
                    97.40714670000001,
                    49.784738899999965
                 ],
                 [
                    97.40510180000003,
                    49.78628860000003
                 ],
                 [
                    97.39923679999995,
                    49.78650990000004
                 ],
                 [
                    97.39561739999998,
                    49.7863817
                 ],
                 [
                    97.39376889999998,
                    49.78554490000004
                 ],
                 [
                    97.39307309999997,
                    49.784415
                 ],
                 [
                    97.390914,
                    49.780832699999976
                 ],
                 [
                    97.38925,
                    49.7794782
                 ],
                 [
                    97.3877912,
                    49.77833589999998
                 ],
                 [
                    97.386279,
                    49.77764520000003
                 ],
                 [
                    97.38424250000003,
                    49.777175599999985
                 ],
                 [
                    97.3818345,
                    49.777633899999984
                 ],
                 [
                    97.37391849999997,
                    49.781529499999984
                 ],
                 [
                    97.361001,
                    49.7821759
                 ],
                 [
                    97.3573297,
                    49.781359899999984
                 ],
                 [
                    97.34544780000002,
                    49.773895600000024
                 ],
                 [
                    97.336848,
                    49.76869030000002
                 ],
                 [
                    97.33539029999999,
                    49.7663232
                 ],
                 [
                    97.3360454,
                    49.76000129999998
                 ],
                 [
                    97.33515670000001,
                    49.757820899999956
                 ],
                 [
                    97.31785370000001,
                    49.7517907
                 ],
                 [
                    97.31463059999997,
                    49.75150509999999
                 ],
                 [
                    97.30886739999998,
                    49.752902
                 ],
                 [
                    97.292494,
                    49.7517327
                 ],
                 [
                    97.2800528,
                    49.7525796
                 ],
                 [
                    97.2660163,
                    49.74946930000004
                 ],
                 [
                    97.2629229,
                    49.74905209999998
                 ],
                 [
                    97.25946579999996,
                    49.7499488
                 ],
                 [
                    97.2555032,
                    49.750528700000025
                 ],
                 [
                    97.25289690000001,
                    49.74971940000003
                 ],
                 [
                    97.24378450000002,
                    49.7472403
                 ],
                 [
                    97.23111360000003,
                    49.74292990000004
                 ],
                 [
                    97.22934179999999,
                    49.743636
                 ],
                 [
                    97.2240529,
                    49.744186
                 ],
                 [
                    97.22120829999999,
                    49.744409299999965
                 ],
                 [
                    97.21994049999998,
                    49.74936229999997
                 ],
                 [
                    97.2180171,
                    49.7506497
                 ],
                 [
                    97.2151059,
                    49.7522052
                 ],
                 [
                    97.21267549999999,
                    49.7531112
                 ],
                 [
                    97.21059890000004,
                    49.75358390000004
                 ],
                 [
                    97.208479,
                    49.753649
                 ],
                 [
                    97.20570480000002,
                    49.75406450000001
                 ],
                 [
                    97.2038119,
                    49.7552654
                 ],
                 [
                    97.2033247,
                    49.75701010000002
                 ],
                 [
                    97.2028353,
                    49.758668900000046
                 ],
                 [
                    97.20169650000001,
                    49.759539799999985
                 ],
                 [
                    97.1994986,
                    49.76048610000003
                 ],
                 [
                    97.19433009999999,
                    49.76197839999996
                 ],
                 [
                    97.192334,
                    49.7630514
                 ],
                 [
                    97.190151,
                    49.764620100000045
                 ],
                 [
                    97.18735259999998,
                    49.768149600000044
                 ],
                 [
                    97.18065910000003,
                    49.7765295
                 ],
                 [
                    97.17905689999998,
                    49.77746939999996
                 ],
                 [
                    97.1762448,
                    49.77775570000002
                 ],
                 [
                    97.17379369999998,
                    49.77788790000004
                 ],
                 [
                    97.16969620000005,
                    49.778509200000016
                 ],
                 [
                    97.16573730000005,
                    49.77938669999997
                 ],
                 [
                    97.1622523,
                    49.78066739999998
                 ],
                 [
                    97.15875689999999,
                    49.78151849999996
                 ],
                 [
                    97.15307680000004,
                    49.782563099999976
                 ],
                 [
                    97.13809069999998,
                    49.787071
                 ],
                 [
                    97.13488129999999,
                    49.7888421
                 ],
                 [
                    97.13324710000005,
                    49.789889
                 ],
                 [
                    97.13052279999997,
                    49.791139800000046
                 ],
                 [
                    97.12772160000002,
                    49.79194020000001
                 ],
                 [
                    97.12507609999997,
                    49.792309600000024
                 ],
                 [
                    97.1239816,
                    49.79232020000001
                 ],
                 [
                    97.1218163,
                    49.79193309999996
                 ],
                 [
                    97.11975990000002,
                    49.791953
                 ],
                 [
                    97.11940639999999,
                    49.79245040000004
                 ],
                 [
                    97.11998860000003,
                    49.79323940000004
                 ],
                 [
                    97.1212743,
                    49.794322300000026
                 ],
                 [
                    97.12281640000005,
                    49.79501609999996
                 ],
                 [
                    97.12449539999997,
                    49.801614599999965
                 ],
                 [
                    97.1281832,
                    49.80467140000002
                 ],
                 [
                    97.13102639999998,
                    49.808488
                 ],
                 [
                    97.13106580000004,
                    49.811601800000034
                 ],
                 [
                    97.13073730000004,
                    49.8131728
                 ],
                 [
                    97.12879430000004,
                    49.81525350000002
                 ],
                 [
                    97.12528849999995,
                    49.81722049999997
                 ],
                 [
                    97.1196996,
                    49.81955109999998
                 ],
                 [
                    97.11709020000002,
                    49.82011320000004
                 ],
                 [
                    97.10681950000003,
                    49.82096340000004
                 ],
                 [
                    97.09376440000004,
                    49.822010800000015
                 ],
                 [
                    97.08646719999996,
                    49.82227280000002
                 ],
                 [
                    97.0798713,
                    49.822721
                 ],
                 [
                    97.06858220000004,
                    49.82411419999997
                 ],
                 [
                    97.05865519999998,
                    49.827104600000034
                 ],
                 [
                    97.04884450000003,
                    49.832455600000024
                 ],
                 [
                    97.0441071,
                    49.83294930000002
                 ],
                 [
                    97.0418733,
                    49.83251839999998
                 ],
                 [
                    97.03836829999999,
                    49.83319420000003
                 ],
                 [
                    97.0313125,
                    49.83396590000002
                 ],
                 [
                    97.02815140000001,
                    49.833650300000016
                 ],
                 [
                    97.0249477,
                    49.83288410000004
                 ],
                 [
                    97.02233619999998,
                    49.83185479999996
                 ],
                 [
                    97.02003759999995,
                    49.83148850000003
                 ],
                 [
                    97.0180158,
                    49.8316566
                 ],
                 [
                    97.014606,
                    49.83177979999997
                 ],
                 [
                    97.01012820000001,
                    49.83336429999998
                 ],
                 [
                    97.00994460000001,
                    49.83349719999996
                 ],
                 [
                    97.00988780000002,
                    49.83358769999999
                 ],
                 [
                    97.009884,
                    49.83389850000004
                 ],
                 [
                    97.00976259999996,
                    49.83432909999996
                 ],
                 [
                    97.00955390000001,
                    49.834678900000036
                 ],
                 [
                    97.009535,
                    49.834776799999965
                 ],
                 [
                    97.009535,
                    49.834874699999986
                 ],
                 [
                    97.00964120000005,
                    49.83505809999997
                 ],
                 [
                    97.0097588,
                    49.8351218
                 ],
                 [
                    97.01022910000002,
                    49.83532239999999
                 ],
                 [
                    97.01032020000001,
                    49.83539820000004
                 ],
                 [
                    97.010396,
                    49.83548390000003
                 ],
                 [
                    97.01051739999998,
                    49.83598779999998
                 ],
                 [
                    97.01043410000001,
                    49.836862400000044
                 ],
                 [
                    97.01043650000004,
                    49.837088
                 ],
                 [
                    97.01047439999998,
                    49.83718339999996
                 ],
                 [
                    97.0106906,
                    49.83752110000001
                 ],
                 [
                    97.010793,
                    49.83784279999998
                 ],
                 [
                    97.010793,
                    49.837938199999975
                 ],
                 [
                    97.01075510000001,
                    49.838129
                 ],
                 [
                    97.01069439999996,
                    49.838222
                 ],
                 [
                    97.01028850000004,
                    49.838562
                 ],
                 [
                    97.00963230000002,
                    49.8389021
                 ],
                 [
                    97.0094882,
                    49.838929
                 ],
                 [
                    97.00919230000002,
                    49.8389143
                 ],
                 [
                    97.00880539999999,
                    49.8388091
                 ],
                 [
                    97.0086499,
                    49.838792
                 ],
                 [
                    97.00850190000001,
                    49.8388262
                 ],
                 [
                    97.00731089999996,
                    49.839288599999975
                 ],
                 [
                    97.00720090000002,
                    49.83935949999998
                 ],
                 [
                    97.00713260000005,
                    49.839447599999986
                 ],
                 [
                    97.0067305,
                    49.8400812
                 ],
                 [
                    97.00654840000003,
                    49.8402476
                 ],
                 [
                    97.0064877,
                    49.8403356
                 ],
                 [
                    97.0065029,
                    49.84067319999996
                 ],
                 [
                    97.00647630000002,
                    49.84077109999998
                 ],
                 [
                    97.00596049999997,
                    49.84135819999997
                 ],
                 [
                    97.0058353,
                    49.8414218
                 ],
                 [
                    97.00568359999997,
                    49.84145110000002
                 ],
                 [
                    97.00524359999999,
                    49.841571
                 ],
                 [
                    97.00514110000003,
                    49.8416419
                 ],
                 [
                    97.00507289999999,
                    49.84173489999996
                 ],
                 [
                    97.0048035,
                    49.84227059999997
                 ],
                 [
                    97.00457589999998,
                    49.842684
                 ],
                 [
                    97.00457970000004,
                    49.84278430000002
                 ],
                 [
                    97.00467080000001,
                    49.84320510000002
                 ],
                 [
                    97.00468590000003,
                    49.843435
                 ],
                 [
                    97.00463660000003,
                    49.84358180000001
                 ],
                 [
                    97.0045418,
                    49.8437383
                 ],
                 [
                    97.00432180000001,
                    49.843970700000035
                 ],
                 [
                    97.00395390000003,
                    49.84426179999996
                 ],
                 [
                    97.0036732,
                    49.844584700000034
                 ],
                 [
                    97.00355939999999,
                    49.84464590000004
                 ],
                 [
                    97.00340760000005,
                    49.8446605
                 ],
                 [
                    97.0032256,
                    49.84472410000002
                 ],
                 [
                    97.002888,
                    49.84496140000003
                 ],
                 [
                    97.00285760000001,
                    49.84505920000004
                 ],
                 [
                    97.002888,
                    49.845157099999966
                 ],
                 [
                    97.0029145,
                    49.8453356
                 ],
                 [
                    97.0028993,
                    49.84560230000003
                 ],
                 [
                    97.00284619999995,
                    49.84569279999997
                 ],
                 [
                    97.0025162,
                    49.8461086
                 ],
                 [
                    97.00221280000004,
                    49.84642169999997
                 ],
                 [
                    97.002171,
                    49.84651460000002
                 ],
                 [
                    97.00216349999995,
                    49.8466149
                 ],
                 [
                    97.00218619999997,
                    49.84701610000004
                 ],
                 [
                    97.00210279999999,
                    49.84720439999999
                 ],
                 [
                    97.00202690000002,
                    49.84729
                 ],
                 [
                    97.00191310000002,
                    49.847358500000034
                 ],
                 [
                    97.0015983,
                    49.84750279999999
                 ],
                 [
                    97.00148449999999,
                    49.847568900000034
                 ],
                 [
                    97.00140860000002,
                    49.84765690000003
                 ],
                 [
                    97.00135549999997,
                    49.8477596
                 ],
                 [
                    97.00135549999997,
                    49.847857500000025
                 ],
                 [
                    97.001731,
                    49.848525200000026
                 ],
                 [
                    97.00175379999999,
                    49.84872329999998
                 ],
                 [
                    97.0017348,
                    49.848818699999974
                 ],
                 [
                    97.00151480000001,
                    49.8490927
                 ],
                 [
                    97.0014276,
                    49.84917340000001
                 ],
                 [
                    97.0013138,
                    49.8492419
                 ],
                 [
                    97.00112789999997,
                    49.849307900000035
                 ],
                 [
                    97.00101790000002,
                    49.84937640000002
                 ],
                 [
                    97.00093830000002,
                    49.849464400000016
                 ],
                 [
                    97.00088140000001,
                    49.84969190000001
                 ],
                 [
                    97.00089650000001,
                    49.849789700000024
                 ],
                 [
                    97.00134029999997,
                    49.85040849999997
                 ],
                 [
                    97.0013859,
                    49.85050150000003
                 ],
                 [
                    97.00139719999997,
                    49.85059690000002
                 ],
                 [
                    97.0013859,
                    49.85069709999996
                 ],
                 [
                    97.00132519999995,
                    49.850834100000014
                 ],
                 [
                    97.00124929999998,
                    49.8509197
                 ],
                 [
                    97.0011507,
                    49.850995500000046
                 ],
                 [
                    97.0009155,
                    49.851147199999986
                 ],
                 [
                    97.00054750000001,
                    49.851435799999976
                 ],
                 [
                    97.0002251,
                    49.851582500000035
                 ],
                 [
                    96.99998239999996,
                    49.85166810000001
                 ],
                 [
                    96.9999065,
                    49.85175610000001
                 ],
                 [
                    96.999842,
                    49.85212790000004
                 ],
                 [
                    96.99984580000003,
                    49.85222330000003
                 ],
                 [
                    96.99994819999998,
                    49.85242869999997
                 ],
                 [
                    96.99996720000004,
                    49.852551
                 ],
                 [
                    96.99991030000004,
                    49.8527662
                 ],
                 [
                    96.99983820000003,
                    49.8529325
                 ],
                 [
                    96.99978130000002,
                    49.853023
                 ],
                 [
                    96.99957650000005,
                    49.8532578
                 ],
                 [
                    96.9995158,
                    49.8533532
                 ],
                 [
                    96.99948539999997,
                    49.85379590000003
                 ],
                 [
                    96.9995234,
                    49.85406
                 ],
                 [
                    96.9995234,
                    49.85415540000002
                 ],
                 [
                    96.99932989999998,
                    49.85455639999998
                 ],
                 [
                    96.99925029999997,
                    49.854644499999985
                 ],
                 [
                    96.9991251,
                    49.854695799999966
                 ],
                 [
                    96.99871540000002,
                    49.854832800000025
                 ],
                 [
                    96.9986623,
                    49.85492569999998
                 ],
                 [
                    96.99866610000004,
                    49.8550236
                 ],
                 [
                    96.99871919999998,
                    49.8551116
                 ],
                 [
                    96.99885959999997,
                    49.8554735
                 ],
                 [
                    96.99885959999997,
                    49.8555689
                 ],
                 [
                    96.998814,
                    49.8556667
                 ],
                 [
                    96.99841580000002,
                    49.856199899999986
                 ],
                 [
                    96.998264,
                    49.8564224
                 ],
                 [
                    96.99756609999997,
                    49.8569922
                 ],
                 [
                    96.99748640000004,
                    49.85710960000002
                 ],
                 [
                    96.99739539999997,
                    49.857322300000035
                 ],
                 [
                    96.99733470000001,
                    49.85751059999998
                 ],
                 [
                    96.99730810000003,
                    49.8577405
                 ],
                 [
                    96.99735369999998,
                    49.85835429999998
                 ],
                 [
                    96.99746370000003,
                    49.85869669999998
                 ],
                 [
                    96.99771019999999,
                    49.8592175
                 ],
                 [
                    96.9978316,
                    49.85969189999999
                 ],
                 [
                    96.99791509999999,
                    49.8600881
                 ],
                 [
                    96.99794160000005,
                    49.86052579999998
                 ],
                 [
                    96.99786950000002,
                    49.8608363
                 ],
                 [
                    96.99768370000002,
                    49.86137670000004
                 ],
                 [
                    96.99761920000002,
                    49.86165059999996
                 ],
                 [
                    96.99761920000002,
                    49.86174839999997
                 ],
                 [
                    96.99764569999998,
                    49.861878
                 ],
                 [
                    96.99772919999997,
                    49.86205410000004
                 ],
                 [
                    96.9980592,
                    49.862577299999984
                 ],
                 [
                    96.99835890000001,
                    49.86315689999998
                 ],
                 [
                    96.99833230000004,
                    49.86334760000004
                 ],
                 [
                    96.99818820000002,
                    49.8636263
                 ],
                 [
                    96.9981995,
                    49.86372660000004
                 ],
                 [
                    96.998283,
                    49.863961300000014
                 ],
                 [
                    96.998283,
                    49.8640567
                 ],
                 [
                    96.99824889999998,
                    49.864152
                 ],
                 [
                    96.99815019999998,
                    49.86422540000002
                 ],
                 [
                    96.99772919999997,
                    49.864399
                 ],
                 [
                    96.99761920000002,
                    49.8644699
                 ],
                 [
                    96.99740680000002,
                    49.86464349999997
                 ],
                 [
                    96.99662159999998,
                    49.86535260000002
                 ],
                 [
                    96.99656850000002,
                    49.8655433
                 ],
                 [
                    96.99656090000002,
                    49.86596379999997
                 ],
                 [
                    96.996504,
                    49.8660543
                 ],
                 [
                    96.99638639999998,
                    49.86612279999998
                 ],
                 [
                    96.99624980000004,
                    49.866161900000016
                 ],
                 [
                    96.99594260000002,
                    49.86620830000003
                 ],
                 [
                    96.99580220000004,
                    49.866247399999985
                 ],
                 [
                    96.99568840000003,
                    49.86631589999997
                 ],
                 [
                    96.9956543,
                    49.866408800000016
                 ],
                 [
                    96.99567330000004,
                    49.86656770000002
                 ],
                 [
                    96.99571119999997,
                    49.866709600000036
                 ],
                 [
                    96.99577950000003,
                    49.86684160000003
                 ],
                 [
                    96.99585529999999,
                    49.866922299999956
                 ],
                 [
                    96.99601839999998,
                    49.8670592
                 ],
                 [
                    96.9964395,
                    49.86718390000002
                 ],
                 [
                    96.99681120000004,
                    49.86733059999999
                 ],
                 [
                    96.99691359999998,
                    49.86740390000001
                 ],
                 [
                    96.99702739999998,
                    49.867584799999975
                 ],
                 [
                    96.9971071,
                    49.86869479999998
                 ],
                 [
                    96.997164,
                    49.868790199999964
                 ],
                 [
                    96.99756609999997,
                    49.86911290000002
                 ],
                 [
                    96.99763059999998,
                    49.86920090000002
                 ],
                 [
                    96.99769880000002,
                    49.86948210000001
                 ],
                 [
                    96.99776330000003,
                    49.86957010000001
                 ],
                 [
                    96.99799849999995,
                    49.86974369999999
                 ],
                 [
                    96.998063,
                    49.869829299999964
                 ],
                 [
                    96.998063,
                    49.86992460000003
                 ],
                 [
                    96.99791509999999,
                    49.87025459999998
                 ],
                 [
                    96.9978885,
                    49.870354900000024
                 ],
                 [
                    96.99799090000003,
                    49.870843799999975
                 ],
                 [
                    96.998082,
                    49.87105160000002
                 ],
                 [
                    96.99814640000002,
                    49.87113970000003
                 ],
                 [
                    96.99832470000003,
                    49.87133280000002
                 ],
                 [
                    96.9985637,
                    49.87171659999998
                 ],
                 [
                    96.9985713,
                    49.871812
                 ],
                 [
                    96.99838920000003,
                    49.872134700000025
                 ],
                 [
                    96.99830579999998,
                    49.87221530000003
                 ],
                 [
                    96.998154,
                    49.87231559999998
                 ],
                 [
                    96.99807440000002,
                    49.87240110000004
                 ],
                 [
                    96.99804020000003,
                    49.87249889999996
                 ],
                 [
                    96.99804780000004,
                    49.87259669999997
                 ],
                 [
                    96.99818820000002,
                    49.87285590000001
                 ],
                 [
                    96.9982868,
                    49.872936500000016
                 ],
                 [
                    96.99865469999997,
                    49.8731541
                 ],
                 [
                    96.99907959999996,
                    49.87349639999998
                 ],
                 [
                    96.9992313,
                    49.87380190000003
                 ],
                 [
                    96.99944369999999,
                    49.87405620000003
                 ],
                 [
                    96.99966369999997,
                    49.87440329999997
                 ],
                 [
                    96.99969789999996,
                    49.87449860000004
                 ],
                 [
                    96.99972440000002,
                    49.87475529999996
                 ],
                 [
                    96.99970549999998,
                    49.8752882
                 ],
                 [
                    96.99962580000003,
                    49.87537140000004
                 ],
                 [
                    96.99936409999997,
                    49.87550579999997
                 ],
                 [
                    96.9992806,
                    49.87558890000001
                 ],
                 [
                    96.9992389,
                    49.87568179999997
                 ],
                 [
                    96.99921990000003,
                    49.875777200000044
                 ],
                 [
                    96.99921990000003,
                    49.875877399999986
                 ],
                 [
                    96.99925410000002,
                    49.8759727
                 ],
                 [
                    96.99937919999998,
                    49.876244100000044
                 ],
                 [
                    96.99953479999996,
                    49.87652029999998
                 ],
                 [
                    96.99954229999997,
                    49.87661559999996
                 ],
                 [
                    96.99951960000004,
                    49.87671339999997
                 ],
                 [
                    96.99922750000003,
                    49.87730369999996
                 ],
                 [
                    96.998962,
                    49.87756770000004
                 ],
                 [
                    96.99887850000002,
                    49.87784640000001
                 ],
                 [
                    96.99887850000002,
                    49.877944100000015
                 ],
                 [
                    96.998943,
                    49.8782155
                 ],
                 [
                    96.998943,
                    49.878638299999984
                 ],
                 [
                    96.998962,
                    49.8787361
                 ],
                 [
                    96.99916679999998,
                    49.879210299999976
                 ],
                 [
                    96.99945510000003,
                    49.879709
                 ],
                 [
                    96.9998193,
                    49.88051320000004
                 ],
                 [
                    96.99982679999997,
                    49.880672
                 ],
                 [
                    97.00003550000001,
                    49.8809507
                 ],
                 [
                    97.0001417,
                    49.88118529999996
                 ],
                 [
                    97.00014549999996,
                    49.8813833
                 ],
                 [
                    97.00005820000003,
                    49.88158130000004
                 ],
                 [
                    96.99965990000001,
                    49.881943
                 ],
                 [
                    96.9994096,
                    49.8822999
                 ],
                 [
                    96.99912890000004,
                    49.88259560000003
                 ],
                 [
                    96.99902269999997,
                    49.882664100000014
                 ],
                 [
                    96.99844610000004,
                    49.882908499999985
                 ],
                 [
                    96.99835509999997,
                    49.882986699999975
                 ],
                 [
                    96.9980592,
                    49.8833509
                 ],
                 [
                    96.99794160000005,
                    49.883414400000014
                 ],
                 [
                    96.99780129999998,
                    49.8834462
                 ],
                 [
                    96.99768370000002,
                    49.8835073
                 ],
                 [
                    96.99747880000002,
                    49.883717500000046
                 ],
                 [
                    96.99745989999998,
                    49.883815299999966
                 ],
                 [
                    96.9974902,
                    49.883910600000036
                 ],
                 [
                    96.9975547,
                    49.883998600000034
                 ],
                 [
                    96.99779369999997,
                    49.88420629999999
                 ],
                 [
                    96.99790749999997,
                    49.88427230000003
                 ],
                 [
                    96.9984347,
                    49.8844898
                 ],
                 [
                    96.9988482,
                    49.8847636
                 ],
                 [
                    96.9991896,
                    49.88501530000004
                 ],
                 [
                    96.99943990000003,
                    49.8852768
                 ],
                 [
                    96.9995879,
                    49.885465
                 ],
                 [
                    96.99961439999998,
                    49.885562800000045
                 ],
                 [
                    96.99959919999996,
                    49.88566049999996
                 ],
                 [
                    96.99930719999996,
                    49.886036900000015
                 ],
                 [
                    96.99917060000003,
                    49.8861762
                 ],
                 [
                    96.9990606,
                    49.88624469999998
                 ],
                 [
                    96.99892780000002,
                    49.886296
                 ],
                 [
                    96.998632,
                    49.88635710000004
                 ],
                 [
                    96.998173,
                    49.886374200000034
                 ],
                 [
                    96.99802129999998,
                    49.8863962
                 ],
                 [
                    96.9978809,
                    49.88644510000004
                 ],
                 [
                    96.99736119999999,
                    49.88668210000002
                 ],
                 [
                    96.99720950000004,
                    49.886731
                 ],
                 [
                    96.9971071,
                    49.88680189999999
                 ],
                 [
                    96.997054,
                    49.886894800000036
                 ],
                 [
                    96.9970502,
                    49.886992500000034
                 ],
                 [
                    96.99708050000002,
                    49.88708780000002
                 ],
                 [
                    96.99715260000004,
                    49.887175800000016
                 ],
                 [
                    96.99753570000003,
                    49.887474
                 ],
                 [
                    96.9976533,
                    49.88753020000003
                 ],
                 [
                    96.99827540000004,
                    49.88767190000003
                 ],
                 [
                    96.99840060000001,
                    49.88772330000002
                 ],
                 [
                    96.9985144,
                    49.8877844
                 ],
                 [
                    96.9986206,
                    49.88785770000003
                 ],
                 [
                    96.9986965,
                    49.8879432
                 ],
                 [
                    96.998742,
                    49.888038499999986
                 ],
                 [
                    96.99880650000001,
                    49.8883025
                 ],
                 [
                    96.9987534,
                    49.88839530000002
                 ],
                 [
                    96.99867370000004,
                    49.88847839999997
                 ],
                 [
                    96.99855990000005,
                    49.8885444
                 ],
                 [
                    96.99807440000002,
                    49.88878390000002
                 ],
                 [
                    96.99796439999997,
                    49.8888523
                 ],
                 [
                    96.99737260000003,
                    49.8893827
                 ],
                 [
                    96.9972626,
                    49.88945109999998
                 ],
                 [
                    96.99681120000004,
                    49.88965389999997
                 ],
                 [
                    96.99665570000005,
                    49.889671
                 ],
                 [
                    96.99650779999997,
                    49.8896735
                 ],
                 [
                    96.9963674,
                    49.88971260000003
                 ],
                 [
                    96.99624980000004,
                    49.88977370000003
                 ],
                 [
                    96.99583640000003,
                    49.89011090000004
                 ],
                 [
                    96.99563910000005,
                    49.8903113
                 ],
                 [
                    96.995605,
                    49.89040419999997
                 ],
                 [
                    96.995605,
                    49.89064369999998
                 ],
                 [
                    96.9956846,
                    49.89100290000003
                 ],
                 [
                    96.99566570000003,
                    49.891098200000016
                 ],
                 [
                    96.99560880000003,
                    49.891188600000035
                 ],
                 [
                    96.99551020000004,
                    49.89126680000002
                 ],
                 [
                    96.99520669999997,
                    49.89146479999997
                 ],
                 [
                    96.9951308,
                    49.8915478
                 ],
                 [
                    96.9949677,
                    49.89177020000002
                 ],
                 [
                    96.99493739999998,
                    49.8918655
                 ],
                 [
                    96.9949298,
                    49.89196079999998
                 ],
                 [
                    96.99517259999999,
                    49.89255710000002
                 ],
                 [
                    96.99519150000005,
                    49.8926524
                 ],
                 [
                    96.99516120000003,
                    49.8927477
                 ],
                 [
                    96.99498669999998,
                    49.892965200000035
                 ],
                 [
                    96.9927853,
                    49.89340510000002
                 ],
                 [
                    96.990955,
                    49.893313299999974
                 ],
                 [
                    96.9857414,
                    49.893594
                 ],
                 [
                    96.97916790000004,
                    49.89403630000002
                 ],
                 [
                    96.9719646,
                    49.89456929999998
                 ],
                 [
                    96.97080289999998,
                    49.894665
                 ],
                 [
                    96.9689696,
                    49.8944226
                 ],
                 [
                    96.96643220000001,
                    49.89388539999997
                 ],
                 [
                    96.96489469999999,
                    49.89346870000004
                 ],
                 [
                    96.96252109999998,
                    49.892801199999965
                 ],
                 [
                    96.960081,
                    49.89213420000002
                 ],
                 [
                    96.95848049999996,
                    49.89188970000004
                 ],
                 [
                    96.95685060000001,
                    49.8918387
                 ],
                 [
                    96.955726,
                    49.892127199999976
                 ],
                 [
                    96.9542418,
                    49.89271930000003
                 ],
                 [
                    96.9529173,
                    49.89298789999996
                 ],
                 [
                    96.95111950000002,
                    49.89285230000002
                 ],
                 [
                    96.94955049999996,
                    49.8925215
                 ],
                 [
                    96.94857780000004,
                    49.89207850000003
                 ],
                 [
                    96.94724110000001,
                    49.891724300000035
                 ],
                 [
                    96.94534530000001,
                    49.8916754
                 ],
                 [
                    96.94299,
                    49.89193079999998
                 ],
                 [
                    96.9410739,
                    49.892547700000044
                 ],
                 [
                    96.93939459999997,
                    49.893377400000034
                 ],
                 [
                    96.93715019999998,
                    49.894211599999956
                 ],
                 [
                    96.9354317,
                    49.89474089999997
                 ],
                 [
                    96.93314519999998,
                    49.8951245
                 ],
                 [
                    96.93068,
                    49.894865100000025
                 ],
                 [
                    96.92832160000003,
                    49.8949699
                 ],
                 [
                    96.91852310000003,
                    49.89543449999998
                 ],
                 [
                    96.9125415,
                    49.89556759999998
                 ],
                 [
                    96.910179,
                    49.8954573
                 ],
                 [
                    96.9085085,
                    49.8950194
                 ],
                 [
                    96.90766740000004,
                    49.8944891
                 ],
                 [
                    96.9068028,
                    49.89447440000003
                 ],
                 [
                    96.90611280000002,
                    49.894909300000045
                 ],
                 [
                    96.9053591,
                    49.89549510000002
                 ],
                 [
                    96.90213509999997,
                    49.89554169999997
                 ],
                 [
                    96.89741260000002,
                    49.89544939999997
                 ],
                 [
                    96.89511660000004,
                    49.89533830000001
                 ],
                 [
                    96.89342490000004,
                    49.89554470000004
                 ],
                 [
                    96.89190729999997,
                    49.89617920000004
                 ],
                 [
                    96.89098519999997,
                    49.89665870000002
                 ],
                 [
                    96.88972680000003,
                    49.89692610000002
                 ],
                 [
                    96.88822769999999,
                    49.8967658
                 ],
                 [
                    96.88679520000004,
                    49.8966049
                 ],
                 [
                    96.885568,
                    49.89676459999998
                 ],
                 [
                    96.88428530000002,
                    49.89752610000002
                 ],
                 [
                    96.8819685,
                    49.89810209999998
                 ],
                 [
                    96.88020820000001,
                    49.898201400000026
                 ],
                 [
                    96.87810379999998,
                    49.897659
                 ],
                 [
                    96.8761837,
                    49.8962775
                 ],
                 [
                    96.87396730000002,
                    49.89504870000003
                 ],
                 [
                    96.8683936,
                    49.895691900000045
                 ],
                 [
                    96.86481129999999,
                    49.896148099999955
                 ],
                 [
                    96.86364549999998,
                    49.896028
                 ],
                 [
                    96.86098090000004,
                    49.8957471
                 ],
                 [
                    96.8546299,
                    49.8956867
                 ],
                 [
                    96.85160530000003,
                    49.89573050000004
                 ],
                 [
                    96.84958510000004,
                    49.89617480000003
                 ],
                 [
                    96.84625180000002,
                    49.897573600000044
                 ],
                 [
                    96.84479620000002,
                    49.897992299999956
                 ],
                 [
                    96.84326670000003,
                    49.897981899999984
                 ],
                 [
                    96.8420427,
                    49.898334500000026
                 ],
                 [
                    96.8418869,
                    49.898936900000045
                 ],
                 [
                    96.84183570000003,
                    49.8998178
                 ],
                 [
                    96.84098860000002,
                    49.9008118
                 ],
                 [
                    96.83951529999997,
                    49.902132600000044
                 ],
                 [
                    96.83536189999995,
                    49.9042242
                 ],
                 [
                    96.8270013,
                    49.907269299999975
                 ],
                 [
                    96.824189,
                    49.90810539999997
                 ],
                 [
                    96.81770819999998,
                    49.90832310000004
                 ],
                 [
                    96.819285,
                    49.9111039
                 ],
                 [
                    96.80528790000002,
                    49.91133080000003
                 ],
                 [
                    96.79504759999998,
                    49.91148730000001
                 ],
                 [
                    96.79005870000002,
                    49.911457
                 ],
                 [
                    96.7878633,
                    49.911429
                 ],
                 [
                    96.78549259999996,
                    49.91084369999996
                 ],
                 [
                    96.78349680000002,
                    49.91081430000001
                 ],
                 [
                    96.78056950000003,
                    49.910769600000044
                 ],
                 [
                    96.77714659999997,
                    49.9109214
                 ],
                 [
                    96.77326570000002,
                    49.911548700000026
                 ],
                 [
                    96.77080980000004,
                    49.911865699999964
                 ],
                 [
                    96.767875,
                    49.911348299999965
                 ],
                 [
                    96.76457960000005,
                    49.91115529999998
                 ],
                 [
                    96.76224550000002,
                    49.9107626
                 ],
                 [
                    96.76043260000003,
                    49.90967919999998
                 ],
                 [
                    96.758631,
                    49.90930449999997
                 ],
                 [
                    96.75620330000004,
                    49.90929880000003
                 ],
                 [
                    96.7544151,
                    49.90978290000003
                 ],
                 [
                    96.75301879999998,
                    49.909813499999984
                 ],
                 [
                    96.75148330000003,
                    49.9094369
                 ],
                 [
                    96.75027819999998,
                    49.90892930000001
                 ],
                 [
                    96.74807229999998,
                    49.908213300000014
                 ],
                 [
                    96.74550180000001,
                    49.90756410000003
                 ],
                 [
                    96.74337340000004,
                    49.90755630000001
                 ],
                 [
                    96.74087970000004,
                    49.9075723
                 ],
                 [
                    96.73875620000001,
                    49.907886499999975
                 ],
                 [
                    96.73606979999997,
                    49.90835459999998
                 ],
                 [
                    96.73404639999997,
                    49.9086895
                 ],
                 [
                    96.73206589999997,
                    49.90966849999998
                 ],
                 [
                    96.73132580000002,
                    49.9113053
                 ],
                 [
                    96.73057619999999,
                    49.91231940000002
                 ],
                 [
                    96.7293404,
                    49.9141741
                 ],
                 [
                    96.72814950000001,
                    49.91680159999998
                 ],
                 [
                    96.72427689999996,
                    49.92028350000004
                 ],
                 [
                    96.72293260000002,
                    49.92158039999996
                 ],
                 [
                    96.72108190000002,
                    49.92238659999997
                 ],
                 [
                    96.71863650000002,
                    49.9234755
                 ],
                 [
                    96.7176134,
                    49.9240187
                 ],
                 [
                    96.71612839999997,
                    49.92482249999998
                 ],
                 [
                    96.71451470000004,
                    49.925927799999954
                 ],
                 [
                    96.7137909,
                    49.92646909999998
                 ],
                 [
                    96.71210519999998,
                    49.92720959999996
                 ],
                 [
                    96.71124729999997,
                    49.927687399999975
                 ],
                 [
                    96.71108790000001,
                    49.92816079999997
                 ],
                 [
                    96.71019759999997,
                    49.9287032
                 ],
                 [
                    96.7092,
                    49.92873069999997
                 ],
                 [
                    96.707864,
                    49.92837380000001
                 ],
                 [
                    96.70738919999995,
                    49.92775389999997
                 ],
                 [
                    96.70717679999997,
                    49.92687469999996
                 ],
                 [
                    96.70633920000003,
                    49.92647180000002
                 ],
                 [
                    96.7053362,
                    49.92613429999999
                 ],
                 [
                    96.7039945,
                    49.92536930000002
                 ],
                 [
                    96.70325329999996,
                    49.92472950000002
                 ],
                 [
                    96.70252280000001,
                    49.92481990000004
                 ],
                 [
                    96.70099579999996,
                    49.9250438
                 ],
                 [
                    96.69918780000002,
                    49.9242387
                 ],
                 [
                    96.69824469999998,
                    49.9234283
                 ],
                 [
                    96.6970417,
                    49.923049
                 ],
                 [
                    96.6953134,
                    49.92314520000003
                 ],
                 [
                    96.693585,
                    49.9232415
                 ],
                 [
                    96.691848,
                    49.92273640000004
                 ],
                 [
                    96.69136760000002,
                    49.9217085
                 ],
                 [
                    96.69121989999996,
                    49.92067850000004
                 ],
                 [
                    96.69066929999997,
                    49.9193932
                 ],
                 [
                    96.68939690000003,
                    49.9187995
                 ],
                 [
                    96.688119,
                    49.917819199999975
                 ],
                 [
                    96.6870951,
                    49.915978299999985
                 ],
                 [
                    96.6859546,
                    49.9152979
                 ],
                 [
                    96.68321360000002,
                    49.91432609999997
                 ],
                 [
                    96.68273410000003,
                    49.9133411
                 ],
                 [
                    96.68198309999998,
                    49.911971
                 ],
                 [
                    96.68103929999997,
                    49.91107459999997
                 ],
                 [
                    96.67930410000001,
                    49.9106553
                 ],
                 [
                    96.67749340000003,
                    49.90959209999996
                 ],
                 [
                    96.67567319999998,
                    49.9078417
                 ],
                 [
                    96.6730522,
                    49.905881100000045
                 ],
                 [
                    96.6704384,
                    49.90443590000002
                 ],
                 [
                    96.66737070000002,
                    49.90380930000004
                 ],
                 [
                    96.66555870000002,
                    49.902617099999986
                 ],
                 [
                    96.66507670000003,
                    49.9014172
                 ],
                 [
                    96.66360510000003,
                    49.9007813
                 ],
                 [
                    96.66126730000005,
                    49.9000216
                 ],
                 [
                    96.65912670000003,
                    49.8990888
                 ],
                 [
                    96.6572562,
                    49.89845519999998
                 ],
                 [
                    96.6551933,
                    49.898338
                 ],
                 [
                    96.65346979999997,
                    49.8987343
                 ],
                 [
                    96.65193469999996,
                    49.89831339999997
                 ],
                 [
                    96.64826340000003,
                    49.897260199999955
                 ],
                 [
                    96.64559360000003,
                    49.89650190000001
                 ],
                 [
                    96.64411550000003,
                    49.89535050000004
                 ],
                 [
                    96.64204360000002,
                    49.8945459
                 ],
                 [
                    96.641102,
                    49.893735
                 ],
                 [
                    96.64074690000002,
                    49.89201889999999
                 ],
                 [
                    96.6408605,
                    49.89055789999998
                 ],
                 [
                    96.64064969999997,
                    49.8897
                 ],
                 [
                    96.6396463,
                    49.8892331
                 ],
                 [
                    96.63784740000001,
                    49.88894229999998
                 ],
                 [
                    96.6347867,
                    49.888744400000036
                 ],
                 [
                    96.63291859999997,
                    49.8882392
                 ],
                 [
                    96.63051710000003,
                    49.887608
                 ],
                 [
                    96.62865020000002,
                    49.8871886
                 ],
                 [
                    96.627575,
                    49.886292400000016
                 ],
                 [
                    96.627093,
                    49.885006500000024
                 ],
                 [
                    96.6269495,
                    49.88419119999998
                 ],
                 [
                    96.62621220000001,
                    49.88372270000003
                 ],
                 [
                    96.62468280000003,
                    49.883688
                 ],
                 [
                    96.62302440000003,
                    49.88395460000003
                 ],
                 [
                    96.62176090000004,
                    49.8839184
                 ],
                 [
                    96.615686,
                    49.881889200000025
                 ],
                 [
                    96.61074080000003,
                    49.8798107
                 ],
                 [
                    96.60711680000003,
                    49.87703779999999
                 ],
                 [
                    96.6048962,
                    49.8748589
                 ],
                 [
                    96.60308690000002,
                    49.873665699999954
                 ],
                 [
                    96.60197260000001,
                    49.874917
                 ],
                 [
                    96.60132560000004,
                    49.8763378
                 ],
                 [
                    96.60053980000004,
                    49.877286799999986
                 ],
                 [
                    96.59908940000003,
                    49.87823930000001
                 ],
                 [
                    96.59783609999997,
                    49.879018900000034
                 ],
                 [
                    96.5958512,
                    49.87975930000001
                 ],
                 [
                    96.5949297,
                    49.8804942
                 ],
                 [
                    96.59440690000001,
                    49.881227
                 ],
                 [
                    96.59288610000003,
                    49.8818791
                 ],
                 [
                    96.59182579999997,
                    49.8821422
                 ],
                 [
                    96.5894518,
                    49.883700599999976
                 ],
                 [
                    96.58807309999997,
                    49.885125
                 ],
                 [
                    96.5875559,
                    49.886330299999955
                 ],
                 [
                    96.586441,
                    49.88758150000001
                 ],
                 [
                    96.58652829999997,
                    49.88929910000002
                 ],
                 [
                    96.58594239999998,
                    49.89033290000002
                 ],
                 [
                    96.58496859999997,
                    49.89227070000004
                 ],
                 [
                    96.58412350000003,
                    49.89386419999999
                 ],
                 [
                    96.58394689999999,
                    49.89575490000002
                 ],
                 [
                    96.58350009999997,
                    49.89730339999998
                 ],
                 [
                    96.58181269999997,
                    49.900748
                 ],
                 [
                    96.57965189999997,
                    49.90355069999996
                 ],
                 [
                    96.57946939999998,
                    49.904969
                 ],
                 [
                    96.57935950000004,
                    49.9069024
                 ],
                 [
                    96.57858460000004,
                    49.9088391
                 ],
                 [
                    96.57708729999997,
                    49.911552499999985
                 ],
                 [
                    96.57354929999998,
                    49.91608
                 ],
                 [
                    96.57169810000002,
                    49.917034
                 ],
                 [
                    96.570714,
                    49.91819860000002
                 ],
                 [
                    96.56993,
                    49.91940510000001
                 ],
                 [
                    96.56854870000002,
                    49.92074329999998
                 ],
                 [
                    96.567368,
                    49.9221665
                 ],
                 [
                    96.56697940000004,
                    49.923070399999965
                 ],
                 [
                    96.5659281,
                    49.9241923
                 ],
                 [
                    96.56407990000002,
                    49.925446899999955
                 ],
                 [
                    96.56256730000001,
                    49.92695749999998
                 ],
                 [
                    96.56139669999997,
                    49.92928259999998
                 ],
                 [
                    96.5611609,
                    49.93190370000004
                 ],
                 [
                    96.5606849,
                    49.93388180000002
                 ],
                 [
                    96.5602668,
                    49.935129400000044
                 ],
                 [
                    96.5594455,
                    49.936035300000036
                 ],
                 [
                    96.55795890000002,
                    49.936965900000025
                 ],
                 [
                    96.55660640000002,
                    49.937981799999974
                 ],
                 [
                    96.55588679999998,
                    49.939059
                 ],
                 [
                    96.55523169999996,
                    49.93996410000003
                 ],
                 [
                    96.55288150000001,
                    49.9410491
                 ],
                 [
                    96.55163210000002,
                    49.9423651
                 ],
                 [
                    96.55087330000003,
                    49.942948599999966
                 ],
                 [
                    96.54924939999997,
                    49.9435361
                 ],
                 [
                    96.54695529999995,
                    49.9437187
                 ],
                 [
                    96.54502620000004,
                    49.94381369999996
                 ],
                 [
                    96.54166549999997,
                    49.943850900000015
                 ],
                 [
                    96.5385358,
                    49.94371519999998
                 ],
                 [
                    96.53683849999999,
                    49.94370160000001
                 ],
                 [
                    96.5349108,
                    49.94392520000004
                 ],
                 [
                    96.53378040000001,
                    49.94401630000002
                 ],
                 [
                    96.5310493,
                    49.9438141
                 ],
                 [
                    96.52875470000002,
                    49.9439534
                 ],
                 [
                    96.52536280000004,
                    49.9441836
                 ],
                 [
                    96.52366489999997,
                    49.94412690000003
                 ],
                 [
                    96.52189450000004,
                    49.943512
                 ],
                 [
                    96.518896,
                    49.9431819
                 ],
                 [
                    96.51433549999997,
                    49.943051899999986
                 ],
                 [
                    96.51192139999998,
                    49.94134450000003
                 ],
                 [
                    96.5092669,
                    49.938908
                 ],
                 [
                    96.5071446,
                    49.93642610000004
                 ],
                 [
                    96.50652020000001,
                    49.93400210000003
                 ],
                 [
                    96.50587119999996,
                    49.93239420000004
                 ],
                 [
                    96.50289930000001,
                    49.928155
                 ],
                 [
                    96.50108010000002,
                    49.9259508
                 ],
                 [
                    96.5002768,
                    49.9254818
                 ],
                 [
                    96.497305,
                    49.9243994
                 ],
                 [
                    96.4933712,
                    49.9235571
                 ],
                 [
                    96.48760180000002,
                    49.92209970000003
                 ],
                 [
                    96.48366159999998,
                    49.92059140000002
                 ],
                 [
                    96.47832649999997,
                    49.919260600000044
                 ],
                 [
                    96.47479269999997,
                    49.918437499999975
                 ],
                 [
                    96.47378879999995,
                    49.91781890000004
                 ],
                 [
                    96.47314660000002,
                    49.91676919999996
                 ],
                 [
                    96.47243390000003,
                    49.91531169999998
                 ],
                 [
                    96.47142570000001,
                    49.914242
                 ],
                 [
                    96.46935610000001,
                    49.9134558
                 ],
                 [
                    96.465718,
                    49.91209610000002
                 ],
                 [
                    96.46434709999998,
                    49.9113284
                 ],
                 [
                    96.46206809999998,
                    49.9094906
                 ],
                 [
                    96.46006250000003,
                    49.90840330000001
                 ],
                 [
                    96.45909249999998,
                    49.907805799999956
                 ],
                 [
                    96.45938749999996,
                    49.90735370000003
                 ],
                 [
                    96.45995109999997,
                    49.90717960000001
                 ],
                 [
                    96.46071290000003,
                    49.90685450000003
                 ],
                 [
                    96.46180590000004,
                    49.90639920000002
                 ],
                 [
                    96.462859,
                    49.90525669999999
                 ],
                 [
                    96.46307819999998,
                    49.90383849999997
                 ],
                 [
                    96.4629314,
                    49.90240019999998
                 ],
                 [
                    96.4625718,
                    49.899566800000024
                 ],
                 [
                    96.46232640000004,
                    49.898236299999986
                 ],
                 [
                    96.46235139999997,
                    49.897377200000044
                 ],
                 [
                    96.4626437,
                    49.896645800000016
                 ],
                 [
                    96.46217109999998,
                    49.8958961
                 ],
                 [
                    96.4614662,
                    49.895190200000016
                 ],
                 [
                    96.4587888,
                    49.89331090000004
                 ],
                 [
                    96.4561128,
                    49.89156049999998
                 ],
                 [
                    96.45520559999999,
                    49.890533199999986
                 ],
                 [
                    96.45433289999998,
                    49.88963459999997
                 ],
                 [
                    96.45319770000003,
                    49.889102199999975
                 ],
                 [
                    96.4510976,
                    49.88846610000002
                 ],
                 [
                    96.4490571,
                    49.88707810000001
                 ],
                 [
                    96.44598420000004,
                    49.885457799999976
                 ],
                 [
                    96.44421529999997,
                    49.88467
                 ],
                 [
                    96.44205029999998,
                    49.88416280000003
                 ],
                 [
                    96.4401537,
                    49.8839338
                 ],
                 [
                    96.43828659999996,
                    49.88329660000004
                 ],
                 [
                    96.43714909999998,
                    49.88246330000001
                 ],
                 [
                    96.43589690000003,
                    49.8799768
                 ],
                 [
                    96.4346169,
                    49.878070299999976
                 ],
                 [
                    96.43348219999999,
                    49.87753769999996
                 ],
                 [
                    96.42985809999998,
                    49.8773364
                 ],
                 [
                    96.42782680000005,
                    49.876871399999985
                 ],
                 [
                    96.425927,
                    49.87623410000001
                 ],
                 [
                    96.4244611,
                    49.87581
                 ],
                 [
                    96.42370130000002,
                    49.87632819999998
                 ],
                 [
                    96.42317669999996,
                    49.877146199999984
                 ],
                 [
                    96.42238520000002,
                    49.87783629999998
                 ],
                 [
                    96.42129189999997,
                    49.878226899999966
                 ],
                 [
                    96.4196947,
                    49.87799639999999
                 ],
                 [
                    96.41712620000001,
                    49.87688890000001
                 ],
                 [
                    96.39748220000003,
                    49.88451739999997
                 ],
                 [
                    96.39732320000002,
                    49.88539850000004
                 ],
                 [
                    96.39716550000001,
                    49.886429800000016
                 ],
                 [
                    96.39631039999996,
                    49.88754950000003
                 ],
                 [
                    96.39376070000003,
                    49.88873929999997
                 ],
                 [
                    96.39220190000002,
                    49.88917409999998
                 ],
                 [
                    96.39027539999996,
                    49.889352399999986
                 ],
                 [
                    96.3887849,
                    49.89002319999997
                 ],
                 [
                    96.38872639999997,
                    49.89101130000002
                 ],
                 [
                    96.38866639999998,
                    49.89182759999997
                 ],
                 [
                    96.38800709999995,
                    49.892517
                 ],
                 [
                    96.3861216,
                    49.893683
                 ],
                 [
                    96.38460779999998,
                    49.895620800000025
                 ],
                 [
                    96.3832543,
                    49.896827899999984
                 ],
                 [
                    96.38195839999997,
                    49.896918099999986
                 ],
                 [
                    96.37919810000002,
                    49.89679830000002
                 ],
                 [
                    96.37744410000002,
                    49.8978349
                 ],
                 [
                    96.37515230000002,
                    49.89812149999999
                 ],
                 [
                    96.37136759999996,
                    49.89882090000004
                 ],
                 [
                    96.36974650000003,
                    49.89987839999996
                 ],
                 [
                    96.3686888,
                    49.90069779999999
                 ],
                 [
                    96.36523890000002,
                    49.901739600000035
                 ],
                 [
                    96.35896460000002,
                    49.9030692
                 ],
                 [
                    96.3538515,
                    49.9040728
                 ],
                 [
                    96.35172860000003,
                    49.9047879
                 ],
                 [
                    96.34827410000003,
                    49.9052923
                 ],
                 [
                    96.34732349999997,
                    49.90720650000002
                 ],
                 [
                    96.34581160000002,
                    49.90968070000001
                 ],
                 [
                    96.34333930000001,
                    49.91275909999999
                 ],
                 [
                    96.34181940000002,
                    49.91415950000004
                 ],
                 [
                    96.33983079999997,
                    49.91513170000001
                 ],
                 [
                    96.34026779999998,
                    49.91579620000002
                 ],
                 [
                    96.3414747,
                    49.91714560000002
                 ],
                 [
                    96.34305060000001,
                    49.918944900000035
                 ],
                 [
                    96.34416170000003,
                    49.9208315
                 ],
                 [
                    96.3448764,
                    49.923105799999966
                 ],
                 [
                    96.3465853,
                    49.92484020000003
                 ],
                 [
                    96.347292,
                    49.925976299999974
                 ],
                 [
                    96.3465744,
                    49.92795420000002
                 ],
                 [
                    96.34538240000003,
                    49.92875229999998
                 ],
                 [
                    96.34209980000004,
                    49.93028680000002
                 ],
                 [
                    96.3375845,
                    49.9316101
                 ],
                 [
                    96.33893090000004,
                    49.9338396
                 ],
                 [
                    96.33937530000003,
                    49.93553490000002
                 ],
                 [
                    96.33864,
                    49.93981070000004
                 ],
                 [
                    96.33811620000004,
                    49.941036300000015
                 ],
                 [
                    96.33663069999996,
                    49.94275859999997
                 ],
                 [
                    96.33450940000004,
                    49.94396739999998
                 ],
                 [
                    96.33055739999999,
                    49.945159899999965
                 ],
                 [
                    96.32840320000001,
                    49.946476
                 ],
                 [
                    96.32688049999996,
                    49.9476829
                 ],
                 [
                    96.32509,
                    49.9486759
                 ],
                 [
                    96.323126,
                    49.9486169
                 ],
                 [
                    96.32149079999996,
                    49.9479557
                 ],
                 [
                    96.32021320000004,
                    49.946005
                 ],
                 [
                    96.3180755,
                    49.9448082
                 ],
                 [
                    96.31518009999998,
                    49.94479460000003
                 ],
                 [
                    96.31271630000005,
                    49.944608
                 ],
                 [
                    96.3093548,
                    49.94455259999999
                 ],
                 [
                    96.30492810000003,
                    49.944457
                 ],
                 [
                    96.3014006,
                    49.944466200000036
                 ],
                 [
                    96.30003889999996,
                    49.94489919999998
                 ],
                 [
                    96.29970890000001,
                    49.945351099999975
                 ],
                 [
                    96.30064459999998,
                    49.94597139999996
                 ],
                 [
                    96.30254739999995,
                    49.946911400000026
                 ],
                 [
                    96.303249,
                    49.94733909999997
                 ],
                 [
                    96.30345640000003,
                    49.948562600000045
                 ],
                 [
                    96.30352779999998,
                    49.94933559999996
                 ],
                 [
                    96.30290629999999,
                    49.95107669999996
                 ],
                 [
                    96.302546,
                    49.952001099999976
                 ],
                 [
                    96.30082220000003,
                    49.95312230000002
                 ],
                 [
                    96.29983050000004,
                    49.954220099999986
                 ],
                 [
                    96.29857169999998,
                    49.95518980000002
                 ],
                 [
                    96.29657990000003,
                    49.95607540000002
                 ],
                 [
                    96.2937532,
                    49.9565121
                 ],
                 [
                    96.28942469999997,
                    49.9563082
                 ],
                 [
                    96.2864612,
                    49.956143699999984
                 ],
                 [
                    96.284561,
                    49.9556545
                 ],
                 [
                    96.2821309,
                    49.95561749999997
                 ],
                 [
                    96.28073240000002,
                    49.955534899999975
                 ],
                 [
                    96.27980449999998,
                    49.9562459
                 ],
                 [
                    96.2794774,
                    49.9572345
                 ],
                 [
                    96.27895040000001,
                    49.958202200000045
                 ],
                 [
                    96.27832370000003,
                    49.95921310000002
                 ],
                 [
                    96.27716430000002,
                    49.9602037
                 ],
                 [
                    96.27451,
                    49.961756200000025
                 ],
                 [
                    96.26874019999997,
                    49.9658501
                 ],
                 [
                    96.2677144,
                    49.966990600000045
                 ],
                 [
                    96.266424,
                    49.96845389999997
                 ],
                 [
                    96.2657637,
                    49.96946480000004
                 ],
                 [
                    96.26503359999997,
                    49.969896
                 ],
                 [
                    96.26367230000004,
                    49.97060780000002
                 ],
                 [
                    96.26377549999997,
                    49.9712088
                 ],
                 [
                    96.2645788,
                    49.97198009999997
                 ],
                 [
                    96.26508409999997,
                    49.97303129999999
                 ],
                 [
                    96.26452609999998,
                    49.974514399999954
                 ],
                 [
                    96.26260129999997,
                    49.97572129999998
                 ],
                 [
                    96.26070789999999,
                    49.9765846
                 ],
                 [
                    96.25851339999996,
                    49.977212299999984
                 ],
                 [
                    96.2546226,
                    49.978230300000014
                 ],
                 [
                    96.2498006,
                    49.97955070000002
                 ],
                 [
                    96.24683930000002,
                    49.98007250000003
                 ],
                 [
                    96.24307670000002,
                    49.980187799999975
                 ],
                 [
                    96.2407119,
                    49.980128300000025
                 ],
                 [
                    96.23515060000004,
                    49.98020409999999
                 ],
                 [
                    96.23075380000004,
                    49.9800412
                 ],
                 [
                    96.2277532,
                    49.97933840000004
                 ],
                 [
                    96.22548630000003,
                    49.978827400000036
                 ],
                 [
                    96.22315030000001,
                    49.97777960000003
                 ],
                 [
                    96.2215486,
                    49.9770955
                 ],
                 [
                    96.22045319999997,
                    49.9778492
                 ],
                 [
                    96.219657,
                    49.97853789999997
                 ],
                 [
                    96.217996,
                    49.979443
                 ],
                 [
                    96.2167339,
                    49.980197
                 ],
                 [
                    96.2159696,
                    49.980563499999974
                 ],
                 [
                    96.2142392,
                    49.9808674
                 ],
                 [
                    96.21330939999997,
                    49.98149189999996
                 ],
                 [
                    96.21241170000003,
                    49.98183720000002
                 ],
                 [
                    96.21128010000002,
                    49.982011
                 ],
                 [
                    96.21048230000001,
                    49.98235609999997
                 ],
                 [
                    96.20941580000004,
                    49.98216470000003
                 ],
                 [
                    96.2085822,
                    49.98195149999997
                 ],
                 [
                    96.2074164,
                    49.98191059999996
                 ],
                 [
                    96.2066178,
                    49.9820838
                 ],
                 [
                    96.2053888,
                    49.982837700000026
                 ],
                 [
                    96.20485919999997,
                    49.983611700000026
                 ],
                 [
                    96.20406330000003,
                    49.98442919999999
                 ],
                 [
                    96.202969,
                    49.9855693
                 ],
                 [
                    96.20174169999996,
                    49.986774
                 ],
                 [
                    96.20054570000003,
                    49.987506300000035
                 ],
                 [
                    96.19904910000001,
                    49.9880672
                 ],
                 [
                    96.19741789999996,
                    49.98828469999996
                 ],
                 [
                    96.1953531,
                    49.98837409999999
                 ],
                 [
                    96.19402020000004,
                    49.9882475
                 ],
                 [
                    96.191554,
                    49.987908
                 ],
                 [
                    96.188791,
                    49.9882991
                 ],
                 [
                    96.1867267,
                    49.988538700000014
                 ],
                 [
                    96.18539360000001,
                    49.988326
                 ],
                 [
                    96.18372990000002,
                    49.988801199999976
                 ],
                 [
                    96.18173249999995,
                    49.98908349999997
                 ],
                 [
                    96.18079960000004,
                    49.9890205
                 ],
                 [
                    96.17946630000003,
                    49.98878629999999
                 ],
                 [
                    96.1783023,
                    49.989282099999976
                 ],
                 [
                    96.17680439999997,
                    49.98956349999999
                 ],
                 [
                    96.17350670000005,
                    49.98952559999996
                 ],
                 [
                    96.1711413,
                    49.9894217
                 ],
                 [
                    96.16960919999998,
                    49.98946689999999
                 ],
                 [
                    96.1666758,
                    49.98882689999997
                 ],
                 [
                    96.16414260000002,
                    49.9883366
                 ],
                 [
                    96.16317640000001,
                    49.98827350000001
                 ],
                 [
                    96.1613127,
                    49.98874859999997
                 ],
                 [
                    96.15954840000003,
                    49.9890517
                 ],
                 [
                    96.15798379999998,
                    49.989333
                 ],
                 [
                    96.1550533,
                    49.98957319999999
                 ],
                 [
                    96.15129030000003,
                    49.989900200000044
                 ],
                 [
                    96.14942509999997,
                    49.989945599999984
                 ],
                 [
                    96.1472924,
                    49.98966920000003
                 ],
                 [
                    96.14495849999999,
                    49.988920499999985
                 ],
                 [
                    96.1427601,
                    49.98892320000003
                 ],
                 [
                    96.14122750000001,
                    49.9887962
                 ],
                 [
                    96.13972730000005,
                    49.98832559999997
                 ],
                 [
                    96.13593010000001,
                    49.988330099999985
                 ],
                 [
                    96.13456380000004,
                    49.98809540000002
                 ],
                 [
                    96.1334963,
                    49.98753830000002
                 ],
                 [
                    96.13216250000004,
                    49.986981499999956
                 ],
                 [
                    96.13043029999997,
                    49.986919
                 ],
                 [
                    96.12909790000005,
                    49.9868775
                 ],
                 [
                    96.1280315,
                    49.986664
                 ],
                 [
                    96.127099,
                    49.986708
                 ],
                 [
                    96.12533349999997,
                    49.986624
                 ],
                 [
                    96.12436749999998,
                    49.986625
                 ],
                 [
                    96.12200369999998,
                    49.98703549999998
                 ],
                 [
                    96.1184754,
                    49.987984099999956
                 ],
                 [
                    96.11511439999998,
                    49.9892975
                 ],
                 [
                    96.1091848,
                    49.989067
                 ],
                 [
                    96.1068866,
                    49.98913360000003
                 ],
                 [
                    96.10542280000003,
                    49.989951
                 ],
                 [
                    96.10412610000003,
                    49.991068800000015
                 ],
                 [
                    96.10226360000003,
                    49.992402
                 ],
                 [
                    96.100034,
                    49.99352060000002
                 ],
                 [
                    96.09793660000004,
                    49.9941452
                 ],
                 [
                    96.09703829999997,
                    49.99470429999999
                 ],
                 [
                    96.0956078,
                    49.99571480000002
                 ],
                 [
                    96.09430979999998,
                    49.99636010000003
                 ],
                 [
                    96.09324609999999,
                    49.99756359999996
                 ],
                 [
                    96.09288109999999,
                    49.9983155
                 ],
                 [
                    96.0923177,
                    49.9998837
                 ],
                 [
                    96.0913877,
                    50.0014092
                 ],
                 [
                    96.09065769999997,
                    50.0029989
                 ],
                 [
                    96.08946,
                    50.0039233
                 ],
                 [
                    96.08609830000002,
                    50.0059445
                 ],
                 [
                    96.083969,
                    50.0078359
                 ],
                 [
                    96.08297160000004,
                    50.00910359999999
                 ],
                 [
                    96.08293969999997,
                    50.009941200000014
                 ],
                 [
                    96.0821401,
                    50.010049099999975
                 ],
                 [
                    96.0805408,
                    50.010222
                 ],
                 [
                    96.07814220000004,
                    50.01065320000004
                 ],
                 [
                    96.076377,
                    50.011234199999976
                 ],
                 [
                    96.07507710000002,
                    50.011127700000046
                 ],
                 [
                    96.07301039999999,
                    50.01074239999996
                 ],
                 [
                    96.07154409999998,
                    50.01070040000004
                 ],
                 [
                    96.070045,
                    50.011002
                 ],
                 [
                    96.06837990000001,
                    50.011840499999956
                 ],
                 [
                    96.06741429999997,
                    50.01244230000001
                 ],
                 [
                    96.06624830000004,
                    50.01270069999999
                 ],
                 [
                    96.0655145,
                    50.01220720000001
                 ],
                 [
                    96.0649134,
                    50.01128410000002
                 ],
                 [
                    96.06377920000003,
                    50.010425699999956
                 ],
                 [
                    96.062079,
                    50.009889800000025
                 ],
                 [
                    96.05828039999997,
                    50.01017090000001
                 ],
                 [
                    96.05398219999996,
                    50.01068840000002
                 ],
                 [
                    96.050683,
                    50.010561
                 ],
                 [
                    96.046383,
                    50.0092743
                 ],
                 [
                    96.04054980000004,
                    50.007343700000035
                 ],
                 [
                    96.03924970000003,
                    50.00669989999997
                 ],
                 [
                    96.03744970000004,
                    50.005863
                 ],
                 [
                    96.03541679999996,
                    50.00547709999996
                 ],
                 [
                    96.03385079999998,
                    50.0055634
                 ],
                 [
                    96.03235170000002,
                    50.005993300000036
                 ],
                 [
                    96.031219,
                    50.00631579999999
                 ],
                 [
                    96.02975280000001,
                    50.00614440000002
                 ],
                 [
                    96.0291192,
                    50.0054358
                 ],
                 [
                    96.0286522,
                    50.0045125
                 ],
                 [
                    96.02785210000005,
                    50.0037611
                 ],
                 [
                    96.02681910000003,
                    50.003611
                 ],
                 [
                    96.02595290000002,
                    50.00384740000003
                 ],
                 [
                    96.024887,
                    50.00436309999998
                 ],
                 [
                    96.0237543,
                    50.0047713
                 ],
                 [
                    96.02242159999997,
                    50.00498630000003
                 ],
                 [
                    96.01992230000003,
                    50.00429959999997
                 ],
                 [
                    96.0174563,
                    50.00335509999998
                 ],
                 [
                    96.01562330000004,
                    50.001938
                 ],
                 [
                    96.01452339999999,
                    50.000714
                 ],
                 [
                    96.0131237,
                    49.999253900000014
                 ],
                 [
                    96.01202390000003,
                    49.997944
                 ],
                 [
                    96.01069109999999,
                    49.99693479999998
                 ],
                 [
                    96.00875840000003,
                    49.994250500000014
                 ],
                 [
                    96.0076588,
                    49.992768799999986
                 ],
                 [
                    96.00669249999999,
                    49.99098640000003
                 ],
                 [
                    96.00569309999999,
                    49.990234900000026
                 ],
                 [
                    96.0037944,
                    49.989655100000014
                 ],
                 [
                    96.00196230000003,
                    49.9887102
                 ],
                 [
                    96.00136269999997,
                    49.987808299999976
                 ],
                 [
                    96.00106290000004,
                    49.98636940000003
                 ],
                 [
                    96.0003302,
                    49.98591840000002
                 ],
                 [
                    95.99936430000001,
                    49.9851453
                 ],
                 [
                    95.9992311,
                    49.98222470000002
                 ],
                 [
                    95.99889809999998,
                    49.98050669999998
                 ],
                 [
                    95.997666,
                    49.97861680000002
                 ],
                 [
                    95.99636740000003,
                    49.97580350000003
                 ],
                 [
                    95.97998540000002,
                    49.97189329999998
                 ],
                 [
                    95.972294,
                    49.9713548
                 ],
                 [
                    95.967566,
                    49.9711389
                 ],
                 [
                    95.96706630000001,
                    49.9714179
                 ],
                 [
                    95.96663280000003,
                    49.9724056
                 ],
                 [
                    95.96623230000002,
                    49.973694
                 ],
                 [
                    95.9655989,
                    49.97466020000003
                 ],
                 [
                    95.9647326,
                    49.97543310000003
                 ],
                 [
                    95.96190039999998,
                    49.97753680000001
                 ],
                 [
                    95.9590347,
                    49.9796404
                 ],
                 [
                    95.95816790000005,
                    49.98077829999998
                 ],
                 [
                    95.9576669,
                    49.9824102
                 ],
                 [
                    95.95706620000003,
                    49.98380590000002
                 ],
                 [
                    95.95586669999999,
                    49.9843208
                 ],
                 [
                    95.95410120000001,
                    49.98459930000003
                 ],
                 [
                    95.95250210000005,
                    49.9850497
                 ],
                 [
                    95.951569,
                    49.98556469999997
                 ],
                 [
                    95.95016930000003,
                    49.98635870000003
                 ],
                 [
                    95.94866970000002,
                    49.9870667
                 ],
                 [
                    95.94720379999997,
                    49.987323800000034
                 ],
                 [
                    95.9446386,
                    49.98775210000004
                 ],
                 [
                    95.94070680000003,
                    49.98890979999999
                 ],
                 [
                    95.9382708,
                    49.992366
                 ],
                 [
                    95.93523359999996,
                    49.996766800000024
                 ],
                 [
                    95.93386729999997,
                    49.99704519999997
                 ],
                 [
                    95.9321683,
                    49.997022799999975
                 ],
                 [
                    95.9302027,
                    49.997000099999966
                 ],
                 [
                    95.92857030000002,
                    49.99699909999997
                 ],
                 [
                    95.926605,
                    49.996847600000045
                 ],
                 [
                    95.92390620000002,
                    49.9969962
                 ],
                 [
                    95.92217350000001,
                    49.9972098
                 ],
                 [
                    95.92080740000004,
                    49.9973162
                 ],
                 [
                    95.919075,
                    49.9973365
                 ],
                 [
                    95.91664259999997,
                    49.99759250000004
                 ],
                 [
                    95.914277,
                    49.99769809999999
                 ],
                 [
                    95.9128783,
                    49.99743939999998
                 ],
                 [
                    95.91134670000001,
                    49.99694429999997
                 ],
                 [
                    95.9104479,
                    49.99653559999999
                 ],
                 [
                    95.91014770000002,
                    49.996771599999974
                 ],
                 [
                    95.91014650000001,
                    49.99739429999998
                 ],
                 [
                    95.91027829999999,
                    49.998167600000016
                 ],
                 [
                    95.91050960000003,
                    49.99919849999996
                 ],
                 [
                    95.9104747,
                    50.000079
                 ],
                 [
                    95.91047330000005,
                    50.0008306
                 ],
                 [
                    95.9096708,
                    50.0023548
                 ],
                 [
                    95.90910149999996,
                    50.003900499999986
                 ],
                 [
                    95.90849990000001,
                    50.004845
                 ],
                 [
                    95.90816549999997,
                    50.00551039999996
                 ],
                 [
                    95.90739760000002,
                    50.006282900000016
                 ],
                 [
                    95.90666279999998,
                    50.0071843
                 ],
                 [
                    95.9063947,
                    50.0079787
                 ],
                 [
                    95.90646,
                    50.00864450000002
                 ],
                 [
                    95.90672580000005,
                    50.00907420000004
                 ],
                 [
                    95.9074574,
                    50.00982640000002
                 ],
                 [
                    95.90862260000004,
                    50.01042860000002
                 ],
                 [
                    95.90945469999997,
                    50.0109447
                 ],
                 [
                    95.91032050000001,
                    50.01133189999997
                 ],
                 [
                    95.9106863,
                    50.0117402
                 ],
                 [
                    95.91051869999998,
                    50.012234
                 ],
                 [
                    95.90971820000001,
                    50.01259849999997
                 ],
                 [
                    95.90945049999998,
                    50.01317809999996
                 ],
                 [
                    95.9094827,
                    50.013779399999976
                 ],
                 [
                    95.91004790000002,
                    50.01453150000003
                 ],
                 [
                    95.91071340000002,
                    50.0150689
                 ],
                 [
                    95.91154570000005,
                    50.015542
                 ],
                 [
                    95.91254419999997,
                    50.01629439999997
                 ],
                 [
                    95.9148748,
                    50.01764909999997
                 ],
                 [
                    95.91593810000003,
                    50.01949669999998
                 ],
                 [
                    95.91613550000002,
                    50.02097860000003
                 ],
                 [
                    95.91570090000003,
                    50.021687
                 ],
                 [
                    95.91489980000001,
                    50.0223307
                 ],
                 [
                    95.9138656,
                    50.022823800000026
                 ],
                 [
                    95.91249860000002,
                    50.023059
                 ],
                 [
                    95.91119750000001,
                    50.02365939999997
                 ],
                 [
                    95.9091611,
                    50.025268399999966
                 ],
                 [
                    95.9079608,
                    50.02543929999998
                 ],
                 [
                    95.9064933,
                    50.02582459999998
                 ],
                 [
                    95.903992,
                    50.0264454
                 ],
                 [
                    95.90212429999998,
                    50.02689479999997
                 ],
                 [
                    95.90075630000004,
                    50.027473499999985
                 ],
                 [
                    95.89945520000003,
                    50.02796629999999
                 ],
                 [
                    95.8979199,
                    50.02884539999999
                 ],
                 [
                    95.8967175,
                    50.02987520000001
                 ],
                 [
                    95.8956492,
                    50.030604400000044
                 ],
                 [
                    95.8927468,
                    50.03146070000002
                 ],
                 [
                    95.89081209999998,
                    50.03193139999997
                 ],
                 [
                    95.888977,
                    50.03259530000001
                 ],
                 [
                    95.88563870000003,
                    50.03441749999997
                 ],
                 [
                    95.88103189999997,
                    50.0367536
                 ],
                 [
                    95.87966119999999,
                    50.0382125
                 ],
                 [
                    95.878758,
                    50.039392700000036
                 ],
                 [
                    95.8771549,
                    50.04042190000002
                 ],
                 [
                    95.87575279999999,
                    50.04104309999999
                 ],
                 [
                    95.874485,
                    50.0412995
                 ],
                 [
                    95.87128350000003,
                    50.041424800000044
                 ],
                 [
                    95.8680479,
                    50.041807700000014
                 ],
                 [
                    95.86330890000004,
                    50.04319810000004
                 ],
                 [
                    95.86087329999995,
                    50.04362469999999
                 ],
                 [
                    95.85827369999998,
                    50.0431491
                 ],
                 [
                    95.85560909999995,
                    50.042093600000044
                 ],
                 [
                    95.8543431,
                    50.0417269
                 ],
                 [
                    95.85157189999995,
                    50.0428616
                 ],
                 [
                    95.850237,
                    50.043182
                 ],
                 [
                    95.8486033,
                    50.043094
                 ],
                 [
                    95.84653629999995,
                    50.042940899999984
                 ],
                 [
                    95.84566860000001,
                    50.043154499999986
                 ],
                 [
                    95.84416649999999,
                    50.043625
                 ],
                 [
                    95.84313269999998,
                    50.04362359999998
                 ],
                 [
                    95.8414336,
                    50.043148799999955
                 ],
                 [
                    95.83907,
                    50.04196440000001
                 ],
                 [
                    95.83434930000004,
                    50.03777009999999
                 ],
                 [
                    95.83331740000001,
                    50.03725320000003
                 ],
                 [
                    95.8315835,
                    50.0372937
                 ],
                 [
                    95.82984910000002,
                    50.037463
                 ],
                 [
                    95.8275806,
                    50.03778169999997
                 ],
                 [
                    95.8250806,
                    50.03758470000001
                 ],
                 [
                    95.82275050000003,
                    50.036528899999965
                 ],
                 [
                    95.8201203,
                    50.035494
                 ],
                 [
                    95.81748439999996,
                    50.03602679999999
                 ],
                 [
                    95.81464820000004,
                    50.03658069999999
                 ],
                 [
                    95.81285379999998,
                    50.03501009999997
                 ],
                 [
                    95.809961,
                    50.03300819999998
                 ],
                 [
                    95.80588770000003,
                    50.0344618
                 ],
                 [
                    95.79963270000003,
                    50.03133720000001
                 ],
                 [
                    95.7959683,
                    50.0306651
                 ],
                 [
                    95.79068030000005,
                    50.035401700000044
                 ],
                 [
                    95.78918949999998,
                    50.03323
                 ],
                 [
                    95.78799369999997,
                    50.03221850000001
                 ],
                 [
                    95.78603359999998,
                    50.030668700000035
                 ],
                 [
                    95.78335360000001,
                    50.0261325
                 ],
                 [
                    95.78202309999998,
                    50.0254857
                 ],
                 [
                    95.78055960000003,
                    50.024774300000026
                 ],
                 [
                    95.77876419999998,
                    50.023783
                 ],
                 [
                    95.77710090000002,
                    50.02304969999997
                 ],
                 [
                    95.77446990000003,
                    50.02255059999998
                 ],
                 [
                    95.77284079999997,
                    50.021667
                 ],
                 [
                    95.77181850000004,
                    50.01934570000002
                 ],
                 [
                    95.76532989999998,
                    50.01714220000004
                 ],
                 [
                    95.763111,
                    50.01426
                 ],
                 [
                    95.76125690000003,
                    50.01180810000003
                 ],
                 [
                    95.76040010000003,
                    50.00987350000001
                 ],
                 [
                    95.7601775,
                    50.0077255
                 ],
                 [
                    95.7605553,
                    50.005449900000016
                 ],
                 [
                    95.76154540000002,
                    50.000641499999986
                 ],
                 [
                    95.7636266,
                    49.9974889
                 ],
                 [
                    95.7798582,
                    49.98863010000003
                 ],
                 [
                    95.78206680000004,
                    49.986357899999966
                 ],
                 [
                    95.785903,
                    49.985055
                 ],
                 [
                    95.78877209999999,
                    49.983965
                 ],
                 [
                    95.7904851,
                    49.98066090000004
                 ],
                 [
                    95.79042269999997,
                    49.9796944
                 ],
                 [
                    95.79033149999997,
                    49.97767560000003
                 ],
                 [
                    95.79134780000003,
                    49.9737045
                 ],
                 [
                    95.7917981,
                    49.96966789999997
                 ],
                 [
                    95.79390279999996,
                    49.96801809999997
                 ],
                 [
                    95.79521049999998,
                    49.965829900000024
                 ],
                 [
                    95.79555069999996,
                    49.96413390000003
                 ],
                 [
                    95.79461879999995,
                    49.9640464
                 ],
                 [
                    95.79315430000003,
                    49.96397930000003
                 ],
                 [
                    95.79072459999998,
                    49.96384610000002
                 ],
                 [
                    95.78896130000001,
                    49.96356380000002
                 ],
                 [
                    95.78686459999999,
                    49.96340959999996
                 ],
                 [
                    95.78496629999995,
                    49.96357790000003
                 ],
                 [
                    95.78363480000002,
                    49.9635325
                 ],
                 [
                    95.78217219999996,
                    49.96303580000003
                 ],
                 [
                    95.78074149999998,
                    49.96286129999998
                 ],
                 [
                    95.77950770000004,
                    49.963288499999976
                 ],
                 [
                    95.77807350000003,
                    49.963865600000034
                 ],
                 [
                    95.77700880000005,
                    49.96373470000004
                 ],
                 [
                    95.7754803,
                    49.96310890000004
                 ],
                 [
                    95.77521890000001,
                    49.9620561
                 ],
                 [
                    95.77522369999996,
                    49.96102530000004
                 ],
                 [
                    95.77512570000002,
                    49.960617100000015
                 ],
                 [
                    95.77419390000001,
                    49.960550900000044
                 ],
                 [
                    95.773392,
                    49.96117210000001
                 ],
                 [
                    95.77235630000003,
                    49.96196460000004
                 ],
                 [
                    95.7712568,
                    49.96215579999996
                 ],
                 [
                    95.77029190000002,
                    49.96204649999998
                 ],
                 [
                    95.768895,
                    49.9617645
                 ],
                 [
                    95.76786170000003,
                    49.96204160000001
                 ],
                 [
                    95.76629040000005,
                    49.963412899999966
                 ],
                 [
                    95.76539050000002,
                    49.9636258
                 ],
                 [
                    95.7643598,
                    49.9633446
                 ],
                 [
                    95.76256510000002,
                    49.962739600000035
                 ],
                 [
                    95.76123459999997,
                    49.96250059999998
                 ],
                 [
                    95.75900389999997,
                    49.962539
                 ],
                 [
                    95.7568055,
                    49.96277060000003
                 ],
                 [
                    95.75517250000003,
                    49.96311080000002
                 ],
                 [
                    95.7536747,
                    49.963064700000025
                 ],
                 [
                    95.75137809999997,
                    49.96295239999997
                 ],
                 [
                    95.7494786,
                    49.9633349
                 ],
                 [
                    95.7444333,
                    49.96678139999997
                 ],
                 [
                    95.7430979,
                    49.96746570000001
                 ],
                 [
                    95.74149980000003,
                    49.96746209999998
                 ],
                 [
                    95.7402028,
                    49.96718
                 ],
                 [
                    95.736294,
                    49.96354189999998
                 ],
                 [
                    95.733976,
                    49.961281600000014
                 ],
                 [
                    95.73258639999997,
                    49.95973219999998
                 ],
                 [
                    95.7305158,
                    49.96093
                 ],
                 [
                    95.7293814,
                    49.96137840000003
                 ],
                 [
                    95.727749,
                    49.961589299999986
                 ],
                 [
                    95.7265849,
                    49.96141480000002
                 ],
                 [
                    95.72525579999999,
                    49.960960600000014
                 ],
                 [
                    95.72419279999995,
                    49.9605716
                 ],
                 [
                    95.72299470000003,
                    49.9605043
                 ],
                 [
                    95.72182680000003,
                    49.96099539999996
                 ],
                 [
                    95.72045939999997,
                    49.961421599999966
                 ],
                 [
                    95.7184974,
                    49.9610518
                 ],
                 [
                    95.71474060000001,
                    49.960226600000034
                 ],
                 [
                    95.71214679999999,
                    49.95974770000002
                 ],
                 [
                    95.70905460000002,
                    49.9591387
                 ],
                 [
                    95.70695840000003,
                    49.9589831
                 ],
                 [
                    95.70555909999999,
                    49.95917279999997
                 ],
                 [
                    95.7048914,
                    49.95949320000001
                 ],
                 [
                    95.703056,
                    49.9602187
                 ],
                 [
                    95.70138930000003,
                    49.9605795
                 ],
                 [
                    95.6996918,
                    49.9605322
                 ],
                 [
                    95.6985293,
                    49.9601211
                 ],
                 [
                    95.69656930000002,
                    49.95947180000002
                 ],
                 [
                    95.69487220000002,
                    49.9593599
                 ],
                 [
                    95.69377049999999,
                    49.95987240000004
                 ],
                 [
                    95.6927325,
                    49.96081460000001
                 ],
                 [
                    95.69186259999996,
                    49.961499500000016
                 ],
                 [
                    95.69086319999995,
                    49.961604199999975
                 ],
                 [
                    95.6893008,
                    49.96125649999998
                 ],
                 [
                    95.68843829999996,
                    49.96078170000004
                 ],
                 [
                    95.68770710000004,
                    49.960607899999964
                 ],
                 [
                    95.68690549999997,
                    49.961013799999975
                 ],
                 [
                    95.68646940000004,
                    49.961528
                 ],
                 [
                    95.68583280000003,
                    49.962149100000026
                 ],
                 [
                    95.68416680000003,
                    49.96238080000001
                 ],
                 [
                    95.68157,
                    49.9623952
                 ],
                 [
                    95.677742,
                    49.96232019999997
                 ],
                 [
                    95.67544490000002,
                    49.9623352
                 ],
                 [
                    95.67367830000005,
                    49.96265240000002
                 ],
                 [
                    95.67180820000003,
                    49.96350610000004
                 ],
                 [
                    95.668409,
                    49.96401189999997
                 ],
                 [
                    95.66607619999996,
                    49.964348800000046
                 ],
                 [
                    95.6645137,
                    49.964022200000024
                 ],
                 [
                    95.66275709999996,
                    49.9629003
                 ],
                 [
                    95.6605729,
                    49.96104709999997
                 ],
                 [
                    95.65822560000002,
                    49.95874229999997
                 ],
                 [
                    95.65564139999998,
                    49.9570166
                 ],
                 [
                    95.652985,
                    49.956106700000035
                 ],
                 [
                    95.6292991,
                    49.95643070000002
                 ],
                 [
                    95.6272424,
                    49.956056400000044
                 ],
                 [
                    95.5722698,
                    49.9442833
                 ],
                 [
                    95.572145,
                    49.943337900000024
                 ],
                 [
                    95.571489,
                    49.942261699999975
                 ],
                 [
                    95.57013830000004,
                    49.94073189999997
                 ],
                 [
                    95.56905310000003,
                    49.939289
                 ],
                 [
                    95.5688638,
                    49.93812869999996
                 ],
                 [
                    95.56853909999997,
                    49.93722550000001
                 ],
                 [
                    95.56794290000002,
                    49.9369226
                 ],
                 [
                    95.5667812,
                    49.93659609999998
                 ],
                 [
                    95.56588769999996,
                    49.936055899999964
                 ],
                 [
                    95.56480559999996,
                    49.93429080000002
                 ],
                 [
                    95.56412140000002,
                    49.93267760000004
                 ],
                 [
                    95.5640375,
                    49.93091629999997
                 ],
                 [
                    95.56365169999997,
                    49.929433
                 ],
                 [
                    95.56240119999997,
                    49.927925
                 ],
                 [
                    95.56155180000003,
                    49.92620379999997
                 ],
                 [
                    95.56136940000005,
                    49.924313200000036
                 ],
                 [
                    95.5597608,
                    49.9219663
                 ],
                 [
                    95.55808269999997,
                    49.91996260000004
                 ],
                 [
                    95.55714080000003,
                    49.917489300000035
                 ],
                 [
                    95.55676469999999,
                    49.91497530000004
                 ],
                 [
                    95.55571010000004,
                    49.91391890000003
                 ],
                 [
                    95.55374389999999,
                    49.91075440000003
                 ],
                 [
                    95.55196130000002,
                    49.909330199999964
                 ],
                 [
                    95.55033430000005,
                    49.9090662
                 ],
                 [
                    95.54949849999998,
                    49.90953539999998
                 ],
                 [
                    95.54872629999996,
                    49.910327
                 ],
                 [
                    95.54779159999998,
                    49.9107099
                 ],
                 [
                    95.545492,
                    49.911237799999974
                 ],
                 [
                    95.54372180000004,
                    49.90852490000004
                 ],
                 [
                    95.54254129999998,
                    49.906759200000046
                 ],
                 [
                    95.54142030000004,
                    49.905745500000016
                 ],
                 [
                    95.539429,
                    49.905351
                 ],
                 [
                    95.53850209999999,
                    49.904917800000014
                 ],
                 [
                    95.53764329999998,
                    49.90431309999998
                 ],
                 [
                    95.53672050000002,
                    49.9034503
                 ],
                 [
                    95.53653629999997,
                    49.9018604
                 ],
                 [
                    95.53455440000003,
                    49.900499500000016
                 ],
                 [
                    95.53293770000002,
                    49.899204399999974
                 ],
                 [
                    95.5325188,
                    49.89782829999998
                 ],
                 [
                    95.5319595,
                    49.89722470000004
                 ],
                 [
                    95.531028,
                    49.8972854
                 ],
                 [
                    95.529386,
                    49.898610200000014
                 ],
                 [
                    95.52579820000003,
                    49.90170960000004
                 ],
                 [
                    95.5232216,
                    49.9033741
                 ],
                 [
                    95.520819,
                    49.9042447
                 ],
                 [
                    95.50543179999995,
                    49.9072932
                 ],
                 [
                    95.50116309999999,
                    49.908156899999966
                 ],
                 [
                    95.49701270000004,
                    49.9108902
                 ],
                 [
                    95.42591619999999,
                    49.9576412
                 ],
                 [
                    95.38394089999998,
                    49.95773859999996
                 ],
                 [
                    95.34621309999996,
                    49.95867020000004
                 ],
                 [
                    95.328055,
                    49.958354500000034
                 ],
                 [
                    95.30124969999999,
                    49.95800010000002
                 ],
                 [
                    95.27161560000005,
                    49.96000909999998
                 ],
                 [
                    95.21185359999998,
                    49.96443640000001
                 ],
                 [
                    95.19971120000002,
                    49.9653336
                 ],
                 [
                    95.14882259999997,
                    49.96447469999998
                 ],
                 [
                    95.11633320000004,
                    49.9634391
                 ],
                 [
                    95.08157910000004,
                    49.96291210000001
                 ],
                 [
                    95.01652860000002,
                    49.98052819999998
                 ],
                 [
                    95.00647280000004,
                    49.99880139999997
                 ],
                 [
                    95.00363570000003,
                    50.00388830000004
                 ],
                 [
                    95.00020519999997,
                    50.01062549999997
                 ],
                 [
                    94.998194,
                    50.01241020000002
                 ],
                 [
                    94.99728119999997,
                    50.01463579999996
                 ],
                 [
                    94.9974693,
                    50.01680649999997
                 ],
                 [
                    94.99845319999996,
                    50.01917740000003
                 ],
                 [
                    94.9987854,
                    50.02083389999996
                 ],
                 [
                    94.99848530000004,
                    50.022441899999954
                 ],
                 [
                    94.998757,
                    50.02376979999996
                 ],
                 [
                    94.99944350000001,
                    50.02605810000002
                 ],
                 [
                    94.99947810000003,
                    50.0276047
                 ],
                 [
                    94.9986662,
                    50.029119500000014
                 ],
                 [
                    94.99838010000003,
                    50.03070920000001
                 ],
                 [
                    94.9988875,
                    50.0319592
                 ],
                 [
                    94.99886779999999,
                    50.032903899999965
                 ],
                 [
                    94.9981037,
                    50.0343792
                 ],
                 [
                    94.99810530000003,
                    50.03590390000003
                 ],
                 [
                    94.99833219999998,
                    50.03781719999998
                 ],
                 [
                    94.99902659999998,
                    50.03948749999998
                 ],
                 [
                    94.9991642,
                    50.04431010000003
                 ],
                 [
                    94.99408410000004,
                    50.0464352
                 ],
                 [
                    94.99032849999998,
                    50.0474119
                 ],
                 [
                    94.98714549999997,
                    50.04745339999996
                 ],
                 [
                    94.98321439999995,
                    50.0463134
                 ],
                 [
                    94.9778832,
                    50.04453259999997
                 ],
                 [
                    94.97333540000004,
                    50.0435475
                 ],
                 [
                    94.96516219999995,
                    50.04368970000004
                 ],
                 [
                    94.95636749999997,
                    50.0444858
                 ],
                 [
                    94.90070069999999,
                    50.050866
                 ],
                 [
                    94.84692440000002,
                    50.0528191
                 ],
                 [
                    94.8045924,
                    50.05436659999997
                 ],
                 [
                    94.76065220000004,
                    50.0549165
                 ],
                 [
                    94.67498220000004,
                    50.03715760000002
                 ],
                 [
                    94.622271,
                    50.0355493
                 ],
                 [
                    94.61791520000003,
                    50.03524130000001
                 ],
                 [
                    94.6155468,
                    50.035277499999985
                 ],
                 [
                    94.61484310000004,
                    50.0353979
                 ],
                 [
                    94.6145089,
                    50.035608500000016
                 ],
                 [
                    94.6143005,
                    50.0357136
                 ],
                 [
                    94.61341229999996,
                    50.03645470000004
                 ],
                 [
                    94.61146170000004,
                    50.0370327
                 ],
                 [
                    94.61088569999998,
                    50.03734789999996
                 ],
                 [
                    94.61054060000004,
                    50.03775179999998
                 ],
                 [
                    94.61036089999999,
                    50.0382007
                 ],
                 [
                    94.61020159999995,
                    50.03910080000004
                 ],
                 [
                    94.608868,
                    50.0402445
                 ],
                 [
                    94.60802139999996,
                    50.0418451
                 ],
                 [
                    94.60776140000003,
                    50.04276540000003
                 ],
                 [
                    94.60774830000001,
                    50.04321630000003
                 ],
                 [
                    94.6079693,
                    50.0436484
                 ],
                 [
                    94.60856440000003,
                    50.04382739999997
                 ],
                 [
                    94.6092317,
                    50.04381390000001
                 ],
                 [
                    94.60986139999997,
                    50.04395030000001
                 ],
                 [
                    94.61042130000001,
                    50.044193300000046
                 ],
                 [
                    94.61081020000005,
                    50.0445845
                 ],
                 [
                    94.61103059999998,
                    50.04503809999996
                 ],
                 [
                    94.61105089999998,
                    50.0454894
                 ],
                 [
                    94.6109557,
                    50.046476100000014
                 ],
                 [
                    94.61066229999997,
                    50.047396100000014
                 ],
                 [
                    94.61029350000001,
                    50.04977549999998
                 ],
                 [
                    94.6102729,
                    50.051643700000014
                 ],
                 [
                    94.60961510000001,
                    50.0524734
                 ],
                 [
                    94.60928839999998,
                    50.053393
                 ],
                 [
                    94.60916159999998,
                    50.05431489999996
                 ],
                 [
                    94.60842040000001,
                    50.05572349999998
                 ],
                 [
                    94.6084061,
                    50.05621729999998
                 ],
                 [
                    94.608866,
                    50.05761870000003
                 ],
                 [
                    94.608652,
                    50.059248300000036
                 ],
                 [
                    94.608437,
                    50.05976120000002
                 ],
                 [
                    94.60697050000005,
                    50.062020099999955
                 ],
                 [
                    94.6064924,
                    50.06240090000002
                 ],
                 [
                    94.60591610000003,
                    50.06271609999999
                 ],
                 [
                    94.6039933,
                    50.0634447
                 ],
                 [
                    94.60361460000003,
                    50.063848200000045
                 ],
                 [
                    94.60343479999997,
                    50.064297
                 ],
                 [
                    94.60342040000003,
                    50.06479079999997
                 ],
                 [
                    94.604286,
                    50.06600379999997
                 ],
                 [
                    94.60433969999998,
                    50.06645550000004
                 ],
                 [
                    94.60412580000005,
                    50.06692540000002
                 ],
                 [
                    94.60292919999998,
                    50.0679204
                 ],
                 [
                    94.60217170000001,
                    50.06872729999998
                 ],
                 [
                    94.60127059999996,
                    50.07020380000002
                 ],
                 [
                    94.60131349999996,
                    50.07026930000002
                 ],
                 [
                    94.60131349999996,
                    50.07033810000003
                 ],
                 [
                    94.60127059999996,
                    50.07041039999997
                 ],
                 [
                    94.60120079999997,
                    50.070469
                 ],
                 [
                    94.601115,
                    50.070741
                 ],
                 [
                    94.60109889999997,
                    50.07088210000001
                 ],
                 [
                    94.60080919999999,
                    50.0710061
                 ],
                 [
                    94.6007073,
                    50.07113350000003
                 ],
                 [
                    94.6006966,
                    50.07120230000004
                 ],
                 [
                    94.60074479999999,
                    50.07138480000004
                 ],
                 [
                    94.60073409999998,
                    50.071526
                 ],
                 [
                    94.600659,
                    50.071581100000024
                 ],
                 [
                    94.6004283,
                    50.07158450000004
                 ],
                 [
                    94.600321,
                    50.07156390000002
                 ],
                 [
                    94.6002191,
                    50.07153629999996
                 ],
                 [
                    94.59988650000003,
                    50.071336600000045
                 ],
                 [
                    94.59977920000003,
                    50.0713263
                 ],
                 [
                    94.59958080000004,
                    50.071515700000035
                 ],
                 [
                    94.59943060000005,
                    50.07171540000004
                 ],
                 [
                    94.59941449999997,
                    50.071784200000046
                 ],
                 [
                    94.599452,
                    50.07200109999996
                 ],
                 [
                    94.59943590000005,
                    50.07207
                 ],
                 [
                    94.59938760000004,
                    50.07213539999997
                 ],
                 [
                    94.59929109999996,
                    50.072169799999976
                 ],
                 [
                    94.59907109999997,
                    50.072152599999974
                 ],
                 [
                    94.59899069999997,
                    50.07221120000003
                 ],
                 [
                    94.59896389999999,
                    50.0722869
                 ],
                 [
                    94.59906579999998,
                    50.07249
                 ],
                 [
                    94.59911939999996,
                    50.07268279999997
                 ],
                 [
                    94.59907649999997,
                    50.07282740000003
                 ],
                 [
                    94.59897989999997,
                    50.072958300000025
                 ],
                 [
                    94.59897459999998,
                    50.07303059999997
                 ],
                 [
                    94.59899069999997,
                    50.07310630000001
                 ],
                 [
                    94.59913009999997,
                    50.07322340000002
                 ],
                 [
                    94.59923740000005,
                    50.07335770000003
                 ],
                 [
                    94.59932329999997,
                    50.07364689999998
                 ],
                 [
                    94.59950569999995,
                    50.073912
                 ],
                 [
                    94.59944659999995,
                    50.07417020000002
                 ],
                 [
                    94.59937150000005,
                    50.074301
                 ],
                 [
                    94.59930180000005,
                    50.0743664
                 ],
                 [
                    94.5991248,
                    50.0744663
                 ],
                 [
                    94.59892089999998,
                    50.07453510000001
                 ],
                 [
                    94.59869029999999,
                    50.074590200000046
                 ],
                 [
                    94.5984542,
                    50.07462459999996
                 ],
                 [
                    94.598245,
                    50.07467280000004
                 ],
                 [
                    94.59776220000003,
                    50.07488630000003
                 ],
                 [
                    94.59771390000003,
                    50.07494830000001
                 ],
                 [
                    94.59768710000003,
                    50.07501710000002
                 ],
                 [
                    94.597773,
                    50.07522369999997
                 ],
                 [
                    94.59776760000003,
                    50.07536830000003
                 ],
                 [
                    94.59773,
                    50.07543710000004
                 ],
                 [
                    94.597671,
                    50.0754957
                 ],
                 [
                    94.59749940000005,
                    50.07559890000002
                 ],
                 [
                    94.59730089999996,
                    50.0756781
                 ],
                 [
                    94.59693609999998,
                    50.07588130000002
                 ],
                 [
                    94.59683419999999,
                    50.075915700000024
                 ],
                 [
                    94.596743,
                    50.075957
                 ],
                 [
                    94.59627630000001,
                    50.07604650000001
                 ],
                 [
                    94.59586320000003,
                    50.07614980000003
                 ],
                 [
                    94.59576670000003,
                    50.0761945
                 ],
                 [
                    94.59568620000003,
                    50.076246200000014
                 ],
                 [
                    94.595595,
                    50.07638390000004
                 ],
                 [
                    94.59551990000004,
                    50.076449300000036
                 ],
                 [
                    94.59535359999995,
                    50.0765423
                 ],
                 [
                    94.59515509999997,
                    50.076614600000035
                 ],
                 [
                    94.59506929999998,
                    50.07666620000004
                 ],
                 [
                    94.59499419999997,
                    50.07672129999998
                 ],
                 [
                    94.59480109999998,
                    50.0769072
                 ],
                 [
                    94.5947045,
                    50.076948500000036
                 ],
                 [
                    94.5945919,
                    50.0769726
                 ],
                 [
                    94.5943612,
                    50.07697950000003
                 ],
                 [
                    94.59425390000001,
                    50.07696570000004
                 ],
                 [
                    94.59414120000001,
                    50.0769347
                 ],
                 [
                    94.59405,
                    50.07689680000002
                 ],
                 [
                    94.59398570000002,
                    50.07683829999998
                 ],
                 [
                    94.59387840000002,
                    50.07678669999997
                 ],
                 [
                    94.59376040000004,
                    50.07676949999997
                 ],
                 [
                    94.59365310000004,
                    50.076779800000025
                 ],
                 [
                    94.59354580000004,
                    50.07680389999997
                 ],
                 [
                    94.59346,
                    50.07684870000004
                 ],
                 [
                    94.59334190000004,
                    50.0769726
                 ],
                 [
                    94.59314879999997,
                    50.07723769999998
                 ],
                 [
                    94.59306839999998,
                    50.07737880000003
                 ],
                 [
                    94.5929503,
                    50.07749590000003
                 ],
                 [
                    94.59285379999999,
                    50.077530300000035
                 ],
                 [
                    94.59262309999998,
                    50.07759230000001
                 ],
                 [
                    94.592194,
                    50.077678300000024
                 ],
                 [
                    94.59198470000001,
                    50.0777575
                 ],
                 [
                    94.59171120000003,
                    50.0779055
                 ],
                 [
                    94.59149120000004,
                    50.078167199999974
                 ],
                 [
                    94.59145370000005,
                    50.07823259999997
                 ],
                 [
                    94.59143760000005,
                    50.078377200000034
                 ],
                 [
                    94.59144830000004,
                    50.078446
                 ],
                 [
                    94.591561,
                    50.07862850000004
                 ],
                 [
                    94.59177020000003,
                    50.07880750000002
                 ],
                 [
                    94.59180770000002,
                    50.0788798
                 ],
                 [
                    94.59181840000002,
                    50.0789521
                 ],
                 [
                    94.59175940000003,
                    50.07901060000004
                 ],
                 [
                    94.59144290000003,
                    50.07900720000002
                 ],
                 [
                    94.59133559999995,
                    50.07899679999996
                 ],
                 [
                    94.59122839999996,
                    50.0789727
                 ],
                 [
                    94.59112639999996,
                    50.0789383
                 ],
                 [
                    94.59101379999997,
                    50.07892450000002
                 ],
                 [
                    94.59096009999998,
                    50.0789865
                 ],
                 [
                    94.59097089999997,
                    50.079206800000016
                 ],
                 [
                    94.5910031,
                    50.07927220000001
                 ],
                 [
                    94.59108889999997,
                    50.07932390000003
                 ],
                 [
                    94.5911962,
                    50.0793342
                 ],
                 [
                    94.591518,
                    50.079310100000036
                 ],
                 [
                    94.59163070000004,
                    50.07932730000004
                 ],
                 [
                    94.59171650000003,
                    50.07937550000003
                 ],
                 [
                    94.59178090000003,
                    50.079430599999974
                 ],
                 [
                    94.59180770000002,
                    50.07949939999998
                 ],
                 [
                    94.59178630000002,
                    50.07957170000001
                 ],
                 [
                    94.59168970000003,
                    50.079640600000026
                 ],
                 [
                    94.59158780000004,
                    50.07967160000002
                 ],
                 [
                    94.59136780000004,
                    50.07965780000003
                 ],
                 [
                    94.59114249999996,
                    50.07967160000002
                 ],
                 [
                    94.59078849999999,
                    50.0797507
                 ],
                 [
                    94.5903701,
                    50.07988149999998
                 ],
                 [
                    94.5902628,
                    50.0799022
                 ],
                 [
                    94.58993020000003,
                    50.0799366
                 ],
                 [
                    94.58974240000002,
                    50.080015799999984
                 ],
                 [
                    94.58898069999998,
                    50.08048740000002
                 ],
                 [
                    94.5887607,
                    50.080659500000046
                 ],
                 [
                    94.5887232,
                    50.08073179999999
                 ],
                 [
                    94.58868559999998,
                    50.080873
                 ],
                 [
                    94.5885676,
                    50.081131099999986
                 ],
                 [
                    94.58862659999998,
                    50.08134459999997
                 ],
                 [
                    94.5887232,
                    50.081471900000025
                 ],
                 [
                    94.58880359999998,
                    50.08152010000002
                 ],
                 [
                    94.588986,
                    50.081589
                 ],
                 [
                    94.58906649999997,
                    50.081637200000024
                 ],
                 [
                    94.589088,
                    50.081706
                 ],
                 [
                    94.58903969999997,
                    50.08177140000003
                 ],
                 [
                    94.5889538,
                    50.08182989999998
                 ],
                 [
                    94.58884659999998,
                    50.08185060000001
                 ],
                 [
                    94.5883906,
                    50.0818334
                 ],
                 [
                    94.58771470000003,
                    50.081778299999975
                 ],
                 [
                    94.587602,
                    50.08178520000001
                 ],
                 [
                    94.58749470000004,
                    50.08180929999997
                 ],
                 [
                    94.58740350000004,
                    50.08184709999998
                 ],
                 [
                    94.58728010000004,
                    50.0819676
                 ],
                 [
                    94.587248,
                    50.08203650000002
                 ],
                 [
                    94.58725330000004,
                    50.082108799999965
                 ],
                 [
                    94.58739280000005,
                    50.08222240000004
                 ],
                 [
                    94.587543,
                    50.08238760000003
                 ],
                 [
                    94.58756980000003,
                    50.0825976
                 ],
                 [
                    94.58752150000004,
                    50.082735300000024
                 ],
                 [
                    94.58746250000003,
                    50.082793799999976
                 ],
                 [
                    94.58737130000004,
                    50.082842
                 ],
                 [
                    94.5871729,
                    50.08291079999998
                 ],
                 [
                    94.58706019999997,
                    50.08293490000002
                 ],
                 [
                    94.58672219999998,
                    50.08293830000003
                 ],
                 [
                    94.58660419999998,
                    50.08295560000004
                 ],
                 [
                    94.58651839999999,
                    50.08300370000003
                 ],
                 [
                    94.5864004,
                    50.0831311
                 ],
                 [
                    94.5863682,
                    50.0832
                 ],
                 [
                    94.58611610000001,
                    50.0835958
                 ],
                 [
                    94.5860463,
                    50.083819500000025
                 ],
                 [
                    94.58593370000001,
                    50.08394
                 ],
                 [
                    94.58591220000001,
                    50.08408460000002
                 ],
                 [
                    94.58594980000002,
                    50.084301400000015
                 ],
                 [
                    94.5860624,
                    50.08451139999998
                 ],
                 [
                    94.58647009999999,
                    50.08498640000003
                 ],
                 [
                    94.58654519999999,
                    50.08504149999997
                 ],
                 [
                    94.58674909999998,
                    50.08509309999997
                 ],
                 [
                    94.58695829999998,
                    50.08508969999996
                 ],
                 [
                    94.58706559999997,
                    50.0851
                 ],
                 [
                    94.58711919999996,
                    50.0851585
                 ],
                 [
                    94.58703339999997,
                    50.085210199999985
                 ],
                 [
                    94.58693679999998,
                    50.08524109999996
                 ],
                 [
                    94.58672219999998,
                    50.0852824
                 ],
                 [
                    94.5865023,
                    50.08528930000004
                 ],
                 [
                    94.585939,
                    50.0852308
                 ],
                 [
                    94.585821,
                    50.0852308
                 ],
                 [
                    94.58571910000002,
                    50.08525490000004
                 ],
                 [
                    94.58563330000003,
                    50.08530310000003
                 ],
                 [
                    94.58550450000003,
                    50.08550270000003
                 ],
                 [
                    94.58543480000004,
                    50.08555779999997
                 ],
                 [
                    94.58516119999996,
                    50.08570240000003
                 ],
                 [
                    94.5850861,
                    50.08582629999998
                 ],
                 [
                    94.58509149999996,
                    50.085902
                 ],
                 [
                    94.58511289999996,
                    50.08597090000004
                 ],
                 [
                    94.58522019999995,
                    50.0860982
                 ],
                 [
                    94.58534360000004,
                    50.08631159999999
                 ],
                 [
                    94.58555280000003,
                    50.086487200000036
                 ],
                 [
                    94.58572450000003,
                    50.08659389999998
                 ],
                 [
                    94.58586930000001,
                    50.086710899999986
                 ],
                 [
                    94.58596590000002,
                    50.08683820000004
                 ],
                 [
                    94.5860678,
                    50.0870482
                 ],
                 [
                    94.586041,
                    50.087117
                 ],
                 [
                    94.58598730000001,
                    50.08718590000003
                 ],
                 [
                    94.58578880000002,
                    50.08726850000002
                 ],
                 [
                    94.58562250000003,
                    50.08737859999999
                 ],
                 [
                    94.58544019999995,
                    50.08746810000002
                 ],
                 [
                    94.58485539999997,
                    50.0874819
                 ],
                 [
                    94.584496,
                    50.08768840000004
                 ],
                 [
                    94.58440479999999,
                    50.0877262
                 ],
                 [
                    94.584083,
                    50.0878157
                 ],
                 [
                    94.583627,
                    50.087874200000044
                 ],
                 [
                    94.58353040000003,
                    50.08791209999998
                 ],
                 [
                    94.58346070000003,
                    50.087967200000016
                 ],
                 [
                    94.58332120000004,
                    50.08822529999996
                 ],
                 [
                    94.5832622,
                    50.08837330000004
                 ],
                 [
                    94.58324609999995,
                    50.088517900000014
                 ],
                 [
                    94.58313879999996,
                    50.0886487
                 ],
                 [
                    94.582994,
                    50.0887657
                 ],
                 [
                    94.58270429999997,
                    50.08888960000004
                 ],
                 [
                    94.5821679,
                    50.0889653
                 ],
                 [
                    94.5819747,
                    50.089041
                 ],
                 [
                    94.58194260000002,
                    50.08910989999997
                 ],
                 [
                    94.5819962,
                    50.08917180000003
                 ],
                 [
                    94.5820874,
                    50.08921309999998
                 ],
                 [
                    94.5822161,
                    50.08930949999996
                 ],
                 [
                    94.5823288,
                    50.0893336
                 ],
                 [
                    94.5824093,
                    50.08937829999997
                 ],
                 [
                    94.58247359999999,
                    50.089436800000016
                 ],
                 [
                    94.5824844,
                    50.08950570000003
                 ],
                 [
                    94.582479,
                    50.08964679999998
                 ],
                 [
                    94.5824414,
                    50.0897156
                 ],
                 [
                    94.5823663,
                    50.089770700000024
                 ],
                 [
                    94.582184,
                    50.08995650000003
                 ],
                 [
                    94.5821142,
                    50.09001159999997
                 ],
                 [
                    94.58202840000001,
                    50.09005630000003
                 ],
                 [
                    94.5819318,
                    50.090087300000015
                 ],
                 [
                    94.58169580000002,
                    50.09009759999998
                 ],
                 [
                    94.581154,
                    50.090190600000035
                 ],
                 [
                    94.58100379999996,
                    50.090125200000045
                 ],
                 [
                    94.580875,
                    50.09001159999997
                 ],
                 [
                    94.58078379999998,
                    50.08997030000002
                 ],
                 [
                    94.58056389999999,
                    50.089935900000015
                 ],
                 [
                    94.5804566,
                    50.0899497
                 ],
                 [
                    94.58038149999999,
                    50.09000470000002
                 ],
                 [
                    94.5803279,
                    50.0900667
                 ],
                 [
                    94.5803225,
                    50.0902801
                 ],
                 [
                    94.5802098,
                    50.090276600000045
                 ],
                 [
                    94.5801401,
                    50.09022150000002
                 ],
                 [
                    94.5801294,
                    50.09015620000003
                 ],
                 [
                    94.58002750000001,
                    50.08995650000003
                 ],
                 [
                    94.57996840000001,
                    50.08975
                 ],
                 [
                    94.57992550000002,
                    50.0896846
                 ],
                 [
                    94.57984510000001,
                    50.089633
                 ],
                 [
                    94.57974310000002,
                    50.089608900000044
                 ],
                 [
                    94.57965190000002,
                    50.089660499999965
                 ],
                 [
                    94.57953390000003,
                    50.089787900000026
                 ],
                 [
                    94.57943740000003,
                    50.089922100000024
                 ],
                 [
                    94.57936760000004,
                    50.09005979999996
                 ],
                 [
                    94.57934620000005,
                    50.09012859999997
                 ],
                 [
                    94.57934620000005,
                    50.090276600000045
                 ],
                 [
                    94.57935690000004,
                    50.0903523
                 ],
                 [
                    94.57941050000004,
                    50.09049689999998
                 ],
                 [
                    94.57952320000003,
                    50.0906345
                 ],
                 [
                    94.57982360000001,
                    50.0908514
                 ],
                 [
                    94.57995240000001,
                    50.090978699999965
                 ],
                 [
                    94.5799953,
                    50.09105790000003
                 ],
                 [
                    94.5801079,
                    50.09142270000002
                 ],
                 [
                    94.5801026,
                    50.09149839999998
                 ],
                 [
                    94.5800918,
                    50.091567199999986
                 ],
                 [
                    94.5800489,
                    50.091632599999976
                 ],
                 [
                    94.580006,
                    50.091698
                 ],
                 [
                    94.5799309,
                    50.09175650000002
                 ],
                 [
                    94.57981820000002,
                    50.09177370000002
                 ],
                 [
                    94.57970560000003,
                    50.0917531
                 ],
                 [
                    94.57929790000004,
                    50.09162570000003
                 ],
                 [
                    94.57908869999996,
                    50.09157760000004
                 ],
                 [
                    94.57874539999997,
                    50.0915363
                 ],
                 [
                    94.5781714,
                    50.091491500000025
                 ],
                 [
                    94.5780533,
                    50.091491500000025
                 ],
                 [
                    94.57779590000003,
                    50.09152250000002
                 ],
                 [
                    94.57758660000002,
                    50.09160510000001
                 ],
                 [
                    94.57733450000003,
                    50.09176339999996
                 ],
                 [
                    94.57728090000005,
                    50.09182540000003
                 ],
                 [
                    94.57724870000004,
                    50.09189759999997
                 ],
                 [
                    94.57724330000003,
                    50.0919699
                 ],
                 [
                    94.5770502,
                    50.0921557
                 ],
                 [
                    94.57700729999996,
                    50.09222460000002
                 ],
                 [
                    94.57699119999997,
                    50.09230029999998
                 ],
                 [
                    94.5769858,
                    50.09237940000004
                 ],
                 [
                    94.57701269999997,
                    50.092451699999984
                 ],
                 [
                    94.577077,
                    50.09251369999996
                 ],
                 [
                    94.57743110000004,
                    50.0927064
                 ],
                 [
                    94.57757590000003,
                    50.09281649999997
                 ],
                 [
                    94.57761350000003,
                    50.0928888
                 ],
                 [
                    94.57757060000003,
                    50.09296450000004
                 ],
                 [
                    94.57749550000004,
                    50.093016099999964
                 ],
                 [
                    94.57738820000004,
                    50.09301270000004
                 ],
                 [
                    94.57708239999997,
                    50.09293010000004
                 ],
                 [
                    94.5769858,
                    50.092895700000035
                 ],
                 [
                    94.57683029999997,
                    50.092792400000015
                 ],
                 [
                    94.576723,
                    50.09277870000004
                 ],
                 [
                    94.5764816,
                    50.0928062
                 ],
                 [
                    94.5763689,
                    50.092789
                 ],
                 [
                    94.5762831,
                    50.092744300000035
                 ],
                 [
                    94.5762134,
                    50.092682299999964
                 ],
                 [
                    94.5761651,
                    50.09254460000003
                 ],
                 [
                    94.5761973,
                    50.09241040000003
                 ],
                 [
                    94.5762348,
                    50.09234160000002
                 ],
                 [
                    94.5762885,
                    50.092283099999975
                 ],
                 [
                    94.5763099,
                    50.092214299999966
                 ],
                 [
                    94.576267,
                    50.09214889999998
                 ],
                 [
                    94.5761973,
                    50.092093800000036
                 ],
                 [
                    94.5761007,
                    50.0920559
                 ],
                 [
                    94.57589690000002,
                    50.09199739999996
                 ],
                 [
                    94.57578420000002,
                    50.0920043
                 ],
                 [
                    94.57562330000003,
                    50.0920835
                 ],
                 [
                    94.57554820000003,
                    50.092142
                 ],
                 [
                    94.57548380000003,
                    50.092217699999985
                 ],
                 [
                    94.57531210000003,
                    50.0924896
                 ],
                 [
                    94.57516730000005,
                    50.092603099999984
                 ],
                 [
                    94.57496879999997,
                    50.092672
                 ],
                 [
                    94.5742875,
                    50.092730500000044
                 ],
                 [
                    94.5740783,
                    50.09277520000001
                 ],
                 [
                    94.573912,
                    50.092875
                 ],
                 [
                    94.57381010000002,
                    50.0929198
                 ],
                 [
                    94.57350430000002,
                    50.093019599999984
                 ],
                 [
                    94.57320390000004,
                    50.09313659999999
                 ],
                 [
                    94.57311269999995,
                    50.0931882
                 ],
                 [
                    94.57297320000005,
                    50.09330179999998
                 ],
                 [
                    94.57297320000005,
                    50.093374
                 ],
                 [
                    94.572925,
                    50.093436
                 ],
                 [
                    94.57281769999997,
                    50.093460100000016
                 ],
                 [
                    94.57266209999997,
                    50.0933568
                 ],
                 [
                    94.5725656,
                    50.093319
                 ],
                 [
                    94.5724583,
                    50.093294900000025
                 ],
                 [
                    94.5722276,
                    50.09328110000004
                 ],
                 [
                    94.5718735,
                    50.093284599999976
                 ],
                 [
                    94.57132640000003,
                    50.093315500000045
                 ],
                 [
                    94.57121370000004,
                    50.09336370000003
                 ],
                 [
                    94.57112789999995,
                    50.09350139999997
                 ],
                 [
                    94.57103130000004,
                    50.09371130000002
                 ],
                 [
                    94.57093479999996,
                    50.093849
                 ],
                 [
                    94.57088109999997,
                    50.09398659999997
                 ],
                 [
                    94.57083279999996,
                    50.094052
                 ],
                 [
                    94.57068259999997,
                    50.09416560000004
                 ],
                 [
                    94.57049489999999,
                    50.094255099999984
                 ],
                 [
                    94.57039829999998,
                    50.094286
                 ],
                 [
                    94.5703179,
                    50.0943411
                 ],
                 [
                    94.57027489999999,
                    50.0944099
                 ],
                 [
                    94.57026959999999,
                    50.094485599999956
                 ],
                 [
                    94.5701998,
                    50.0946611
                 ],
                 [
                    94.5699906,
                    50.094836699999966
                 ],
                 [
                    94.569937,
                    50.094902
                 ],
                 [
                    94.5699263,
                    50.09505
                 ],
                 [
                    94.5699584,
                    50.09511540000002
                 ],
                 [
                    94.5700657,
                    50.09512229999997
                 ],
                 [
                    94.570173,
                    50.09510509999997
                 ],
                 [
                    94.57027489999999,
                    50.095081
                 ],
                 [
                    94.57049489999999,
                    50.09506380000001
                 ],
                 [
                    94.57060219999998,
                    50.09509129999998
                 ],
                 [
                    94.57065579999997,
                    50.09514980000002
                 ],
                 [
                    94.57065049999999,
                    50.095222099999965
                 ],
                 [
                    94.57057539999998,
                    50.0952771
                 ],
                 [
                    94.5703286,
                    50.095418200000026
                 ],
                 [
                    94.5701677,
                    50.09553180000001
                 ],
                 [
                    94.56998530000001,
                    50.09561780000002
                 ],
                 [
                    94.56962580000001,
                    50.095734800000024
                 ],
                 [
                    94.56953470000003,
                    50.095793299999976
                 ],
                 [
                    94.56923420000004,
                    50.095903500000034
                 ],
                 [
                    94.56915910000004,
                    50.09595510000004
                 ],
                 [
                    94.56913770000004,
                    50.09602389999996
                 ],
                 [
                    94.569186,
                    50.09616159999999
                 ],
                 [
                    94.56945960000003,
                    50.096488500000035
                 ],
                 [
                    94.56949710000002,
                    50.09662609999996
                 ],
                 [
                    94.56949170000003,
                    50.0967018
                 ],
                 [
                    94.56940590000004,
                    50.0968326
                 ],
                 [
                    94.56940590000004,
                    50.09690490000003
                 ],
                 [
                    94.56947030000003,
                    50.097039100000025
                 ],
                 [
                    94.56952390000002,
                    50.09710450000002
                 ],
                 [
                    94.56953470000003,
                    50.09725590000002
                 ],
                 [
                    94.56951320000003,
                    50.09732470000003
                 ],
                 [
                    94.56942740000004,
                    50.097372900000025
                 ],
                 [
                    94.56932010000004,
                    50.09739350000004
                 ],
                 [
                    94.56922890000004,
                    50.097445100000044
                 ],
                 [
                    94.569186,
                    50.09751050000004
                 ],
                 [
                    94.56915379999995,
                    50.09770670000002
                 ],
                 [
                    94.56911089999996,
                    50.097775500000026
                 ],
                 [
                    94.56896069999996,
                    50.0978856
                 ],
                 [
                    94.5687461,
                    50.097951
                 ],
                 [
                    94.56863879999997,
                    50.09796130000004
                 ],
                 [
                    94.56853149999998,
                    50.0979544
                 ],
                 [
                    94.56842419999998,
                    50.09793379999998
                 ],
                 [
                    94.5681989,
                    50.097930300000044
                 ],
                 [
                    94.5681131,
                    50.09797850000004
                 ],
                 [
                    94.5680004,
                    50.09810239999999
                 ],
                 [
                    94.5679468,
                    50.09824690000004
                 ],
                 [
                    94.5679414,
                    50.0983226
                 ],
                 [
                    94.5680326,
                    50.0985394
                 ],
                 [
                    94.568274,
                    50.0988354
                 ],
                 [
                    94.56852079999999,
                    50.09906939999997
                 ],
                 [
                    94.56854759999997,
                    50.09913819999998
                 ],
                 [
                    94.56847249999998,
                    50.09919670000002
                 ],
                 [
                    94.5679146,
                    50.0992552
                 ],
                 [
                    94.56769470000002,
                    50.09921730000004
                 ],
                 [
                    94.56739430000003,
                    50.09912790000001
                 ],
                 [
                    94.567287,
                    50.09911750000004
                 ],
                 [
                    94.56719040000004,
                    50.099165700000036
                 ],
                 [
                    94.56715820000004,
                    50.099234500000044
                 ],
                 [
                    94.56715289999995,
                    50.0993033
                 ],
                 [
                    94.56710459999995,
                    50.09952359999998
                 ],
                 [
                    94.56697579999995,
                    50.0997404
                 ],
                 [
                    94.56687929999997,
                    50.0997782
                 ],
                 [
                    94.56678269999996,
                    50.099736900000046
                 ],
                 [
                    94.56674519999997,
                    50.099671499999964
                 ],
                 [
                    94.56673979999997,
                    50.09960270000004
                 ],
                 [
                    94.56670219999997,
                    50.09953729999996
                 ],
                 [
                    94.56660029999998,
                    50.09949950000003
                 ],
                 [
                    94.56647689999998,
                    50.09951670000003
                 ],
                 [
                    94.56638039999999,
                    50.099558
                 ],
                 [
                    94.56634279999999,
                    50.0996268
                 ],
                 [
                    94.56635889999998,
                    50.09995709999997
                 ],
                 [
                    94.5662999,
                    50.10001910000003
                 ],
                 [
                    94.5661122,
                    50.10010849999997
                 ],
                 [
                    94.5660263,
                    50.10016019999998
                 ],
                 [
                    94.5659512,
                    50.10030120000002
                 ],
                 [
                    94.5658654,
                    50.1003494
                 ],
                 [
                    94.56576880000001,
                    50.1003804
                 ],
                 [
                    94.56565080000001,
                    50.10038380000001
                 ],
                 [
                    94.56557030000002,
                    50.1003322
                 ],
                 [
                    94.56554890000002,
                    50.1002634
                 ],
                 [
                    94.56545230000002,
                    50.10022549999997
                 ],
                 [
                    94.56533970000004,
                    50.1002152
                 ],
                 [
                    94.56524310000003,
                    50.10025310000003
                 ],
                 [
                    94.56523780000003,
                    50.10032190000004
                 ],
                 [
                    94.56528070000003,
                    50.10038730000004
                 ],
                 [
                    94.565345,
                    50.10044579999999
                 ],
                 [
                    94.56553820000002,
                    50.1005146
                 ],
                 [
                    94.565565,
                    50.10058680000002
                 ],
                 [
                    94.56554890000002,
                    50.100659099999966
                 ],
                 [
                    94.56536110000003,
                    50.10088620000001
                 ],
                 [
                    94.56520560000004,
                    50.10099289999996
                 ],
                 [
                    94.56506609999995,
                    50.10110640000003
                 ],
                 [
                    94.56496950000005,
                    50.101144299999966
                 ],
                 [
                    94.56486219999996,
                    50.101158
                 ],
                 [
                    94.56464229999997,
                    50.101127099999964
                 ],
                 [
                    94.5645297,
                    50.10113740000001
                 ],
                 [
                    94.56443309999999,
                    50.101175200000036
                 ],
                 [
                    94.56435259999998,
                    50.101237200000014
                 ],
                 [
                    94.56430969999998,
                    50.1013026
                 ],
                 [
                    94.56430429999999,
                    50.101381699999976
                 ],
                 [
                    94.56434189999999,
                    50.10152620000003
                 ],
                 [
                    94.56429359999998,
                    50.10166729999998
                 ],
                 [
                    94.5641649,
                    50.10179120000002
                 ],
                 [
                    94.5638591,
                    50.10192880000004
                 ],
                 [
                    94.56344070000003,
                    50.1021903
                 ],
                 [
                    94.56316710000004,
                    50.10233140000004
                 ],
                 [
                    94.56302229999996,
                    50.10244840000004
                 ],
                 [
                    94.562974,
                    50.10251030000001
                 ],
                 [
                    94.56295249999995,
                    50.10257910000002
                 ],
                 [
                    94.562872,
                    50.10263070000003
                 ],
                 [
                    94.56277549999997,
                    50.10266510000003
                 ],
                 [
                    94.56229269999999,
                    50.102734
                 ],
                 [
                    94.56207269999999,
                    50.10274770000002
                 ],
                 [
                    94.561488,
                    50.10275119999996
                 ],
                 [
                    94.56125740000003,
                    50.102734
                 ],
                 [
                    94.56104810000004,
                    50.10268230000003
                 ],
                 [
                    94.56094090000005,
                    50.102672
                 ],
                 [
                    94.5608282,
                    50.10268230000003
                 ],
                 [
                    94.56076919999997,
                    50.102740799999985
                 ],
                 [
                    94.5607263,
                    50.10288539999996
                 ],
                 [
                    94.56077459999997,
                    50.1030264
                 ],
                 [
                    94.56088180000005,
                    50.10315370000004
                 ],
                 [
                    94.56105890000005,
                    50.103239799999976
                 ],
                 [
                    94.56128420000003,
                    50.10326039999999
                 ],
                 [
                    94.56138070000003,
                    50.10328450000003
                 ],
                 [
                    94.56167580000002,
                    50.1034118
                 ],
                 [
                    94.561767,
                    50.103542499999975
                 ],
                 [
                    94.56178840000001,
                    50.10361820000002
                 ],
                 [
                    94.561649,
                    50.103738700000044
                 ],
                 [
                    94.56152560000002,
                    50.103862499999984
                 ],
                 [
                    94.56147730000002,
                    50.103927899999974
                 ],
                 [
                    94.56143970000002,
                    50.104069
                 ],
                 [
                    94.56139680000003,
                    50.104137800000025
                 ],
                 [
                    94.56128950000003,
                    50.1041653
                 ],
                 [
                    94.56118760000004,
                    50.104141200000036
                 ],
                 [
                    94.56094620000005,
                    50.103983
                 ],
                 [
                    94.56086579999996,
                    50.10393480000001
                 ],
                 [
                    94.5607263,
                    50.10382469999996
                 ],
                 [
                    94.56060289999998,
                    50.10370430000004
                 ],
                 [
                    94.5602381,
                    50.10343240000001
                 ],
                 [
                    94.560045,
                    50.10336710000003
                 ],
                 [
                    94.5599323,
                    50.1033602
                 ],
                 [
                    94.55982510000001,
                    50.10348750000004
                 ],
                 [
                    94.5598358,
                    50.10355629999996
                 ],
                 [
                    94.5600021,
                    50.1036492
                 ],
                 [
                    94.5600772,
                    50.10370430000004
                 ],
                 [
                    94.56011469999999,
                    50.10376960000002
                 ],
                 [
                    94.5598197,
                    50.10401049999997
                 ],
                 [
                    94.5596856,
                    50.10421349999998
                 ],
                 [
                    94.55956220000002,
                    50.10433730000001
                 ],
                 [
                    94.55936910000003,
                    50.1044199
                 ],
                 [
                    94.55926180000003,
                    50.1044474
                 ],
                 [
                    94.55915450000003,
                    50.104475
                 ],
                 [
                    94.55893459999996,
                    50.10449220000004
                 ],
                 [
                    94.55883260000004,
                    50.10452660000004
                 ],
                 [
                    94.55875219999997,
                    50.104578199999956
                 ],
                 [
                    94.5586502,
                    50.1047055
                 ],
                 [
                    94.55858589999997,
                    50.10476739999998
                 ],
                 [
                    94.55848929999998,
                    50.104812199999955
                 ],
                 [
                    94.55826939999999,
                    50.10483619999999
                 ],
                 [
                    94.5579529,
                    50.10491190000003
                 ],
                 [
                    94.557867,
                    50.10496010000003
                 ],
                 [
                    94.5578188,
                    50.105022
                 ],
                 [
                    94.55782950000001,
                    50.105090900000015
                 ],
                 [
                    94.55825859999999,
                    50.1053592
                 ],
                 [
                    94.55834979999997,
                    50.105496800000026
                 ],
                 [
                    94.55836589999998,
                    50.1055725
                 ],
                 [
                    94.55836589999998,
                    50.105644800000015
                 ],
                 [
                    94.55832839999998,
                    50.105717
                 ],
                 [
                    94.55822109999998,
                    50.10584089999998
                 ],
                 [
                    94.5581728,
                    50.105982
                 ],
                 [
                    94.5580924,
                    50.10604049999998
                 ],
                 [
                    94.5578188,
                    50.10618839999996
                 ],
                 [
                    94.5577758,
                    50.10632259999996
                 ],
                 [
                    94.5578617,
                    50.10645330000003
                 ],
                 [
                    94.5579636,
                    50.106577199999975
                 ],
                 [
                    94.5580387,
                    50.10662879999998
                 ],
                 [
                    94.5581299,
                    50.10667350000004
                 ],
                 [
                    94.5582372,
                    50.10669419999998
                 ],
                 [
                    94.55841959999998,
                    50.106621900000036
                 ],
                 [
                    94.55861809999998,
                    50.10656690000001
                 ],
                 [
                    94.55905790000004,
                    50.1065325
                 ],
                 [
                    94.55915990000004,
                    50.10653930000004
                 ],
                 [
                    94.55924570000003,
                    50.10658750000003
                 ],
                 [
                    94.55938520000002,
                    50.10677669999996
                 ],
                 [
                    94.55940660000002,
                    50.1068524
                 ],
                 [
                    94.55940130000003,
                    50.10692809999997
                 ],
                 [
                    94.55936910000003,
                    50.10699689999998
                 ],
                 [
                    94.55932080000002,
                    50.10705890000004
                 ],
                 [
                    94.55929930000002,
                    50.107127699999964
                 ],
                 [
                    94.55930470000003,
                    50.1072687
                 ],
                 [
                    94.55928330000003,
                    50.107341
                 ],
                 [
                    94.55920810000003,
                    50.10739260000003
                 ],
                 [
                    94.55909010000003,
                    50.1074063
                 ],
                 [
                    94.55897210000005,
                    50.107382299999976
                 ],
                 [
                    94.55888629999995,
                    50.10733409999999
                 ],
                 [
                    94.55877359999997,
                    50.10731349999997
                 ],
                 [
                    94.5586663,
                    50.107327200000036
                 ],
                 [
                    94.55856439999997,
                    50.10735820000003
                 ],
                 [
                    94.5584732,
                    50.1074029
                 ],
                 [
                    94.5583016,
                    50.1075061
                 ],
                 [
                    94.5581889,
                    50.1075371
                 ],
                 [
                    94.5579636,
                    50.107561200000035
                 ],
                 [
                    94.5578563,
                    50.10758179999996
                 ],
                 [
                    94.55775980000001,
                    50.10763
                 ],
                 [
                    94.55764710000001,
                    50.107729700000036
                 ],
                 [
                    94.55760420000001,
                    50.107795100000025
                 ],
                 [
                    94.55736820000003,
                    50.10805319999998
                 ],
                 [
                    94.55726620000003,
                    50.10818390000004
                 ],
                 [
                    94.55726090000003,
                    50.108252699999966
                 ],
                 [
                    94.55728230000003,
                    50.108321499999974
                 ],
                 [
                    94.55733060000003,
                    50.10838340000003
                 ],
                 [
                    94.55740570000002,
                    50.1084385
                 ],
                 [
                    94.55757740000001,
                    50.10853140000002
                 ],
                 [
                    94.55776510000001,
                    50.1086105
                 ],
                 [
                    94.55818349999998,
                    50.10872060000004
                 ],
                 [
                    94.55833369999998,
                    50.10882719999998
                 ],
                 [
                    94.5584571,
                    50.109026799999974
                 ],
                 [
                    94.55880049999996,
                    50.10920220000001
                 ],
                 [
                    94.55884339999996,
                    50.1093192
                 ],
                 [
                    94.55875749999996,
                    50.1093674
                 ],
                 [
                    94.55868239999997,
                    50.109419
                 ],
                 [
                    94.55858049999998,
                    50.10944989999999
                 ],
                 [
                    94.55846249999998,
                    50.10946709999999
                 ],
                 [
                    94.5581299,
                    50.10947749999996
                 ],
                 [
                    94.5580226,
                    50.109456800000025
                 ],
                 [
                    94.5579261,
                    50.10941549999998
                 ],
                 [
                    94.5578241,
                    50.10929509999997
                 ],
                 [
                    94.55767390000001,
                    50.109185
                 ],
                 [
                    94.55752910000002,
                    50.1089924
                 ],
                 [
                    94.55747010000002,
                    50.10893390000002
                 ],
                 [
                    94.55735740000003,
                    50.1089064
                 ],
                 [
                    94.55724480000003,
                    50.108899500000014
                 ],
                 [
                    94.55691750000004,
                    50.10897519999997
                 ],
                 [
                    94.55662249999997,
                    50.1090784
                 ],
                 [
                    94.55654739999997,
                    50.1091334
                 ],
                 [
                    94.55650449999997,
                    50.1091988
                 ],
                 [
                    94.55647229999998,
                    50.109271
                 ],
                 [
                    94.55649909999997,
                    50.10934329999996
                 ],
                 [
                    94.556601,
                    50.1093674
                 ],
                 [
                    94.55682629999995,
                    50.1093846
                 ],
                 [
                    94.55702480000004,
                    50.109425899999955
                 ],
                 [
                    94.55729840000002,
                    50.109539400000024
                 ],
                 [
                    94.55754520000002,
                    50.109690800000024
                 ],
                 [
                    94.55764710000001,
                    50.1098181
                 ],
                 [
                    94.55767930000002,
                    50.1098869
                 ],
                 [
                    94.55769540000001,
                    50.1100382
                 ],
                 [
                    94.55769,
                    50.110107
                 ],
                 [
                    94.55760960000002,
                    50.11016550000004
                 ],
                 [
                    94.55750760000002,
                    50.110196500000036
                 ],
                 [
                    94.55740030000003,
                    50.1102068
                 ],
                 [
                    94.55730910000003,
                    50.11016210000003
                 ],
                 [
                    94.55711070000004,
                    50.109983199999974
                 ],
                 [
                    94.55702480000004,
                    50.1099385
                 ],
                 [
                    94.55692290000005,
                    50.1099041
                 ],
                 [
                    94.55681029999997,
                    50.10989030000001
                 ],
                 [
                    94.55655809999998,
                    50.10989030000001
                 ],
                 [
                    94.55638109999998,
                    50.109983199999974
                 ],
                 [
                    94.55633819999998,
                    50.11004859999996
                 ],
                 [
                    94.55634889999997,
                    50.11011739999997
                 ],
                 [
                    94.55641329999997,
                    50.110179300000034
                 ],
                 [
                    94.55650979999997,
                    50.1102068
                 ],
                 [
                    94.55658489999996,
                    50.110255
                 ],
                 [
                    94.5566118,
                    50.110327200000015
                 ],
                 [
                    94.556601,
                    50.11047169999998
                 ],
                 [
                    94.55655809999998,
                    50.110544
                 ],
                 [
                    94.55646689999998,
                    50.11067119999996
                 ],
                 [
                    94.55645619999997,
                    50.11081570000002
                 ],
                 [
                    94.55625769999999,
                    50.111146
                 ],
                 [
                    94.5560431,
                    50.11140060000001
                 ],
                 [
                    94.5560324,
                    50.11168950000002
                 ],
                 [
                    94.5560056,
                    50.11197510000002
                 ],
                 [
                    94.556027,
                    50.11204390000003
                 ],
                 [
                    94.55607529999999,
                    50.11211270000004
                 ],
                 [
                    94.5561612,
                    50.1121505
                 ],
                 [
                    94.55631139999998,
                    50.112264
                 ],
                 [
                    94.55635429999998,
                    50.11240169999997
                 ],
                 [
                    94.5562524,
                    50.11243260000004
                 ],
                 [
                    94.5560217,
                    50.11243260000004
                 ],
                 [
                    94.555909,
                    50.11244980000004
                 ],
                 [
                    94.555614,
                    50.11258050000002
                 ],
                 [
                    94.555276,
                    50.11262179999998
                 ],
                 [
                    94.55505610000004,
                    50.11268030000002
                 ],
                 [
                    94.55480930000004,
                    50.11273530000004
                 ],
                 [
                    94.55475569999996,
                    50.11279730000002
                 ],
                 [
                    94.554745,
                    50.112866100000026
                 ],
                 [
                    94.55468059999997,
                    50.112972699999965
                 ],
                 [
                    94.55448749999998,
                    50.1130621
                 ],
                 [
                    94.55443919999998,
                    50.11312749999998
                 ],
                 [
                    94.55448749999998,
                    50.113189400000046
                 ],
                 [
                    94.55468059999997,
                    50.1132479
                 ],
                 [
                    94.55473959999996,
                    50.11330640000004
                 ],
                 [
                    94.55477179999997,
                    50.11337180000004
                 ],
                 [
                    94.55477179999997,
                    50.113444
                 ],
                 [
                    94.554745,
                    50.11351279999998
                 ],
                 [
                    94.55468059999997,
                    50.113574700000036
                 ],
                 [
                    94.55450359999998,
                    50.11367450000004
                 ],
                 [
                    94.55430509999998,
                    50.11374669999997
                 ],
                 [
                    94.55419779999998,
                    50.11377769999996
                 ],
                 [
                    94.5540798,
                    50.11377769999996
                 ],
                 [
                    94.5539725,
                    50.113760499999955
                 ],
                 [
                    94.5538598,
                    50.113757
                 ],
                 [
                    94.55362920000002,
                    50.113791400000025
                 ],
                 [
                    94.55339850000003,
                    50.1138018
                 ],
                 [
                    94.55316780000003,
                    50.113791400000025
                 ],
                 [
                    94.55306050000003,
                    50.113763899999974
                 ],
                 [
                    94.5529747,
                    50.11371229999997
                 ],
                 [
                    94.55294250000004,
                    50.113643500000045
                 ],
                 [
                    94.55269569999996,
                    50.11340959999997
                 ],
                 [
                    94.55261529999997,
                    50.113361399999974
                 ],
                 [
                    94.55251339999998,
                    50.113337400000034
                 ],
                 [
                    94.55180530000001,
                    50.1133305
                 ],
                 [
                    94.55112930000003,
                    50.1132995
                 ],
                 [
                    94.55056069999996,
                    50.11330979999997
                 ],
                 [
                    94.55013689999998,
                    50.11339579999998
                 ],
                 [
                    94.5500243,
                    50.11340959999997
                 ],
                 [
                    94.5498204,
                    50.113471500000024
                 ],
                 [
                    94.5497292,
                    50.11351970000002
                 ],
                 [
                    94.54967560000001,
                    50.113585
                 ],
                 [
                    94.5496595,
                    50.1136538
                 ],
                 [
                    94.5496809,
                    50.11372260000002
                 ],
                 [
                    94.5497507,
                    50.11377420000002
                 ],
                 [
                    94.54993839999999,
                    50.1138534
                 ],
                 [
                    94.5500189,
                    50.1139015
                 ],
                 [
                    94.550094,
                    50.1139531
                 ],
                 [
                    94.550153,
                    50.114018499999986
                 ],
                 [
                    94.5501907,
                    50.11408929999998
                 ],
                 [
                    94.5502032,
                    50.11423289999996
                 ],
                 [
                    94.55018160000004,
                    50.11427450000003
                 ],
                 [
                    94.55014180000003,
                    50.1143095
                 ],
                 [
                    94.54997579999998,
                    50.114362700000044
                 ],
                 [
                    94.54976309999996,
                    50.1144181
                 ],
                 [
                    94.54971540000001,
                    50.11445680000001
                 ],
                 [
                    94.54971420000001,
                    50.11458
                 ],
                 [
                    94.54973920000003,
                    50.114719299999976
                 ],
                 [
                    94.54981320000003,
                    50.11490519999999
                 ],
                 [
                    94.54989050000003,
                    50.115067800000034
                 ],
                 [
                    94.54989729999997,
                    50.115194
                 ],
                 [
                    94.54987230000003,
                    50.115305499999984
                 ],
                 [
                    94.54976309999996,
                    50.115377
                 ],
                 [
                    94.54962550000003,
                    50.11540180000004
                 ],
                 [
                    94.54952320000001,
                    50.115378399999976
                 ],
                 [
                    94.54943789999997,
                    50.115301099999975
                 ],
                 [
                    94.54937540000005,
                    50.11525450000003
                 ],
                 [
                    94.54929240000001,
                    50.11524429999998
                 ],
                 [
                    94.54920939999998,
                    50.115260299999974
                 ],
                 [
                    94.5491537,
                    50.11529750000003
                 ],
                 [
                    94.54914909999997,
                    50.115397400000035
                 ],
                 [
                    94.5491314,
                    50.1154722
                 ],
                 [
                    94.5489615,
                    50.11567970000004
                 ],
                 [
                    94.548916,
                    50.11584370000001
                 ],
                 [
                    94.5489263,
                    50.1159764
                 ],
                 [
                    94.54898990000002,
                    50.11605660000004
                 ],
                 [
                    94.54904910000005,
                    50.116088
                 ],
                 [
                    94.54910480000004,
                    50.11608650000003
                 ],
                 [
                    94.54916050000003,
                    50.11605440000004
                 ],
                 [
                    94.54931740000004,
                    50.115948
                 ],
                 [
                    94.54947659999998,
                    50.115884500000014
                 ],
                 [
                    94.54960050000001,
                    50.115867
                 ],
                 [
                    94.54970060000004,
                    50.115877200000035
                 ],
                 [
                    94.5498052,
                    50.11592029999996
                 ],
                 [
                    94.54983480000004,
                    50.115988099999974
                 ],
                 [
                    94.54982,
                    50.116055899999985
                 ],
                 [
                    94.54974609999998,
                    50.116125200000035
                 ],
                 [
                    94.54963009999997,
                    50.116335899999974
                 ],
                 [
                    94.54957329999998,
                    50.11643430000003
                 ],
                 [
                    94.54938,
                    50.11649340000004
                 ],
                 [
                    94.54922419999997,
                    50.11649770000004
                 ],
                 [
                    94.5490627,
                    50.1164598
                 ],
                 [
                    94.54894260000002,
                    50.11646689999998
                 ],
                 [
                    94.5486354,
                    50.1165711
                 ],
                 [
                    94.54837490000004,
                    50.11666280000002
                 ],
                 [
                    94.54814720000003,
                    50.1166745
                 ],
                 [
                    94.54789370000002,
                    50.116640899999986
                 ],
                 [
                    94.5477163,
                    50.11651189999997
                 ],
                 [
                    94.54768220000001,
                    50.11638720000004
                 ],
                 [
                    94.54772650000004,
                    50.11623919999997
                 ],
                 [
                    94.54770490000003,
                    50.116193299999985
                 ],
                 [
                    94.54763329999996,
                    50.116151
                 ],
                 [
                    94.54755819999997,
                    50.1161408
                 ],
                 [
                    94.5475059,
                    50.11616040000002
                 ],
                 [
                    94.54743320000001,
                    50.11627349999996
                 ],
                 [
                    94.54728310000003,
                    50.11644549999998
                 ],
                 [
                    94.5471625,
                    50.116533
                 ],
                 [
                    94.54701360000003,
                    50.11656659999996
                 ],
                 [
                    94.54691229999999,
                    50.116597300000016
                 ],
                 [
                    94.54675629999996,
                    50.11669009999997
                 ],
                 [
                    94.54658750000003,
                    50.1167313
                 ],
                 [
                    94.54638809999996,
                    50.116716899999965
                 ],
                 [
                    94.54619030000002,
                    50.11667770000001
                 ],
                 [
                    94.5460858,
                    50.116675600000015
                 ],
                 [
                    94.5460214,
                    50.11671379999998
                 ],
                 [
                    94.54600379999997,
                    50.11679009999998
                 ],
                 [
                    94.54601980000004,
                    50.11684469999997
                 ],
                 [
                    94.54612920000004,
                    50.1169241
                 ],
                 [
                    94.54629640000002,
                    50.11710869999997
                 ],
                 [
                    94.54645240000004,
                    50.11729119999997
                 ],
                 [
                    94.54647810000003,
                    50.117418
                 ],
                 [
                    94.5464283,
                    50.117512900000015
                 ],
                 [
                    94.5462948,
                    50.11762739999998
                 ],
                 [
                    94.54605679999999,
                    50.11771910000002
                 ],
                 [
                    94.54555829999995,
                    50.1178068
                 ],
                 [
                    94.5450791,
                    50.117837699999974
                 ],
                 [
                    94.544698,
                    50.117824300000024
                 ],
                 [
                    94.5442847,
                    50.11776139999997
                 ],
                 [
                    94.54416409999997,
                    50.11770259999999
                 ],
                 [
                    94.544029,
                    50.11755309999997
                 ],
                 [
                    94.543902,
                    50.1173871
                 ],
                 [
                    94.54380229999997,
                    50.11735
                 ],
                 [
                    94.54368490000003,
                    50.11734069999998
                 ],
                 [
                    94.54360130000003,
                    50.117349
                 ],
                 [
                    94.54341320000002,
                    50.117416
                 ],
                 [
                    94.5430642,
                    50.11760259999998
                 ],
                 [
                    94.54267990000001,
                    50.11781089999998
                 ],
                 [
                    94.5424499,
                    50.11808110000004
                 ],
                 [
                    94.54237759999995,
                    50.11820069999999
                 ],
                 [
                    94.54239360000004,
                    50.1183811
                 ],
                 [
                    94.54242419999999,
                    50.1184832
                 ],
                 [
                    94.54253190000001,
                    50.118600700000044
                 ],
                 [
                    94.54259139999996,
                    50.11880389999998
                 ],
                 [
                    94.542548,
                    50.11894920000002
                 ],
                 [
                    94.54248209999999,
                    50.119008
                 ],
                 [
                    94.5422827,
                    50.1190554
                 ],
                 [
                    94.54191930000005,
                    50.11911519999998
                 ],
                 [
                    94.54175680000002,
                    50.11915960000002
                 ],
                 [
                    94.5415333,
                    50.1193173
                 ],
                 [
                    94.54148029999996,
                    50.11944310000003
                 ],
                 [
                    94.5415076,
                    50.11968030000002
                 ],
                 [
                    94.54144809999997,
                    50.119831800000036
                 ],
                 [
                    94.54144010000002,
                    50.119904
                 ],
                 [
                    94.54145769999995,
                    50.11997
                 ],
                 [
                    94.5415333,
                    50.120044200000024
                 ],
                 [
                    94.5416234,
                    50.12007310000001
                 ],
                 [
                    94.54176970000002,
                    50.1200772
                 ],
                 [
                    94.54195140000004,
                    50.12009060000004
                 ],
                 [
                    94.54208650000002,
                    50.120144200000034
                 ],
                 [
                    94.5422505,
                    50.12027
                 ],
                 [
                    94.54229390000005,
                    50.1203917
                 ],
                 [
                    94.5422923,
                    50.1205216
                 ],
                 [
                    94.54210419999998,
                    50.120735
                 ],
                 [
                    94.54182120000002,
                    50.12092680000004
                 ],
                 [
                    94.54162659999999,
                    50.121014500000015
                 ],
                 [
                    94.541186,
                    50.1210938
                 ],
                 [
                    94.540903,
                    50.1211805
                 ],
                 [
                    94.54069230000005,
                    50.121205199999984
                 ],
                 [
                    94.54042280000004,
                    50.121176
                 ],
                 [
                    94.54034320000002,
                    50.121191299999985
                 ],
                 [
                    94.54031139999996,
                    50.12122339999998
                 ],
                 [
                    94.54031019999996,
                    50.12126719999996
                 ],
                 [
                    94.54039890000001,
                    50.121347400000026
                 ],
                 [
                    94.54050010000005,
                    50.1213911
                 ],
                 [
                    94.54061790000002,
                    50.1215199
                 ],
                 [
                    94.5406517,
                    50.121633299999964
                 ],
                 [
                    94.54058409999998,
                    50.1217303
                 ],
                 [
                    94.5404718,
                    50.1217787
                 ],
                 [
                    94.54029209999997,
                    50.121786
                 ],
                 [
                    94.54017629999998,
                    50.1218237
                 ],
                 [
                    94.5400863,
                    50.121914400000044
                 ],
                 [
                    94.54008140000003,
                    50.12203909999997
                 ],
                 [
                    94.5402045,
                    50.122154500000015
                 ],
                 [
                    94.5404115,
                    50.122275499999986
                 ],
                 [
                    94.54052739999999,
                    50.12228790000004
                 ],
                 [
                    94.54060359999998,
                    50.12227480000002
                 ],
                 [
                    94.540847,
                    50.122148699999975
                 ],
                 [
                    94.541013,
                    50.122118
                 ],
                 [
                    94.54111759999998,
                    50.12212680000003
                 ],
                 [
                    94.54126310000002,
                    50.122189499999976
                 ],
                 [
                    94.54132679999996,
                    50.1222617
                 ],
                 [
                    94.5413325,
                    50.122330900000044
                 ],
                 [
                    94.5412779,
                    50.1224352
                 ],
                 [
                    94.54123129999996,
                    50.12255109999997
                 ],
                 [
                    94.5411744,
                    50.1226138
                 ],
                 [
                    94.54108009999997,
                    50.12263059999997
                 ],
                 [
                    94.5410061,
                    50.1226203
                 ],
                 [
                    94.5408958,
                    50.12256060000004
                 ],
                 [
                    94.5408001,
                    50.1225534
                 ],
                 [
                    94.54068589999996,
                    50.122575
                 ],
                 [
                    94.540546,
                    50.122645100000014
                 ],
                 [
                    94.5404383,
                    50.122765700000045
                 ],
                 [
                    94.54044630000003,
                    50.12292970000003
                 ],
                 [
                    94.54052509999998,
                    50.12309979999998
                 ],
                 [
                    94.5406779,
                    50.12331840000004
                 ],
                 [
                    94.54070840000004,
                    50.1234823
                 ],
                 [
                    94.54064729999996,
                    50.12359669999997
                 ],
                 [
                    94.5405265,
                    50.12365110000003
                 ],
                 [
                    94.54042749999998,
                    50.1236453
                 ],
                 [
                    94.54036040000003,
                    50.12361760000001
                 ],
                 [
                    94.5402968,
                    50.123621200000045
                 ],
                 [
                    94.54021630000001,
                    50.1236486
                 ],
                 [
                    94.54017929999996,
                    50.12370729999997
                 ],
                 [
                    94.54016959999996,
                    50.12385060000002
                 ],
                 [
                    94.54022430000005,
                    50.12398260000001
                 ],
                 [
                    94.5402548,
                    50.12411040000002
                 ],
                 [
                    94.54020180000005,
                    50.124205299999964
                 ],
                 [
                    94.54001040000003,
                    50.12432080000002
                 ],
                 [
                    94.53988659999999,
                    50.1244837
                 ],
                 [
                    94.539803,
                    50.12481460000002
                 ],
                 [
                    94.53971129999996,
                    50.125017700000036
                 ],
                 [
                    94.539573,
                    50.1251878
                 ],
                 [
                    94.5394444,
                    50.125227
                 ],
                 [
                    94.53922409999998,
                    50.125242399999976
                 ],
                 [
                    94.5390182,
                    50.125227
                 ],
                 [
                    94.53890729999996,
                    50.12516719999997
                 ],
                 [
                    94.53878350000002,
                    50.1250332
                 ],
                 [
                    94.53871429999998,
                    50.12498569999997
                 ],
                 [
                    94.5384956,
                    50.124930099999986
                 ],
                 [
                    94.5382464,
                    50.12491670000003
                 ],
                 [
                    94.53809520000001,
                    50.124928
                 ],
                 [
                    94.53799389999998,
                    50.12497949999999
                 ],
                 [
                    94.537735,
                    50.125196
                 ],
                 [
                    94.53756940000002,
                    50.12533210000003
                 ],
                 [
                    94.53724129999996,
                    50.12548880000003
                 ],
                 [
                    94.53708210000003,
                    50.1255455
                 ],
                 [
                    94.53680390000001,
                    50.12556410000004
                 ],
                 [
                    94.53641479999997,
                    50.12561149999996
                 ],
                 [
                    94.5361864,
                    50.1256796
                 ],
                 [
                    94.53604170000001,
                    50.12584759999996
                 ],
                 [
                    94.53602079999997,
                    50.1259383
                 ],
                 [
                    94.5360449,
                    50.126181599999974
                 ],
                 [
                    94.5361222,
                    50.126283499999964
                 ],
                 [
                    94.536344,
                    50.12638549999997
                 ],
                 [
                    94.53653949999999,
                    50.12643289999998
                 ],
                 [
                    94.53665209999998,
                    50.126497099999966
                 ],
                 [
                    94.5367146,
                    50.12662250000004
                 ],
                 [
                    94.53672150000004,
                    50.12671580000003
                 ],
                 [
                    94.53664759999997,
                    50.12682510000001
                 ],
                 [
                    94.536502,
                    50.1268754
                 ],
                 [
                    94.53640419999998,
                    50.12687179999997
                 ],
                 [
                    94.5363121,
                    50.126831700000025
                 ],
                 [
                    94.53611319999997,
                    50.12684379999996
                 ],
                 [
                    94.53587039999996,
                    50.12692940000003
                 ],
                 [
                    94.53565489999998,
                    50.12707679999997
                 ],
                 [
                    94.5355681,
                    50.1272253
                 ],
                 [
                    94.5355906,
                    50.12739640000003
                 ],
                 [
                    94.53565650000003,
                    50.12757780000003
                 ],
                 [
                    94.53573209999998,
                    50.127816
                 ],
                 [
                    94.5357624,
                    50.1280698
                 ],
                 [
                    94.53584120000004,
                    50.12831210000004
                 ],
                 [
                    94.53583160000004,
                    50.1285585
                 ],
                 [
                    94.53571580000005,
                    50.128665699999985
                 ],
                 [
                    94.5353717,
                    50.128774900000046
                 ],
                 [
                    94.535087,
                    50.1289471
                 ],
                 [
                    94.53490530000005,
                    50.1291522
                 ],
                 [
                    94.5348812,
                    50.129412
                 ],
                 [
                    94.5349584,
                    50.12964090000003
                 ],
                 [
                    94.53507739999998,
                    50.129833599999984
                 ],
                 [
                    94.53526709999996,
                    50.129912
                 ],
                 [
                    94.5354199,
                    50.13001919999997
                 ],
                 [
                    94.53548260000002,
                    50.130131600000034
                 ],
                 [
                    94.53547620000003,
                    50.13022949999996
                 ],
                 [
                    94.53537970000004,
                    50.1303501
                 ],
                 [
                    94.53523020000002,
                    50.1305088
                 ],
                 [
                    94.53505010000003,
                    50.13097579999999
                 ],
                 [
                    94.53496,
                    50.13121809999996
                 ],
                 [
                    94.53483479999998,
                    50.13138459999997
                 ],
                 [
                    94.53457550000002,
                    50.1315289
                 ],
                 [
                    94.53446520000004,
                    50.13157339999996
                 ],
                 [
                    94.534306,
                    50.13158940000004
                 ],
                 [
                    94.5342082,
                    50.13157339999996
                 ],
                 [
                    94.5341503,
                    50.1315384
                 ],
                 [
                    94.53413550000003,
                    50.13149030000001
                 ],
                 [
                    94.53417979999996,
                    50.13138320000003
                 ],
                 [
                    94.53413640000002,
                    50.1312658
                 ],
                 [
                    94.53402219999998,
                    50.131112500000015
                 ],
                 [
                    94.5339039,
                    50.131041
                 ],
                 [
                    94.53365489999997,
                    50.130995099999986
                 ],
                 [
                    94.53340249999995,
                    50.13099289999998
                 ],
                 [
                    94.5332001,
                    50.13101549999998
                 ],
                 [
                    94.53303970000005,
                    50.131088399999975
                 ],
                 [
                    94.532959,
                    50.13115259999996
                 ],
                 [
                    94.53291690000002,
                    50.13123280000002
                 ],
                 [
                    94.53291810000002,
                    50.131339200000035
                 ],
                 [
                    94.5329522,
                    50.13139170000002
                 ],
                 [
                    94.53305230000002,
                    50.131449199999985
                 ],
                 [
                    94.53329439999999,
                    50.1315309
                 ],
                 [
                    94.53344679999998,
                    50.1316329
                 ],
                 [
                    94.53354229999998,
                    50.1318261
                 ],
                 [
                    94.53362990000004,
                    50.13198420000002
                 ],
                 [
                    94.5336435,
                    50.1321278
                 ],
                 [
                    94.5336026,
                    50.13218539999997
                 ],
                 [
                    94.53350249999997,
                    50.1322189
                 ],
                 [
                    94.53334110000003,
                    50.13223210000002
                 ],
                 [
                    94.53321369999996,
                    50.13222040000002
                 ],
                 [
                    94.5331239,
                    50.132181800000026
                 ],
                 [
                    94.532876,
                    50.131994399999975
                 ],
                 [
                    94.53276680000003,
                    50.13189019999997
                 ],
                 [
                    94.53274410000002,
                    50.13180859999997
                 ],
                 [
                    94.53269519999996,
                    50.1317466
                 ],
                 [
                    94.5325463,
                    50.13163440000003
                 ],
                 [
                    94.53243029999999,
                    50.131573100000026
                 ],
                 [
                    94.53232570000003,
                    50.1315578
                 ],
                 [
                    94.5322552,
                    50.1315739
                 ],
                 [
                    94.532221,
                    50.13162050000004
                 ],
                 [
                    94.532179,
                    50.131657
                 ],
                 [
                    94.53201750000002,
                    50.1317218
                 ],
                 [
                    94.53184470000002,
                    50.131794
                 ],
                 [
                    94.53174920000004,
                    50.13187270000003
                 ],
                 [
                    94.531706,
                    50.13199950000004
                 ],
                 [
                    94.53170820000001,
                    50.1320426
                 ],
                 [
                    94.5317628,
                    50.1320914
                 ],
                 [
                    94.5319356,
                    50.13216570000003
                 ],
                 [
                    94.5321574,
                    50.132259
                 ],
                 [
                    94.5322438,
                    50.13233339999996
                 ],
                 [
                    94.53225509999997,
                    50.13246439999996
                 ],
                 [
                    94.5322824,
                    50.13257770000001
                 ],
                 [
                    94.5323918,
                    50.13268290000001
                 ],
                 [
                    94.5327761,
                    50.132854
                 ],
                 [
                    94.53298350000001,
                    50.13296740000001
                 ],
                 [
                    94.53303979999997,
                    50.13306840000002
                 ],
                 [
                    94.53303659999997,
                    50.1331766
                 ],
                 [
                    94.53296750000003,
                    50.133358
                 ],
                 [
                    94.53283560000004,
                    50.13357859999996
                 ],
                 [
                    94.53267479999997,
                    50.133738400000034
                 ],
                 [
                    94.5322824,
                    50.133917799999956
                 ],
                 [
                    94.53198980000005,
                    50.134026
                 ],
                 [
                    94.53164720000002,
                    50.13405180000003
                 ],
                 [
                    94.53143340000001,
                    50.13401569999998
                 ],
                 [
                    94.5311584,
                    50.1338786
                 ],
                 [
                    94.53078210000004,
                    50.133771400000015
                 ],
                 [
                    94.530491,
                    50.13374150000003
                 ],
                 [
                    94.53027559999997,
                    50.13374560000001
                 ],
                 [
                    94.53013240000001,
                    50.13378169999998
                 ],
                 [
                    94.53000060000004,
                    50.13391049999998
                 ],
                 [
                    94.52990730000005,
                    50.134067199999976
                 ],
                 [
                    94.52987680000001,
                    50.13425690000004
                 ],
                 [
                    94.5299604,
                    50.1345383
                 ],
                 [
                    94.53008740000003,
                    50.1347599
                 ],
                 [
                    94.53018549999997,
                    50.1348114
                 ],
                 [
                    94.53038009999999,
                    50.13483410000001
                 ],
                 [
                    94.53060519999997,
                    50.13486709999999
                 ],
                 [
                    94.53073219999999,
                    50.1349166
                 ],
                 [
                    94.530848,
                    50.135053599999964
                 ],
                 [
                    94.53087540000003,
                    50.135233
                 ],
                 [
                    94.5308384,
                    50.13533709999997
                 ],
                 [
                    94.53070309999998,
                    50.1354198
                 ],
                 [
                    94.53049159999998,
                    50.135454099999976
                 ],
                 [
                    94.53036880000003,
                    50.13544830000003
                 ],
                 [
                    94.5302471,
                    50.13541549999998
                 ],
                 [
                    94.53018229999996,
                    50.1353535
                 ],
                 [
                    94.53014249999997,
                    50.13525879999998
                 ],
                 [
                    94.53009930000003,
                    50.13521139999997
                 ],
                 [
                    94.53002650000005,
                    50.1351924
                 ],
                 [
                    94.52987060000002,
                    50.135204
                 ],
                 [
                    94.52957950000001,
                    50.13524110000002
                 ],
                 [
                    94.52936720000002,
                    50.135239
                 ],
                 [
                    94.5292241,
                    50.13519989999999
                 ],
                 [
                    94.5289492,
                    50.135046299999985
                 ],
                 [
                    94.52859059999999,
                    50.1349886
                 ],
                 [
                    94.528285,
                    50.135018399999986
                 ],
                 [
                    94.5281033,
                    50.135084400000025
                 ],
                 [
                    94.5279972,
                    50.13518950000002
                 ],
                 [
                    94.52815720000001,
                    50.13551119999999
                 ],
                 [
                    94.5284481,
                    50.13572750000004
                 ],
                 [
                    94.528969,
                    50.1358226
                 ],
                 [
                    94.529537,
                    50.1358205
                 ],
                 [
                    94.53005940000001,
                    50.13594569999997
                 ],
                 [
                    94.5302073,
                    50.13604569999998
                 ],
                 [
                    94.5302491,
                    50.1361519
                 ],
                 [
                    94.53023140000003,
                    50.13626630000003
                 ],
                 [
                    94.53016710000004,
                    50.13636730000004
                 ],
                 [
                    94.5299066,
                    50.13656519999998
                 ],
                 [
                    94.52967339999996,
                    50.13670640000003
                 ],
                 [
                    94.5294129,
                    50.13676310000001
                 ],
                 [
                    94.52910139999997,
                    50.1367908
                 ],
                 [
                    94.52901720000003,
                    50.1368338
                 ],
                 [
                    94.5289729,
                    50.13692120000004
                 ],
                 [
                    94.52893079999998,
                    50.137052399999966
                 ],
                 [
                    94.5288774,
                    50.137102
                 ],
                 [
                    94.5288012,
                    50.1371129
                 ],
                 [
                    94.528692,
                    50.1371093
                 ],
                 [
                    94.5285886,
                    50.137070599999966
                 ],
                 [
                    94.52835949999997,
                    50.136965
                 ],
                 [
                    94.5280701,
                    50.1369434
                 ],
                 [
                    94.52776939999998,
                    50.136987700000034
                 ],
                 [
                    94.52751369999997,
                    50.137086600000046
                 ],
                 [
                    94.52741079999998,
                    50.137193800000034
                 ],
                 [
                    94.52739950000003,
                    50.137299
                 ],
                 [
                    94.527422,
                    50.137426800000036
                 ],
                 [
                    94.52750409999997,
                    50.137574099999966
                 ],
                 [
                    94.52754430000002,
                    50.1376679
                 ],
                 [
                    94.52751530000002,
                    50.1377741
                 ],
                 [
                    94.52735929999999,
                    50.13791319999996
                 ],
                 [
                    94.52720010000004,
                    50.137986399999974
                 ],
                 [
                    94.52679009999996,
                    50.13806469999997
                 ],
                 [
                    94.52648129999999,
                    50.1381101
                 ],
                 [
                    94.52622889999998,
                    50.13809569999996
                 ],
                 [
                    94.5260552,
                    50.138107
                 ],
                 [
                    94.52597319999997,
                    50.13815540000002
                 ],
                 [
                    94.52595069999997,
                    50.13825130000004
                 ],
                 [
                    94.5259555,
                    50.138439900000016
                 ],
                 [
                    94.52589440000001,
                    50.13856360000004
                 ],
                 [
                    94.52579150000003,
                    50.13863779999996
                 ],
                 [
                    94.5255245,
                    50.13869240000004
                 ],
                 [
                    94.52534759999998,
                    50.138734699999986
                 ],
                 [
                    94.5251997,
                    50.138821299999954
                 ],
                 [
                    94.5251257,
                    50.13894389999997
                 ],
                 [
                    94.52509680000001,
                    50.13913350000003
                 ],
                 [
                    94.52513050000005,
                    50.139465399999956
                 ],
                 [
                    94.52516910000004,
                    50.13983540000002
                 ],
                 [
                    94.52524630000003,
                    50.140013700000026
                 ],
                 [
                    94.52544250000001,
                    50.1402528
                 ],
                 [
                    94.5256516,
                    50.14045480000002
                 ],
                 [
                    94.52571269999999,
                    50.140661
                 ],
                 [
                    94.52567570000004,
                    50.14080109999998
                 ],
                 [
                    94.52557919999995,
                    50.1409011
                 ],
                 [
                    94.525457,
                    50.140955699999985
                 ],
                 [
                    94.52533960000002,
                    50.14095669999998
                 ],
                 [
                    94.52522060000004,
                    50.140931
                 ],
                 [
                    94.525055,
                    50.1408475
                 ],
                 [
                    94.52480410000004,
                    50.140762
                 ],
                 [
                    94.52468990000001,
                    50.140664
                 ],
                 [
                    94.52465619999997,
                    50.14053320000002
                 ],
                 [
                    94.52468030000001,
                    50.140394
                 ],
                 [
                    94.5246915,
                    50.1402786
                 ],
                 [
                    94.52465130000002,
                    50.14019920000001
                 ],
                 [
                    94.52459990000003,
                    50.140157
                 ],
                 [
                    94.52450020000003,
                    50.140139499999954
                 ],
                 [
                    94.52438119999995,
                    50.140142500000024
                 ],
                 [
                    94.5242525,
                    50.1401786
                 ],
                 [
                    94.52412550000003,
                    50.14028989999996
                 ],
                 [
                    94.5239824,
                    50.14049709999997
                 ],
                 [
                    94.52384090000001,
                    50.140621799999984
                 ],
                 [
                    94.52371220000002,
                    50.14065269999996
                 ],
                 [
                    94.52353859999997,
                    50.140663
                 ],
                 [
                    94.52318320000005,
                    50.1406434
                 ],
                 [
                    94.52282780000003,
                    50.14065169999997
                 ],
                 [
                    94.52260589999997,
                    50.1406929
                 ],
                 [
                    94.5223518,
                    50.14080519999997
                 ],
                 [
                    94.5222135,
                    50.140898
                 ],
                 [
                    94.52215719999997,
                    50.1410052
                 ],
                 [
                    94.52217969999997,
                    50.14112680000002
                 ],
                 [
                    94.5223003,
                    50.14122780000003
                 ],
                 [
                    94.522384,
                    50.1413381
                 ],
                 [
                    94.5224129,
                    50.141471
                 ],
                 [
                    94.5223389,
                    50.14158439999996
                 ],
                 [
                    94.5222907,
                    50.1418307
                 ],
                 [
                    94.52235020000005,
                    50.1419327
                 ],
                 [
                    94.52246109999999,
                    50.142009
                 ],
                 [
                    94.52266220000001,
                    50.14205330000003
                 ],
                 [
                    94.52287920000002,
                    50.14206669999999
                 ],
                 [
                    94.5231655,
                    50.1420894
                 ],
                 [
                    94.52332790000003,
                    50.142146099999984
                 ],
                 [
                    94.52341629999997,
                    50.142238800000015
                 ],
                 [
                    94.52344529999996,
                    50.142358399999964
                 ],
                 [
                    94.52340349999997,
                    50.142444900000015
                 ],
                 [
                    94.52327320000003,
                    50.1425232
                 ],
                 [
                    94.52320890000004,
                    50.142652100000014
                 ],
                 [
                    94.52320570000003,
                    50.14279940000004
                 ],
                 [
                    94.523262,
                    50.142906600000025
                 ],
                 [
                    94.52338580000001,
                    50.142975699999965
                 ],
                 [
                    94.523635,
                    50.14302309999998
                 ],
                 [
                    94.52383440000001,
                    50.14302309999998
                 ],
                 [
                    94.52394219999996,
                    50.14299939999997
                 ],
                 [
                    94.52403220000004,
                    50.14293340000002
                 ],
                 [
                    94.52417209999997,
                    50.14277470000003
                 ],
                 [
                    94.5243924,
                    50.1426593
                 ],
                 [
                    94.5244482,
                    50.14257329999998
                 ],
                 [
                    94.52442429999996,
                    50.1424727
                 ],
                 [
                    94.52430370000003,
                    50.1423845
                 ],
                 [
                    94.52414460000001,
                    50.142296399999985
                 ],
                 [
                    94.52411839999998,
                    50.142206700000024
                 ],
                 [
                    94.52412980000003,
                    50.142163
                 ],
                 [
                    94.52419569999998,
                    50.142112
                 ],
                 [
                    94.52432190000002,
                    50.14207189999997
                 ],
                 [
                    94.52447540000003,
                    50.142054400000035
                 ],
                 [
                    94.5245846,
                    50.142061
                 ],
                 [
                    94.5247211,
                    50.14212659999997
                 ],
                 [
                    94.52488020000003,
                    50.142241
                 ],
                 [
                    94.5250388,
                    50.1424683
                 ],
                 [
                    94.52514019999997,
                    50.14256620000001
                 ],
                 [
                    94.52528969999999,
                    50.142618800000015
                 ],
                 [
                    94.5255808,
                    50.14265589999997
                 ],
                 [
                    94.52574,
                    50.142694
                 ],
                 [
                    94.52587989999996,
                    50.14277240000002
                 ],
                 [
                    94.5259361,
                    50.14287130000003
                 ],
                 [
                    94.5259458,
                    50.14300630000001
                 ],
                 [
                    94.52588949999996,
                    50.143093899999975
                 ],
                 [
                    94.525793,
                    50.143158800000016
                 ],
                 [
                    94.5255197,
                    50.14326290000002
                 ],
                 [
                    94.5252786,
                    50.143342300000015
                 ],
                 [
                    94.525141,
                    50.1434342
                 ],
                 [
                    94.5251137,
                    50.14351579999998
                 ],
                 [
                    94.52512959999997,
                    50.1435908
                 ],
                 [
                    94.52520239999996,
                    50.14364989999997
                 ],
                 [
                    94.52528539999999,
                    50.14367830000001
                 ],
                 [
                    94.52539120000003,
                    50.14366880000003
                 ],
                 [
                    94.525489,
                    50.143636
                 ],
                 [
                    94.52560949999997,
                    50.14353470000003
                 ],
                 [
                    94.52570160000005,
                    50.1434859
                 ],
                 [
                    94.52579819999995,
                    50.14347279999998
                 ],
                 [
                    94.5259006,
                    50.14348520000002
                 ],
                 [
                    94.52600290000002,
                    50.143526
                 ],
                 [
                    94.52606090000002,
                    50.14361119999997
                 ],
                 [
                    94.52606549999996,
                    50.14371910000001
                 ],
                 [
                    94.526012,
                    50.1438124
                 ],
                 [
                    94.525753,
                    50.14392860000003
                 ],
                 [
                    94.52556319999997,
                    50.14406370000002
                 ],
                 [
                    94.5253156,
                    50.144228499999976
                 ],
                 [
                    94.52519660000002,
                    50.1443532
                 ],
                 [
                    94.52518370000001,
                    50.1445583
                 ],
                 [
                    94.525211,
                    50.14470770000001
                 ],
                 [
                    94.5252866,
                    50.14480150000004
                 ],
                 [
                    94.5254153,
                    50.144886
                 ],
                 [
                    94.52556,
                    50.14492829999997
                 ],
                 [
                    94.52571120000005,
                    50.1449437
                 ],
                 [
                    94.52592179999998,
                    50.1449406
                 ],
                 [
                    94.5262418,
                    50.144885
                 ],
                 [
                    94.52643159999997,
                    50.14488090000004
                 ],
                 [
                    94.526565,
                    50.14491380000001
                 ],
                 [
                    94.52668729999998,
                    50.14500659999996
                 ],
                 [
                    94.52675320000002,
                    50.145145700000015
                 ],
                 [
                    94.52673070000002,
                    50.14523849999996
                 ],
                 [
                    94.52664539999998,
                    50.145365199999965
                 ],
                 [
                    94.5266519,
                    50.14543939999998
                 ],
                 [
                    94.52670979999998,
                    50.14556819999998
                 ],
                 [
                    94.52679339999997,
                    50.1456661
                 ],
                 [
                    94.52694780000004,
                    50.145726899999964
                 ],
                 [
                    94.527123,
                    50.1457403
                 ],
                 [
                    94.52731280000005,
                    50.14569389999998
                 ],
                 [
                    94.5274849,
                    50.145608400000015
                 ],
                 [
                    94.5276633,
                    50.145478600000025
                 ],
                 [
                    94.527784,
                    50.14542190000004
                 ],
                 [
                    94.52794959999997,
                    50.14541159999999
                 ],
                 [
                    94.5280895,
                    50.14543839999998
                 ],
                 [
                    94.52825830000003,
                    50.14551569999998
                 ],
                 [
                    94.52845929999997,
                    50.1458197
                 ],
                 [
                    94.52854749999997,
                    50.14602810000002
                 ],
                 [
                    94.5285102,
                    50.14633669999997
                 ],
                 [
                    94.5284201,
                    50.14648919999998
                 ],
                 [
                    94.52828979999997,
                    50.14656649999998
                 ],
                 [
                    94.52817729999998,
                    50.1466613
                 ],
                 [
                    94.52808240000005,
                    50.146811700000015
                 ],
                 [
                    94.5280937,
                    50.14695809999996
                 ],
                 [
                    94.5281644,
                    50.1470724
                 ],
                 [
                    94.52832680000002,
                    50.14723529999998
                 ],
                 [
                    94.5283686,
                    50.1474073
                 ],
                 [
                    94.52836379999997,
                    50.147627900000046
                 ],
                 [
                    94.52829790000001,
                    50.14773920000002
                 ],
                 [
                    94.52822070000002,
                    50.147769
                 ],
                 [
                    94.52810170000004,
                    50.1477701
                 ],
                 [
                    94.52789430000001,
                    50.14770309999996
                 ],
                 [
                    94.527645,
                    50.147685600000024
                 ],
                 [
                    94.52734910000002,
                    50.147679400000044
                 ],
                 [
                    94.52722049999996,
                    50.147701
                 ],
                 [
                    94.5271513,
                    50.147748399999976
                 ],
                 [
                    94.527132,
                    50.14781130000003
                 ],
                 [
                    94.52716259999997,
                    50.1478999
                 ],
                 [
                    94.52722049999996,
                    50.1479473
                 ],
                 [
                    94.52737969999998,
                    50.1480236
                 ],
                 [
                    94.52754530000001,
                    50.14821519999997
                 ],
                 [
                    94.5276643,
                    50.14841509999999
                 ],
                 [
                    94.52775759999999,
                    50.1486954
                 ],
                 [
                    94.52780259999999,
                    50.148983899999976
                 ],
                 [
                    94.5278951,
                    50.1491013
                 ],
                 [
                    94.52800769999999,
                    50.14914350000004
                 ],
                 [
                    94.52821350000005,
                    50.149150800000015
                 ],
                 [
                    94.5283705,
                    50.1491291
                 ],
                 [
                    94.52868709999998,
                    50.149066200000036
                 ],
                 [
                    94.5289685,
                    50.14903710000002
                 ],
                 [
                    94.52924990000001,
                    50.14908880000004
                 ],
                 [
                    94.52959580000002,
                    50.149238099999955
                 ],
                 [
                    94.52975260000004,
                    50.14937239999997
                 ],
                 [
                    94.52979360000005,
                    50.149533
                 ],
                 [
                    94.52976139999996,
                    50.14964939999997
                 ],
                 [
                    94.52963970000002,
                    50.14974509999998
                 ],
                 [
                    94.52945630000004,
                    50.1497956
                 ],
                 [
                    94.5292563,
                    50.1498115
                 ],
                 [
                    94.529077,
                    50.149798300000036
                 ],
                 [
                    94.52881579999998,
                    50.1497591
                 ],
                 [
                    94.52862310000002,
                    50.14975310000003
                 ],
                 [
                    94.528535,
                    50.14976439999998
                 ],
                 [
                    94.52846139999996,
                    50.1498129
                 ],
                 [
                    94.52844170000002,
                    50.149895900000026
                 ],
                 [
                    94.52844270000001,
                    50.149979500000015
                 ],
                 [
                    94.5284894,
                    50.150045300000045
                 ],
                 [
                    94.52855360000004,
                    50.150089100000024
                 ],
                 [
                    94.52869870000005,
                    50.150118300000045
                 ],
                 [
                    94.52891119999995,
                    50.150123700000044
                 ],
                 [
                    94.5291371,
                    50.1501489
                 ],
                 [
                    94.52928220000001,
                    50.150205300000046
                 ],
                 [
                    94.52938059999998,
                    50.15026439999996
                 ],
                 [
                    94.52947489999997,
                    50.15038159999998
                 ],
                 [
                    94.529513,
                    50.15045860000004
                 ],
                 [
                    94.5296361,
                    50.15054310000002
                 ],
                 [
                    94.5298575,
                    50.150616399999954
                 ],
                 [
                    94.53012420000005,
                    50.15070940000001
                 ],
                 [
                    94.5303953,
                    50.15084079999996
                 ],
                 [
                    94.53058289999998,
                    50.1509507
                 ],
                 [
                    94.53068989999996,
                    50.15108310000002
                 ],
                 [
                    94.53084530000002,
                    50.15126250000004
                 ],
                 [
                    94.53094640000005,
                    50.151465400000035
                 ],
                 [
                    94.53092150000002,
                    50.15164379999997
                 ],
                 [
                    94.530838,
                    50.15175459999998
                 ],
                 [
                    94.53056679999999,
                    50.15183819999997
                 ],
                 [
                    94.5302781,
                    50.1518654
                 ],
                 [
                    94.53011980000004,
                    50.151841900000015
                 ],
                 [
                    94.53000260000002,
                    50.15177809999997
                 ],
                 [
                    94.52989559999996,
                    50.151749
                 ],
                 [
                    94.52974019999998,
                    50.15175180000002
                 ],
                 [
                    94.52962,
                    50.15178840000003
                 ],
                 [
                    94.52939290000002,
                    50.15192929999996
                 ],
                 [
                    94.52919059999998,
                    50.15201849999997
                 ],
                 [
                    94.52892820000004,
                    50.152051400000026
                 ],
                 [
                    94.5286937,
                    50.1520382
                 ],
                 [
                    94.52855159999996,
                    50.15196780000004
                 ],
                 [
                    94.52841090000004,
                    50.151758400000034
                 ],
                 [
                    94.5283757,
                    50.15167570000003
                 ],
                 [
                    94.5283933,
                    50.1515884
                 ],
                 [
                    94.5284651,
                    50.1515273
                 ],
                 [
                    94.528638,
                    50.15145970000001
                 ],
                 [
                    94.52868350000004,
                    50.1514081
                 ],
                 [
                    94.52870109999998,
                    50.1512879
                 ],
                 [
                    94.52864829999997,
                    50.151132
                 ],
                 [
                    94.5285897,
                    50.15104560000003
                 ],
                 [
                    94.528449,
                    50.150962
                 ],
                 [
                    94.52824669999995,
                    50.150900900000046
                 ],
                 [
                    94.52807380000004,
                    50.150881200000015
                 ],
                 [
                    94.5278671,
                    50.15089339999996
                 ],
                 [
                    94.527722,
                    50.1509573
                 ],
                 [
                    94.527552,
                    50.15109070000003
                 ],
                 [
                    94.52742889999998,
                    50.15124840000002
                 ],
                 [
                    94.52722660000002,
                    50.151382700000035
                 ],
                 [
                    94.5270332,
                    50.15147380000002
                 ],
                 [
                    94.52685579999998,
                    50.15171139999996
                 ],
                 [
                    94.526721,
                    50.151906699999955
                 ],
                 [
                    94.52650259999997,
                    50.152051400000026
                 ],
                 [
                    94.52642050000003,
                    50.15224480000004
                 ],
                 [
                    94.5263956,
                    50.1523763
                 ],
                 [
                    94.5264191,
                    50.152473
                 ],
                 [
                    94.52646890000003,
                    50.15255939999996
                 ],
                 [
                    94.5265275,
                    50.1527285
                 ],
                 [
                    94.52650550000004,
                    50.152866499999966
                 ],
                 [
                    94.52643230000002,
                    50.15292
                 ],
                 [
                    94.52621970000001,
                    50.15301960000002
                 ],
                 [
                    94.52603210000004,
                    50.15312570000001
                 ],
                 [
                    94.52580349999995,
                    50.153276
                 ],
                 [
                    94.52562180000002,
                    50.15338489999996
                 ],
                 [
                    94.52550739999997,
                    50.15342149999997
                 ],
                 [
                    94.52538289999997,
                    50.15342340000004
                 ],
                 [
                    94.52529929999999,
                    50.15339620000001
                 ],
                 [
                    94.52516890000003,
                    50.153323899999975
                 ],
                 [
                    94.52506189999997,
                    50.15328250000002
                 ],
                 [
                    94.5248508,
                    50.1532769
                 ],
                 [
                    94.52472770000003,
                    50.15330979999997
                 ],
                 [
                    94.52463980000003,
                    50.153384
                 ],
                 [
                    94.52461190000004,
                    50.153538
                 ],
                 [
                    94.52461630000005,
                    50.1536591
                 ],
                 [
                    94.5246706,
                    50.1537558
                 ],
                 [
                    94.5247541,
                    50.15381119999997
                 ],
                 [
                    94.52510440000002,
                    50.15390039999998
                 ],
                 [
                    94.52538729999998,
                    50.15392580000004
                 ],
                 [
                    94.52567010000001,
                    50.153942700000016
                 ],
                 [
                    94.5257976,
                    50.1539746
                 ],
                 [
                    94.52586650000002,
                    50.1540207
                 ],
                 [
                    94.525997,
                    50.15418029999996
                 ],
                 [
                    94.52605269999997,
                    50.15433809999996
                 ],
                 [
                    94.52605120000003,
                    50.15445170000003
                 ],
                 [
                    94.5259706,
                    50.1545296
                 ],
                 [
                    94.52580349999995,
                    50.1545991
                 ],
                 [
                    94.52562029999999,
                    50.15462069999998
                 ],
                 [
                    94.525462,
                    50.1546113
                 ],
                 [
                    94.52531250000001,
                    50.154554
                 ],
                 [
                    94.5251557,
                    50.15443479999997
                 ],
                 [
                    94.5250194,
                    50.154399100000035
                 ],
                 [
                    94.52485819999998,
                    50.15439060000004
                 ],
                 [
                    94.52469550000002,
                    50.154403799999976
                 ],
                 [
                    94.5246061,
                    50.15442539999999
                 ],
                 [
                    94.52451229999997,
                    50.15447610000001
                 ],
                 [
                    94.5244492,
                    50.1545522
                 ],
                 [
                    94.5244536,
                    50.1546188
                 ],
                 [
                    94.52448,
                    50.154668600000036
                 ],
                 [
                    94.524543,
                    50.154694
                 ],
                 [
                    94.52463980000003,
                    50.154693
                 ],
                 [
                    94.52474089999997,
                    50.1546949
                 ],
                 [
                    94.52484790000003,
                    50.15474470000003
                 ],
                 [
                    94.52488310000001,
                    50.154813200000014
                 ],
                 [
                    94.524845,
                    50.154903400000016
                 ],
                 [
                    94.5247468,
                    50.15498789999999
                 ],
                 [
                    94.52465739999998,
                    50.155045200000025
                 ],
                 [
                    94.52457679999998,
                    50.155066800000036
                 ],
                 [
                    94.52446389999996,
                    50.15505080000004
                 ],
                 [
                    94.52438330000004,
                    50.155002
                 ],
                 [
                    94.52427919999995,
                    50.154968199999956
                 ],
                 [
                    94.52415469999995,
                    50.154961600000036
                 ],
                 [
                    94.5239641,
                    50.15498410000003
                 ],
                 [
                    94.5238762,
                    50.155033
                 ],
                 [
                    94.52386150000004,
                    50.1550996
                 ],
                 [
                    94.5238718,
                    50.15516069999999
                 ],
                 [
                    94.52389519999998,
                    50.1552048
                 ],
                 [
                    94.52404910000001,
                    50.15534570000002
                 ],
                 [
                    94.524118,
                    50.15544140000003
                 ],
                 [
                    94.52407840000004,
                    50.15552410000004
                 ],
                 [
                    94.52395529999998,
                    50.15563770000002
                 ],
                 [
                    94.52370319999999,
                    50.155731599999974
                 ],
                 [
                    94.52361970000001,
                    50.155771100000045
                 ],
                 [
                    94.5236153,
                    50.1558189
                 ],
                 [
                    94.52364460000003,
                    50.15589880000004
                 ],
                 [
                    94.52372769999998,
                    50.1560378
                 ],
                 [
                    94.52372349999999,
                    50.1562129
                 ],
                 [
                    94.52375280000001,
                    50.156364500000024
                 ],
                 [
                    94.5238407,
                    50.156403
                 ],
                 [
                    94.52401510000004,
                    50.15642270000004
                 ],
                 [
                    94.5242701,
                    50.1564115
                 ],
                 [
                    94.5244064,
                    50.15642270000004
                 ],
                 [
                    94.52465170000005,
                    50.156464699999965
                 ],
                 [
                    94.5248466,
                    50.156547
                 ],
                 [
                    94.52502280000002,
                    50.15670639999998
                 ],
                 [
                    94.525085,
                    50.1568675
                 ],
                 [
                    94.52505710000001,
                    50.1569962
                 ],
                 [
                    94.52494130000002,
                    50.157085399999985
                 ],
                 [
                    94.52472149999996,
                    50.15729190000002
                 ],
                 [
                    94.52446790000002,
                    50.15744220000002
                 ],
                 [
                    94.52410029999999,
                    50.15758840000004
                 ],
                 [
                    94.52395419999998,
                    50.157737799999964
                 ],
                 [
                    94.523921,
                    50.15783339999997
                 ],
                 [
                    94.52394180000002,
                    50.1578971
                 ],
                 [
                    94.52404329999997,
                    50.15796090000004
                 ],
                 [
                    94.52414490000004,
                    50.1580452
                 ],
                 [
                    94.524177,
                    50.1581362
                 ],
                 [
                    94.5241376,
                    50.15825629999997
                 ],
                 [
                    94.52404850000005,
                    50.15837190000003
                 ],
                 [
                    94.52388889999999,
                    50.1584296
                 ],
                 [
                    94.5237645,
                    50.15844290000004
                 ],
                 [
                    94.523663,
                    50.1584283
                 ],
                 [
                    94.523636,
                    50.158401099999956
                 ],
                 [
                    94.5236205,
                    50.158323400000015
                 ],
                 [
                    94.52356559999997,
                    50.1582371
                 ],
                 [
                    94.5235231,
                    50.1581999
                 ],
                 [
                    94.5234329,
                    50.1581913
                 ],
                 [
                    94.52336550000003,
                    50.1582059
                 ],
                 [
                    94.523321,
                    50.15823909999997
                 ],
                 [
                    94.52330130000004,
                    50.15830149999998
                 ],
                 [
                    94.52332409999998,
                    50.15839379999998
                 ],
                 [
                    94.52330210000002,
                    50.15851709999996
                 ],
                 [
                    94.5232461,
                    50.15870360000003
                 ],
                 [
                    94.52323990000002,
                    50.15885759999998
                 ],
                 [
                    94.5232741,
                    50.1590263
                 ],
                 [
                    94.52331969999997,
                    50.159165
                 ],
                 [
                    94.52344410000005,
                    50.1593344
                 ],
                 [
                    94.52355389999998,
                    50.15945050000001
                 ],
                 [
                    94.52362540000004,
                    50.159544200000035
                 ],
                 [
                    94.52363790000001,
                    50.15963840000001
                 ],
                 [
                    94.52362029999998,
                    50.15972540000002
                 ],
                 [
                    94.5235767,
                    50.15979850000003
                 ],
                 [
                    94.5234617,
                    50.1598775
                 ],
                 [
                    94.52337979999997,
                    50.1599439
                 ],
                 [
                    94.523356,
                    50.16001220000003
                 ],
                 [
                    94.5233684,
                    50.160066700000016
                 ],
                 [
                    94.52339540000001,
                    50.1601052
                 ],
                 [
                    94.52346479999999,
                    50.160131100000015
                 ],
                 [
                    94.52363370000002,
                    50.160167600000015
                 ],
                 [
                    94.52375290000002,
                    50.16021080000004
                 ],
                 [
                    94.52384,
                    50.16028050000003
                 ],
                 [
                    94.5238762,
                    50.16037940000004
                 ],
                 [
                    94.52385859999997,
                    50.160478299999966
                 ],
                 [
                    94.52376949999997,
                    50.16054470000004
                 ],
                 [
                    94.52363790000001,
                    50.160583200000026
                 ],
                 [
                    94.52347,
                    50.160585200000014
                 ],
                 [
                    94.52328969999998,
                    50.16055
                 ],
                 [
                    94.523127,
                    50.16053680000001
                 ],
                 [
                    94.52301809999997,
                    50.16056
                 ],
                 [
                    94.52293830000004,
                    50.160610499999976
                 ],
                 [
                    94.52286730000002,
                    50.1608501
                 ],
                 [
                    94.5227135,
                    50.16103969999996
                 ],
                 [
                    94.52250089999998,
                    50.16126319999998
                 ],
                 [
                    94.5222723,
                    50.161444399999965
                 ],
                 [
                    94.52212130000002,
                    50.16159179999999
                 ],
                 [
                    94.5219792,
                    50.161685700000035
                 ],
                 [
                    94.52178130000004,
                    50.161727
                 ],
                 [
                    94.52166989999998,
                    50.16172139999997
                 ],
                 [
                    94.52143689999997,
                    50.161664100000024
                 ],
                 [
                    94.52134889999998,
                    50.161684800000046
                 ],
                 [
                    94.5212683,
                    50.16175330000003
                 ],
                 [
                    94.52123609999997,
                    50.1618087
                 ],
                 [
                    94.5212376,
                    50.16186969999998
                 ],
                 [
                    94.52126250000002,
                    50.161958
                 ],
                 [
                    94.5212332,
                    50.1620181
                 ],
                 [
                    94.5211657,
                    50.162076300000024
                 ],
                 [
                    94.52101619999998,
                    50.16217490000001
                 ],
                 [
                    94.52100739999996,
                    50.162208699999965
                 ],
                 [
                    94.5210294,
                    50.1622425
                 ],
                 [
                    94.5210895,
                    50.16227160000002
                 ],
                 [
                    94.5212976,
                    50.16231670000002
                 ],
                 [
                    94.52144569999999,
                    50.162381400000044
                 ],
                 [
                    94.52148670000001,
                    50.162446199999984
                 ],
                 [
                    94.52147940000002,
                    50.162512
                 ],
                 [
                    94.521431,
                    50.16261989999997
                 ],
                 [
                    94.521409,
                    50.1627007
                 ],
                 [
                    94.52141640000005,
                    50.162742
                 ],
                 [
                    94.52144859999996,
                    50.162773
                 ],
                 [
                    94.52149990000004,
                    50.16279550000002
                 ],
                 [
                    94.52156149999998,
                    50.1627974
                 ],
                 [
                    94.52163180000002,
                    50.162784199999976
                 ],
                 [
                    94.52174760000001,
                    50.162726
                 ],
                 [
                    94.5219117,
                    50.1627082
                 ],
                 [
                    94.5220964,
                    50.16272319999999
                 ],
                 [
                    94.5223602,
                    50.16277110000004
                 ],
                 [
                    94.5226695,
                    50.1628415
                 ],
                 [
                    94.52305349999997,
                    50.162988
                 ],
                 [
                    94.52332020000001,
                    50.163185100000014
                 ],
                 [
                    94.5234477,
                    50.163311
                 ],
                 [
                    94.52350930000003,
                    50.163340099999964
                 ],
                 [
                    94.52357519999997,
                    50.1633448
                 ],
                 [
                    94.523628,
                    50.16333259999996
                 ],
                 [
                    94.52366029999999,
                    50.16329409999997
                 ],
                 [
                    94.52373209999999,
                    50.16316820000004
                 ],
                 [
                    94.52381119999995,
                    50.16309969999997
                 ],
                 [
                    94.52395920000004,
                    50.16301609999998
                 ],
                 [
                    94.5241659,
                    50.16296449999997
                 ],
                 [
                    94.52445319999997,
                    50.16296359999998
                 ],
                 [
                    94.52477410000004,
                    50.16299830000001
                 ],
                 [
                    94.52489869999997,
                    50.1630406
                 ],
                 [
                    94.52496910000002,
                    50.16313629999998
                 ],
                 [
                    94.52498520000002,
                    50.163249
                 ],
                 [
                    94.52494120000001,
                    50.16334190000002
                 ],
                 [
                    94.52492510000002,
                    50.1633917
                 ],
                 [
                    94.52495879999996,
                    50.163497800000044
                 ],
                 [
                    94.5249442,
                    50.163604800000016
                 ],
                 [
                    94.52489430000004,
                    50.1636743
                 ],
                 [
                    94.524758,
                    50.16371369999997
                 ],
                 [
                    94.52448979999997,
                    50.16373720000004
                 ],
                 [
                    94.52435789999998,
                    50.1637795
                 ],
                 [
                    94.5243022,
                    50.163878
                 ],
                 [
                    94.52425679999996,
                    50.1641006
                 ],
                 [
                    94.52417470000002,
                    50.164222599999974
                 ],
                 [
                    94.52400909999999,
                    50.164291199999965
                 ],
                 [
                    94.52387129999995,
                    50.16430370000002
                 ],
                 [
                    94.52372730000003,
                    50.16428179999998
                 ],
                 [
                    94.52359359999998,
                    50.164243900000045
                 ],
                 [
                    94.5234982,
                    50.1642486
                 ],
                 [
                    94.5234329,
                    50.16427380000003
                 ],
                 [
                    94.52340180000002,
                    50.1643143
                 ],
                 [
                    94.52339020000002,
                    50.16436260000001
                 ],
                 [
                    94.5234144,
                    50.16441149999997
                 ],
                 [
                    94.52346569999997,
                    50.16445139999998
                 ],
                 [
                    94.5236325,
                    50.16452009999998
                 ],
                 [
                    94.5237131,
                    50.164607500000024
                 ],
                 [
                    94.52375850000004,
                    50.164716399999975
                 ],
                 [
                    94.52374530000002,
                    50.16481309999998
                 ],
                 [
                    94.52365450000005,
                    50.1649098
                 ],
                 [
                    94.52359879999997,
                    50.1650525
                 ],
                 [
                    94.52353720000002,
                    50.1651482
                 ],
                 [
                    94.52343020000005,
                    50.16525810000003
                 ],
                 [
                    94.52337449999997,
                    50.16534070000002
                 ],
                 [
                    94.5232353,
                    50.1653801
                 ],
                 [
                    94.52309309999995,
                    50.16541859999999
                 ],
                 [
                    94.5230594,
                    50.165475
                 ],
                 [
                    94.52307990000003,
                    50.1655529
                 ],
                 [
                    94.52315090000003,
                    50.165664900000024
                 ],
                 [
                    94.52317369999997,
                    50.165731899999976
                 ],
                 [
                    94.52317010000003,
                    50.165761
                 ],
                 [
                    94.52314569999996,
                    50.16578109999996
                 ],
                 [
                    94.5230452,
                    50.1658149
                 ],
                 [
                    94.52294470000002,
                    50.16585869999999
                 ],
                 [
                    94.52291260000004,
                    50.1659072
                 ],
                 [
                    94.52288870000001,
                    50.16607050000001
                 ],
                 [
                    94.5228799,
                    50.16618970000002
                 ],
                 [
                    94.52283320000004,
                    50.166315300000015
                 ],
                 [
                    94.52276379999998,
                    50.166401
                 ],
                 [
                    94.52264979999997,
                    50.1664461
                 ],
                 [
                    94.5224581,
                    50.166461400000024
                 ],
                 [
                    94.522399,
                    50.16649260000003
                 ],
                 [
                    94.52237519999998,
                    50.166537099999985
                 ],
                 [
                    94.52237719999997,
                    50.166570299999975
                 ],
                 [
                    94.5224239,
                    50.166596800000036
                 ],
                 [
                    94.52255239999998,
                    50.166634699999975
                 ],
                 [
                    94.5227244,
                    50.166701
                 ],
                 [
                    94.52280320000004,
                    50.166809200000024
                 ],
                 [
                    94.52279589999998,
                    50.1668829
                 ],
                 [
                    94.52276479999998,
                    50.16692610000002
                 ],
                 [
                    94.52266120000002,
                    50.1669646
                 ],
                 [
                    94.52253059999995,
                    50.16701440000004
                 ],
                 [
                    94.52250260000004,
                    50.1670462
                 ],
                 [
                    94.52250159999996,
                    50.16709739999998
                 ],
                 [
                    94.5225472,
                    50.167132500000044
                 ],
                 [
                    94.52264730000002,
                    50.1671439
                 ],
                 [
                    94.52291780000002,
                    50.16716379999997
                 ],
                 [
                    94.52314169999998,
                    50.167203
                 ],
                 [
                    94.5233469,
                    50.167265400000026
                 ],
                 [
                    94.5234702,
                    50.16735040000004
                 ],
                 [
                    94.523522,
                    50.1674765
                 ],
                 [
                    94.52353239999998,
                    50.16769959999998
                 ],
                 [
                    94.5235531,
                    50.16788079999996
                 ],
                 [
                    94.52354069999996,
                    50.168076
                 ],
                 [
                    94.523494,
                    50.16829170000003
                 ],
                 [
                    94.52344119999998,
                    50.16841249999999
                 ],
                 [
                    94.52335409999998,
                    50.16848690000002
                 ],
                 [
                    94.52323289999998,
                    50.16853
                 ],
                 [
                    94.5231323,
                    50.168546
                 ],
                 [
                    94.52306390000003,
                    50.16853860000003
                 ],
                 [
                    94.52299549999996,
                    50.1685114
                 ],
                 [
                    94.52285870000001,
                    50.16842309999998
                 ],
                 [
                    94.52275609999997,
                    50.1683966
                 ],
                 [
                    94.5225178,
                    50.16838459999999
                 ],
                 [
                    94.5223602,
                    50.1683289
                 ],
                 [
                    94.5222369,
                    50.168225300000046
                 ],
                 [
                    94.52215250000003,
                    50.168068
                 ],
                 [
                    94.5220909,
                    50.16801829999997
                 ],
                 [
                    94.52193790000004,
                    50.16797759999998
                 ],
                 [
                    94.52177629999998,
                    50.1679683
                 ],
                 [
                    94.52162740000001,
                    50.167996700000046
                 ],
                 [
                    94.52146179999998,
                    50.168081200000024
                 ],
                 [
                    94.521242,
                    50.16817509999998
                 ],
                 [
                    94.5210485,
                    50.16827460000004
                 ],
                 [
                    94.52097080000004,
                    50.16834220000003
                 ],
                 [
                    94.52084620000004,
                    50.16849049999996
                 ],
                 [
                    94.520729,
                    50.16862760000002
                 ],
                 [
                    94.5206162,
                    50.1687766
                 ],
                 [
                    94.52049750000005,
                    50.168877099999975
                 ],
                 [
                    94.5202307,
                    50.1690545
                 ],
                 [
                    94.52004459999996,
                    50.169161499999966
                 ],
                 [
                    94.5198614,
                    50.16922069999998
                 ],
                 [
                    94.51977490000003,
                    50.16928829999998
                 ],
                 [
                    94.5197573,
                    50.169354
                 ],
                 [
                    94.519772,
                    50.16941589999997
                 ],
                 [
                    94.519813,
                    50.169468499999965
                 ],
                 [
                    94.51987460000002,
                    50.169484500000046
                 ],
                 [
                    94.5200739,
                    50.1694807
                 ],
                 [
                    94.52030989999999,
                    50.169461900000044
                 ],
                 [
                    94.52055459999998,
                    50.1694281
                 ],
                 [
                    94.52072910000003,
                    50.169425300000036
                 ],
                 [
                    94.520858,
                    50.16944030000003
                 ],
                 [
                    94.52095179999998,
                    50.16949759999998
                 ],
                 [
                    94.52102540000003,
                    50.16958320000004
                 ],
                 [
                    94.52105130000004,
                    50.16968940000004
                 ],
                 [
                    94.52102019999995,
                    50.16976780000004
                 ],
                 [
                    94.52095489999996,
                    50.16982289999998
                 ],
                 [
                    94.5207621,
                    50.16991319999999
                 ],
                 [
                    94.5206258,
                    50.169988299999986
                 ],
                 [
                    94.52051159999998,
                    50.170122299999974
                 ],
                 [
                    94.52046190000003,
                    50.170228499999965
                 ],
                 [
                    94.52042870000004,
                    50.170420300000025
                 ],
                 [
                    94.52039659999996,
                    50.17049
                 ],
                 [
                    94.52023879999996,
                    50.17063209999996
                 ],
                 [
                    94.52020449999996,
                    50.1706923
                 ],
                 [
                    94.52020139999998,
                    50.17073270000003
                 ],
                 [
                    94.520276,
                    50.170827
                 ],
                 [
                    94.5204304,
                    50.1709996
                 ],
                 [
                    94.520533,
                    50.1711002
                 ],
                 [
                    94.52065170000002,
                    50.17116029999997
                 ],
                 [
                    94.5209918,
                    50.17131049999996
                 ],
                 [
                    94.52119989999998,
                    50.1714288
                 ],
                 [
                    94.52124819999999,
                    50.1714935
                 ],
                 [
                    94.52125959999995,
                    50.171565899999955
                 ],
                 [
                    94.5212451,
                    50.1716834
                 ],
                 [
                    94.52125139999998,
                    50.171786900000036
                 ],
                 [
                    94.52129590000003,
                    50.17193759999998
                 ],
                 [
                    94.5213363,
                    50.1720624
                 ],
                 [
                    94.5213954,
                    50.1721181
                 ],
                 [
                    94.5214607,
                    50.172156
                 ],
                 [
                    94.52169080000002,
                    50.172252899999954
                 ],
                 [
                    94.52183790000002,
                    50.17232660000001
                 ],
                 [
                    94.52194779999996,
                    50.1724102
                 ],
                 [
                    94.52203379999997,
                    50.172523
                 ],
                 [
                    94.52206150000005,
                    50.172634299999984
                 ],
                 [
                    94.52202060000003,
                    50.172717899999974
                 ],
                 [
                    94.5219374,
                    50.17279910000003
                 ],
                 [
                    94.521824,
                    50.17282859999998
                 ],
                 [
                    94.521634,
                    50.1728295
                 ],
                 [
                    94.52149670000003,
                    50.1728295
                 ],
                 [
                    94.52143469999997,
                    50.1728439
                 ],
                 [
                    94.52138720000004,
                    50.172897099999965
                 ],
                 [
                    94.52137540000004,
                    50.1730146
                 ],
                 [
                    94.521349,
                    50.173100800000036
                 ],
                 [
                    94.5212869,
                    50.1732013
                 ],
                 [
                    94.52123150000003,
                    50.17323260000001
                 ],
                 [
                    94.5211722,
                    50.173239300000034
                 ],
                 [
                    94.52104020000002,
                    50.173212300000024
                 ],
                 [
                    94.52094519999997,
                    50.17321650000002
                 ],
                 [
                    94.520837,
                    50.1732571
                 ],
                 [
                    94.5207803,
                    50.17332810000001
                 ],
                 [
                    94.52078950000005,
                    50.17346240000002
                 ],
                 [
                    94.52077630000002,
                    50.17356719999999
                 ],
                 [
                    94.52072490000003,
                    50.17363149999998
                 ],
                 [
                    94.52054279999997,
                    50.17371
                 ],
                 [
                    94.52044509999998,
                    50.17375149999996
                 ],
                 [
                    94.52041079999998,
                    50.17380979999999
                 ],
                 [
                    94.520421,
                    50.17391860000002
                 ],
                 [
                    94.5203744,
                    50.1741277
                 ],
                 [
                    94.5202886,
                    50.174252
                 ],
                 [
                    94.52020090000002,
                    50.1742962
                 ],
                 [
                    94.52004979999995,
                    50.1742908
                 ],
                 [
                    94.519972,
                    50.17427139999999
                 ],
                 [
                    94.5199192,
                    50.17421979999998
                 ],
                 [
                    94.51986510000003,
                    50.174105799999964
                 ],
                 [
                    94.51982950000003,
                    50.17407029999996
                 ],
                 [
                    94.51971069999996,
                    50.174028
                 ],
                 [
                    94.51950750000003,
                    50.1740213
                 ],
                 [
                    94.51939140000002,
                    50.17404660000004
                 ],
                 [
                    94.5192238,
                    50.17416489999997
                 ],
                 [
                    94.5190972,
                    50.17428240000001
                 ],
                 [
                    94.51899689999996,
                    50.1743381
                 ],
                 [
                    94.5188438,
                    50.17445809999998
                 ],
                 [
                    94.51876069999996,
                    50.174586599999955
                 ],
                 [
                    94.5186644,
                    50.174737
                 ],
                 [
                    94.51851660000001,
                    50.17486540000002
                 ],
                 [
                    94.51837410000005,
                    50.17493559999997
                 ],
                 [
                    94.5181353,
                    50.1750209
                 ],
                 [
                    94.51790570000001,
                    50.1751485
                 ],
                 [
                    94.51776589999999,
                    50.175217799999956
                 ],
                 [
                    94.51763520000002,
                    50.175233900000045
                 ],
                 [
                    94.51751649999996,
                    50.17521950000001
                 ],
                 [
                    94.51741880000004,
                    50.17517389999997
                 ],
                 [
                    94.51726160000001,
                    50.1751249
                 ],
                 [
                    94.5171197,
                    50.17510930000004
                 ],
                 [
                    94.51700029999996,
                    50.17514279999997
                 ],
                 [
                    94.516907,
                    50.175207299999975
                 ],
                 [
                    94.51688090000003,
                    50.1753364
                 ],
                 [
                    94.51680440000001,
                    50.17553950000002
                 ],
                 [
                    94.51678760000004,
                    50.1756208
                 ],
                 [
                    94.5168697,
                    50.175757
                 ],
                 [
                    94.516898,
                    50.1758546
                 ],
                 [
                    94.51682590000001,
                    50.17591420000002
                 ],
                 [
                    94.5167475,
                    50.1759226
                 ],
                 [
                    94.51666080000004,
                    50.17591960000003
                 ],
                 [
                    94.51660759999999,
                    50.175893900000034
                 ],
                 [
                    94.51656,
                    50.17583360000002
                 ],
                 [
                    94.5164574,
                    50.1757947
                 ],
                 [
                    94.51638089999997,
                    50.1757852
                 ],
                 [
                    94.5162596,
                    50.175797699999976
                 ],
                 [
                    94.51612239999999,
                    50.175836
                 ],
                 [
                    94.51599649999996,
                    50.1758581
                 ],
                 [
                    94.51584629999996,
                    50.175861099999985
                 ],
                 [
                    94.51562050000003,
                    50.175861700000034
                 ],
                 [
                    94.5155291,
                    50.17586580000002
                 ],
                 [
                    94.5154162,
                    50.17584550000002
                 ],
                 [
                    94.5152081,
                    50.1757696
                 ],
                 [
                    94.5150588,
                    50.17570749999997
                 ],
                 [
                    94.51498610000003,
                    50.17569610000001
                 ],
                 [
                    94.51487780000004,
                    50.175702099999974
                 ],
                 [
                    94.5148377,
                    50.175712300000015
                 ],
                 [
                    94.5148153,
                    50.17573979999998
                 ],
                 [
                    94.5147948,
                    50.17583839999997
                 ],
                 [
                    94.51477240000001,
                    50.17586050000002
                 ],
                 [
                    94.5147174,
                    50.1758634
                 ],
                 [
                    94.51465489999998,
                    50.175849700000015
                 ],
                 [
                    94.51453640000003,
                    50.175763099999955
                 ],
                 [
                    94.51435910000002,
                    50.1756734
                 ],
                 [
                    94.51415009999995,
                    50.17560290000002
                 ],
                 [
                    94.5138711,
                    50.175547399999964
                 ],
                 [
                    94.513636,
                    50.1755241
                 ],
                 [
                    94.51337390000003,
                    50.17555089999998
                 ],
                 [
                    94.51315270000003,
                    50.17554920000002
                 ],
                 [
                    94.51294,
                    50.17554620000004
                 ],
                 [
                    94.51280470000002,
                    50.17556650000003
                 ],
                 [
                    94.5127198,
                    50.175594
                 ],
                 [
                    94.51269139999997,
                    50.17562990000004
                 ],
                 [
                    94.51266889999997,
                    50.175834199999976
                 ],
                 [
                    94.51263449999996,
                    50.17591870000004
                 ],
                 [
                    94.51250340000004,
                    50.1760181
                 ],
                 [
                    94.5122738,
                    50.1761544
                 ],
                 [
                    94.51213859999997,
                    50.17624519999997
                 ],
                 [
                    94.51197160000001,
                    50.17633
                 ],
                 [
                    94.5118363,
                    50.17636229999997
                 ],
                 [
                    94.51169629999997,
                    50.17635689999997
                 ],
                 [
                    94.51150319999998,
                    50.17633649999997
                 ],
                 [
                    94.51144050000002,
                    50.17633989999999
                 ],
                 [
                    94.51139630000002,
                    50.17635760000003
                 ],
                 [
                    94.51137720000003,
                    50.17638460000004
                 ],
                 [
                    94.51138570000002,
                    50.1764227
                 ],
                 [
                    94.51143850000004,
                    50.17648980000004
                 ],
                 [
                    94.51152459999997,
                    50.17660030000004
                 ],
                 [
                    94.5115616,
                    50.1766975
                 ],
                 [
                    94.5115444,
                    50.17677019999997
                 ],
                 [
                    94.51149960000004,
                    50.1768285
                 ],
                 [
                    94.5113531,
                    50.1768843
                 ],
                 [
                    94.51113670000002,
                    50.1769079
                 ],
                 [
                    94.5109464,
                    50.1768798
                 ],
                 [
                    94.51080090000004,
                    50.17683860000004
                 ],
                 [
                    94.51066560000004,
                    50.17685
                 ],
                 [
                    94.510604,
                    50.17688519999999
                 ],
                 [
                    94.51059,
                    50.17693
                 ],
                 [
                    94.51062130000001,
                    50.17701059999997
                 ],
                 [
                    94.51068939999996,
                    50.177106800000026
                 ],
                 [
                    94.5107585,
                    50.17720240000003
                 ],
                 [
                    94.510778,
                    50.17727349999996
                 ],
                 [
                    94.51074910000001,
                    50.177347
                 ],
                 [
                    94.510668,
                    50.17738519999997
                 ],
                 [
                    94.510528,
                    50.17741150000001
                 ],
                 [
                    94.5103806,
                    50.17741629999996
                 ],
                 [
                    94.51032090000002,
                    50.177440800000035
                 ],
                 [
                    94.510278,
                    50.177497
                 ],
                 [
                    94.51025840000001,
                    50.17757639999998
                 ],
                 [
                    94.510277,
                    50.17763380000002
                 ],
                 [
                    94.5103153,
                    50.17767680000002
                 ],
                 [
                    94.51043470000003,
                    50.17775569999998
                 ],
                 [
                    94.51048139999999,
                    50.17779390000003
                 ],
                 [
                    94.51050379999997,
                    50.177862
                 ],
                 [
                    94.51049720000005,
                    50.17791520000003
                 ],
                 [
                    94.51046460000002,
                    50.177988699999986
                 ],
                 [
                    94.510416,
                    50.17804840000004
                 ],
                 [
                    94.51030849999998,
                    50.17809489999998
                 ],
                 [
                    94.51023590000001,
                    50.17811219999999
                 ],
                 [
                    94.51019310000002,
                    50.17811140000001
                 ],
                 [
                    94.51015609999997,
                    50.17809530000001
                 ],
                 [
                    94.51013430000005,
                    50.178059399999974
                 ],
                 [
                    94.51011849999998,
                    50.1779901
                 ],
                 [
                    94.51005910000003,
                    50.17794659999996
                 ],
                 [
                    94.50993709999997,
                    50.1779095
                 ],
                 [
                    94.5098117,
                    50.17789929999996
                 ],
                 [
                    94.50970030000002,
                    50.17791830000002
                 ],
                 [
                    94.50960060000003,
                    50.17797410000002
                 ],
                 [
                    94.50941590000002,
                    50.178007899999976
                 ],
                 [
                    94.509311,
                    50.178031499999975
                 ],
                 [
                    94.50918830000002,
                    50.178043
                 ],
                 [
                    94.5090894,
                    50.1780844
                 ],
                 [
                    94.50899239999997,
                    50.178100800000024
                 ],
                 [
                    94.5089132,
                    50.1780983
                 ],
                 [
                    94.50883009999995,
                    50.17807510000003
                 ],
                 [
                    94.50877659999998,
                    50.17803369999998
                 ],
                 [
                    94.50876279999999,
                    50.17799729999999
                 ],
                 [
                    94.508772,
                    50.17796609999998
                 ],
                 [
                    94.50882350000003,
                    50.177904
                 ],
                 [
                    94.50884859999998,
                    50.1778511
                 ],
                 [
                    94.50885189999998,
                    50.177806800000035
                 ],
                 [
                    94.50884060000003,
                    50.1777599
                 ],
                 [
                    94.50881229999999,
                    50.17772649999996
                 ],
                 [
                    94.50876210000001,
                    50.177698200000016
                 ],
                 [
                    94.50870469999997,
                    50.17767829999997
                 ],
                 [
                    94.5085891,
                    50.17767150000003
                 ],
                 [
                    94.50846219999998,
                    50.1776775
                 ],
                 [
                    94.5084184,
                    50.17767389999996
                 ],
                 [
                    94.5083325,
                    50.17763810000002
                 ],
                 [
                    94.50826259999998,
                    50.177613
                 ],
                 [
                    94.50818890000002,
                    50.1775981
                 ],
                 [
                    94.50813010000003,
                    50.177595699999976
                 ],
                 [
                    94.50803770000003,
                    50.177622
                 ],
                 [
                    94.50792950000005,
                    50.17765960000002
                 ],
                 [
                    94.50783529999998,
                    50.177685300000014
                 ],
                 [
                    94.50779139999999,
                    50.177679900000015
                 ],
                 [
                    94.507755,
                    50.17765479999998
                 ],
                 [
                    94.507741,
                    50.177622
                 ],
                 [
                    94.50775689999998,
                    50.17757060000003
                 ],
                 [
                    94.50784269999997,
                    50.17746479999998
                 ],
                 [
                    94.50788940000001,
                    50.177371
                 ],
                 [
                    94.5078828,
                    50.177302300000036
                 ],
                 [
                    94.50785669999998,
                    50.17726409999998
                 ],
                 [
                    94.50776339999999,
                    50.1772396
                 ],
                 [
                    94.507728,
                    50.1772073
                 ],
                 [
                    94.507714,
                    50.1771463
                 ],
                 [
                    94.50767109999998,
                    50.17712129999998
                 ],
                 [
                    94.50760850000005,
                    50.1771183
                 ],
                 [
                    94.50749479999996,
                    50.17714110000002
                 ],
                 [
                    94.50734730000002,
                    50.17719250000001
                 ],
                 [
                    94.50718029999996,
                    50.1772385
                 ],
                 [
                    94.50706930000001,
                    50.17724440000004
                 ],
                 [
                    94.507004,
                    50.177215799999985
                 ],
                 [
                    94.50698069999997,
                    50.1771494
                 ],
                 [
                    94.506948,
                    50.17710460000002
                 ],
                 [
                    94.50686130000004,
                    50.1770646
                 ],
                 [
                    94.50676049999996,
                    50.17708249999997
                 ],
                 [
                    94.506614,
                    50.1770957
                 ],
                 [
                    94.50649649999997,
                    50.17705860000003
                 ],
                 [
                    94.50641619999998,
                    50.176973199999985
                 ],
                 [
                    94.50636679999998,
                    50.1769433
                 ],
                 [
                    94.50629589999997,
                    50.176926
                 ],
                 [
                    94.5061643,
                    50.1769337
                 ],
                 [
                    94.50606260000004,
                    50.176956400000016
                 ],
                 [
                    94.50599730000005,
                    50.1770162
                 ],
                 [
                    94.50594689999997,
                    50.17710580000003
                 ],
                 [
                    94.50587790000003,
                    50.1771763
                 ],
                 [
                    94.50581169999997,
                    50.17720079999999
                 ],
                 [
                    94.5057239,
                    50.177208
                 ],
                 [
                    94.50559430000001,
                    50.177202
                 ],
                 [
                    94.5055234,
                    50.17722529999997
                 ],
                 [
                    94.50547760000003,
                    50.17725160000001
                 ],
                 [
                    94.50547579999997,
                    50.1772857
                 ],
                 [
                    94.505515,
                    50.17731610000002
                 ],
                 [
                    94.50563349999997,
                    50.17736210000001
                 ],
                 [
                    94.50570339999997,
                    50.177405200000024
                 ],
                 [
                    94.50576129999997,
                    50.17747029999999
                 ],
                 [
                    94.50577150000002,
                    50.17754859999999
                 ],
                 [
                    94.505751,
                    50.17760529999997
                 ],
                 [
                    94.5057025,
                    50.17764179999997
                 ],
                 [
                    94.50564280000003,
                    50.1776507
                 ],
                 [
                    94.50558490000003,
                    50.177637599999976
                 ],
                 [
                    94.50554850000005,
                    50.17758980000002
                 ],
                 [
                    94.50549910000004,
                    50.1775766
                 ],
                 [
                    94.50538730000002,
                    50.17759779999997
                 ],
                 [
                    94.50528469999998,
                    50.177664
                 ],
                 [
                    94.505253,
                    50.177716799999956
                 ],
                 [
                    94.5052614,
                    50.177811499999976
                 ],
                 [
                    94.5052344,
                    50.177865299999986
                 ],
                 [
                    94.50517959999998,
                    50.177921099999985
                 ],
                 [
                    94.50509850000002,
                    50.1779663
                 ],
                 [
                    94.50495729999997,
                    50.1779895
                 ],
                 [
                    94.50483,
                    50.17799330000001
                 ],
                 [
                    94.50477980000004,
                    50.17798490000003
                 ],
                 [
                    94.50472510000003,
                    50.17794730000002
                 ],
                 [
                    94.50467230000002,
                    50.17789739999998
                 ],
                 [
                    94.50459710000001,
                    50.1778729
                 ],
                 [
                    94.50453769999999,
                    50.17786450000001
                 ],
                 [
                    94.50447109999998,
                    50.177871599999975
                 ],
                 [
                    94.50443510000002,
                    50.17788679999999
                 ],
                 [
                    94.50441639999998,
                    50.17792319999998
                 ],
                 [
                    94.50442389999998,
                    50.17802020000001
                 ],
                 [
                    94.50441639999998,
                    50.17805719999997
                 ],
                 [
                    94.5043866,
                    50.17807510000003
                 ],
                 [
                    94.504269,
                    50.17807930000002
                 ],
                 [
                    94.50419910000004,
                    50.1780704
                 ],
                 [
                    94.50413560000003,
                    50.178041700000016
                 ],
                 [
                    94.50410670000002,
                    50.17797479999999
                 ],
                 [
                    94.5040955,
                    50.17788390000002
                 ],
                 [
                    94.50406470000001,
                    50.1778493
                 ],
                 [
                    94.50398909999998,
                    50.177811599999984
                 ],
                 [
                    94.50379689999997,
                    50.177759700000045
                 ],
                 [
                    94.5037027,
                    50.17775190000002
                 ],
                 [
                    94.503657,
                    50.1777656
                 ],
                 [
                    94.5036243,
                    50.17778720000001
                 ],
                 [
                    94.50360750000003,
                    50.177811100000035
                 ],
                 [
                    94.5036094,
                    50.17784750000003
                 ],
                 [
                    94.5035954,
                    50.1778857
                 ],
                 [
                    94.50353880000003,
                    50.1779428
                 ],
                 [
                    94.503424,
                    50.1779952
                 ],
                 [
                    94.50322740000001,
                    50.17802729999998
                 ],
                 [
                    94.50305460000001,
                    50.178051
                 ],
                 [
                    94.50297540000004,
                    50.1780468
                 ],
                 [
                    94.502854,
                    50.17799440000001
                 ],
                 [
                    94.50280260000004,
                    50.177991
                 ],
                 [
                    94.50255929999999,
                    50.178123699999965
                 ],
                 [
                    94.50250610000002,
                    50.17817979999999
                 ],
                 [
                    94.50244639999997,
                    50.1782498
                 ],
                 [
                    94.502368,
                    50.17829930000001
                 ],
                 [
                    94.50228219999997,
                    50.17831909999997
                 ],
                 [
                    94.50214880000003,
                    50.17832439999996
                 ],
                 [
                    94.50205449999996,
                    50.178366299999965
                 ],
                 [
                    94.5019659,
                    50.1784409
                 ],
                 [
                    94.50190429999996,
                    50.178557499999975
                 ],
                 [
                    94.50179140000004,
                    50.1786286
                 ],
                 [
                    94.50168129999999,
                    50.1786716
                 ],
                 [
                    94.50158149999999,
                    50.17868829999996
                 ],
                 [
                    94.50149849999997,
                    50.17866980000003
                 ],
                 [
                    94.50140519999997,
                    50.17867520000003
                 ],
                 [
                    94.50136730000003,
                    50.17870620000002
                 ],
                 [
                    94.50136860000005,
                    50.178801700000015
                 ],
                 [
                    94.50138440000002,
                    50.17894450000002
                 ],
                 [
                    94.5013818,
                    50.179075500000025
                 ],
                 [
                    94.50133959999997,
                    50.1791684
                 ],
                 [
                    94.5012855,
                    50.17922419999998
                 ],
                 [
                    94.5011561,
                    50.179285900000025
                 ],
                 [
                    94.5010106,
                    50.17931280000003
                 ],
                 [
                    94.5008408,
                    50.1793253
                 ],
                 [
                    94.50072230000005,
                    50.17932470000004
                 ],
                 [
                    94.50062340000004,
                    50.179291300000024
                 ],
                 [
                    94.50054969999998,
                    50.17923870000003
                 ],
                 [
                    94.50045360000001,
                    50.17914550000004
                 ],
                 [
                    94.50038920000001,
                    50.17911620000002
                 ],
                 [
                    94.50025390000002,
                    50.179096499999986
                 ],
                 [
                    94.50006730000003,
                    50.17910130000003
                 ],
                 [
                    94.4999403,
                    50.179114
                 ],
                 [
                    94.4998524,
                    50.179153299999975
                 ],
                 [
                    94.49981480000001,
                    50.1791773
                 ],
                 [
                    94.49981439999998,
                    50.179224
                 ],
                 [
                    94.4998437,
                    50.17933010000004
                 ],
                 [
                    94.4998384,
                    50.17938040000003
                 ],
                 [
                    94.49981,
                    50.179403200000046
                 ],
                 [
                    94.49972229999999,
                    50.179437
                 ],
                 [
                    94.49965439999997,
                    50.1794594
                 ],
                 [
                    94.499624,
                    50.17952149999998
                 ],
                 [
                    94.49960819999997,
                    50.179606
                 ],
                 [
                    94.49959170000002,
                    50.1796677
                 ],
                 [
                    94.49953820000003,
                    50.1797247
                 ],
                 [
                    94.49952830000002,
                    50.17978850000004
                 ],
                 [
                    94.49954619999998,
                    50.179870499999986
                 ],
                 [
                    94.4995679,
                    50.179980700000044
                 ],
                 [
                    94.49955210000003,
                    50.18005760000001
                 ],
                 [
                    94.49947890000001,
                    50.1801734
                 ],
                 [
                    94.49939640000002,
                    50.18026419999997
                 ],
                 [
                    94.49935220000002,
                    50.18030260000003
                 ],
                 [
                    94.49925590000004,
                    50.1803474
                 ],
                 [
                    94.49921629999996,
                    50.180381199999964
                 ],
                 [
                    94.49919650000001,
                    50.18045639999997
                 ],
                 [
                    94.49922419999999,
                    50.18054559999998
                 ],
                 [
                    94.49926970000003,
                    50.180631299999966
                 ],
                 [
                    94.49927569999998,
                    50.18070989999998
                 ],
                 [
                    94.49925919999995,
                    50.1807809
                 ],
                 [
                    94.49918790000001,
                    50.180859
                 ],
                 [
                    94.4991437,
                    50.18091230000003
                 ],
                 [
                    94.49913049999996,
                    50.18096629999997
                 ],
                 [
                    94.49914769999997,
                    50.181012
                 ],
                 [
                    94.49921370000001,
                    50.18103939999998
                 ],
                 [
                    94.4993199,
                    50.18108549999997
                 ],
                 [
                    94.499372,
                    50.181154700000015
                 ],
                 [
                    94.499376,
                    50.181183900000036
                 ],
                 [
                    94.4993562,
                    50.18121940000004
                 ],
                 [
                    94.49923410000001,
                    50.181271299999985
                 ],
                 [
                    94.4991437,
                    50.18131910000003
                 ],
                 [
                    94.4990144,
                    50.18139640000003
                 ],
                 [
                    94.4989452,
                    50.18145640000002
                 ],
                 [
                    94.49888970000002,
                    50.18148130000004
                 ],
                 [
                    94.49882239999997,
                    50.181478399999975
                 ],
                 [
                    94.498771,
                    50.18145640000002
                 ],
                 [
                    94.49871050000003,
                    50.18137590000002
                 ],
                 [
                    94.49865350000002,
                    50.1813508
                 ],
                 [
                    94.49841750000003,
                    50.18133830000002
                 ],
                 [
                    94.49827939999997,
                    50.18131609999996
                 ],
                 [
                    94.49810309999995,
                    50.18125160000004
                 ],
                 [
                    94.49801910000002,
                    50.18119069999997
                 ],
                 [
                    94.4980135,
                    50.18111779999998
                 ],
                 [
                    94.49799020000003,
                    50.1810718
                 ],
                 [
                    94.4978997,
                    50.1809828
                 ],
                 [
                    94.49772060000002,
                    50.1808764
                 ],
                 [
                    94.4976301,
                    50.180831
                 ],
                 [
                    94.49757590000003,
                    50.1808304
                 ],
                 [
                    94.49754140000002,
                    50.18085249999997
                 ],
                 [
                    94.49752650000003,
                    50.1809236
                 ],
                 [
                    94.497492,
                    50.18097320000001
                 ],
                 [
                    94.49742389999999,
                    50.1810138
                 ],
                 [
                    94.49723350000004,
                    50.1810288
                 ],
                 [
                    94.49705530000004,
                    50.18102339999999
                 ],
                 [
                    94.49692750000003,
                    50.181048500000024
                 ],
                 [
                    94.49674920000002,
                    50.18112
                 ],
                 [
                    94.49663929999998,
                    50.181162300000025
                 ],
                 [
                    94.4964431,
                    50.181158
                 ],
                 [
                    94.49620959999996,
                    50.181093
                 ],
                 [
                    94.4960962,
                    50.18106509999998
                 ],
                 [
                    94.49592519999997,
                    50.18112470000002
                 ],
                 [
                    94.49574240000003,
                    50.1811653
                 ],
                 [
                    94.4955978,
                    50.18118209999997
                 ],
                 [
                    94.49543450000004,
                    50.1811492
                 ],
                 [
                    94.495344,
                    50.18115519999997
                 ],
                 [
                    94.495233,
                    50.1811868
                 ],
                 [
                    94.4951807,
                    50.1812221
                 ],
                 [
                    94.49516949999997,
                    50.181298
                 ],
                 [
                    94.49517230000002,
                    50.18138460000003
                 ],
                 [
                    94.495135,
                    50.18141509999998
                 ],
                 [
                    94.4950464,
                    50.181442
                 ],
                 [
                    94.49495870000003,
                    50.18143480000001
                 ],
                 [
                    94.49489709999999,
                    50.1813971
                 ],
                 [
                    94.49478050000002,
                    50.18133139999998
                 ],
                 [
                    94.49470779999996,
                    50.18126640000002
                 ],
                 [
                    94.49466880000001,
                    50.181141
                 ],
                 [
                    94.49465229999998,
                    50.18109529999999
                 ],
                 [
                    94.49461080000002,
                    50.181061500000034
                 ],
                 [
                    94.49451379999998,
                    50.1810193
                 ],
                 [
                    94.49439369999999,
                    50.180992700000026
                 ],
                 [
                    94.4943132,
                    50.181003699999955
                 ],
                 [
                    94.49425589999996,
                    50.181048899999965
                 ],
                 [
                    94.49424529999997,
                    50.18109529999999
                 ],
                 [
                    94.49426650000004,
                    50.181146
                 ],
                 [
                    94.4943262,
                    50.181230200000044
                 ],
                 [
                    94.49433459999999,
                    50.1812834
                 ],
                 [
                    94.49428120000002,
                    50.18132440000002
                 ],
                 [
                    94.49427319999997,
                    50.181356
                 ],
                 [
                    94.49429889999996,
                    50.181414600000025
                 ],
                 [
                    94.494312,
                    50.1814905
                 ],
                 [
                    94.4942866,
                    50.181562
                 ],
                 [
                    94.494218,
                    50.18160980000001
                 ],
                 [
                    94.49416450000004,
                    50.18165070000002
                 ],
                 [
                    94.49415930000005,
                    50.18169040000002
                 ],
                 [
                    94.49416650000002,
                    50.18171329999996
                 ],
                 [
                    94.49420610000001,
                    50.181747500000036
                 ],
                 [
                    94.4943295,
                    50.181813800000015
                 ],
                 [
                    94.49444690000004,
                    50.1818489
                 ],
                 [
                    94.49451149999997,
                    50.18184669999999
                 ],
                 [
                    94.49457750000002,
                    50.18182819999996
                 ],
                 [
                    94.49465470000001,
                    50.1818028
                 ],
                 [
                    94.49473120000003,
                    50.181804499999956
                 ],
                 [
                    94.49478270000003,
                    50.18183530000002
                 ],
                 [
                    94.49479650000002,
                    50.1818822
                 ],
                 [
                    94.4947919,
                    50.1819135
                 ],
                 [
                    94.49474180000001,
                    50.181946
                 ],
                 [
                    94.4946653,
                    50.18200639999996
                 ],
                 [
                    94.49463890000003,
                    50.182058800000036
                 ],
                 [
                    94.49465930000004,
                    50.1821099
                 ],
                 [
                    94.49469690000004,
                    50.18213820000003
                 ],
                 [
                    94.4947985,
                    50.18215940000001
                 ],
                 [
                    94.4948929,
                    50.182182599999976
                 ],
                 [
                    94.49491729999997,
                    50.1822232
                 ],
                 [
                    94.49491660000001,
                    50.182265
                 ],
                 [
                    94.49486850000002,
                    50.18232790000001
                 ],
                 [
                    94.49487639999997,
                    50.18235879999999
                 ],
                 [
                    94.49491920000004,
                    50.18240349999996
                 ],
                 [
                    94.49496349999998,
                    50.18241410000004
                 ],
                 [
                    94.4950175,
                    50.18241539999997
                 ],
                 [
                    94.49509280000002,
                    50.18239509999997
                 ],
                 [
                    94.49515210000004,
                    50.1823985
                 ],
                 [
                    94.49522729999995,
                    50.18241920000001
                 ],
                 [
                    94.4952999,
                    50.182461
                 ],
                 [
                    94.4953329,
                    50.1825125
                 ],
                 [
                    94.4953243,
                    50.182548
                 ],
                 [
                    94.49529069999996,
                    50.18258270000004
                 ],
                 [
                    94.49519040000001,
                    50.18262450000004
                 ],
                 [
                    94.49513890000001,
                    50.182676
                 ],
                 [
                    94.49511980000003,
                    50.18275210000002
                 ],
                 [
                    94.49510990000002,
                    50.1828184
                 ],
                 [
                    94.495067,
                    50.1828458
                 ],
                 [
                    94.494941,
                    50.182882599999985
                 ],
                 [
                    94.494788,
                    50.182914300000036
                 ],
                 [
                    94.49470809999998,
                    50.18294469999997
                 ],
                 [
                    94.49465539999997,
                    50.1829772
                 ],
                 [
                    94.49463489999997,
                    50.1830254
                 ],
                 [
                    94.49462959999997,
                    50.183120400000035
                 ],
                 [
                    94.49463819999997,
                    50.183183
                 ],
                 [
                    94.4946606,
                    50.18321289999996
                 ],
                 [
                    94.494757,
                    50.183270800000045
                 ],
                 [
                    94.49479789999995,
                    50.18330290000004
                 ],
                 [
                    94.49481370000002,
                    50.18333759999997
                 ],
                 [
                    94.49480310000003,
                    50.18337260000003
                 ],
                 [
                    94.49474439999997,
                    50.18341149999996
                 ],
                 [
                    94.49461310000002,
                    50.183442300000024
                 ],
                 [
                    94.4945729,
                    50.18347990000002
                 ],
                 [
                    94.4944753,
                    50.183587699999975
                 ],
                 [
                    94.494369,
                    50.18366330000001
                 ],
                 [
                    94.49428059999997,
                    50.183740600000014
                 ],
                 [
                    94.49422259999996,
                    50.183802200000045
                 ],
                 [
                    94.4941296,
                    50.18384360000001
                 ],
                 [
                    94.49405370000002,
                    50.183852500000036
                 ],
                 [
                    94.49396270000004,
                    50.18384790000001
                 ],
                 [
                    94.49391650000004,
                    50.1838327
                 ],
                 [
                    94.4938419,
                    50.18377859999996
                 ],
                 [
                    94.4937443,
                    50.18376340000003
                 ],
                 [
                    94.49366779999997,
                    50.18376340000003
                 ],
                 [
                    94.49360239999999,
                    50.18378409999997
                 ],
                 [
                    94.49356550000003,
                    50.18382929999998
                 ],
                 [
                    94.49349619999998,
                    50.18394380000004
                 ],
                 [
                    94.4934105,
                    50.1840088
                 ],
                 [
                    94.49333919999997,
                    50.1840367
                 ],
                 [
                    94.4932904,
                    50.1840367
                 ],
                 [
                    94.4932548,
                    50.18402610000001
                 ],
                 [
                    94.49323829999996,
                    50.183999899999975
                 ],
                 [
                    94.49324359999996,
                    50.183956400000014
                 ],
                 [
                    94.4932957,
                    50.18385589999996
                 ],
                 [
                    94.4932904,
                    50.18382379999997
                 ],
                 [
                    94.49327059999997,
                    50.183793799999975
                 ],
                 [
                    94.49321850000001,
                    50.1837663
                 ],
                 [
                    94.493074,
                    50.1837309
                 ],
                 [
                    94.4930529,
                    50.18371060000002
                 ],
                 [
                    94.49300080000003,
                    50.183583399999975
                 ],
                 [
                    94.492979,
                    50.18350020000002
                 ],
                 [
                    94.49296379999998,
                    50.18345669999997
                 ],
                 [
                    94.4929282,
                    50.18340939999997
                 ],
                 [
                    94.49289460000004,
                    50.183394599999986
                 ],
                 [
                    94.49284439999997,
                    50.183394599999986
                 ],
                 [
                    94.49280550000003,
                    50.18341279999998
                 ],
                 [
                    94.4927461,
                    50.18347189999999
                 ],
                 [
                    94.49269470000002,
                    50.18353910000004
                 ],
                 [
                    94.49261020000004,
                    50.18362820000004
                 ],
                 [
                    94.492504,
                    50.1837393
                 ],
                 [
                    94.4924433,
                    50.183820799999985
                 ],
                 [
                    94.49245059999998,
                    50.18390950000004
                 ],
                 [
                    94.49247429999998,
                    50.1839809
                 ],
                 [
                    94.49246510000003,
                    50.18400710000004
                 ],
                 [
                    94.4924347,
                    50.18402569999998
                 ],
                 [
                    94.49238460000002,
                    50.18403160000002
                 ],
                 [
                    94.4923358,
                    50.18401980000002
                 ],
                 [
                    94.49224410000004,
                    50.1839788
                 ],
                 [
                    94.49211410000002,
                    50.1839742
                 ],
                 [
                    94.49202969999997,
                    50.18398140000004
                 ],
                 [
                    94.49197619999998,
                    50.18396530000004
                 ],
                 [
                    94.49194589999996,
                    50.1839379
                 ],
                 [
                    94.49192940000002,
                    50.18386730000002
                 ],
                 [
                    94.49186210000005,
                    50.183828
                 ],
                 [
                    94.49176510000002,
                    50.183796800000046
                 ],
                 [
                    94.49170180000002,
                    50.18378829999996
                 ],
                 [
                    94.49166210000003,
                    50.183788
                 ],
                 [
                    94.49163459999997,
                    50.183797
                 ],
                 [
                    94.49162340000002,
                    50.18381309999997
                 ],
                 [
                    94.49162340000002,
                    50.18384209999997
                 ],
                 [
                    94.49164439999998,
                    50.18388179999997
                 ],
                 [
                    94.49169650000003,
                    50.18392870000003
                 ],
                 [
                    94.4917058,
                    50.183978900000014
                 ],
                 [
                    94.49168250000002,
                    50.18401589999996
                 ],
                 [
                    94.4916275,
                    50.18403089999996
                 ],
                 [
                    94.49152109999999,
                    50.18403150000001
                 ],
                 [
                    94.4914353,
                    50.184049399999985
                 ],
                 [
                    94.49138579999999,
                    50.1840817
                 ],
                 [
                    94.491328,
                    50.1842149
                 ],
                 [
                    94.49123940000004,
                    50.18432360000002
                 ],
                 [
                    94.49113019999997,
                    50.18437320000004
                 ],
                 [
                    94.491049,
                    50.18437679999998
                 ],
                 [
                    94.49098280000004,
                    50.1843941
                 ],
                 [
                    94.4908858,
                    50.18445379999996
                 ],
                 [
                    94.49083160000005,
                    50.184461599999985
                 ],
                 [
                    94.49078129999998,
                    50.184452600000036
                 ],
                 [
                    94.49071310000002,
                    50.184420400000036
                 ],
                 [
                    94.49060490000004,
                    50.184324199999985
                 ],
                 [
                    94.49057409999998,
                    50.184231
                 ],
                 [
                    94.4905293,
                    50.184128300000026
                 ],
                 [
                    94.49047520000005,
                    50.18407629999999
                 ],
                 [
                    94.49039970000003,
                    50.184044
                 ],
                 [
                    94.4902858,
                    50.18403629999997
                 ],
                 [
                    94.49008620000002,
                    50.18402549999996
                 ],
                 [
                    94.4899294,
                    50.1839723
                 ],
                 [
                    94.48982030000005,
                    50.18390419999996
                 ],
                 [
                    94.48957970000004,
                    50.183885099999976
                 ],
                 [
                    94.48945430000005,
                    50.18389689999998
                 ],
                 [
                    94.48938039999997,
                    50.18391719999997
                 ],
                 [
                    94.4892541,
                    50.18399830000002
                 ],
                 [
                    94.4891541,
                    50.184024
                 ],
                 [
                    94.4890157,
                    50.184026
                 ],
                 [
                    94.48893709999997,
                    50.184049399999985
                 ],
                 [
                    94.488893,
                    50.184084
                 ],
                 [
                    94.4888892,
                    50.1841247
                 ],
                 [
                    94.48891879999996,
                    50.18418640000004
                 ],
                 [
                    94.48893709999997,
                    50.1842375
                 ],
                 [
                    94.48892070000004,
                    50.18425930000003
                 ],
                 [
                    94.48886280000005,
                    50.184260899999984
                 ],
                 [
                    94.48880370000003,
                    50.1842504
                 ],
                 [
                    94.488742,
                    50.1842005
                 ],
                 [
                    94.48867590000003,
                    50.1841537
                 ],
                 [
                    94.4886187,
                    50.184146500000026
                 ],
                 [
                    94.48856650000005,
                    50.1841558
                 ],
                 [
                    94.48849540000002,
                    50.18420609999998
                 ],
                 [
                    94.48842240000002,
                    50.1842835
                 ],
                 [
                    94.48829460000002,
                    50.18434510000002
                 ],
                 [
                    94.48811530000002,
                    50.18445629999999
                 ],
                 [
                    94.48805110000004,
                    50.18452919999998
                 ],
                 [
                    94.4880518,
                    50.18459650000004
                 ],
                 [
                    94.48807,
                    50.18462630000002
                 ],
                 [
                    94.48810839999997,
                    50.18465369999998
                 ],
                 [
                    94.48820279999997,
                    50.18467150000003
                 ],
                 [
                    94.4883211,
                    50.18467509999997
                 ],
                 [
                    94.4883941,
                    50.184653300000036
                 ],
                 [
                    94.488535,
                    50.1845905
                 ],
                 [
                    94.4886174,
                    50.18456750000004
                 ],
                 [
                    94.4886772,
                    50.18457439999999
                 ],
                 [
                    94.48874450000002,
                    50.18462110000004
                 ],
                 [
                    94.48877660000002,
                    50.18466019999998
                 ],
                 [
                    94.4887968,
                    50.184778199999975
                 ],
                 [
                    94.48882190000003,
                    50.184855200000044
                 ],
                 [
                    94.488832,
                    50.184908
                 ],
                 [
                    94.4888119,
                    50.18493460000004
                 ],
                 [
                    94.48876969999998,
                    50.18494139999998
                 ],
                 [
                    94.48870050000002,
                    50.1849342
                 ],
                 [
                    94.48863060000002,
                    50.18493860000002
                 ],
                 [
                    94.488596,
                    50.1849555
                 ],
                 [
                    94.48858660000002,
                    50.18499660000002
                 ],
                 [
                    94.48858409999998,
                    50.185118299999964
                 ],
                 [
                    94.4885614,
                    50.18513759999996
                 ],
                 [
                    94.48833550000002,
                    50.18520410000004
                 ],
                 [
                    94.48823550000002,
                    50.18525279999999
                 ],
                 [
                    94.4881279,
                    50.185267300000035
                 ],
                 [
                    94.48807319999999,
                    50.185265299999955
                 ],
                 [
                    94.48804739999999,
                    50.1852379
                 ],
                 [
                    94.48800710000003,
                    50.18522059999999
                 ],
                 [
                    94.48789569999997,
                    50.18522630000002
                 ],
                 [
                    94.48781959999997,
                    50.1852174
                 ],
                 [
                    94.48778179999995,
                    50.18519320000003
                 ],
                 [
                    94.48776670000004,
                    50.1851457
                 ],
                 [
                    94.48779440000001,
                    50.18506870000003
                 ],
                 [
                    94.48777110000005,
                    50.185024
                 ],
                 [
                    94.487717,
                    50.18499220000001
                 ],
                 [
                    94.4876384,
                    50.1849793
                 ],
                 [
                    94.48753839999998,
                    50.18499179999998
                 ],
                 [
                    94.487439,
                    50.18503369999998
                 ],
                 [
                    94.48733459999998,
                    50.185048599999966
                 ],
                 [
                    94.48726850000003,
                    50.18503650000003
                 ],
                 [
                    94.4871961,
                    50.18497290000001
                 ],
                 [
                    94.48711810000002,
                    50.18492890000001
                 ],
                 [
                    94.48704259999998,
                    50.18491
                 ],
                 [
                    94.48694570000005,
                    50.1849116
                 ],
                 [
                    94.4869054,
                    50.18493660000003
                 ],
                 [
                    94.48689729999995,
                    50.185005500000045
                 ],
                 [
                    94.4869117,
                    50.18506870000003
                 ],
                 [
                    94.486996,
                    50.18517749999997
                 ],
                 [
                    94.48701110000005,
                    50.18524
                 ],
                 [
                    94.486991,
                    50.1852948
                 ],
                 [
                    94.4869237,
                    50.18532620000002
                 ],
                 [
                    94.4868903,
                    50.18540189999998
                 ],
                 [
                    94.486862,
                    50.185445500000036
                 ],
                 [
                    94.48682740000004,
                    50.185462
                 ],
                 [
                    94.4867777,
                    50.18546319999999
                 ],
                 [
                    94.48673810000001,
                    50.18544950000001
                 ],
                 [
                    94.4867066,
                    50.18541320000003
                 ],
                 [
                    94.4866896,
                    50.185327799999975
                 ],
                 [
                    94.486667,
                    50.18528149999997
                 ],
                 [
                    94.4866166,
                    50.1852658
                 ],
                 [
                    94.48654429999996,
                    50.18526459999999
                 ],
                 [
                    94.4864694,
                    50.18529759999996
                 ],
                 [
                    94.48637880000004,
                    50.18535239999997
                 ],
                 [
                    94.48627059999997,
                    50.18543780000002
                 ],
                 [
                    94.48613590000002,
                    50.18550389999998
                 ],
                 [
                    94.48603840000003,
                    50.1855986
                 ],
                 [
                    94.48588920000003,
                    50.18572430000001
                 ],
                 [
                    94.48578610000003,
                    50.185811700000045
                 ],
                 [
                    94.48573379999996,
                    50.18588340000003
                 ],
                 [
                    94.4857288,
                    50.185949900000026
                 ],
                 [
                    94.485754,
                    50.1860828
                 ],
                 [
                    94.485742,
                    50.18616739999999
                 ],
                 [
                    94.485703,
                    50.18624840000003
                 ],
                 [
                    94.48566150000002,
                    50.1862984
                 ],
                 [
                    94.485591,
                    50.18631769999999
                 ],
                 [
                    94.4855249,
                    50.18632460000003
                 ],
                 [
                    94.4854872,
                    50.186316100000035
                 ],
                 [
                    94.48545259999997,
                    50.1862855
                 ],
                 [
                    94.48540730000003,
                    50.186261300000034
                 ],
                 [
                    94.48534749999997,
                    50.186257299999966
                 ],
                 [
                    94.4852877,
                    50.186264899999976
                 ],
                 [
                    94.48521979999998,
                    50.18629509999999
                 ],
                 [
                    94.48509140000002,
                    50.186384199999985
                 ],
                 [
                    94.48503920000005,
                    50.1864305
                 ],
                 [
                    94.485019,
                    50.1864724
                 ],
                 [
                    94.48501970000004,
                    50.186507499999976
                 ],
                 [
                    94.48503790000002,
                    50.18653730000004
                 ],
                 [
                    94.4850901,
                    50.18656830000003
                 ],
                 [
                    94.48515810000002,
                    50.18658
                 ],
                 [
                    94.48521849999996,
                    50.186576800000026
                 ],
                 [
                    94.48530469999999,
                    50.1865562
                 ],
                 [
                    94.4853443,
                    50.1865506
                 ],
                 [
                    94.4853928,
                    50.18655980000004
                 ],
                 [
                    94.48542430000002,
                    50.18658880000004
                 ],
                 [
                    94.48542430000002,
                    50.186624299999956
                 ],
                 [
                    94.48538270000005,
                    50.18667470000004
                 ],
                 [
                    94.48527889999998,
                    50.18680679999996
                 ],
                 [
                    94.48517760000004,
                    50.186884199999966
                 ],
                 [
                    94.48508569999998,
                    50.1869168
                 ],
                 [
                    94.4849958,
                    50.18691879999999
                 ],
                 [
                    94.48489320000004,
                    50.1869027
                 ],
                 [
                    94.48482459999997,
                    50.1868664
                 ],
                 [
                    94.48471829999997,
                    50.186860799999984
                 ],
                 [
                    94.48463579999998,
                    50.18689179999998
                 ],
                 [
                    94.4845654,
                    50.18694019999998
                 ],
                 [
                    94.48447039999996,
                    50.18703320000004
                 ],
                 [
                    94.4844213,
                    50.187052600000044
                 ],
                 [
                    94.484359,
                    50.187053799999966
                 ],
                 [
                    94.48431679999996,
                    50.18704050000002
                 ],
                 [
                    94.48421870000001,
                    50.186963100000014
                 ],
                 [
                    94.48416830000002,
                    50.1869414
                 ],
                 [
                    94.48408839999998,
                    50.186938899999966
                 ],
                 [
                    94.48401859999998,
                    50.1869522
                 ],
                 [
                    94.4839318,
                    50.1870038
                 ],
                 [
                    94.48384740000003,
                    50.18702479999997
                 ],
                 [
                    94.48368129999996,
                    50.187036
                 ],
                 [
                    94.48362090000002,
                    50.187043299999985
                 ],
                 [
                    94.48359009999996,
                    50.187068300000014
                 ],
                 [
                    94.483558,
                    50.187180699999985
                 ],
                 [
                    94.48348560000002,
                    50.18724230000002
                 ],
                 [
                    94.48341079999996,
                    50.187285400000036
                 ],
                 [
                    94.48339379999997,
                    50.1873322
                 ],
                 [
                    94.48340070000002,
                    50.18743890000003
                 ],
                 [
                    94.4834435,
                    50.18751669999999
                 ],
                 [
                    94.48346110000004,
                    50.187599700000014
                 ],
                 [
                    94.48345350000002,
                    50.187631900000014
                 ],
                 [
                    94.48341519999997,
                    50.187643199999975
                 ],
                 [
                    94.48335480000003,
                    50.1876424
                 ],
                 [
                    94.48326040000003,
                    50.1876134
                 ],
                 [
                    94.4830924,
                    50.18748690000001
                 ],
                 [
                    94.4830162,
                    50.18734750000002
                 ],
                 [
                    94.48297279999996,
                    50.187300300000025
                 ],
                 [
                    94.48289540000003,
                    50.1872846
                 ],
                 [
                    94.4828338,
                    50.1872947
                 ],
                 [
                    94.48276020000004,
                    50.18734139999996
                 ],
                 [
                    94.4826255,
                    50.1873902
                 ],
                 [
                    94.48248769999996,
                    50.187388200000015
                 ],
                 [
                    94.4824399,
                    50.18741229999996
                 ],
                 [
                    94.4824216,
                    50.18745060000003
                 ],
                 [
                    94.482443,
                    50.187484800000014
                 ],
                 [
                    94.48252290000003,
                    50.18753679999996
                 ],
                 [
                    94.48257259999997,
                    50.187575499999966
                 ],
                 [
                    94.482572,
                    50.18764080000004
                 ],
                 [
                    94.4825129,
                    50.187709200000015
                 ],
                 [
                    94.482365,
                    50.18782410000002
                 ],
                 [
                    94.48221150000003,
                    50.18792520000004
                 ],
                 [
                    94.48217119999998,
                    50.18799610000004
                 ],
                 [
                    94.48208940000005,
                    50.18803919999996
                 ],
                 [
                    94.482029,
                    50.188052099999965
                 ],
                 [
                    94.48196990000002,
                    50.18804359999997
                 ],
                 [
                    94.48185969999996,
                    50.1879892
                 ],
                 [
                    94.48177790000004,
                    50.1879667
                 ],
                 [
                    94.48170619999996,
                    50.18797389999998
                 ],
                 [
                    94.48166720000002,
                    50.188003700000046
                 ],
                 [
                    94.48162689999998,
                    50.18810279999999
                 ],
                 [
                    94.4816389,
                    50.188156
                 ],
                 [
                    94.48168039999996,
                    50.188181
                 ],
                 [
                    94.48179869999998,
                    50.18819470000004
                 ],
                 [
                    94.48185720000002,
                    50.188253899999964
                 ],
                 [
                    94.481899,
                    50.18832789999996
                 ],
                 [
                    94.481891,
                    50.18838609999998
                 ],
                 [
                    94.48184560000004,
                    50.1884561
                 ],
                 [
                    94.48179529999996,
                    50.18852420000004
                 ],
                 [
                    94.48174809999996,
                    50.1885548
                 ],
                 [
                    94.481699,
                    50.188563299999984
                 ],
                 [
                    94.48164680000002,
                    50.188552
                 ],
                 [
                    94.48160969999998,
                    50.188523
                 ],
                 [
                    94.48157510000004,
                    50.1884271
                 ],
                 [
                    94.48154050000002,
                    50.18839530000003
                 ],
                 [
                    94.481487,
                    50.188375100000044
                 ],
                 [
                    94.48142470000003,
                    50.18837839999996
                 ],
                 [
                    94.48135480000002,
                    50.18840529999997
                 ],
                 [
                    94.48124220000003,
                    50.18848909999998
                 ],
                 [
                    94.48105909999998,
                    50.18857049999996
                 ],
                 [
                    94.4809811,
                    50.18861039999997
                 ],
                 [
                    94.48090119999998,
                    50.18870670000004
                 ],
                 [
                    94.48086589999998,
                    50.18879890000003
                 ],
                 [
                    94.48080679999998,
                    50.18892259999996
                 ],
                 [
                    94.48074449999997,
                    50.18900639999997
                 ],
                 [
                    94.48064760000004,
                    50.18913170000003
                 ],
                 [
                    94.48058909999999,
                    50.18923690000003
                 ],
                 [
                    94.48057650000001,
                    50.1892924
                 ],
                 [
                    94.48058969999995,
                    50.18932150000001
                 ],
                 [
                    94.4806256,
                    50.189344400000046
                 ],
                 [
                    94.4807181,
                    50.18936780000003
                 ],
                 [
                    94.48085589999995,
                    50.18937909999998
                 ],
                 [
                    94.480954,
                    50.18940770000004
                 ],
                 [
                    94.481005,
                    50.18943589999998
                 ],
                 [
                    94.48101250000003,
                    50.18946770000003
                 ],
                 [
                    94.48097480000003,
                    50.1895112
                 ],
                 [
                    94.48090430000005,
                    50.18962880000004
                 ],
                 [
                    94.48077339999996,
                    50.18972510000001
                 ],
                 [
                    94.48070550000003,
                    50.189792
                 ],
                 [
                    94.480686,
                    50.18982459999999
                 ],
                 [
                    94.4807168,
                    50.189868499999974
                 ],
                 [
                    94.4808263,
                    50.1899213
                 ],
                 [
                    94.480944,
                    50.19000310000001
                 ],
                 [
                    94.481112,
                    50.1901115
                 ],
                 [
                    94.4812063,
                    50.19014410000004
                 ],
                 [
                    94.48133660000002,
                    50.1901683
                 ],
                 [
                    94.48140770000005,
                    50.19021460000001
                 ],
                 [
                    94.4814297,
                    50.19026449999997
                 ],
                 [
                    94.4814243,
                    50.190335900000015
                 ],
                 [
                    94.48136550000002,
                    50.19041789999996
                 ],
                 [
                    94.48130179999998,
                    50.1905489
                 ],
                 [
                    94.481232,
                    50.19064
                 ],
                 [
                    94.481098,
                    50.19074109999997
                 ],
                 [
                    94.48097150000001,
                    50.19080029999998
                 ],
                 [
                    94.48077710000001,
                    50.190868
                 ],
                 [
                    94.480672,
                    50.19088450000002
                 ],
                 [
                    94.48059959999998,
                    50.19088089999999
                 ],
                 [
                    94.48049329999998,
                    50.190845
                 ],
                 [
                    94.48040209999998,
                    50.190802299999966
                 ],
                 [
                    94.48036239999998,
                    50.1907479
                 ],
                 [
                    94.48034790000003,
                    50.19066660000002
                 ],
                 [
                    94.4801919,
                    50.190586
                 ],
                 [
                    94.48006860000001,
                    50.19049209999997
                 ],
                 [
                    94.479978,
                    50.19039219999996
                 ],
                 [
                    94.47991,
                    50.19032779999996
                 ],
                 [
                    94.479854,
                    50.190308
                 ],
                 [
                    94.47972750000002,
                    50.190275
                 ],
                 [
                    94.4796036,
                    50.1902669
                 ],
                 [
                    94.47941730000002,
                    50.19029110000004
                 ],
                 [
                    94.47929590000003,
                    50.1902939
                 ],
                 [
                    94.47919960000004,
                    50.190271
                 ],
                 [
                    94.47905049999997,
                    50.190203299999965
                 ],
                 [
                    94.47899760000003,
                    50.1901823
                 ],
                 [
                    94.47893789999998,
                    50.1901823
                 ],
                 [
                    94.4789039,
                    50.190196
                 ],
                 [
                    94.47887110000005,
                    50.190241
                 ],
                 [
                    94.47881549999997,
                    50.1902918
                 ],
                 [
                    94.47866829999997,
                    50.190352599999976
                 ],
                 [
                    94.47856229999998,
                    50.19046930000004
                 ],
                 [
                    94.4785013,
                    50.190512
                 ],
                 [
                    94.47846349999998,
                    50.190526200000036
                 ],
                 [
                    94.47843119999997,
                    50.19052549999998
                 ],
                 [
                    94.47840349999998,
                    50.19051860000003
                 ],
                 [
                    94.47834620000005,
                    50.190475
                 ],
                 [
                    94.478311,
                    50.1904634
                 ],
                 [
                    94.47826760000002,
                    50.190469399999955
                 ],
                 [
                    94.47819650000001,
                    50.190498400000045
                 ],
                 [
                    94.47808830000002,
                    50.19055119999997
                 ],
                 [
                    94.4779763,
                    50.190596299999974
                 ],
                 [
                    94.4778057,
                    50.190622900000044
                 ],
                 [
                    94.47773459999998,
                    50.19064869999996
                 ],
                 [
                    94.47763269999999,
                    50.190664
                 ],
                 [
                    94.47757359999999,
                    50.1906555
                 ],
                 [
                    94.477437,
                    50.19061730000003
                 ],
                 [
                    94.47733509999996,
                    50.190606400000014
                 ],
                 [
                    94.47725330000003,
                    50.1906289
                 ],
                 [
                    94.47720730000005,
                    50.190666
                 ],
                 [
                    94.47714829999997,
                    50.19076059999998
                 ],
                 [
                    94.4770168,
                    50.19082709999998
                 ],
                 [
                    94.47691419999995,
                    50.19091729999998
                 ],
                 [
                    94.47678390000002,
                    50.19097570000002
                 ],
                 [
                    94.47666309999997,
                    50.191018
                 ],
                 [
                    94.476565,
                    50.1910281
                 ],
                 [
                    94.47647439999999,
                    50.191017600000016
                 ],
                 [
                    94.476358,
                    50.1909552
                 ],
                 [
                    94.4762768,
                    50.19091929999997
                 ],
                 [
                    94.476195,
                    50.19091450000001
                 ],
                 [
                    94.47616040000003,
                    50.190922499999964
                 ],
                 [
                    94.47614840000001,
                    50.19094069999996
                 ],
                 [
                    94.4761579,
                    50.190962
                 ],
                 [
                    94.4762019,
                    50.19100829999996
                 ],
                 [
                    94.476234,
                    50.191061900000044
                 ],
                 [
                    94.47621830000001,
                    50.19109979999998
                 ],
                 [
                    94.47616419999999,
                    50.19112840000004
                 ],
                 [
                    94.47606339999999,
                    50.191163800000034
                 ],
                 [
                    94.47603940000005,
                    50.19123159999996
                 ],
                 [
                    94.47600959999998,
                    50.191337
                 ],
                 [
                    94.47596820000003,
                    50.191424500000025
                 ],
                 [
                    94.4759455,
                    50.1914467
                 ],
                 [
                    94.47587429999997,
                    50.191477699999986
                 ],
                 [
                    94.47580569999998,
                    50.1915635
                 ],
                 [
                    94.47576669999995,
                    50.191662199999975
                 ],
                 [
                    94.47568930000004,
                    50.1917226
                 ],
                 [
                    94.47566609999998,
                    50.1917516
                 ],
                 [
                    94.4756717,
                    50.19178989999997
                 ],
                 [
                    94.4757082,
                    50.19185549999998
                 ],
                 [
                    94.47569690000005,
                    50.19188449999998
                 ],
                 [
                    94.47558679999999,
                    50.1919256
                 ],
                 [
                    94.47550619999998,
                    50.191978799999966
                 ],
                 [
                    94.47544139999995,
                    50.1919965
                 ],
                 [
                    94.47537409999998,
                    50.191993699999955
                 ],
                 [
                    94.47530869999999,
                    50.19197520000002
                 ],
                 [
                    94.47524260000003,
                    50.1919123
                 ],
                 [
                    94.4751929,
                    50.1919026
                 ],
                 [
                    94.47513,
                    50.19190430000002
                 ],
                 [
                    94.475067,
                    50.1919244
                 ],
                 [
                    94.475011,
                    50.191961499999955
                 ],
                 [
                    94.47496379999995,
                    50.192003
                 ],
                 [
                    94.47493680000004,
                    50.1920167
                 ],
                 [
                    94.47490279999998,
                    50.192020300000024
                 ],
                 [
                    94.47484929999999,
                    50.192013
                 ],
                 [
                    94.47481159999998,
                    50.191991300000026
                 ],
                 [
                    94.474787,
                    50.1919449
                 ],
                 [
                    94.47475430000003,
                    50.191862
                 ],
                 [
                    94.4747153,
                    50.19180189999999
                 ],
                 [
                    94.47460830000003,
                    50.19172859999996
                 ],
                 [
                    94.47453969999995,
                    50.191663
                 ],
                 [
                    94.47449440000003,
                    50.19163720000004
                 ],
                 [
                    94.4744007,
                    50.19158440000002
                 ],
                 [
                    94.47427359999996,
                    50.19154250000002
                 ],
                 [
                    94.47418989999997,
                    50.1915671
                 ],
                 [
                    94.47410490000004,
                    50.19163480000002
                 ],
                 [
                    94.47406209999995,
                    50.19167629999998
                 ],
                 [
                    94.4739885,
                    50.19171010000003
                 ],
                 [
                    94.473869,
                    50.19172740000004
                 ],
                 [
                    94.47379790000004,
                    50.191764099999965
                 ],
                 [
                    94.47374180000001,
                    50.19177919999997
                 ],
                 [
                    94.4736826,
                    50.19178
                 ],
                 [
                    94.47364349999997,
                    50.19177010000002
                 ],
                 [
                    94.47362920000002,
                    50.19174560000003
                 ],
                 [
                    94.4736461,
                    50.1917097
                 ],
                 [
                    94.47362749999998,
                    50.19165090000001
                 ],
                 [
                    94.47359539999998,
                    50.19158
                 ],
                 [
                    94.47352679999999,
                    50.191529199999984
                 ],
                 [
                    94.47342669999996,
                    50.1914769
                 ],
                 [
                    94.4733424,
                    50.1914329
                 ],
                 [
                    94.47329210000002,
                    50.19137779999998
                 ],
                 [
                    94.47324930000003,
                    50.191281900000035
                 ],
                 [
                    94.47324739999996,
                    50.19120010000002
                 ],
                 [
                    94.4732361,
                    50.19112959999996
                 ],
                 [
                    94.47321849999997,
                    50.191041
                 ],
                 [
                    94.47321470000001,
                    50.1909914
                 ],
                 [
                    94.4732216,
                    50.190957600000026
                 ],
                 [
                    94.47324109999998,
                    50.19092860000003
                 ],
                 [
                    94.47328890000003,
                    50.1909024
                 ],
                 [
                    94.4733487,
                    50.19089310000002
                 ],
                 [
                    94.47343050000002,
                    50.19089679999997
                 ],
                 [
                    94.47353939999996,
                    50.190910899999984
                 ],
                 [
                    94.47364950000002,
                    50.190905200000046
                 ],
                 [
                    94.4737426,
                    50.190913799999954
                 ],
                 [
                    94.47384439999998,
                    50.1909376
                 ],
                 [
                    94.4738972,
                    50.190947299999976
                 ],
                 [
                    94.47394820000004,
                    50.190946
                 ],
                 [
                    94.47399039999998,
                    50.190928700000036
                 ],
                 [
                    94.47399540000004,
                    50.19090859999997
                 ],
                 [
                    94.47397969999999,
                    50.19088079999998
                 ],
                 [
                    94.47390420000004,
                    50.19083610000001
                 ],
                 [
                    94.47386330000003,
                    50.190791799999985
                 ],
                 [
                    94.47386249999997,
                    50.190753
                 ],
                 [
                    94.4739123,
                    50.19070520000002
                 ],
                 [
                    94.47399769999996,
                    50.1906488
                 ],
                 [
                    94.4740347,
                    50.1905815
                 ],
                 [
                    94.47402530000002,
                    50.190528
                 ],
                 [
                    94.47398189999998,
                    50.19046429999998
                 ],
                 [
                    94.4739209,
                    50.19041279999998
                 ],
                 [
                    94.473868,
                    50.190382100000015
                 ],
                 [
                    94.47381580000001,
                    50.19037089999998
                 ],
                 [
                    94.47376860000001,
                    50.1903733
                 ],
                 [
                    94.47374220000005,
                    50.19038740000001
                 ],
                 [
                    94.47369370000003,
                    50.19046549999999
                 ],
                 [
                    94.4736597,
                    50.190503
                 ],
                 [
                    94.47362449999999,
                    50.19051909999998
                 ],
                 [
                    94.4735861,
                    50.19052070000003
                 ],
                 [
                    94.473544,
                    50.19050819999997
                 ],
                 [
                    94.47351819999999,
                    50.1904732
                 ],
                 [
                    94.4735012,
                    50.190402700000035
                 ],
                 [
                    94.47343080000005,
                    50.1902981
                 ],
                 [
                    94.47341,
                    50.19025220000002
                 ],
                 [
                    94.4733829,
                    50.19023
                 ],
                 [
                    94.47335389999998,
                    50.1902253
                 ],
                 [
                    94.47332630000001,
                    50.1902284
                 ],
                 [
                    94.47324510000004,
                    50.190270700000035
                 ],
                 [
                    94.473142,
                    50.19033429999997
                 ],
                 [
                    94.47304569999997,
                    50.19041130000004
                 ],
                 [
                    94.4729482,
                    50.19044069999998
                 ],
                 [
                    94.47287890000003,
                    50.1904367
                 ],
                 [
                    94.47283360000002,
                    50.190418099999974
                 ],
                 [
                    94.47281479999997,
                    50.19037419999999
                 ],
                 [
                    94.4727927,
                    50.1903529
                 ],
                 [
                    94.4727411,
                    50.1903472
                 ],
                 [
                    94.47268949999999,
                    50.19035169999999
                 ],
                 [
                    94.47265049999996,
                    50.190368599999964
                 ],
                 [
                    94.4726178,
                    50.19044069999998
                 ],
                 [
                    94.4725876,
                    50.1904818
                 ],
                 [
                    94.47253750000003,
                    50.190521
                 ],
                 [
                    94.47234750000003,
                    50.19057010000003
                 ],
                 [
                    94.47215940000001,
                    50.190618500000035
                 ],
                 [
                    94.47209770000005,
                    50.19062490000003
                 ],
                 [
                    94.47201719999997,
                    50.1906116
                 ],
                 [
                    94.47197749999997,
                    50.19058869999997
                 ],
                 [
                    94.47193850000002,
                    50.1905085
                 ],
                 [
                    94.47190449999997,
                    50.1904602
                 ],
                 [
                    94.47185419999998,
                    50.19042950000003
                 ],
                 [
                    94.4717869,
                    50.1904162
                 ],
                 [
                    94.47164279999998,
                    50.1904291
                 ],
                 [
                    94.471371,
                    50.1904215
                 ],
                 [
                    94.47128480000002,
                    50.19043519999997
                 ],
                 [
                    94.47123630000002,
                    50.19046580000001
                 ],
                 [
                    94.47121549999997,
                    50.19055520000004
                 ],
                 [
                    94.47116770000002,
                    50.1905987
                 ],
                 [
                    94.4710538,
                    50.190613200000044
                 ],
                 [
                    94.4709148,
                    50.19062570000001
                 ],
                 [
                    94.4707927,
                    50.19061559999997
                 ],
                 [
                    94.47067879999997,
                    50.19059829999996
                 ],
                 [
                    94.47060330000004,
                    50.190608
                 ],
                 [
                    94.47052969999999,
                    50.19065790000001
                 ],
                 [
                    94.47043660000001,
                    50.190706699999964
                 ],
                 [
                    94.47039380000002,
                    50.1907148
                 ],
                 [
                    94.47033839999997,
                    50.190701100000034
                 ],
                 [
                    94.47029190000003,
                    50.19066479999996
                 ],
                 [
                    94.47028049999997,
                    50.19060069999998
                 ],
                 [
                    94.47031450000003,
                    50.19051860000003
                 ],
                 [
                    94.47031330000003,
                    50.19045610000001
                 ],
                 [
                    94.47029190000003,
                    50.190407
                 ],
                 [
                    94.47024340000002,
                    50.1903647
                 ],
                 [
                    94.470178,
                    50.19034289999997
                 ],
                 [
                    94.47005590000005,
                    50.19031880000002
                 ],
                 [
                    94.469976,
                    50.190281300000024
                 ],
                 [
                    94.46988290000003,
                    50.1902027
                 ],
                 [
                    94.46984129999996,
                    50.19014309999996
                 ],
                 [
                    94.46981989999996,
                    50.19007620000002
                 ],
                 [
                    94.46980040000004,
                    50.19003640000001
                 ],
                 [
                    94.46976959999998,
                    50.190002100000015
                 ],
                 [
                    94.46965630000003,
                    50.189926
                 ],
                 [
                    94.4695852,
                    50.1898655
                 ],
                 [
                    94.469528,
                    50.18983939999997
                 ],
                 [
                    94.46948389999997,
                    50.18983209999998
                 ],
                 [
                    94.4694456,
                    50.189839
                 ],
                 [
                    94.46941849999997,
                    50.18986920000003
                 ],
                 [
                    94.4694059,
                    50.189955399999974
                 ],
                 [
                    94.46938890000001,
                    50.1899783
                 ],
                 [
                    94.4693587,
                    50.189985599999986
                 ],
                 [
                    94.4693115,
                    50.1899848
                 ],
                 [
                    94.4692524,
                    50.1899707
                 ],
                 [
                    94.46918,
                    50.189986
                 ],
                 [
                    94.46913719999996,
                    50.190017
                 ],
                 [
                    94.46904030000003,
                    50.19009110000001
                 ],
                 [
                    94.468963,
                    50.19014309999996
                 ],
                 [
                    94.46894660000001,
                    50.1901705
                 ],
                 [
                    94.46894780000002,
                    50.190194699999964
                 ],
                 [
                    94.4689922,
                    50.19025069999998
                 ],
                 [
                    94.4690228,
                    50.19029159999999
                 ],
                 [
                    94.46900710000004,
                    50.1903226
                 ],
                 [
                    94.468897,
                    50.19036769999998
                 ],
                 [
                    94.46886610000001,
                    50.1904044
                 ],
                 [
                    94.4688649,
                    50.19044069999998
                 ],
                 [
                    94.46891160000004,
                    50.19049380000002
                 ],
                 [
                    94.4690095,
                    50.1905525
                 ],
                 [
                    94.46914120000004,
                    50.19061969999996
                 ],
                 [
                    94.46916570000002,
                    50.190661200000015
                 ],
                 [
                    94.46917389999999,
                    50.19071319999997
                 ],
                 [
                    94.46913069999997,
                    50.1907747
                 ],
                 [
                    94.4689633,
                    50.19092779999996
                 ],
                 [
                    94.46881749999999,
                    50.191031600000024
                 ],
                 [
                    94.46868759999998,
                    50.19106580000001
                 ],
                 [
                    94.4686191,
                    50.191068100000024
                 ],
                 [
                    94.46856829999997,
                    50.1910601
                 ],
                 [
                    94.46851669999997,
                    50.191040100000016
                 ],
                 [
                    94.46850250000003,
                    50.191007100000036
                 ],
                 [
                    94.468523,
                    50.19094560000001
                 ],
                 [
                    94.4685826,
                    50.19082189999999
                 ],
                 [
                    94.46860039999996,
                    50.190682900000034
                 ],
                 [
                    94.46859060000004,
                    50.1906009
                 ],
                 [
                    94.46856120000001,
                    50.1905559
                 ],
                 [
                    94.46844909999997,
                    50.1904989
                 ],
                 [
                    94.46841169999998,
                    50.19045730000002
                 ],
                 [
                    94.46840370000002,
                    50.1903764
                 ],
                 [
                    94.468426,
                    50.190287
                 ],
                 [
                    94.46839659999998,
                    50.19019919999998
                 ],
                 [
                    94.46833070000002,
                    50.1901457
                 ],
                 [
                    94.468159,
                    50.19007730000002
                 ],
                 [
                    94.468054,
                    50.1900471
                 ],
                 [
                    94.4679526,
                    50.1900448
                 ],
                 [
                    94.46787780000004,
                    50.190074499999966
                 ],
                 [
                    94.4676981,
                    50.19020439999997
                 ],
                 [
                    94.46758949999999,
                    50.19029669999997
                 ],
                 [
                    94.4675263,
                    50.19032510000001
                 ],
                 [
                    94.46745069999996,
                    50.1903303
                 ],
                 [
                    94.46739370000004,
                    50.19031890000003
                 ],
                 [
                    94.46733409999999,
                    50.1902636
                 ],
                 [
                    94.467245,
                    50.19014070000003
                 ],
                 [
                    94.4671808,
                    50.19007540000004
                 ],
                 [
                    94.46705670000004,
                    50.19004520000003
                 ],
                 [
                    94.46688949999998,
                    50.19002179999996
                 ],
                 [
                    94.4667533,
                    50.19004119999997
                 ],
                 [
                    94.46664650000004,
                    50.19008910000002
                 ],
                 [
                    94.46658510000002,
                    50.190177400000046
                 ],
                 [
                    94.46654860000001,
                    50.19030209999997
                 ],
                 [
                    94.466481,
                    50.19037910000004
                 ],
                 [
                    94.466376,
                    50.190426300000034
                 ],
                 [
                    94.46627280000003,
                    50.1904451
                 ],
                 [
                    94.46615270000004,
                    50.19046109999998
                 ],
                 [
                    94.466085,
                    50.19051010000004
                 ],
                 [
                    94.466061,
                    50.1906166
                 ],
                 [
                    94.46600230000003,
                    50.190664500000025
                 ],
                 [
                    94.4657389,
                    50.19073970000003
                 ],
                 [
                    94.46559469999995,
                    50.190744199999955
                 ],
                 [
                    94.46545949999997,
                    50.190728900000025
                 ],
                 [
                    94.46535620000003,
                    50.190685
                 ],
                 [
                    94.4652762,
                    50.19061149999999
                 ],
                 [
                    94.4651658,
                    50.190567100000045
                 ],
                 [
                    94.46510070000004,
                    50.190554200000044
                 ],
                 [
                    94.464888,
                    50.190554200000044
                 ],
                 [
                    94.46473069999998,
                    50.19057039999996
                 ],
                 [
                    94.46459979999997,
                    50.19060179999999
                 ],
                 [
                    94.46444,
                    50.19069120000001
                 ],
                 [
                    94.46428270000003,
                    50.1907565
                 ],
                 [
                    94.46415280000002,
                    50.1908305
                 ],
                 [
                    94.46406219999999,
                    50.190851899999984
                 ],
                 [
                    94.46393390000003,
                    50.1908317
                 ],
                 [
                    94.46382889999997,
                    50.19084260000002
                 ],
                 [
                    94.4637639,
                    50.19083520000002
                 ],
                 [
                    94.46371409999996,
                    50.1908078
                 ],
                 [
                    94.46368289999997,
                    50.1907339
                 ],
                 [
                    94.46363259999997,
                    50.1906879
                 ],
                 [
                    94.46348410000003,
                    50.19064119999996
                 ],
                 [
                    94.46340230000001,
                    50.19057110000002
                 ],
                 [
                    94.46334819999998,
                    50.19045110000004
                 ],
                 [
                    94.46331670000004,
                    50.19038740000001
                 ],
                 [
                    94.463201,
                    50.190338300000036
                 ],
                 [
                    94.46303550000005,
                    50.19030759999998
                 ],
                 [
                    94.46298580000001,
                    50.19027180000004
                 ],
                 [
                    94.46291339999998,
                    50.1901318
                 ],
                 [
                    94.46285280000002,
                    50.190028
                 ],
                 [
                    94.46279060000003,
                    50.1899642
                 ],
                 [
                    94.46274159999997,
                    50.189932799999966
                 ],
                 [
                    94.46266420000005,
                    50.189936800000034
                 ],
                 [
                    94.4626206,
                    50.18996480000004
                 ],
                 [
                    94.4625992,
                    50.19005480000003
                 ],
                 [
                    94.462553,
                    50.190095800000044
                 ],
                 [
                    94.46249069999999,
                    50.19012660000001
                 ],
                 [
                    94.46240520000002,
                    50.19013970000003
                 ],
                 [
                    94.4622362,
                    50.190147100000026
                 ],
                 [
                    94.4621579,
                    50.19013620000001
                 ],
                 [
                    94.46212850000005,
                    50.19012140000003
                 ],
                 [
                    94.46208850000002,
                    50.19002739999998
                 ],
                 [
                    94.46199950000003,
                    50.1899864
                 ],
                 [
                    94.46181529999998,
                    50.18996360000003
                 ],
                 [
                    94.4616943,
                    50.1899084
                 ],
                 [
                    94.46158129999998,
                    50.189877
                 ],
                 [
                    94.46141479999997,
                    50.189852499999986
                 ],
                 [
                    94.46128320000001,
                    50.1898463
                 ],
                 [
                    94.46120749999996,
                    50.189855399999956
                 ],
                 [
                    94.46113190000001,
                    50.18992089999996
                 ],
                 [
                    94.4609966,
                    50.189934
                 ],
                 [
                    94.460921,
                    50.1899277
                 ],
                 [
                    94.46087470000005,
                    50.1899374
                 ],
                 [
                    94.4608462,
                    50.18997440000003
                 ],
                 [
                    94.46081950000001,
                    50.19000809999998
                 ],
                 [
                    94.46077590000004,
                    50.19001659999997
                 ],
                 [
                    94.46063090000004,
                    50.190025700000014
                 ],
                 [
                    94.4604298,
                    50.190111700000024
                 ],
                 [
                    94.46022779999998,
                    50.190202900000024
                 ],
                 [
                    94.46009080000002,
                    50.1902337
                 ],
                 [
                    94.460024,
                    50.190235399999956
                 ],
                 [
                    94.45988079999997,
                    50.19019320000002
                 ],
                 [
                    94.4597224,
                    50.19018350000002
                 ],
                 [
                    94.45941269999996,
                    50.1901972
                 ],
                 [
                    94.45930950000003,
                    50.190205699999986
                 ],
                 [
                    94.459273,
                    50.190228
                 ],
                 [
                    94.4592801,
                    50.19025990000003
                 ],
                 [
                    94.45933710000001,
                    50.19029520000002
                 ],
                 [
                    94.4594251,
                    50.19035440000003
                 ],
                 [
                    94.45947939999998,
                    50.1904473
                 ],
                 [
                    94.45952659999998,
                    50.19052019999999
                 ],
                 [
                    94.45963959999999,
                    50.190608
                 ],
                 [
                    94.4597402,
                    50.19069460000003
                 ],
                 [
                    94.45980689999998,
                    50.190793699999965
                 ],
                 [
                    94.45985670000002,
                    50.1908478
                 ],
                 [
                    94.459887,
                    50.1908928
                 ],
                 [
                    94.4598888,
                    50.19094240000001
                 ],
                 [
                    94.4598674,
                    50.190964
                 ],
                 [
                    94.459814,
                    50.190968
                 ],
                 [
                    94.459758,
                    50.19096060000001
                 ],
                 [
                    94.45964579999998,
                    50.19092130000004
                 ],
                 [
                    94.45958620000002,
                    50.19092469999997
                 ],
                 [
                    94.459491,
                    50.190972
                 ],
                 [
                    94.459459,
                    50.19103180000004
                 ],
                 [
                    94.459475,
                    50.1911247
                 ],
                 [
                    94.4594518,
                    50.191185599999976
                 ],
                 [
                    94.45940380000002,
                    50.19122439999999
                 ],
                 [
                    94.4593326,
                    50.19124320000003
                 ],
                 [
                    94.45921070000003,
                    50.19124090000002
                 ],
                 [
                    94.4590612,
                    50.1911913
                 ],
                 [
                    94.45899710000003,
                    50.191137800000014
                 ],
                 [
                    94.45899890000001,
                    50.19108420000002
                 ],
                 [
                    94.4590718,
                    50.1910432
                 ],
                 [
                    94.45907450000004,
                    50.19098850000001
                 ],
                 [
                    94.45904779999996,
                    50.190944600000016
                 ],
                 [
                    94.45893480000004,
                    50.19087
                 ],
                 [
                    94.4588067,
                    50.190813
                 ],
                 [
                    94.45864649999999,
                    50.19078739999998
                 ],
                 [
                    94.4584472,
                    50.19073159999998
                 ],
                 [
                    94.45832529999996,
                    50.19071219999997
                 ],
                 [
                    94.458238,
                    50.190714499999984
                 ],
                 [
                    94.45816510000003,
                    50.19074349999999
                 ],
                 [
                    94.4581099,
                    50.19078629999998
                 ],
                 [
                    94.45809569999997,
                    50.19090930000003
                 ],
                 [
                    94.45809029999997,
                    50.1910324
                 ],
                 [
                    94.45810810000002,
                    50.1910882
                 ],
                 [
                    94.45813930000003,
                    50.191099599999966
                 ],
                 [
                    94.45819180000002,
                    50.191099599999966
                 ],
                 [
                    94.4583092,
                    50.1910819
                 ],
                 [
                    94.45837779999995,
                    50.1910819
                 ],
                 [
                    94.45841420000004,
                    50.191099599999966
                 ],
                 [
                    94.45841330000005,
                    50.19115029999998
                 ],
                 [
                    94.45837779999995,
                    50.191209
                 ],
                 [
                    94.458392,
                    50.19128529999996
                 ],
                 [
                    94.45843919999997,
                    50.19135710000004
                 ],
                 [
                    94.45854769999998,
                    50.19145570000003
                 ],
                 [
                    94.4585726,
                    50.19150070000002
                 ],
                 [
                    94.45855389999996,
                    50.19152689999997
                 ],
                 [
                    94.4584997,
                    50.19154109999999
                 ],
                 [
                    94.45831990000005,
                    50.191565599999976
                 ],
                 [
                    94.45819620000003,
                    50.19165909999998
                 ],
                 [
                    94.45809479999998,
                    50.191726300000035
                 ],
                 [
                    94.45800129999998,
                    50.191732500000015
                 ],
                 [
                    94.4579168,
                    50.191724
                 ],
                 [
                    94.45779490000004,
                    50.191694900000016
                 ],
                 [
                    94.45766050000002,
                    50.19161460000003
                 ],
                 [
                    94.4575867,
                    50.191566200000025
                 ],
                 [
                    94.457495,
                    50.19154109999999
                 ],
                 [
                    94.45742740000001,
                    50.19154570000001
                 ],
                 [
                    94.45737490000002,
                    50.1915594
                 ],
                 [
                    94.45735440000001,
                    50.1915873
                 ],
                 [
                    94.4573598,
                    50.191712
                 ],
                 [
                    94.45734639999996,
                    50.19174279999998
                 ],
                 [
                    94.45730459999997,
                    50.19175820000001
                 ],
                 [
                    94.45725739999997,
                    50.19175820000001
                 ],
                 [
                    94.457148,
                    50.19174279999998
                 ],
                 [
                    94.4570323,
                    50.1917354
                 ],
                 [
                    94.45689620000002,
                    50.19175649999996
                 ],
                 [
                    94.45676450000005,
                    50.19177809999997
                 ],
                 [
                    94.45673420000003,
                    50.19180660000002
                 ],
                 [
                    94.4567477,
                    50.191868900000024
                 ],
                 [
                    94.4567993,
                    50.191946200000025
                 ],
                 [
                    94.4567779,
                    50.19205169999996
                 ],
                 [
                    94.45670620000003,
                    50.192178200000036
                 ],
                 [
                    94.45656150000003,
                    50.192255600000045
                 ],
                 [
                    94.456457,
                    50.19245139999999
                 ],
                 [
                    94.456374,
                    50.192565
                 ],
                 [
                    94.45608480000003,
                    50.19276210000003
                 ],
                 [
                    94.4560145,
                    50.19278939999998
                 ],
                 [
                    94.45592820000005,
                    50.192794499999955
                 ],
                 [
                    94.45585710000002,
                    50.19278140000003
                 ],
                 [
                    94.4557351,
                    50.19273190000002
                 ],
                 [
                    94.45560959999997,
                    50.19268170000004
                 ],
                 [
                    94.4554949,
                    50.19269819999998
                 ],
                 [
                    94.455367,
                    50.19276510000001
                 ],
                 [
                    94.45508760000004,
                    50.19281719999997
                 ],
                 [
                    94.45475160000004,
                    50.1928454
                 ],
                 [
                    94.45439669999998,
                    50.19289210000004
                 ],
                 [
                    94.4541865,
                    50.19293319999998
                 ],
                 [
                    94.45412939999999,
                    50.1928949
                 ],
                 [
                    94.4540535,
                    50.1928744
                 ],
                 [
                    94.45397410000001,
                    50.1928754
                 ],
                 [
                    94.4537367,
                    50.192907900000016
                 ],
                 [
                    94.4535017,
                    50.192878599999986
                 ],
                 [
                    94.45325989999998,
                    50.19290580000002
                 ],
                 [
                    94.453172,
                    50.19290150000002
                 ],
                 [
                    94.4529478,
                    50.192952599999984
                 ],
                 [
                    94.4528683,
                    50.192953599999974
                 ],
                 [
                    94.45280049999998,
                    50.192922299999964
                 ],
                 [
                    94.4527653,
                    50.192866300000034
                 ],
                 [
                    94.4527867,
                    50.1927453
                 ],
                 [
                    94.45277660000005,
                    50.19268629999998
                 ],
                 [
                    94.4527374,
                    50.19263579999997
                 ],
                 [
                    94.45273149999996,
                    50.19257950000002
                 ],
                 [
                    94.45280990000005,
                    50.19240670000002
                 ],
                 [
                    94.45278319999997,
                    50.192353399999966
                 ],
                 [
                    94.45266370000003,
                    50.1922743
                 ],
                 [
                    94.45269130000001,
                    50.1922176
                 ],
                 [
                    94.4526686,
                    50.19215880000003
                 ],
                 [
                    94.45260880000002,
                    50.1921166
                 ],
                 [
                    94.45246490000001,
                    50.19206210000002
                 ],
                 [
                    94.45230510000003,
                    50.1920318
                 ],
                 [
                    94.4522329,
                    50.1919952
                 ],
                 [
                    94.45217610000002,
                    50.1919127
                 ],
                 [
                    94.45197450000003,
                    50.191883
                 ],
                 [
                    94.45189620000004,
                    50.19178459999998
                 ],
                 [
                    94.4519935,
                    50.19168410000001
                 ],
                 [
                    94.4520169,
                    50.19162740000002
                 ],
                 [
                    94.45196759999999,
                    50.191518
                 ],
                 [
                    94.4519119,
                    50.1914757
                 ],
                 [
                    94.45156809999997,
                    50.1913082
                 ],
                 [
                    94.45143809999996,
                    50.19115679999999
                 ],
                 [
                    94.45146570000003,
                    50.1911001
                 ],
                 [
                    94.45152710000005,
                    50.19105640000003
                 ],
                 [
                    94.45161409999996,
                    50.19103109999998
                 ],
                 [
                    94.45169840000001,
                    50.19105149999998
                 ],
                 [
                    94.4517863,
                    50.1910558
                 ],
                 [
                    94.4518561,
                    50.191012
                 ],
                 [
                    94.45181279999997,
                    50.19096420000004
                 ],
                 [
                    94.451887,
                    50.190931099999965
                 ],
                 [
                    94.45196030000001,
                    50.190865699999975
                 ],
                 [
                    94.45196270000002,
                    50.190806599999966
                 ],
                 [
                    94.45200909999996,
                    50.190679899999964
                 ],
                 [
                    94.45205769999998,
                    50.19062829999996
                 ],
                 [
                    94.45203919999996,
                    50.19057210000002
                 ],
                 [
                    94.45196070000004,
                    50.1904657
                 ],
                 [
                    94.45193279999995,
                    50.190232499999986
                 ],
                 [
                    94.45180510000004,
                    50.190159
                 ],
                 [
                    94.45165690000005,
                    50.19009909999996
                 ],
                 [
                    94.45162609999998,
                    50.1900458
                 ],
                 [
                    94.45150259999997,
                    50.18997210000002
                 ],
                 [
                    94.45143069999997,
                    50.1899462
                 ],
                 [
                    94.45135129999998,
                    50.1899472
                 ],
                 [
                    94.45119730000003,
                    50.18997059999999
                 ],
                 [
                    94.45105240000002,
                    50.190020699999955
                 ],
                 [
                    94.4508933,
                    50.190012
                 ],
                 [
                    94.4508418,
                    50.189967
                 ],
                 [
                    94.45075310000004,
                    50.18980160000004
                 ],
                 [
                    94.45062139999999,
                    50.18973080000004
                 ],
                 [
                    94.45054140000002,
                    50.189715700000036
                 ],
                 [
                    94.450462,
                    50.18971670000003
                 ],
                 [
                    94.45039159999997,
                    50.189739
                 ],
                 [
                    94.45031180000004,
                    50.18972659999996
                 ],
                 [
                    94.44992789999998,
                    50.1896133
                 ],
                 [
                    94.44979579999998,
                    50.1896713
                 ],
                 [
                    94.4496521,
                    50.1896194
                 ],
                 [
                    94.4495728,
                    50.18962310000001
                 ],
                 [
                    94.4494248,
                    50.1895713
                 ],
                 [
                    94.4493453,
                    50.18956689999998
                 ],
                 [
                    94.44910559999998,
                    50.1895242
                 ],
                 [
                    94.449022,
                    50.189522599999954
                 ],
                 [
                    94.44894190000002,
                    50.189502100000034
                 ],
                 [
                    94.44887809999999,
                    50.1894653
                 ],
                 [
                    94.44872740000004,
                    50.189322199999985
                 ],
                 [
                    94.44864780000002,
                    50.189317899999985
                 ],
                 [
                    94.44851140000002,
                    50.1893706
                 ],
                 [
                    94.44827340000005,
                    50.18938160000001
                 ],
                 [
                    94.44796640000004,
                    50.189460599999975
                 ],
                 [
                    94.44780310000003,
                    50.189451899999966
                 ],
                 [
                    94.447647,
                    50.1894082
                 ],
                 [
                    94.44750719999998,
                    50.189345500000044
                 ],
                 [
                    94.4474313,
                    50.189325
                 ],
                 [
                    94.44701219999996,
                    50.18929260000002
                 ],
                 [
                    94.44674870000001,
                    50.18929050000003
                 ],
                 [
                    94.44659069999999,
                    50.1893193
                 ],
                 [
                    94.44653340000005,
                    50.189360300000025
                 ],
                 [
                    94.44649289999998,
                    50.18940379999999
                 ],
                 [
                    94.44640950000002,
                    50.18954979999999
                 ],
                 [
                    94.4462504,
                    50.189543700000016
                 ],
                 [
                    94.44618249999996,
                    50.18950960000004
                 ],
                 [
                    94.44615989999997,
                    50.18945360000002
                 ],
                 [
                    94.44605230000003,
                    50.18935290000004
                 ],
                 [
                    94.44598040000004,
                    50.18932429999998
                 ],
                 [
                    94.44590029999998,
                    50.18930379999997
                 ],
                 [
                    94.44564569999996,
                    50.18931769999997
                 ],
                 [
                    94.44550140000001,
                    50.189247
                 ],
                 [
                    94.44544790000003,
                    50.18913759999999
                 ],
                 [
                    94.44536530000003,
                    50.18903390000002
                 ],
                 [
                    94.44520660000005,
                    50.189041300000014
                 ],
                 [
                    94.4451321,
                    50.189066400000044
                 ],
                 [
                    94.44508319999996,
                    50.189107200000045
                 ],
                 [
                    94.4450172,
                    50.18913759999999
                 ],
                 [
                    94.44461319999998,
                    50.1891882
                 ],
                 [
                    94.44449389999997,
                    50.18925420000004
                 ],
                 [
                    94.44440619999999,
                    50.1893975
                 ],
                 [
                    94.4441636,
                    50.189392500000025
                 ],
                 [
                    94.4440801,
                    50.18939890000002
                 ],
                 [
                    94.4440055,
                    50.189421300000014
                 ],
                 [
                    94.4438863,
                    50.1894899
                 ],
                 [
                    94.44384759999996,
                    50.189592400000045
                 ],
                 [
                    94.44371980000004,
                    50.189653
                 ],
                 [
                    94.44367929999997,
                    50.189696499999954
                 ],
                 [
                    94.44356390000002,
                    50.189612
                 ],
                 [
                    94.4434144,
                    50.18964609999996
                 ],
                 [
                    94.44336540000003,
                    50.189687
                 ],
                 [
                    94.443295,
                    50.18970930000004
                 ],
                 [
                    94.44326880000003,
                    50.189809
                 ],
                 [
                    94.44318969999996,
                    50.18982070000002
                 ],
                 [
                    94.44132609999996,
                    50.189725699999975
                 ],
                 [
                    94.44025670000003,
                    50.18976050000001
                 ],
                 [
                    94.43958990000002,
                    50.1898331
                 ],
                 [
                    94.4382222,
                    50.1899575
                 ],
                 [
                    94.43752380000004,
                    50.190095
                 ],
                 [
                    94.435859,
                    50.1903518
                 ],
                 [
                    94.43329190000001,
                    50.19064119999996
                 ],
                 [
                    94.4316624,
                    50.190961900000026
                 ],
                 [
                    94.4298278,
                    50.191134900000044
                 ],
                 [
                    94.42912620000004,
                    50.191165
                 ],
                 [
                    94.42672240000002,
                    50.1913234
                 ],
                 [
                    94.42478290000003,
                    50.191347200000024
                 ],
                 [
                    94.42244659999999,
                    50.1915261
                 ],
                 [
                    94.42171280000002,
                    50.191599500000024
                 ],
                 [
                    94.4207507,
                    50.191869
                 ],
                 [
                    94.4202568,
                    50.19213280000001
                 ],
                 [
                    94.4195214,
                    50.19328
                 ],
                 [
                    94.419073,
                    50.1939512
                 ],
                 [
                    94.4187165,
                    50.19434210000002
                 ],
                 [
                    94.41773550000002,
                    50.195105699999964
                 ],
                 [
                    94.4151569,
                    50.19614650000001
                 ],
                 [
                    94.41334290000002,
                    50.1970276
                 ],
                 [
                    94.41278,
                    50.19722780000004
                 ],
                 [
                    94.41109469999998,
                    50.19793549999998
                 ],
                 [
                    94.4106732,
                    50.198391599999965
                 ],
                 [
                    94.4102813,
                    50.19871850000001
                 ],
                 [
                    94.40975379999996,
                    50.198982600000015
                 ],
                 [
                    94.4088255,
                    50.199273
                 ],
                 [
                    94.408062,
                    50.19947560000004
                 ],
                 [
                    94.40711290000004,
                    50.20019580000003
                 ],
                 [
                    94.40689079999996,
                    50.2006065
                 ],
                 [
                    94.40657959999997,
                    50.20140489999998
                 ],
                 [
                    94.4062559,
                    50.20177390000004
                 ],
                 [
                    94.40530420000005,
                    50.202408200000036
                 ],
                 [
                    94.40340270000002,
                    50.202603
                 ],
                 [
                    94.40237279999997,
                    50.20285160000004
                 ],
                 [
                    94.40065859999999,
                    50.2037313
                 ],
                 [
                    94.39921579999998,
                    50.20473660000004
                 ],
                 [
                    94.39812070000005,
                    50.20505039999998
                 ],
                 [
                    94.3976271,
                    50.20533560000004
                 ],
                 [
                    94.39638990000002,
                    50.20651009999997
                 ],
                 [
                    94.395715,
                    50.207463100000034
                 ],
                 [
                    94.39553369999996,
                    50.208131099999974
                 ],
                 [
                    94.3955127,
                    50.2085608
                 ],
                 [
                    94.3956147,
                    50.209762200000014
                 ],
                 [
                    94.39513229999997,
                    50.21043379999998
                 ],
                 [
                    94.3944411,
                    50.210828599999964
                 ],
                 [
                    94.39388049999995,
                    50.2111146
                 ],
                 [
                    94.3923535,
                    50.21154090000002
                 ],
                 [
                    94.39176880000002,
                    50.212149199999985
                 ],
                 [
                    94.39066190000001,
                    50.213214800000046
                 ],
                 [
                    94.389747,
                    50.2139774
                 ],
                 [
                    94.389113,
                    50.215119100000045
                 ],
                 [
                    94.38826410000003,
                    50.21979109999999
                 ],
                 [
                    94.38723509999998,
                    50.22566340000003
                 ],
                 [
                    94.38675209999998,
                    50.227923299999965
                 ],
                 [
                    94.38661239999998,
                    50.228651099999965
                 ],
                 [
                    94.38664970000004,
                    50.228948100000025
                 ],
                 [
                    94.3866976,
                    50.2292086
                 ],
                 [
                    94.38664970000004,
                    50.22935920000002
                 ],
                 [
                    94.38657389999999,
                    50.2294716
                 ],
                 [
                    94.38638630000001,
                    50.22963249999999
                 ],
                 [
                    94.38625059999997,
                    50.2297755
                 ],
                 [
                    94.38614280000002,
                    50.2300385
                 ],
                 [
                    94.3859472,
                    50.230906599999976
                 ],
                 [
                    94.38563710000001,
                    50.23221419999999
                 ],
                 [
                    94.38500490000001,
                    50.23473090000003
                 ],
                 [
                    94.38449530000004,
                    50.23678320000002
                 ],
                 [
                    94.3841792,
                    50.2376245
                 ],
                 [
                    94.38388560000004,
                    50.23900010000004
                 ],
                 [
                    94.38376710000001,
                    50.239548899999974
                 ],
                 [
                    94.3837755,
                    50.240231200000025
                 ],
                 [
                    94.38396250000001,
                    50.24229469999997
                 ],
                 [
                    94.38451179999998,
                    50.253238699999976
                 ],
                 [
                    94.38463560000001,
                    50.25416519999999
                 ],
                 [
                    94.384763,
                    50.2569155
                 ],
                 [
                    94.38441010000001,
                    50.26284839999999
                 ],
                 [
                    94.3840546,
                    50.26785870000003
                 ],
                 [
                    94.38393040000003,
                    50.269525600000016
                 ],
                 [
                    94.3838345,
                    50.270121
                 ],
                 [
                    94.383402,
                    50.272846
                 ],
                 [
                    94.3833301,
                    50.2734123
                 ],
                 [
                    94.38316180000002,
                    50.27714380000001
                 ],
                 [
                    94.382595,
                    50.28114849999998
                 ],
                 [
                    94.38207609999998,
                    50.28353589999998
                 ],
                 [
                    94.38138159999998,
                    50.286015
                 ],
                 [
                    94.379526,
                    50.29155630000004
                 ],
                 [
                    94.37723020000004,
                    50.2982144
                 ],
                 [
                    94.37701469999998,
                    50.298895600000044
                 ],
                 [
                    94.37657559999997,
                    50.30036429999998
                 ],
                 [
                    94.37625149999998,
                    50.30440530000002
                 ],
                 [
                    94.3754725,
                    50.307866400000044
                 ],
                 [
                    94.37493060000003,
                    50.31323059999997
                 ],
                 [
                    94.37425320000001,
                    50.31651810000002
                 ],
                 [
                    94.372927,
                    50.32283169999998
                 ],
                 [
                    94.37219259999996,
                    50.325614500000015
                 ],
                 [
                    94.37192109999997,
                    50.328509299999986
                 ],
                 [
                    94.37045220000003,
                    50.3370703
                 ],
                 [
                    94.368402,
                    50.34764080000002
                 ],
                 [
                    94.36313069999999,
                    50.378576
                 ],
                 [
                    94.3571568,
                    50.411725099999956
                 ],
                 [
                    94.35474179999999,
                    50.426205800000034
                 ],
                 [
                    94.353303,
                    50.43519910000003
                 ],
                 [
                    94.352794,
                    50.438785
                 ],
                 [
                    94.35193190000003,
                    50.4440319
                 ],
                 [
                    94.3513413,
                    50.4479214
                 ],
                 [
                    94.35095019999997,
                    50.4506435
                 ],
                 [
                    94.3504341,
                    50.45421530000002
                 ],
                 [
                    94.35006720000001,
                    50.456537
                 ],
                 [
                    94.34995949999998,
                    50.458891
                 ],
                 [
                    94.34986350000004,
                    50.4603788
                 ],
                 [
                    94.3495079,
                    50.462222300000036
                 ],
                 [
                    94.34774670000004,
                    50.46999190000003
                 ],
                 [
                    94.3452108,
                    50.48094169999999
                 ],
                 [
                    94.34509789999998,
                    50.48322249999997
                 ],
                 [
                    94.34436659999997,
                    50.4887974
                 ],
                 [
                    94.3439322,
                    50.4917354
                 ],
                 [
                    94.3435653,
                    50.49323650000004
                 ],
                 [
                    94.341509,
                    50.501224499999985
                 ],
                 [
                    94.34018310000003,
                    50.502561
                 ],
                 [
                    94.30467149999997,
                    50.52591609999999
                 ],
                 [
                    94.29594490000002,
                    50.5531809
                 ],
                 [
                    94.28374840000002,
                    50.57192460000002
                 ],
                 [
                    94.28031169999998,
                    50.57330070000001
                 ],
                 [
                    94.27279079999995,
                    50.5762074
                 ],
                 [
                    94.266008,
                    50.578622
                 ],
                 [
                    94.25403459999997,
                    50.58333770000003
                 ],
                 [
                    94.24040689999995,
                    50.58776
                 ],
                 [
                    94.2193912,
                    50.589650900000045
                 ],
                 [
                    94.18949639999997,
                    50.59143959999999
                 ],
                 [
                    94.1685258,
                    50.5923168
                 ],
                 [
                    94.13749590000003,
                    50.593383399999965
                 ],
                 [
                    94.12121170000003,
                    50.594164
                 ],
                 [
                    94.08908950000001,
                    50.594060400000025
                 ],
                 [
                    94.06395829999997,
                    50.59490220000004
                 ],
                 [
                    94.0310165,
                    50.59673430000002
                 ],
                 [
                    94.0090159,
                    50.6016484
                 ],
                 [
                    93.994415,
                    50.6048321
                 ],
                 [
                    93.96925619999996,
                    50.597245400000034
                 ],
                 [
                    93.94104440000002,
                    50.589895300000016
                 ],
                 [
                    93.85617449999997,
                    50.60105180000003
                 ],
                 [
                    93.8236547,
                    50.5977643
                 ],
                 [
                    93.78781379999997,
                    50.59775720000001
                 ],
                 [
                    93.75039720000002,
                    50.595264199999974
                 ],
                 [
                    93.70341389999999,
                    50.5862228
                 ],
                 [
                    93.6672547,
                    50.5902494
                 ],
                 [
                    93.63635250000003,
                    50.6069389
                 ],
                 [
                    93.60878190000003,
                    50.60569860000001
                 ],
                 [
                    93.58168,
                    50.60504380000001
                 ],
                 [
                    93.58153059999997,
                    50.6050351
                 ],
                 [
                    93.58124839999998,
                    50.60506880000004
                 ],
                 [
                    93.5808816,
                    50.60507859999996
                 ],
                 [
                    93.580337,
                    50.605041099999966
                 ],
                 [
                    93.580067,
                    50.605039800000036
                 ],
                 [
                    93.57989369999997,
                    50.60500840000002
                 ],
                 [
                    93.57952070000002,
                    50.60485459999999
                 ],
                 [
                    93.57925880000002,
                    50.604821
                 ],
                 [
                    93.57917029999999,
                    50.6048322
                 ],
                 [
                    93.57853190000002,
                    50.605052800000045
                 ],
                 [
                    93.578197,
                    50.605261099999964
                 ],
                 [
                    93.57796630000001,
                    50.60536419999997
                 ],
                 [
                    93.577879,
                    50.605469300000046
                 ],
                 [
                    93.57793239999997,
                    50.605694600000035
                 ],
                 [
                    93.5778996,
                    50.60576989999996
                 ],
                 [
                    93.57776140000003,
                    50.60585110000002
                 ],
                 [
                    93.57771989999998,
                    50.60591030000003
                 ],
                 [
                    93.57762710000002,
                    50.6062544
                 ],
                 [
                    93.57764960000002,
                    50.606367
                 ],
                 [
                    93.5777686,
                    50.60643889999998
                 ],
                 [
                    93.5778072,
                    50.60648970000001
                 ],
                 [
                    93.57783880000004,
                    50.6066613
                 ],
                 [
                    93.57778910000002,
                    50.60673670000002
                 ],
                 [
                    93.57764240000004,
                    50.6068126
                 ],
                 [
                    93.577071,
                    50.606998
                 ],
                 [
                    93.57704220000004,
                    50.607054500000025
                 ],
                 [
                    93.5770429,
                    50.60711079999997
                 ],
                 [
                    93.57720149999997,
                    50.607316699999956
                 ],
                 [
                    93.5772576,
                    50.60742649999998
                 ],
                 [
                    93.57716240000003,
                    50.607571900000025
                 ],
                 [
                    93.57711239999998,
                    50.607620500000046
                 ],
                 [
                    93.57683849999997,
                    50.60764329999998
                 ],
                 [
                    93.57648529999996,
                    50.60773359999999
                 ],
                 [
                    93.576381,
                    50.60783080000004
                 ],
                 [
                    93.57632549999997,
                    50.608118200000014
                 ],
                 [
                    93.57625510000001,
                    50.6082286
                 ],
                 [
                    93.57556830000003,
                    50.60863729999998
                 ],
                 [
                    93.57545130000003,
                    50.608729200000035
                 ],
                 [
                    93.57532240000002,
                    50.60888280000004
                 ],
                 [
                    93.5750035,
                    50.6090132
                 ],
                 [
                    93.57440269999995,
                    50.60956110000003
                 ],
                 [
                    93.57417620000004,
                    50.6096669
                 ],
                 [
                    93.57399949999999,
                    50.609708
                 ],
                 [
                    93.57390269999998,
                    50.60972459999997
                 ],
                 [
                    93.57372540000004,
                    50.60971470000004
                 ],
                 [
                    93.57358540000001,
                    50.6096456
                 ],
                 [
                    93.57347390000002,
                    50.6094985
                 ],
                 [
                    93.57339350000002,
                    50.6094748
                 ],
                 [
                    93.57266020000002,
                    50.60953210000003
                 ],
                 [
                    93.57241469999995,
                    50.6094608
                 ],
                 [
                    93.57228150000003,
                    50.60926280000004
                 ],
                 [
                    93.5722178,
                    50.60922019999998
                 ],
                 [
                    93.57204430000004,
                    50.6091808
                 ],
                 [
                    93.57158470000003,
                    50.609193800000014
                 ],
                 [
                    93.57098690000004,
                    50.608944399999984
                 ],
                 [
                    93.57084610000003,
                    50.60880550000004
                 ],
                 [
                    93.57078580000001,
                    50.60870650000002
                 ],
                 [
                    93.5707803,
                    50.60860450000002
                 ],
                 [
                    93.57083049999997,
                    50.608564
                 ],
                 [
                    93.57098609999997,
                    50.608531
                 ],
                 [
                    93.57107370000003,
                    50.60844739999999
                 ],
                 [
                    93.5710604,
                    50.60839650000004
                 ],
                 [
                    93.5708567,
                    50.608290100000026
                 ],
                 [
                    93.570788,
                    50.60819379999997
                 ],
                 [
                    93.57079589999995,
                    50.60814280000001
                 ],
                 [
                    93.570992,
                    50.60797269999997
                 ],
                 [
                    93.57101619999997,
                    50.6078733
                 ],
                 [
                    93.57097339999999,
                    50.60782520000002
                 ],
                 [
                    93.5707279,
                    50.607753899999985
                 ],
                 [
                    93.57067680000004,
                    50.6077112
                 ],
                 [
                    93.5705549,
                    50.6074031
                 ],
                 [
                    93.57047329999999,
                    50.60728010000004
                 ],
                 [
                    93.57048049999997,
                    50.6071753
                 ],
                 [
                    93.57055879999996,
                    50.6070273
                 ],
                 [
                    93.57056119999999,
                    50.60687430000004
                 ],
                 [
                    93.5706022,
                    50.606774799999975
                 ],
                 [
                    93.5706565,
                    50.6067343
                 ],
                 [
                    93.5707729,
                    50.60659410000003
                 ],
                 [
                    93.57099030000005,
                    50.60643740000003
                 ],
                 [
                    93.571066,
                    50.60641820000004
                 ],
                 [
                    93.57123050000003,
                    50.60641739999998
                 ],
                 [
                    93.5716156,
                    50.6065175
                 ],
                 [
                    93.57178020000003,
                    50.60652750000003
                 ],
                 [
                    93.57185589999999,
                    50.60650830000004
                 ],
                 [
                    93.5724056,
                    50.606272100000034
                 ],
                 [
                    93.57258139999998,
                    50.60616120000001
                 ],
                 [
                    93.5726557,
                    50.60602659999997
                 ],
                 [
                    93.57264659999996,
                    50.605975600000015
                 ],
                 [
                    93.57254850000001,
                    50.60588220000002
                 ],
                 [
                    93.5722961,
                    50.60559079999997
                 ],
                 [
                    93.57216069999998,
                    50.605213
                 ],
                 [
                    93.5720492,
                    50.6050606
                 ],
                 [
                    93.57200230000002,
                    50.605015199999954
                 ],
                 [
                    93.571926,
                    50.604986
                 ],
                 [
                    93.5717444,
                    50.604968099999965
                 ],
                 [
                    93.5715877,
                    50.60491520000003
                 ],
                 [
                    93.57140450000004,
                    50.60475779999997
                 ],
                 [
                    93.57127550000003,
                    50.6045571
                 ],
                 [
                    93.571216,
                    50.604517100000045
                 ],
                 [
                    93.57105919999998,
                    50.60445880000002
                 ],
                 [
                    93.57080960000002,
                    50.604398299999986
                 ],
                 [
                    93.5701889,
                    50.60433960000001
                 ],
                 [
                    93.56985550000003,
                    50.60432509999997
                 ],
                 [
                    93.56920719999995,
                    50.60442489999996
                 ],
                 [
                    93.56848790000004,
                    50.6045895
                 ],
                 [
                    93.56812529999996,
                    50.6045993
                 ],
                 [
                    93.5678757,
                    50.60453339999997
                 ],
                 [
                    93.56767180000001,
                    50.60441629999996
                 ],
                 [
                    93.5675611,
                    50.60432829999996
                 ],
                 [
                    93.56741120000004,
                    50.60413309999998
                 ],
                 [
                    93.5671262,
                    50.6039358
                 ],
                 [
                    93.56686820000004,
                    50.60387270000002
                 ],
                 [
                    93.56660620000004,
                    50.60383369999999
                 ],
                 [
                    93.56641649999997,
                    50.603842600000014
                 ],
                 [
                    93.566046,
                    50.60389540000003
                 ],
                 [
                    93.56578829999995,
                    50.603859100000044
                 ],
                 [
                    93.56562229999999,
                    50.60373379999998
                 ],
                 [
                    93.5655066,
                    50.6035867
                 ],
                 [
                    93.56538340000003,
                    50.60350949999999
                 ],
                 [
                    93.56520119999996,
                    50.60343790000001
                 ],
                 [
                    93.5648421,
                    50.60339129999998
                 ],
                 [
                    93.5646896,
                    50.6033357
                 ],
                 [
                    93.564506,
                    50.60314869999998
                 ],
                 [
                    93.56429720000004,
                    50.6029698
                 ],
                 [
                    93.56416559999998,
                    50.602898
                 ],
                 [
                    93.564081,
                    50.602876899999956
                 ],
                 [
                    93.56379829999997,
                    50.60286490000003
                 ],
                 [
                    93.56361660000003,
                    50.60284159999997
                 ],
                 [
                    93.5631611,
                    50.60284110000001
                 ],
                 [
                    93.56289569999998,
                    50.6028666
                 ],
                 [
                    93.56272330000002,
                    50.60291029999997
                 ],
                 [
                    93.5623631,
                    50.60311610000003
                 ],
                 [
                    93.56225449999995,
                    50.60321060000003
                 ],
                 [
                    93.56224680000003,
                    50.603267
                 ],
                 [
                    93.5623281,
                    50.60336859999996
                 ],
                 [
                    93.56240430000001,
                    50.603392399999976
                 ],
                 [
                    93.56256909999996,
                    50.603413
                 ],
                 [
                    93.56263689999997,
                    50.60344489999997
                 ],
                 [
                    93.56267549999997,
                    50.60349840000004
                 ],
                 [
                    93.56266779999996,
                    50.6035548
                 ],
                 [
                    93.56258790000001,
                    50.603582
                 ],
                 [
                    93.56231789999997,
                    50.60356990000001
                 ],
                 [
                    93.562221,
                    50.6035784
                 ],
                 [
                    93.5621411,
                    50.60360570000004
                 ],
                 [
                    93.56208270000005,
                    50.603654299999974
                 ],
                 [
                    93.56206229999995,
                    50.60371070000002
                 ],
                 [
                    93.56206450000005,
                    50.60389860000003
                 ],
                 [
                    93.56200669999997,
                    50.604006299999966
                 ],
                 [
                    93.5620039,
                    50.604121700000015
                 ],
                 [
                    93.56186570000003,
                    50.60420289999998
                 ],
                 [
                    93.5615079,
                    50.604266299999985
                 ],
                 [
                    93.56143239999997,
                    50.60430159999998
                 ],
                 [
                    93.5613577,
                    50.60440659999996
                 ],
                 [
                    93.5613296,
                    50.6045249
                 ],
                 [
                    93.56132409999998,
                    50.60476649999998
                 ],
                 [
                    93.561364,
                    50.604935400000016
                 ],
                 [
                    93.56136120000004,
                    50.60504810000001
                 ],
                 [
                    93.56122749999999,
                    50.6051615
                 ],
                 [
                    93.5611655,
                    50.6052692
                 ],
                 [
                    93.56117469999997,
                    50.605325499999964
                 ],
                 [
                    93.56126939999999,
                    50.605488799999975
                 ],
                 [
                    93.56129609999998,
                    50.605606700000045
                 ],
                 [
                    93.5612975,
                    50.60572220000001
                 ],
                 [
                    93.56126440000001,
                    50.605781400000026
                 ],
                 [
                    93.5611644,
                    50.60588389999998
                 ],
                 [
                    93.56101770000004,
                    50.605957
                 ],
                 [
                    93.560563,
                    50.60602630000004
                 ],
                 [
                    93.56008920000004,
                    50.6062675
                 ],
                 [
                    93.56003640000002,
                    50.60643679999998
                 ],
                 [
                    93.559888,
                    50.60672739999996
                 ],
                 [
                    93.55983160000004,
                    50.60695590000004
                 ],
                 [
                    93.55993,
                    50.60707080000004
                 ],
                 [
                    93.5603603,
                    50.60743379999997
                 ],
                 [
                    93.56049610000004,
                    50.6075029
                 ],
                 [
                    93.56096620000004,
                    50.6076617
                 ],
                 [
                    93.56131940000003,
                    50.607923100000036
                 ],
                 [
                    93.5618622,
                    50.6081674
                 ],
                 [
                    93.56211189999998,
                    50.6082306
                 ],
                 [
                    93.56243709999997,
                    50.6082639
                 ],
                 [
                    93.562746,
                    50.60835369999996
                 ],
                 [
                    93.5631117,
                    50.6085935
                 ],
                 [
                    93.56328619999996,
                    50.608729599999975
                 ],
                 [
                    93.56371850000004,
                    50.609250900000035
                 ],
                 [
                    93.56391580000002,
                    50.6095264
                 ],
                 [
                    93.5640519,
                    50.60962240000004
                 ],
                 [
                    93.5643677,
                    50.6099215
                 ],
                 [
                    93.564433,
                    50.61009020000002
                 ],
                 [
                    93.56442590000003,
                    50.61020569999999
                 ],
                 [
                    93.56438090000003,
                    50.6103186
                 ],
                 [
                    93.56414709999997,
                    50.61052109999997
                 ],
                 [
                    93.5638706,
                    50.6106835
                 ],
                 [
                    93.56378410000003,
                    50.6108584
                 ],
                 [
                    93.5636213,
                    50.61100680000001
                 ],
                 [
                    93.563362,
                    50.61118789999998
                 ],
                 [
                    93.5630515,
                    50.6113263
                 ],
                 [
                    93.56288030000005,
                    50.6114747
                 ],
                 [
                    93.562641,
                    50.6115725
                 ],
                 [
                    93.56209259999997,
                    50.6115725
                 ],
                 [
                    93.56080789999999,
                    50.61173160000003
                 ],
                 [
                    93.560278,
                    50.611865700000024
                 ],
                 [
                    93.55935969999999,
                    50.6119802
                 ],
                 [
                    93.55906969999997,
                    50.612072800000014
                 ],
                 [
                    93.55870369999997,
                    50.61215509999999
                 ],
                 [
                    93.55815529999998,
                    50.61215240000003
                 ],
                 [
                    93.557817,
                    50.612084199999984
                 ],
                 [
                    93.5575287,
                    50.6119621
                 ],
                 [
                    93.55572240000004,
                    50.6108219
                 ],
                 [
                    93.5556327,
                    50.610725700000025
                 ],
                 [
                    93.555602,
                    50.610623900000036
                 ],
                 [
                    93.55572350000004,
                    50.61055620000003
                 ],
                 [
                    93.55587920000004,
                    50.6105205
                 ],
                 [
                    93.55593290000004,
                    50.6104237
                 ],
                 [
                    93.55593519999996,
                    50.61026530000003
                 ],
                 [
                    93.5558605,
                    50.610008
                 ],
                 [
                    93.55587060000003,
                    50.609433500000044
                 ],
                 [
                    93.5560161,
                    50.609253
                 ],
                 [
                    93.55607460000004,
                    50.608847400000045
                 ],
                 [
                    93.556141,
                    50.60875580000001
                 ],
                 [
                    93.5563628,
                    50.60860440000001
                 ],
                 [
                    93.5564207,
                    50.60850749999999
                 ],
                 [
                    93.5564279,
                    50.60840549999998
                 ],
                 [
                    93.55628380000003,
                    50.60834180000004
                 ],
                 [
                    93.55610220000001,
                    50.60832919999998
                 ],
                 [
                    93.55605109999996,
                    50.6082865
                 ],
                 [
                    93.55609209999999,
                    50.60818160000002
                 ],
                 [
                    93.55608659999997,
                    50.60807430000003
                 ],
                 [
                    93.55602210000005,
                    50.6079753
                 ],
                 [
                    93.55594179999999,
                    50.60795420000004
                 ],
                 [
                    93.55558319999997,
                    50.6079505
                 ],
                 [
                    93.55535019999996,
                    50.6078658
                 ],
                 [
                    93.55529070000001,
                    50.60782579999998
                 ],
                 [
                    93.5552305,
                    50.6077348
                 ],
                 [
                    93.55522019999997,
                    50.607571100000044
                 ],
                 [
                    93.55531350000004,
                    50.6072539
                 ],
                 [
                    93.55531169999998,
                    50.607101
                 ],
                 [
                    93.55525569999996,
                    50.60700190000002
                 ],
                 [
                    93.55509389999999,
                    50.6068738
                 ],
                 [
                    93.55488420000005,
                    50.606619800000026
                 ],
                 [
                    93.5548794,
                    50.60656620000003
                 ],
                 [
                    93.55492519999997,
                    50.606523
                 ],
                 [
                    93.55500530000003,
                    50.606514600000025
                 ],
                 [
                    93.5550682,
                    50.6064821
                 ],
                 [
                    93.5550844,
                    50.606431
                 ],
                 [
                    93.5549523,
                    50.6059539
                 ],
                 [
                    93.5549813,
                    50.60590539999998
                 ],
                 [
                    93.555107,
                    50.605835
                 ],
                 [
                    93.55519459999998,
                    50.60575680000003
                 ],
                 [
                    93.55522779999997,
                    50.60570830000002
                 ],
                 [
                    93.555235,
                    50.60560630000001
                 ],
                 [
                    93.5549016,
                    50.6052294
                 ],
                 [
                    93.55474609999999,
                    50.60491879999997
                 ],
                 [
                    93.55466910000001,
                    50.6048225
                 ],
                 [
                    93.5543714,
                    50.60461719999998
                 ],
                 [
                    93.55412380000001,
                    50.6043607
                 ],
                 [
                    93.55361859999996,
                    50.603727
                 ],
                 [
                    93.5535717,
                    50.603681600000016
                 ],
                 [
                    93.55324050000003,
                    50.60349260000001
                 ],
                 [
                    93.55309059999998,
                    50.6033
                 ],
                 [
                    93.552697,
                    50.60318380000004
                 ],
                 [
                    93.55215630000002,
                    50.6031058
                 ],
                 [
                    93.55188609999996,
                    50.60308290000004
                 ],
                 [
                    93.55123650000004,
                    50.603078
                 ],
                 [
                    93.55096189999996,
                    50.6030363
                 ],
                 [
                    93.5498392,
                    50.602969199999954
                 ],
                 [
                    93.54918959999996,
                    50.60296150000003
                 ],
                 [
                    93.54892769999996,
                    50.602925100000036
                 ],
                 [
                    93.54859729999998,
                    50.602805900000035
                 ],
                 [
                    93.54846150000003,
                    50.6027314
                 ],
                 [
                    93.54822960000003,
                    50.6023782
                 ],
                 [
                    93.5480511,
                    50.60226089999997
                 ],
                 [
                    93.54778039999998,
                    50.602192399999986
                 ],
                 [
                    93.5475948,
                    50.602185200000015
                 ],
                 [
                    93.54691370000003,
                    50.60200859999997
                 ],
                 [
                    93.54673679999996,
                    50.60202550000003
                 ],
                 [
                    93.5464817,
                    50.602214600000046
                 ],
                 [
                    93.5463004,
                    50.602220800000026
                 ],
                 [
                    93.54613550000005,
                    50.602184
                 ],
                 [
                    93.54562889999995,
                    50.60214069999997
                 ],
                 [
                    93.54526180000005,
                    50.60213170000003
                 ],
                 [
                    93.5449626,
                    50.602149199999964
                 ],
                 [
                    93.54469319999998,
                    50.60219080000003
                 ],
                 [
                    93.54445379999999,
                    50.60228310000003
                 ],
                 [
                    93.54403489999997,
                    50.60252929999997
                 ],
                 [
                    93.54337579999996,
                    50.60279280000002
                 ],
                 [
                    93.54316620000003,
                    50.60290650000001
                 ],
                 [
                    93.54315420000002,
                    50.602962899999966
                 ],
                 [
                    93.54318850000001,
                    50.6030138
                 ],
                 [
                    93.54318919999999,
                    50.603070099999954
                 ],
                 [
                    93.5431476,
                    50.60312130000002
                 ],
                 [
                    93.54298829999996,
                    50.6032026
                 ],
                 [
                    93.54286790000005,
                    50.60337490000004
                 ],
                 [
                    93.54273619999998,
                    50.60366010000001
                 ],
                 [
                    93.54275019999999,
                    50.603775400000046
                 ],
                 [
                    93.54280970000002,
                    50.60381539999998
                 ],
                 [
                    93.54284410000002,
                    50.603868899999966
                 ],
                 [
                    93.54288470000002,
                    50.60409959999996
                 ],
                 [
                    93.54300449999998,
                    50.6042493
                 ],
                 [
                    93.5430766,
                    50.60428390000002
                 ],
                 [
                    93.54316119999997,
                    50.604302300000036
                 ],
                 [
                    93.54340139999995,
                    50.604290400000025
                 ],
                 [
                    93.54356230000003,
                    50.604338
                 ],
                 [
                    93.543745,
                    50.60446059999999
                 ],
                 [
                    93.544073,
                    50.604732900000045
                 ],
                 [
                    93.54421720000003,
                    50.60480729999998
                 ],
                 [
                    93.54448389999999,
                    50.604894700000024
                 ],
                 [
                    93.54454760000002,
                    50.6049319
                 ],
                 [
                    93.54457360000004,
                    50.604985499999984
                 ],
                 [
                    93.5445538,
                    50.605101
                 ],
                 [
                    93.544413,
                    50.605329799999964
                 ],
                 [
                    93.54415060000004,
                    50.605615599999986
                 ],
                 [
                    93.54399570000001,
                    50.60571830000004
                 ],
                 [
                    93.5436768,
                    50.60585670000004
                 ],
                 [
                    93.54330140000003,
                    50.6058584
                 ],
                 [
                    93.5425732,
                    50.605988
                 ],
                 [
                    93.5422061,
                    50.605978900000025
                 ],
                 [
                    93.5421215,
                    50.60595789999997
                 ],
                 [
                    93.541943,
                    50.60583520000004
                 ],
                 [
                    93.5418659,
                    50.605736300000025
                 ],
                 [
                    93.54177380000002,
                    50.605430699999964
                 ],
                 [
                    93.5415148,
                    50.60528159999997
                 ],
                 [
                    93.54147630000001,
                    50.6052335
                 ],
                 [
                    93.54148180000001,
                    50.604975799999984
                 ],
                 [
                    93.54131210000001,
                    50.60452020000003
                 ],
                 [
                    93.54116290000002,
                    50.604384
                 ],
                 [
                    93.5408912,
                    50.604232300000014
                 ],
                 [
                    93.54086949999999,
                    50.60417870000002
                 ],
                 [
                    93.54085989999999,
                    50.604071400000024
                 ],
                 [
                    93.54089309999998,
                    50.604025599999964
                 ],
                 [
                    93.54122860000003,
                    50.603863
                 ],
                 [
                    93.54133309999997,
                    50.603779300000014
                 ],
                 [
                    93.5413699,
                    50.6036771
                 ],
                 [
                    93.54145710000002,
                    50.603558599999964
                 ],
                 [
                    93.54153010000002,
                    50.60330060000002
                 ],
                 [
                    93.541512,
                    50.603196
                 ],
                 [
                    93.54141689999997,
                    50.60299250000002
                 ],
                 [
                    93.54123280000002,
                    50.6027518
                 ],
                 [
                    93.54119779999996,
                    50.60264719999996
                 ],
                 [
                    93.5410437,
                    50.60244659999997
                 ],
                 [
                    93.54090369999997,
                    50.602377500000024
                 ],
                 [
                    93.5407685,
                    50.60235930000003
                 ],
                 [
                    93.5405915,
                    50.60236550000001
                 ],
                 [
                    93.54050309999995,
                    50.6023847
                 ],
                 [
                    93.5404446,
                    50.60243330000002
                 ],
                 [
                    93.54019670000001,
                    50.60251229999999
                 ],
                 [
                    93.54006270000002,
                    50.6025961
                 ],
                 [
                    93.53997429999998,
                    50.60260730000003
                 ],
                 [
                    93.5396235,
                    50.602549799999984
                 ],
                 [
                    93.53927009999998,
                    50.60262130000004
                 ],
                 [
                    93.53918179999997,
                    50.60264580000002
                 ],
                 [
                    93.53911480000002,
                    50.6026864
                 ],
                 [
                    93.53884350000004,
                    50.602934600000026
                 ],
                 [
                    93.53876380000001,
                    50.60297520000001
                 ],
                 [
                    93.53839770000002,
                    50.60304669999997
                 ],
                 [
                    93.53800009999998,
                    50.6033143
                 ],
                 [
                    93.53772590000003,
                    50.6033155
                 ],
                 [
                    93.53748049999997,
                    50.60324150000002
                 ],
                 [
                    93.53741670000002,
                    50.603198900000045
                 ],
                 [
                    93.53735650000002,
                    50.60309719999998
                 ],
                 [
                    93.53726489999998,
                    50.602831799999954
                 ],
                 [
                    93.5371242,
                    50.602698300000014
                 ],
                 [
                    93.53707780000005,
                    50.60232539999998
                 ],
                 [
                    93.53708930000002,
                    50.60222070000002
                 ],
                 [
                    93.53743329999998,
                    50.601693
                 ],
                 [
                    93.53757179999998,
                    50.6016333
                 ],
                 [
                    93.5378833,
                    50.601580900000016
                 ],
                 [
                    93.538312,
                    50.601447399999984
                 ],
                 [
                    93.53846240000004,
                    50.601328600000016
                 ],
                 [
                    93.53846069999999,
                    50.6011729
                 ],
                 [
                    93.53832419999998,
                    50.601036600000015
                 ],
                 [
                    93.53818420000002,
                    50.60096480000002
                 ],
                 [
                    93.53806930000002,
                    50.60087680000002
                 ],
                 [
                    93.5379252,
                    50.600815700000034
                 ],
                 [
                    93.537591,
                    50.60072330000003
                 ],
                 [
                    93.53718439999999,
                    50.60057750000004
                 ],
                 [
                    93.53691860000002,
                    50.60056259999996
                 ],
                 [
                    93.53663630000003,
                    50.600585399999986
                 ],
                 [
                    93.53611050000004,
                    50.6007113
                 ],
                 [
                    93.53591380000002,
                    50.6008411
                 ],
                 [
                    93.535683,
                    50.6009441
                 ],
                 [
                    93.5355023,
                    50.601004
                 ],
                 [
                    93.53536410000001,
                    50.60109050000003
                 ],
                 [
                    93.5353414,
                    50.60132149999996
                 ],
                 [
                    93.53539469999997,
                    50.6015547
                 ],
                 [
                    93.53527190000003,
                    50.6018855
                 ],
                 [
                    93.53528170000003,
                    50.602006200000034
                 ],
                 [
                    93.53536880000004,
                    50.602250099999964
                 ],
                 [
                    93.53542279999998,
                    50.602550400000034
                 ],
                 [
                    93.53542059999998,
                    50.60272760000004
                 ],
                 [
                    93.5353629,
                    50.60284059999997
                 ],
                 [
                    93.53529589999997,
                    50.60288650000004
                 ],
                 [
                    93.5351363,
                    50.60294630000001
                 ],
                 [
                    93.53437870000002,
                    50.6030813
                 ],
                 [
                    93.53410109999996,
                    50.603155099999974
                 ],
                 [
                    93.53377770000003,
                    50.6032693
                 ],
                 [
                    93.53304550000001,
                    50.603420299999975
                 ],
                 [
                    93.53262529999998,
                    50.6035537
                 ],
                 [
                    93.53256309999998,
                    50.60364789999998
                 ],
                 [
                    93.53263020000003,
                    50.6039912
                 ],
                 [
                    93.53260980000003,
                    50.60405029999997
                 ],
                 [
                    93.53244689999995,
                    50.604196
                 ],
                 [
                    93.532306,
                    50.60441409999996
                 ],
                 [
                    93.532241,
                    50.60463989999999
                 ],
                 [
                    93.53209230000002,
                    50.6049116
                 ],
                 [
                    93.53200069999998,
                    50.6050167
                 ],
                 [
                    93.5318794,
                    50.60511390000004
                 ],
                 [
                    93.53186390000003,
                    50.60523209999996
                 ],
                 [
                    93.53188630000002,
                    50.60534470000004
                 ],
                 [
                    93.5320362,
                    50.6055534
                 ],
                 [
                    93.532151,
                    50.60563340000004
                 ],
                 [
                    93.53267019999996,
                    50.605668600000016
                 ],
                 [
                    93.5329155,
                    50.605723799999964
                 ],
                 [
                    93.53297079999996,
                    50.60576650000004
                 ],
                 [
                    93.53300930000005,
                    50.605817399999985
                 ],
                 [
                    93.53292169999999,
                    50.60627410000002
                 ],
                 [
                    93.53292650000003,
                    50.606330399999976
                 ],
                 [
                    93.5331774,
                    50.60650910000002
                 ],
                 [
                    93.53332160000004,
                    50.60657819999996
                 ],
                 [
                    93.533655,
                    50.606598200000015
                 ],
                 [
                    93.5338032,
                    50.606645800000045
                 ],
                 [
                    93.53395250000001,
                    50.6067901
                 ],
                 [
                    93.53398329999999,
                    50.60690540000003
                 ],
                 [
                    93.5339512,
                    50.6070478
                 ],
                 [
                    93.53375430000004,
                    50.607172099999985
                 ],
                 [
                    93.5336923,
                    50.60728249999997
                 ],
                 [
                    93.5336174,
                    50.60774179999996
                 ],
                 [
                    93.53348460000002,
                    50.60793570000001
                 ],
                 [
                    93.533359,
                    50.60802209999997
                 ],
                 [
                    93.53293410000003,
                    50.6081234
                 ],
                 [
                    93.532638,
                    50.60842540000003
                 ],
                 [
                    93.53244970000002,
                    50.60855510000002
                 ],
                 [
                    93.53236989999998,
                    50.608585
                 ],
                 [
                    93.53192390000002,
                    50.60868630000004
                 ],
                 [
                    93.53175479999999,
                    50.60864950000001
                 ],
                 [
                    93.53160209999996,
                    50.608575
                 ],
                 [
                    93.5315092,
                    50.60857279999997
                 ],
                 [
                    93.53142939999996,
                    50.608602699999956
                 ],
                 [
                    93.5311613,
                    50.608767600000014
                 ],
                 [
                    93.53106879999997,
                    50.6087922
                 ],
                 [
                    93.530235,
                    50.60891140000001
                 ],
                 [
                    93.52988950000001,
                    50.60895330000001
                 ],
                 [
                    93.529704,
                    50.6089541
                 ],
                 [
                    93.5293452,
                    50.60893689999999
                 ],
                 [
                    93.52926480000002,
                    50.60891580000002
                 ],
                 [
                    93.52912059999998,
                    50.60884669999999
                 ],
                 [
                    93.5290058,
                    50.608764
                 ],
                 [
                    93.528476,
                    50.6085356
                 ],
                 [
                    93.5283069,
                    50.60849879999998
                 ],
                 [
                    93.5279438,
                    50.608473600000025
                 ],
                 [
                    93.52786339999997,
                    50.60844980000001
                 ],
                 [
                    93.52772760000002,
                    50.6083779
                 ],
                 [
                    93.52762470000003,
                    50.60822809999996
                 ],
                 [
                    93.5274889,
                    50.60815619999996
                 ],
                 [
                    93.52738249999997,
                    50.6080708
                 ],
                 [
                    93.5271731,
                    50.60782479999999
                 ],
                 [
                    93.527087,
                    50.607672199999975
                 ],
                 [
                    93.5270297,
                    50.607447
                 ],
                 [
                    93.52704019999999,
                    50.607259099999986
                 ],
                 [
                    93.52739690000001,
                    50.60670990000002
                 ],
                 [
                    93.52765079999996,
                    50.60641079999996
                 ],
                 [
                    93.52782709999998,
                    50.60595369999998
                 ],
                 [
                    93.52784910000003,
                    50.605661
                 ],
                 [
                    93.52770640000004,
                    50.605347600000016
                 ],
                 [
                    93.5273602,
                    50.60493579999996
                 ],
                 [
                    93.52713549999996,
                    50.6048375
                 ],
                 [
                    93.52678909999999,
                    50.60478540000004
                 ],
                 [
                    93.52631710000001,
                    50.604819700000036
                 ],
                 [
                    93.52569780000002,
                    50.60488960000004
                 ],
                 [
                    93.52498520000003,
                    50.6049063
                 ],
                 [
                    93.52469769999996,
                    50.60483780000002
                 ],
                 [
                    93.52445629999997,
                    50.60475569999998
                 ],
                 [
                    93.52427470000005,
                    50.604735
                 ],
                 [
                    93.5238436,
                    50.60464839999998
                 ],
                 [
                    93.52366509999997,
                    50.604525700000046
                 ],
                 [
                    93.52318610000003,
                    50.60431850000004
                 ],
                 [
                    93.5229323,
                    50.60424449999996
                 ],
                 [
                    93.52245280000002,
                    50.60398890000004
                 ],
                 [
                    93.52221149999995,
                    50.6039068
                 ],
                 [
                    93.5217172,
                    50.6038339
                 ],
                 [
                    93.5215648,
                    50.603778200000015
                 ],
                 [
                    93.52139889999995,
                    50.60365280000003
                 ],
                 [
                    93.52122959999998,
                    50.603602500000044
                 ],
                 [
                    93.521048,
                    50.60357919999998
                 ],
                 [
                    93.520407,
                    50.6035847
                 ],
                 [
                    93.52017720000003,
                    50.6037844
                 ],
                 [
                    93.52009750000002,
                    50.603825
                 ],
                 [
                    93.520005,
                    50.60384419999997
                 ],
                 [
                    93.51982339999998,
                    50.60382890000003
                 ],
                 [
                    93.519736,
                    50.60393129999998
                 ],
                 [
                    93.51974120000003,
                    50.60402249999998
                 ],
                 [
                    93.51967839999998,
                    50.6040658
                 ],
                 [
                    93.51949689999996,
                    50.60405310000002
                 ],
                 [
                    93.51924330000004,
                    50.60400329999999
                 ],
                 [
                    93.51906209999997,
                    50.604020199999965
                 ],
                 [
                    93.5186962,
                    50.6041077
                 ],
                 [
                    93.51851630000002,
                    50.604242699999986
                 ],
                 [
                    93.51809289999997,
                    50.604483500000015
                 ],
                 [
                    93.517975,
                    50.60450009999997
                 ],
                 [
                    93.51789529999996,
                    50.6045381
                 ],
                 [
                    93.5178495,
                    50.60458929999998
                 ],
                 [
                    93.51782540000005,
                    50.60470209999998
                 ],
                 [
                    93.51786590000002,
                    50.60493279999998
                 ],
                 [
                    93.51782080000002,
                    50.60504840000004
                 ],
                 [
                    93.51775369999997,
                    50.60508890000001
                 ],
                 [
                    93.51766540000004,
                    50.6051081
                 ],
                 [
                    93.5175726,
                    50.605108500000036
                 ],
                 [
                    93.51731889999998,
                    50.6050533
                 ],
                 [
                    93.51723480000003,
                    50.60507510000003
                 ],
                 [
                    93.51720590000004,
                    50.6051316
                 ],
                 [
                    93.51722060000002,
                    50.60532210000004
                 ],
                 [
                    93.5171248,
                    50.60542720000003
                 ],
                 [
                    93.51716329999998,
                    50.605480800000024
                 ],
                 [
                    93.5174223,
                    50.60563530000002
                 ],
                 [
                    93.5174566,
                    50.6056888
                 ],
                 [
                    93.51747960000003,
                    50.6058605
                 ],
                 [
                    93.51762789999997,
                    50.60592159999999
                 ],
                 [
                    93.51783609999995,
                    50.6060656
                 ],
                 [
                    93.51797269999997,
                    50.60620990000004
                 ],
                 [
                    93.5180406,
                    50.60624989999997
                 ],
                 [
                    93.51829010000004,
                    50.60630520000002
                 ],
                 [
                    93.5183454,
                    50.60635050000004
                 ],
                 [
                    93.51837560000001,
                    50.606409500000034
                 ],
                 [
                    93.51842229999997,
                    50.60681989999998
                 ],
                 [
                    93.5186093,
                    50.6069533
                 ],
                 [
                    93.51868649999997,
                    50.60706569999997
                 ],
                 [
                    93.51870460000005,
                    50.607178299999966
                 ],
                 [
                    93.51867989999997,
                    50.607237499999975
                 ],
                 [
                    93.51845009999995,
                    50.60743179999998
                 ],
                 [
                    93.51841760000002,
                    50.60754730000003
                 ],
                 [
                    93.51841880000003,
                    50.60766010000004
                 ],
                 [
                    93.51850560000001,
                    50.6078798
                 ],
                 [
                    93.51860790000005,
                    50.607976
                 ],
                 [
                    93.51879479999997,
                    50.6080986
                 ],
                 [
                    93.51902339999997,
                    50.6081727
                 ],
                 [
                    93.51927670000005,
                    50.6081931
                 ],
                 [
                    93.51951780000002,
                    50.6082511
                 ],
                 [
                    93.51961160000005,
                    50.6083446
                 ],
                 [
                    93.5196207,
                    50.60840089999996
                 ],
                 [
                    93.51947190000003,
                    50.608675400000024
                 ],
                 [
                    93.5193759,
                    50.60877510000001
                 ],
                 [
                    93.51937559999998,
                    50.60912669999997
                 ],
                 [
                    93.51935160000002,
                    50.609247600000025
                 ],
                 [
                    93.51922270000001,
                    50.60941190000003
                 ],
                 [
                    93.51893410000004,
                    50.61001979999997
                 ],
                 [
                    93.518884,
                    50.6100684
                 ],
                 [
                    93.51880840000003,
                    50.61010090000002
                 ],
                 [
                    93.51853819999997,
                    50.610075300000034
                 ],
                 [
                    93.51844980000001,
                    50.610094500000024
                 ],
                 [
                    93.51837849999998,
                    50.61013239999996
                 ],
                 [
                    93.51828690000004,
                    50.6102401
                 ],
                 [
                    93.51823520000002,
                    50.610530200000014
                 ],
                 [
                    93.51799419999998,
                    50.61124530000003
                 ],
                 [
                    93.51799580000002,
                    50.611392899999984
                 ],
                 [
                    93.51805620000005,
                    50.6115134
                 ],
                 [
                    93.5181265,
                    50.61177079999999
                 ],
                 [
                    93.518094,
                    50.6118837
                 ],
                 [
                    93.51809179999998,
                    50.6120662
                 ],
                 [
                    93.51801,
                    50.6123001
                 ],
                 [
                    93.51781889999995,
                    50.612572
                 ],
                 [
                    93.517576,
                    50.61272880000003
                 ],
                 [
                    93.51725449999998,
                    50.61303619999997
                 ],
                 [
                    93.51711189999999,
                    50.613112
                 ],
                 [
                    93.51679269999995,
                    50.61323689999997
                 ],
                 [
                    93.51669260000003,
                    50.6133393
                 ],
                 [
                    93.51661850000002,
                    50.6135088
                 ],
                 [
                    93.51633759999996,
                    50.61367110000001
                 ],
                 [
                    93.51600970000001,
                    50.61377450000004
                 ],
                 [
                    93.51564280000002,
                    50.6137815
                 ],
                 [
                    93.5154289,
                    50.61390320000003
                 ],
                 [
                    93.51525220000003,
                    50.613947
                 ],
                 [
                    93.51461130000003,
                    50.61397129999998
                 ],
                 [
                    93.51444309999997,
                    50.614020300000035
                 ],
                 [
                    93.514141,
                    50.61417729999997
                 ],
                 [
                    93.5136812,
                    50.61456860000002
                 ],
                 [
                    93.51332750000003,
                    50.61501029999997
                 ],
                 [
                    93.5132004,
                    50.615349100000024
                 ],
                 [
                    93.51297730000002,
                    50.61578219999998
                 ],
                 [
                    93.51268479999997,
                    50.6160412
                 ],
                 [
                    93.51244119999998,
                    50.61614159999996
                 ],
                 [
                    93.51219309999996,
                    50.6162151
                 ],
                 [
                    93.512042,
                    50.61629089999997
                 ],
                 [
                    93.51185020000001,
                    50.61650110000004
                 ],
                 [
                    93.511843,
                    50.6166166
                 ],
                 [
                    93.51176919999997,
                    50.6168129
                 ],
                 [
                    93.51177469999999,
                    50.61692830000004
                 ],
                 [
                    93.5117415,
                    50.61698209999996
                 ],
                 [
                    93.51147350000002,
                    50.61716310000001
                 ],
                 [
                    93.51130549999998,
                    50.61723090000003
                 ],
                 [
                    93.5111286,
                    50.617266599999965
                 ],
                 [
                    93.51085879999997,
                    50.617283899999975
                 ],
                 [
                    93.5103879,
                    50.61742820000001
                 ],
                 [
                    93.510029,
                    50.6174056
                 ],
                 [
                    93.50976360000003,
                    50.61743629999997
                 ],
                 [
                    93.5090783,
                    50.61765940000004
                 ],
                 [
                    93.50872450000003,
                    50.61772
                 ],
                 [
                    93.5084504,
                    50.6177319
                 ],
                 [
                    93.5075936,
                    50.6176954
                 ],
                 [
                    93.507323,
                    50.617642900000014
                 ],
                 [
                    93.5072041,
                    50.61756560000001
                 ],
                 [
                    93.50718239999998,
                    50.61751469999997
                 ],
                 [
                    93.5072652,
                    50.617361299999985
                 ],
                 [
                    93.50728930000004,
                    50.6172539
                 ],
                 [
                    93.5072845,
                    50.61720019999998
                 ],
                 [
                    93.50724180000002,
                    50.61715470000003
                 ],
                 [
                    93.50707269999997,
                    50.6171179
                 ],
                 [
                    93.50679469999997,
                    50.61715939999998
                 ],
                 [
                    93.5067143,
                    50.6171382
                 ],
                 [
                    93.50657430000003,
                    50.6170664
                 ],
                 [
                    93.50650150000004,
                    50.616967399999986
                 ],
                 [
                    93.50633170000003,
                    50.6168688
                 ],
                 [
                    93.50617090000004,
                    50.616823900000014
                 ],
                 [
                    93.50590069999998,
                    50.6168009
                 ],
                 [
                    93.5057356,
                    50.616756
                 ],
                 [
                    93.50559980000004,
                    50.61668140000004
                 ],
                 [
                    93.50550540000005,
                    50.61653149999998
                 ],
                 [
                    93.5054501,
                    50.6164888
                 ],
                 [
                    93.50505640000003,
                    50.616359
                 ],
                 [
                    93.46563659999998,
                    50.627606800000024
                 ],
                 [
                    93.4608117,
                    50.629117499999964
                 ],
                 [
                    93.45664089999997,
                    50.63111429999998
                 ],
                 [
                    93.4244431,
                    50.628324399999975
                 ],
                 [
                    93.39745660000001,
                    50.634504099999965
                 ],
                 [
                    93.38766529999998,
                    50.63143
                 ],
                 [
                    93.3431388,
                    50.6167443
                 ],
                 [
                    93.3226,
                    50.6148
                 ],
                 [
                    93.3141,
                    50.6119
                 ],
                 [
                    93.2999,
                    50.6103
                 ],
                 [
                    93.2858,
                    50.6093
                 ],
                 [
                    93.2717,
                    50.6102
                 ],
                 [
                    93.26553,
                    50.60845640000002
                 ],
                 [
                    93.2533,
                    50.605
                 ],
                 [
                    93.249,
                    50.5941
                 ],
                 [
                    93.225,
                    50.5898
                 ],
                 [
                    93.2066,
                    50.5908
                 ],
                 [
                    93.201,
                    50.5971
                 ],
                 [
                    93.1911,
                    50.5989
                 ],
                 [
                    93.1812,
                    50.5989
                 ],
                 [
                    93.1685,
                    50.5941
                 ],
                 [
                    93.1629,
                    50.6024
                 ],
                 [
                    93.1445,
                    50.6034
                 ],
                 [
                    93.136,
                    50.6007
                 ],
                 [
                    93.136,
                    50.5856
                 ],
                 [
                    93.1275,
                    50.5846
                 ],
                 [
                    93.1219,
                    50.5862
                 ],
                 [
                    93.0937,
                    50.599
                 ],
                 [
                    93.0711,
                    50.6125
                 ],
                 [
                    93.057,
                    50.6188
                 ],
                 [
                    93.0471,
                    50.6188
                 ],
                 [
                    93.0343,
                    50.6143
                 ],
                 [
                    93.0188,
                    50.6062
                 ],
                 [
                    93.0103,
                    50.6062
                 ],
                 [
                    93.0047,
                    50.6107
                 ],
                 [
                    92.9905,
                    50.6261
                 ],
                 [
                    92.9806,
                    50.6422
                 ],
                 [
                    92.9707,
                    50.653
                 ],
                 [
                    92.9594,
                    50.6619
                 ],
                 [
                    92.96060670000001,
                    50.66718190000003
                 ],
                 [
                    92.96158240000001,
                    50.6714517
                 ],
                 [
                    92.9835,
                    50.6871
                 ],
                 [
                    93.0203,
                    50.7132
                 ],
                 [
                    93.0259,
                    50.7267
                 ],
                 [
                    93.0274,
                    50.7337
                 ],
                 [
                    93.0274,
                    50.759
                 ],
                 [
                    93.0231,
                    50.7779
                 ],
                 [
                    93.0089,
                    50.7932
                 ],
                 [
                    92.9948,
                    50.7959
                 ],
                 [
                    92.9593,
                    50.7968
                 ],
                 [
                    92.9423,
                    50.7959
                 ],
                 [
                    92.9352,
                    50.7914
                 ],
                 [
                    92.9196,
                    50.7905
                 ],
                 [
                    92.9111,
                    50.7941
                 ],
                 [
                    92.8997,
                    50.8021
                 ],
                 [
                    92.8898,
                    50.803
                 ],
                 [
                    92.8742,
                    50.803
                 ],
                 [
                    92.8685,
                    50.8039
                 ],
                 [
                    92.8557,
                    50.8084
                 ],
                 [
                    92.8472,
                    50.8085
                 ],
                 [
                    92.8231,
                    50.8092
                 ],
                 [
                    92.8132,
                    50.8092
                 ],
                 [
                    92.799,
                    50.8083
                 ],
                 [
                    92.7877,
                    50.8056
                 ],
                 [
                    92.7777,
                    50.8002
                 ],
                 [
                    92.7679,
                    50.7928
                 ],
                 [
                    92.7566,
                    50.7759
                 ],
                 [
                    92.7567,
                    50.7633
                 ],
                 [
                    92.7624,
                    50.7444
                 ],
                 [
                    92.7611,
                    50.7336
                 ],
                 [
                    92.754,
                    50.7275
                 ],
                 [
                    92.7399,
                    50.7219
                 ],
                 [
                    92.7338868,
                    50.71934349999998
                 ],
                 [
                    92.7272,
                    50.7165
                 ],
                 [
                    92.713,
                    50.7137
                 ],
                 [
                    92.6932,
                    50.7101
                 ],
                 [
                    92.6649,
                    50.7073
                 ],
                 [
                    92.6465,
                    50.7055
                 ],
                 [
                    92.6139,
                    50.7108
                 ],
                 [
                    92.594,
                    50.7197
                 ],
                 [
                    92.5883,
                    50.7259
                 ],
                 [
                    92.5911,
                    50.7332
                 ],
                 [
                    92.5925,
                    50.7403
                 ],
                 [
                    92.6066,
                    50.7458
                 ],
                 [
                    92.6135,
                    50.762
                 ],
                 [
                    92.6021,
                    50.7682
                 ],
                 [
                    92.5992,
                    50.7745
                 ],
                 [
                    92.5907,
                    50.7826
                 ],
                 [
                    92.5793,
                    50.7906
                 ],
                 [
                    92.565,
                    50.7961
                 ],
                 [
                    92.5551,
                    50.7959
                 ],
                 [
                    92.5423,
                    50.7959
                 ],
                 [
                    92.5225,
                    50.7931
                 ],
                 [
                    92.497,
                    50.7867
                 ],
                 [
                    92.4786,
                    50.7866
                 ],
                 [
                    92.4587,
                    50.79
                 ],
                 [
                    92.4445,
                    50.7973
                 ],
                 [
                    92.4273,
                    50.8062
                 ],
                 [
                    92.4216,
                    50.8114
                 ],
                 [
                    92.4201,
                    50.8205
                 ],
                 [
                    92.4129,
                    50.8268
                 ],
                 [
                    92.4029,
                    50.8312
                 ],
                 [
                    92.4014,
                    50.8347
                 ],
                 [
                    92.4013,
                    50.842
                 ],
                 [
                    92.404,
                    50.8562
                 ],
                 [
                    92.40195029999997,
                    50.85997380000002
                 ],
                 [
                    92.3996,
                    50.8643
                 ],
                 [
                    92.3939,
                    50.8689
                 ],
                 [
                    92.3867,
                    50.8743
                 ],
                 [
                    92.3781,
                    50.8778
                 ],
                 [
                    92.3581,
                    50.8851
                 ],
                 [
                    92.3482,
                    50.8849
                 ],
                 [
                    92.3326,
                    50.8803
                 ],
                 [
                    92.3128,
                    50.8775
                 ],
                 [
                    92.3057,
                    50.8756
                 ],
                 [
                    92.3058,
                    50.8692
                 ],
                 [
                    92.3116,
                    50.8595
                 ],
                 [
                    92.3232,
                    50.8434
                 ],
                 [
                    92.3234,
                    50.8335
                 ],
                 [
                    92.3178,
                    50.8254
                 ],
                 [
                    92.3095,
                    50.8145
                 ],
                 [
                    92.2955,
                    50.7974
                 ],
                 [
                    92.2957,
                    50.7884
                 ],
                 [
                    92.3059,
                    50.7678
                 ],
                 [
                    92.306,
                    50.7616
                 ],
                 [
                    92.3004,
                    50.7587
                 ],
                 [
                    92.2907,
                    50.7533
                 ],
                 [
                    92.285,
                    50.745
                 ],
                 [
                    92.277866,
                    50.73782480000004
                 ],
                 [
                    92.2729345,
                    50.73126209999998
                 ],
                 [
                    92.270169,
                    50.72935769999997
                 ],
                 [
                    92.2408879,
                    50.72462750000002
                 ],
                 [
                    92.2261182,
                    50.720821
                 ],
                 [
                    92.215918,
                    50.71771140000003
                 ],
                 [
                    92.21138149999997,
                    50.716791500000035
                 ],
                 [
                    92.21109059999998,
                    50.71697620000004
                 ],
                 [
                    92.20293429999995,
                    50.71473309999998
                 ],
                 [
                    92.17214190000001,
                    50.70625780000004
                 ],
                 [
                    92.15066489999997,
                    50.70307939999996
                 ],
                 [
                    92.1023674,
                    50.697424799999986
                 ],
                 [
                    92.07170179999997,
                    50.695824700000024
                 ],
                 [
                    92.06778220000002,
                    50.6966017
                 ],
                 [
                    92.04877509999999,
                    50.700393200000015
                 ],
                 [
                    92.0298911,
                    50.7035941
                 ],
                 [
                    92.02223580000005,
                    50.7051388
                 ],
                 [
                    92.014347,
                    50.707089799999956
                 ],
                 [
                    92.01354060000003,
                    50.70728920000003
                 ],
                 [
                    91.99982720000003,
                    50.7129471
                 ],
                 [
                    91.9913594,
                    50.71267240000001
                 ],
                 [
                    91.9875659,
                    50.713353
                 ],
                 [
                    91.98149619999997,
                    50.716035199999986
                 ],
                 [
                    91.97675440000002,
                    50.71747630000003
                 ],
                 [
                    91.97397249999997,
                    50.71809680000003
                 ],
                 [
                    91.967787,
                    50.71845630000002
                 ],
                 [
                    91.8843,
                    50.7321
                 ],
                 [
                    91.8559,
                    50.7354
                 ],
                 [
                    91.8403,
                    50.7353
                 ],
                 [
                    91.8319,
                    50.7334
                 ],
                 [
                    91.8207,
                    50.7287
                 ],
                 [
                    91.8024,
                    50.7243
                 ],
                 [
                    91.7925,
                    50.724
                 ],
                 [
                    91.7784,
                    50.7194
                 ],
                 [
                    91.7547,
                    50.7056
                 ],
                 [
                    91.7391,
                    50.6992
                 ],
                 [
                    91.7224,
                    50.6972
                 ],
                 [
                    91.7155,
                    50.6899
                 ],
                 [
                    91.6489,
                    50.6424
                 ],
                 [
                    91.6465,
                    50.628
                 ],
                 [
                    91.6502,
                    50.6002
                 ],
                 [
                    91.6493952,
                    50.591640099999964
                 ],
                 [
                    91.6491,
                    50.5885
                 ],
                 [
                    91.6337,
                    50.582
                 ],
                 [
                    91.621,
                    50.5828
                 ],
                 [
                    91.604,
                    50.5844
                 ],
                 [
                    91.5941,
                    50.5842
                 ],
                 [
                    91.5786,
                    50.5841
                 ],
                 [
                    91.5574,
                    50.5849
                 ],
                 [
                    91.5391,
                    50.5845
                 ],
                 [
                    91.5292,
                    50.5834
                 ],
                 [
                    91.5239,
                    50.5735
                 ],
                 [
                    91.5197,
                    50.5716
                 ],
                 [
                    91.514,
                    50.5734
                 ],
                 [
                    91.5085,
                    50.5688
                 ],
                 [
                    91.5072,
                    50.5643
                 ],
                 [
                    91.5017,
                    50.5597
                 ],
                 [
                    91.4947,
                    50.5578
                 ],
                 [
                    91.4694,
                    50.5538
                 ],
                 [
                    91.4582,
                    50.552
                 ],
                 [
                    91.4528,
                    50.5456
                 ],
                 [
                    91.4531,
                    50.5366
                 ],
                 [
                    91.4539,
                    50.5106
                 ],
                 [
                    91.44892979999997,
                    50.50523420000001
                 ],
                 [
                    91.44898120000005,
                    50.5050923
                 ],
                 [
                    91.44887209999999,
                    50.504845
                 ],
                 [
                    91.4457301,
                    50.5018859
                 ],
                 [
                    91.4455563,
                    50.501627
                 ],
                 [
                    91.44526819999997,
                    50.5000147
                 ],
                 [
                    91.44136619999996,
                    50.49441810000001
                 ],
                 [
                    91.4411497,
                    50.49427880000003
                 ],
                 [
                    91.43770120000003,
                    50.49409830000002
                 ],
                 [
                    91.42981169999997,
                    50.492358500000044
                 ],
                 [
                    91.41823320000005,
                    50.48840739999998
                 ],
                 [
                    91.40565090000001,
                    50.48414740000002
                 ],
                 [
                    91.40236760000005,
                    50.481733100000014
                 ],
                 [
                    91.401582,
                    50.48077920000004
                 ],
                 [
                    91.3998552,
                    50.47844019999996
                 ],
                 [
                    91.39918440000001,
                    50.477818699999965
                 ],
                 [
                    91.39741680000004,
                    50.4750697
                 ],
                 [
                    91.3974195,
                    50.473746
                 ],
                 [
                    91.40033860000004,
                    50.471077
                 ],
                 [
                    91.40011940000002,
                    50.46944850000001
                 ],
                 [
                    91.40003389999995,
                    50.46679730000003
                 ],
                 [
                    91.40447609999998,
                    50.46069340000001
                 ],
                 [
                    91.40745230000003,
                    50.4552905
                 ],
                 [
                    91.4094,
                    50.4506
                 ],
                 [
                    91.3952,
                    50.4549
                 ],
                 [
                    91.3796,
                    50.4583
                 ],
                 [
                    91.3568,
                    50.4643
                 ],
                 [
                    91.32,
                    50.4709
                 ],
                 [
                    91.2933,
                    50.4696
                 ],
                 [
                    91.2516,
                    50.4546
                 ],
                 [
                    91.2085,
                    50.4396
                 ],
                 [
                    91.16,
                    50.4201
                 ],
                 [
                    91.1557,
                    50.4199
                 ],
                 [
                    91.1472,
                    50.4216
                 ],
                 [
                    91.1203,
                    50.4274
                 ],
                 [
                    91.1103,
                    50.431
                 ],
                 [
                    91.0932,
                    50.436
                 ],
                 [
                    91.0876,
                    50.4359
                 ],
                 [
                    91.0765,
                    50.4311
                 ],
                 [
                    91.0568,
                    50.4309
                 ],
                 [
                    91.0383,
                    50.4351
                 ],
                 [
                    91.0257,
                    50.4348
                 ],
                 [
                    91.0118,
                    50.4301
                 ],
                 [
                    91.0048,
                    50.4291
                 ],
                 [
                    90.9989208,
                    50.429584199999965
                 ],
                 [
                    90.9963,
                    50.4298
                 ],
                 [
                    90.9823,
                    50.4296
                 ],
                 [
                    90.9696,
                    50.4294
                 ],
                 [
                    90.9431,
                    50.4237
                 ],
                 [
                    90.932,
                    50.4197
                 ],
                 [
                    90.9208,
                    50.4185
                 ],
                 [
                    90.9069,
                    50.4139
                 ],
                 [
                    90.903,
                    50.4084
                 ],
                 [
                    90.9023,
                    50.3922
                 ],
                 [
                    90.9082,
                    50.3842
                 ],
                 [
                    90.9116,
                    50.3726
                 ],
                 [
                    90.9119,
                    50.3645
                 ],
                 [
                    90.911,
                    50.3528
                 ],
                 [
                    90.9056,
                    50.3482
                 ],
                 [
                    90.8986,
                    50.3483
                 ],
                 [
                    90.8929,
                    50.3505
                 ],
                 [
                    90.8828,
                    50.3559
                 ],
                 [
                    90.8785,
                    50.3569
                 ],
                 [
                    90.873,
                    50.3547
                 ],
                 [
                    90.8594,
                    50.3456
                 ],
                 [
                    90.8481,
                    50.3454
                 ],
                 [
                    90.8382,
                    50.3477
                 ],
                 [
                    90.8351,
                    50.3532
                 ],
                 [
                    90.8293,
                    50.3569
                 ],
                 [
                    90.8196,
                    50.3547
                 ],
                 [
                    90.8157,
                    50.3475
                 ],
                 [
                    90.8061,
                    50.3421
                 ],
                 [
                    90.8051,
                    50.3347
                 ],
                 [
                    90.7956,
                    50.3264
                 ],
                 [
                    90.79,
                    50.3272
                 ],
                 [
                    90.7844,
                    50.3271
                 ],
                 [
                    90.7826,
                    50.3343
                 ],
                 [
                    90.7782,
                    50.3379
                 ],
                 [
                    90.7726,
                    50.3377
                 ],
                 [
                    90.7629,
                    50.3349
                 ],
                 [
                    90.7437,
                    50.3245
                 ],
                 [
                    90.7203,
                    50.316
                 ],
                 [
                    90.71651319999997,
                    50.315338799999964
                 ],
                 [
                    90.714,
                    50.3149
                 ],
                 [
                    90.7091,
                    50.3141
                 ],
                 [
                    90.7064,
                    50.3129
                 ],
                 [
                    90.708,
                    50.3093
                 ],
                 [
                    90.7192,
                    50.2791
                 ],
                 [
                    90.7308,
                    50.2434
                 ],
                 [
                    90.6993,
                    50.2265
                 ],
                 [
                    90.6815,
                    50.2192
                 ],
                 [
                    90.6733,
                    50.2143
                 ],
                 [
                    90.6691,
                    50.2132
                 ],
                 [
                    90.6648,
                    50.2152
                 ],
                 [
                    90.6546,
                    50.223
                 ],
                 [
                    90.6532,
                    50.2247
                 ],
                 [
                    90.6489,
                    50.2255
                 ],
                 [
                    90.642,
                    50.2227
                 ],
                 [
                    90.6308,
                    50.2225
                 ],
                 [
                    90.6182,
                    50.224
                 ],
                 [
                    90.6024,
                    50.2309
                 ],
                 [
                    90.5981,
                    50.2326
                 ],
                 [
                    90.5978,
                    50.2387
                 ],
                 [
                    90.5935,
                    50.2407
                 ],
                 [
                    90.5807,
                    50.2439
                 ],
                 [
                    90.5709,
                    50.2437
                 ],
                 [
                    90.564,
                    50.2409
                 ],
                 [
                    90.557,
                    50.2407
                 ],
                 [
                    90.5499,
                    50.2433
                 ],
                 [
                    90.5443,
                    50.2432
                 ],
                 [
                    90.5387,
                    50.2421
                 ],
                 [
                    90.5292,
                    50.2374
                 ],
                 [
                    90.5194,
                    50.2363
                 ],
                 [
                    90.5041,
                    50.2351
                 ],
                 [
                    90.4834,
                    50.2282
                 ],
                 [
                    90.4795,
                    50.2218
                 ],
                 [
                    90.4798,
                    50.2173
                 ],
                 [
                    90.4815,
                    50.2103
                 ],
                 [
                    90.4748,
                    50.2057
                 ],
                 [
                    90.4528,
                    50.1971
                 ],
                 [
                    90.4389,
                    50.195
                 ],
                 [
                    90.4121,
                    50.1991
                 ],
                 [
                    90.3976424,
                    50.19681670000004
                 ],
                 [
                    90.38888770000003,
                    50.19264070000002
                 ],
                 [
                    90.3772147,
                    50.17901130000004
                 ],
                 [
                    90.37069160000003,
                    50.17351439999997
                 ],
                 [
                    90.35867530000002,
                    50.1633986
                 ],
                 [
                    90.34374069999996,
                    50.15471050000004
                 ],
                 [
                    90.32897789999998,
                    50.15218069999998
                 ],
                 [
                    90.30752020000004,
                    50.13160779999996
                 ],
                 [
                    90.29670549999997,
                    50.119391800000045
                 ],
                 [
                    90.28520420000004,
                    50.11091589999996
                 ],
                 [
                    90.247782,
                    50.104310199999986
                 ],
                 [
                    90.20881490000002,
                    50.10265860000003
                 ],
                 [
                    90.16521289999997,
                    50.10816359999996
                 ],
                 [
                    90.11886429999996,
                    50.09759349999998
                 ],
                 [
                    90.0669493,
                    50.083146
                 ],
                 [
                    90.06143840000001,
                    50.08016660000003
                 ],
                 [
                    90.05321109999998,
                    50.07607609999997
                 ],
                 [
                    90.0291156,
                    50.06581130000003
                 ],
                 [
                    90.02398780000001,
                    50.062956499999984
                 ],
                 [
                    90.020559,
                    50.0604596
                 ],
                 [
                    90.0077,
                    50.0579
                 ],
                 [
                    90.0023,
                    50.0542
                 ],
                 [
                    90.0051,
                    50.0318
                 ],
                 [
                    90.02250460000003,
                    50.0135864
                 ],
                 [
                    90.03072129999998,
                    50.00783769999997
                 ],
                 [
                    90.03143690000005,
                    50.00419630000003
                 ],
                 [
                    90.03155619999997,
                    49.998638
                 ],
                 [
                    90.02887250000005,
                    49.998983
                 ],
                 [
                    90.02200479999998,
                    50.0012214
                 ],
                 [
                    90.01729920000001,
                    49.9904665
                 ],
                 [
                    90.0074115,
                    49.9881488
                 ],
                 [
                    89.99969529999998,
                    49.9823983
                 ],
                 [
                    89.99239970000004,
                    49.981162
                 ],
                 [
                    89.98647740000004,
                    49.97854019999998
                 ],
                 [
                    89.9799,
                    49.9737
                 ],
                 [
                    89.97483020000001,
                    49.97125940000002
                 ],
                 [
                    89.9671,
                    49.9724
                 ],
                 [
                    89.9587,
                    49.9717
                 ],
                 [
                    89.9516,
                    49.9691
                 ],
                 [
                    89.9473,
                    49.9677
                 ],
                 [
                    89.93209969999997,
                    49.9651263
                 ],
                 [
                    89.92019150000002,
                    49.962409399999984
                 ],
                 [
                    89.91693680000002,
                    49.96332770000002
                 ],
                 [
                    89.91122669999999,
                    49.9621155
                 ],
                 [
                    89.90961390000004,
                    49.9598408
                 ],
                 [
                    89.90512509999998,
                    49.95820430000004
                 ],
                 [
                    89.88956009999998,
                    49.95478579999999
                 ],
                 [
                    89.88275820000003,
                    49.95299690000003
                 ],
                 [
                    89.87900819999999,
                    49.95338259999997
                 ],
                 [
                    89.87086760000003,
                    49.95893889999998
                 ],
                 [
                    89.8659454,
                    49.9585001
                 ],
                 [
                    89.86142469999996,
                    49.959444
                 ],
                 [
                    89.85482959999997,
                    49.957530599999984
                 ],
                 [
                    89.83751120000004,
                    49.95453090000004
                 ],
                 [
                    89.82257339999998,
                    49.9518287
                 ],
                 [
                    89.81855769999999,
                    49.9514696
                 ],
                 [
                    89.80628450000003,
                    49.95253410000002
                 ],
                 [
                    89.79935,
                    49.95337519999998
                 ],
                 [
                    89.79527029999998,
                    49.95306450000003
                 ],
                 [
                    89.7933608,
                    49.9521882
                 ],
                 [
                    89.78734359999996,
                    49.9516784
                 ],
                 [
                    89.781803,
                    49.95179020000002
                 ],
                 [
                    89.77659759999999,
                    49.951503
                 ],
                 [
                    89.77089,
                    49.95003469999999
                 ],
                 [
                    89.76730579999999,
                    49.9488644
                 ],
                 [
                    89.7585787,
                    49.9482807
                 ],
                 [
                    89.7522465,
                    49.9484752
                 ],
                 [
                    89.7441516,
                    49.94760979999999
                 ],
                 [
                    89.73355319999999,
                    49.947931400000044
                 ],
                 [
                    89.72056319999997,
                    49.9438807
                 ],
                 [
                    89.70595679999998,
                    49.9439408
                 ],
                 [
                    89.70002359999997,
                    49.941671299999975
                 ],
                 [
                    89.695084,
                    49.940402499999976
                 ],
                 [
                    89.6844956,
                    49.9384544
                 ],
                 [
                    89.6786817,
                    49.936311100000026
                 ],
                 [
                    89.6761044,
                    49.93576140000002
                 ],
                 [
                    89.67474050000004,
                    49.93450269999997
                 ],
                 [
                    89.66963839999995,
                    49.9328951
                 ],
                 [
                    89.66696970000004,
                    49.931776099999965
                 ],
                 [
                    89.66167789999997,
                    49.930058200000026
                 ],
                 [
                    89.6591617,
                    49.928978300000026
                 ],
                 [
                    89.63967839999997,
                    49.92194749999996
                 ],
                 [
                    89.62928539999997,
                    49.9187749
                 ],
                 [
                    89.61239569999998,
                    49.915911499999964
                 ],
                 [
                    89.60772590000003,
                    49.91483580000004
                 ],
                 [
                    89.60306360000001,
                    49.91426220000001
                 ],
                 [
                    89.59357030000004,
                    49.91228730000003
                 ],
                 [
                    89.59792190000003,
                    49.911466
                 ],
                 [
                    89.60393579999997,
                    49.90893390000004
                 ],
                 [
                    89.6070652,
                    49.90652209999997
                 ],
                 [
                    89.60996969999998,
                    49.905396800000034
                 ],
                 [
                    89.61618509999998,
                    49.90687619999996
                 ],
                 [
                    89.6211147,
                    49.906646699999975
                 ],
                 [
                    89.62594430000001,
                    49.90541869999998
                 ],
                 [
                    89.62903270000002,
                    49.903544299999965
                 ],
                 [
                    89.63087959999999,
                    49.899345
                 ],
                 [
                    89.63096180000004,
                    49.89588319999998
                 ],
                 [
                    89.6305459,
                    49.8920674
                 ],
                 [
                    89.6312319,
                    49.88944850000003
                 ],
                 [
                    89.6311836,
                    49.887494299999986
                 ],
                 [
                    89.630761,
                    49.885336399999964
                 ],
                 [
                    89.62094210000002,
                    49.883216
                 ],
                 [
                    89.614875,
                    49.87783949999997
                 ],
                 [
                    89.61368250000004,
                    49.87412319999997
                 ],
                 [
                    89.613491,
                    49.87146710000003
                 ],
                 [
                    89.61127169999996,
                    49.86873459999998
                 ],
                 [
                    89.61174150000002,
                    49.86352
                 ],
                 [
                    89.6136122,
                    49.86161219999998
                 ],
                 [
                    89.61876620000002,
                    49.85963149999996
                 ],
                 [
                    89.62123310000004,
                    49.8546564
                 ],
                 [
                    89.62590050000004,
                    49.8531235
                 ],
                 [
                    89.62728979999997,
                    49.850055
                 ],
                 [
                    89.6303507,
                    49.8451563
                 ],
                 [
                    89.63619140000002,
                    49.845183700000035
                 ],
                 [
                    89.64023449999996,
                    49.84509269999997
                 ],
                 [
                    89.64318730000004,
                    49.843351799999986
                 ],
                 [
                    89.64334749999996,
                    49.84125370000003
                 ],
                 [
                    89.64200760000003,
                    49.84016229999997
                 ],
                 [
                    89.63972750000002,
                    49.83788830000002
                 ],
                 [
                    89.6397745,
                    49.835508
                 ],
                 [
                    89.6413231,
                    49.8327108
                 ],
                 [
                    89.64471390000004,
                    49.8308667
                 ],
                 [
                    89.64863649999997,
                    49.82968730000003
                 ],
                 [
                    89.65095350000001,
                    49.82872840000001
                 ],
                 [
                    89.6510371,
                    49.82644020000004
                 ],
                 [
                    89.64760360000004,
                    49.82256639999998
                 ],
                 [
                    89.64357529999998,
                    49.81906870000003
                 ],
                 [
                    89.63691429999996,
                    49.818046
                 ],
                 [
                    89.62920909999997,
                    49.818348
                 ],
                 [
                    89.62654540000001,
                    49.8188708
                 ],
                 [
                    89.62504949999997,
                    49.81801279999998
                 ],
                 [
                    89.62558140000002,
                    49.81672589999999
                 ],
                 [
                    89.628274,
                    49.813465499999964
                 ],
                 [
                    89.6293,
                    49.8095
                 ],
                 [
                    89.62894260000003,
                    49.8083606
                 ],
                 [
                    89.62446249999998,
                    49.806369099999976
                 ],
                 [
                    89.630858,
                    49.8026699
                 ],
                 [
                    89.63596129999998,
                    49.802031099999965
                 ],
                 [
                    89.64260489999998,
                    49.79763390000002
                 ],
                 [
                    89.65062459999997,
                    49.79243620000002
                 ],
                 [
                    89.6642,
                    49.7871
                 ],
                 [
                    89.670406,
                    49.784337299999954
                 ],
                 [
                    89.67345159999998,
                    49.783475200000034
                 ],
                 [
                    89.6765,
                    49.7834
                 ],
                 [
                    89.6862,
                    49.7842
                 ],
                 [
                    89.69206519999997,
                    49.7854843
                 ],
                 [
                    89.69487530000005,
                    49.7861971
                 ],
                 [
                    89.69577849999999,
                    49.7869098
                 ],
                 [
                    89.69575339999996,
                    49.78820570000002
                 ],
                 [
                    89.69709820000003,
                    49.789429600000034
                 ],
                 [
                    89.699812,
                    49.79092370000002
                 ],
                 [
                    89.70176909999996,
                    49.79074550000002
                 ],
                 [
                    89.706,
                    49.7908
                 ],
                 [
                    89.70944669999999,
                    49.79081029999996
                 ],
                 [
                    89.7118805,
                    49.79009759999997
                 ],
                 [
                    89.7132618,
                    49.78877749999999
                 ],
                 [
                    89.71450689999999,
                    49.78573460000002
                 ],
                 [
                    89.71376230000001,
                    49.78290510000003
                 ],
                 [
                    89.71253289999999,
                    49.7773804
                 ],
                 [
                    89.714719,
                    49.774879199999965
                 ],
                 [
                    89.71907410000004,
                    49.7719084
                 ],
                 [
                    89.7164577,
                    49.7689897
                 ],
                 [
                    89.71347710000005,
                    49.7680042
                 ],
                 [
                    89.71400930000003,
                    49.76470389999996
                 ],
                 [
                    89.71241259999998,
                    49.761816
                 ],
                 [
                    89.7129194,
                    49.76038
                 ],
                 [
                    89.71689350000001,
                    49.75451169999998
                 ],
                 [
                    89.72079670000002,
                    49.75226509999999
                 ],
                 [
                    89.7207257,
                    49.749514
                 ],
                 [
                    89.7175096,
                    49.746875099999976
                 ],
                 [
                    89.7143,
                    49.7412
                 ],
                 [
                    89.7192,
                    49.7283
                 ],
                 [
                    89.705,
                    49.7234
                 ],
                 [
                    89.69398710000002,
                    49.72036590000004
                 ],
                 [
                    89.677842,
                    49.7142725
                 ],
                 [
                    89.6608,
                    49.6999
                 ],
                 [
                    89.6454,
                    49.6961
                 ],
                 [
                    89.6074,
                    49.686
                 ],
                 [
                    89.5962,
                    49.6836
                 ],
                 [
                    89.5892,
                    49.6819
                 ],
                 [
                    89.5788303,
                    49.6815214
                 ],
                 [
                    89.5714653,
                    49.68348769999997
                 ],
                 [
                    89.56863390000004,
                    49.68354080000001
                 ],
                 [
                    89.56485870000004,
                    49.683116
                 ],
                 [
                    89.5530599,
                    49.68541430000004
                 ],
                 [
                    89.54673479999998,
                    49.6844992
                 ],
                 [
                    89.53666769999995,
                    49.68077949999998
                 ],
                 [
                    89.52797380000004,
                    49.6794197
                 ],
                 [
                    89.5205,
                    49.67913330000002
                 ],
                 [
                    89.5163554,
                    49.677487
                 ],
                 [
                    89.51311370000002,
                    49.67515029999998
                 ],
                 [
                    89.51204480000004,
                    49.67358470000003
                 ],
                 [
                    89.512802,
                    49.67235150000004
                 ],
                 [
                    89.512334,
                    49.67124660000002
                 ],
                 [
                    89.50925639999998,
                    49.670264
                 ],
                 [
                    89.50683530000003,
                    49.66989220000004
                 ],
                 [
                    89.50445530000002,
                    49.67031710000003
                 ],
                 [
                    89.5033063,
                    49.66981250000002
                 ],
                 [
                    89.5010158,
                    49.66641410000003
                 ],
                 [
                    89.49656330000002,
                    49.66504130000004
                 ],
                 [
                    89.48433770000003,
                    49.66339780000004
                 ],
                 [
                    89.47736929999996,
                    49.661836199999975
                 ],
                 [
                    89.47154809999998,
                    49.661231
                 ],
                 [
                    89.46699779999999,
                    49.6626996
                 ],
                 [
                    89.46360180000002,
                    49.6657253
                 ],
                 [
                    89.4615641,
                    49.666216600000034
                 ],
                 [
                    89.45888520000003,
                    49.66504469999997
                 ],
                 [
                    89.4560725,
                    49.662342700000046
                 ],
                 [
                    89.45158470000004,
                    49.6566594
                 ],
                 [
                    89.44841450000001,
                    49.65084929999996
                 ],
                 [
                    89.44422559999998,
                    49.644613599999964
                 ],
                 [
                    89.44258260000002,
                    49.643216700000025
                 ],
                 [
                    89.43919240000001,
                    49.64034960000003
                 ],
                 [
                    89.43580390000004,
                    49.638225400000046
                 ],
                 [
                    89.43514729999998,
                    49.6370827
                 ],
                 [
                    89.4362553,
                    49.6356742
                 ],
                 [
                    89.43822990000004,
                    49.63483640000003
                 ],
                 [
                    89.437704,
                    49.63212030000001
                 ],
                 [
                    89.43777509999998,
                    49.62869779999998
                 ],
                 [
                    89.4345539,
                    49.6253885
                 ],
                 [
                    89.43206559999999,
                    49.624410099999956
                 ],
                 [
                    89.42965019999998,
                    49.62282330000003
                 ],
                 [
                    89.4273522,
                    49.619380600000014
                 ],
                 [
                    89.42484910000002,
                    49.61754620000003
                 ],
                 [
                    89.42271530000004,
                    49.6174665
                 ],
                 [
                    89.42026230000002,
                    49.61825560000001
                 ],
                 [
                    89.4156192,
                    49.6209014
                 ],
                 [
                    89.41102029999998,
                    49.622437800000014
                 ],
                 [
                    89.40749130000002,
                    49.622172
                 ],
                 [
                    89.40526879999997,
                    49.62112740000004
                 ],
                 [
                    89.40118710000003,
                    49.61734110000002
                 ],
                 [
                    89.3932,
                    49.6125
                 ],
                 [
                    89.3846,
                    49.6062
                 ],
                 [
                    89.391,
                    49.5953
                 ],
                 [
                    89.3854,
                    49.5938
                 ],
                 [
                    89.377,
                    49.592
                 ],
                 [
                    89.3685,
                    49.5886
                 ],
                 [
                    89.36,
                    49.5844
                 ],
                 [
                    89.3475,
                    49.5826
                 ],
                 [
                    89.3431,
                    49.5828
                 ],
                 [
                    89.345,
                    49.5918
                 ],
                 [
                    89.3342,
                    49.5974
                 ],
                 [
                    89.3178,
                    49.6022
                 ],
                 [
                    89.3124,
                    49.6043
                 ],
                 [
                    89.307,
                    49.6078
                 ],
                 [
                    89.2989,
                    49.6114
                 ],
                 [
                    89.2869,
                    49.6217
                 ],
                 [
                    89.2709,
                    49.6329
                 ],
                 [
                    89.2506,
                    49.6423
                 ],
                 [
                    89.241,
                    49.6452
                 ],
                 [
                    89.2341,
                    49.6444
                 ],
                 [
                    89.2257,
                    49.6428
                 ],
                 [
                    89.2188,
                    49.642
                 ],
                 [
                    89.2098,
                    49.6386
                 ],
                 [
                    89.2058,
                    49.6367
                 ],
                 [
                    89.1936369,
                    49.634683399999986
                 ],
                 [
                    89.18882720000003,
                    49.632478399999975
                 ],
                 [
                    89.18549419999995,
                    49.6276836
                 ],
                 [
                    89.18239810000001,
                    49.622897500000036
                 ],
                 [
                    89.18244980000003,
                    49.6194319
                 ],
                 [
                    89.19195989999997,
                    49.61050479999997
                 ],
                 [
                    89.1996311,
                    49.60432670000003
                 ],
                 [
                    89.1988,
                    49.5991
                 ],
                 [
                    89.19515989999998,
                    49.5967178
                 ],
                 [
                    89.19269770000001,
                    49.5906442
                 ],
                 [
                    89.1902833,
                    49.588745
                 ],
                 [
                    89.19146890000003,
                    49.58601990000002
                 ],
                 [
                    89.19919650000001,
                    49.582289199999984
                 ],
                 [
                    89.2115,
                    49.5729
                 ],
                 [
                    89.2182,
                    49.57
                 ],
                 [
                    89.2208,
                    49.5655
                 ],
                 [
                    89.2301,
                    49.558
                 ],
                 [
                    89.2327,
                    49.5537
                 ],
                 [
                    89.2297,
                    49.5481
                 ],
                 [
                    89.2254,
                    49.5448
                 ],
                 [
                    89.2197,
                    49.5422
                 ],
                 [
                    89.2154,
                    49.5382
                 ],
                 [
                    89.2068,
                    49.5307
                 ],
                 [
                    89.2038,
                    49.5254
                 ],
                 [
                    89.2009,
                    49.5228
                 ],
                 [
                    89.1953,
                    49.5202
                 ],
                 [
                    89.1911,
                    49.5194
                 ],
                 [
                    89.1745,
                    49.5188
                 ],
                 [
                    89.1621,
                    49.5174
                 ],
                 [
                    89.1576,
                    49.5153
                 ],
                 [
                    89.1504,
                    49.5077
                 ],
                 [
                    89.1476,
                    49.5021
                 ],
                 [
                    89.1323,
                    49.4989
                 ],
                 [
                    89.1224,
                    49.4991
                 ],
                 [
                    89.114,
                    49.4964
                 ],
                 [
                    89.1056,
                    49.4931
                 ],
                 [
                    89.0944,
                    49.4887
                 ],
                 [
                    89.0846,
                    49.4861
                 ],
                 [
                    89.0749,
                    49.4845
                 ],
                 [
                    89.0639,
                    49.4847
                 ],
                 [
                    89.035,
                    49.4861
                 ],
                 [
                    89.025,
                    49.4773
                 ],
                 [
                    89.0179,
                    49.4721
                 ],
                 [
                    89.0136,
                    49.4696
                 ],
                 [
                    89.0027,
                    49.4705
                 ],
                 [
                    88.9845,
                    49.4637
                 ],
                 [
                    88.9708,
                    49.4657
                 ],
                 [
                    88.9655,
                    49.4712
                 ],
                 [
                    88.96,
                    49.4776
                 ],
                 [
                    88.959,
                    49.4876
                 ],
                 [
                    88.962,
                    49.4946
                 ],
                 [
                    88.9595,
                    49.5
                 ],
                 [
                    88.9514,
                    49.5046
                 ],
                 [
                    88.9379,
                    49.5112
                 ],
                 [
                    88.9326,
                    49.5168
                 ],
                 [
                    88.9302,
                    49.5267
                 ],
                 [
                    88.9319,
                    49.5348
                 ],
                 [
                    88.9295,
                    49.5429
                 ],
                 [
                    88.9254,
                    49.5447
                 ],
                 [
                    88.9131,
                    49.5466
                 ],
                 [
                    88.9047751,
                    49.55383179999997
                 ],
                 [
                    88.90052130000002,
                    49.55690350000001
                 ],
                 [
                    88.90009039999998,
                    49.558961
                 ],
                 [
                    88.87293429999998,
                    49.548723099999954
                 ],
                 [
                    88.86961489999996,
                    49.54674059999997
                 ],
                 [
                    88.87462489999999,
                    49.54447410000002
                 ],
                 [
                    88.87767469999999,
                    49.54092090000002
                 ],
                 [
                    88.87741239999995,
                    49.53315069999997
                 ],
                 [
                    88.87537160000004,
                    49.53019510000003
                 ],
                 [
                    88.87989810000002,
                    49.52205869999997
                 ],
                 [
                    88.87967510000004,
                    49.51376649999999
                 ],
                 [
                    88.8759787,
                    49.50857580000003
                 ],
                 [
                    88.876068,
                    49.50495270000001
                 ],
                 [
                    88.8749074,
                    49.5014101
                 ],
                 [
                    88.8657,
                    49.4992
                 ],
                 [
                    88.8587,
                    49.4963
                 ],
                 [
                    88.8573,
                    49.4947
                 ],
                 [
                    88.8571,
                    49.4918
                 ],
                 [
                    88.8653,
                    49.489
                 ],
                 [
                    88.8774,
                    49.4807
                 ],
                 [
                    88.8801,
                    49.4781
                 ],
                 [
                    88.8786,
                    49.4755
                 ],
                 [
                    88.8686,
                    49.4674
                 ],
                 [
                    88.8659,
                    49.4674
                 ],
                 [
                    88.8631,
                    49.4664
                 ],
                 [
                    88.8534,
                    49.4667
                 ],
                 [
                    88.8628,
                    49.4595
                 ],
                 [
                    88.8514,
                    49.4488
                 ],
                 [
                    88.8471,
                    49.4436
                 ],
                 [
                    88.8429,
                    49.4416
                 ],
                 [
                    88.8319,
                    49.4446
                 ],
                 [
                    88.8185,
                    49.4529
                 ],
                 [
                    88.8172,
                    49.4547
                 ],
                 [
                    88.8143,
                    49.4584
                 ],
                 [
                    88.8022,
                    49.4604
                 ],
                 [
                    88.7897,
                    49.4577
                 ],
                 [
                    88.7799,
                    49.4589
                 ],
                 [
                    88.7691,
                    49.4601
                 ],
                 [
                    88.7496,
                    49.4603
                 ],
                 [
                    88.744,
                    49.4568
                 ],
                 [
                    88.7383,
                    49.4515
                 ],
                 [
                    88.7328,
                    49.4515
                 ],
                 [
                    88.7233,
                    49.4544
                 ],
                 [
                    88.7,
                    49.4601
                 ],
                 [
                    88.6932,
                    49.4613
                 ],
                 [
                    88.6836,
                    49.4622
                 ],
                 [
                    88.674,
                    49.4659
                 ],
                 [
                    88.6687,
                    49.4715
                 ],
                 [
                    88.6634,
                    49.4759
                 ],
                 [
                    88.6607,
                    49.4786
                 ],
                 [
                    88.6323,
                    49.4954
                 ],
                 [
                    88.6241,
                    49.4962
                 ],
                 [
                    88.6103,
                    49.4973
                 ],
                 [
                    88.5992,
                    49.5009
                 ],
                 [
                    88.5909,
                    49.5012
                 ],
                 [
                    88.5729,
                    49.4978
                 ],
                 [
                    88.5673,
                    49.4961
                 ],
                 [
                    88.5561,
                    49.4909
                 ],
                 [
                    88.5491,
                    49.4874
                 ],
                 [
                    88.5449,
                    49.4856
                 ],
                 [
                    88.538,
                    49.4856
                 ],
                 [
                    88.5312,
                    49.4867
                 ],
                 [
                    88.5242,
                    49.4858
                 ],
                 [
                    88.5173,
                    49.4841
                 ],
                 [
                    88.5117,
                    49.4816
                 ],
                 [
                    88.4977,
                    49.4737
                 ],
                 [
                    88.4949,
                    49.4719
                 ],
                 [
                    88.4756,
                    49.4713
                 ],
                 [
                    88.445,
                    49.4726
                 ],
                 [
                    88.4357,
                    49.4764
                 ],
                 [
                    88.4153,
                    49.4909
                 ],
                 [
                    88.4112,
                    49.4936
                 ],
                 [
                    88.4059,
                    49.4946
                 ],
                 [
                    88.396,
                    49.4928
                 ],
                 [
                    88.3918,
                    49.4901
                 ],
                 [
                    88.389,
                    49.4875
                 ],
                 [
                    88.3765,
                    49.486
                 ],
                 [
                    88.3709,
                    49.4842
                 ],
                 [
                    88.36800430000002,
                    49.4838463
                 ],
                 [
                    88.36641440000002,
                    49.4838903
                 ],
                 [
                    88.36566789999998,
                    49.48407090000002
                 ],
                 [
                    88.362459,
                    49.48571010000002
                 ],
                 [
                    88.3605296,
                    49.487388299999964
                 ],
                 [
                    88.36032070000005,
                    49.4882937
                 ],
                 [
                    88.34802180000001,
                    49.491382599999966
                 ],
                 [
                    88.33821439999998,
                    49.493647699999975
                 ],
                 [
                    88.3312,
                    49.4935
                 ],
                 [
                    88.325838,
                    49.48489
                 ],
                 [
                    88.324925,
                    49.48449
                 ],
                 [
                    88.323668,
                    49.484204
                 ],
                 [
                    88.322697,
                    49.483805
                 ],
                 [
                    88.321898,
                    49.483062
                 ],
                 [
                    88.32127,
                    49.482206
                 ],
                 [
                    88.320185,
                    49.479122
                 ],
                 [
                    88.318929,
                    49.476152
                 ],
                 [
                    88.318472,
                    49.475524
                 ],
                 [
                    88.3179,
                    49.475124
                 ],
                 [
                    88.317101,
                    49.474896
                 ],
                 [
                    88.313217,
                    49.474667
                 ],
                 [
                    88.311162,
                    49.474382
                 ],
                 [
                    88.308935,
                    49.474154
                 ],
                 [
                    88.303452,
                    49.472612
                 ],
                 [
                    88.301111,
                    49.471698
                 ],
                 [
                    88.298827,
                    49.47107
                 ],
                 [
                    88.297742,
                    49.470898
                 ],
                 [
                    88.296485,
                    49.470898
                 ],
                 [
                    88.2954,
                    49.471184
                 ],
                 [
                    88.294658,
                    49.471926
                 ],
                 [
                    88.293344,
                    49.472726
                 ],
                 [
                    88.291403,
                    49.473754
                 ],
                 [
                    88.287576,
                    49.475524
                 ],
                 [
                    88.285578,
                    49.475981
                 ],
                 [
                    88.283122,
                    49.476438
                 ],
                 [
                    88.280895,
                    49.476666
                 ],
                 [
                    88.278497,
                    49.47678
                 ],
                 [
                    88.274328,
                    49.478208
                 ],
                 [
                    88.272215,
                    49.478665
                 ],
                 [
                    88.266218,
                    49.479522
                 ],
                 [
                    88.265476,
                    49.480207
                 ],
                 [
                    88.265305,
                    49.481235
                 ],
                 [
                    88.264734,
                    49.48192
                 ],
                 [
                    88.263991,
                    49.482263
                 ],
                 [
                    88.263249,
                    49.482377
                 ],
                 [
                    88.261878,
                    49.481806
                 ],
                 [
                    88.259651,
                    49.481121
                 ],
                 [
                    88.258509,
                    49.480721
                 ],
                 [
                    88.257253,
                    49.480835
                 ],
                 [
                    88.256282,
                    49.481178
                 ],
                 [
                    88.255482,
                    49.481121
                 ],
                 [
                    88.254169,
                    49.480664
                 ],
                 [
                    88.252627,
                    49.47975
                 ],
                 [
                    88.251256,
                    49.479065
                 ],
                 [
                    88.250685,
                    49.478722
                 ],
                 [
                    88.249943,
                    49.479065
                 ],
                 [
                    88.248625,
                    49.479888
                 ],
                 [
                    88.248054,
                    49.480402
                 ],
                 [
                    88.246912,
                    49.480573
                 ],
                 [
                    88.246169,
                    49.480516
                 ],
                 [
                    88.244799,
                    49.480116
                 ],
                 [
                    88.243885,
                    49.479431
                 ],
                 [
                    88.243599,
                    49.479031
                 ],
                 [
                    88.243371,
                    49.478403
                 ],
                 [
                    88.243086,
                    49.477889
                 ],
                 [
                    88.242743,
                    49.477489
                 ],
                 [
                    88.242,
                    49.476804
                 ],
                 [
                    88.241258,
                    49.47629
                 ],
                 [
                    88.238688,
                    49.474805
                 ],
                 [
                    88.237432,
                    49.474634
                 ],
                 [
                    88.235947,
                    49.474691
                 ],
                 [
                    88.234805,
                    49.474863
                 ],
                 [
                    88.233891,
                    49.475548
                 ],
                 [
                    88.233492,
                    49.476062
                 ],
                 [
                    88.232977,
                    49.477261
                 ],
                 [
                    88.232578,
                    49.477775
                 ],
                 [
                    88.231892,
                    49.478175
                 ],
                 [
                    88.230979,
                    49.478175
                 ],
                 [
                    88.228637,
                    49.47846
                 ],
                 [
                    88.226296,
                    49.478689
                 ],
                 [
                    88.224126,
                    49.479088
                 ],
                 [
                    88.221784,
                    49.47966
                 ],
                 [
                    88.219329,
                    49.47669
                 ],
                 [
                    88.218072,
                    49.475434
                 ],
                 [
                    88.216588,
                    49.474177
                 ],
                 [
                    88.214932,
                    49.473378
                 ],
                 [
                    88.212819,
                    49.47275
                 ],
                 [
                    88.211505,
                    49.471436
                 ],
                 [
                    88.208992,
                    49.470808
                 ],
                 [
                    88.207622,
                    49.470637
                 ],
                 [
                    88.206822,
                    49.470237
                 ],
                 [
                    88.203339,
                    49.468067
                 ],
                 [
                    88.201512,
                    49.467096
                 ],
                 [
                    88.199627,
                    49.466182
                 ],
                 [
                    88.196029,
                    49.463955
                 ],
                 [
                    88.192831,
                    49.461328
                 ],
                 [
                    88.191746,
                    49.459444
                 ],
                 [
                    88.191004,
                    49.458359
                 ],
                 [
                    88.189462,
                    49.456874
                 ],
                 [
                    88.188663,
                    49.45636
                 ],
                 [
                    88.187634,
                    49.455846
                 ],
                 [
                    88.186607,
                    49.455789
                 ],
                 [
                    88.185464,
                    49.455617
                 ],
                 [
                    88.184494,
                    49.455332
                 ],
                 [
                    88.183694,
                    49.454875
                 ],
                 [
                    88.182095,
                    49.4540495
                 ],
                 [
                    88.180782,
                    49.454018
                 ],
                 [
                    88.178554,
                    49.454475
                 ],
                 [
                    88.176384,
                    49.454475
                 ],
                 [
                    88.175128,
                    49.454076
                 ],
                 [
                    88.173757,
                    49.453847
                 ],
                 [
                    88.172101,
                    49.452705
                 ],
                 [
                    88.171473,
                    49.45202
                 ],
                 [
                    88.171016,
                    49.451277
                 ],
                 [
                    88.170331,
                    49.44945
                 ],
                 [
                    88.16976,
                    49.448708
                 ],
                 [
                    88.169132,
                    49.448079
                 ],
                 [
                    88.168275,
                    49.447394
                 ],
                 [
                    88.166448,
                    49.446138
                 ],
                 [
                    88.165591,
                    49.445452
                 ],
                 [
                    88.165991,
                    49.444539
                 ],
                 [
                    88.166391,
                    49.443397
                 ],
                 [
                    88.166105,
                    49.440084
                 ],
                 [
                    88.166334,
                    49.439513
                 ],
                 [
                    88.16759,
                    49.439685
                 ],
                 [
                    88.16896,
                    49.440027
                 ],
                 [
                    88.170274,
                    49.440084
                 ],
                 [
                    88.171759,
                    49.43997
                 ],
                 [
                    88.172672,
                    49.439742
                 ],
                 [
                    88.173929,
                    49.439285
                 ],
                 [
                    88.174785,
                    49.438885
                 ],
                 [
                    88.175756,
                    49.438314
                 ],
                 [
                    88.176384,
                    49.436715
                 ],
                 [
                    88.177013,
                    49.436372
                 ],
                 [
                    88.178212,
                    49.435402
                 ],
                 [
                    88.178897,
                    49.433974
                 ],
                 [
                    88.178783,
                    49.432489
                 ],
                 [
                    88.178269,
                    49.42952
                 ],
                 [
                    88.178212,
                    49.426607
                 ],
                 [
                    88.178669,
                    49.425065
                 ],
                 [
                    88.179011,
                    49.424152
                 ],
                 [
                    88.179411,
                    49.423409
                 ],
                 [
                    88.179811,
                    49.422781
                 ],
                 [
                    88.180496,
                    49.422096
                 ],
                 [
                    88.182038,
                    49.420896
                 ],
                 [
                    88.183866,
                    49.419754
                 ],
                 [
                    88.184779,
                    49.419012
                 ],
                 [
                    88.185179,
                    49.418612
                 ],
                 [
                    88.185636,
                    49.417755
                 ],
                 [
                    88.185579,
                    49.417127
                 ],
                 [
                    88.185293,
                    49.416785
                 ],
                 [
                    88.184379,
                    49.415928
                 ],
                 [
                    88.183351,
                    49.415471
                 ],
                 [
                    88.182552,
                    49.4149
                 ],
                 [
                    88.180382,
                    49.413929
                 ],
                 [
                    88.17884,
                    49.413758
                 ],
                 [
                    88.173986,
                    49.413758
                 ],
                 [
                    88.172787,
                    49.413986
                 ],
                 [
                    88.171587,
                    49.414044
                 ],
                 [
                    88.170731,
                    49.413358
                 ],
                 [
                    88.169531,
                    49.411931
                 ],
                 [
                    88.168389,
                    49.409875
                 ],
                 [
                    88.167761,
                    49.409475
                 ],
                 [
                    88.166448,
                    49.408961
                 ],
                 [
                    88.165477,
                    49.408504
                 ],
                 [
                    88.161993,
                    49.408219
                 ],
                 [
                    88.159424,
                    49.408161
                 ],
                 [
                    88.157425,
                    49.407876
                 ],
                 [
                    88.155255,
                    49.407476
                 ],
                 [
                    88.153884,
                    49.407362
                 ],
                 [
                    88.15297,
                    49.407019
                 ],
                 [
                    88.151314,
                    49.405934
                 ],
                 [
                    88.149658,
                    49.404964
                 ],
                 [
                    88.147888,
                    49.40405
                 ],
                 [
                    88.144633,
                    49.402108
                 ],
                 [
                    88.144062,
                    49.401594
                 ],
                 [
                    88.143719,
                    49.401423
                 ],
                 [
                    88.142805,
                    49.401708
                 ],
                 [
                    88.140635,
                    49.402108
                 ],
                 [
                    88.139322,
                    49.400795
                 ],
                 [
                    88.138465,
                    49.400109
                 ],
                 [
                    88.138008,
                    49.399481
                 ],
                 [
                    88.135781,
                    49.398739
                 ],
                 [
                    88.134582,
                    49.398396
                 ],
                 [
                    88.133954,
                    49.397939
                 ],
                 [
                    88.131327,
                    49.396455
                 ],
                 [
                    88.130242,
                    49.395998
                 ],
                 [
                    88.129214,
                    49.395655
                 ],
                 [
                    88.128414,
                    49.395541
                 ],
                 [
                    88.127958,
                    49.39537
                 ],
                 [
                    88.127444,
                    49.395084
                 ],
                 [
                    88.126701,
                    49.394342
                 ],
                 [
                    88.125902,
                    49.393713
                 ],
                 [
                    88.123846,
                    49.392857
                 ],
                 [
                    88.121847,
                    49.392172
                 ],
                 [
                    88.11962,
                    49.391658
                 ],
                 [
                    88.119106,
                    49.391315
                 ],
                 [
                    88.118763,
                    49.390801
                 ],
                 [
                    88.118878,
                    49.39023
                 ],
                 [
                    88.11962,
                    49.388574
                 ],
                 [
                    88.119849,
                    49.387831
                 ],
                 [
                    88.119677,
                    49.386975
                 ],
                 [
                    88.118478,
                    49.385661
                 ],
                 [
                    88.11785,
                    49.384748
                 ],
                 [
                    88.116993,
                    49.383377
                 ],
                 [
                    88.116708,
                    49.382578
                 ],
                 [
                    88.117107,
                    49.379437
                 ],
                 [
                    88.117564,
                    49.378866
                 ],
                 [
                    88.118021,
                    49.378523
                 ],
                 [
                    88.120705,
                    49.378009
                 ],
                 [
                    88.12219,
                    49.377952
                 ],
                 [
                    88.124189,
                    49.377495
                 ],
                 [
                    88.123332,
                    49.377095
                 ],
                 [
                    88.122418,
                    49.37641
                 ],
                 [
                    88.122247,
                    49.375782
                 ],
                 [
                    88.12219,
                    49.375154
                 ],
                 [
                    88.12259,
                    49.374354
                 ],
                 [
                    88.123046,
                    49.37384
                 ],
                 [
                    88.124131,
                    49.372926
                 ],
                 [
                    88.125673,
                    49.372127
                 ],
                 [
                    88.127615,
                    49.371499
                 ],
                 [
                    88.129614,
                    49.370928
                 ],
                 [
                    88.132012,
                    49.370642
                 ],
                 [
                    88.134354,
                    49.370642
                 ],
                 [
                    88.13641,
                    49.370528
                 ],
                 [
                    88.138351,
                    49.370585
                 ],
                 [
                    88.138751,
                    49.369786
                 ],
                 [
                    88.140236,
                    49.368415
                 ],
                 [
                    88.141149,
                    49.367958
                 ],
                 [
                    88.143034,
                    49.367273
                 ],
                 [
                    88.145147,
                    49.366645
                 ],
                 [
                    88.147317,
                    49.366245
                 ],
                 [
                    88.149316,
                    49.365388
                 ],
                 [
                    88.151143,
                    49.364532
                 ],
                 [
                    88.152,
                    49.364246
                 ],
                 [
                    88.153256,
                    49.364189
                 ],
                 [
                    88.154684,
                    49.36436
                 ],
                 [
                    88.155654,
                    49.364189
                 ],
                 [
                    88.157025,
                    49.363846
                 ],
                 [
                    88.160451,
                    49.362761
                 ],
                 [
                    88.160394,
                    49.361048
                 ],
                 [
                    88.160223,
                    49.359621
                 ],
                 [
                    88.159709,
                    49.356765
                 ],
                 [
                    88.159937,
                    49.355909
                 ],
                 [
                    88.162793,
                    49.35431
                 ],
                 [
                    88.163935,
                    49.353567
                 ],
                 [
                    88.165248,
                    49.352539
                 ],
                 [
                    88.166733,
                    49.350483
                 ],
                 [
                    88.166619,
                    49.349513
                 ],
                 [
                    88.166162,
                    49.347628
                 ],
                 [
                    88.164678,
                    49.344887
                 ],
                 [
                    88.16462,
                    49.34403
                 ],
                 [
                    88.164849,
                    49.343288
                 ],
                 [
                    88.165306,
                    49.342317
                 ],
                 [
                    88.165648,
                    49.341289
                 ],
                 [
                    88.165591,
                    49.340375
                 ],
                 [
                    88.163935,
                    49.340375
                 ],
                 [
                    88.160908,
                    49.340033
                 ],
                 [
                    88.15811,
                    49.339176
                 ],
                 [
                    88.155769,
                    49.338377
                 ],
                 [
                    88.155026,
                    49.338034
                 ],
                 [
                    88.154398,
                    49.337235
                 ],
                 [
                    88.154569,
                    49.336721
                 ],
                 [
                    88.156454,
                    49.335464
                 ],
                 [
                    88.158567,
                    49.334493
                 ],
                 [
                    88.161137,
                    49.333408
                 ],
                 [
                    88.163545,
                    49.332742
                 ],
                 [
                    88.164344,
                    49.332285
                 ],
                 [
                    88.165601,
                    49.331143
                 ],
                 [
                    88.166857,
                    49.329773
                 ],
                 [
                    88.168056,
                    49.328573
                 ],
                 [
                    88.16937,
                    49.327031
                 ],
                 [
                    88.169598,
                    49.326175
                 ],
                 [
                    88.169998,
                    49.32549
                 ],
                 [
                    88.169998,
                    49.324633
                 ],
                 [
                    88.169769,
                    49.323891
                 ],
                 [
                    88.16897,
                    49.322406
                 ],
                 [
                    88.168056,
                    49.321092
                 ],
                 [
                    88.1672,
                    49.319722
                 ],
                 [
                    88.166001,
                    49.318408
                 ],
                 [
                    88.165144,
                    49.317894
                 ],
                 [
                    88.164173,
                    49.317495
                 ],
                 [
                    88.162917,
                    49.317266
                 ],
                 [
                    88.161546,
                    49.317209
                 ],
                 [
                    88.160404,
                    49.317038
                 ],
                 [
                    88.159148,
                    49.317095
                 ],
                 [
                    88.158462,
                    49.316809
                 ],
                 [
                    88.15812,
                    49.316467
                 ],
                 [
                    88.158291,
                    49.31601
                 ],
                 [
                    88.15989,
                    49.314925
                 ],
                 [
                    88.162688,
                    49.312241
                 ],
                 [
                    88.164972,
                    49.309271
                 ],
                 [
                    88.166001,
                    49.307558
                 ],
                 [
                    88.166172,
                    49.307044
                 ],
                 [
                    88.166229,
                    49.306416
                 ],
                 [
                    88.166115,
                    49.306016
                 ],
                 [
                    88.165429,
                    49.304645
                 ],
                 [
                    88.163488,
                    49.301847
                 ],
                 [
                    88.16286,
                    49.300477
                 ],
                 [
                    88.16246,
                    49.298992
                 ],
                 [
                    88.164459,
                    49.298135
                 ],
                 [
                    88.168456,
                    49.296765
                 ],
                 [
                    88.169484,
                    49.296251
                 ],
                 [
                    88.170112,
                    49.295794
                 ],
                 [
                    88.170341,
                    49.294081
                 ],
                 [
                    88.170112,
                    49.293567
                 ],
                 [
                    88.169084,
                    49.292482
                 ],
                 [
                    88.168456,
                    49.292196
                 ],
                 [
                    88.167028,
                    49.291739
                 ],
                 [
                    88.164059,
                    49.291568
                 ],
                 [
                    88.162802,
                    49.290997
                 ],
                 [
                    88.162574,
                    49.290083
                 ],
                 [
                    88.162117,
                    49.289398
                 ],
                 [
                    88.161432,
                    49.288827
                 ],
                 [
                    88.160461,
                    49.288427
                 ],
                 [
                    88.15595,
                    49.287228
                 ],
                 [
                    88.154008,
                    49.286485
                 ],
                 [
                    88.152295,
                    49.285286
                 ],
                 [
                    88.151267,
                    49.283916
                 ],
                 [
                    88.150125,
                    49.282602
                 ],
                 [
                    88.149382,
                    49.281917
                 ],
                 [
                    88.148469,
                    49.28146
                 ],
                 [
                    88.143614,
                    49.280318
                 ],
                 [
                    88.141501,
                    49.280032
                 ],
                 [
                    88.140074,
                    49.280089
                 ],
                 [
                    88.139389,
                    49.280261
                 ],
                 [
                    88.137732,
                    49.28106
                 ],
                 [
                    88.137219,
                    49.280946
                 ],
                 [
                    88.13619,
                    49.279804
                 ],
                 [
                    88.135619,
                    49.279461
                 ],
                 [
                    88.133164,
                    49.279176
                 ],
                 [
                    88.131793,
                    49.279575
                 ],
                 [
                    88.12968,
                    49.279575
                 ],
                 [
                    88.128424,
                    49.27929
                 ],
                 [
                    88.126311,
                    49.278605
                 ],
                 [
                    88.125283,
                    49.277919
                 ],
                 [
                    88.124769,
                    49.277234
                 ],
                 [
                    88.12357,
                    49.275121
                 ],
                 [
                    88.12357,
                    49.274207
                 ],
                 [
                    88.123227,
                    49.272551
                 ],
                 [
                    88.121628,
                    49.271124
                 ],
                 [
                    88.120086,
                    49.270039
                 ],
                 [
                    88.117516,
                    49.268097
                 ],
                 [
                    88.11706,
                    49.267811
                 ],
                 [
                    88.116431,
                    49.267069
                 ],
                 [
                    88.116203,
                    49.266212
                 ],
                 [
                    88.11586,
                    49.26547
                 ],
                 [
                    88.11546,
                    49.264842
                 ],
                 [
                    88.11232,
                    49.262329
                 ],
                 [
                    88.110835,
                    49.261301
                 ],
                 [
                    88.109864,
                    49.259988
                 ],
                 [
                    88.109236,
                    49.258846
                 ],
                 [
                    88.108094,
                    49.257932
                 ],
                 [
                    88.107808,
                    49.257304
                 ],
                 [
                    88.10670580000004,
                    49.2565686
                 ],
                 [
                    88.10421,
                    49.255876
                 ],
                 [
                    88.103069,
                    49.255705
                 ],
                 [
                    88.101926,
                    49.255305
                 ],
                 [
                    88.100727,
                    49.255134
                 ],
                 [
                    88.099756,
                    49.255134
                 ],
                 [
                    88.098328,
                    49.255362
                 ],
                 [
                    88.097301,
                    49.255591
                 ],
                 [
                    88.095188,
                    49.255819
                 ],
                 [
                    88.092903,
                    49.255591
                 ],
                 [
                    88.090962,
                    49.254962
                 ],
                 [
                    88.088563,
                    49.254563
                 ],
                 [
                    88.087078,
                    49.254734
                 ],
                 [
                    88.084851,
                    49.255248
                 ],
                 [
                    88.083995,
                    49.255705
                 ],
                 [
                    88.081482,
                    49.258503
                 ],
                 [
                    88.081139,
                    49.259531
                 ],
                 [
                    88.081596,
                    49.260673
                 ],
                 [
                    88.080225,
                    49.260502
                 ],
                 [
                    88.076856,
                    49.259759
                 ],
                 [
                    88.074686,
                    49.259417
                 ],
                 [
                    88.072402,
                    49.25936
                 ],
                 [
                    88.070232,
                    49.259474
                 ],
                 [
                    88.06789,
                    49.259645
                 ],
                 [
                    88.065663,
                    49.259874
                 ],
                 [
                    88.064464,
                    49.260045
                 ],
                 [
                    88.063322,
                    49.260045
                 ],
                 [
                    88.061095,
                    49.259588
                 ],
                 [
                    88.058811,
                    49.259417
                 ],
                 [
                    88.056583,
                    49.258788
                 ],
                 [
                    88.054756,
                    49.258389
                 ],
                 [
                    88.053328,
                    49.257247
                 ],
                 [
                    88.052586,
                    49.256847
                 ],
                 [
                    88.0519,
                    49.256104
                 ],
                 [
                    88.052414,
                    49.253592
                 ],
                 [
                    88.0523,
                    49.252164
                 ],
                 [
                    88.0531,
                    49.251193
                 ],
                 [
                    88.05447,
                    49.250908
                 ],
                 [
                    88.055099,
                    49.250451
                 ],
                 [
                    88.056012,
                    49.248966
                 ],
                 [
                    88.05664,
                    49.24731
                 ],
                 [
                    88.057554,
                    49.244283
                 ],
                 [
                    88.057497,
                    49.242741
                 ],
                 [
                    88.057211,
                    49.241999
                 ],
                 [
                    88.056412,
                    49.241085
                 ],
                 [
                    88.055612,
                    49.240229
                 ],
                 [
                    88.05487,
                    49.239772
                 ],
                 [
                    88.052871,
                    49.238972
                 ],
                 [
                    88.048131,
                    49.237944
                 ],
                 [
                    88.04579,
                    49.237259
                 ],
                 [
                    88.04145,
                    49.235831
                 ],
                 [
                    88.039223,
                    49.234975
                 ],
                 [
                    88.03711,
                    49.234347
                 ],
                 [
                    88.034654,
                    49.233718
                 ],
                 [
                    88.03277,
                    49.23309
                 ],
                 [
                    88.0302,
                    49.232462
                 ],
                 [
                    88.028258,
                    49.23172
                 ],
                 [
                    88.026659,
                    49.230806
                 ],
                 [
                    88.024832,
                    49.229607
                 ],
                 [
                    88.02249,
                    49.228921
                 ],
                 [
                    88.02072,
                    49.228065
                 ],
                 [
                    88.018835,
                    49.22698
                 ],
                 [
                    88.017979,
                    49.226295
                 ],
                 [
                    88.017579,
                    49.225438
                 ],
                 [
                    88.017351,
                    49.224753
                 ],
                 [
                    88.017408,
                    49.223782
                 ],
                 [
                    88.017693,
                    49.222811
                 ],
                 [
                    88.018436,
                    49.222012
                 ],
                 [
                    88.019349,
                    49.221269
                 ],
                 [
                    88.019864,
                    49.220641
                 ],
                 [
                    88.017237,
                    49.219841
                 ],
                 [
                    88.014267,
                    49.218814
                 ],
                 [
                    88.012611,
                    49.217557
                 ],
                 [
                    88.01164,
                    49.216187
                 ],
                 [
                    88.011411,
                    49.215387
                 ],
                 [
                    88.011355,
                    49.214359
                 ],
                 [
                    88.011012,
                    49.213788
                 ],
                 [
                    88.010098,
                    49.21276
                 ],
                 [
                    88.008099,
                    49.21139
                 ],
                 [
                    88.006786,
                    49.209905
                 ],
                 [
                    88.006101,
                    49.209391
                 ],
                 [
                    88.00473,
                    49.209048
                 ],
                 [
                    88.003131,
                    49.209162
                 ],
                 [
                    88.002046,
                    49.209162
                 ],
                 [
                    88.000847,
                    49.208763
                 ],
                 [
                    88.000276,
                    49.208077
                 ],
                 [
                    88.000047,
                    49.207278
                 ],
                 [
                    88.000219,
                    49.205051
                 ],
                 [
                    88.000447,
                    49.204308
                 ],
                 [
                    88.000904,
                    49.203338
                 ],
                 [
                    88.003017,
                    49.200025
                 ],
                 [
                    88.00216,
                    49.199112
                 ],
                 [
                    88.001761,
                    49.198426
                 ],
                 [
                    88.001189,
                    49.197741
                 ],
                 [
                    88.000618,
                    49.19717
                 ],
                 [
                    87.99999,
                    49.196313
                 ],
                 [
                    87.998832,
                    49.195526
                 ],
                 [
                    87.998203,
                    49.195012
                 ],
                 [
                    87.996947,
                    49.193699
                 ],
                 [
                    87.996376,
                    49.192956
                 ],
                 [
                    87.99552,
                    49.191643
                 ],
                 [
                    87.993121,
                    49.18913
                 ],
                 [
                    87.991693,
                    49.187132
                 ],
                 [
                    87.991465,
                    49.186561
                 ],
                 [
                    87.990837,
                    49.185533
                 ],
                 [
                    87.990094,
                    49.184676
                 ],
                 [
                    87.989809,
                    49.184219
                 ],
                 [
                    87.988438,
                    49.18302
                 ],
                 [
                    87.985926,
                    49.183591
                 ],
                 [
                    87.984269,
                    49.184105
                 ],
                 [
                    87.981243,
                    49.184162
                 ],
                 [
                    87.980329,
                    49.18439
                 ],
                 [
                    87.978159,
                    49.185533
                 ],
                 [
                    87.97656,
                    49.186561
                 ],
                 [
                    87.974333,
                    49.185932
                 ],
                 [
                    87.972106,
                    49.185418
                 ],
                 [
                    87.969993,
                    49.18479
                 ],
                 [
                    87.967765,
                    49.184333
                 ],
                 [
                    87.965424,
                    49.184105
                 ],
                 [
                    87.960912,
                    49.184904
                 ],
                 [
                    87.958857,
                    49.185361
                 ],
                 [
                    87.957144,
                    49.185533
                 ],
                 [
                    87.956573,
                    49.185533
                 ],
                 [
                    87.954459,
                    49.184904
                 ],
                 [
                    87.951833,
                    49.183762
                 ],
                 [
                    87.951319,
                    49.183248
                 ],
                 [
                    87.950519,
                    49.182677
                 ],
                 [
                    87.949948,
                    49.181992
                 ],
                 [
                    87.94932,
                    49.181364
                 ],
                 [
                    87.948235,
                    49.180964
                 ],
                 [
                    87.946179,
                    49.180678
                 ],
                 [
                    87.944237,
                    49.181078
                 ],
                 [
                    87.942296,
                    49.181021
                 ],
                 [
                    87.93767,
                    49.180793
                 ],
                 [
                    87.934301,
                    49.180564
                 ],
                 [
                    87.933159,
                    49.180279
                 ],
                 [
                    87.931959,
                    49.18005
                 ],
                 [
                    87.931331,
                    49.179422
                 ],
                 [
                    87.930874,
                    49.178851
                 ],
                 [
                    87.930646,
                    49.177937
                 ],
                 [
                    87.929732,
                    49.176624
                 ],
                 [
                    87.928761,
                    49.175367
                 ],
                 [
                    87.927448,
                    49.174511
                 ],
                 [
                    87.924421,
                    49.173654
                 ],
                 [
                    87.920481,
                    49.173083
                 ],
                 [
                    87.918654,
                    49.173083
                 ],
                 [
                    87.916483,
                    49.173255
                 ],
                 [
                    87.914256,
                    49.17354
                 ],
                 [
                    87.912029,
                    49.174168
                 ],
                 [
                    87.908374,
                    49.17571
                 ],
                 [
                    87.906261,
                    49.17651
                 ],
                 [
                    87.904948,
                    49.177081
                 ],
                 [
                    87.90392,
                    49.177252
                 ],
                 [
                    87.901521,
                    49.176852
                 ],
                 [
                    87.899408,
                    49.176795
                 ],
                 [
                    87.896953,
                    49.17651
                 ],
                 [
                    87.894897,
                    49.176453
                 ],
                 [
                    87.89364,
                    49.176167
                 ],
                 [
                    87.892841,
                    49.175824
                 ],
                 [
                    87.891471,
                    49.175596
                 ],
                 [
                    87.8905,
                    49.175253
                 ],
                 [
                    87.886902,
                    49.173369
                 ],
                 [
                    87.88496,
                    49.172626
                 ],
                 [
                    87.884047,
                    49.17217
                 ],
                 [
                    87.88319,
                    49.172055
                 ],
                 [
                    87.881591,
                    49.172227
                 ],
                 [
                    87.879078,
                    49.172284
                 ],
                 [
                    87.877822,
                    49.172055
                 ],
                 [
                    87.876566,
                    49.171713
                 ],
                 [
                    87.874966,
                    49.171427
                 ],
                 [
                    87.872283,
                    49.173312
                 ],
                 [
                    87.869827,
                    49.174625
                 ],
                 [
                    87.865658,
                    49.175939
                 ],
                 [
                    87.864287,
                    49.176567
                 ],
                 [
                    87.863774,
                    49.176909
                 ],
                 [
                    87.862574,
                    49.17748
                 ],
                 [
                    87.861318,
                    49.177937
                 ],
                 [
                    87.860633,
                    49.177937
                 ],
                 [
                    87.859319,
                    49.177652
                 ],
                 [
                    87.857492,
                    49.176681
                 ],
                 [
                    87.856464,
                    49.17651
                 ],
                 [
                    87.854294,
                    49.175996
                 ],
                 [
                    87.85298,
                    49.175939
                 ],
                 [
                    87.851838,
                    49.176053
                 ],
                 [
                    87.85041,
                    49.176395
                 ],
                 [
                    87.848412,
                    49.177195
                 ],
                 [
                    87.847269,
                    49.17748
                 ],
                 [
                    87.84607,
                    49.17748
                 ],
                 [
                    87.844985,
                    49.177366
                 ],
                 [
                    87.84253,
                    49.177252
                 ],
                 [
                    87.84036,
                    49.177252
                 ],
                 [
                    87.837847,
                    49.176967
                 ],
                 [
                    87.835791,
                    49.176681
                 ],
                 [
                    87.833507,
                    49.176567
                 ],
                 [
                    87.83128,
                    49.176567
                 ],
                 [
                    87.826882,
                    49.177538
                 ],
                 [
                    87.825911,
                    49.177652
                 ],
                 [
                    87.824941,
                    49.177709
                 ],
                 [
                    87.824084,
                    49.17788
                 ],
                 [
                    87.823228,
                    49.177823
                 ],
                 [
                    87.822428,
                    49.177366
                 ],
                 [
                    87.821971,
                    49.176909
                 ],
                 [
                    87.821514,
                    49.176567
                 ],
                 [
                    87.820829,
                    49.175767
                 ],
                 [
                    87.820372,
                    49.174968
                 ],
                 [
                    87.819744,
                    49.174168
                 ],
                 [
                    87.818944,
                    49.173483
                 ],
                 [
                    87.815575,
                    49.17097
                 ],
                 [
                    87.81453750000001,
                    49.17018270000002
                 ],
                 [
                    87.81794,
                    49.167936
                 ],
                 [
                    87.820942,
                    49.164959
                 ],
                 [
                    87.811707,
                    49.154117
                 ],
                 [
                    87.81468,
                    49.148668
                 ],
                 [
                    87.817675,
                    49.145196
                 ],
                 [
                    87.822262,
                    49.148146
                 ],
                 [
                    87.829875,
                    49.150095
                 ],
                 [
                    87.832869,
                    49.146623
                 ],
                 [
                    87.833533,
                    49.13871
                 ],
                 [
                    87.840295,
                    49.133246
                 ],
                 [
                    87.847038,
                    49.126299
                 ],
                 [
                    87.853027,
                    49.119849
                 ],
                 [
                    87.862103,
                    49.117835
                 ],
                 [
                    87.866635,
                    49.116333
                 ],
                 [
                    87.865043,
                    49.110407
                 ],
                 [
                    87.857417,
                    49.106978
                 ],
                 [
                    87.855106,
                    49.104021
                 ],
                 [
                    87.855051,
                    49.099572
                 ],
                 [
                    87.844395,
                    49.096153
                 ],
                 [
                    87.838295,
                    49.09321
                 ],
                 [
                    87.837454,
                    49.086293
                 ],
                 [
                    87.838164,
                    49.082335
                 ],
                 [
                    87.843433,
                    49.079348
                 ],
                 [
                    87.850229,
                    49.077344
                 ],
                 [
                    87.853187,
                    49.0714
                 ],
                 [
                    87.850071,
                    49.064491
                 ],
                 [
                    87.843223,
                    49.062046
                 ],
                 [
                    87.83563,
                    49.060592
                 ],
                 [
                    87.828701,
                    49.051225
                 ],
                 [
                    87.832382,
                    49.042313
                 ],
                 [
                    87.839131,
                    49.036849
                 ],
                 [
                    87.848211,
                    49.036319
                 ],
                 [
                    87.855807,
                    49.038267
                 ],
                 [
                    87.86107,
                    49.035279
                 ],
                 [
                    87.861766,
                    49.030333
                 ],
                 [
                    87.862486,
                    49.027364
                 ],
                 [
                    87.865489,
                    49.025374
                 ],
                 [
                    87.87608,
                    49.024836
                 ],
                 [
                    87.876036,
                    49.021375
                 ],
                 [
                    87.88204,
                    49.017395
                 ],
                 [
                    87.879732,
                    49.014439
                 ],
                 [
                    87.873621,
                    49.010015
                 ],
                 [
                    87.867517,
                    49.006085
                 ],
                 [
                    87.872001,
                    49.001617
                 ],
                 [
                    87.872732,
                    48.999637
                 ],
                 [
                    87.870419,
                    48.996185
                 ],
                 [
                    87.874901,
                    48.991717
                 ],
                 [
                    87.885447,
                    48.988212
                 ],
                 [
                    87.895248,
                    48.985699
                 ],
                 [
                    87.900523,
                    48.984192
                 ],
                 [
                    87.897393,
                    48.976296
                 ],
                 [
                    87.887536,
                    48.974361
                 ],
                 [
                    87.879968,
                    48.973899
                 ],
                 [
                    87.873887,
                    48.971452
                 ],
                 [
                    87.87005,
                    48.967019
                 ],
                 [
                    87.867726,
                    48.962579
                 ],
                 [
                    87.866158,
                    48.958136
                 ],
                 [
                    87.871393,
                    48.953665
                 ],
                 [
                    87.87288,
                    48.951681
                 ],
                 [
                    87.868308,
                    48.948734
                 ],
                 [
                    87.857689,
                    48.945811
                 ],
                 [
                    87.847858,
                    48.945355
                 ],
                 [
                    87.831993,
                    48.945911
                 ],
                 [
                    87.822179,
                    48.946936
                 ],
                 [
                    87.810887,
                    48.950933
                 ],
                 [
                    87.80853,
                    48.943031
                 ],
                 [
                    87.800172,
                    48.939106
                 ],
                 [
                    87.794094,
                    48.93616
                 ],
                 [
                    87.786462,
                    48.929265
                 ],
                 [
                    87.77888,
                    48.926819
                 ],
                 [
                    87.765312,
                    48.929829
                 ],
                 [
                    87.756271,
                    48.93233
                 ],
                 [
                    87.756225,
                    48.927881
                 ],
                 [
                    87.762207,
                    48.921929
                 ],
                 [
                    87.76141,
                    48.917977
                 ],
                 [
                    87.759124,
                    48.916006
                 ],
                 [
                    87.75155,
                    48.914052
                 ],
                 [
                    87.754515,
                    48.908605
                 ],
                 [
                    87.751449,
                    48.904165
                 ],
                 [
                    87.747633,
                    48.900222
                 ],
                 [
                    87.744582,
                    48.897265
                 ],
                 [
                    87.740772,
                    48.893815
                 ],
                 [
                    87.738458,
                    48.888878
                 ],
                 [
                    87.73762,
                    48.880476
                 ],
                 [
                    87.739065,
                    48.874045
                 ],
                 [
                    87.742774,
                    48.867607
                 ],
                 [
                    87.754068,
                    48.8651
                 ],
                 [
                    87.76992,
                    48.865544
                 ],
                 [
                    87.781281,
                    48.869462
                 ],
                 [
                    87.78576,
                    48.864997
                 ],
                 [
                    87.791726,
                    48.85855
                 ],
                 [
                    87.793924,
                    48.85261
                 ],
                 [
                    87.790858,
                    48.848171
                 ],
                 [
                    87.790082,
                    48.846196
                 ],
                 [
                    87.792301,
                    48.842233
                 ],
                 [
                    87.796804,
                    48.84024
                 ],
                 [
                    87.805123,
                    48.842188
                 ],
                 [
                    87.810408,
                    48.842664
                 ],
                 [
                    87.814168,
                    48.841661
                 ],
                 [
                    87.814122,
                    48.837706
                 ],
                 [
                    87.815585,
                    48.833746
                 ],
                 [
                    87.823063,
                    48.82828
                 ],
                 [
                    87.821515,
                    48.824825
                 ],
                 [
                    87.815461,
                    48.82287
                 ],
                 [
                    87.801164,
                    48.825393
                 ],
                 [
                    87.792848,
                    48.823444
                 ],
                 [
                    87.799612,
                    48.821443
                 ],
                 [
                    87.807865,
                    48.817953
                 ],
                 [
                    87.813114,
                    48.815462
                 ],
                 [
                    87.816798,
                    48.808033
                 ],
                 [
                    87.820515,
                    48.80357
                 ],
                 [
                    87.833269,
                    48.798578
                 ],
                 [
                    87.840793,
                    48.797561
                 ],
                 [
                    87.846815,
                    48.797043
                 ],
                 [
                    87.852138,
                    48.800977
                 ],
                 [
                    87.858933,
                    48.801939
                 ],
                 [
                    87.861904,
                    48.798466
                 ],
                 [
                    87.867908,
                    48.796464
                 ],
                 [
                    87.874677,
                    48.795448
                 ],
                 [
                    87.875362,
                    48.790006
                 ],
                 [
                    87.879098,
                    48.787519
                 ],
                 [
                    87.885112,
                    48.786505
                 ],
                 [
                    87.888094,
                    48.78402
                 ],
                 [
                    87.89181,
                    48.780049
                 ],
                 [
                    87.898544,
                    48.776559
                 ],
                 [
                    87.909803,
                    48.773543
                 ],
                 [
                    87.917994,
                    48.766585
                 ],
                 [
                    87.926923,
                    48.758634
                 ],
                 [
                    87.932879,
                    48.753662
                 ],
                 [
                    87.941982,
                    48.758563
                 ],
                 [
                    87.94811,
                    48.76595
                 ],
                 [
                    87.964756,
                    48.771307
                 ],
                 [
                    87.972273,
                    48.770281
                 ],
                 [
                    87.976734,
                    48.766304
                 ],
                 [
                    87.977465,
                    48.764817
                 ],
                 [
                    87.981991,
                    48.765288
                 ],
                 [
                    87.986502,
                    48.764771
                 ],
                 [
                    87.990245,
                    48.763269
                 ],
                 [
                    87.999221,
                    48.759267
                 ],
                 [
                    88.004516,
                    48.75604
                 ],
                 [
                    88.00767,
                    48.752962
                 ],
                 [
                    88.012908,
                    48.750919
                 ],
                 [
                    88.017616,
                    48.749902
                 ],
                 [
                    88.022315,
                    48.7506
                 ],
                 [
                    88.024947,
                    48.747178
                 ],
                 [
                    88.027588,
                    48.742042
                 ],
                 [
                    88.041226,
                    48.731789
                 ],
                 [
                    88.047004,
                    48.725632
                 ],
                 [
                    88.050698,
                    48.718098
                 ],
                 [
                    88.052293,
                    48.712616
                 ],
                 [
                    88.05491,
                    48.71125
                 ],
                 [
                    88.064817,
                    48.714014
                 ],
                 [
                    88.071076,
                    48.715399
                 ],
                 [
                    88.078385,
                    48.715756
                 ],
                 [
                    88.088838,
                    48.713377
                 ],
                 [
                    88.089893,
                    48.710979
                 ],
                 [
                    88.087296,
                    48.707889
                 ],
                 [
                    88.085225,
                    48.704114
                 ],
                 [
                    88.083675,
                    48.70034
                 ],
                 [
                    88.076909,
                    48.696212
                 ],
                 [
                    88.072248,
                    48.688317
                 ],
                 [
                    88.06967,
                    48.681798
                 ],
                 [
                    88.06134,
                    48.678009
                 ],
                 [
                    88.053536,
                    48.673535
                 ],
                 [
                    88.049901,
                    48.670099
                 ],
                 [
                    88.049392,
                    48.667698
                 ],
                 [
                    88.047588,
                    48.666133
                 ],
                 [
                    88.041068,
                    48.665325
                 ],
                 [
                    88.034293,
                    48.663689
                 ],
                 [
                    88.029767,
                    48.660717
                 ],
                 [
                    88.023734,
                    48.656921
                 ],
                 [
                    88.016943,
                    48.651634
                 ],
                 [
                    88.011416,
                    48.648333
                 ],
                 [
                    88.013662,
                    48.646667
                 ],
                 [
                    88.018415,
                    48.64516
                 ],
                 [
                    88.021897,
                    48.640006
                 ],
                 [
                    88.024141,
                    48.637842
                 ],
                 [
                    88.027633,
                    48.635011
                 ],
                 [
                    88.026365,
                    48.631863
                 ],
                 [
                    88.024847,
                    48.628716
                 ],
                 [
                    88.017581,
                    48.627909
                 ],
                 [
                    88.01357,
                    48.627092
                 ],
                 [
                    88.007535,
                    48.621802
                 ],
                 [
                    88.003517,
                    48.61916
                 ],
                 [
                    88.00169139999998,
                    48.6172737
                 ],
                 [
                    87.999995,
                    48.615521
                 ],
                 [
                    87.99666,
                    48.615867
                 ],
                 [
                    87.991855,
                    48.61492
                 ],
                 [
                    87.984407,
                    48.612865
                 ],
                 [
                    87.972869,
                    48.608589
                 ],
                 [
                    87.967323,
                    48.602241
                 ],
                 [
                    87.963956,
                    48.600338
                 ],
                 [
                    87.958661,
                    48.596372
                 ],
                 [
                    87.95553,
                    48.593356
                 ],
                 [
                    87.963661,
                    48.587628
                 ],
                 [
                    87.966287,
                    48.584607
                 ],
                 [
                    87.967952,
                    48.581586
                 ],
                 [
                    87.969128,
                    48.576183
                 ],
                 [
                    87.97103,
                    48.572527
                 ],
                 [
                    87.97534,
                    48.571251
                 ],
                 [
                    87.979652,
                    48.570293
                 ],
                 [
                    87.982522,
                    48.568859
                 ],
                 [
                    87.986351,
                    48.567425
                 ],
                 [
                    87.988752,
                    48.568057
                 ],
                 [
                    87.99475,
                    48.568843
                 ],
                 [
                    88.000531,
                    48.567243
                 ],
                 [
                    88.004533,
                    48.567231
                 ],
                 [
                    88.010021,
                    48.564063
                 ],
                 [
                    88.013256,
                    48.560569
                 ],
                 [
                    88.013486,
                    48.556255
                 ],
                 [
                    88.015214,
                    48.551438
                 ],
                 [
                    88.018453,
                    48.549106
                 ],
                 [
                    88.025202,
                    48.548587
                 ],
                 [
                    88.030211,
                    48.55023
                 ],
                 [
                    88.033962,
                    48.550383
                 ],
                 [
                    88.037953,
                    48.54838
                 ],
                 [
                    88.042187,
                    48.545213
                 ],
                 [
                    88.046422,
                    48.542379
                 ],
                 [
                    88.050666,
                    48.541203
                 ],
                 [
                    88.057922,
                    48.54234
                 ],
                 [
                    88.064437,
                    48.544971
                 ],
                 [
                    88.068698,
                    48.546781
                 ],
                 [
                    88.072209,
                    48.548593
                 ],
                 [
                    88.076206,
                    48.547915
                 ],
                 [
                    88.079698,
                    48.546575
                 ],
                 [
                    88.081671,
                    48.542089
                 ],
                 [
                    88.083667,
                    48.541418
                 ],
                 [
                    88.087928,
                    48.543227
                 ],
                 [
                    88.090683,
                    48.544046
                 ],
                 [
                    88.093941,
                    48.545195
                 ],
                 [
                    88.097687,
                    48.544517
                 ],
                 [
                    88.099419,
                    48.54169
                 ],
                 [
                    88.105149,
                    48.538682
                 ],
                 [
                    88.106635,
                    48.53652
                 ],
                 [
                    88.107358,
                    48.532369
                 ],
                 [
                    88.109082,
                    48.528547
                 ],
                 [
                    88.110816,
                    48.526052
                 ],
                 [
                    88.1133,
                    48.523885
                 ],
                 [
                    88.117794,
                    48.523204
                 ],
                 [
                    88.12281,
                    48.525838
                 ],
                 [
                    88.129326,
                    48.5283
                 ],
                 [
                    88.136081,
                    48.529268
                 ],
                 [
                    88.14033,
                    48.52925
                 ],
                 [
                    88.143052,
                    48.525589
                 ],
                 [
                    88.144776,
                    48.522098
                 ],
                 [
                    88.151232,
                    48.51643
                 ],
                 [
                    88.156703,
                    48.512922
                 ],
                 [
                    88.161915,
                    48.508255
                 ],
                 [
                    88.167876,
                    48.503749
                 ],
                 [
                    88.172356,
                    48.501573
                 ],
                 [
                    88.175337,
                    48.499569
                 ],
                 [
                    88.187297,
                    48.495865
                 ],
                 [
                    88.19354,
                    48.49567
                 ],
                 [
                    88.198062,
                    48.498801
                 ],
                 [
                    88.207822,
                    48.500911
                 ],
                 [
                    88.21358,
                    48.502377
                 ],
                 [
                    88.217081,
                    48.502857
                 ],
                 [
                    88.221806,
                    48.500346
                 ],
                 [
                    88.22578,
                    48.497838
                 ],
                 [
                    88.229018,
                    48.496827
                 ],
                 [
                    88.23177,
                    48.497311
                 ],
                 [
                    88.236763,
                    48.496954
                 ],
                 [
                    88.241277,
                    48.498922
                 ],
                 [
                    88.245532,
                    48.499896
                 ],
                 [
                    88.25002,
                    48.499043
                 ],
                 [
                    88.252503,
                    48.497371
                 ],
                 [
                    88.253469,
                    48.493883
                 ],
                 [
                    88.257942,
                    48.491537
                 ],
                 [
                    88.262177,
                    48.490354
                 ],
                 [
                    88.264179,
                    48.490841
                 ],
                 [
                    88.267157,
                    48.488834
                 ],
                 [
                    88.27413,
                    48.486806
                 ],
                 [
                    88.280108,
                    48.485281
                 ],
                 [
                    88.286096,
                    48.484751
                 ],
                 [
                    88.292327,
                    48.483556
                 ],
                 [
                    88.295792,
                    48.48055
                 ],
                 [
                    88.301781,
                    48.480185
                 ],
                 [
                    88.306768,
                    48.479494
                 ],
                 [
                    88.309736,
                    48.476823
                 ],
                 [
                    88.312706,
                    48.474317
                 ],
                 [
                    88.319436,
                    48.473284
                 ],
                 [
                    88.325919,
                    48.472583
                 ],
                 [
                    88.330199,
                    48.475876
                 ],
                 [
                    88.332223,
                    48.475565
                 ],
                 [
                    88.33264,
                    48.470719
                 ],
                 [
                    88.33561,
                    48.468379
                 ],
                 [
                    88.340072,
                    48.465532
                 ],
                 [
                    88.342546,
                    48.463527
                 ],
                 [
                    88.347044,
                    48.463998
                 ],
                 [
                    88.351773,
                    48.462808
                 ],
                 [
                    88.35574,
                    48.460461
                 ],
                 [
                    88.35495,
                    48.456817
                 ],
                 [
                    88.354432,
                    48.455161
                 ],
                 [
                    88.351171,
                    48.453687
                 ],
                 [
                    88.349878,
                    48.449714
                 ],
                 [
                    88.347351,
                    48.446908
                 ],
                 [
                    88.348569,
                    48.444247
                 ],
                 [
                    88.350528,
                    48.440917
                 ],
                 [
                    88.350243,
                    48.437767
                 ],
                 [
                    88.349714,
                    48.435116
                 ],
                 [
                    88.350688,
                    48.432953
                 ],
                 [
                    88.355652,
                    48.430767
                 ],
                 [
                    88.359388,
                    48.430246
                 ],
                 [
                    88.365127,
                    48.430377
                 ],
                 [
                    88.370351,
                    48.429184
                 ],
                 [
                    88.375291,
                    48.425006
                 ],
                 [
                    88.381469,
                    48.420156
                 ],
                 [
                    88.390119,
                    48.413631
                 ],
                 [
                    88.395818,
                    48.410609
                 ],
                 [
                    88.406002,
                    48.407391
                 ],
                 [
                    88.409709,
                    48.404713
                 ],
                 [
                    88.415684,
                    48.40401
                 ],
                 [
                    88.42191,
                    48.403471
                 ],
                 [
                    88.426355,
                    48.400124
                 ],
                 [
                    88.431015,
                    48.39412
                 ],
                 [
                    88.435669,
                    48.387785
                 ],
                 [
                    88.440133,
                    48.386096
                 ],
                 [
                    88.452622,
                    48.388167
                 ],
                 [
                    88.465626,
                    48.391395
                 ],
                 [
                    88.474393,
                    48.394485
                 ],
                 [
                    88.477418,
                    48.396952
                 ],
                 [
                    88.479752,
                    48.403571
                 ],
                 [
                    88.481269,
                    48.405053
                 ],
                 [
                    88.485276,
                    48.406352
                 ],
                 [
                    88.491492,
                    48.405146
                 ],
                 [
                    88.497198,
                    48.403114
                 ],
                 [
                    88.49868,
                    48.402108
                 ],
                 [
                    88.50367,
                    48.402403
                 ],
                 [
                    88.506163,
                    48.402384
                 ],
                 [
                    88.510621,
                    48.400361
                 ],
                 [
                    88.513107,
                    48.399845
                 ],
                 [
                    88.516326,
                    48.398328
                 ],
                 [
                    88.519503,
                    48.393991
                 ],
                 [
                    88.522189,
                    48.390155
                 ],
                 [
                    88.521911,
                    48.388166
                 ],
                 [
                    88.51616,
                    48.386882
                 ],
                 [
                    88.512132,
                    48.384092
                 ],
                 [
                    88.513306,
                    48.379106
                 ],
                 [
                    88.517743,
                    48.375755
                 ],
                 [
                    88.523926,
                    48.372557
                 ],
                 [
                    88.530337,
                    48.368029
                 ],
                 [
                    88.537732,
                    48.362665
                 ],
                 [
                    88.539936,
                    48.360159
                 ],
                 [
                    88.545707,
                    48.362935
                 ],
                 [
                    88.544009,
                    48.365934
                 ],
                 [
                    88.546056,
                    48.369568
                 ],
                 [
                    88.548052,
                    48.369719
                 ],
                 [
                    88.552028,
                    48.369025
                 ],
                 [
                    88.557953,
                    48.365495
                 ],
                 [
                    88.562925,
                    48.364792
                 ],
                 [
                    88.568174,
                    48.365912
                 ],
                 [
                    88.57087,
                    48.363071
                 ],
                 [
                    88.568806,
                    48.358442
                 ],
                 [
                    88.567504,
                    48.354802
                 ],
                 [
                    88.567206,
                    48.351653
                 ],
                 [
                    88.569925,
                    48.350304
                 ],
                 [
                    88.576415,
                    48.351248
                 ],
                 [
                    88.57662,
                    48.348426
                 ],
                 [
                    88.578573,
                    48.345922
                 ],
                 [
                    88.580516,
                    48.342754
                 ],
                 [
                    88.586984,
                    48.342371
                 ],
                 [
                    88.594679,
                    48.340815
                 ],
                 [
                    88.603783,
                    48.334105
                 ],
                 [
                    88.604161,
                    48.326637
                 ],
                 [
                    88.600085,
                    48.320864
                 ],
                 [
                    88.588146,
                    48.305698
                 ],
                 [
                    88.580515,
                    48.295142
                 ],
                 [
                    88.56652,
                    48.274847
                 ],
                 [
                    88.57018,
                    48.270339
                 ],
                 [
                    88.57411,
                    48.267156
                 ],
                 [
                    88.581252,
                    48.262786
                 ],
                 [
                    88.58765,
                    48.258587
                 ],
                 [
                    88.587585,
                    48.25444
                 ],
                 [
                    88.58877,
                    48.250781
                 ],
                 [
                    88.59045350000001,
                    48.24968130000002
                 ],
                 [
                    88.594428,
                    48.247085
                 ],
                 [
                    88.595369,
                    48.243759
                 ],
                 [
                    88.594806,
                    48.239616
                 ],
                 [
                    88.592986,
                    48.234488
                 ],
                 [
                    88.587373,
                    48.225243
                 ],
                 [
                    88.586811,
                    48.2211
                 ],
                 [
                    88.58725,
                    48.217447
                 ],
                 [
                    88.588709,
                    48.215444
                 ],
                 [
                    88.59421,
                    48.217723
                 ],
                 [
                    88.597936,
                    48.217692
                 ],
                 [
                    88.603125,
                    48.215991
                 ],
                 [
                    88.609048,
                    48.21362
                 ],
                 [
                    88.615487,
                    48.212405
                 ],
                 [
                    88.621638,
                    48.20887
                 ],
                 [
                    88.626264,
                    48.203191
                 ],
                 [
                    88.629335,
                    48.193708
                 ],
                 [
                    88.627267,
                    48.188749
                 ],
                 [
                    88.633412,
                    48.185047
                 ],
                 [
                    88.63564549999998,
                    48.182654499999984
                 ],
                 [
                    88.637314,
                    48.180867
                 ],
                 [
                    88.641707,
                    48.17635
                 ],
                 [
                    88.646637,
                    48.174317
                 ],
                 [
                    88.650342,
                    48.17329
                 ],
                 [
                    88.657248,
                    48.170742
                 ],
                 [
                    88.663415,
                    48.168531
                 ],
                 [
                    88.666618,
                    48.167176
                 ],
                 [
                    88.671848,
                    48.168292
                 ],
                 [
                    88.678606,
                    48.17155
                 ],
                 [
                    88.6861,
                    48.174304
                 ],
                 [
                    88.690889,
                    48.178409
                 ],
                 [
                    88.695583,
                    48.177205
                 ],
                 [
                    88.70127,
                    48.175993
                 ],
                 [
                    88.712886,
                    48.173233
                 ],
                 [
                    88.71406,
                    48.169572
                 ],
                 [
                    88.709029,
                    48.165802
                 ],
                 [
                    88.707229,
                    48.162335
                 ],
                 [
                    88.708392,
                    48.158011
                 ],
                 [
                    88.712812,
                    48.155482
                 ],
                 [
                    88.71601,
                    48.15396
                 ],
                 [
                    88.721452,
                    48.15308
                 ],
                 [
                    88.727454,
                    48.155679
                 ],
                 [
                    88.729209,
                    48.156658
                 ],
                 [
                    88.732918,
                    48.15596
                 ],
                 [
                    88.73658,
                    48.152774
                 ],
                 [
                    88.738769,
                    48.150431
                 ],
                 [
                    88.742725,
                    48.14973
                 ],
                 [
                    88.745426,
                    48.148211
                 ],
                 [
                    88.745636,
                    48.146219
                 ],
                 [
                    88.746359,
                    48.14505
                 ],
                 [
                    88.74908,
                    48.144693
                 ],
                 [
                    88.752493,
                    48.141508
                 ],
                 [
                    88.754882,
                    48.136675
                 ],
                 [
                    88.756065,
                    48.133677
                 ],
                 [
                    88.762951,
                    48.130625
                 ],
                 [
                    88.769362,
                    48.128739
                 ],
                 [
                    88.775345,
                    48.13034
                 ],
                 [
                    88.780803,
                    48.130453
                 ],
                 [
                    88.784222,
                    48.127765
                 ],
                 [
                    88.787882,
                    48.124743
                 ],
                 [
                    88.794533,
                    48.122521
                 ],
                 [
                    88.798457,
                    48.120326
                 ],
                 [
                    88.803597,
                    48.116957
                 ],
                 [
                    88.801995,
                    48.111166
                 ],
                 [
                    88.803667,
                    48.107997
                 ],
                 [
                    88.808098,
                    48.10646
                 ],
                 [
                    88.814294,
                    48.106398
                 ],
                 [
                    88.81697,
                    48.103883
                 ],
                 [
                    88.819143,
                    48.101041
                 ],
                 [
                    88.823821,
                    48.0995
                 ],
                 [
                    88.830796,
                    48.101255
                 ],
                 [
                    88.836346,
                    48.106009
                 ],
                 [
                    88.840145,
                    48.109952
                 ],
                 [
                    88.847088,
                    48.110046
                 ],
                 [
                    88.853985,
                    48.107984
                 ],
                 [
                    88.859389,
                    48.105606
                 ],
                 [
                    88.869301,
                    48.105502
                 ],
                 [
                    88.878229,
                    48.10574
                 ],
                 [
                    88.883455,
                    48.10668
                 ],
                 [
                    88.887398,
                    48.105642
                 ],
                 [
                    88.895375,
                    48.107714
                 ],
                 [
                    88.90282,
                    48.108131
                 ],
                 [
                    88.91149,
                    48.107871
                 ],
                 [
                    88.918119,
                    48.104979
                 ],
                 [
                    88.923792,
                    48.103755
                 ],
                 [
                    88.929776,
                    48.105349
                 ],
                 [
                    88.934277,
                    48.107124
                 ],
                 [
                    88.936452,
                    48.104611
                 ],
                 [
                    88.936111,
                    48.100467
                 ],
                 [
                    88.937993,
                    48.095967
                 ],
                 [
                    88.943357,
                    48.092092
                 ],
                 [
                    88.947809,
                    48.09171
                 ],
                 [
                    88.950793,
                    48.092175
                 ],
                 [
                    88.952734,
                    48.090328
                 ],
                 [
                    88.953161,
                    48.087337
                 ],
                 [
                    88.957285,
                    48.083475
                 ],
                 [
                    88.958212,
                    48.080644
                 ],
                 [
                    88.963356,
                    48.078097
                 ],
                 [
                    88.966526,
                    48.075905
                 ],
                 [
                    88.968667,
                    48.072065
                 ],
                 [
                    88.97208,
                    48.069703
                 ],
                 [
                    88.974267,
                    48.067853
                 ],
                 [
                    88.980442,
                    48.067119
                 ],
                 [
                    88.984902,
                    48.067234
                 ],
                 [
                    88.987328,
                    48.065049
                 ],
                 [
                    88.988485,
                    48.061552
                 ],
                 [
                    88.990167,
                    48.059376
                 ],
                 [
                    88.994809,
                    48.056668
                 ],
                 [
                    88.999953,
                    48.054285
                 ],
                 [
                    89.005357,
                    48.05103
                 ],
                 [
                    89.007183,
                    48.048238
                 ],
                 [
                    89.009045,
                    48.042247
                 ],
                 [
                    89.012682,
                    48.037863
                 ],
                 [
                    89.018705,
                    48.033889
                 ],
                 [
                    89.025315,
                    48.030716
                 ],
                 [
                    89.030136,
                    48.027136
                 ],
                 [
                    89.03257,
                    48.023146
                 ],
                 [
                    89.03442,
                    48.017955
                 ],
                 [
                    89.035664,
                    48.013561
                 ],
                 [
                    89.039885,
                    48.009978
                 ],
                 [
                    89.042889,
                    48.00839
                 ],
                 [
                    89.047694,
                    48.006009
                 ],
                 [
                    89.05191,
                    48.002825
                 ],
                 [
                    89.053727,
                    48.000433
                 ],
                 [
                    89.05684,
                    47.995839
                 ],
                 [
                    89.059826,
                    47.991991
                 ],
                 [
                    89.060713,
                    47.989506
                 ],
                 [
                    89.062006,
                    47.987302
                 ],
                 [
                    89.066538,
                    47.987907
                 ],
                 [
                    89.07277,
                    47.986591
                 ],
                 [
                    89.091365,
                    47.986795
                 ],
                 [
                    89.100055,
                    47.986336
                 ],
                 [
                    89.108765,
                    47.985044
                 ],
                 [
                    89.119515,
                    47.984882
                 ],
                 [
                    89.130214,
                    47.986937
                 ],
                 [
                    89.142536,
                    47.990393
                 ],
                 [
                    89.151566,
                    47.99326
                 ],
                 [
                    89.156938,
                    47.993315
                 ],
                 [
                    89.16318,
                    47.991439
                 ],
                 [
                    89.170242,
                    47.989849
                 ],
                 [
                    89.176465,
                    47.988803
                 ],
                 [
                    89.181484,
                    47.986082
                 ],
                 [
                    89.186556,
                    47.980867
                 ],
                 [
                    89.194053,
                    47.97817
                 ],
                 [
                    89.207728,
                    47.976366
                 ],
                 [
                    89.216008,
                    47.975616
                 ],
                 [
                    89.236263,
                    47.97526
                 ],
                 [
                    89.243241,
                    47.977545
                 ],
                 [
                    89.246042,
                    47.982007
                 ],
                 [
                    89.252534,
                    47.98789
                 ],
                 [
                    89.257035,
                    47.990151
                 ],
                 [
                    89.262876,
                    47.987434
                 ],
                 [
                    89.270766,
                    47.985568
                 ],
                 [
                    89.281074,
                    47.986773
                 ],
                 [
                    89.284684,
                    47.99235
                 ],
                 [
                    89.287076,
                    47.996807
                 ],
                 [
                    89.288612,
                    48.00059
                 ],
                 [
                    89.291883,
                    48.004613
                 ],
                 [
                    89.29345,
                    48.009063
                 ],
                 [
                    89.29753,
                    48.011872
                 ],
                 [
                    89.302088,
                    48.011359
                 ],
                 [
                    89.306657,
                    48.010292
                 ],
                 [
                    89.31201,
                    48.011449
                 ],
                 [
                    89.321466,
                    48.014306
                 ],
                 [
                    89.328834,
                    48.018252
                 ],
                 [
                    89.332953,
                    48.01912
                 ],
                 [
                    89.343673,
                    48.020877
                 ],
                 [
                    89.347011,
                    48.019243
                 ],
                 [
                    89.347885,
                    48.016756
                 ],
                 [
                    89.352055,
                    48.014852
                 ],
                 [
                    89.356613,
                    48.014336
                 ],
                 [
                    89.361539,
                    48.016319
                 ],
                 [
                    89.36769,
                    48.019144
                 ],
                 [
                    89.371361,
                    48.021947
                 ],
                 [
                    89.374181,
                    48.026128
                 ],
                 [
                    89.377813,
                    48.031148
                 ],
                 [
                    89.376064,
                    48.0364
                 ],
                 [
                    89.377654,
                    48.040017
                 ],
                 [
                    89.385868,
                    48.043412
                 ],
                 [
                    89.388374,
                    48.042047
                 ],
                 [
                    89.390905,
                    48.039296
                 ],
                 [
                    89.395105,
                    48.035727
                 ],
                 [
                    89.399314,
                    48.031604
                 ],
                 [
                    89.401843,
                    48.028853
                 ],
                 [
                    89.405988,
                    48.028333
                 ],
                 [
                    89.412163,
                    48.030046
                 ],
                 [
                    89.418765,
                    48.030931
                 ],
                 [
                    89.424161,
                    48.029866
                 ],
                 [
                    89.426656,
                    48.029054
                 ],
                 [
                    89.435322,
                    48.030232
                 ],
                 [
                    89.443165,
                    48.031125
                 ],
                 [
                    89.446488,
                    48.03032
                 ],
                 [
                    89.452291,
                    48.029534
                 ],
                 [
                    89.458494,
                    48.029582
                 ],
                 [
                    89.465147,
                    48.027415
                 ],
                 [
                    89.470963,
                    48.025797
                 ],
                 [
                    89.479647,
                    48.025862
                 ],
                 [
                    89.486268,
                    48.025635
                 ],
                 [
                    89.491251,
                    48.024286
                 ],
                 [
                    89.494572,
                    48.023479
                 ],
                 [
                    89.502369,
                    48.027417
                 ],
                 [
                    89.508542,
                    48.029402
                 ],
                 [
                    89.511415,
                    48.030809
                 ],
                 [
                    89.514276,
                    48.033047
                 ],
                 [
                    89.52172,
                    48.033101
                 ],
                 [
                    89.527481,
                    48.035082
                 ],
                 [
                    89.537399,
                    48.035707
                 ],
                 [
                    89.5428,
                    48.034081
                 ],
                 [
                    89.547345,
                    48.03439
                 ],
                 [
                    89.552698,
                    48.03609
                 ],
                 [
                    89.560178,
                    48.033647
                 ],
                 [
                    89.564751,
                    48.032015
                 ],
                 [
                    89.568477,
                    48.031763
                 ],
                 [
                    89.57344,
                    48.031797
                 ],
                 [
                    89.5788,
                    48.032941
                 ],
                 [
                    89.582553,
                    48.030749
                 ],
                 [
                    89.584235,
                    48.02882
                 ],
                 [
                    89.585521,
                    48.025503
                 ],
                 [
                    89.587631,
                    48.022468
                 ],
                 [
                    89.592199,
                    48.021112
                 ],
                 [
                    89.596348,
                    48.02003
                 ],
                 [
                    89.598048,
                    48.016716
                 ],
                 [
                    89.599743,
                    48.013678
                 ],
                 [
                    89.60184,
                    48.011474
                 ],
                 [
                    89.603531,
                    48.008714
                 ],
                 [
                    89.601923,
                    48.005377
                 ],
                 [
                    89.598669,
                    48.001476
                 ],
                 [
                    89.595738,
                    47.999283
                 ],
                 [
                    89.591142,
                    47.99848
                 ],
                 [
                    89.587106,
                    47.99884
                 ],
                 [
                    89.585982,
                    47.996902
                 ],
                 [
                    89.58603,
                    47.993427
                 ],
                 [
                    89.585502,
                    47.989948
                 ],
                 [
                    89.590199,
                    47.983414
                 ],
                 [
                    89.593162,
                    47.977255
                 ],
                 [
                    89.593226,
                    47.972622
                 ],
                 [
                    89.592715,
                    47.967985
                 ],
                 [
                    89.601405,
                    47.963794
                 ],
                 [
                    89.613556,
                    47.958852
                 ],
                 [
                    89.620008,
                    47.949625
                 ],
                 [
                    89.630373,
                    47.948919
                 ],
                 [
                    89.640187,
                    47.946276
                 ],
                 [
                    89.640837,
                    47.940488
                 ],
                 [
                    89.638671,
                    47.930049
                 ],
                 [
                    89.641066,
                    47.922727
                 ],
                 [
                    89.647528,
                    47.911954
                 ],
                 [
                    89.655104,
                    47.903891
                 ],
                 [
                    89.666019,
                    47.904343
                 ],
                 [
                    89.678089,
                    47.904415
                 ],
                 [
                    89.68615,
                    47.903303
                 ],
                 [
                    89.694812,
                    47.899878
                 ],
                 [
                    89.708044,
                    47.898794
                 ],
                 [
                    89.716664,
                    47.898842
                 ],
                 [
                    89.723538,
                    47.900811
                 ],
                 [
                    89.727052,
                    47.895038
                 ],
                 [
                    89.739226,
                    47.88545
                 ],
                 [
                    89.74157,
                    47.881215
                 ],
                 [
                    89.750238,
                    47.876627
                 ],
                 [
                    89.750284,
                    47.87238
                 ],
                 [
                    89.742863,
                    47.868093
                 ],
                 [
                    89.738307,
                    47.864594
                 ],
                 [
                    89.744692,
                    47.85845
                 ],
                 [
                    89.749901,
                    47.854616
                 ],
                 [
                    89.750501,
                    47.852302
                 ],
                 [
                    89.749369,
                    47.850752
                 ],
                 [
                    89.751703,
                    47.847289
                 ],
                 [
                    89.752327,
                    47.842659
                 ],
                 [
                    89.751795,
                    47.838794
                 ],
                 [
                    89.757552,
                    47.83728
                 ],
                 [
                    89.760438,
                    47.83575
                 ],
                 [
                    89.758749,
                    47.832653
                 ],
                 [
                    89.756491,
                    47.829166
                 ],
                 [
                    89.758819,
                    47.826089
                 ],
                 [
                    89.765694,
                    47.827283
                 ],
                 [
                    89.772584,
                    47.826932
                 ],
                 [
                    89.777191,
                    47.82541
                 ],
                 [
                    89.784624,
                    47.828151
                 ],
                 [
                    89.790328,
                    47.831654
                 ],
                 [
                    89.794322,
                    47.83399
                 ],
                 [
                    89.804083,
                    47.833651
                 ],
                 [
                    89.809249,
                    47.833676
                 ],
                 [
                    89.814437,
                    47.831384
                 ],
                 [
                    89.819054,
                    47.828703
                 ],
                 [
                    89.828226,
                    47.829903
                 ],
                 [
                    89.832236,
                    47.830694
                 ],
                 [
                    89.836267,
                    47.829168
                 ],
                 [
                    89.843718,
                    47.83036
                 ],
                 [
                    89.846601,
                    47.828828
                 ],
                 [
                    89.848921,
                    47.826135
                 ],
                 [
                    89.855797,
                    47.827324
                 ],
                 [
                    89.863241,
                    47.829287
                 ],
                 [
                    89.870124,
                    47.829702
                 ],
                 [
                    89.88326,
                    47.837479
                 ],
                 [
                    89.891289,
                    47.838284
                 ],
                 [
                    89.898199,
                    47.835609
                 ],
                 [
                    89.905669,
                    47.83448
                 ],
                 [
                    89.914278,
                    47.834513
                 ],
                 [
                    89.921757,
                    47.832225
                 ],
                 [
                    89.928658,
                    47.83032
                 ],
                 [
                    89.936108,
                    47.831892
                 ],
                 [
                    89.949887,
                    47.831169
                 ],
                 [
                    89.95388,
                    47.834658
                 ],
                 [
                    89.952703,
                    47.838901
                 ],
                 [
                    89.952663,
                    47.844693
                 ],
                 [
                    89.953205,
                    47.849328
                 ],
                 [
                    89.954911,
                    47.85165
                 ],
                 [
                    89.950297,
                    47.854723
                 ],
                 [
                    89.951995,
                    47.858204
                 ],
                 [
                    89.956568,
                    47.861308
                 ],
                 [
                    89.954818,
                    47.865163
                 ],
                 [
                    89.957034,
                    47.87714
                 ],
                 [
                    89.952986,
                    47.880987
                 ],
                 [
                    89.956973,
                    47.88602
                 ],
                 [
                    89.959266,
                    47.8868
                 ],
                 [
                    89.967907,
                    47.883354
                 ],
                 [
                    89.972522,
                    47.880281
                 ],
                 [
                    89.977128,
                    47.878752
                 ],
                 [
                    89.982288,
                    47.880313
                 ],
                 [
                    89.985143,
                    47.883025
                 ],
                 [
                    89.992618,
                    47.88189
                 ],
                 [
                    89.999514,
                    47.881525
                 ],
                 [
                    90.004092,
                    47.879713
                 ],
                 [
                    90.010753,
                    47.880257
                 ],
                 [
                    90.013735,
                    47.879283
                 ],
                 [
                    90.018242,
                    47.875832
                 ],
                 [
                    90.019759,
                    47.873853
                 ],
                 [
                    90.026377,
                    47.876883
                 ],
                 [
                    90.027834,
                    47.878385
                 ],
                 [
                    90.033098,
                    47.873944
                 ],
                 [
                    90.037587,
                    47.871488
                 ],
                 [
                    90.047936,
                    47.873049
                 ],
                 [
                    90.0516,
                    47.87556
                 ],
                 [
                    90.052974,
                    47.882034
                 ],
                 [
                    90.056622,
                    47.88554
                 ],
                 [
                    90.061152,
                    47.880596
                 ],
                 [
                    90.064931,
                    47.876144
                 ],
                 [
                    90.072424,
                    47.871219
                 ],
                 [
                    90.078384,
                    47.869268
                 ],
                 [
                    90.08287,
                    47.86681
                 ],
                 [
                    90.085874,
                    47.864342
                 ],
                 [
                    90.087388,
                    47.862362
                 ],
                 [
                    90.089682,
                    47.8579
                 ],
                 [
                    90.083038,
                    47.856366
                 ],
                 [
                    90.079334,
                    47.856343
                 ],
                 [
                    90.07939,
                    47.852862
                 ],
                 [
                    90.072063,
                    47.847841
                 ],
                 [
                    90.069922,
                    47.842854
                 ],
                 [
                    90.068579,
                    47.83439
                 ],
                 [
                    90.065682,
                    47.830393
                 ],
                 [
                    90.065772,
                    47.824923
                 ],
                 [
                    90.067399,
                    47.815981
                 ],
                 [
                    90.067545,
                    47.807029
                 ],
                 [
                    90.066861,
                    47.803543
                 ],
                 [
                    90.073603,
                    47.798613
                 ],
                 [
                    90.067747,
                    47.794597
                 ],
                 [
                    90.0663,
                    47.792598
                 ],
                 [
                    90.068584,
                    47.788634
                 ],
                 [
                    90.070844,
                    47.786162
                 ],
                 [
                    90.070226,
                    47.778697
                 ],
                 [
                    90.076223,
                    47.773762
                 ],
                 [
                    90.080709,
                    47.770806
                 ],
                 [
                    90.082252,
                    47.766837
                 ],
                 [
                    90.08381,
                    47.761873
                 ],
                 [
                    90.091968,
                    47.760432
                 ],
                 [
                    90.095697,
                    47.758466
                 ],
                 [
                    90.098725,
                    47.754008
                 ],
                 [
                    90.10099,
                    47.751038
                 ],
                 [
                    90.106943,
                    47.748588
                 ],
                 [
                    90.11288,
                    47.747131
                 ],
                 [
                    90.119579,
                    47.744187
                 ],
                 [
                    90.121149,
                    47.738228
                 ],
                 [
                    90.123464,
                    47.731776
                 ],
                 [
                    90.127936,
                    47.729316
                 ],
                 [
                    90.134654,
                    47.724879
                 ],
                 [
                    90.138393,
                    47.721916
                 ],
                 [
                    90.147253,
                    47.722465
                 ],
                 [
                    90.152412,
                    47.723489
                 ],
                 [
                    90.159823,
                    47.722039
                 ],
                 [
                    90.164228,
                    47.724053
                 ],
                 [
                    90.169387,
                    47.725076
                 ],
                 [
                    90.175313,
                    47.724114
                 ],
                 [
                    90.179733,
                    47.725133
                 ],
                 [
                    90.184975,
                    47.720188
                 ],
                 [
                    90.184996,
                    47.718696
                 ],
                 [
                    90.193877,
                    47.717748
                 ],
                 [
                    90.196114,
                    47.716268
                 ],
                 [
                    90.19688,
                    47.714283
                 ],
                 [
                    90.202079,
                    47.71232
                 ],
                 [
                    90.208044,
                    47.708373
                 ],
                 [
                    90.211778,
                    47.705408
                 ],
                 [
                    90.22586,
                    47.701998
                 ],
                 [
                    90.235443,
                    47.703537
                 ],
                 [
                    90.239086,
                    47.707535
                 ],
                 [
                    90.242767,
                    47.708547
                 ],
                 [
                    90.245747,
                    47.706572
                 ],
                 [
                    90.245811,
                    47.701599
                 ],
                 [
                    90.257742,
                    47.692703
                 ],
                 [
                    90.26619730000002,
                    47.6921472
                 ],
                 [
                    90.272844,
                    47.69359770000003
                 ],
                 [
                    90.28595809999997,
                    47.693859899999964
                 ],
                 [
                    90.29413940000002,
                    47.69332549999998
                 ],
                 [
                    90.29808459999995,
                    47.69206050000003
                 ],
                 [
                    90.302109,
                    47.688429
                 ],
                 [
                    90.31396,
                    47.685495
                 ],
                 [
                    90.322828,
                    47.685035
                 ],
                 [
                    90.326542,
                    47.683061
                 ],
                 [
                    90.331028,
                    47.678106
                 ],
                 [
                    90.337674,
                    47.678133
                 ],
                 [
                    90.348055,
                    47.674195
                 ],
                 [
                    90.352548,
                    47.668244
                 ],
                 [
                    90.356277,
                    47.664776
                 ],
                 [
                    90.362962,
                    47.660823
                 ],
                 [
                    90.370406,
                    47.654882
                 ],
                 [
                    90.374162,
                    47.64843
                 ],
                 [
                    90.37201,
                    47.642453
                 ],
                 [
                    90.363872,
                    47.644412
                 ],
                 [
                    90.360188,
                    47.6439
                 ],
                 [
                    90.354269,
                    47.64537
                 ],
                 [
                    90.352772,
                    47.647354
                 ],
                 [
                    90.34621,
                    47.639867
                 ],
                 [
                    90.344794,
                    47.63439
                 ],
                 [
                    90.345585,
                    47.629419
                 ],
                 [
                    90.344912,
                    47.623447
                 ],
                 [
                    90.346403,
                    47.621961
                 ],
                 [
                    90.354549,
                    47.619008
                 ],
                 [
                    90.359011,
                    47.615544
                 ],
                 [
                    90.3598,
                    47.610573
                 ],
                 [
                    90.362065,
                    47.605607
                 ],
                 [
                    90.370212,
                    47.602156
                 ],
                 [
                    90.373914,
                    47.600678
                 ],
                 [
                    90.376176,
                    47.595712
                 ],
                 [
                    90.380655,
                    47.590257
                 ],
                 [
                    90.380685,
                    47.587272
                 ],
                 [
                    90.379983,
                    47.583788
                 ],
                 [
                    90.385207,
                    47.57734
                 ],
                 [
                    90.389673,
                    47.572879
                 ],
                 [
                    90.391943,
                    47.566919
                 ],
                 [
                    90.39127,
                    47.56045
                 ],
                 [
                    90.39206,
                    47.554981
                 ],
                 [
                    90.396495,
                    47.553504
                 ],
                 [
                    90.397265,
                    47.550025
                 ],
                 [
                    90.398772,
                    47.546548
                 ],
                 [
                    90.397333,
                    47.543061
                 ],
                 [
                    90.398094,
                    47.540577
                 ],
                 [
                    90.399595,
                    47.537597
                 ],
                 [
                    90.406233,
                    47.536625
                 ],
                 [
                    90.413606,
                    47.535655
                 ],
                 [
                    90.418043,
                    47.533681
                 ],
                 [
                    90.423976,
                    47.529223
                 ],
                 [
                    90.428416,
                    47.52675
                 ],
                 [
                    90.432843,
                    47.52577
                 ],
                 [
                    90.439514,
                    47.520816
                 ],
                 [
                    90.44249,
                    47.517344
                 ],
                 [
                    90.446197,
                    47.51437
                 ],
                 [
                    90.449899,
                    47.511894
                 ],
                 [
                    90.450682,
                    47.506425
                 ],
                 [
                    90.452189,
                    47.50245
                 ],
                 [
                    90.456617,
                    47.500971
                 ],
                 [
                    90.462513,
                    47.499993
                 ],
                 [
                    90.464771,
                    47.494031
                 ],
                 [
                    90.469947,
                    47.491061
                 ],
                 [
                    90.472906,
                    47.489079
                 ],
                 [
                    90.471472,
                    47.484598
                 ],
                 [
                    90.467855,
                    47.477127
                 ],
                 [
                    90.467905,
                    47.471158
                 ],
                 [
                    90.472351,
                    47.467191
                 ],
                 [
                    90.470908,
                    47.463704
                 ],
                 [
                    90.467995,
                    47.460214
                 ],
                 [
                    90.468801,
                    47.45176
                 ],
                 [
                    90.468123,
                    47.444794
                 ],
                 [
                    90.461528,
                    47.442288
                 ],
                 [
                    90.458608,
                    47.439792
                 ],
                 [
                    90.457176,
                    47.435311
                 ],
                 [
                    90.457222,
                    47.429839
                 ],
                 [
                    90.460943,
                    47.424378
                 ],
                 [
                    90.463185,
                    47.419908
                 ],
                 [
                    90.461757,
                    47.414929
                 ],
                 [
                    90.460304,
                    47.412935
                 ],
                 [
                    90.455892,
                    47.41342
                 ],
                 [
                    90.45815,
                    47.40696
                 ],
                 [
                    90.467746,
                    47.401515
                 ],
                 [
                    90.475862,
                    47.397061
                 ],
                 [
                    90.483957,
                    47.395093
                 ],
                 [
                    90.490548,
                    47.397598
                 ],
                 [
                    90.494928,
                    47.401092
                 ],
                 [
                    90.499328,
                    47.402098
                 ],
                 [
                    90.50818,
                    47.397146
                 ],
                 [
                    90.509679,
                    47.39317
                 ],
                 [
                    90.509709,
                    47.38919
                 ],
                 [
                    90.514893,
                    47.383233
                 ],
                 [
                    90.517859,
                    47.379261
                 ],
                 [
                    90.520087,
                    47.375784
                 ],
                 [
                    90.517917,
                    47.371302
                 ],
                 [
                    90.513545,
                    47.366814
                 ],
                 [
                    90.511361,
                    47.364322
                 ],
                 [
                    90.502605,
                    47.357336
                 ],
                 [
                    90.498973,
                    47.352352
                 ],
                 [
                    90.503418,
                    47.346891
                 ],
                 [
                    90.507113,
                    47.343418
                 ],
                 [
                    90.509336,
                    47.340439
                 ],
                 [
                    90.507153,
                    47.337946
                 ],
                 [
                    90.5035,
                    47.335947
                 ],
                 [
                    90.50131,
                    47.33445
                 ],
                 [
                    90.49829,
                    47.331447
                 ],
                 [
                    90.493257,
                    47.328556
                 ],
                 [
                    90.489923,
                    47.3251
                 ],
                 [
                    90.486566,
                    47.323363
                 ],
                 [
                    90.477261,
                    47.32389
                 ],
                 [
                    90.476455,
                    47.321019
                 ],
                 [
                    90.480721,
                    47.318174
                 ],
                 [
                    90.482467,
                    47.31417
                 ],
                 [
                    90.485027,
                    47.312463
                 ],
                 [
                    90.491787,
                    47.312497
                 ],
                 [
                    90.493524,
                    47.309066
                 ],
                 [
                    90.499494,
                    47.305082
                 ],
                 [
                    90.507137,
                    47.302252
                 ],
                 [
                    90.512237,
                    47.299984
                 ],
                 [
                    90.513113,
                    47.297694
                 ],
                 [
                    90.51064,
                    47.293096
                 ],
                 [
                    90.515754,
                    47.289681
                 ],
                 [
                    90.52255,
                    47.286846
                 ],
                 [
                    90.524299,
                    47.282268
                 ],
                 [
                    90.522678,
                    47.2771
                 ],
                 [
                    90.523567,
                    47.273665
                 ],
                 [
                    90.527819,
                    47.271392
                 ],
                 [
                    90.529553,
                    47.26796
                 ],
                 [
                    90.531294,
                    47.263955
                 ],
                 [
                    90.527961,
                    47.260499
                 ],
                 [
                    90.530546,
                    47.256498
                 ],
                 [
                    90.536492,
                    47.25366
                 ],
                 [
                    90.535714,
                    47.248496
                 ],
                 [
                    90.536625,
                    47.243341
                 ],
                 [
                    90.53752,
                    47.239332
                 ],
                 [
                    90.548556,
                    47.234221
                 ],
                 [
                    90.555379,
                    47.228518
                 ],
                 [
                    90.557988,
                    47.222223
                 ],
                 [
                    90.556386,
                    47.215336
                 ],
                 [
                    90.559031,
                    47.206174
                 ],
                 [
                    90.56582,
                    47.202764
                 ],
                 [
                    90.573438,
                    47.200503
                 ],
                 [
                    90.579418,
                    47.194222
                 ],
                 [
                    90.587998,
                    47.181644
                 ],
                 [
                    90.590649,
                    47.171335
                 ],
                 [
                    90.59408,
                    47.166189
                 ],
                 [
                    90.605083,
                    47.16222
                 ],
                 [
                    90.613543,
                    47.159386
                 ],
                 [
                    90.617801,
                    47.155389
                 ],
                 [
                    90.617029,
                    47.149079
                 ],
                 [
                    90.619596,
                    47.145649
                 ],
                 [
                    90.625518,
                    47.143378
                 ],
                 [
                    90.623891,
                    47.138212
                 ],
                 [
                    90.627311,
                    47.133638
                 ],
                 [
                    90.639109,
                    47.133108
                 ],
                 [
                    90.645853,
                    47.132559
                 ],
                 [
                    90.648429,
                    47.127981
                 ],
                 [
                    90.648508,
                    47.120528
                 ],
                 [
                    90.64686,
                    47.117083
                 ],
                 [
                    90.644402,
                    47.110767
                 ],
                 [
                    90.649501,
                    47.106199
                 ],
                 [
                    90.656261,
                    47.103929
                 ],
                 [
                    90.656327,
                    47.097623
                 ],
                 [
                    90.669911,
                    47.086201
                 ],
                 [
                    90.679197,
                    47.083366
                 ],
                 [
                    90.685116,
                    47.080518
                 ],
                 [
                    90.686849,
                    47.075364
                 ],
                 [
                    90.694517,
                    47.06564
                 ],
                 [
                    90.702192,
                    47.054771
                 ],
                 [
                    90.706447,
                    47.049624
                 ],
                 [
                    90.713206,
                    47.046204
                 ],
                 [
                    90.719177,
                    47.037048
                 ],
                 [
                    90.725967,
                    47.030187
                 ],
                 [
                    90.730222,
                    47.024465
                 ],
                 [
                    90.738613,
                    47.026208
                 ],
                 [
                    90.748774,
                    47.017635
                 ],
                 [
                    90.755591,
                    47.006759
                 ],
                 [
                    90.75566,
                    46.998732
                 ],
                 [
                    90.756539,
                    46.994148
                 ],
                 [
                    90.760778,
                    46.989571
                 ],
                 [
                    90.765833,
                    46.987864
                 ],
                 [
                    90.775064,
                    46.989033
                 ],
                 [
                    90.781775,
                    46.990196
                 ],
                 [
                    90.791852,
                    46.990792
                 ],
                 [
                    90.801932,
                    46.990814
                 ],
                 [
                    90.811996,
                    46.993129
                 ],
                 [
                    90.818713,
                    46.993715
                 ],
                 [
                    90.827135,
                    46.990866
                 ],
                 [
                    90.833884,
                    46.986865
                 ],
                 [
                    90.8423,
                    46.984587
                 ],
                 [
                    90.852365,
                    46.986898
                 ],
                 [
                    90.852411,
                    46.980017
                 ],
                 [
                    90.857484,
                    46.974866
                 ],
                 [
                    90.866745,
                    46.971441
                 ],
                 [
                    90.879381,
                    46.965153
                 ],
                 [
                    90.892851,
                    46.959438
                 ],
                 [
                    90.894554,
                    46.955426
                 ],
                 [
                    90.903799,
                    46.953718
                 ],
                 [
                    90.903838,
                    46.946838
                 ],
                 [
                    90.909752,
                    46.939964
                 ],
                 [
                    90.913126,
                    46.937101
                 ],
                 [
                    90.919843,
                    46.936536
                 ],
                 [
                    90.918186,
                    46.93252
                 ],
                 [
                    90.913189,
                    46.925633
                 ],
                 [
                    90.911552,
                    46.918178
                 ],
                 [
                    90.915794,
                    46.909581
                 ],
                 [
                    90.921718,
                    46.89984
                 ],
                 [
                    90.922575,
                    46.896401
                 ],
                 [
                    90.930991,
                    46.890676
                 ],
                 [
                    90.931859,
                    46.884942
                 ],
                 [
                    90.938576,
                    46.883229
                 ],
                 [
                    90.946965,
                    46.88209
                 ],
                 [
                    90.952023,
                    46.87636
                 ],
                 [
                    90.953714,
                    46.873494
                 ],
                 [
                    90.951216,
                    46.870052
                 ],
                 [
                    90.951232,
                    46.866612
                 ],
                 [
                    90.952936,
                    46.860879
                 ],
                 [
                    90.953782,
                    46.859159
                 ],
                 [
                    90.951287,
                    46.855143
                 ],
                 [
                    90.950478,
                    46.848835
                 ],
                 [
                    90.947161,
                    46.841952
                 ],
                 [
                    90.938819,
                    46.83449
                 ],
                 [
                    90.936379,
                    46.820152
                 ],
                 [
                    90.945624,
                    46.81328
                 ],
                 [
                    90.954854,
                    46.809274
                 ],
                 [
                    90.960733,
                    46.805264
                 ],
                 [
                    90.965766,
                    46.802974
                 ],
                 [
                    90.96496,
                    46.796093
                 ],
                 [
                    90.974186,
                    46.792084
                 ],
                 [
                    90.981712,
                    46.793809
                 ],
                 [
                    90.987569,
                    46.794385
                 ],
                 [
                    90.986748,
                    46.790371
                 ],
                 [
                    90.98593,
                    46.785783
                 ],
                 [
                    90.988463,
                    46.78005
                 ],
                 [
                    90.990155,
                    46.775464
                 ],
                 [
                    90.988498,
                    46.771449
                 ],
                 [
                    90.988514,
                    46.767435
                 ],
                 [
                    90.997062,
                    46.763929
                 ],
                 [
                    91.001977,
                    46.764941
                 ],
                 [
                    91.006301,
                    46.765329
                 ],
                 [
                    91.010644,
                    46.765055
                 ],
                 [
                    91.013576,
                    46.763437
                 ],
                 [
                    91.015571,
                    46.760813
                 ],
                 [
                    91.016663,
                    46.755858
                 ],
                 [
                    91.016259,
                    46.752872
                 ],
                 [
                    91.016809,
                    46.750229
                 ],
                 [
                    91.016852,
                    46.748573
                 ],
                 [
                    91.021236,
                    46.746642
                 ],
                 [
                    91.025602,
                    46.745374
                 ],
                 [
                    91.029993,
                    46.743112
                 ],
                 [
                    91.031471,
                    46.741806
                 ],
                 [
                    91.034951,
                    46.737544
                 ],
                 [
                    91.036986,
                    46.733264
                 ],
                 [
                    91.039485,
                    46.729652
                 ],
                 [
                    91.041013,
                    46.726359
                 ],
                 [
                    91.042997,
                    46.724066
                 ],
                 [
                    91.044466,
                    46.723091
                 ],
                 [
                    91.046888,
                    46.722459
                 ],
                 [
                    91.048399,
                    46.719828
                 ],
                 [
                    91.043554,
                    46.70221
                 ],
                 [
                    91.041377,
                    46.693238
                 ],
                 [
                    91.039581,
                    46.688246
                 ],
                 [
                    91.038754,
                    46.682936
                 ],
                 [
                    91.035524,
                    46.677594
                 ],
                 [
                    91.033695,
                    46.673927
                 ],
                 [
                    91.031933,
                    46.66761
                 ],
                 [
                    91.030978,
                    46.66212
                 ],
                 [
                    91.028184,
                    46.653635
                 ],
                 [
                    91.025556,
                    46.644359
                 ],
                 [
                    91.023257,
                    46.63678
                 ],
                 [
                    91.019807,
                    46.626937
                 ],
                 [
                    91.018826,
                    46.621394
                 ],
                 [
                    91.014556,
                    46.609629
                 ],
                 [
                    91.014408,
                    46.59877
                 ],
                 [
                    91.014592,
                    46.586553
                 ],
                 [
                    91.014437,
                    46.580558
                 ],
                 [
                    91.0151,
                    46.577957
                 ],
                 [
                    91.017739,
                    46.577053
                 ],
                 [
                    91.018233,
                    46.57694
                 ],
                 [
                    91.021365,
                    46.576602
                 ],
                 [
                    91.022518,
                    46.577055
                 ],
                 [
                    91.024989,
                    46.577508
                 ],
                 [
                    91.025977,
                    46.577961
                 ],
                 [
                    91.029274,
                    46.57717
                 ],
                 [
                    91.032735,
                    46.577624
                 ],
                 [
                    91.035701,
                    46.577625
                 ],
                 [
                    91.037679,
                    46.577738
                 ],
                 [
                    91.039327,
                    46.577512
                 ],
                 [
                    91.041799,
                    46.577739
                 ],
                 [
                    91.045261,
                    46.576722
                 ],
                 [
                    91.04625,
                    46.576269
                 ],
                 [
                    91.0507,
                    46.576157
                 ],
                 [
                    91.05449,
                    46.575705
                 ],
                 [
                    91.057292,
                    46.576044
                 ],
                 [
                    91.059104,
                    46.576158
                 ],
                 [
                    91.061906,
                    46.575479
                 ],
                 [
                    91.065861,
                    46.575592
                 ],
                 [
                    91.068169,
                    46.574574
                 ],
                 [
                    91.070643,
                    46.571407
                 ],
                 [
                    91.071139,
                    46.568466
                 ],
                 [
                    91.072624,
                    46.564393
                 ],
                 [
                    91.074108,
                    46.561905
                 ],
                 [
                    91.074768,
                    46.559416
                 ],
                 [
                    91.074933,
                    46.557606
                 ],
                 [
                    91.06884,
                    46.554326
                 ],
                 [
                    91.06851,
                    46.553987
                 ],
                 [
                    91.069499,
                    46.553308
                 ],
                 [
                    91.064888,
                    46.55082
                 ],
                 [
                    91.061101,
                    46.54901
                 ],
                 [
                    91.060115,
                    46.546069
                 ],
                 [
                    91.059458,
                    46.543241
                 ],
                 [
                    91.058307,
                    46.540752
                 ],
                 [
                    91.057319,
                    46.539621
                 ],
                 [
                    91.055015,
                    46.53815
                 ],
                 [
                    91.051887,
                    46.536679
                 ],
                 [
                    91.0509,
                    46.535435
                 ],
                 [
                    91.051067,
                    46.53272
                 ],
                 [
                    91.053044,
                    46.531589
                 ],
                 [
                    91.055022,
                    46.529327
                 ],
                 [
                    91.055517,
                    46.528309
                 ],
                 [
                    91.054201,
                    46.526499
                 ],
                 [
                    91.052721,
                    46.524237
                 ],
                 [
                    91.052229,
                    46.522087
                 ],
                 [
                    91.052725,
                    46.519825
                 ],
                 [
                    91.052891,
                    46.51711
                 ],
                 [
                    91.053717,
                    46.513717
                 ],
                 [
                    91.052401,
                    46.512585
                 ],
                 [
                    91.052403,
                    46.511115
                 ],
                 [
                    91.050758,
                    46.50987
                 ],
                 [
                    91.047138,
                    46.508286
                 ],
                 [
                    91.043681,
                    46.508286
                 ],
                 [
                    91.041379,
                    46.506249
                 ],
                 [
                    91.038585,
                    46.503082
                 ],
                 [
                    91.037105,
                    46.501498
                 ],
                 [
                    91.036119,
                    46.500253
                 ],
                 [
                    91.035462,
                    46.499122
                 ],
                 [
                    91.035628,
                    46.497651
                 ],
                 [
                    91.036124,
                    46.496294
                 ],
                 [
                    91.03415,
                    46.495049
                 ],
                 [
                    91.032671,
                    46.493239
                 ],
                 [
                    91.034154,
                    46.491656
                 ],
                 [
                    91.033333,
                    46.490072
                 ],
                 [
                    91.03284,
                    46.489506
                 ],
                 [
                    91.030209,
                    46.488714
                 ],
                 [
                    91.028235,
                    46.487808
                 ],
                 [
                    91.02577,
                    46.485771
                 ],
                 [
                    91.024951,
                    46.48283
                 ],
                 [
                    91.025118,
                    46.480907
                 ],
                 [
                    91.025942,
                    46.480455
                 ],
                 [
                    91.026601,
                    46.479211
                 ],
                 [
                    91.027098,
                    46.476722
                 ],
                 [
                    91.024963,
                    46.474007
                 ],
                 [
                    91.022993,
                    46.471065
                 ],
                 [
                    91.021022,
                    46.468915
                 ],
                 [
                    91.016422,
                    46.465633
                 ],
                 [
                    91.014451,
                    46.463483
                 ],
                 [
                    91.013304,
                    46.461107
                 ],
                 [
                    91.011332,
                    46.460315
                 ],
                 [
                    91.009359,
                    46.460087
                 ],
                 [
                    91.008046,
                    46.458277
                 ],
                 [
                    91.006901,
                    46.454544
                 ],
                 [
                    91.00674,
                    46.452281
                 ],
                 [
                    91.007579,
                    46.441988
                 ],
                 [
                    91.006761,
                    46.439386
                 ],
                 [
                    91.007588,
                    46.435993
                 ],
                 [
                    91.007262,
                    46.434522
                 ],
                 [
                    91.005621,
                    46.432824
                 ],
                 [
                    91.003816,
                    46.43124
                 ],
                 [
                    91.000534,
                    46.428184
                 ],
                 [
                    90.994211,
                    46.421492
                 ],
                 [
                    90.992275,
                    46.413297
                 ],
                 [
                    90.985784,
                    46.395084
                 ],
                 [
                    90.981909,
                    46.380514
                 ],
                 [
                    90.976707,
                    46.369129
                 ],
                 [
                    90.974767,
                    46.362755
                 ],
                 [
                    90.969538,
                    46.357742
                 ],
                 [
                    90.954476,
                    46.349985
                 ],
                 [
                    90.940085,
                    46.340407
                 ],
                 [
                    90.933546,
                    46.335846
                 ],
                 [
                    90.927678,
                    46.32901
                 ],
                 [
                    90.91521880000005,
                    46.32397670000003
                 ],
                 [
                    90.910021,
                    46.318513
                 ],
                 [
                    90.905456,
                    46.313954
                 ],
                 [
                    90.899549,
                    46.314399
                 ],
                 [
                    90.892338,
                    46.313476
                 ],
                 [
                    90.894354,
                    46.305741
                 ],
                 [
                    90.902273,
                    46.297562
                 ],
                 [
                    90.908887,
                    46.288015
                 ],
                 [
                    90.915496,
                    46.278922
                 ],
                 [
                    90.922771,
                    46.267554
                 ],
                 [
                    90.931343,
                    46.258008
                 ],
                 [
                    90.934657,
                    46.251185
                 ],
                 [
                    90.941259,
                    46.241635
                 ],
                 [
                    90.951148,
                    46.229358
                 ],
                 [
                    90.945269,
                    46.226164
                 ],
                 [
                    90.945309,
                    46.218426
                 ],
                 [
                    90.945996,
                    46.212054
                 ],
                 [
                    90.953888,
                    46.205237
                 ],
                 [
                    90.960492,
                    46.19341
                 ],
                 [
                    90.969086,
                    46.175212
                 ],
                 [
                    90.974385,
                    46.161107
                 ],
                 [
                    90.979631,
                    46.158381
                 ],
                 [
                    90.990144,
                    46.147466
                 ],
                 [
                    91.003287,
                    46.132001
                 ],
                 [
                    91.007882,
                    46.127452
                 ],
                 [
                    91.011819,
                    46.123814
                 ],
                 [
                    91.010529,
                    46.119261
                 ],
                 [
                    91.010554,
                    46.112888
                 ],
                 [
                    91.009262,
                    46.10879
                 ],
                 [
                    91.009285,
                    46.102872
                 ],
                 [
                    91.011272,
                    46.096046
                 ],
                 [
                    91.011944,
                    46.091039
                 ],
                 [
                    91.013918,
                    46.087399
                 ],
                 [
                    91.015237,
                    46.084213
                 ],
                 [
                    91.008063,
                    46.081022
                 ],
                 [
                    91.009396,
                    46.074195
                 ],
                 [
                    91.014001,
                    46.065549
                 ],
                 [
                    91.019249,
                    46.05918
                 ],
                 [
                    91.019327,
                    46.037785
                 ],
                 [
                    91.021313,
                    46.030048
                 ],
                 [
                    91.021993,
                    46.022309
                 ],
                 [
                    91.015484,
                    46.017753
                 ],
                 [
                    91.011585,
                    46.013654
                 ],
                 [
                    91.007032,
                    46.010009
                 ],
                 [
                    91.000525,
                    46.005452
                 ],
                 [
                    90.98207,
                    45.990643
                 ],
                 [
                    90.951957,
                    45.968758
                 ],
                 [
                    90.933886,
                    45.953881
                 ],
                 [
                    90.867732,
                    45.902627
                 ],
                 [
                    90.84791,
                    45.887327
                 ],
                 [
                    90.840691,
                    45.879462
                 ],
                 [
                    90.806403,
                    45.838054
                 ],
                 [
                    90.771583,
                    45.799542
                 ],
                 [
                    90.744583,
                    45.765986
                 ],
                 [
                    90.730797,
                    45.748998
                 ],
                 [
                    90.71282,
                    45.725378
                 ],
                 [
                    90.709188,
                    45.710865
                 ],
                 [
                    90.699524,
                    45.675619
                 ],
                 [
                    90.683271,
                    45.624202
                 ],
                 [
                    90.66987670000002,
                    45.560264
                 ],
                 [
                    90.6685055,
                    45.551965800000026
                 ],
                 [
                    90.66769980000002,
                    45.53704820000004
                 ],
                 [
                    90.668609,
                    45.507246
                 ],
                 [
                    90.66831109999998,
                    45.48643160000003
                 ],
                 [
                    90.677418,
                    45.480692
                 ],
                 [
                    90.689848,
                    45.473628
                 ],
                 [
                    90.696352,
                    45.468228
                 ],
                 [
                    90.709969,
                    45.462405
                 ],
                 [
                    90.721806,
                    45.456998
                 ],
                 [
                    90.728911,
                    45.454499
                 ],
                 [
                    90.738379,
                    45.450338
                 ],
                 [
                    90.741932,
                    45.449503
                 ],
                 [
                    90.747839,
                    45.444931
                 ],
                 [
                    90.755528,
                    45.441185
                 ],
                 [
                    90.760846,
                    45.437858
                 ],
                 [
                    90.766744,
                    45.432041
                 ],
                 [
                    90.7698523,
                    45.42839090000001
                 ],
                 [
                    90.770255,
                    45.423323
                 ],
                 [
                    90.770748,
                    45.404241
                 ],
                 [
                    90.774254,
                    45.395109
                 ],
                 [
                    90.77686919999996,
                    45.391931499999956
                 ],
                 [
                    90.78247,
                    45.38099
                 ],
                 [
                    90.787139,
                    45.368537
                 ],
                 [
                    90.793593,
                    45.358154
                 ],
                 [
                    90.798264,
                    45.346944
                 ],
                 [
                    90.807066,
                    45.334066
                 ],
                 [
                    90.808518,
                    45.328715
                 ],
                 [
                    90.811567,
                    45.324992
                 ],
                 [
                    90.80776,
                    45.30951
                 ],
                 [
                    90.803279,
                    45.297777
                 ],
                 [
                    90.801706,
                    45.288579
                 ],
                 [
                    90.832894,
                    45.298925
                 ],
                 [
                    90.843688,
                    45.293195
                 ],
                 [
                    90.850306,
                    45.288672
                 ],
                 [
                    90.86941,
                    45.28219
                 ],
                 [
                    90.882786,
                    45.266049
                 ],
                 [
                    90.894961,
                    45.250311
                 ],
                 [
                    90.889882,
                    45.238158
                 ],
                 [
                    90.880662,
                    45.225963
                 ],
                 [
                    90.876667,
                    45.218828
                 ],
                 [
                    90.864547,
                    45.204098
                 ],
                 [
                    90.882577,
                    45.190925
                 ],
                 [
                    90.883794,
                    45.189268
                 ],
                 [
                    90.913356,
                    45.190395
                 ],
                 [
                    90.93107,
                    45.192235
                 ],
                 [
                    90.946394,
                    45.195302
                 ],
                 [
                    90.963462,
                    45.200053
                 ],
                 [
                    90.973417,
                    45.205571
                 ],
                 [
                    90.985764,
                    45.209858
                 ],
                 [
                    90.995151,
                    45.213834
                 ],
                 [
                    91.001494,
                    45.213446
                 ],
                 [
                    91.006817,
                    45.213895
                 ],
                 [
                    91.011552,
                    45.213921
                 ],
                 [
                    91.01808,
                    45.212278
                 ],
                 [
                    91.026391,
                    45.209806
                 ],
                 [
                    91.034689,
                    45.208591
                 ],
                 [
                    91.042387,
                    45.208212
                 ],
                 [
                    91.048921,
                    45.205728
                 ],
                 [
                    91.05895,
                    45.209136
                 ],
                 [
                    91.068388,
                    45.21254
                 ],
                 [
                    91.077842,
                    45.214265
                 ],
                 [
                    91.087312,
                    45.214311
                 ],
                 [
                    91.09086,
                    45.214747
                 ],
                 [
                    91.096202,
                    45.213095
                 ],
                 [
                    91.103319,
                    45.21145
                 ],
                 [
                    91.108653,
                    45.210635
                 ],
                 [
                    91.116928,
                    45.211932
                 ],
                 [
                    91.122238,
                    45.214054
                 ],
                 [
                    91.135297,
                    45.209497
                 ],
                 [
                    91.143613,
                    45.205757
                 ],
                 [
                    91.153701,
                    45.202443
                 ],
                 [
                    91.163784,
                    45.199548
                 ],
                 [
                    91.165588,
                    45.195779
                 ],
                 [
                    91.166227,
                    45.189488
                 ],
                 [
                    91.167478,
                    45.180682
                 ],
                 [
                    91.169876,
                    45.176496
                 ],
                 [
                    91.173444,
                    45.173993
                 ],
                 [
                    91.175246,
                    45.170224
                 ],
                 [
                    91.178228,
                    45.166879
                 ],
                 [
                    91.184734,
                    45.166905
                 ],
                 [
                    91.187142,
                    45.16104
                 ],
                 [
                    91.191318,
                    45.156022
                 ],
                 [
                    91.201976,
                    45.153965
                 ],
                 [
                    91.21026,
                    45.153157
                 ],
                 [
                    91.217358,
                    45.152763
                 ],
                 [
                    91.223288,
                    45.150267
                 ],
                 [
                    91.2316,
                    45.144842
                 ],
                 [
                    91.235185,
                    45.13898
                 ],
                 [
                    91.238157,
                    45.136473
                 ],
                 [
                    91.241732,
                    45.13187
                 ],
                 [
                    91.246451,
                    45.133564
                 ],
                 [
                    91.252953,
                    45.133586
                 ],
                 [
                    91.289621,
                    45.129927
                 ],
                 [
                    91.30086,
                    45.128282
                 ],
                 [
                    91.30973,
                    45.127468
                 ],
                 [
                    91.317417,
                    45.12665
                 ],
                 [
                    91.321552,
                    45.127081
                 ],
                 [
                    91.329226,
                    45.1292
                 ],
                 [
                    91.335162,
                    45.123761
                 ],
                 [
                    91.336355,
                    45.121247
                 ],
                 [
                    91.341674,
                    45.12126
                 ],
                 [
                    91.344625,
                    45.122107
                 ],
                 [
                    91.349368,
                    45.118762
                 ],
                 [
                    91.357066,
                    45.115005
                 ],
                 [
                    91.365353,
                    45.111668
                 ],
                 [
                    91.371269,
                    45.110003
                 ],
                 [
                    91.377771,
                    45.109179
                 ],
                 [
                    91.385443,
                    45.111713
                 ],
                 [
                    91.388373,
                    45.118433
                 ],
                 [
                    91.390709,
                    45.125991
                 ],
                 [
                    91.403673,
                    45.137348
                 ],
                 [
                    91.41547,
                    45.145344
                 ],
                 [
                    91.424326,
                    45.149556
                 ],
                 [
                    91.427862,
                    45.153339
                 ],
                 [
                    91.430218,
                    45.156281
                 ],
                 [
                    91.437318,
                    45.155035
                 ],
                 [
                    91.442642,
                    45.154624
                 ],
                 [
                    91.449743,
                    45.152538
                 ],
                 [
                    91.456254,
                    45.15003
                 ],
                 [
                    91.458624,
                    45.147936
                 ],
                 [
                    91.460993,
                    45.146681
                 ],
                 [
                    91.463368,
                    45.142488
                 ],
                 [
                    91.466331,
                    45.139555
                 ],
                 [
                    91.468114,
                    45.135362
                 ],
                 [
                    91.471074,
                    45.133268
                 ],
                 [
                    91.4764,
                    45.130758
                 ],
                 [
                    91.480543,
                    45.128246
                 ],
                 [
                    91.4841,
                    45.123215
                 ],
                 [
                    91.48706,
                    45.120701
                 ],
                 [
                    91.490614,
                    45.11651
                 ],
                 [
                    91.490626,
                    45.110215
                 ],
                 [
                    91.491819,
                    45.104342
                 ],
                 [
                    91.494186,
                    45.102247
                 ],
                 [
                    91.498327,
                    45.098895
                 ],
                 [
                    91.504827,
                    45.098063
                 ],
                 [
                    91.508968,
                    45.094711
                 ],
                 [
                    91.511926,
                    45.091777
                 ],
                 [
                    91.517242,
                    45.091782
                 ],
                 [
                    91.520788,
                    45.090947
                 ],
                 [
                    91.527289,
                    45.088435
                 ],
                 [
                    91.533195,
                    45.088441
                 ],
                 [
                    91.540288,
                    45.083411
                 ],
                 [
                    91.545608,
                    45.079219
                 ],
                 [
                    91.5527,
                    45.073349
                 ],
                 [
                    91.558608,
                    45.068318
                 ],
                 [
                    91.563917,
                    45.077553
                 ],
                 [
                    91.568639,
                    45.082591
                 ],
                 [
                    91.579271,
                    45.07924
                 ],
                 [
                    91.599351,
                    45.073373
                 ],
                 [
                    91.608798,
                    45.070018
                 ],
                 [
                    91.613521,
                    45.066242
                 ],
                 [
                    91.621788,
                    45.067083
                 ],
                 [
                    91.633007,
                    45.067923
                 ],
                 [
                    91.656624,
                    45.065822
                 ],
                 [
                    91.666662,
                    45.06582
                 ],
                 [
                    91.673747,
                    45.065398
                 ],
                 [
                    91.680237,
                    45.062038
                 ],
                 [
                    91.690864,
                    45.062033
                 ],
                 [
                    91.705633,
                    45.066221
                 ],
                 [
                    91.710949,
                    45.067476
                 ],
                 [
                    91.725124,
                    45.069144
                 ],
                 [
                    91.746981,
                    45.072482
                 ],
                 [
                    91.794251,
                    45.080398
                 ],
                 [
                    91.800752,
                    45.081648
                 ],
                 [
                    91.825554,
                    45.08035
                 ],
                 [
                    91.840908,
                    45.079903
                 ],
                 [
                    91.876926,
                    45.077734
                 ],
                 [
                    91.909999,
                    45.077659
                 ],
                 [
                    91.922409,
                    45.078887
                 ],
                 [
                    91.936581,
                    45.078431
                 ],
                 [
                    91.945443,
                    45.078826
                 ],
                 [
                    91.95667,
                    45.079634
                 ],
                 [
                    91.968482,
                    45.0796
                 ],
                 [
                    91.982663,
                    45.080397
                 ],
                 [
                    92.000382,
                    45.080342
                 ],
                 [
                    92.019201,
                    45.081634
                 ],
                 [
                    92.037504,
                    45.082187
                 ],
                 [
                    92.048136,
                    45.082265
                 ],
                 [
                    92.05758,
                    45.08275
                 ],
                 [
                    92.074151,
                    45.080368
                 ],
                 [
                    92.099612,
                    45.075545
                 ],
                 [
                    92.110902,
                    45.070206
                 ],
                 [
                    92.12396,
                    45.064877
                 ],
                 [
                    92.145923,
                    45.055438
                 ],
                 [
                    92.188041,
                    45.037787
                 ],
                 [
                    92.196355,
                    45.033254
                 ],
                 [
                    92.211771,
                    45.02668
                 ],
                 [
                    92.225436,
                    45.01801
                 ],
                 [
                    92.234924,
                    45.013481
                 ],
                 [
                    92.248416,
                    45.021055
                 ],
                 [
                    92.265548,
                    45.019065
                 ],
                 [
                    92.306202,
                    45.025525
                 ],
                 [
                    92.313272,
                    45.02681
                 ],
                 [
                    92.328094,
                    45.018967
                 ],
                 [
                    92.343491,
                    45.012374
                 ],
                 [
                    92.381226,
                    45.015461
                 ],
                 [
                    92.404819,
                    45.016394
                 ],
                 [
                    92.418386,
                    45.016866
                 ],
                 [
                    92.4308,
                    45.013583
                 ],
                 [
                    92.436116,
                    45.01277
                 ],
                 [
                    92.442619,
                    45.010712
                 ],
                 [
                    92.453255,
                    45.008253
                 ],
                 [
                    92.457398,
                    45.006185
                 ],
                 [
                    92.463906,
                    45.003293
                 ],
                 [
                    92.471585,
                    45.001654
                 ],
                 [
                    92.480438,
                    45.000852
                 ],
                 [
                    92.49047,
                    45.000053
                 ],
                 [
                    92.501653,
                    45.004256
                 ],
                 [
                    92.519334,
                    45.007229
                 ],
                 [
                    92.535819,
                    45.013529
                 ],
                 [
                    92.549368,
                    45.017735
                 ],
                 [
                    92.562349,
                    45.017772
                 ],
                 [
                    92.571197,
                    45.018212
                 ],
                 [
                    92.578872,
                    45.017399
                 ],
                 [
                    92.592441,
                    45.017851
                 ],
                 [
                    92.606003,
                    45.019967
                 ],
                 [
                    92.611312,
                    45.020396
                 ],
                 [
                    92.626652,
                    45.020847
                 ],
                 [
                    92.644945,
                    45.02047
                 ],
                 [
                    92.662641,
                    45.022587
                 ],
                 [
                    92.684465,
                    45.026376
                 ],
                 [
                    92.696851,
                    45.028896
                 ],
                 [
                    92.70688,
                    45.030578
                 ],
                 [
                    92.71868,
                    45.032261
                 ],
                 [
                    92.732838,
                    45.036029
                 ],
                 [
                    92.738142,
                    45.041035
                 ],
                 [
                    92.746995,
                    45.041879
                 ],
                 [
                    92.757617,
                    45.043557
                 ],
                 [
                    92.770602,
                    45.045237
                 ],
                 [
                    92.774143,
                    45.04649
                 ],
                 [
                    92.775911,
                    45.049824
                 ],
                 [
                    92.787129,
                    45.04775
                 ],
                 [
                    92.798348,
                    45.044843
                 ],
                 [
                    92.814878,
                    45.041937
                 ],
                 [
                    92.831406,
                    45.039862
                 ],
                 [
                    92.847933,
                    45.037368
                 ],
                 [
                    92.856788,
                    45.041119
                 ],
                 [
                    92.863281,
                    45.040703
                 ],
                 [
                    92.869773,
                    45.038204
                 ],
                 [
                    92.873317,
                    45.041954
                 ],
                 [
                    92.881584,
                    45.046537
                 ],
                 [
                    92.893388,
                    45.04362
                 ],
                 [
                    92.900469,
                    45.040702
                 ],
                 [
                    92.903416,
                    45.037785
                 ],
                 [
                    92.914038,
                    45.035699
                 ],
                 [
                    92.924661,
                    45.034862
                 ],
                 [
                    92.925825,
                    45.024863
                 ],
                 [
                    92.929351,
                    45.015697
                 ],
                 [
                    92.944693,
                    45.016522
                 ],
                 [
                    92.959443,
                    45.016096
                 ],
                 [
                    92.971238,
                    45.014004
                 ],
                 [
                    92.977718,
                    45.009832
                 ],
                 [
                    92.981826,
                    45.001496
                 ],
                 [
                    92.98477,
                    44.99941
                 ],
                 [
                    92.991869,
                    45.006903
                 ],
                 [
                    92.998369,
                    45.010645
                 ],
                 [
                    93.00751,
                    45.00892
                 ],
                 [
                    93.013119,
                    45.011095
                 ],
                 [
                    93.019954,
                    45.011066
                 ],
                 [
                    93.032396,
                    45.012771
                 ],
                 [
                    93.042355,
                    45.014925
                 ],
                 [
                    93.053548,
                    45.015754
                 ],
                 [
                    93.063481,
                    45.01439
                 ],
                 [
                    93.076493,
                    45.009492
                 ],
                 [
                    93.084553,
                    45.007256
                 ],
                 [
                    93.098219,
                    45.006749
                 ],
                 [
                    93.109404,
                    45.006693
                 ],
                 [
                    93.119979,
                    45.007959
                 ],
                 [
                    93.125579,
                    45.008809
                 ],
                 [
                    93.131204,
                    45.012297
                 ],
                 [
                    93.139274,
                    45.011375
                 ],
                 [
                    93.147336,
                    45.009574
                 ],
                 [
                    93.166008,
                    45.012549
                 ],
                 [
                    93.173465,
                    45.012508
                 ],
                 [
                    93.17841,
                    45.009842
                 ],
                 [
                    93.182108,
                    45.006744
                 ],
                 [
                    93.188952,
                    45.007584
                 ],
                 [
                    93.19576,
                    45.004907
                 ],
                 [
                    93.206903,
                    45.000885
                 ],
                 [
                    93.217423,
                    44.996865
                 ],
                 [
                    93.229824,
                    44.994592
                 ],
                 [
                    93.240977,
                    44.991886
                 ],
                 [
                    93.258341,
                    44.989139
                 ],
                 [
                    93.273203,
                    44.985087
                 ],
                 [
                    93.2856,
                    44.982807
                 ],
                 [
                    93.288074,
                    44.981912
                 ],
                 [
                    93.30177,
                    44.984458
                 ],
                 [
                    93.311049,
                    44.981318
                 ],
                 [
                    93.315996,
                    44.979526
                 ],
                 [
                    93.323007,
                    44.993986
                 ],
                 [
                    93.33166,
                    44.990409
                 ],
                 [
                    93.339064,
                    44.9864
                 ],
                 [
                    93.343401,
                    44.98549
                 ],
                 [
                    93.35025,
                    44.98676
                 ],
                 [
                    93.359527,
                    44.983616
                 ],
                 [
                    93.365151,
                    44.986213
                 ],
                 [
                    93.372599,
                    44.985719
                 ],
                 [
                    93.376308,
                    44.984372
                 ],
                 [
                    93.379366,
                    44.980833
                 ],
                 [
                    93.383684,
                    44.978602
                 ],
                 [
                    93.393609,
                    44.977649
                 ],
                 [
                    93.397311,
                    44.975862
                 ],
                 [
                    93.400977,
                    44.971438
                 ],
                 [
                    93.408391,
                    44.968743
                 ],
                 [
                    93.413918,
                    44.964304
                 ],
                 [
                    93.42068,
                    44.959415
                 ],
                 [
                    93.429942,
                    44.955826
                 ],
                 [
                    93.43741,
                    44.957086
                 ],
                 [
                    93.446761,
                    44.959649
                 ],
                 [
                    93.459772,
                    44.957786
                 ],
                 [
                    93.469731,
                    44.959463
                 ],
                 [
                    93.482201,
                    44.962878
                 ],
                 [
                    93.49144,
                    44.957964
                 ],
                 [
                    93.500704,
                    44.954809
                 ],
                 [
                    93.502765,
                    44.967541
                 ],
                 [
                    93.510788,
                    44.964395
                 ],
                 [
                    93.518797,
                    44.96037
                 ],
                 [
                    93.522459,
                    44.956381
                 ],
                 [
                    93.531136,
                    44.955426
                 ],
                 [
                    93.536074,
                    44.953624
                 ],
                 [
                    93.54592,
                    44.948261
                 ],
                 [
                    93.563847,
                    44.943704
                 ],
                 [
                    93.585487,
                    44.938671
                 ],
                 [
                    93.589756,
                    44.934234
                 ],
                 [
                    93.605806,
                    44.929248
                 ],
                 [
                    93.613842,
                    44.927414
                 ],
                 [
                    93.62121,
                    44.922947
                 ],
                 [
                    93.639783,
                    44.92057
                 ],
                 [
                    93.657646,
                    44.913361
                 ],
                 [
                    93.675537,
                    44.907907
                 ],
                 [
                    93.680441,
                    44.904781
                 ],
                 [
                    93.681583,
                    44.899493
                 ],
                 [
                    93.687719,
                    44.895914
                 ],
                 [
                    93.695756,
                    44.894513
                 ],
                 [
                    93.70509,
                    44.896176
                 ],
                 [
                    93.709448,
                    44.897011
                 ],
                 [
                    93.710001,
                    44.893488
                 ],
                 [
                    93.71362,
                    44.888174
                 ],
                 [
                    93.71346,
                    44.879822
                 ],
                 [
                    93.712128,
                    44.874999
                 ],
                 [
                    93.718853,
                    44.870093
                 ],
                 [
                    93.723116,
                    44.866092
                 ],
                 [
                    93.727352,
                    44.860771
                 ],
                 [
                    93.732938,
                    44.861152
                 ],
                 [
                    93.75139,
                    44.853922
                 ],
                 [
                    93.79567,
                    44.837173
                 ],
                 [
                    93.839914,
                    44.819966
                 ],
                 [
                    93.862626,
                    44.810471
                 ],
                 [
                    93.888426,
                    44.800934
                 ],
                 [
                    93.910524,
                    44.792315
                 ],
                 [
                    93.972467,
                    44.767367
                 ],
                 [
                    94.000053,
                    44.756463
                 ],
                 [
                    94.013316,
                    44.75198
                 ],
                 [
                    94.036811,
                    44.742404
                 ],
                 [
                    94.060917,
                    44.732823
                 ],
                 [
                    94.079449,
                    44.722795
                 ],
                 [
                    94.110937,
                    44.705346
                 ],
                 [
                    94.130067,
                    44.694436
                 ],
                 [
                    94.150423,
                    44.682649
                 ],
                 [
                    94.160922,
                    44.681343
                 ],
                 [
                    94.168329,
                    44.679161
                 ],
                 [
                    94.181911,
                    44.676543
                 ],
                 [
                    94.192405,
                    44.67436
                 ],
                 [
                    94.210293,
                    44.667806
                 ],
                 [
                    94.21567,
                    44.662154
                 ],
                 [
                    94.218028,
                    44.655943
                 ],
                 [
                    94.219777,
                    44.65018
                 ],
                 [
                    94.22215,
                    44.644853
                 ],
                 [
                    94.231923,
                    44.63901
                 ],
                 [
                    94.24655,
                    44.628698
                 ],
                 [
                    94.256844,
                    44.617986
                 ],
                 [
                    94.267168,
                    44.609041
                 ],
                 [
                    94.28184,
                    44.601817
                 ],
                 [
                    94.298368,
                    44.595014
                 ],
                 [
                    94.31735,
                    44.58774
                 ],
                 [
                    94.327703,
                    44.580999
                 ],
                 [
                    94.333658,
                    44.570327
                 ],
                 [
                    94.339699,
                    44.564073
                 ],
                 [
                    94.341397,
                    44.556541
                 ],
                 [
                    94.343694,
                    44.548119
                 ],
                 [
                    94.346017,
                    44.541022
                 ],
                 [
                    94.345927,
                    44.536603
                 ],
                 [
                    94.343936,
                    44.529553
                 ],
                 [
                    94.341973,
                    44.523829
                 ],
                 [
                    94.344303,
                    44.517174
                 ],
                 [
                    94.350999,
                    44.513123
                 ],
                 [
                    94.359649,
                    44.514353
                 ],
                 [
                    94.372676,
                    44.518627
                 ],
                 [
                    94.38563,
                    44.519364
                 ],
                 [
                    94.393059,
                    44.521048
                 ],
                 [
                    94.404698,
                    44.51782
                 ],
                 [
                    94.415113,
                    44.515048
                 ],
                 [
                    94.433582,
                    44.51439
                 ],
                 [
                    94.44031,
                    44.512101
                 ],
                 [
                    94.447662,
                    44.510246
                 ],
                 [
                    94.461212,
                    44.510084
                 ],
                 [
                    94.478308,
                    44.503248
                 ],
                 [
                    94.493543,
                    44.49599
                 ],
                 [
                    94.500175,
                    44.489721
                 ],
                 [
                    94.501972,
                    44.487489
                 ],
                 [
                    94.517775,
                    44.478453
                 ],
                 [
                    94.531111,
                    44.469446
                 ],
                 [
                    94.5439,
                    44.463538
                 ],
                 [
                    94.553026,
                    44.459002
                 ],
                 [
                    94.56283,
                    44.457108
                 ],
                 [
                    94.590314,
                    44.448352
                 ],
                 [
                    94.603795,
                    44.445964
                 ],
                 [
                    94.620702,
                    44.432922
                 ],
                 [
                    94.63815,
                    44.417218
                 ],
                 [
                    94.653857,
                    44.405954
                 ],
                 [
                    94.675545,
                    44.388418
                 ],
                 [
                    94.688073,
                    44.373658
                 ],
                 [
                    94.69765,
                    44.3638
                 ],
                 [
                    94.704756,
                    44.353534
                 ],
                 [
                    94.710759,
                    44.348146
                 ],
                 [
                    94.71734,
                    44.341422
                 ],
                 [
                    94.725229,
                    44.337774
                 ],
                 [
                    94.741217,
                    44.338428
                 ],
                 [
                    94.75293,
                    44.340026
                 ],
                 [
                    94.771351,
                    44.339756
                 ],
                 [
                    94.78357,
                    44.337365
                 ],
                 [
                    94.788962,
                    44.332423
                 ],
                 [
                    94.793752,
                    44.327932
                 ],
                 [
                    94.799817,
                    44.32519
                 ],
                 [
                    94.808362,
                    44.323294
                 ],
                 [
                    94.817433,
                    44.318295
                 ],
                 [
                    94.844939,
                    44.313898
                 ],
                 [
                    94.864464,
                    44.309618
                 ],
                 [
                    94.885264,
                    44.307083
                 ],
                 [
                    94.890044,
                    44.302588
                 ],
                 [
                    94.897303,
                    44.298937
                 ],
                 [
                    94.904039,
                    44.298389
                 ],
                 [
                    94.913255,
                    44.298684
                 ],
                 [
                    94.921805,
                    44.297221
                 ],
                 [
                    94.933343,
                    44.293058
                 ],
                 [
                    94.940598,
                    44.289404
                 ],
                 [
                    94.945933,
                    44.28313
                 ],
                 [
                    94.956146,
                    44.275892
                 ],
                 [
                    94.968195,
                    44.268623
                 ],
                 [
                    94.975923,
                    44.26054
                 ],
                 [
                    94.98072,
                    44.256924
                 ],
                 [
                    94.988662,
                    44.255909
                 ],
                 [
                    94.999519,
                    44.249982
                 ],
                 [
                    95.02266,
                    44.254122
                 ],
                 [
                    95.062625,
                    44.258466
                 ],
                 [
                    95.109985,
                    44.264021
                 ],
                 [
                    95.136439,
                    44.267196
                 ],
                 [
                    95.166519,
                    44.268102
                 ],
                 [
                    95.189866,
                    44.269538
                 ],
                 [
                    95.226785,
                    44.273868
                 ],
                 [
                    95.262507,
                    44.279088
                 ],
                 [
                    95.298198,
                    44.282971
                 ],
                 [
                    95.336948,
                    44.286352
                 ],
                 [
                    95.356586,
                    44.286486
                 ],
                 [
                    95.375037,
                    44.287962
                 ],
                 [
                    95.395405,
                    44.292057
                 ],
                 [
                    95.407737,
                    44.294069
                 ],
                 [
                    95.416781,
                    44.288615
                 ],
                 [
                    95.423958,
                    44.282307
                 ],
                 [
                    95.426266,
                    44.277404
                 ],
                 [
                    95.424178,
                    44.269036
                 ],
                 [
                    95.417669,
                    44.256318
                 ],
                 [
                    95.410627,
                    44.246262
                 ],
                 [
                    95.403092,
                    44.240193
                 ],
                 [
                    95.373245,
                    44.225637
                 ],
                 [
                    95.365874,
                    44.203643
                 ],
                 [
                    95.357972,
                    44.18431
                 ],
                 [
                    95.352124,
                    44.172462
                 ],
                 [
                    95.350051,
                    44.142423
                 ],
                 [
                    95.34772,
                    44.103101
                 ],
                 [
                    95.346673,
                    44.087639
                 ],
                 [
                    95.337351,
                    44.060367
                 ],
                 [
                    95.334624,
                    44.050238
                 ],
                 [
                    95.32298,
                    44.02698
                 ],
                 [
                    95.331468,
                    44.024637
                 ],
                 [
                    95.349176,
                    44.02436
                 ],
                 [
                    95.366833,
                    44.022313
                 ],
                 [
                    95.383167,
                    44.016746
                 ],
                 [
                    95.404369,
                    44.010656
                 ],
                 [
                    95.440812,
                    44.003869
                 ],
                 [
                    95.444474,
                    44.003808
                 ],
                 [
                    95.460941,
                    44.003093
                 ],
                 [
                    95.481109,
                    44.003639
                 ],
                 [
                    95.489667,
                    44.003937
                 ],
                 [
                    95.503691,
                    44.003256
                 ],
                 [
                    95.522625,
                    44.003374
                 ],
                 [
                    95.523764,
                    44.000701
                 ],
                 [
                    95.54607,
                    43.966676
                 ],
                 [
                    95.565279,
                    43.938849
                 ],
                 [
                    95.57907,
                    43.91853
                 ],
                 [
                    95.588045,
                    43.903961
                 ],
                 [
                    95.6114,
                    43.869502
                 ],
                 [
                    95.622143,
                    43.851402
                 ],
                 [
                    95.633947,
                    43.820993
                 ],
                 [
                    95.644473,
                    43.786643
                 ],
                 [
                    95.649162,
                    43.772547
                 ],
                 [
                    95.659828,
                    43.750491
                 ],
                 [
                    95.673468,
                    43.724015
                 ],
                 [
                    95.685893,
                    43.698427
                 ],
                 [
                    95.707797,
                    43.656058
                 ],
                 [
                    95.724286,
                    43.619009
                 ],
                 [
                    95.733693,
                    43.597398
                 ],
                 [
                    95.770499,
                    43.542574
                 ],
                 [
                    95.796612,
                    43.505857
                 ],
                 [
                    95.814363,
                    43.478444
                 ],
                 [
                    95.832106,
                    43.451467
                 ],
                 [
                    95.843312,
                    43.432903
                 ],
                 [
                    95.853934,
                    43.4161
                 ],
                 [
                    95.857465,
                    43.409914
                 ],
                 [
                    95.859615,
                    43.392763
                 ],
                 [
                    95.86234,
                    43.373849
                 ],
                 [
                    95.866724,
                    43.345255
                 ],
                 [
                    95.865962,
                    43.335162
                 ],
                 [
                    95.869273,
                    43.328831
                 ],
                 [
                    95.871505,
                    43.314715
                 ],
                 [
                    95.874243,
                    43.293106
                 ],
                 [
                    95.877645,
                    43.276337
                 ],
                 [
                    95.909052,
                    43.23509
                 ],
                 [
                    95.913223,
                    43.231528
                 ],
                 [
                    95.944824,
                    43.20745
                 ],
                 [
                    95.971655,
                    43.188254
                 ],
                 [
                    95.999639,
                    43.166839
                 ],
                 [
                    96.0572,
                    43.125798
                 ],
                 [
                    96.11243079999998,
                    43.08512610000001
                 ],
                 [
                    96.169431,
                    43.043123
                 ],
                 [
                    96.241686,
                    42.986763
                 ],
                 [
                    96.359415,
                    42.899481
                 ],
                 [
                    96.365426,
                    42.865092
                 ],
                 [
                    96.371431,
                    42.828939
                 ],
                 [
                    96.385808,
                    42.724006
                 ],
                 [
                    96.513655,
                    42.735271900000015
                 ],
                 [
                    96.53260640000003,
                    42.736669900000045
                 ],
                 [
                    96.59404830000001,
                    42.74188
                 ],
                 [
                    96.61083149999999,
                    42.74318240000003
                 ],
                 [
                    96.634614,
                    42.743808
                 ],
                 [
                    96.749521,
                    42.754869
                 ],
                 [
                    96.82997109999997,
                    42.754660700000024
                 ],
                 [
                    96.890717,
                    42.755098
                 ],
                 [
                    96.913451,
                    42.754977
                 ],
                 [
                    96.966091,
                    42.753796
                 ],
                 [
                    96.980475,
                    42.756357
                 ],
                 [
                    96.999673,
                    42.761531
                 ],
                 [
                    97.067136,
                    42.775389
                 ],
                 [
                    97.17259689999997,
                    42.7949997
                 ],
                 [
                    97.7792607,
                    42.71076290000002
                 ],
                 [
                    97.78931740000003,
                    42.70935690000004
                 ],
                 [
                    97.79567689999998,
                    42.70847110000002
                 ],
                 [
                    97.80013689999996,
                    42.70786130000001
                 ],
                 [
                    97.87333760000004,
                    42.69771290000001
                 ],
                 [
                    98.19227249999999,
                    42.652009600000014
                 ],
                 [
                    98.4513257,
                    42.6411193
                 ],
                 [
                    98.49419819999999,
                    42.63942079999998
                 ],
                 [
                    98.53150230000003,
                    42.6375866
                 ],
                 [
                    98.61845840000004,
                    42.631047
                 ],
                 [
                    99.05866380000002,
                    42.59793069999997
                 ],
                 [
                    99.50347660000001,
                    42.566121
                 ],
                 [
                    99.59687370000003,
                    42.57929439999997
                 ],
                 [
                    99.60432760000003,
                    42.579916199999985
                 ],
                 [
                    99.97121760000003,
                    42.64382
                 ],
                 [
                    100.14665019999998,
                    42.640341
                 ],
                 [
                    100.26780470000001,
                    42.63525840000002
                 ],
                 [
                    100.2683618,
                    42.64062570000002
                 ],
                 [
                    100.31846010000004,
                    42.686875600000036
                 ],
                 [
                    100.84508839999995,
                    42.67149950000004
                 ],
                 [
                    100.86424590000001,
                    42.669054299999964
                 ],
                 [
                    100.87748290000003,
                    42.66626569999998
                 ],
                 [
                    101.1921209,
                    42.602402499999954
                 ],
                 [
                    101.26365229999996,
                    42.5872405
                 ],
                 [
                    101.27502769999997,
                    42.58490969999997
                 ],
                 [
                    101.28823420000005,
                    42.58207919999999
                 ],
                 [
                    101.3417162,
                    42.57162689999998
                 ],
                 [
                    101.59221220000003,
                    42.52059010000002
                 ],
                 [
                    101.60300780000003,
                    42.5196173
                 ],
                 [
                    101.769443,
                    42.5029433
                 ],
                 [
                    101.78084890000004,
                    42.50170730000004
                 ],
                 [
                    101.79114860000003,
                    42.50017760000004
                 ],
                 [
                    101.79557369999998,
                    42.499231600000016
                 ],
                 [
                    101.80346249999997,
                    42.494467
                 ],
                 [
                    101.81289240000002,
                    42.486818599999964
                 ],
                 [
                    101.824428,
                    42.47640060000004
                 ],
                 [
                    102.074512,
                    42.222635500000045
                 ],
                 [
                    102.4424471,
                    42.14191270000003
                 ],
                 [
                    102.53803579999999,
                    42.158373100000034
                 ],
                 [
                    102.6207766,
                    42.1487868
                 ],
                 [
                    102.70676749999998,
                    42.148775300000025
                 ],
                 [
                    102.988399,
                    42.0314047
                 ],
                 [
                    102.9897189,
                    42.03085540000002
                 ],
                 [
                    103.38569640000003,
                    41.887989
                 ],
                 [
                    103.4517136,
                    41.875522600000025
                 ],
                 [
                    103.84377290000003,
                    41.80061339999998
                 ],
                 [
                    103.85449980000001,
                    41.800815600000014
                 ],
                 [
                    103.86399079999998,
                    41.7996483
                 ],
                 [
                    104.05312349999998,
                    41.80198289999996
                 ],
                 [
                    104.06874080000004,
                    41.80054090000002
                 ],
                 [
                    104.08838649999998,
                    41.80434420000003
                 ],
                 [
                    104.10989379999995,
                    41.80836870000004
                 ],
                 [
                    104.13200379999996,
                    41.8128357
                 ],
                 [
                    104.3425597,
                    41.84528760000001
                 ],
                 [
                    104.52493290000001,
                    41.87338259999997
                 ],
                 [
                    104.51834870000003,
                    41.66220090000003
                 ],
                 [
                    104.67765049999998,
                    41.64268489999998
                 ],
                 [
                    104.91575619999998,
                    41.652259800000046
                 ],
                 [
                    105.01120759999998,
                    41.58041760000002
                 ],
                 [
                    105.2084428,
                    41.732677400000036
                 ],
                 [
                    105.21431730000005,
                    41.73683929999996
                 ],
                 [
                    105.22872920000002,
                    41.7470322
                 ],
                 [
                    105.2837982,
                    41.74484629999997
                 ],
                 [
                    105.317337,
                    41.7612801
                 ],
                 [
                    105.61383819999998,
                    41.89830780000003
                 ],
                 [
                    105.62973020000003,
                    41.905345899999986
                 ],
                 [
                    105.65614319999999,
                    41.91425710000003
                 ],
                 [
                    105.69693759999998,
                    41.9309082
                 ],
                 [
                    105.7339096,
                    41.9428062
                 ],
                 [
                    106.028224,
                    42.03852569999997
                 ],
                 [
                    106.50708770000004,
                    42.19395829999996
                 ],
                 [
                    106.55931090000001,
                    42.218952200000025
                 ],
                 [
                    106.72078759999997,
                    42.27058620000001
                 ],
                 [
                    106.78600309999997,
                    42.29142760000004
                 ],
                 [
                    106.993187,
                    42.30996319999998
                 ],
                 [
                    107.02086640000002,
                    42.313034100000046
                 ],
                 [
                    107.04105380000003,
                    42.31425860000002
                 ],
                 [
                    107.25698090000003,
                    42.36276250000002
                 ],
                 [
                    107.26486210000002,
                    42.37029649999997
                 ],
                 [
                    107.29280850000004,
                    42.40811160000002
                 ],
                 [
                    107.29855350000003,
                    42.40985109999997
                 ],
                 [
                    107.45317839999998,
                    42.45666119999998
                 ],
                 [
                    107.49616240000005,
                    42.45497889999996
                 ],
                 [
                    107.56593320000002,
                    42.41157910000004
                 ],
                 [
                    107.569226,
                    42.41158909999998
                 ],
                 [
                    107.72736360000005,
                    42.412067400000026
                 ],
                 [
                    107.77747349999996,
                    42.41070940000002
                 ],
                 [
                    107.82879639999996,
                    42.40928649999997
                 ],
                 [
                    107.87625120000003,
                    42.4054642
                 ],
                 [
                    107.91879269999998,
                    42.4022675
                 ],
                 [
                    107.92793270000004,
                    42.40444180000004
                 ],
                 [
                    107.950737,
                    42.411602
                 ],
                 [
                    107.979599,
                    42.4123344
                 ],
                 [
                    108,
                    42.42480849999996
                 ],
                 [
                    108.00282290000001,
                    42.425621
                 ],
                 [
                    108.01672359999998,
                    42.431339299999955
                 ],
                 [
                    108.029068,
                    42.431972500000036
                 ],
                 [
                    108.045433,
                    42.43273159999996
                 ],
                 [
                    108.05715939999996,
                    42.433338199999966
                 ],
                 [
                    108.084137,
                    42.43787
                 ],
                 [
                    108.106369,
                    42.439041100000026
                 ],
                 [
                    108.12423710000004,
                    42.44052509999998
                 ],
                 [
                    108.14208979999995,
                    42.4424629
                 ],
                 [
                    108.16359710000002,
                    42.44543079999998
                 ],
                 [
                    108.1715469,
                    42.447052
                 ],
                 [
                    108.18534090000001,
                    42.44976810000003
                 ],
                 [
                    108.1991272,
                    42.452259099999985
                 ],
                 [
                    108.21015930000003,
                    42.454433400000035
                 ],
                 [
                    108.22302250000003,
                    42.457119
                 ],
                 [
                    108.23310850000001,
                    42.4594879
                 ],
                 [
                    108.24079130000003,
                    42.46064
                 ],
                 [
                    108.25302890000005,
                    42.4575844
                 ],
                 [
                    108.25971219999995,
                    42.454357100000024
                 ],
                 [
                    108.27245329999998,
                    42.4476585
                 ],
                 [
                    108.279213,
                    42.443058
                 ],
                 [
                    108.28594209999996,
                    42.43868639999999
                 ],
                 [
                    108.29316710000002,
                    42.43661879999998
                 ],
                 [
                    108.30621339999998,
                    42.43587110000002
                 ],
                 [
                    108.3145599,
                    42.4361229
                 ],
                 [
                    108.33712009999996,
                    42.43680190000003
                 ],
                 [
                    108.35752109999997,
                    42.43740840000003
                 ],
                 [
                    108.37605290000003,
                    42.4381905
                 ],
                 [
                    108.3939667,
                    42.438716900000024
                 ],
                 [
                    108.4134598,
                    42.4390602
                 ],
                 [
                    108.42894739999996,
                    42.43882750000003
                 ],
                 [
                    108.4345169,
                    42.4387627
                 ],
                 [
                    108.45835880000004,
                    42.4387627
                 ],
                 [
                    108.477829,
                    42.43932719999999
                 ],
                 [
                    108.49633029999997,
                    42.44076920000001
                 ],
                 [
                    108.51365660000003,
                    42.4410362
                 ],
                 [
                    108.52757260000001,
                    42.44120029999998
                 ],
                 [
                    108.5390472,
                    42.44061280000004
                 ],
                 [
                    108.5524368,
                    42.439159400000044
                 ],
                 [
                    108.56490330000004,
                    42.4374466
                 ],
                 [
                    108.59367370000002,
                    42.43138889999997
                 ],
                 [
                    108.6183167,
                    42.427265200000015
                 ],
                 [
                    108.64299770000002,
                    42.4226799
                 ],
                 [
                    108.6561127,
                    42.4202919
                 ],
                 [
                    108.67359919999998,
                    42.41687770000004
                 ],
                 [
                    108.68582149999997,
                    42.41355129999997
                 ],
                 [
                    108.69989779999999,
                    42.4102669
                 ],
                 [
                    108.71601869999996,
                    42.4095573
                 ],
                 [
                    108.7320328,
                    42.411125199999965
                 ],
                 [
                    108.74033359999999,
                    42.412262
                 ],
                 [
                    108.76531220000001,
                    42.4140625
                 ],
                 [
                    108.78289789999997,
                    42.41498180000003
                 ],
                 [
                    108.79563139999998,
                    42.413936599999964
                 ],
                 [
                    108.806633,
                    42.4103355
                 ],
                 [
                    108.8141937,
                    42.407329599999976
                 ],
                 [
                    108.82051089999999,
                    42.4042931
                 ],
                 [
                    108.8230972,
                    42.401844
                 ],
                 [
                    108.82914730000002,
                    42.39788060000002
                 ],
                 [
                    108.83853150000003,
                    42.395607
                 ],
                 [
                    108.8473969,
                    42.397892
                 ],
                 [
                    108.85221859999999,
                    42.40076070000003
                 ],
                 [
                    108.9210205,
                    42.42604829999996
                 ],
                 [
                    108.94139859999997,
                    42.43409729999998
                 ],
                 [
                    108.95995329999997,
                    42.4416428
                 ],
                 [
                    108.97154239999998,
                    42.44558720000004
                 ],
                 [
                    108.9776688,
                    42.44710920000003
                 ],
                 [
                    108.98351290000002,
                    42.447937
                 ],
                 [
                    108.99157709999997,
                    42.44744869999997
                 ],
                 [
                    108.9966278,
                    42.445282
                 ],
                 [
                    108.9999096,
                    42.445311200000035
                 ],
                 [
                    109.00420109999999,
                    42.44654470000004
                 ],
                 [
                    109.01095920000004,
                    42.44808390000003
                 ],
                 [
                    109.01561179999997,
                    42.449229
                 ],
                 [
                    109.0200733,
                    42.450308300000046
                 ],
                 [
                    109.02440640000005,
                    42.45418550000004
                 ],
                 [
                    109.03372189999996,
                    42.4537201
                 ],
                 [
                    109.0415176,
                    42.452606400000036
                 ],
                 [
                    109.0683048,
                    42.45188080000004
                 ],
                 [
                    109.10613470000004,
                    42.449038499999965
                 ],
                 [
                    109.16330970000001,
                    42.4444649
                 ],
                 [
                    109.17078669999997,
                    42.44387270000003
                 ],
                 [
                    109.19763020000002,
                    42.4417524
                 ],
                 [
                    109.20752220000003,
                    42.44114669999998
                 ],
                 [
                    109.22406610000002,
                    42.439696199999965
                 ],
                 [
                    109.23760590000005,
                    42.4382654
                 ],
                 [
                    109.2530983,
                    42.43657489999999
                 ],
                 [
                    109.26423480000003,
                    42.43543310000002
                 ],
                 [
                    109.2713277,
                    42.4350657
                 ],
                 [
                    109.27977019999997,
                    42.43472050000003
                 ],
                 [
                    109.28394320000001,
                    42.4345055
                 ],
                 [
                    109.2968369,
                    42.43684390000004
                 ],
                 [
                    109.31623080000004,
                    42.4395485
                 ],
                 [
                    109.33290860000001,
                    42.44036480000004
                 ],
                 [
                    109.347438,
                    42.44309279999998
                 ],
                 [
                    109.3643038,
                    42.444624
                 ],
                 [
                    109.37642729999999,
                    42.445766499999955
                 ],
                 [
                    109.38223060000001,
                    42.4462842
                 ],
                 [
                    109.38414030000001,
                    42.44629289999999
                 ],
                 [
                    109.3905561,
                    42.447087
                 ],
                 [
                    109.4100037,
                    42.4479256
                 ],
                 [
                    109.42601780000001,
                    42.450080899999975
                 ],
                 [
                    109.4414368,
                    42.451313
                 ],
                 [
                    109.45717620000005,
                    42.452549
                 ],
                 [
                    109.46398160000003,
                    42.4529152
                 ],
                 [
                    109.469223,
                    42.453476
                 ],
                 [
                    109.475647,
                    42.45566179999997
                 ],
                 [
                    109.48018650000003,
                    42.458499899999964
                 ],
                 [
                    109.48593140000001,
                    42.462497699999986
                 ],
                 [
                    109.49539180000002,
                    42.466346700000045
                 ],
                 [
                    109.5058975,
                    42.46701049999999
                 ],
                 [
                    109.51394650000002,
                    42.46694179999999
                 ],
                 [
                    109.521698,
                    42.46664050000002
                 ],
                 [
                    109.52848049999997,
                    42.467456799999965
                 ],
                 [
                    109.53883360000003,
                    42.47269060000003
                 ],
                 [
                    109.543663,
                    42.47598270000001
                 ],
                 [
                    109.5600433,
                    42.48613360000004
                 ],
                 [
                    109.567627,
                    42.49062350000001
                 ],
                 [
                    109.59066769999997,
                    42.50569920000002
                 ],
                 [
                    109.6022034,
                    42.512775400000024
                 ],
                 [
                    109.61834720000002,
                    42.520855
                 ],
                 [
                    109.63907619999998,
                    42.53130340000004
                 ],
                 [
                    109.65426639999995,
                    42.5409584
                 ],
                 [
                    109.66882320000002,
                    42.55128479999996
                 ],
                 [
                    109.6933136,
                    42.56064989999999
                 ],
                 [
                    109.70865629999997,
                    42.56572719999998
                 ],
                 [
                    109.72273249999998,
                    42.57169339999997
                 ],
                 [
                    109.74514010000001,
                    42.57895280000003
                 ],
                 [
                    109.7703629,
                    42.58602519999999
                 ],
                 [
                    109.78539880000001,
                    42.5929298
                 ],
                 [
                    109.8084014,
                    42.60091140000004
                 ],
                 [
                    109.824028,
                    42.60615539999996
                 ],
                 [
                    109.8574829,
                    42.618606599999985
                 ],
                 [
                    109.87596889999998,
                    42.623027800000045
                 ],
                 [
                    109.89134980000003,
                    42.62762449999997
                 ],
                 [
                    109.90337370000003,
                    42.630565599999954
                 ],
                 [
                    109.917923,
                    42.63194270000003
                 ],
                 [
                    109.92910770000005,
                    42.6312103
                 ],
                 [
                    110.10083009999997,
                    42.63910680000002
                 ],
                 [
                    110.1032715,
                    42.64074329999998
                 ],
                 [
                    110.10991670000004,
                    42.64792249999998
                 ],
                 [
                    110.12838750000003,
                    42.6667061
                 ],
                 [
                    110.1363297,
                    42.67207719999997
                 ],
                 [
                    110.14742280000002,
                    42.675663
                 ],
                 [
                    110.15235140000004,
                    42.677105
                 ],
                 [
                    110.16776280000003,
                    42.681896199999976
                 ],
                 [
                    110.18936160000001,
                    42.68767930000001
                 ],
                 [
                    110.1992264,
                    42.69078830000004
                 ],
                 [
                    110.21093749999997,
                    42.694835699999985
                 ],
                 [
                    110.25196840000001,
                    42.70704650000001
                 ],
                 [
                    110.27263639999998,
                    42.71371840000004
                 ],
                 [
                    110.293602,
                    42.720848100000026
                 ],
                 [
                    110.30808260000005,
                    42.72674939999998
                 ],
                 [
                    110.3188477,
                    42.73168560000002
                 ],
                 [
                    110.32563779999995,
                    42.73382950000004
                 ],
                 [
                    110.32739260000002,
                    42.7345009
                 ],
                 [
                    110.331192,
                    42.73595810000003
                 ],
                 [
                    110.3339767,
                    42.73599240000003
                 ],
                 [
                    110.34631349999995,
                    42.7414055
                 ],
                 [
                    110.35185240000004,
                    42.74375919999997
                 ],
                 [
                    110.36048129999999,
                    42.7472954
                 ],
                 [
                    110.37065119999998,
                    42.75130459999998
                 ],
                 [
                    110.37373350000001,
                    42.7524834
                 ],
                 [
                    110.38484949999997,
                    42.756279
                 ],
                 [
                    110.39132689999998,
                    42.758873
                 ],
                 [
                    110.4033508,
                    42.763813
                 ],
                 [
                    110.41567990000004,
                    42.769218399999986
                 ],
                 [
                    110.42215729999997,
                    42.77180859999998
                 ],
                 [
                    110.429863,
                    42.77555850000001
                 ],
                 [
                    110.43293,
                    42.777195
                 ],
                 [
                    110.43622069999998,
                    42.78190690000001
                 ],
                 [
                    110.4402344,
                    42.789322500000026
                 ],
                 [
                    110.446736,
                    42.8009111
                 ],
                 [
                    110.4498903,
                    42.806876199999984
                 ],
                 [
                    110.45417109999998,
                    42.81445879999996
                 ],
                 [
                    110.45747450000002,
                    42.82075230000004
                 ],
                 [
                    110.4641264,
                    42.832582899999956
                 ],
                 [
                    110.46879449999996,
                    42.83619570000001
                 ],
                 [
                    110.4780288,
                    42.84188359999998
                 ],
                 [
                    110.48353590000002,
                    42.84525920000004
                 ],
                 [
                    110.49342790000003,
                    42.8515063
                 ],
                 [
                    110.51587680000002,
                    42.865440399999976
                 ],
                 [
                    110.52664179999996,
                    42.87310030000004
                 ],
                 [
                    110.55804019999997,
                    42.89199720000003
                 ],
                 [
                    110.6155403,
                    42.928043700000025
                 ],
                 [
                    110.6240118,
                    42.93278840000003
                 ],
                 [
                    110.62926460000003,
                    42.93926069999998
                 ],
                 [
                    110.63281369999996,
                    42.9443124
                 ],
                 [
                    110.64331510000004,
                    42.959584299999975
                 ],
                 [
                    110.65086360000004,
                    42.97037510000001
                 ],
                 [
                    110.65512090000003,
                    42.977272
                 ],
                 [
                    110.66152950000004,
                    42.98601530000001
                 ],
                 [
                    110.66977690000004,
                    42.9968376
                 ],
                 [
                    110.67835240000002,
                    43.006977099999965
                 ],
                 [
                    110.68170930000004,
                    43.0113487
                 ],
                 [
                    110.6835403,
                    43.0141106
                 ],
                 [
                    110.6850204,
                    43.01937869999996
                 ],
                 [
                    110.68492889999997,
                    43.0250931
                 ],
                 [
                    110.68296810000001,
                    43.03055569999998
                 ],
                 [
                    110.68226620000002,
                    43.03512189999996
                 ],
                 [
                    110.68654630000003,
                    43.04019170000004
                 ],
                 [
                    110.6936722,
                    43.04460140000003
                 ],
                 [
                    110.70929719999997,
                    43.06371310000003
                 ],
                 [
                    110.7188568,
                    43.0715714
                 ],
                 [
                    110.72841640000004,
                    43.07988360000003
                 ],
                 [
                    110.73304749999997,
                    43.084041599999985
                 ],
                 [
                    110.7407913,
                    43.0884514
                 ],
                 [
                    110.74887090000004,
                    43.09149549999997
                 ],
                 [
                    110.75881960000004,
                    43.0947838
                 ],
                 [
                    110.7668762,
                    43.099884
                 ],
                 [
                    110.77115629999999,
                    43.10563279999997
                 ],
                 [
                    110.78012090000001,
                    43.11325070000002
                 ],
                 [
                    110.78659820000001,
                    43.11924739999997
                 ],
                 [
                    110.7918472,
                    43.123634300000035
                 ],
                 [
                    110.79927829999997,
                    43.1291847
                 ],
                 [
                    110.8014374,
                    43.13125990000002
                 ],
                 [
                    110.80603789999998,
                    43.13769530000002
                 ],
                 [
                    110.8137283,
                    43.146900200000026
                 ],
                 [
                    110.82080840000005,
                    43.154956799999965
                 ],
                 [
                    110.8248215,
                    43.15887450000002
                 ],
                 [
                    110.83070369999997,
                    43.163951900000015
                 ],
                 [
                    110.837822,
                    43.169494599999965
                 ],
                 [
                    110.848671,
                    43.17734909999997
                 ],
                 [
                    110.85669710000005,
                    43.1851845
                 ],
                 [
                    110.87027740000005,
                    43.19991680000001
                 ],
                 [
                    110.8786316,
                    43.20797730000001
                 ],
                 [
                    110.88883969999998,
                    43.217197400000025
                 ],
                 [
                    110.90216830000003,
                    43.22826770000001
                 ],
                 [
                    110.91239930000002,
                    43.236801099999965
                 ],
                 [
                    110.93190769999997,
                    43.25568009999998
                 ],
                 [
                    110.98059079999996,
                    43.301040600000036
                 ],
                 [
                    110.98741909999997,
                    43.30702969999997
                 ],
                 [
                    110.9961166,
                    43.314170800000014
                 ],
                 [
                    111.006073,
                    43.32200620000004
                 ],
                 [
                    111.01605220000005,
                    43.3275566
                 ],
                 [
                    111.028862,
                    43.33243939999998
                 ],
                 [
                    111.03574369999995,
                    43.334999100000026
                 ],
                 [
                    111.04789730000005,
                    43.341930400000024
                 ],
                 [
                    111.0654068,
                    43.3484383
                 ],
                 [
                    111.08010859999997,
                    43.35378269999996
                 ],
                 [
                    111.09636690000005,
                    43.3598213
                 ],
                 [
                    111.10732269999997,
                    43.36354059999998
                 ],
                 [
                    111.11889650000005,
                    43.36749270000004
                 ],
                 [
                    111.127037,
                    43.3700523
                 ],
                 [
                    111.14080809999999,
                    43.374469800000014
                 ],
                 [
                    111.146759,
                    43.377018
                 ],
                 [
                    111.15270230000002,
                    43.3793335
                 ],
                 [
                    111.15386959999996,
                    43.37969970000002
                 ],
                 [
                    111.16898350000002,
                    43.384449
                 ],
                 [
                    111.18370820000004,
                    43.38909529999999
                 ],
                 [
                    111.202179,
                    43.39558790000002
                 ],
                 [
                    111.21314239999995,
                    43.39929580000003
                 ],
                 [
                    111.22693630000005,
                    43.40302280000003
                 ],
                 [
                    111.24291990000005,
                    43.40789410000003
                 ],
                 [
                    111.25921629999998,
                    43.413227100000036
                 ],
                 [
                    111.2767868,
                    43.41741560000003
                 ],
                 [
                    111.29311369999998,
                    43.419998200000016
                 ],
                 [
                    111.309433,
                    43.422123
                 ],
                 [
                    111.32700350000005,
                    43.42516710000004
                 ],
                 [
                    111.34488680000001,
                    43.43072129999996
                 ],
                 [
                    111.3578732,
                    43.438917
                 ],
                 [
                    111.36054230000003,
                    43.440601299999955
                 ],
                 [
                    111.36617279999997,
                    43.446792600000016
                 ],
                 [
                    111.37021640000005,
                    43.4522896
                 ],
                 [
                    111.37803649999996,
                    43.45962910000003
                 ],
                 [
                    111.38963319999998,
                    43.4699478
                 ],
                 [
                    111.40155030000004,
                    43.476387
                 ],
                 [
                    111.42038730000004,
                    43.4819298
                 ],
                 [
                    111.4379883,
                    43.486324299999985
                 ],
                 [
                    111.45337679999997,
                    43.489337900000024
                 ],
                 [
                    111.4647064,
                    43.4900589
                 ],
                 [
                    111.47696689999995,
                    43.48871989999996
                 ],
                 [
                    111.4939728,
                    43.487167400000025
                 ],
                 [
                    111.51629639999999,
                    43.48653789999999
                 ],
                 [
                    111.5288773,
                    43.48748019999997
                 ],
                 [
                    111.54145809999999,
                    43.4870491
                 ],
                 [
                    111.55278020000002,
                    43.4870758
                 ],
                 [
                    111.5637894,
                    43.48823929999998
                 ],
                 [
                    111.57290650000003,
                    43.49077220000004
                 ],
                 [
                    111.58704380000003,
                    43.50038910000002
                 ],
                 [
                    111.60495760000003,
                    43.513443
                 ],
                 [
                    111.61721039999998,
                    43.52351
                 ],
                 [
                    111.6297913,
                    43.53609469999997
                 ],
                 [
                    111.64740750000004,
                    43.55005260000004
                 ],
                 [
                    111.6508636,
                    43.552341499999976
                 ],
                 [
                    111.665657,
                    43.565609
                 ],
                 [
                    111.67825319999996,
                    43.57658390000003
                 ],
                 [
                    111.691803,
                    43.587108599999986
                 ],
                 [
                    111.70030209999997,
                    43.59396740000003
                 ],
                 [
                    111.714798,
                    43.60608289999998
                 ],
                 [
                    111.72551730000005,
                    43.61591339999997
                 ],
                 [
                    111.7416,
                    43.62802890000001
                 ],
                 [
                    111.75264740000003,
                    43.63785550000003
                 ],
                 [
                    111.76368709999996,
                    43.648136100000045
                 ],
                 [
                    111.776001,
                    43.65887450000001
                 ],
                 [
                    111.78768160000001,
                    43.66641619999998
                 ],
                 [
                    111.7990417,
                    43.669612899999976
                 ],
                 [
                    111.811348,
                    43.6698418
                 ],
                 [
                    111.83911129999998,
                    43.670517
                 ],
                 [
                    111.85897830000003,
                    43.67050929999998
                 ],
                 [
                    111.87348940000004,
                    43.67072680000003
                 ],
                 [
                    111.87980650000003,
                    43.67118070000002
                 ],
                 [
                    111.88416289999996,
                    43.67324070000002
                 ],
                 [
                    111.88967829999997,
                    43.674521599999956
                 ],
                 [
                    111.90094949999997,
                    43.67873309999999
                 ],
                 [
                    111.909576,
                    43.681921
                 ],
                 [
                    111.92240980000004,
                    43.68667119999998
                 ],
                 [
                    111.92386139999998,
                    43.6870428
                 ],
                 [
                    111.9304591,
                    43.687877599999965
                 ],
                 [
                    111.93786009999997,
                    43.68883419999998
                 ],
                 [
                    111.94155760000001,
                    43.689347
                 ],
                 [
                    111.94623940000004,
                    43.68999630000003
                 ],
                 [
                    111.9461637,
                    43.6900421
                 ],
                 [
                    111.946458,
                    43.690293099999984
                 ],
                 [
                    111.94659059999996,
                    43.690384199999976
                 ],
                 [
                    111.94654129999998,
                    43.69054930000004
                 ],
                 [
                    111.9466842,
                    43.690646600000015
                 ],
                 [
                    111.94697050000005,
                    43.69075329999996
                 ],
                 [
                    111.947909,
                    43.69260790000002
                 ],
                 [
                    111.951184,
                    43.70178749999997
                 ],
                 [
                    111.95314739999998,
                    43.707454099999964
                 ],
                 [
                    111.95758110000001,
                    43.71963029999996
                 ],
                 [
                    111.95973060000004,
                    43.72560030000001
                 ],
                 [
                    111.96260429999997,
                    43.7338453
                 ],
                 [
                    111.9632207,
                    43.73550349999997
                 ],
                 [
                    111.96487779999995,
                    43.73930879999996
                 ],
                 [
                    111.9665527,
                    43.741210899999956
                 ],
                 [
                    111.96781919999997,
                    43.7439461
                 ],
                 [
                    111.96764380000002,
                    43.744471900000036
                 ],
                 [
                    111.963768,
                    43.75629040000002
                 ],
                 [
                    111.9637909,
                    43.762455
                 ],
                 [
                    111.9647827,
                    43.772045099999985
                 ],
                 [
                    111.9632263,
                    43.7789001
                 ],
                 [
                    111.96106719999997,
                    43.788723
                 ],
                 [
                    111.96015929999999,
                    43.79900359999997
                 ],
                 [
                    111.96019740000003,
                    43.807910900000046
                 ],
                 [
                    111.96022029999997,
                    43.812252
                 ],
                 [
                    111.95897670000002,
                    43.8191032
                 ],
                 [
                    111.95458979999995,
                    43.82756040000004
                 ],
                 [
                    111.952713,
                    43.829616499999986
                 ],
                 [
                    111.9416885,
                    43.84310910000002
                 ],
                 [
                    111.93821720000003,
                    43.8469925
                 ],
                 [
                    111.93224330000001,
                    43.85362629999998
                 ],
                 [
                    111.9256287,
                    43.86276630000003
                 ],
                 [
                    111.91996,
                    43.8703079
                 ],
                 [
                    111.9139709,
                    43.8783073
                 ],
                 [
                    111.906723,
                    43.886310599999966
                 ],
                 [
                    111.9001083,
                    43.896591199999975
                 ],
                 [
                    111.89411929999997,
                    43.90550609999997
                 ],
                 [
                    111.89034270000005,
                    43.909389500000024
                 ],
                 [
                    111.88307950000004,
                    43.919216199999966
                 ],
                 [
                    111.87803650000004,
                    43.926754
                 ],
                 [
                    111.87393189999997,
                    43.9322395
                 ],
                 [
                    111.8698273,
                    43.9365807
                 ],
                 [
                    111.86224370000002,
                    43.94435119999997
                 ],
                 [
                    111.84896090000004,
                    43.95440289999996
                 ],
                 [
                    111.8423233,
                    43.958290099999985
                 ],
                 [
                    111.83029169999998,
                    43.9651451
                 ],
                 [
                    111.81508639999998,
                    43.9740562
                 ],
                 [
                    111.80148320000004,
                    43.98547360000002
                 ],
                 [
                    111.78819270000002,
                    43.9959793
                 ],
                 [
                    111.778183,
                    44.000007599999975
                 ],
                 [
                    111.76586150000001,
                    44.006073
                 ],
                 [
                    111.75420380000001,
                    44.00878140000002
                 ],
                 [
                    111.74355320000004,
                    44.01306919999996
                 ],
                 [
                    111.735733,
                    44.016628300000015
                 ],
                 [
                    111.72885890000005,
                    44.0201683
                 ],
                 [
                    111.71753690000003,
                    44.0235519
                 ],
                 [
                    111.70284270000005,
                    44.03064350000003
                 ],
                 [
                    111.6931381,
                    44.03536990000003
                 ],
                 [
                    111.68598940000004,
                    44.040287
                 ],
                 [
                    111.67817690000004,
                    44.0445251
                 ],
                 [
                    111.67108919999998,
                    44.05126570000004
                 ],
                 [
                    111.664238,
                    44.0557137
                 ],
                 [
                    111.65426640000001,
                    44.06204219999996
                 ],
                 [
                    111.64933779999998,
                    44.066917400000015
                 ],
                 [
                    111.64531710000001,
                    44.070407899999985
                 ],
                 [
                    111.63979340000003,
                    44.07643509999996
                 ],
                 [
                    111.631813,
                    44.085926100000016
                 ],
                 [
                    111.62254329999998,
                    44.09452440000001
                 ],
                 [
                    111.61699679999998,
                    44.10055159999999
                 ],
                 [
                    111.60922240000002,
                    44.10615919999998
                 ],
                 [
                    111.60462189999996,
                    44.11170960000003
                 ],
                 [
                    111.6016083,
                    44.11724090000002
                 ],
                 [
                    111.599617,
                    44.124813100000026
                 ],
                 [
                    111.59501650000004,
                    44.130825
                 ],
                 [
                    111.58851619999999,
                    44.1366348
                 ],
                 [
                    111.583847,
                    44.14013289999998
                 ],
                 [
                    111.57887270000003,
                    44.143867499999985
                 ],
                 [
                    111.57422639999999,
                    44.14804839999997
                 ],
                 [
                    111.56864170000003,
                    44.15293120000003
                 ],
                 [
                    111.561882,
                    44.1610298
                 ],
                 [
                    111.55914309999997,
                    44.1654129
                 ],
                 [
                    111.556427,
                    44.17047880000002
                 ],
                 [
                    111.55119319999999,
                    44.176269499999975
                 ],
                 [
                    111.54811859999997,
                    44.1801987
                 ],
                 [
                    111.54290770000003,
                    44.18690490000003
                 ],
                 [
                    111.53923030000004,
                    44.19175719999997
                 ],
                 [
                    111.53650670000002,
                    44.19682309999999
                 ],
                 [
                    111.534729,
                    44.201190899999986
                 ],
                 [
                    111.53270719999998,
                    44.20830540000004
                 ],
                 [
                    111.5287476,
                    44.21453479999997
                 ],
                 [
                    111.52700040000003,
                    44.22027210000003
                 ],
                 [
                    111.5261765,
                    44.22485350000002
                 ],
                 [
                    111.5247574,
                    44.230587
                 ],
                 [
                    111.52366639999997,
                    44.237228400000035
                 ],
                 [
                    111.52065280000002,
                    44.24298860000004
                 ],
                 [
                    111.51923370000002,
                    44.248951
                 ],
                 [
                    111.52037050000001,
                    44.2551041
                 ],
                 [
                    111.52277369999999,
                    44.2610092
                 ],
                 [
                    111.52195740000005,
                    44.26581960000002
                 ],
                 [
                    111.51923370000002,
                    44.2708893
                 ],
                 [
                    111.51210019999998,
                    44.2778473
                 ],
                 [
                    111.50753779999997,
                    44.2854538
                 ],
                 [
                    111.50260160000002,
                    44.29123690000003
                 ],
                 [
                    111.49542240000002,
                    44.29682540000004
                 ],
                 [
                    111.4894409,
                    44.299652099999975
                 ],
                 [
                    111.47738650000004,
                    44.30256270000001
                 ],
                 [
                    111.4669113,
                    44.304081
                 ],
                 [
                    111.45678709999996,
                    44.30719380000003
                 ],
                 [
                    111.4507599,
                    44.308647200000024
                 ],
                 [
                    111.44226070000002,
                    44.31287770000003
                 ],
                 [
                    111.43557740000001,
                    44.313427
                 ],
                 [
                    111.425148,
                    44.31745150000001
                 ],
                 [
                    111.41861720000004,
                    44.3239403
                 ],
                 [
                    111.41657259999998,
                    44.330593100000044
                 ],
                 [
                    111.4123306,
                    44.3388786
                 ],
                 [
                    111.40928650000002,
                    44.344635
                 ],
                 [
                    111.40973660000004,
                    44.3494263
                 ],
                 [
                    111.41027070000001,
                    44.356960299999955
                 ],
                 [
                    111.41407779999997,
                    44.36765290000004
                 ],
                 [
                    111.41487119999998,
                    44.3733521
                 ],
                 [
                    111.41663359999997,
                    44.379043599999974
                 ],
                 [
                    111.41835779999998,
                    44.38404850000001
                 ],
                 [
                    111.42180630000003,
                    44.3931427
                 ],
                 [
                    111.42489619999998,
                    44.400871299999984
                 ],
                 [
                    111.42756650000004,
                    44.404949199999976
                 ],
                 [
                    111.4312363,
                    44.41061020000003
                 ],
                 [
                    111.4359283,
                    44.41831589999998
                 ],
                 [
                    111.44158169999997,
                    44.42601010000004
                 ],
                 [
                    111.44429779999999,
                    44.43168640000003
                 ],
                 [
                    111.44635009999998,
                    44.4364586
                 ],
                 [
                    111.44840240000005,
                    44.44123079999997
                 ],
                 [
                    111.4534073,
                    44.44870380000003
                 ],
                 [
                    111.45644379999997,
                    44.45391850000002
                 ],
                 [
                    111.45918270000001,
                    44.4600487
                 ],
                 [
                    111.46125030000003,
                    44.46573640000004
                 ],
                 [
                    111.46365360000001,
                    44.47141649999999
                 ],
                 [
                    111.46897890000001,
                    44.478653
                 ],
                 [
                    111.4745789,
                    44.484062200000025
                 ],
                 [
                    111.48303219999998,
                    44.48874279999997
                 ],
                 [
                    111.4885864,
                    44.49254990000002
                 ],
                 [
                    111.49577330000001,
                    44.4974747
                 ],
                 [
                    111.50105290000003,
                    44.502658800000034
                 ],
                 [
                    111.50826259999998,
                    44.5084991
                 ],
                 [
                    111.51158140000004,
                    44.51256560000003
                 ],
                 [
                    111.51435090000004,
                    44.5196114
                 ],
                 [
                    111.51785279999999,
                    44.5293884
                 ],
                 [
                    111.52001950000002,
                    44.53804019999998
                 ],
                 [
                    111.52378850000002,
                    44.54644390000004
                 ],
                 [
                    111.52908329999998,
                    44.552082099999986
                 ],
                 [
                    111.54010009999998,
                    44.55603410000004
                 ],
                 [
                    111.54917140000003,
                    44.55933
                 ],
                 [
                    111.55474089999998,
                    44.56313319999998
                 ],
                 [
                    111.5610733,
                    44.57127
                 ],
                 [
                    111.56318659999997,
                    44.57764050000004
                 ],
                 [
                    111.56316380000003,
                    44.58700940000002
                 ],
                 [
                    111.56170649999997,
                    44.59251789999998
                 ],
                 [
                    111.55906679999997,
                    44.600555400000026
                 ],
                 [
                    111.5585938,
                    44.606048599999966
                 ],
                 [
                    111.55841060000003,
                    44.6106186
                 ],
                 [
                    111.5583115,
                    44.6179352
                 ],
                 [
                    111.55651089999996,
                    44.62230680000003
                 ],
                 [
                    111.55570980000003,
                    44.6275749
                 ],
                 [
                    111.5543137,
                    44.63445280000002
                 ],
                 [
                    111.55349730000002,
                    44.639720899999986
                 ],
                 [
                    111.55335240000002,
                    44.645435300000024
                 ],
                 [
                    111.557518,
                    44.6556625
                 ],
                 [
                    111.55933380000003,
                    44.6627197
                 ],
                 [
                    111.5611496,
                    44.66977690000002
                 ],
                 [
                    111.56497959999999,
                    44.6795502
                 ],
                 [
                    111.5684128,
                    44.68658449999998
                 ],
                 [
                    111.57151029999997,
                    44.693622600000026
                 ],
                 [
                    111.57389830000002,
                    44.69815829999997
                 ],
                 [
                    111.57826229999998,
                    44.7042656
                 ],
                 [
                    111.5826874,
                    44.712429
                 ],
                 [
                    111.586113,
                    44.71900560000004
                 ],
                 [
                    111.58883670000004,
                    44.72422030000003
                 ],
                 [
                    111.59294890000002,
                    44.7326164
                 ],
                 [
                    111.59860229999998,
                    44.738704699999964
                 ],
                 [
                    111.60170749999996,
                    44.745513899999985
                 ],
                 [
                    111.60281370000001,
                    44.7500687
                 ],
                 [
                    111.60491939999997,
                    44.7557526
                 ],
                 [
                    111.60925289999997,
                    44.7604866
                 ],
                 [
                    111.61327360000003,
                    44.7654533
                 ],
                 [
                    111.6183701,
                    44.77451710000004
                 ],
                 [
                    111.62171940000002,
                    44.77858349999997
                 ],
                 [
                    111.62644959999997,
                    44.785823800000045
                 ],
                 [
                    111.62980649999999,
                    44.790344199999964
                 ],
                 [
                    111.63511659999998,
                    44.795291899999974
                 ],
                 [
                    111.64114380000004,
                    44.802513099999985
                 ],
                 [
                    111.64483640000003,
                    44.80748370000003
                 ],
                 [
                    111.64791869999998,
                    44.8131523
                 ],
                 [
                    111.65296169999996,
                    44.819931
                 ],
                 [
                    111.65975190000002,
                    44.83079910000003
                 ],
                 [
                    111.66316220000002,
                    44.836689
                 ],
                 [
                    111.66786960000005,
                    44.8427849
                 ],
                 [
                    111.67365259999998,
                    44.852066
                 ],
                 [
                    111.677681,
                    44.857261700000016
                 ],
                 [
                    111.68740840000002,
                    44.869220699999985
                 ],
                 [
                    111.69252010000002,
                    44.87760160000003
                 ],
                 [
                    111.6988907,
                    44.8850403
                 ],
                 [
                    111.7039413,
                    44.891590099999966
                 ],
                 [
                    111.70937350000004,
                    44.8999634
                 ],
                 [
                    111.71243290000004,
                    44.904712700000026
                 ],
                 [
                    111.7158966,
                    44.9122009
                 ],
                 [
                    111.7193604,
                    44.919002500000026
                 ],
                 [
                    111.72241209999999,
                    44.92352290000003
                 ],
                 [
                    111.72614290000003,
                    44.9287224
                 ],
                 [
                    111.73185729999997,
                    44.935943600000016
                 ],
                 [
                    111.73586270000001,
                    44.939994800000015
                 ],
                 [
                    111.73954769999999,
                    44.944046
                 ],
                 [
                    111.7429428,
                    44.949024199999975
                 ],
                 [
                    111.74833679999996,
                    44.956020400000014
                 ],
                 [
                    111.75238040000005,
                    44.96075440000002
                 ],
                 [
                    111.75705720000002,
                    44.96570970000004
                 ],
                 [
                    111.76078029999996,
                    44.9704475
                 ],
                 [
                    111.7655029,
                    44.97608570000004
                 ],
                 [
                    111.76952359999997,
                    44.98059080000003
                 ],
                 [
                    111.7797699,
                    44.987506900000014
                 ],
                 [
                    111.79067230000003,
                    44.99464039999996
                 ],
                 [
                    111.79856870000003,
                    44.99885179999998
                 ],
                 [
                    111.80841059999997,
                    45.0030289
                 ],
                 [
                    111.81398770000003,
                    45.00545119999997
                 ],
                 [
                    111.82380680000004,
                    45.00894170000002
                 ],
                 [
                    111.83297730000004,
                    45.01267239999997
                 ],
                 [
                    111.8428879,
                    45.01867680000002
                 ],
                 [
                    111.85016630000003,
                    45.02334980000004
                 ],
                 [
                    111.85841370000003,
                    45.02778239999998
                 ],
                 [
                    111.86765290000002,
                    45.0333366
                 ],
                 [
                    111.87397,
                    45.0380287
                 ],
                 [
                    111.88088989999997,
                    45.041793800000036
                 ],
                 [
                    111.8890915,
                    45.045078300000014
                 ],
                 [
                    111.89866639999997,
                    45.050396
                 ],
                 [
                    111.90553280000003,
                    45.05256270000004
                 ],
                 [
                    111.9124069,
                    45.0549507
                 ],
                 [
                    111.91990660000005,
                    45.05664830000004
                 ],
                 [
                    111.925499,
                    45.059063
                 ],
                 [
                    111.9320984,
                    45.062603
                 ],
                 [
                    111.93769069999998,
                    45.06501770000002
                 ],
                 [
                    111.94458769999999,
                    45.06786729999998
                 ],
                 [
                    111.95346829999997,
                    45.0718231
                 ],
                 [
                    111.96269229999996,
                    45.0762253
                 ],
                 [
                    111.96672060000002,
                    45.08004
                 ],
                 [
                    111.97138209999997,
                    45.08361049999998
                 ],
                 [
                    111.98090359999998,
                    45.08686829999997
                 ],
                 [
                    111.98901369999999,
                    45.0874023
                 ],
                 [
                    112.00012210000001,
                    45.09040070000003
                 ],
                 [
                    112.00891109999998,
                    45.091835
                 ],
                 [
                    112.01995090000001,
                    45.0927734
                 ],
                 [
                    112.02935789999995,
                    45.0932846
                 ],
                 [
                    112.04102329999998,
                    45.0932922
                 ],
                 [
                    112.05233,
                    45.0930786
                 ],
                 [
                    112.06182860000003,
                    45.095871
                 ],
                 [
                    112.06781010000005,
                    45.091644299999984
                 ],
                 [
                    112.07444,
                    45.0874023
                 ],
                 [
                    112.08078,
                    45.0840797
                 ],
                 [
                    112.0887375,
                    45.08072279999997
                 ],
                 [
                    112.10112760000003,
                    45.0749969
                 ],
                 [
                    112.111351,
                    45.07159810000003
                 ],
                 [
                    112.11976620000004,
                    45.07166289999997
                 ],
                 [
                    112.13136289999997,
                    45.070522300000015
                 ],
                 [
                    112.1417007,
                    45.0700874
                 ],
                 [
                    112.15335850000002,
                    45.07031630000002
                 ],
                 [
                    112.163063,
                    45.0701218
                 ],
                 [
                    112.17339330000001,
                    45.069458
                 ],
                 [
                    112.18598180000002,
                    45.0689774
                 ],
                 [
                    112.20085140000003,
                    45.068676
                 ],
                 [
                    112.2147522,
                    45.068393700000016
                 ],
                 [
                    112.22281650000004,
                    45.0677719
                 ],
                 [
                    112.2334518,
                    45.066867799999976
                 ],
                 [
                    112.24376680000002,
                    45.06597139999997
                 ],
                 [
                    112.2573318,
                    45.06546020000004
                 ],
                 [
                    112.26737210000002,
                    45.06570820000004
                 ],
                 [
                    112.28093720000001,
                    45.065193199999975
                 ],
                 [
                    112.29779050000002,
                    45.0657539
                 ],
                 [
                    112.306488,
                    45.06488419999999
                 ],
                 [
                    112.31584170000002,
                    45.064228099999966
                 ],
                 [
                    112.32653809999998,
                    45.0646858
                 ],
                 [
                    112.33554839999996,
                    45.063579599999954
                 ],
                 [
                    112.344902,
                    45.06291959999997
                 ],
                 [
                    112.356842,
                    45.062206300000014
                 ],
                 [
                    112.36717989999997,
                    45.0619774
                 ],
                 [
                    112.37428280000005,
                    45.06159589999996
                 ],
                 [
                    112.38539119999999,
                    45.064098399999985
                 ],
                 [
                    112.39395140000002,
                    45.067577399999976
                 ],
                 [
                    112.400177,
                    45.0699501
                 ],
                 [
                    112.40570829999997,
                    45.072361
                 ],
                 [
                    112.41385650000002,
                    45.07479859999999
                 ],
                 [
                    112.42009740000002,
                    45.074966400000015
                 ],
                 [
                    112.42550660000002,
                    45.0741653
                 ],
                 [
                    112.43363189999997,
                    45.06965640000004
                 ],
                 [
                    112.43727109999998,
                    45.066604599999955
                 ],
                 [
                    112.44442750000005,
                    45.060272200000036
                 ],
                 [
                    112.45294190000001,
                    45.0555077
                 ],
                 [
                    112.46460720000003,
                    45.0490723
                 ],
                 [
                    112.47055820000001,
                    45.044818900000045
                 ],
                 [
                    112.47650909999999,
                    45.04056929999997
                 ],
                 [
                    112.48403929999998,
                    45.03559489999997
                 ],
                 [
                    112.49182130000003,
                    45.0292435
                 ],
                 [
                    112.49739069999995,
                    45.0238571
                 ],
                 [
                    112.50714869999999,
                    45.01814650000002
                 ],
                 [
                    112.518837,
                    45.012619
                 ],
                 [
                    112.52477259999998,
                    45.00790790000002
                 ],
                 [
                    112.52747349999999,
                    45.003501899999975
                 ],
                 [
                    112.53079219999995,
                    44.99862289999996
                 ],
                 [
                    112.54400630000004,
                    44.98459629999998
                 ],
                 [
                    112.54765319999998,
                    44.97948070000002
                 ],
                 [
                    112.55317689999995,
                    44.973403900000015
                 ],
                 [
                    112.55686190000003,
                    44.969432799999986
                 ],
                 [
                    112.56141660000003,
                    44.963607800000034
                 ],
                 [
                    112.56627659999998,
                    44.95709229999998
                 ],
                 [
                    112.57550809999998,
                    44.94749830000002
                 ],
                 [
                    112.5794907,
                    44.943058
                 ],
                 [
                    112.5849609,
                    44.9360733
                 ],
                 [
                    112.590889,
                    44.9318161
                 ],
                 [
                    112.59779360000002,
                    44.927536
                 ],
                 [
                    112.6063538,
                    44.924358399999974
                 ],
                 [
                    112.61580659999997,
                    44.9200134
                 ],
                 [
                    112.62152860000002,
                    44.918045
                 ],
                 [
                    112.62754059999997,
                    44.91561509999997
                 ],
                 [
                    112.634819,
                    44.9126968
                 ],
                 [
                    112.64111329999997,
                    44.909343700000036
                 ],
                 [
                    112.6467514,
                    44.9060059
                 ],
                 [
                    112.65521240000001,
                    44.9009972
                 ],
                 [
                    112.6637878,
                    44.898269700000014
                 ],
                 [
                    112.67106630000002,
                    44.8953476
                 ],
                 [
                    112.6804962,
                    44.8905449
                 ],
                 [
                    112.69058229999999,
                    44.8857231
                 ],
                 [
                    112.70195009999998,
                    44.880867
                 ],
                 [
                    112.70928189999998,
                    44.87908549999997
                 ],
                 [
                    112.71691890000002,
                    44.87706759999998
                 ],
                 [
                    112.73148350000002,
                    44.871669800000035
                 ],
                 [
                    112.73811340000005,
                    44.868988
                 ],
                 [
                    112.74508669999997,
                    44.8667526
                 ],
                 [
                    112.756813,
                    44.86257169999998
                 ],
                 [
                    112.770813,
                    44.8590164
                 ],
                 [
                    112.78324130000004,
                    44.85618210000004
                 ],
                 [
                    112.7934418,
                    44.85386279999999
                 ],
                 [
                    112.80909730000002,
                    44.851402299999975
                 ],
                 [
                    112.8176804,
                    44.84889220000003
                 ],
                 [
                    112.8243637,
                    44.8471184
                 ],
                 [
                    112.8324585,
                    44.841877
                 ],
                 [
                    112.843277,
                    44.839080800000026
                 ],
                 [
                    112.85643010000003,
                    44.838050799999984
                 ],
                 [
                    112.86347960000003,
                    44.8371773
                 ],
                 [
                    112.87799069999996,
                    44.83725360000001
                 ],
                 [
                    112.8851013,
                    44.83775329999997
                 ],
                 [
                    112.89187619999998,
                    44.837802899999986
                 ],
                 [
                    112.90767670000002,
                    44.8380661
                 ],
                 [
                    112.920929,
                    44.838626900000044
                 ],
                 [
                    112.9309692,
                    44.83950040000002
                 ],
                 [
                    112.9380112,
                    44.838623
                 ],
                 [
                    112.946579,
                    44.8361092
                 ],
                 [
                    112.95516970000004,
                    44.83404540000004
                 ],
                 [
                    112.9624634,
                    44.83179090000002
                 ],
                 [
                    112.97170260000001,
                    44.829937
                 ],
                 [
                    112.98032380000004,
                    44.82855990000003
                 ],
                 [
                    112.99152369999999,
                    44.82688139999997
                 ],
                 [
                    113.00372310000004,
                    44.826088
                 ],
                 [
                    113.01331329999996,
                    44.82467649999997
                 ],
                 [
                    113.02223970000001,
                    44.8230591
                 ],
                 [
                    113.03016659999997,
                    44.82078170000003
                 ],
                 [
                    113.04032139999995,
                    44.8179817
                 ],
                 [
                    113.05078129999998,
                    44.81494520000002
                 ],
                 [
                    113.06094359999997,
                    44.81214519999998
                 ],
                 [
                    113.0694885,
                    44.80961990000003
                 ],
                 [
                    113.080307,
                    44.8072548
                 ],
                 [
                    113.09205630000004,
                    44.80440520000004
                 ],
                 [
                    113.10349270000003,
                    44.801338200000025
                 ],
                 [
                    113.1142731,
                    44.79828640000003
                 ],
                 [
                    113.12761689999998,
                    44.795387299999966
                 ],
                 [
                    113.1359634,
                    44.794693
                 ],
                 [
                    113.14431,
                    44.794223799999976
                 ],
                 [
                    113.15682219999997,
                    44.79340359999997
                 ],
                 [
                    113.17159270000005,
                    44.792518600000015
                 ],
                 [
                    113.181221,
                    44.7920074
                 ],
                 [
                    113.19243619999997,
                    44.79099660000003
                 ],
                 [
                    113.20300290000003,
                    44.79000089999998
                 ],
                 [
                    113.2139206,
                    44.789447799999955
                 ],
                 [
                    113.22489930000005,
                    44.78981020000001
                 ],
                 [
                    113.23805239999996,
                    44.78896709999997
                 ],
                 [
                    113.24698640000003,
                    44.7875557
                 ],
                 [
                    113.2581711,
                    44.78607939999996
                 ],
                 [
                    113.26876829999998,
                    44.78553390000004
                 ],
                 [
                    113.27970889999997,
                    44.7854347
                 ],
                 [
                    113.2893295,
                    44.784687
                 ],
                 [
                    113.30058290000002,
                    44.78434750000002
                 ],
                 [
                    113.31141659999997,
                    44.7824173
                 ],
                 [
                    113.32234190000004,
                    44.7820854
                 ],
                 [
                    113.33329010000004,
                    44.7819824
                 ],
                 [
                    113.34485630000003,
                    44.781627700000044
                 ],
                 [
                    113.35476679999996,
                    44.7804108
                 ],
                 [
                    113.36834719999999,
                    44.78136440000002
                 ],
                 [
                    113.37893679999998,
                    44.78057860000002
                 ],
                 [
                    113.3931427,
                    44.781055400000035
                 ],
                 [
                    113.4052505,
                    44.7790756
                 ],
                 [
                    113.4157715,
                    44.77737430000001
                 ],
                 [
                    113.42474370000002,
                    44.776638
                 ],
                 [
                    113.43728639999998,
                    44.7764702
                 ],
                 [
                    113.44888309999999,
                    44.7763329
                 ],
                 [
                    113.45561219999996,
                    44.77589420000003
                 ],
                 [
                    113.46302029999998,
                    44.77588650000001
                 ],
                 [
                    113.46945190000002,
                    44.77568439999999
                 ],
                 [
                    113.47621150000003,
                    44.7756996
                 ],
                 [
                    113.49057010000004,
                    44.77363970000001
                 ],
                 [
                    113.4972916,
                    44.77296829999997
                 ],
                 [
                    113.505928,
                    44.772003199999965
                 ],
                 [
                    113.5156097,
                    44.767345400000046
                 ],
                 [
                    113.52773280000002,
                    44.76055150000004
                 ],
                 [
                    113.5378037,
                    44.75702290000003
                 ],
                 [
                    113.54445649999997,
                    44.7554359
                 ],
                 [
                    113.55402370000002,
                    44.75397870000004
                 ],
                 [
                    113.56461330000002,
                    44.75340650000003
                 ],
                 [
                    113.57353209999997,
                    44.75197220000002
                 ],
                 [
                    113.58120729999997,
                    44.75126270000003
                 ],
                 [
                    113.5977936,
                    44.74888989999999
                 ],
                 [
                    113.6066818,
                    44.746994
                 ],
                 [
                    113.61267090000003,
                    44.7453766
                 ],
                 [
                    113.625633,
                    44.742385
                 ],
                 [
                    113.63654330000003,
                    44.7455215
                 ],
                 [
                    113.64380650000004,
                    44.74850849999996
                 ],
                 [
                    113.65313719999997,
                    44.753231
                 ],
                 [
                    113.66351319999997,
                    44.7590599
                 ],
                 [
                    113.66988370000003,
                    44.76273350000002
                 ],
                 [
                    113.6805878,
                    44.768779799999976
                 ],
                 [
                    113.68772130000002,
                    44.77426150000003
                 ],
                 [
                    113.6975098,
                    44.780792200000015
                 ],
                 [
                    113.7072372,
                    44.7866402
                 ],
                 [
                    113.71573640000004,
                    44.79298780000003
                 ],
                 [
                    113.72518920000005,
                    44.799301099999965
                 ],
                 [
                    113.73403169999999,
                    44.80609130000001
                 ],
                 [
                    113.74115750000001,
                    44.81133650000004
                 ],
                 [
                    113.74830629999997,
                    44.816814399999956
                 ],
                 [
                    113.755127,
                    44.8223
                 ],
                 [
                    113.76051330000004,
                    44.8255463
                 ],
                 [
                    113.77002720000003,
                    44.83254240000003
                 ],
                 [
                    113.77628330000003,
                    44.838962600000016
                 ],
                 [
                    113.7813034,
                    44.8417587
                 ],
                 [
                    113.7919769,
                    44.846881899999964
                 ],
                 [
                    113.8029709,
                    44.851764700000025
                 ],
                 [
                    113.81281280000002,
                    44.8541679
                 ],
                 [
                    113.81905370000001,
                    44.85578160000003
                 ],
                 [
                    113.82534789999997,
                    44.858078
                 ],
                 [
                    113.83545679999999,
                    44.85978320000001
                 ],
                 [
                    113.84465030000003,
                    44.8619804
                 ],
                 [
                    113.85044859999998,
                    44.86635209999996
                 ],
                 [
                    113.85563659999998,
                    44.8714333
                 ],
                 [
                    113.8631821,
                    44.8729973
                 ],
                 [
                    113.87006379999998,
                    44.87435909999997
                 ],
                 [
                    113.8769073,
                    44.88006210000004
                 ],
                 [
                    113.8806686,
                    44.88748170000003
                 ],
                 [
                    113.88143159999998,
                    44.893631
                 ],
                 [
                    113.8872528,
                    44.89823149999996
                 ],
                 [
                    113.8931885,
                    44.9000816
                 ],
                 [
                    113.89749149999997,
                    44.906105
                 ],
                 [
                    113.89856719999999,
                    44.912014
                 ],
                 [
                    113.9068298,
                    44.91469570000002
                 ],
                 [
                    113.9151917,
                    44.91416929999998
                 ],
                 [
                    113.92510990000002,
                    44.912899
                 ],
                 [
                    113.93608859999998,
                    44.91296389999999
                 ],
                 [
                    113.9476624,
                    44.916667900000036
                 ],
                 [
                    113.9549332,
                    44.91892239999997
                 ],
                 [
                    113.96309659999997,
                    44.920230899999964
                 ],
                 [
                    113.97154239999996,
                    44.92084120000001
                 ],
                 [
                    113.97927090000002,
                    44.92056269999998
                 ],
                 [
                    113.98864750000003,
                    44.920677200000036
                 ],
                 [
                    114,
                    44.92316059999999
                 ],
                 [
                    114.04418920000003,
                    44.92456649999996
                 ],
                 [
                    114.056154,
                    44.9255814
                 ],
                 [
                    114.08905290000003,
                    44.9416832
                 ],
                 [
                    114.12560270000002,
                    44.96185680000002
                 ],
                 [
                    114.13337709999996,
                    44.96665950000003
                 ],
                 [
                    114.1604919,
                    44.99561690000002
                 ],
                 [
                    114.17104340000004,
                    45.022529600000034
                 ],
                 [
                    114.17697139999999,
                    45.03379059999999
                 ],
                 [
                    114.19109339999996,
                    45.04206469999998
                 ],
                 [
                    114.21643070000002,
                    45.04975130000004
                 ],
                 [
                    114.2982995,
                    45.099915
                 ],
                 [
                    114.31167190000002,
                    45.102405
                 ],
                 [
                    114.31590340000002,
                    45.1078451
                 ],
                 [
                    114.34315459999998,
                    45.1189841
                 ],
                 [
                    114.38472240000002,
                    45.14338669999997
                 ],
                 [
                    114.38866210000003,
                    45.14922849999998
                 ],
                 [
                    114.4037339,
                    45.16548540000002
                 ],
                 [
                    114.42330929999999,
                    45.177284199999974
                 ],
                 [
                    114.44100189999997,
                    45.1924019
                 ],
                 [
                    114.45617680000002,
                    45.2060776
                 ],
                 [
                    114.514679,
                    45.274231
                 ],
                 [
                    114.528038,
                    45.29266739999997
                 ],
                 [
                    114.53090669999997,
                    45.306720700000035
                 ],
                 [
                    114.5415878,
                    45.347095500000044
                 ],
                 [
                    114.54699710000001,
                    45.38252260000004
                 ],
                 [
                    114.744751,
                    45.43503189999998
                 ],
                 [
                    114.79000850000001,
                    45.41907880000002
                 ],
                 [
                    114.94432070000003,
                    45.3749275
                 ],
                 [
                    114.95480349999998,
                    45.3730812
                 ],
                 [
                    115.0017929,
                    45.375640899999965
                 ],
                 [
                    115.16233829999999,
                    45.38999179999996
                 ],
                 [
                    115.1718063,
                    45.39065930000003
                 ],
                 [
                    115.2152405,
                    45.391426099999975
                 ],
                 [
                    115.3567429,
                    45.387165100000026
                 ],
                 [
                    115.37197110000002,
                    45.38798139999997
                 ],
                 [
                    115.41577150000005,
                    45.39857480000002
                 ],
                 [
                    115.56162259999996,
                    45.4309006
                 ],
                 [
                    115.68744659999999,
                    45.45267490000004
                 ],
                 [
                    115.7050705,
                    45.46208570000003
                 ],
                 [
                    115.891983,
                    45.59627529999999
                 ],
                 [
                    115.92543030000003,
                    45.62660979999997
                 ],
                 [
                    115.93325040000002,
                    45.63014980000004
                 ],
                 [
                    115.98282619999998,
                    45.64225390000003
                 ],
                 [
                    116.02622220000003,
                    45.65649029999996
                 ],
                 [
                    116.02831269999999,
                    45.6654472
                 ],
                 [
                    116.03092189999995,
                    45.67967990000003
                 ],
                 [
                    116.04589080000002,
                    45.6819038
                 ],
                 [
                    116.06707,
                    45.683967599999974
                 ],
                 [
                    116.08345029999997,
                    45.68138890000004
                 ],
                 [
                    116.1090622,
                    45.674552900000016
                 ],
                 [
                    116.12336730000001,
                    45.67721940000002
                 ],
                 [
                    116.13091280000003,
                    45.679492900000014
                 ],
                 [
                    116.13411710000004,
                    45.680172
                 ],
                 [
                    116.14549259999995,
                    45.68087770000002
                 ],
                 [
                    116.18160249999997,
                    45.6924858
                 ],
                 [
                    116.21197469999996,
                    45.71672040000003
                 ],
                 [
                    116.212275,
                    45.71715119999997
                 ],
                 [
                    116.2126663,
                    45.71759669999997
                 ],
                 [
                    116.21293430000004,
                    45.7179975
                 ],
                 [
                    116.21447850000001,
                    45.72003850000004
                 ],
                 [
                    116.216771,
                    45.72303350000004
                 ],
                 [
                    116.21878159999997,
                    45.72565260000003
                 ],
                 [
                    116.21907440000004,
                    45.72606089999997
                 ],
                 [
                    116.21898969999995,
                    45.72957329999998
                 ],
                 [
                    116.21878689999997,
                    45.7407697
                 ],
                 [
                    116.21875789999996,
                    45.7448251
                 ],
                 [
                    116.222049,
                    45.746534
                 ],
                 [
                    116.239176,
                    45.755133800000024
                 ],
                 [
                    116.25118750000001,
                    45.758837599999964
                 ],
                 [
                    116.2627666,
                    45.763898099999984
                 ],
                 [
                    116.26835630000002,
                    45.76598779999996
                 ],
                 [
                    116.2685773,
                    45.768478499999986
                 ],
                 [
                    116.28056360000002,
                    45.77038990000004
                 ],
                 [
                    116.27921819999999,
                    45.78171020000001
                 ],
                 [
                    116.27774619999997,
                    45.79569269999999
                 ],
                 [
                    116.27566479999996,
                    45.803183
                 ],
                 [
                    116.2748687,
                    45.80812509999996
                 ],
                 [
                    116.2748859,
                    45.81556290000004
                 ],
                 [
                    116.27472070000002,
                    45.82790120000004
                 ],
                 [
                    116.28175019999996,
                    45.833497499999986
                 ],
                 [
                    116.28213970000004,
                    45.8344371
                 ],
                 [
                    116.28329619999998,
                    45.84003049999998
                 ],
                 [
                    116.27808420000004,
                    45.843094899999954
                 ],
                 [
                    116.277081,
                    45.84452020000003
                 ],
                 [
                    116.2748644,
                    45.84637520000003
                 ],
                 [
                    116.27255880000001,
                    45.8506685
                 ],
                 [
                    116.2703658,
                    45.853079299999976
                 ],
                 [
                    116.26193510000003,
                    45.85902040000003
                 ],
                 [
                    116.258592,
                    45.866972899999965
                 ],
                 [
                    116.2585598,
                    45.868780100000016
                 ],
                 [
                    116.25826260000002,
                    45.869197700000015
                 ],
                 [
                    116.25755449999996,
                    45.86973550000002
                 ],
                 [
                    116.2506108,
                    45.871439499999966
                 ],
                 [
                    116.24062229999998,
                    45.8726765
                 ],
                 [
                    116.23895289999999,
                    45.873319
                 ],
                 [
                    116.23585650000001,
                    45.88216559999998
                 ],
                 [
                    116.23759350000003,
                    45.88515450000001
                 ],
                 [
                    116.24414670000002,
                    45.89430030000001
                 ],
                 [
                    116.24984910000003,
                    45.902257399999975
                 ],
                 [
                    116.25074489999999,
                    45.90481290000003
                 ],
                 [
                    116.253911,
                    45.91340810000004
                 ],
                 [
                    116.25699339999998,
                    45.93517130000004
                 ],
                 [
                    116.25796550000004,
                    45.942514500000016
                 ],
                 [
                    116.2646302,
                    45.9612181
                 ],
                 [
                    116.26730350000004,
                    45.963134799999956
                 ],
                 [
                    116.27409359999999,
                    45.9684639
                 ],
                 [
                    116.28112029999998,
                    45.97906489999996
                 ],
                 [
                    116.3638611,
                    46.075286900000044
                 ],
                 [
                    116.39028929999998,
                    46.1080246
                 ],
                 [
                    116.41007230000001,
                    46.126045199999965
                 ],
                 [
                    116.42878720000004,
                    46.134594
                 ],
                 [
                    116.44151309999995,
                    46.1415634
                 ],
                 [
                    116.5334396,
                    46.224727600000044
                 ],
                 [
                    116.54273339999997,
                    46.23672420000002
                 ],
                 [
                    116.56574079999999,
                    46.25507940000004
                 ],
                 [
                    116.568512,
                    46.26573
                 ],
                 [
                    116.572853,
                    46.277433
                 ],
                 [
                    116.575261,
                    46.284315
                 ],
                 [
                    116.577685,
                    46.289824
                 ],
                 [
                    116.585096,
                    46.293985
                 ],
                 [
                    116.592489,
                    46.299863
                 ],
                 [
                    116.598912,
                    46.303674
                 ],
                 [
                    116.613781,
                    46.308214
                 ],
                 [
                    116.626674,
                    46.311712
                 ],
                 [
                    116.643534,
                    46.316601
                 ],
                 [
                    116.65841,
                    46.321135
                 ],
                 [
                    116.685252,
                    46.322971
                 ],
                 [
                    116.710104,
                    46.325137
                 ],
                 [
                    116.730986,
                    46.326251
                 ],
                 [
                    116.752359,
                    46.328393
                 ],
                 [
                    116.759818,
                    46.328765
                 ],
                 [
                    116.760759,
                    46.335638
                 ],
                 [
                    116.766676,
                    46.342529
                 ],
                 [
                    116.774606,
                    46.34668
                 ],
                 [
                    116.784535,
                    46.349806
                 ],
                 [
                    116.796964,
                    46.351565
                 ],
                 [
                    116.80839,
                    46.354351
                 ],
                 [
                    116.812362,
                    46.355738
                 ],
                 [
                    116.812332,
                    46.360203
                 ],
                 [
                    116.813273,
                    46.368107
                 ],
                 [
                    116.815226,
                    46.373609
                 ],
                 [
                    116.821173,
                    46.377749
                 ],
                 [
                    116.831102,
                    46.382245
                 ],
                 [
                    116.838564,
                    46.383298
                 ],
                 [
                    116.846035,
                    46.382977
                 ],
                 [
                    116.852521,
                    46.380934
                 ],
                 [
                    116.858015,
                    46.378202
                 ],
                 [
                    116.86051,
                    46.377178
                 ],
                 [
                    116.864993,
                    46.376847
                 ],
                 [
                    116.868977,
                    46.376858
                 ],
                 [
                    116.872468,
                    46.375837
                 ],
                 [
                    116.87646,
                    46.37413
                 ],
                 [
                    116.881937,
                    46.374144
                 ],
                 [
                    116.887911,
                    46.37416
                 ],
                 [
                    116.891391,
                    46.375199
                 ],
                 [
                    116.89586,
                    46.377271
                 ],
                 [
                    116.897846,
                    46.378306
                 ],
                 [
                    116.907815,
                    46.376269
                 ],
                 [
                    116.912799,
                    46.37525
                 ],
                 [
                    116.919271,
                    46.375265
                 ],
                 [
                    116.931728,
                    46.373232
                 ],
                 [
                    116.945179,
                    46.371199
                 ],
                 [
                    116.959127,
                    46.369166
                 ],
                 [
                    116.972576,
                    46.367129
                 ],
                 [
                    116.983035,
                    46.365774
                 ],
                 [
                    116.992997,
                    46.364073
                 ],
                 [
                    117.000467,
                    46.363054
                 ],
                 [
                    117.01345,
                    46.361078
                 ],
                 [
                    117.027394,
                    46.359385
                 ],
                 [
                    117.04283,
                    46.35804
                 ],
                 [
                    117.057768,
                    46.356346
                 ],
                 [
                    117.078179,
                    46.35466
                 ],
                 [
                    117.085647,
                    46.353984
                 ],
                 [
                    117.098086,
                    46.355041
                 ],
                 [
                    117.116995,
                    46.356106
                 ],
                 [
                    117.141377,
                    46.358211
                 ],
                 [
                    117.163773,
                    46.359273
                 ],
                 [
                    117.196619,
                    46.36172
                 ],
                 [
                    117.222003,
                    46.363118
                 ],
                 [
                    117.245398,
                    46.36451
                 ],
                 [
                    117.266802,
                    46.363826
                 ],
                 [
                    117.292188,
                    46.363484
                 ],
                 [
                    117.313591,
                    46.362791
                 ],
                 [
                    117.331012,
                    46.362096
                 ],
                 [
                    117.361373,
                    46.361047
                 ],
                 [
                    117.36834,
                    46.360697
                 ],
                 [
                    117.37185,
                    46.374496
                 ],
                 [
                    117.374859,
                    46.385535
                 ],
                 [
                    117.378867,
                    46.397608
                 ],
                 [
                    117.36843,
                    46.410037
                 ],
                 [
                    117.379454,
                    46.441427
                 ],
                 [
                    117.38798,
                    46.465227
                 ],
                 [
                    117.391,
                    46.477645
                 ],
                 [
                    117.405999,
                    46.491777
                 ],
                 [
                    117.411505,
                    46.498327
                 ],
                 [
                    117.425014,
                    46.511078
                 ],
                 [
                    117.438529,
                    46.523482
                 ],
                 [
                    117.439034,
                    46.525552
                 ],
                 [
                    117.432091,
                    46.540741
                 ],
                 [
                    117.424149,
                    46.557657
                 ],
                 [
                    117.413715,
                    46.578715
                 ],
                 [
                    117.423214,
                    46.580085
                 ],
                 [
                    117.441705,
                    46.580407
                 ],
                 [
                    117.464706,
                    46.583824
                 ],
                 [
                    117.470207,
                    46.584851
                 ],
                 [
                    117.486237,
                    46.59414
                 ],
                 [
                    117.493745,
                    46.596542
                 ],
                 [
                    117.501743,
                    46.596527
                 ],
                 [
                    117.511215,
                    46.590644
                 ],
                 [
                    117.516193,
                    46.586149
                 ],
                 [
                    117.51969,
                    46.585797
                 ],
                 [
                    117.52572,
                    46.592685
                 ],
                 [
                    117.529232,
                    46.595438
                 ],
                 [
                    117.53823,
                    46.595419
                 ],
                 [
                    117.545235,
                    46.596783
                 ],
                 [
                    117.552241,
                    46.598147
                 ],
                 [
                    117.558726,
                    46.595717
                 ],
                 [
                    117.563222,
                    46.595016
                 ],
                 [
                    117.569212,
                    46.593622
                 ],
                 [
                    117.574705,
                    46.592573
                 ],
                 [
                    117.580213,
                    46.594285
                 ],
                 [
                    117.585258,
                    46.602207
                 ],
                 [
                    117.597235,
                    46.598725
                 ],
                 [
                    117.605721,
                    46.596632
                 ],
                 [
                    117.609713,
                    46.595586
                 ],
                 [
                    117.61471,
                    46.595227
                 ],
                 [
                    117.623662,
                    46.588301
                 ],
                 [
                    117.630095,
                    46.578621
                 ],
                 [
                    117.63304,
                    46.570677
                 ],
                 [
                    117.632476,
                    46.561018
                 ],
                 [
                    117.633429,
                    46.554115
                 ],
                 [
                    117.635903,
                    46.550657
                 ],
                 [
                    117.646848,
                    46.544413
                 ],
                 [
                    117.655803,
                    46.539554
                 ],
                 [
                    117.662749,
                    46.533321
                 ],
                 [
                    117.672683,
                    46.526388
                 ],
                 [
                    117.685608,
                    46.519099
                 ],
                 [
                    117.696551,
                    46.51423
                 ],
                 [
                    117.702031,
                    46.51283
                 ],
                 [
                    117.713516,
                    46.513478
                 ],
                 [
                    117.72052,
                    46.515522
                 ],
                 [
                    117.728031,
                    46.518254
                 ],
                 [
                    117.733518,
                    46.517888
                 ],
                 [
                    117.740019,
                    46.519243
                 ],
                 [
                    117.749528,
                    46.521965
                 ],
                 [
                    117.759024,
                    46.523306
                 ],
                 [
                    117.762038,
                    46.525364
                 ],
                 [
                    117.764578,
                    46.530184
                 ],
                 [
                    117.76911,
                    46.534305
                 ],
                 [
                    117.772611,
                    46.534981
                 ],
                 [
                    117.780078,
                    46.532534
                 ],
                 [
                    117.78804,
                    46.529739
                 ],
                 [
                    117.792024,
                    46.528687
                 ],
                 [
                    117.796531,
                    46.530047
                 ],
                 [
                    117.798531,
                    46.530383
                 ],
                 [
                    117.808003,
                    46.52896
                 ],
                 [
                    117.813495,
                    46.528935
                 ],
                 [
                    117.817492,
                    46.529262
                 ],
                 [
                    117.821993,
                    46.529931
                 ],
                 [
                    117.825972,
                    46.528533
                 ],
                 [
                    117.830473,
                    46.529201
                 ],
                 [
                    117.836015,
                    46.534005
                 ],
                 [
                    117.841518,
                    46.535014
                 ],
                 [
                    117.847516,
                    46.535675
                 ],
                 [
                    117.863119,
                    46.546983
                 ],
                 [
                    117.862169,
                    46.551473
                 ],
                 [
                    117.858271,
                    46.560463
                 ],
                 [
                    117.856877,
                    46.570131
                 ],
                 [
                    117.864939,
                    46.576301
                 ],
                 [
                    117.87547,
                    46.579698
                 ],
                 [
                    117.880467,
                    46.579672
                 ],
                 [
                    117.886975,
                    46.580673
                 ],
                 [
                    117.890993,
                    46.582377
                 ],
                 [
                    117.889061,
                    46.588253
                 ],
                 [
                    117.892642,
                    46.595479
                 ],
                 [
                    117.899184,
                    46.59924
                 ],
                 [
                    117.906223,
                    46.602652
                 ],
                 [
                    117.915787,
                    46.60812
                 ],
                 [
                    117.924849,
                    46.613245
                 ],
                 [
                    117.93037,
                    46.61494
                 ],
                 [
                    117.936879,
                    46.615593
                 ],
                 [
                    117.94534,
                    46.61244
                 ],
                 [
                    117.951806,
                    46.609642
                 ],
                 [
                    117.963314,
                    46.610265
                 ],
                 [
                    117.978826,
                    46.611208
                 ],
                 [
                    117.984371,
                    46.614624
                 ],
                 [
                    117.98293,
                    46.619118
                 ],
                 [
                    117.981003,
                    46.62465
                 ],
                 [
                    117.983053,
                    46.628433
                 ],
                 [
                    117.989564,
                    46.629083
                 ],
                 [
                    118.000572,
                    46.62936
                 ],
                 [
                    118.009548,
                    46.629121
                 ],
                 [
                    118.017056,
                    46.628456
                 ],
                 [
                    118.030574,
                    46.626773
                 ],
                 [
                    118.037047,
                    46.630245
                 ],
                 [
                    118.052998,
                    46.63685
                 ],
                 [
                    118.061474,
                    46.640326
                 ],
                 [
                    118.066452,
                    46.643447
                 ],
                 [
                    118.072921,
                    46.647952
                 ],
                 [
                    118.087874,
                    46.655585
                 ],
                 [
                    118.100839,
                    46.661831
                 ],
                 [
                    118.108307,
                    46.667371
                 ],
                 [
                    118.109469,
                    46.673172
                 ],
                 [
                    118.115556,
                    46.678658
                 ],
                 [
                    118.126114,
                    46.681692
                 ],
                 [
                    118.135636,
                    46.682314
                 ],
                 [
                    118.144653,
                    46.682593
                 ],
                 [
                    118.150175,
                    46.683588
                 ],
                 [
                    118.1532,
                    46.684948
                 ],
                 [
                    118.163697,
                    46.683832
                 ],
                 [
                    118.17069,
                    46.682742
                 ],
                 [
                    118.176175,
                    46.681317
                 ],
                 [
                    118.184184,
                    46.681256
                 ],
                 [
                    118.191215,
                    46.682583
                 ],
                 [
                    118.194296,
                    46.687397
                 ],
                 [
                    118.20135,
                    46.690106
                 ],
                 [
                    118.211403,
                    46.692445
                 ],
                 [
                    118.215471,
                    46.696213
                 ],
                 [
                    118.219085,
                    46.70275
                 ],
                 [
                    118.219154,
                    46.706897
                 ],
                 [
                    118.223725,
                    46.710661
                 ],
                 [
                    118.227772,
                    46.713047
                 ],
                 [
                    118.247342,
                    46.714959
                 ],
                 [
                    118.258886,
                    46.716245
                 ],
                 [
                    118.272978,
                    46.719927
                 ],
                 [
                    118.283083,
                    46.724678
                 ],
                 [
                    118.291742,
                    46.732551
                 ],
                 [
                    118.300868,
                    46.738346
                 ],
                 [
                    118.312926,
                    46.739967
                 ],
                 [
                    118.322878,
                    46.736077
                 ],
                 [
                    118.331871,
                    46.734613
                 ],
                 [
                    118.347404,
                    46.734471
                 ],
                 [
                    118.361921,
                    46.733646
                 ],
                 [
                    118.392396,
                    46.728865
                 ],
                 [
                    118.402381,
                    46.727041
                 ],
                 [
                    118.406821,
                    46.723542
                 ],
                 [
                    118.421702,
                    46.71614
                 ],
                 [
                    118.437036,
                    46.706657
                 ],
                 [
                    118.449019,
                    46.704809
                 ],
                 [
                    118.459528,
                    46.704357
                 ],
                 [
                    118.467017,
                    46.703244
                 ],
                 [
                    118.489514,
                    46.701283
                 ],
                 [
                    118.512509,
                    46.699313
                 ],
                 [
                    118.540994,
                    46.696588
                 ],
                 [
                    118.575476,
                    46.693442
                 ],
                 [
                    118.577478,
                    46.69342
                 ],
                 [
                    118.580562,
                    46.696841
                 ],
                 [
                    118.597756,
                    46.703903
                 ],
                 [
                    118.611924,
                    46.709962
                 ],
                 [
                    118.629134,
                    46.717365
                 ],
                 [
                    118.635211,
                    46.720058
                 ],
                 [
                    118.647725,
                    46.719565
                 ],
                 [
                    118.654134,
                    46.715343
                 ],
                 [
                    118.656501,
                    46.709785
                 ],
                 [
                    118.659344,
                    46.703186
                 ],
                 [
                    118.665741,
                    46.698617
                 ],
                 [
                    118.673692,
                    46.696102
                 ],
                 [
                    118.679708,
                    46.696375
                 ],
                 [
                    118.691205,
                    46.695544
                 ],
                 [
                    118.701174,
                    46.693694
                 ],
                 [
                    118.709219,
                    46.694977
                 ],
                 [
                    118.716755,
                    46.695921
                 ],
                 [
                    118.724711,
                    46.693748
                 ],
                 [
                    118.730592,
                    46.688836
                 ],
                 [
                    118.738628,
                    46.689772
                 ],
                 [
                    118.747156,
                    46.690355
                 ],
                 [
                    118.753618,
                    46.688545
                 ],
                 [
                    118.759551,
                    46.685705
                 ],
                 [
                    118.770071,
                    46.685915
                 ],
                 [
                    118.776661,
                    46.68894
                 ],
                 [
                    118.785133,
                    46.687448
                 ],
                 [
                    118.789591,
                    46.685662
                 ],
                 [
                    118.792131,
                    46.687011
                 ],
                 [
                    118.791771,
                    46.692199
                 ],
                 [
                    118.791364,
                    46.69566
                 ],
                 [
                    118.793489,
                    46.700125
                 ],
                 [
                    118.789142,
                    46.706056
                 ],
                 [
                    118.784247,
                    46.710267
                 ],
                 [
                    118.781308,
                    46.712724
                 ],
                 [
                    118.783432,
                    46.717189
                 ],
                 [
                    118.794685,
                    46.725681
                 ],
                 [
                    118.808427,
                    46.733449
                 ],
                 [
                    118.811046,
                    46.737561
                 ],
                 [
                    118.817325,
                    46.747153
                 ],
                 [
                    118.821044,
                    46.754706
                 ],
                 [
                    118.830778,
                    46.762178
                 ],
                 [
                    118.833352,
                    46.764563
                 ],
                 [
                    118.832426,
                    46.76734
                 ],
                 [
                    118.842549,
                    46.770659
                 ],
                 [
                    118.852083,
                    46.770875
                 ],
                 [
                    118.862109,
                    46.770738
                 ],
                 [
                    118.866581,
                    46.769295
                 ],
                 [
                    118.87465,
                    46.770911
                 ],
                 [
                    118.887262,
                    46.773501
                 ],
                 [
                    118.896858,
                    46.775786
                 ],
                 [
                    118.906322,
                    46.77358
                 ],
                 [
                    118.909729,
                    46.770076
                 ],
                 [
                    118.911541,
                    46.763485
                 ],
                 [
                    118.912381,
                    46.757944
                 ],
                 [
                    118.91277,
                    46.754137
                 ],
                 [
                    118.913108,
                    46.748603
                 ],
                 [
                    118.914019,
                    46.74548
                 ],
                 [
                    118.91495,
                    46.743048
                 ],
                 [
                    118.914868,
                    46.740285
                 ],
                 [
                    118.914316,
                    46.738565
                 ],
                 [
                    118.907753,
                    46.736929
                 ],
                 [
                    118.903164,
                    46.734229
                 ],
                 [
                    118.901109,
                    46.73253
                 ],
                 [
                    118.910024,
                    46.728949
                 ],
                 [
                    118.912969,
                    46.726834
                 ],
                 [
                    118.915892,
                    46.724028
                 ],
                 [
                    118.921862,
                    46.722561
                 ],
                 [
                    118.926911,
                    46.723872
                 ],
                 [
                    118.930416,
                    46.723822
                 ],
                 [
                    118.932849,
                    46.721368
                 ],
                 [
                    118.934821,
                    46.720303
                 ],
                 [
                    118.942364,
                    46.721231
                 ],
                 [
                    118.945013,
                    46.726031
                 ],
                 [
                    118.947037,
                    46.726693
                 ],
                 [
                    118.952744,
                    46.733176
                 ],
                 [
                    118.957879,
                    46.737248
                 ],
                 [
                    118.963431,
                    46.73855
                 ],
                 [
                    118.971446,
                    46.738433
                 ],
                 [
                    118.978012,
                    46.740064
                 ],
                 [
                    118.982062,
                    46.741387
                 ],
                 [
                    118.98672,
                    46.746156
                 ],
                 [
                    118.991325,
                    46.749198
                 ],
                 [
                    118.999907,
                    46.751144
                 ],
                 [
                    119.002831,
                    46.748036
                 ],
                 [
                    119.008344,
                    46.746313
                 ],
                 [
                    119.011852,
                    46.743214
                 ],
                 [
                    119.013856,
                    46.738049
                 ],
                 [
                    119.014858,
                    46.736327
                 ],
                 [
                    119.013856,
                    46.733573
                 ],
                 [
                    119.012854,
                    46.731507
                 ],
                 [
                    119.014858,
                    46.729097
                 ],
                 [
                    119.019867,
                    46.724963
                 ],
                 [
                    119.026878,
                    46.716697
                 ],
                 [
                    119.032386,
                    46.711874
                 ],
                 [
                    119.033887,
                    46.708431
                 ],
                 [
                    119.039393,
                    46.702231
                 ],
                 [
                    119.044897,
                    46.695686
                 ],
                 [
                    119.053902,
                    46.686384
                 ],
                 [
                    119.063905,
                    46.677081
                 ],
                 [
                    119.070904,
                    46.669845
                 ],
                 [
                    119.074403,
                    46.666744
                 ],
                 [
                    119.079662,
                    46.66324
                 ],
                 [
                    119.086204,
                    46.659489
                 ],
                 [
                    119.091731,
                    46.657108
                 ],
                 [
                    119.099269,
                    46.653705
                 ],
                 [
                    119.101296,
                    46.650963
                 ],
                 [
                    119.100829,
                    46.647522
                 ],
                 [
                    119.10383,
                    46.647536
                 ],
                 [
                    119.107828,
                    46.647899
                 ],
                 [
                    119.11283,
                    46.647921
                 ],
                 [
                    119.115335,
                    46.647589
                 ],
                 [
                    119.117348,
                    46.646222
                 ],
                 [
                    119.121828,
                    46.648649
                 ],
                 [
                    119.1243,
                    46.651755
                 ],
                 [
                    119.128318,
                    46.650053
                 ],
                 [
                    119.130831,
                    46.648689
                 ],
                 [
                    119.133311,
                    46.651107
                 ],
                 [
                    119.137291,
                    46.653531
                 ],
                 [
                    119.140771,
                    46.655953
                 ],
                 [
                    119.147275,
                    46.65598
                 ],
                 [
                    119.156289,
                    46.654986
                 ],
                 [
                    119.164304,
                    46.653644
                 ],
                 [
                    119.166317,
                    46.652277
                 ],
                 [
                    119.170836,
                    46.650232
                 ],
                 [
                    119.175352,
                    46.648531
                 ],
                 [
                    119.18186,
                    46.647869
                 ],
                 [
                    119.184372,
                    46.646503
                 ],
                 [
                    119.186395,
                    46.64376
                 ],
                 [
                    119.191878,
                    46.646188
                 ],
                 [
                    119.192364,
                    46.647909
                 ],
                 [
                    119.194373,
                    46.646885
                 ],
                 [
                    119.196888,
                    46.645176
                 ],
                 [
                    119.197396,
                    46.644146
                 ],
                 [
                    119.20239,
                    46.645196
                 ],
                 [
                    119.205385,
                    46.645895
                 ],
                 [
                    119.209394,
                    46.644879
                 ],
                 [
                    119.213888,
                    46.645927
                 ],
                 [
                    119.216889,
                    46.645937
                 ],
                 [
                    119.218877,
                    46.647664
                 ],
                 [
                    119.220855,
                    46.650765
                 ],
                 [
                    119.223861,
                    46.650088
                 ],
                 [
                    119.229378,
                    46.648045
                 ],
                 [
                    119.234385,
                    46.647375
                 ],
                 [
                    119.238392,
                    46.646701
                 ],
                 [
                    119.24492,
                    46.642941
                 ],
                 [
                    119.249917,
                    46.643645
                 ],
                 [
                    119.257928,
                    46.642296
                 ],
                 [
                    119.25844,
                    46.640579
                 ],
                 [
                    119.257461,
                    46.637481
                 ],
                 [
                    119.256474,
                    46.635415
                 ],
                 [
                    119.258488,
                    46.633358
                 ],
                 [
                    119.261,
                    46.631647
                 ],
                 [
                    119.262016,
                    46.629244
                 ],
                 [
                    119.262525,
                    46.62787
                 ],
                 [
                    119.266525,
                    46.627883
                 ],
                 [
                    119.269534,
                    46.626517
                 ],
                 [
                    119.276554,
                    46.623445
                 ],
                 [
                    119.281575,
                    46.620022
                 ],
                 [
                    119.288589,
                    46.617636
                 ],
                 [
                    119.291603,
                    46.615239
                 ],
                 [
                    119.295121,
                    46.612155
                 ],
                 [
                    119.300639,
                    46.608732
                 ],
                 [
                    119.305136,
                    46.609089
                 ],
                 [
                    119.311629,
                    46.609795
                 ],
                 [
                    119.317125,
                    46.610154
                 ],
                 [
                    119.322622,
                    46.610513
                 ],
                 [
                    119.331615,
                    46.611225
                 ],
                 [
                    119.336106,
                    46.612612
                 ],
                 [
                    119.339589,
                    46.615715
                 ],
                 [
                    119.341578,
                    46.617783
                 ],
                 [
                    119.348078,
                    46.617455
                 ],
                 [
                    119.355093,
                    46.614034
                 ],
                 [
                    119.359607,
                    46.610951
                 ],
                 [
                    119.364622,
                    46.607524
                 ],
                 [
                    119.36863,
                    46.605471
                 ],
                 [
                    119.375627,
                    46.605487
                 ],
                 [
                    119.383611,
                    46.608255
                 ],
                 [
                    119.387596,
                    46.611358
                 ],
                 [
                    119.39507,
                    46.616874
                 ],
                 [
                    119.406048,
                    46.62171
                 ],
                 [
                    119.413528,
                    46.626537
                 ],
                 [
                    119.422504,
                    46.633087
                 ],
                 [
                    119.427488,
                    46.637565
                 ],
                 [
                    119.430484,
                    46.638602
                 ],
                 [
                    119.4341194,
                    46.6383219
                 ],
                 [
                    119.443991,
                    46.637249
                 ],
                 [
                    119.457503,
                    46.633832
                 ],
                 [
                    119.472015,
                    46.629728
                 ],
                 [
                    119.480016,
                    46.629051
                 ],
                 [
                    119.489015,
                    46.629406
                 ],
                 [
                    119.498011,
                    46.631136
                 ],
                 [
                    119.506507,
                    46.632865
                 ],
                 [
                    119.515503,
                    46.634937
                 ],
                 [
                    119.521001,
                    46.635973
                 ],
                 [
                    119.529003,
                    46.635293
                 ],
                 [
                    119.536005,
                    46.633924
                 ],
                 [
                    119.546005,
                    46.634276
                 ],
                 [
                    119.555506,
                    46.633938
                 ],
                 [
                    119.558008,
                    46.632221
                 ],
                 [
                    119.560513,
                    46.627753
                 ],
                 [
                    119.563015,
                    46.626036
                 ],
                 [
                    119.566015,
                    46.625006
                 ],
                 [
                    119.567518,
                    46.622257
                 ],
                 [
                    119.571018,
                    46.621571
                 ],
                 [
                    119.577017,
                    46.62123
                 ],
                 [
                    119.579519,
                    46.618137
                 ],
                 [
                    119.582023,
                    46.61195
                 ],
                 [
                    119.591516,
                    46.616767
                 ],
                 [
                    119.597514,
                    46.618144
                 ],
                 [
                    119.607011,
                    46.618834
                 ],
                 [
                    119.614508,
                    46.62021
                 ],
                 [
                    119.621006,
                    46.622274
                 ],
                 [
                    119.628005,
                    46.622962
                 ],
                 [
                    119.637003,
                    46.625024
                 ],
                 [
                    119.646502,
                    46.62468
                 ],
                 [
                    119.655998,
                    46.621584
                 ],
                 [
                    119.665992,
                    46.617112
                 ],
                 [
                    119.671485,
                    46.61161
                 ],
                 [
                    119.67298,
                    46.60714
                 ],
                 [
                    119.674473,
                    46.599577
                 ],
                 [
                    119.677965,
                    46.594075
                 ],
                 [
                    119.679959,
                    46.589605
                 ],
                 [
                    119.678455,
                    46.584793
                 ],
                 [
                    119.686455,
                    46.590977
                 ],
                 [
                    119.690454,
                    46.593382
                 ],
                 [
                    119.693956,
                    46.596818
                 ],
                 [
                    119.702949,
                    46.596469
                 ],
                 [
                    119.709949,
                    46.599558
                 ],
                 [
                    119.715449,
                    46.60196
                 ],
                 [
                    119.719458,
                    46.608489
                 ],
                 [
                    119.723459,
                    46.609861
                 ],
                 [
                    119.727461,
                    46.612264
                 ],
                 [
                    119.732465,
                    46.615354
                 ],
                 [
                    119.736958,
                    46.612943
                 ],
                 [
                    119.742951,
                    46.610875
                 ],
                 [
                    119.748952,
                    46.612588
                 ],
                 [
                    119.756958,
                    46.616361
                 ],
                 [
                    119.76346,
                    46.618072
                 ],
                 [
                    119.771973,
                    46.623906
                 ],
                 [
                    119.778485,
                    46.62871
                 ],
                 [
                    119.782496,
                    46.63283
                 ],
                 [
                    119.78702,
                    46.64073
                 ],
                 [
                    119.78747650000003,
                    46.64369289999996
                 ],
                 [
                    119.7892324,
                    46.644842799999985
                 ],
                 [
                    119.79255009999997,
                    46.6461512
                 ],
                 [
                    119.79477940000004,
                    46.6480849
                 ],
                 [
                    119.7964996,
                    46.6527276
                 ],
                 [
                    119.79935540000002,
                    46.65578610000003
                 ],
                 [
                    119.80264359999995,
                    46.6617403
                 ],
                 [
                    119.8066877,
                    46.66730550000004
                 ],
                 [
                    119.80393719999996,
                    46.67170059999999
                 ],
                 [
                    119.80337,
                    46.67473130000001
                 ],
                 [
                    119.8013894,
                    46.6809712
                 ],
                 [
                    119.80255850000002,
                    46.681803600000045
                 ],
                 [
                    119.80815579999995,
                    46.67960940000004
                 ],
                 [
                    119.820587,
                    46.6747118
                 ],
                 [
                    119.82794660000002,
                    46.671817
                 ],
                 [
                    119.83278490000004,
                    46.67025789999999
                 ],
                 [
                    119.836937,
                    46.67060549999997
                 ],
                 [
                    119.84175050000005,
                    46.6715817
                 ],
                 [
                    119.8445046,
                    46.671348499999986
                 ],
                 [
                    119.8507562,
                    46.670233
                 ],
                 [
                    119.858798,
                    46.6683313
                 ],
                 [
                    119.86343750000005,
                    46.668502
                 ],
                 [
                    119.86744360000004,
                    46.668250200000045
                 ],
                 [
                    119.87080919999998,
                    46.667129799999984
                 ],
                 [
                    119.87390470000004,
                    46.66456380000003
                 ],
                 [
                    119.87943070000003,
                    46.66379219999996
                 ],
                 [
                    119.88843920000004,
                    46.66349330000001
                 ],
                 [
                    119.89335560000004,
                    46.66416650000002
                 ],
                 [
                    119.89694429999996,
                    46.6657109
                 ],
                 [
                    119.90044480000005,
                    46.668156
                 ],
                 [
                    119.901912,
                    46.669787
                 ],
                 [
                    119.90487929999996,
                    46.676309
                 ],
                 [
                    119.906095,
                    46.680767
                 ],
                 [
                    119.906219,
                    46.688339
                 ],
                 [
                    119.908255,
                    46.690388
                 ],
                 [
                    119.912833,
                    46.694825
                 ],
                 [
                    119.919425,
                    46.699934
                 ],
                 [
                    119.924523,
                    46.705399
                 ],
                 [
                    119.927654,
                    46.712945
                 ],
                 [
                    119.928766,
                    46.719476
                 ],
                 [
                    119.927811,
                    46.722237
                 ],
                 [
                    119.920353,
                    46.725397
                 ],
                 [
                    119.914936,
                    46.730949
                 ],
                 [
                    119.913543,
                    46.737501
                 ],
                 [
                    119.909115,
                    46.742356
                 ],
                 [
                    119.908193,
                    46.747182
                 ],
                 [
                    119.904265,
                    46.752033
                 ],
                 [
                    119.902307,
                    46.754803
                 ],
                 [
                    119.903348,
                    46.757204
                 ],
                 [
                    119.904891,
                    46.759601
                 ],
                 [
                    119.902448,
                    46.763407
                 ],
                 [
                    119.905011,
                    46.766828
                 ],
                 [
                    119.909549,
                    46.768512
                 ],
                 [
                    119.912107,
                    46.771589
                 ],
                 [
                    119.920201,
                    46.775997
                 ],
                 [
                    119.924776,
                    46.779745
                 ],
                 [
                    119.923866,
                    46.78526
                 ],
                 [
                    119.929987,
                    46.791404
                 ],
                 [
                    119.930187,
                    46.803106
                 ],
                 [
                    119.927271,
                    46.808637
                 ],
                 [
                    119.925843,
                    46.813124
                 ],
                 [
                    119.924892,
                    46.81623
                 ],
                 [
                    119.921976,
                    46.821761
                 ],
                 [
                    119.921509,
                    46.82383
                 ],
                 [
                    119.919601,
                    46.829697
                 ],
                 [
                    119.921696,
                    46.834843
                 ],
                 [
                    119.922763,
                    46.83862
                 ],
                 [
                    119.925856,
                    46.843413
                 ],
                 [
                    119.929918,
                    46.846133
                 ],
                 [
                    119.931949,
                    46.847493
                 ],
                 [
                    119.931488,
                    46.849906
                 ],
                 [
                    119.928483,
                    46.850275
                 ],
                 [
                    119.922448,
                    46.849637
                 ],
                 [
                    119.916904,
                    46.848307
                 ],
                 [
                    119.908903,
                    46.850094
                 ],
                 [
                    119.903964,
                    46.854953
                 ],
                 [
                    119.900034,
                    46.860148
                 ],
                 [
                    119.898602,
                    46.864634
                 ],
                 [
                    119.899669,
                    46.868412
                 ],
                 [
                    119.90219,
                    46.86908
                 ],
                 [
                    119.90275,
                    46.872517
                 ],
                 [
                    119.900348,
                    46.879076
                 ],
                 [
                    119.902944,
                    46.884218
                 ],
                 [
                    119.906517,
                    46.887631
                 ],
                 [
                    119.9096,
                    46.891736
                 ],
                 [
                    119.915218,
                    46.897197
                 ],
                 [
                    119.917771,
                    46.899585
                 ],
                 [
                    119.921322,
                    46.901621
                 ],
                 [
                    119.911818,
                    46.904109
                 ],
                 [
                    119.894307,
                    46.908727
                 ],
                 [
                    119.875303,
                    46.914386
                 ],
                 [
                    119.854284,
                    46.920058
                 ],
                 [
                    119.851875,
                    46.926617
                 ],
                 [
                    119.846534,
                    46.938705
                 ],
                 [
                    119.86435,
                    46.952335
                 ],
                 [
                    119.866406,
                    46.955072
                 ],
                 [
                    119.862957,
                    46.959574
                 ],
                 [
                    119.86404,
                    46.964384
                 ],
                 [
                    119.859027,
                    46.965456
                 ],
                 [
                    119.852477,
                    46.964819
                 ],
                 [
                    119.842422,
                    46.96524
                 ],
                 [
                    119.834422,
                    46.968399
                 ],
                 [
                    119.831982,
                    46.973237
                 ],
                 [
                    119.830012,
                    46.976005
                 ],
                 [
                    119.824499,
                    46.977423
                 ],
                 [
                    119.816034,
                    46.983338
                 ],
                 [
                    119.807101,
                    46.991665
                 ],
                 [
                    119.80517,
                    46.997186
                 ],
                 [
                    119.802731,
                    47.002367
                 ],
                 [
                    119.794763,
                    47.008277
                 ],
                 [
                    119.792348,
                    47.015178
                 ],
                 [
                    119.78342,
                    47.024536
                 ],
                 [
                    119.788507,
                    47.027942
                 ],
                 [
                    119.789066,
                    47.031724
                 ],
                 [
                    119.792637,
                    47.034796
                 ],
                 [
                    119.791161,
                    47.037216
                 ],
                 [
                    119.790215,
                    47.041353
                 ],
                 [
                    119.787772,
                    47.046534
                 ],
                 [
                    119.790866,
                    47.05133
                 ],
                 [
                    119.79798,
                    47.055409
                 ],
                 [
                    119.799045,
                    47.059188
                 ],
                 [
                    119.792153,
                    47.070252
                 ],
                 [
                    119.784275,
                    47.083045
                 ],
                 [
                    119.776889,
                    47.095144
                 ],
                 [
                    119.764074,
                    47.115887
                 ],
                 [
                    119.754679,
                    47.129376
                 ],
                 [
                    119.744228,
                    47.139774
                 ],
                 [
                    119.732758,
                    47.149834
                 ],
                 [
                    119.723289,
                    47.158847
                 ],
                 [
                    119.716346,
                    47.168187
                 ],
                 [
                    119.712964,
                    47.179568
                 ],
                 [
                    119.710563,
                    47.188877
                 ],
                 [
                    119.708097,
                    47.193368
                 ],
                 [
                    119.706595,
                    47.19441
                 ],
                 [
                    119.704074,
                    47.194771
                 ],
                 [
                    119.702071,
                    47.196161
                 ],
                 [
                    119.699563,
                    47.197554
                 ],
                 [
                    119.69756,
                    47.198943
                 ],
                 [
                    119.694037,
                    47.199999
                 ],
                 [
                    119.691515,
                    47.200359
                 ],
                 [
                    119.690505,
                    47.200366
                 ],
                 [
                    119.688506,
                    47.202099
                 ],
                 [
                    119.688032,
                    47.204512
                 ],
                 [
                    119.685009,
                    47.205219
                 ],
                 [
                    119.678467,
                    47.207326
                 ],
                 [
                    119.67696,
                    47.208024
                 ],
                 [
                    119.676494,
                    47.211125
                 ],
                 [
                    119.674486,
                    47.21217
                 ],
                 [
                    119.670957,
                    47.21288
                 ],
                 [
                    119.66643,
                    47.214629
                 ],
                 [
                    119.663445,
                    47.218434
                 ],
                 [
                    119.660935,
                    47.219826
                 ],
                 [
                    119.656437,
                    47.223984
                 ],
                 [
                    119.651412,
                    47.226424
                 ],
                 [
                    119.650427,
                    47.228495
                 ],
                 [
                    119.647402,
                    47.229202
                 ],
                 [
                    119.644883,
                    47.229906
                 ],
                 [
                    119.643884,
                    47.230944
                 ],
                 [
                    119.642898,
                    47.233015
                 ],
                 [
                    119.639881,
                    47.23441
                 ],
                 [
                    119.639388,
                    47.235446
                 ],
                 [
                    119.631812,
                    47.236179
                 ],
                 [
                    119.631833,
                    47.2379
                 ],
                 [
                    119.630345,
                    47.240318
                 ],
                 [
                    119.625802,
                    47.241033
                 ],
                 [
                    119.624297,
                    47.242075
                 ],
                 [
                    119.62331,
                    47.244146
                 ],
                 [
                    119.619783,
                    47.245199
                 ],
                 [
                    119.613718,
                    47.245578
                 ],
                 [
                    119.608677,
                    47.246984
                 ],
                 [
                    119.602099,
                    47.246677
                 ],
                 [
                    119.598057,
                    47.247043
                 ],
                 [
                    119.595525,
                    47.246713
                 ],
                 [
                    119.590962,
                    47.245706
                 ],
                 [
                    119.586916,
                    47.245728
                 ],
                 [
                    119.583376,
                    47.245748
                 ],
                 [
                    119.581342,
                    47.244726
                 ],
                 [
                    119.575779,
                    47.244757
                 ],
                 [
                    119.571216,
                    47.243748
                 ],
                 [
                    119.57034449999998,
                    47.244057
                 ],
                 [
                    119.57008640000004,
                    47.2462478
                 ],
                 [
                    119.566161,
                    47.24672839999997
                 ],
                 [
                    119.5607267,
                    47.24648240000004
                 ],
                 [
                    119.55824540000005,
                    47.2489289
                 ],
                 [
                    119.55800040000003,
                    47.2509657
                 ],
                 [
                    119.55591030000002,
                    47.25337319999998
                 ],
                 [
                    119.5538674,
                    47.253803599999976
                 ],
                 [
                    119.553395,
                    47.252921800000024
                 ],
                 [
                    119.55227110000001,
                    47.25338609999998
                 ],
                 [
                    119.55173050000005,
                    47.2550168
                 ],
                 [
                    119.5507887,
                    47.25609549999998
                 ],
                 [
                    119.55109170000003,
                    47.2565068
                 ],
                 [
                    119.55203420000004,
                    47.25631869999997
                 ],
                 [
                    119.55329149999997,
                    47.2566174
                 ],
                 [
                    119.55433809999997,
                    47.2566174
                 ],
                 [
                    119.5554741,
                    47.255846299999966
                 ],
                 [
                    119.55693010000002,
                    47.2561901
                 ],
                 [
                    119.55761739999996,
                    47.255978
                 ],
                 [
                    119.5589479,
                    47.25640579999999
                 ],
                 [
                    119.55963349999996,
                    47.2577554
                 ],
                 [
                    119.55783840000004,
                    47.2588835
                 ],
                 [
                    119.55640070000001,
                    47.25911770000002
                 ],
                 [
                    119.5563509,
                    47.25979339999997
                 ],
                 [
                    119.55736739999998,
                    47.26063660000001
                 ],
                 [
                    119.5589672,
                    47.260982
                 ],
                 [
                    119.558063,
                    47.26164690000003
                 ],
                 [
                    119.55799649999997,
                    47.262729100000044
                 ],
                 [
                    119.55637349999998,
                    47.262729100000044
                 ],
                 [
                    119.55672339999998,
                    47.263382
                 ],
                 [
                    119.55842489999998,
                    47.263566799999985
                 ],
                 [
                    119.5575225,
                    47.26469740000001
                 ],
                 [
                    119.557744,
                    47.26559920000002
                 ],
                 [
                    119.55912369999999,
                    47.2663794
                 ],
                 [
                    119.5608006,
                    47.266674399999985
                 ],
                 [
                    119.56183790000003,
                    47.26751909999997
                 ],
                 [
                    119.5607416,
                    47.26966819999998
                 ],
                 [
                    119.56174760000002,
                    47.27023030000004
                 ],
                 [
                    119.56181149999998,
                    47.272007900000034
                 ],
                 [
                    119.56168010000002,
                    47.27343369999996
                 ],
                 [
                    119.5598367,
                    47.27395129999998
                 ],
                 [
                    119.55899139999995,
                    47.27477070000001
                 ],
                 [
                    119.5580054,
                    47.27524850000002
                 ],
                 [
                    119.5567137,
                    47.274836
                 ],
                 [
                    119.55725770000004,
                    47.272498
                 ],
                 [
                    119.5559251,
                    47.2723688
                 ],
                 [
                    119.55575819999996,
                    47.27279340000001
                 ],
                 [
                    119.5566315,
                    47.27334369999998
                 ],
                 [
                    119.55611689999998,
                    47.274191599999966
                 ],
                 [
                    119.55441109999997,
                    47.27472580000003
                 ],
                 [
                    119.55170230000002,
                    47.27480750000004
                 ],
                 [
                    119.55071229999999,
                    47.2756658
                 ],
                 [
                    119.5510217,
                    47.27679909999998
                 ],
                 [
                    119.549402,
                    47.27793870000003
                 ],
                 [
                    119.54994019999997,
                    47.27852289999996
                 ],
                 [
                    119.55033910000003,
                    47.279180099999984
                 ],
                 [
                    119.5512245,
                    47.27935680000004
                 ],
                 [
                    119.55272569999997,
                    47.278993099999965
                 ],
                 [
                    119.55261079999995,
                    47.2783698
                 ],
                 [
                    119.55337109999998,
                    47.277854099999956
                 ],
                 [
                    119.55450820000001,
                    47.278539800000026
                 ],
                 [
                    119.5552187,
                    47.278250499999984
                 ],
                 [
                    119.55536720000003,
                    47.277646
                 ],
                 [
                    119.55429719999995,
                    47.27708139999997
                 ],
                 [
                    119.55406679999999,
                    47.2762476
                 ],
                 [
                    119.55550389999996,
                    47.27588830000002
                 ],
                 [
                    119.5571088,
                    47.276750300000025
                 ],
                 [
                    119.5577798,
                    47.27782649999998
                 ],
                 [
                    119.558447,
                    47.2781175
                 ],
                 [
                    119.5590939,
                    47.2776785
                 ],
                 [
                    119.55960479999999,
                    47.276638900000044
                 ],
                 [
                    119.56178369999998,
                    47.27624470000002
                 ],
                 [
                    119.56194340000005,
                    47.27721950000003
                 ],
                 [
                    119.56080450000005,
                    47.2780328
                 ],
                 [
                    119.56194440000004,
                    47.27982380000004
                 ],
                 [
                    119.56151580000002,
                    47.2810288
                 ],
                 [
                    119.5622487,
                    47.28206840000004
                 ],
                 [
                    119.56109630000003,
                    47.282436300000015
                 ],
                 [
                    119.5605644,
                    47.283473500000035
                 ],
                 [
                    119.55927680000003,
                    47.283146
                 ],
                 [
                    119.55895570000001,
                    47.2819692
                 ],
                 [
                    119.55794010000001,
                    47.2808345
                 ],
                 [
                    119.55732180000001,
                    47.28034520000001
                 ],
                 [
                    119.5559508,
                    47.280846
                 ],
                 [
                    119.5554861,
                    47.28210680000002
                 ],
                 [
                    119.55541780000004,
                    47.2828483
                 ],
                 [
                    119.55365329999998,
                    47.2833271
                 ],
                 [
                    119.553771,
                    47.283506600000024
                 ],
                 [
                    119.55543790000002,
                    47.2837704
                 ],
                 [
                    119.55709159999998,
                    47.28316640000003
                 ],
                 [
                    119.55819590000004,
                    47.2838274
                 ],
                 [
                    119.55957,
                    47.28395450000004
                 ],
                 [
                    119.56039549999996,
                    47.2847011
                 ],
                 [
                    119.5604712,
                    47.2856242
                 ],
                 [
                    119.55815190000004,
                    47.2857991
                 ],
                 [
                    119.5566288,
                    47.28707820000004
                 ],
                 [
                    119.55632050000003,
                    47.285823499999964
                 ],
                 [
                    119.55529139999997,
                    47.285230099999985
                 ],
                 [
                    119.5535259,
                    47.2863864
                 ],
                 [
                    119.5517318,
                    47.28557510000002
                 ],
                 [
                    119.55205609999999,
                    47.287445699999985
                 ],
                 [
                    119.55067520000003,
                    47.28907470000003
                 ],
                 [
                    119.55143459999998,
                    47.28981039999997
                 ],
                 [
                    119.55263449999995,
                    47.2902914
                 ],
                 [
                    119.55384009999997,
                    47.289732
                 ],
                 [
                    119.5547311,
                    47.29039119999998
                 ],
                 [
                    119.55413119999999,
                    47.29120489999998
                 ],
                 [
                    119.55315290000004,
                    47.29132929999997
                 ],
                 [
                    119.55283969999998,
                    47.291789500000036
                 ],
                 [
                    119.55237450000004,
                    47.29352540000004
                 ],
                 [
                    119.55352149999999,
                    47.29418650000003
                 ],
                 [
                    119.55448060000003,
                    47.29488720000001
                 ],
                 [
                    119.55287269999995,
                    47.29572990000001
                 ],
                 [
                    119.55126809999997,
                    47.295447700000025
                 ],
                 [
                    119.55098340000004,
                    47.295778599999956
                 ],
                 [
                    119.55211169999995,
                    47.296849899999955
                 ],
                 [
                    119.553547,
                    47.2974848
                 ],
                 [
                    119.5538562,
                    47.29803
                 ],
                 [
                    119.55477470000002,
                    47.298486700000026
                 ],
                 [
                    119.55545129999996,
                    47.299588
                 ],
                 [
                    119.55475369999998,
                    47.300345
                 ],
                 [
                    119.55273669999998,
                    47.301120099999956
                 ],
                 [
                    119.55180560000004,
                    47.30054120000002
                 ],
                 [
                    119.55211570000003,
                    47.299447899999976
                 ],
                 [
                    119.5512126,
                    47.29872739999996
                 ],
                 [
                    119.54977860000001,
                    47.29890740000002
                 ],
                 [
                    119.54946479999998,
                    47.299394
                 ],
                 [
                    119.5504116,
                    47.29977920000003
                 ],
                 [
                    119.5498204,
                    47.300409200000026
                 ],
                 [
                    119.54794139999996,
                    47.30063680000003
                 ],
                 [
                    119.5467395,
                    47.30018849999997
                 ],
                 [
                    119.5448418,
                    47.30076540000001
                 ],
                 [
                    119.54421940000003,
                    47.29942229999999
                 ],
                 [
                    119.54342649999997,
                    47.300161699999975
                 ],
                 [
                    119.54132620000001,
                    47.3003843
                 ],
                 [
                    119.5398147,
                    47.3000182
                 ],
                 [
                    119.53977730000001,
                    47.30042420000002
                 ],
                 [
                    119.54088789999997,
                    47.30097920000002
                 ],
                 [
                    119.54143040000001,
                    47.30160979999997
                 ],
                 [
                    119.53994429999997,
                    47.3023776
                 ],
                 [
                    119.53759549999997,
                    47.30242190000002
                 ],
                 [
                    119.53643079999998,
                    47.30181789999996
                 ],
                 [
                    119.536124,
                    47.300652900000024
                 ],
                 [
                    119.53429369999998,
                    47.2997219
                 ],
                 [
                    119.53252530000003,
                    47.299915400000025
                 ],
                 [
                    119.53072270000001,
                    47.300811900000035
                 ],
                 [
                    119.52928360000004,
                    47.3015145
                 ],
                 [
                    119.5293847,
                    47.30216550000004
                 ],
                 [
                    119.5319324,
                    47.30348189999997
                 ],
                 [
                    119.5326022,
                    47.30443560000001
                 ],
                 [
                    119.53201020000004,
                    47.305729
                 ],
                 [
                    119.5300583,
                    47.307006899999955
                 ],
                 [
                    119.527335,
                    47.30543730000003
                 ],
                 [
                    119.5282922,
                    47.304220200000046
                 ],
                 [
                    119.5258417,
                    47.30346489999999
                 ],
                 [
                    119.52424130000003,
                    47.303590100000044
                 ],
                 [
                    119.5232138,
                    47.303241599999986
                 ],
                 [
                    119.5232138,
                    47.30246219999998
                 ],
                 [
                    119.522242,
                    47.30246219999998
                 ],
                 [
                    119.522047,
                    47.30325590000002
                 ],
                 [
                    119.5209176,
                    47.30300070000004
                 ],
                 [
                    119.52110370000003,
                    47.30220119999997
                 ],
                 [
                    119.520708,
                    47.30208940000004
                 ],
                 [
                    119.52021250000001,
                    47.30292919999999
                 ],
                 [
                    119.52136340000001,
                    47.3040383
                 ],
                 [
                    119.52073059999998,
                    47.306613
                 ],
                 [
                    119.51997179999998,
                    47.307642
                 ],
                 [
                    119.51808640000004,
                    47.307394499999965
                 ],
                 [
                    119.5170051,
                    47.30936239999998
                 ],
                 [
                    119.517309,
                    47.310014700000046
                 ],
                 [
                    119.51845890000004,
                    47.31004719999998
                 ],
                 [
                    119.5187032,
                    47.30909470000004
                 ],
                 [
                    119.52031450000001,
                    47.30909470000004
                 ],
                 [
                    119.5208606,
                    47.31024659999996
                 ],
                 [
                    119.52243829999996,
                    47.3101085
                 ],
                 [
                    119.522519,
                    47.30969819999997
                 ],
                 [
                    119.52115970000004,
                    47.30933759999998
                 ],
                 [
                    119.5207641,
                    47.30831830000002
                 ],
                 [
                    119.52323809999997,
                    47.30817470000004
                 ],
                 [
                    119.52469530000002,
                    47.30897440000003
                 ],
                 [
                    119.524238,
                    47.31012639999996
                 ],
                 [
                    119.524238,
                    47.31115939999999
                 ],
                 [
                    119.52172080000003,
                    47.31120109999998
                 ],
                 [
                    119.52172080000003,
                    47.312413900000045
                 ],
                 [
                    119.52290229999998,
                    47.313054700000045
                 ],
                 [
                    119.5237334,
                    47.3140408
                 ],
                 [
                    119.52266470000004,
                    47.314817
                 ],
                 [
                    119.5207025,
                    47.314129
                 ],
                 [
                    119.51935539999997,
                    47.312858300000045
                 ],
                 [
                    119.51838370000004,
                    47.3131219
                 ],
                 [
                    119.51816389999996,
                    47.31401630000003
                 ],
                 [
                    119.5185603,
                    47.31516810000002
                 ],
                 [
                    119.52094380000003,
                    47.31583089999997
                 ],
                 [
                    119.5213834,
                    47.31672509999998
                 ],
                 [
                    119.520188,
                    47.317485
                 ],
                 [
                    119.51755859999997,
                    47.31632630000002
                 ],
                 [
                    119.5154643,
                    47.3140139
                 ],
                 [
                    119.51504769999997,
                    47.314084499999986
                 ],
                 [
                    119.51545940000003,
                    47.316556699999985
                 ],
                 [
                    119.51526850000003,
                    47.31845410000004
                 ],
                 [
                    119.51351240000004,
                    47.319600600000044
                 ],
                 [
                    119.51073320000003,
                    47.32001920000004
                 ],
                 [
                    119.50884629999996,
                    47.32091459999996
                 ],
                 [
                    119.507053,
                    47.32058729999997
                 ],
                 [
                    119.5066172,
                    47.31974340000004
                 ],
                 [
                    119.50596830000005,
                    47.3195549
                 ],
                 [
                    119.505358,
                    47.31996870000003
                 ],
                 [
                    119.5039538,
                    47.32029979999999
                 ],
                 [
                    119.503334,
                    47.32079
                 ],
                 [
                    119.50373530000003,
                    47.3214701
                 ],
                 [
                    119.50490879999995,
                    47.32158950000002
                 ],
                 [
                    119.50648340000004,
                    47.322567899999974
                 ],
                 [
                    119.50663890000003,
                    47.32335850000002
                 ],
                 [
                    119.50466629999997,
                    47.32377349999999
                 ],
                 [
                    119.50253019999997,
                    47.323817399999974
                 ],
                 [
                    119.5011661,
                    47.32298520000004
                 ],
                 [
                    119.50128089999998,
                    47.321973
                 ],
                 [
                    119.5001076,
                    47.321909400000024
                 ],
                 [
                    119.49974720000002,
                    47.322607400000045
                 ],
                 [
                    119.50023670000002,
                    47.32468090000001
                 ],
                 [
                    119.4998243,
                    47.32584570000002
                 ],
                 [
                    119.49818320000001,
                    47.32594240000003
                 ],
                 [
                    119.49681419999999,
                    47.32520009999998
                 ],
                 [
                    119.49681419999999,
                    47.32432739999999
                 ],
                 [
                    119.495822,
                    47.32262630000001
                 ],
                 [
                    119.4939257,
                    47.322259
                 ],
                 [
                    119.49293630000003,
                    47.32344250000004
                 ],
                 [
                    119.4913924,
                    47.324108399999986
                 ],
                 [
                    119.490488,
                    47.32339319999996
                 ],
                 [
                    119.49054480000004,
                    47.32227589999997
                 ],
                 [
                    119.48698380000002,
                    47.32227589999997
                 ],
                 [
                    119.48674639999999,
                    47.322855200000035
                 ],
                 [
                    119.48764689999999,
                    47.32362839999997
                 ],
                 [
                    119.48764689999999,
                    47.324506300000046
                 ],
                 [
                    119.48594810000003,
                    47.32465640000003
                 ],
                 [
                    119.48490670000001,
                    47.3238563
                 ],
                 [
                    119.484846,
                    47.3227872
                 ],
                 [
                    119.48397970000005,
                    47.322303599999955
                 ],
                 [
                    119.48341680000001,
                    47.322509
                 ],
                 [
                    119.4835335,
                    47.3241311
                 ],
                 [
                    119.4835335,
                    47.3255277
                 ],
                 [
                    119.48272890000004,
                    47.32602339999998
                 ],
                 [
                    119.4811623,
                    47.32598259999998
                 ],
                 [
                    119.48024429999998,
                    47.3268952
                 ],
                 [
                    119.47906860000005,
                    47.3268952
                 ],
                 [
                    119.477672,
                    47.32594860000001
                 ],
                 [
                    119.47475349999996,
                    47.326297700000026
                 ],
                 [
                    119.47437530000003,
                    47.32687449999997
                 ],
                 [
                    119.47541469999996,
                    47.32736770000001
                 ],
                 [
                    119.47732410000003,
                    47.3274094
                 ],
                 [
                    119.47841839999998,
                    47.32799209999998
                 ],
                 [
                    119.477549,
                    47.328581399999976
                 ],
                 [
                    119.4765016,
                    47.3288575
                 ],
                 [
                    119.47614469999996,
                    47.32979060000002
                 ],
                 [
                    119.4783275,
                    47.33034
                 ],
                 [
                    119.477815,
                    47.33109270000002
                 ],
                 [
                    119.475891,
                    47.331174200000014
                 ],
                 [
                    119.47570750000003,
                    47.33192050000003
                 ],
                 [
                    119.47768519999998,
                    47.33208289999997
                 ],
                 [
                    119.4778126,
                    47.33303309999999
                 ],
                 [
                    119.47877760000003,
                    47.33336010000004
                 ],
                 [
                    119.4799204,
                    47.333474299999985
                 ],
                 [
                    119.47978389999999,
                    47.3348076
                 ],
                 [
                    119.47876340000002,
                    47.33567220000003
                 ],
                 [
                    119.4772175,
                    47.3357159
                 ],
                 [
                    119.4759917,
                    47.334843500000034
                 ],
                 [
                    119.474868,
                    47.33491970000003
                 ],
                 [
                    119.4747485,
                    47.33581009999998
                 ],
                 [
                    119.4739746,
                    47.33633450000003
                 ],
                 [
                    119.47196120000004,
                    47.3366239
                 ],
                 [
                    119.4710135,
                    47.33778790000002
                 ],
                 [
                    119.471733,
                    47.33921010000002
                 ],
                 [
                    119.4735868,
                    47.34050810000003
                 ],
                 [
                    119.473522,
                    47.34129759999998
                 ],
                 [
                    119.47281809999998,
                    47.34190460000003
                 ],
                 [
                    119.47055839999996,
                    47.342525399999964
                 ],
                 [
                    119.46996430000002,
                    47.343531800000015
                 ],
                 [
                    119.47021049999995,
                    47.34515840000004
                 ],
                 [
                    119.46909739999997,
                    47.34645720000003
                 ],
                 [
                    119.46829510000002,
                    47.34838090000004
                 ],
                 [
                    119.4667233,
                    47.34922770000003
                 ],
                 [
                    119.46499120000004,
                    47.3489235
                 ],
                 [
                    119.46328469999997,
                    47.34888220000004
                 ],
                 [
                    119.462255,
                    47.35019539999998
                 ],
                 [
                    119.46300220000003,
                    47.351292200000046
                 ],
                 [
                    119.46300220000003,
                    47.352283099999966
                 ],
                 [
                    119.4613355,
                    47.35264440000002
                 ],
                 [
                    119.45896509999997,
                    47.35163010000003
                 ],
                 [
                    119.45771149999999,
                    47.352560299999986
                 ],
                 [
                    119.4578919,
                    47.3534568
                 ],
                 [
                    119.4589142,
                    47.35414940000002
                 ],
                 [
                    119.46072520000003,
                    47.35389549999997
                 ],
                 [
                    119.46192589999998,
                    47.354257
                 ],
                 [
                    119.46211669999997,
                    47.355635699999986
                 ],
                 [
                    119.46363159999999,
                    47.356662
                 ],
                 [
                    119.46369679999995,
                    47.3581618
                 ],
                 [
                    119.46226130000002,
                    47.35895740000002
                 ],
                 [
                    119.4599542,
                    47.35912639999997
                 ],
                 [
                    119.4591973,
                    47.36049350000002
                 ],
                 [
                    119.457664,
                    47.36058380000003
                 ],
                 [
                    119.4554135,
                    47.35927110000004
                 ],
                 [
                    119.45417710000002,
                    47.35978960000004
                 ],
                 [
                    119.45417710000002,
                    47.361344900000034
                 ],
                 [
                    119.453788,
                    47.362618900000015
                 ],
                 [
                    119.44847139999999,
                    47.36383369999999
                 ],
                 [
                    119.44720039999999,
                    47.363145
                 ],
                 [
                    119.446081,
                    47.36318490000004
                 ],
                 [
                    119.44573330000003,
                    47.3636951
                 ],
                 [
                    119.44597729999995,
                    47.3646455
                 ],
                 [
                    119.4475012,
                    47.36580659999998
                 ],
                 [
                    119.4469804,
                    47.3676586
                 ],
                 [
                    119.44556910000003,
                    47.36793170000004
                 ],
                 [
                    119.44406769999996,
                    47.36709159999999
                 ],
                 [
                    119.443629,
                    47.36603050000003
                 ],
                 [
                    119.44211849999998,
                    47.3654985
                 ],
                 [
                    119.44041880000003,
                    47.366033
                 ],
                 [
                    119.43979830000004,
                    47.367167699999975
                 ],
                 [
                    119.43938610000004,
                    47.3677261
                 ],
                 [
                    119.43826889999997,
                    47.36744229999996
                 ],
                 [
                    119.43801449999997,
                    47.36658090000001
                 ],
                 [
                    119.437324,
                    47.36626909999997
                 ],
                 [
                    119.43601369999998,
                    47.3667129
                 ],
                 [
                    119.43571019999999,
                    47.3677819
                 ],
                 [
                    119.43671420000003,
                    47.36960950000004
                 ],
                 [
                    119.43690850000002,
                    47.37066220000001
                 ],
                 [
                    119.43605129999997,
                    47.37142140000003
                 ],
                 [
                    119.43365390000002,
                    47.3717633
                 ],
                 [
                    119.43236289999997,
                    47.37280410000004
                 ],
                 [
                    119.43192350000004,
                    47.37518470000001
                 ],
                 [
                    119.43127910000001,
                    47.37640650000003
                 ],
                 [
                    119.42820620000003,
                    47.37740360000002
                 ],
                 [
                    119.42253349999999,
                    47.377274099999966
                 ],
                 [
                    119.42095690000002,
                    47.37552310000004
                 ],
                 [
                    119.41894240000005,
                    47.37544040000003
                 ],
                 [
                    119.4179393,
                    47.37584
                 ],
                 [
                    119.41817589999995,
                    47.37676119999998
                 ],
                 [
                    119.42000519999996,
                    47.3775301
                 ],
                 [
                    119.42033179999999,
                    47.3790779
                 ],
                 [
                    119.41927180000003,
                    47.3797059
                 ],
                 [
                    119.41755960000003,
                    47.3798348
                 ],
                 [
                    119.41549180000001,
                    47.3805561
                 ],
                 [
                    119.4124194,
                    47.381575
                 ],
                 [
                    119.411696,
                    47.38222809999996
                 ],
                 [
                    119.41219570000004,
                    47.38387770000003
                 ],
                 [
                    119.41200059999997,
                    47.3849346
                 ],
                 [
                    119.4092196,
                    47.38624829999998
                 ],
                 [
                    119.40782450000003,
                    47.385708499999986
                 ],
                 [
                    119.40719049999998,
                    47.384291900000015
                 ],
                 [
                    119.405779,
                    47.38329460000001
                 ],
                 [
                    119.40453159999998,
                    47.383334799999965
                 ],
                 [
                    119.40381080000003,
                    47.38406680000004
                 ],
                 [
                    119.40381080000003,
                    47.38538790000002
                 ],
                 [
                    119.40277710000004,
                    47.386612700000015
                 ],
                 [
                    119.40099820000002,
                    47.3870429
                 ],
                 [
                    119.3979837,
                    47.3887861
                 ],
                 [
                    119.39565460000003,
                    47.39014990000003
                 ],
                 [
                    119.39355989999999,
                    47.391654
                 ],
                 [
                    119.3885826,
                    47.39233640000002
                 ],
                 [
                    119.38704709999998,
                    47.39358380000002
                 ],
                 [
                    119.38736130000004,
                    47.39515789999997
                 ],
                 [
                    119.38656729999997,
                    47.39605370000002
                 ],
                 [
                    119.3847799,
                    47.39605370000002
                 ],
                 [
                    119.3838955,
                    47.39524120000002
                 ],
                 [
                    119.38207010000002,
                    47.395158799999955
                 ],
                 [
                    119.38069559999998,
                    47.3958355
                 ],
                 [
                    119.37521170000002,
                    47.39694490000004
                 ],
                 [
                    119.37234819999996,
                    47.3983776
                 ],
                 [
                    119.37191970000003,
                    47.3998693
                 ],
                 [
                    119.37291729999998,
                    47.40075560000003
                 ],
                 [
                    119.37324660000004,
                    47.40178080000003
                 ],
                 [
                    119.37212509999998,
                    47.40240589999998
                 ],
                 [
                    119.36938,
                    47.4029676
                 ],
                 [
                    119.365341,
                    47.40241239999998
                 ],
                 [
                    119.3632993,
                    47.40266359999998
                 ],
                 [
                    119.3620888,
                    47.403442
                 ],
                 [
                    119.36244870000004,
                    47.40449750000002
                 ],
                 [
                    119.36390659999996,
                    47.405032
                 ],
                 [
                    119.365604,
                    47.40486179999996
                 ],
                 [
                    119.36669649999996,
                    47.40516630000002
                 ],
                 [
                    119.3680324,
                    47.40555380000002
                 ],
                 [
                    119.3690777,
                    47.406172799999986
                 ],
                 [
                    119.3690777,
                    47.407789
                 ],
                 [
                    119.36829770000003,
                    47.4088007
                 ],
                 [
                    119.3662836,
                    47.40928439999996
                 ],
                 [
                    119.36476410000003,
                    47.408813
                 ],
                 [
                    119.36343429999997,
                    47.4089767
                 ],
                 [
                    119.36246910000004,
                    47.40991569999997
                 ],
                 [
                    119.36321519999996,
                    47.41117819999998
                 ],
                 [
                    119.36321519999996,
                    47.4120341
                 ],
                 [
                    119.361986,
                    47.41240380000004
                 ],
                 [
                    119.36072030000003,
                    47.411633
                 ],
                 [
                    119.35935050000003,
                    47.41191510000002
                 ],
                 [
                    119.35880789999999,
                    47.41285350000004
                 ],
                 [
                    119.35948670000002,
                    47.414189700000016
                 ],
                 [
                    119.36039119999998,
                    47.4148893
                 ],
                 [
                    119.35955990000002,
                    47.416274
                 ],
                 [
                    119.35961990000001,
                    47.41712729999998
                 ],
                 [
                    119.36152650000002,
                    47.41781539999998
                 ],
                 [
                    119.36112620000004,
                    47.41885390000002
                 ],
                 [
                    119.35980040000004,
                    47.419622799999956
                 ],
                 [
                    119.35865930000003,
                    47.42198190000002
                 ],
                 [
                    119.3571916,
                    47.422252700000044
                 ],
                 [
                    119.35618260000001,
                    47.421698
                 ],
                 [
                    119.35480030000004,
                    47.421942
                 ],
                 [
                    119.35379660000004,
                    47.42291820000004
                 ],
                 [
                    119.35154450000005,
                    47.4236583
                 ],
                 [
                    119.3499768,
                    47.42336130000002
                 ],
                 [
                    119.34809589999998,
                    47.42429449999996
                 ],
                 [
                    119.346391,
                    47.42429449999996
                 ],
                 [
                    119.3454381,
                    47.423434799999974
                 ],
                 [
                    119.34385410000002,
                    47.4231463
                 ],
                 [
                    119.34247380000004,
                    47.4235929
                 ],
                 [
                    119.3424153,
                    47.4244249
                 ],
                 [
                    119.34440590000003,
                    47.425266600000015
                 ],
                 [
                    119.34560270000001,
                    47.42637460000003
                 ],
                 [
                    119.347694,
                    47.427189200000015
                 ],
                 [
                    119.34815770000003,
                    47.4280854
                 ],
                 [
                    119.347497,
                    47.42880050000002
                 ],
                 [
                    119.346009,
                    47.4294133
                 ],
                 [
                    119.34362960000001,
                    47.429282800000045
                 ],
                 [
                    119.33978460000002,
                    47.4274918
                 ],
                 [
                    119.33813590000003,
                    47.427409200000014
                 ],
                 [
                    119.33679769999998,
                    47.428149900000015
                 ],
                 [
                    119.33647449999997,
                    47.429663700000035
                 ],
                 [
                    119.33516780000002,
                    47.43050119999996
                 ],
                 [
                    119.3337813,
                    47.4299853
                 ],
                 [
                    119.33323719999999,
                    47.4290192
                 ],
                 [
                    119.33370939999998,
                    47.42796960000002
                 ],
                 [
                    119.33475929999997,
                    47.42725920000004
                 ],
                 [
                    119.3352512,
                    47.426385899999985
                 ],
                 [
                    119.333717,
                    47.425887799999984
                 ],
                 [
                    119.33194410000002,
                    47.42655409999996
                 ],
                 [
                    119.3306665,
                    47.42602580000003
                 ],
                 [
                    119.3313492,
                    47.424963399999974
                 ],
                 [
                    119.33186360000002,
                    47.424180400000026
                 ],
                 [
                    119.33154780000001,
                    47.4228555
                 ],
                 [
                    119.32953259999996,
                    47.4224293
                 ],
                 [
                    119.3280086,
                    47.423030900000036
                 ],
                 [
                    119.32688959999996,
                    47.4242778
                 ],
                 [
                    119.32524890000002,
                    47.42460159999997
                 ],
                 [
                    119.32402730000001,
                    47.4239586
                 ],
                 [
                    119.32332310000001,
                    47.423222399999986
                 ],
                 [
                    119.3218599,
                    47.423222399999986
                 ],
                 [
                    119.32154519999999,
                    47.42428680000003
                 ],
                 [
                    119.321622,
                    47.42480569999998
                 ],
                 [
                    119.32241820000002,
                    47.43398669999996
                 ],
                 [
                    119.329567,
                    47.45206069999998
                 ],
                 [
                    119.3544769,
                    47.47262950000002
                 ],
                 [
                    119.35764309999998,
                    47.47959520000002
                 ],
                 [
                    119.19019560000001,
                    47.5221109
                 ],
                 [
                    119.159497,
                    47.53465219999999
                 ],
                 [
                    119.142807,
                    47.5423672
                 ],
                 [
                    119.14100649999997,
                    47.5495529
                 ],
                 [
                    119.12867740000002,
                    47.6237144
                 ],
                 [
                    119.12367249999998,
                    47.65584560000004
                 ],
                 [
                    119.12168119999998,
                    47.66403959999998
                 ],
                 [
                    119.1096802,
                    47.66791149999998
                 ],
                 [
                    118.75482180000002,
                    47.7720795
                 ],
                 [
                    118.58537290000001,
                    47.96155549999998
                 ],
                 [
                    118.5609131,
                    47.987915
                 ],
                 [
                    118.55493159999997,
                    47.993461599999975
                 ],
                 [
                    118.45486739999996,
                    47.99623720000003
                 ],
                 [
                    118.45063430000002,
                    47.996459400000035
                 ],
                 [
                    118.449873,
                    47.997205799999975
                 ],
                 [
                    118.44767629999996,
                    47.99769580000002
                 ],
                 [
                    118.4451379,
                    47.9975744
                 ],
                 [
                    118.44105920000001,
                    47.99543840000002
                 ],
                 [
                    118.4402004,
                    47.9935994
                 ],
                 [
                    118.4385663,
                    47.99272460000001
                 ],
                 [
                    118.4367845,
                    47.99207410000001
                 ],
                 [
                    118.43528669999998,
                    47.9922746
                 ],
                 [
                    118.43436119999996,
                    47.99341
                 ],
                 [
                    118.43485949999997,
                    47.995410800000045
                 ],
                 [
                    118.4355512,
                    47.99737810000002
                 ],
                 [
                    118.4355512,
                    47.99973530000001
                 ],
                 [
                    118.43464860000003,
                    48.001063899999984
                 ],
                 [
                    118.4328267,
                    48.002283
                 ],
                 [
                    118.43034960000003,
                    48.00240139999998
                 ],
                 [
                    118.42859860000002,
                    48.002720900000035
                 ],
                 [
                    118.42729960000001,
                    48.004350500000044
                 ],
                 [
                    118.4269569,
                    48.00561150000002
                 ],
                 [
                    118.42605870000001,
                    48.0068134
                 ],
                 [
                    118.42426420000004,
                    48.00789390000004
                 ],
                 [
                    118.420868,
                    48.008689300000036
                 ],
                 [
                    118.41973080000002,
                    48.0098847
                 ],
                 [
                    118.41890010000003,
                    48.01132980000002
                 ],
                 [
                    118.41890010000003,
                    48.013467699999985
                 ],
                 [
                    118.41749270000003,
                    48.0140057
                 ],
                 [
                    118.4147463,
                    48.0138832
                 ],
                 [
                    118.4132911,
                    48.013031499999954
                 ],
                 [
                    118.4120608,
                    48.01209080000002
                 ],
                 [
                    118.4108736,
                    48.01072929999999
                 ],
                 [
                    118.40889450000003,
                    48.00973639999999
                 ],
                 [
                    118.40675950000005,
                    48.00929690000004
                 ],
                 [
                    118.40521419999999,
                    48.00940019999997
                 ],
                 [
                    118.40375690000003,
                    48.010700199999974
                 ],
                 [
                    118.40257520000004,
                    48.01239409999997
                 ],
                 [
                    118.40204120000001,
                    48.0135849
                 ],
                 [
                    118.40028320000003,
                    48.014499600000015
                 ],
                 [
                    118.39834709999997,
                    48.01372250000002
                 ],
                 [
                    118.39744309999996,
                    48.012634
                 ],
                 [
                    118.3965885,
                    48.0110332
                 ],
                 [
                    118.39565660000002,
                    48.010098
                 ],
                 [
                    118.39399820000003,
                    48.010098
                 ],
                 [
                    118.3928631,
                    48.0106405
                 ],
                 [
                    118.39123029999999,
                    48.01136860000003
                 ],
                 [
                    118.38853239999997,
                    48.011128
                 ],
                 [
                    118.385803,
                    48.01055729999997
                 ],
                 [
                    118.3838193,
                    48.01055729999997
                 ],
                 [
                    118.38130489999998,
                    48.011006
                 ],
                 [
                    118.37893530000002,
                    48.0117986
                 ],
                 [
                    118.37634830000002,
                    48.01283690000002
                 ],
                 [
                    118.37352630000004,
                    48.01354489999998
                 ],
                 [
                    118.37024539999999,
                    48.013429400000014
                 ],
                 [
                    118.36715410000002,
                    48.01388890000002
                 ],
                 [
                    118.36436469999997,
                    48.013772299999964
                 ],
                 [
                    118.3625306,
                    48.01364960000003
                 ],
                 [
                    118.36112360000003,
                    48.012473
                 ],
                 [
                    118.3595164,
                    48.011935400000034
                 ],
                 [
                    118.35734830000003,
                    48.011935400000034
                 ],
                 [
                    118.35533929999998,
                    48.011935400000034
                 ],
                 [
                    118.35383319999997,
                    48.010802
                 ],
                 [
                    118.3534545,
                    48.00915510000003
                 ],
                 [
                    118.35549679999995,
                    48.00803709999999
                 ],
                 [
                    118.35735289999997,
                    48.00758550000001
                 ],
                 [
                    118.35823529999996,
                    48.0067985
                 ],
                 [
                    118.35745130000002,
                    48.00574939999997
                 ],
                 [
                    118.35668480000001,
                    48.0047236
                 ],
                 [
                    118.35433019999998,
                    48.005038699999965
                 ],
                 [
                    118.3519229,
                    48.005614
                 ],
                 [
                    118.35067350000003,
                    48.00575320000002
                 ],
                 [
                    118.34994790000002,
                    48.00478229999998
                 ],
                 [
                    118.34859350000005,
                    48.00448020000003
                 ],
                 [
                    118.347785,
                    48.00466050000003
                 ],
                 [
                    118.347268,
                    48.005928799999985
                 ],
                 [
                    118.34568750000004,
                    48.00623090000002
                 ],
                 [
                    118.344915,
                    48.00493870000004
                 ],
                 [
                    118.344278,
                    48.00408640000004
                 ],
                 [
                    118.34245210000003,
                    48.003577400000026
                 ],
                 [
                    118.3412384,
                    48.00418629999996
                 ],
                 [
                    118.34022330000002,
                    48.005205299999986
                 ],
                 [
                    118.3386011,
                    48.0064111
                 ],
                 [
                    118.33625439999997,
                    48.00665270000002
                 ],
                 [
                    118.33312079999996,
                    48.00665270000002
                 ],
                 [
                    118.32911090000002,
                    48.00618610000004
                 ],
                 [
                    118.32728239999997,
                    48.0055745
                 ],
                 [
                    118.3259344,
                    48.00388330000003
                 ],
                 [
                    118.32341970000003,
                    48.003672900000026
                 ],
                 [
                    118.3212584,
                    48.00456280000002
                 ],
                 [
                    118.31810910000002,
                    48.0056164
                 ],
                 [
                    118.3153759,
                    48.00573070000002
                 ],
                 [
                    118.31270150000005,
                    48.006849
                 ],
                 [
                    118.31089550000002,
                    48.0078376
                 ],
                 [
                    118.31037329999997,
                    48.009235
                 ],
                 [
                    118.308014,
                    48.010084900000045
                 ],
                 [
                    118.30344329999998,
                    48.0110257
                 ],
                 [
                    118.30174929999998,
                    48.010648
                 ],
                 [
                    118.300027,
                    48.00936780000004
                 ],
                 [
                    118.3014406,
                    48.00771279999998
                 ],
                 [
                    118.30112359999998,
                    48.006970799999955
                 ],
                 [
                    118.30078710000004,
                    48.00550730000001
                 ],
                 [
                    118.29900420000004,
                    48.00377219999998
                 ],
                 [
                    118.29744560000005,
                    48.003667800000045
                 ],
                 [
                    118.29517510000004,
                    48.00390160000003
                 ],
                 [
                    118.2925802,
                    48.00320710000003
                 ],
                 [
                    118.29044760000004,
                    48.00320710000003
                 ],
                 [
                    118.28870989999996,
                    48.00362980000001
                 ],
                 [
                    118.28791559999996,
                    48.004799
                 ],
                 [
                    118.2875692,
                    48.0067695
                 ],
                 [
                    118.286648,
                    48.007632399999984
                 ],
                 [
                    118.28494510000004,
                    48.00851850000003
                 ],
                 [
                    118.28323810000002,
                    48.00801090000004
                 ],
                 [
                    118.28157120000003,
                    48.00734180000001
                 ],
                 [
                    118.27952530000005,
                    48.00734180000001
                 ],
                 [
                    118.27825530000004,
                    48.008191499999974
                 ],
                 [
                    118.27702009999997,
                    48.009608
                 ],
                 [
                    118.27524789999998,
                    48.010003200000035
                 ],
                 [
                    118.27319480000001,
                    48.00900429999999
                 ],
                 [
                    118.27184409999998,
                    48.00719710000003
                 ],
                 [
                    118.27040429999997,
                    48.006908
                 ],
                 [
                    118.2693592,
                    48.00770710000004
                 ],
                 [
                    118.268885,
                    48.008976
                 ],
                 [
                    118.270218,
                    48.010648
                 ],
                 [
                    118.27076519999997,
                    48.01162420000004
                 ],
                 [
                    118.2704047,
                    48.01295070000002
                 ],
                 [
                    118.26951870000005,
                    48.01461030000002
                 ],
                 [
                    118.26773340000004,
                    48.015805
                 ],
                 [
                    118.2664652,
                    48.0162897
                 ],
                 [
                    118.26482520000002,
                    48.0165336
                 ],
                 [
                    118.2630366,
                    48.0162942
                 ],
                 [
                    118.26204859999996,
                    48.016073899999974
                 ],
                 [
                    118.260811,
                    48.01617740000001
                 ],
                 [
                    118.26003860000002,
                    48.016590800000024
                 ],
                 [
                    118.259544,
                    48.0170318
                 ],
                 [
                    118.25905220000001,
                    48.0174705
                 ],
                 [
                    118.25857480000005,
                    48.018322
                 ],
                 [
                    118.25873340000003,
                    48.01906410000003
                 ],
                 [
                    118.259063,
                    48.01972569999997
                 ],
                 [
                    118.25975589999999,
                    48.0206525
                 ],
                 [
                    118.26011280000003,
                    48.02172679999998
                 ],
                 [
                    118.26011280000003,
                    48.02259260000003
                 ],
                 [
                    118.25938830000004,
                    48.023561700000016
                 ],
                 [
                    118.25848909999996,
                    48.024403500000034
                 ],
                 [
                    118.25736749999999,
                    48.02490370000003
                 ],
                 [
                    118.25606749999997,
                    48.0250279
                 ],
                 [
                    118.25481420000003,
                    48.024908199999956
                 ],
                 [
                    118.25336779999998,
                    48.02466640000002
                 ],
                 [
                    118.25222320000005,
                    48.0240284
                 ],
                 [
                    118.2518459,
                    48.02301880000004
                 ],
                 [
                    118.2520327,
                    48.02214439999999
                 ],
                 [
                    118.25257679999999,
                    48.021537799999976
                 ],
                 [
                    118.25337309999998,
                    48.020685599999986
                 ],
                 [
                    118.25308180000003,
                    48.020003600000045
                 ],
                 [
                    118.25230340000002,
                    48.019483
                 ],
                 [
                    118.2513693,
                    48.01917050000004
                 ],
                 [
                    118.25005939999998,
                    48.01917050000004
                 ],
                 [
                    118.2492954,
                    48.01917050000004
                 ],
                 [
                    118.24853980000002,
                    48.019574900000016
                 ],
                 [
                    118.247719,
                    48.0203434
                 ],
                 [
                    118.24706089999995,
                    48.02133379999997
                 ],
                 [
                    118.24689340000003,
                    48.02245410000003
                 ],
                 [
                    118.2467232,
                    48.023478699999984
                 ],
                 [
                    118.2467232,
                    48.02476970000004
                 ],
                 [
                    118.2459994,
                    48.025859
                 ],
                 [
                    118.24525410000003,
                    48.026482
                 ],
                 [
                    118.24411459999999,
                    48.026863
                 ],
                 [
                    118.2426065,
                    48.026863
                 ],
                 [
                    118.24128790000005,
                    48.026359100000015
                 ],
                 [
                    118.2405917,
                    48.02566050000003
                 ],
                 [
                    118.240103,
                    48.0254154
                 ],
                 [
                    118.23961970000005,
                    48.02565779999998
                 ],
                 [
                    118.23914119999998,
                    48.02619129999999
                 ],
                 [
                    118.23863779999998,
                    48.026976799999964
                 ],
                 [
                    118.23827790000004,
                    48.02769880000002
                 ],
                 [
                    118.2374831,
                    48.028230300000025
                 ],
                 [
                    118.23617390000004,
                    48.02835539999999
                 ],
                 [
                    118.23493830000004,
                    48.02835539999999
                 ],
                 [
                    118.23336059999997,
                    48.02823819999997
                 ],
                 [
                    118.23202080000004,
                    48.0280142
                 ],
                 [
                    118.23090320000003,
                    48.02812090000003
                 ],
                 [
                    118.2301696,
                    48.028317200000025
                 ],
                 [
                    118.22990839999997,
                    48.0286666
                 ],
                 [
                    118.23006790000002,
                    48.0295203
                 ],
                 [
                    118.23038609999998,
                    48.030371199999976
                 ],
                 [
                    118.2313199,
                    48.03089169999996
                 ],
                 [
                    118.23289049999997,
                    48.03135850000004
                 ],
                 [
                    118.23364079999996,
                    48.0319856
                 ],
                 [
                    118.23419809999999,
                    48.03285519999998
                 ],
                 [
                    118.2340158,
                    48.03395219999997
                 ],
                 [
                    118.23332250000001,
                    48.035458800000015
                 ],
                 [
                    118.23299029999997,
                    48.036236399999964
                 ],
                 [
                    118.23299029999997,
                    48.038523
                 ],
                 [
                    118.23263389999997,
                    48.039833699999974
                 ],
                 [
                    118.23206890000003,
                    48.04046330000002
                 ],
                 [
                    118.23111819999997,
                    48.0409718
                 ],
                 [
                    118.22999720000004,
                    48.041096700000026
                 ],
                 [
                    118.22857219999999,
                    48.041096700000026
                 ],
                 [
                    118.22701710000001,
                    48.0408656
                 ],
                 [
                    118.22480270000003,
                    48.0407518
                 ],
                 [
                    118.22284460000002,
                    48.04063280000001
                 ],
                 [
                    118.22128710000003,
                    48.039938500000034
                 ],
                 [
                    118.21958729999997,
                    48.039711199999964
                 ],
                 [
                    118.21789529999995,
                    48.03914549999997
                 ],
                 [
                    118.21638959999999,
                    48.03914549999997
                 ],
                 [
                    118.21449239999995,
                    48.0389149
                 ],
                 [
                    118.21195120000002,
                    48.03880170000003
                 ],
                 [
                    118.20960020000001,
                    48.0389139
                 ],
                 [
                    118.20654240000005,
                    48.039595399999975
                 ],
                 [
                    118.20345820000003,
                    48.0402828
                 ],
                 [
                    118.20123920000002,
                    48.0408527
                 ],
                 [
                    118.19972360000003,
                    48.041303099999965
                 ],
                 [
                    118.1974905,
                    48.04233670000004
                 ],
                 [
                    118.196224,
                    48.042699599999956
                 ],
                 [
                    118.19402379999995,
                    48.042822199999975
                 ],
                 [
                    118.192167,
                    48.041953200000016
                 ],
                 [
                    118.1906731,
                    48.039733699999964
                 ],
                 [
                    118.1881224,
                    48.03994680000001
                 ],
                 [
                    118.1851191,
                    48.04018310000002
                 ],
                 [
                    118.1828385,
                    48.039362
                 ],
                 [
                    118.18062560000001,
                    48.038939300000045
                 ],
                 [
                    118.17944390000002,
                    48.039828100000044
                 ],
                 [
                    118.17977809999996,
                    48.04183860000004
                 ],
                 [
                    118.17924679999996,
                    48.04408840000003
                 ],
                 [
                    118.17626770000004,
                    48.04666580000003
                 ],
                 [
                    118.174299,
                    48.04774269999996
                 ],
                 [
                    118.17199650000003,
                    48.04797960000002
                 ],
                 [
                    118.16986589999996,
                    48.04821690000003
                 ],
                 [
                    118.16752880000004,
                    48.047616
                 ],
                 [
                    118.1659161,
                    48.046777400000025
                 ],
                 [
                    118.1631401,
                    48.0438776
                 ],
                 [
                    118.16232230000003,
                    48.04289350000001
                 ],
                 [
                    118.1612884,
                    48.04259720000001
                 ],
                 [
                    118.15931890000003,
                    48.042926399999985
                 ],
                 [
                    118.1574132,
                    48.043273900000045
                 ],
                 [
                    118.155826,
                    48.04339180000003
                 ],
                 [
                    118.1522939,
                    48.04327370000003
                 ],
                 [
                    118.15041069999998,
                    48.04239240000003
                 ],
                 [
                    118.1500567,
                    48.03955290000001
                 ],
                 [
                    118.1498892,
                    48.037983800000035
                 ],
                 [
                    118.14893919999999,
                    48.03681929999996
                 ],
                 [
                    118.14627,
                    48.0362616
                 ],
                 [
                    118.1446381,
                    48.03565530000002
                 ],
                 [
                    118.1436043,
                    48.0345033
                 ],
                 [
                    118.142372,
                    48.03398820000002
                 ],
                 [
                    118.14050609999998,
                    48.0341962
                 ],
                 [
                    118.13916849999998,
                    48.03520240000003
                 ],
                 [
                    118.1373247,
                    48.035942100000035
                 ],
                 [
                    118.13558920000003,
                    48.03568420000001
                 ],
                 [
                    118.1343425,
                    48.03473149999997
                 ],
                 [
                    118.13229289999997,
                    48.0335608
                 ],
                 [
                    118.12915619999995,
                    48.0332215
                 ],
                 [
                    118.12868270000004,
                    48.03491050000004
                 ],
                 [
                    118.12692760000004,
                    48.034388900000046
                 ],
                 [
                    118.12696510000004,
                    48.03511660000004
                 ],
                 [
                    118.126619,
                    48.0360426
                 ],
                 [
                    118.12392449999999,
                    48.03689239999997
                 ],
                 [
                    118.12362660000002,
                    48.03798790000002
                 ],
                 [
                    118.12020020000003,
                    48.04003959999996
                 ],
                 [
                    118.11892829999996,
                    48.04337279999997
                 ],
                 [
                    118.11735779999995,
                    48.044535299999964
                 ],
                 [
                    118.11317059999999,
                    48.044099100000025
                 ],
                 [
                    118.11236260000001,
                    48.04330949999997
                 ],
                 [
                    118.11327349999996,
                    48.0416976
                 ],
                 [
                    118.11399980000002,
                    48.039651099999965
                 ],
                 [
                    118.11643809999998,
                    48.037851
                 ],
                 [
                    118.11664969999998,
                    48.0366907
                 ],
                 [
                    118.11410420000004,
                    48.036517600000046
                 ],
                 [
                    118.1131923,
                    48.037865600000025
                 ],
                 [
                    118.11165770000004,
                    48.03882090000002
                 ],
                 [
                    118.110038,
                    48.039553499999975
                 ],
                 [
                    118.11023359999999,
                    48.04089380000003
                 ],
                 [
                    118.10964040000002,
                    48.041766400000014
                 ],
                 [
                    118.10757459999996,
                    48.0421216
                 ],
                 [
                    118.10623760000001,
                    48.0411532
                 ],
                 [
                    118.10408340000004,
                    48.039467
                 ],
                 [
                    118.1038788,
                    48.037073
                 ],
                 [
                    118.1013166,
                    48.0348114
                 ],
                 [
                    118.1014714,
                    48.03322290000003
                 ],
                 [
                    118.10016920000001,
                    48.03144650000004
                 ],
                 [
                    118.101938,
                    48.029047
                 ],
                 [
                    118.10128890000001,
                    48.02850439999997
                 ],
                 [
                    118.0998127,
                    48.02830120000003
                 ],
                 [
                    118.09809059999998,
                    48.029781899999975
                 ],
                 [
                    118.09551280000002,
                    48.030608
                 ],
                 [
                    118.09392740000003,
                    48.030472199999984
                 ],
                 [
                    118.09317309999997,
                    48.034111099999976
                 ],
                 [
                    118.08816,
                    48.03279409999998
                 ],
                 [
                    118.08907010000004,
                    48.03081610000002
                 ],
                 [
                    118.08891809999997,
                    48.02800429999998
                 ],
                 [
                    118.08653729999999,
                    48.02722379999997
                 ],
                 [
                    118.0858182,
                    48.02777339999997
                 ],
                 [
                    118.08438819999998,
                    48.02751259999997
                 ],
                 [
                    118.08370959999996,
                    48.02681440000003
                 ],
                 [
                    118.0807071,
                    48.02656340000004
                 ],
                 [
                    118.07986970000005,
                    48.0253034
                 ],
                 [
                    118.07756719999996,
                    48.025373299999984
                 ],
                 [
                    118.077371,
                    48.02518590000002
                 ],
                 [
                    118.075827,
                    48.0272833
                 ],
                 [
                    118.07387439999995,
                    48.027837400000024
                 ],
                 [
                    118.07177430000002,
                    48.0272177
                 ],
                 [
                    118.07242309999995,
                    48.0242527
                 ],
                 [
                    118.07087810000002,
                    48.02312539999998
                 ],
                 [
                    118.06740689999995,
                    48.023991699999975
                 ],
                 [
                    118.0618212,
                    48.022993
                 ],
                 [
                    118.06109250000002,
                    48.0216433
                 ],
                 [
                    118.0589412,
                    48.01934779999996
                 ],
                 [
                    118.0571665,
                    48.019165200000046
                 ],
                 [
                    118.05637470000002,
                    48.02002580000002
                 ],
                 [
                    118.05336519999997,
                    48.021209900000024
                 ],
                 [
                    118.0518745,
                    48.0193353
                 ],
                 [
                    118.051822,
                    48.016774699999964
                 ],
                 [
                    118.05011850000001,
                    48.0157329
                 ],
                 [
                    118.04654769999996,
                    48.01559829999997
                 ],
                 [
                    118.04468050000003,
                    48.01665010000004
                 ],
                 [
                    118.0441511,
                    48.0183145
                 ],
                 [
                    118.04273120000002,
                    48.0192247
                 ],
                 [
                    118.04068029999998,
                    48.01914150000004
                 ],
                 [
                    118.03975560000003,
                    48.01732459999999
                 ],
                 [
                    118.03987050000003,
                    48.015633799999975
                 ],
                 [
                    118.04362020000005,
                    48.0140213
                 ],
                 [
                    118.04455050000001,
                    48.0117284
                 ],
                 [
                    118.0430981,
                    48.0111212
                 ],
                 [
                    118.03833870000003,
                    48.01146720000002
                 ],
                 [
                    118.03474460000001,
                    48.01243609999999
                 ],
                 [
                    118.03118039999998,
                    48.01207490000003
                 ],
                 [
                    118.02792490000002,
                    48.01200229999998
                 ],
                 [
                    118.025734,
                    48.010929799999964
                 ],
                 [
                    118.02436269999997,
                    48.01080329999998
                 ],
                 [
                    118.02087250000002,
                    48.01239529999999
                 ],
                 [
                    118.01929140000004,
                    48.01239529999999
                 ],
                 [
                    118.01587079999999,
                    48.0112511
                 ],
                 [
                    118.01140230000001,
                    48.01077720000004
                 ],
                 [
                    118.0108514,
                    48.0098354
                 ],
                 [
                    118.01080350000002,
                    48.009002
                 ],
                 [
                    118.00911720000003,
                    48.008597
                 ],
                 [
                    118.00845060000003,
                    48.00940530000004
                 ],
                 [
                    118.00983950000003,
                    48.01102270000001
                 ],
                 [
                    118.01007590000003,
                    48.01280219999999
                 ],
                 [
                    118.00761430000003,
                    48.01336449999997
                 ],
                 [
                    118.00459030000002,
                    48.012727600000034
                 ],
                 [
                    118.002676,
                    48.01129640000001
                 ],
                 [
                    118.00210709999999,
                    48.00752479999996
                 ],
                 [
                    118.00064430000002,
                    48.00752479999996
                 ],
                 [
                    117.99785069999999,
                    48.010723599999956
                 ],
                 [
                    117.99381610000003,
                    48.009875300000026
                 ],
                 [
                    117.99195260000002,
                    48.0073127
                 ],
                 [
                    117.9905192,
                    48.00760329999998
                 ],
                 [
                    117.98850250000002,
                    48.00991109999997
                 ],
                 [
                    117.98612910000003,
                    48.010453199999965
                 ],
                 [
                    117.98412940000003,
                    48.0098547
                 ],
                 [
                    117.9833701,
                    48.010108699999975
                 ],
                 [
                    117.98565460000002,
                    48.0120358
                 ],
                 [
                    117.98600480000005,
                    48.0137534
                 ],
                 [
                    117.9844683,
                    48.01452430000001
                 ],
                 [
                    117.98109990000002,
                    48.01336129999998
                 ],
                 [
                    117.9782884,
                    48.013625199999964
                 ],
                 [
                    117.97717110000002,
                    48.014977700000046
                 ],
                 [
                    117.97326730000005,
                    48.0152991
                 ],
                 [
                    117.97337760000002,
                    48.0134164
                 ],
                 [
                    117.97117270000003,
                    48.01299959999998
                 ],
                 [
                    117.96731050000002,
                    48.01299959999998
                 ],
                 [
                    117.96611870000001,
                    48.01203449999998
                 ],
                 [
                    117.9665041,
                    48.01138990000002
                 ],
                 [
                    117.96478340000002,
                    48.01091760000002
                 ],
                 [
                    117.96335219999999,
                    48.01133650000004
                 ],
                 [
                    117.9634039,
                    48.012894099999954
                 ],
                 [
                    117.9612688,
                    48.0142838
                 ],
                 [
                    117.9575695,
                    48.01413380000003
                 ],
                 [
                    117.95572729999998,
                    48.013299
                 ],
                 [
                    117.9560121,
                    48.0115465
                 ],
                 [
                    117.95665220000004,
                    48.00960309999997
                 ],
                 [
                    117.95369619999997,
                    48.0090196
                 ],
                 [
                    117.95119609999996,
                    48.009122
                 ],
                 [
                    117.94670340000002,
                    48.01067779999999
                 ],
                 [
                    117.9414149,
                    48.011614400000035
                 ],
                 [
                    117.94096490000004,
                    48.013052700000024
                 ],
                 [
                    117.93995619999998,
                    48.0145897
                 ],
                 [
                    117.93837509999999,
                    48.01507789999999
                 ],
                 [
                    117.933578,
                    48.0137438
                 ],
                 [
                    117.92889,
                    48.01353929999996
                 ],
                 [
                    117.92702910000003,
                    48.01544110000002
                 ],
                 [
                    117.92466039999996,
                    48.01639179999999
                 ],
                 [
                    117.9223024,
                    48.01463109999997
                 ],
                 [
                    117.92035130000004,
                    48.01457040000001
                 ],
                 [
                    117.9199882,
                    48.01557249999997
                 ],
                 [
                    117.92015089999998,
                    48.01716940000002
                 ],
                 [
                    117.91664350000002,
                    48.0207252
                 ],
                 [
                    117.91340480000002,
                    48.021295299999984
                 ],
                 [
                    117.91172820000003,
                    48.02061439999996
                 ],
                 [
                    117.91064060000002,
                    48.0179774
                 ],
                 [
                    117.91030280000004,
                    48.01856109999998
                 ],
                 [
                    117.9095165,
                    48.02074
                 ],
                 [
                    117.9083765,
                    48.0207877
                 ],
                 [
                    117.9057702,
                    48.01960070000002
                 ],
                 [
                    117.90422739999998,
                    48.018328
                 ],
                 [
                    117.89841640000003,
                    48.01816189999999
                 ],
                 [
                    117.89851989999998,
                    48.02190069999998
                 ],
                 [
                    117.89588229999998,
                    48.023243
                 ],
                 [
                    117.893132,
                    48.02305139999998
                 ],
                 [
                    117.8895621,
                    48.02069819999996
                 ],
                 [
                    117.88848619999997,
                    48.02123130000002
                 ],
                 [
                    117.88696360000003,
                    48.02146410000001
                 ],
                 [
                    117.87712860000002,
                    48.021537799999976
                 ],
                 [
                    117.863472,
                    48.021522500000046
                 ],
                 [
                    117.84945679999998,
                    48.021060900000045
                 ],
                 [
                    117.81672750000001,
                    48.01608110000004
                 ],
                 [
                    117.8049667,
                    48.0141899
                 ],
                 [
                    117.8019789,
                    48.01168119999996
                 ],
                 [
                    117.79708169999998,
                    48.00759860000003
                 ],
                 [
                    117.78672050000003,
                    47.999699
                 ],
                 [
                    117.7839859,
                    47.9988044
                 ],
                 [
                    117.52217750000001,
                    47.788805899999986
                 ],
                 [
                    117.518612,
                    47.78303350000004
                 ],
                 [
                    117.51851310000004,
                    47.77159849999999
                 ],
                 [
                    117.50243580000003,
                    47.7666351
                 ],
                 [
                    117.49772929999997,
                    47.763723
                 ],
                 [
                    117.4821612,
                    47.75879759999999
                 ],
                 [
                    117.3751831,
                    47.64004139999997
                 ],
                 [
                    117.0869064,
                    47.8203011
                 ],
                 [
                    116.87078860000003,
                    47.89093780000002
                 ],
                 [
                    116.81977080000004,
                    47.8948708
                 ],
                 [
                    116.67637629999999,
                    47.889198299999975
                 ],
                 [
                    116.4555435,
                    47.83911509999996
                 ],
                 [
                    116.43944540000003,
                    47.83785939999998
                 ],
                 [
                    116.25638249999997,
                    47.877609299999975
                 ],
                 [
                    116.2534181,
                    47.87827560000004
                 ],
                 [
                    116.22833409999996,
                    47.867854799999975
                 ],
                 [
                    116.19153309999997,
                    47.85253730000003
                 ],
                 [
                    116.16647170000002,
                    47.84199549999999
                 ],
                 [
                    116.1507861,
                    47.83552860000004
                 ],
                 [
                    116.12477939999997,
                    47.824811100000026
                 ],
                 [
                    116.11147560000002,
                    47.81924989999997
                 ],
                 [
                    116.10252889999998,
                    47.811886700000024
                 ],
                 [
                    116.08581329999998,
                    47.7981087
                 ],
                 [
                    116.067467,
                    47.78302939999996
                 ],
                 [
                    116.0593131,
                    47.77626679999997
                 ],
                 [
                    116.0572103,
                    47.77309420000002
                 ],
                 [
                    116.0512665,
                    47.768104299999976
                 ],
                 [
                    116.0409453,
                    47.75956560000003
                 ],
                 [
                    116.0271266,
                    47.74795229999997
                 ],
                 [
                    116.01324349999999,
                    47.73633649999997
                 ],
                 [
                    116.00395230000004,
                    47.7285575
                 ],
                 [
                    115.98135739999995,
                    47.709603
                 ],
                 [
                    115.97150830000004,
                    47.70125680000003
                 ],
                 [
                    115.96992050000003,
                    47.69956720000003
                 ],
                 [
                    115.96643359999999,
                    47.6959279
                 ],
                 [
                    115.96545730000004,
                    47.695148
                 ],
                 [
                    115.9562948,
                    47.68815
                 ],
                 [
                    115.95559750000002,
                    47.68767340000004
                 ],
                 [
                    115.94513680000003,
                    47.684553199999975
                 ],
                 [
                    115.93533070000002,
                    47.68123060000004
                 ],
                 [
                    115.9299234,
                    47.684928799999966
                 ],
                 [
                    115.92274580000004,
                    47.68991949999999
                 ],
                 [
                    115.917435,
                    47.693501499999975
                 ],
                 [
                    115.908176,
                    47.69999320000002
                 ],
                 [
                    115.9076717,
                    47.7003398
                 ],
                 [
                    115.89790690000002,
                    47.707101500000014
                 ],
                 [
                    115.89033390000003,
                    47.712064799999986
                 ],
                 [
                    115.8798841,
                    47.7189295
                 ],
                 [
                    115.8734253,
                    47.7227405
                 ],
                 [
                    115.84394939999999,
                    47.743913099999986
                 ],
                 [
                    115.84134880000002,
                    47.74537650000001
                 ],
                 [
                    115.83004860000003,
                    47.75225169999999
                 ],
                 [
                    115.8082369,
                    47.765609200000036
                 ],
                 [
                    115.8041599,
                    47.7681764
                 ],
                 [
                    115.74209569999996,
                    47.80340850000002
                 ],
                 [
                    115.643296,
                    47.87167939999996
                 ],
                 [
                    115.57051160000003,
                    47.91909340000002
                 ],
                 [
                    115.567971,
                    47.923079499999965
                 ],
                 [
                    115.56487270000002,
                    47.97021689999996
                 ],
                 [
                    115.55660270000001,
                    47.997934
                 ],
                 [
                    115.55400210000005,
                    48.0155138
                 ],
                 [
                    115.55011820000004,
                    48.04137260000003
                 ],
                 [
                    115.54552629999998,
                    48.06685989999997
                 ],
                 [
                    115.538986,
                    48.0835225
                 ],
                 [
                    115.533326,
                    48.100993200000026
                 ],
                 [
                    115.53404369999998,
                    48.10111120000002
                 ],
                 [
                    115.53437629999996,
                    48.1013621
                 ],
                 [
                    115.53485140000002,
                    48.101603199999964
                 ],
                 [
                    115.53479690000005,
                    48.10232
                 ],
                 [
                    115.53418619999997,
                    48.10283569999998
                 ],
                 [
                    115.5324789,
                    48.10300089999997
                 ],
                 [
                    115.5300827,
                    48.103079100000045
                 ],
                 [
                    115.52931130000002,
                    48.103214499999964
                 ],
                 [
                    115.5290294,
                    48.10343239999997
                 ],
                 [
                    115.529124,
                    48.1036958
                 ],
                 [
                    115.52951349999996,
                    48.10384220000003
                 ],
                 [
                    115.53013739999999,
                    48.10388820000002
                 ],
                 [
                    115.53041070000003,
                    48.103973100000026
                 ],
                 [
                    115.53057,
                    48.10423659999998
                 ],
                 [
                    115.53029030000002,
                    48.10480090000004
                 ],
                 [
                    115.5290159,
                    48.1056719
                 ],
                 [
                    115.52842450000001,
                    48.105798200000045
                 ],
                 [
                    115.52773279999998,
                    48.105676600000024
                 ],
                 [
                    115.52707190000001,
                    48.1058487
                 ],
                 [
                    115.52548079999998,
                    48.10662479999996
                 ],
                 [
                    115.52464849999996,
                    48.10745630000002
                 ],
                 [
                    115.5244768,
                    48.108141
                 ],
                 [
                    115.52366089999995,
                    48.10910640000003
                 ],
                 [
                    115.52298420000001,
                    48.10953790000003
                 ],
                 [
                    115.52234450000002,
                    48.11055069999998
                 ],
                 [
                    115.522227,
                    48.11069019999998
                 ],
                 [
                    115.52141510000003,
                    48.11120290000004
                 ],
                 [
                    115.5213762,
                    48.1117296
                 ],
                 [
                    115.521436,
                    48.112166600000016
                 ],
                 [
                    115.52165680000004,
                    48.1123308
                 ],
                 [
                    115.52186969999998,
                    48.11232
                 ],
                 [
                    115.52216989999995,
                    48.112156
                 ],
                 [
                    115.5224368,
                    48.1121277
                 ],
                 [
                    115.52282970000002,
                    48.11229550000002
                 ],
                 [
                    115.52336049999997,
                    48.11273
                 ],
                 [
                    115.52386609999996,
                    48.11293950000002
                 ],
                 [
                    115.52417749999998,
                    48.113201799999956
                 ],
                 [
                    115.52438750000003,
                    48.11367050000001
                 ],
                 [
                    115.52431,
                    48.11403279999997
                 ],
                 [
                    115.52428289999999,
                    48.11422260000004
                 ],
                 [
                    115.52492960000004,
                    48.11473709999998
                 ],
                 [
                    115.52632410000004,
                    48.115196
                 ],
                 [
                    115.5280227,
                    48.115447300000035
                 ],
                 [
                    115.52863859999997,
                    48.11559909999998
                 ],
                 [
                    115.52898349999998,
                    48.11576549999998
                 ],
                 [
                    115.52898810000002,
                    48.11603120000003
                 ],
                 [
                    115.52863559999997,
                    48.11650790000004
                 ],
                 [
                    115.52834029999997,
                    48.1168401
                 ],
                 [
                    115.52844060000001,
                    48.11731349999999
                 ],
                 [
                    115.528523,
                    48.1176063
                 ],
                 [
                    115.52826839999997,
                    48.11795580000002
                 ],
                 [
                    115.52851729999996,
                    48.11834780000004
                 ],
                 [
                    115.52934770000002,
                    48.118523199999984
                 ],
                 [
                    115.53075129999998,
                    48.11847489999998
                 ],
                 [
                    115.53233970000004,
                    48.118835
                 ],
                 [
                    115.53308240000003,
                    48.11909910000003
                 ],
                 [
                    115.53329060000002,
                    48.11934710000003
                 ],
                 [
                    115.5333804,
                    48.11962649999997
                 ],
                 [
                    115.5332796,
                    48.12038119999997
                 ],
                 [
                    115.533006,
                    48.120570800000024
                 ],
                 [
                    115.53256209999996,
                    48.120754
                 ],
                 [
                    115.53210589999996,
                    48.12123760000004
                 ],
                 [
                    115.532163,
                    48.12181080000004
                 ],
                 [
                    115.532358,
                    48.12190060000001
                 ],
                 [
                    115.5331026,
                    48.12204670000002
                 ],
                 [
                    115.5339021,
                    48.12240559999996
                 ],
                 [
                    115.53422050000005,
                    48.12265980000003
                 ],
                 [
                    115.534434,
                    48.12272369999999
                 ],
                 [
                    115.53513080000005,
                    48.12353250000003
                 ],
                 [
                    115.53598839999995,
                    48.124676
                 ],
                 [
                    115.53638609999999,
                    48.1266087
                 ],
                 [
                    115.5362949,
                    48.12671190000002
                 ],
                 [
                    115.5360566,
                    48.126739900000025
                 ],
                 [
                    115.535538,
                    48.12654069999997
                 ],
                 [
                    115.53524269999998,
                    48.1265129
                 ],
                 [
                    115.53444880000002,
                    48.126735
                 ],
                 [
                    115.5341266,
                    48.1269747
                 ],
                 [
                    115.5337423,
                    48.127582
                 ],
                 [
                    115.53370979999998,
                    48.127944300000024
                 ],
                 [
                    115.53417569999998,
                    48.12847050000004
                 ],
                 [
                    115.5351153,
                    48.12885519999998
                 ],
                 [
                    115.5356016,
                    48.1289757
                 ],
                 [
                    115.5362842,
                    48.12943039999997
                 ],
                 [
                    115.53725219999998,
                    48.130946
                 ],
                 [
                    115.53746249999998,
                    48.1328795
                 ],
                 [
                    115.53534540000004,
                    48.133544800000045
                 ],
                 [
                    115.53550720000001,
                    48.135409499999966
                 ],
                 [
                    115.53373879999998,
                    48.13672960000004
                 ],
                 [
                    115.5322469,
                    48.136474200000045
                 ],
                 [
                    115.533312,
                    48.1350652
                 ],
                 [
                    115.53283169999997,
                    48.13418809999998
                 ],
                 [
                    115.53062549999996,
                    48.1339982
                 ],
                 [
                    115.53051609999997,
                    48.13616539999998
                 ],
                 [
                    115.52856989999997,
                    48.136718099999975
                 ],
                 [
                    115.52670659999998,
                    48.13605779999996
                 ],
                 [
                    115.52533339999997,
                    48.1359081
                 ],
                 [
                    115.5233146,
                    48.13633650000002
                 ],
                 [
                    115.52413620000002,
                    48.1381374
                 ],
                 [
                    115.52462719999997,
                    48.14100329999997
                 ],
                 [
                    115.5224764,
                    48.1441822
                 ],
                 [
                    115.52036979999997,
                    48.15290430000003
                 ],
                 [
                    115.5477057,
                    48.161439
                 ],
                 [
                    115.81340790000002,
                    48.2588654
                 ],
                 [
                    115.78697010000005,
                    48.519881
                 ],
                 [
                    116.071373,
                    48.81937789999997
                 ],
                 [
                    116.03633879999998,
                    48.8673821
                 ],
                 [
                    116.0771957,
                    48.9260414
                 ],
                 [
                    116.11284569999997,
                    48.98050920000003
                 ],
                 [
                    116.16191830000002,
                    49.0503151
                 ],
                 [
                    116.20688420000003,
                    49.1141117
                 ],
                 [
                    116.24450489999998,
                    49.17381349999996
                 ],
                 [
                    116.279194,
                    49.227215
                 ],
                 [
                    116.3256677,
                    49.289111
                 ],
                 [
                    116.4823112,
                    49.521863900000014
                 ],
                 [
                    116.559956,
                    49.6368257
                 ],
                 [
                    116.60884839999997,
                    49.707061
                 ],
                 [
                    116.65850150000003,
                    49.77240059999996
                 ],
                 [
                    116.7112958,
                    49.841832500000045
                 ],
                 [
                    116.7015,
                    49.8491
                 ],
                 [
                    116.64946980000005,
                    49.90225060000004
                 ],
                 [
                    116.6455,
                    49.9063
                 ],
                 [
                    116.6216,
                    49.9311
                 ],
                 [
                    116.531,
                    49.9454
                 ],
                 [
                    116.5268,
                    49.9472
                 ],
                 [
                    116.3982,
                    49.9809
                 ],
                 [
                    116.3436,
                    49.9958
                 ],
                 [
                    116.2819,
                    50.0152
                 ],
                 [
                    116.2524,
                    50.0294
                 ],
                 [
                    116.2355,
                    50.0356
                 ],
                 [
                    116.23296289999996,
                    50.03586659999996
                 ],
                 [
                    116.18569190000002,
                    50.0306596
                 ],
                 [
                    116.1255,
                    50.024
                 ],
                 [
                    116.0727,
                    50.0137
                 ],
                 [
                    116.0598,
                    50.0082
                 ],
                 [
                    116.0299,
                    49.99
                 ],
                 [
                    115.9967,
                    49.9771
                 ],
                 [
                    115.9829,
                    49.9707
                 ],
                 [
                    115.9496,
                    49.9614
                 ],
                 [
                    115.9286446,
                    49.954628899999975
                 ],
                 [
                    115.89499890000002,
                    49.94336209999999
                 ],
                 [
                    115.84350049999998,
                    49.92612539999996
                 ],
                 [
                    115.80728,
                    49.90755589999998
                 ],
                 [
                    115.75817270000005,
                    49.89215739999999
                 ],
                 [
                    115.74539449999997,
                    49.887175800000016
                 ],
                 [
                    115.74487540000001,
                    49.88749509999997
                 ],
                 [
                    115.7445232,
                    49.8876319
                 ],
                 [
                    115.70823140000005,
                    49.885993100000036
                 ],
                 [
                    115.68248220000002,
                    49.886103700000035
                 ],
                 [
                    115.6548,
                    49.8891
                 ],
                 [
                    115.6099,
                    49.9003
                 ],
                 [
                    115.596,
                    49.9012
                 ],
                 [
                    115.551,
                    49.9035
                 ],
                 [
                    115.5277,
                    49.9047
                 ],
                 [
                    115.5067,
                    49.9071
                 ],
                 [
                    115.4828,
                    49.914
                 ],
                 [
                    115.48,
                    49.915
                 ],
                 [
                    115.47123880000004,
                    49.9194604
                 ],
                 [
                    115.36873260000003,
                    49.938714400000016
                 ],
                 [
                    115.3608,
                    49.9402
                 ],
                 [
                    115.3313,
                    49.947
                 ],
                 [
                    115.3003,
                    49.9573
                 ],
                 [
                    115.2947,
                    49.959
                 ],
                 [
                    115.2239,
                    49.9884
                 ],
                 [
                    115.2125,
                    49.9965
                 ],
                 [
                    115.168,
                    50.029
                 ],
                 [
                    115.1292,
                    50.0572
                 ],
                 [
                    115.0893,
                    50.0763
                 ],
                 [
                    115.0793,
                    50.0833
                 ],
                 [
                    115.0677,
                    50.0939
                 ],
                 [
                    115.0685,
                    50.1091
                 ],
                 [
                    115.0554,
                    50.1216
                 ],
                 [
                    115.03895170000001,
                    50.13663890000004
                 ],
                 [
                    115.0321,
                    50.1429
                 ],
                 [
                    115.0075,
                    50.1639
                 ],
                 [
                    114.986,
                    50.1744
                 ],
                 [
                    114.9647,
                    50.1821
                 ],
                 [
                    114.9349,
                    50.1906
                 ],
                 [
                    114.9109,
                    50.1955
                 ],
                 [
                    114.9109,
                    50.1966
                 ],
                 [
                    114.8567,
                    50.217
                 ],
                 [
                    114.8312,
                    50.2237
                 ],
                 [
                    114.8115,
                    50.2261
                 ],
                 [
                    114.7791,
                    50.2281
                 ],
                 [
                    114.7523,
                    50.2338
                 ],
                 [
                    114.70285190000003,
                    50.242342500000014
                 ],
                 [
                    114.7002,
                    50.2428
                 ],
                 [
                    114.6535,
                    50.2472
                 ],
                 [
                    114.6171,
                    50.2456
                 ],
                 [
                    114.5698,
                    50.2374
                 ],
                 [
                    114.55006090000002,
                    50.2344567
                 ],
                 [
                    114.5142,
                    50.2291
                 ],
                 [
                    114.4677,
                    50.2335
                 ],
                 [
                    114.4391,
                    50.2436
                 ],
                 [
                    114.412,
                    50.2529
                 ],
                 [
                    114.4091,
                    50.2546
                 ],
                 [
                    114.3388,
                    50.2827
                 ],
                 [
                    114.1784,
                    50.243
                 ],
                 [
                    114.1279,
                    50.2193
                 ],
                 [
                    114.10551469999997,
                    50.211176399999964
                 ],
                 [
                    114.092563,
                    50.2072758
                 ],
                 [
                    114.0738974,
                    50.1907758
                 ],
                 [
                    114.06213910000001,
                    50.18363069999998
                 ],
                 [
                    114.04600650000003,
                    50.18318690000003
                 ],
                 [
                    114.0121,
                    50.1669
                 ],
                 [
                    114,
                    50.1637
                 ],
                 [
                    113.9801777,
                    50.15266269999998
                 ],
                 [
                    113.9131,
                    50.12
                 ],
                 [
                    113.9045,
                    50.1159
                 ],
                 [
                    113.8958,
                    50.1116
                 ],
                 [
                    113.8787,
                    50.1049
                 ],
                 [
                    113.8702,
                    50.1033
                 ],
                 [
                    113.8448,
                    50.0994
                 ],
                 [
                    113.8108,
                    50.0923
                 ],
                 [
                    113.798,
                    50.0888
                 ],
                 [
                    113.77515950000004,
                    50.0813951
                 ],
                 [
                    113.7724,
                    50.0805
                 ],
                 [
                    113.7514,
                    50.0765
                 ],
                 [
                    113.7427,
                    50.0749
                 ],
                 [
                    113.7341,
                    50.0706
                 ],
                 [
                    113.7253,
                    50.0625
                 ],
                 [
                    113.7147,
                    50.0495
                 ],
                 [
                    113.7103,
                    50.0442
                 ],
                 [
                    113.69,
                    50.0312
                 ],
                 [
                    113.6827,
                    50.0259
                 ],
                 [
                    113.6578,
                    50.0049
                 ],
                 [
                    113.6519,
                    49.9997
                 ],
                 [
                    113.6404,
                    49.9927
                 ],
                 [
                    113.6361,
                    49.991
                 ],
                 [
                    113.615,
                    49.9869
                 ],
                 [
                    113.5918949,
                    49.984817
                 ],
                 [
                    113.5519,
                    49.9812
                 ],
                 [
                    113.53402259999997,
                    49.979231399999975
                 ],
                 [
                    113.5183,
                    49.9775
                 ],
                 [
                    113.493,
                    49.9744
                 ],
                 [
                    113.4888,
                    49.9736
                 ],
                 [
                    113.4842633,
                    49.9694633
                 ],
                 [
                    113.47392419999996,
                    49.93544829999996
                 ],
                 [
                    113.4317,
                    49.9209
                 ],
                 [
                    113.3936,
                    49.9091
                 ],
                 [
                    113.368,
                    49.8979
                 ],
                 [
                    113.26283160000003,
                    49.8557729
                 ],
                 [
                    113.21842090000004,
                    49.83705750000002
                 ],
                 [
                    113.2061,
                    49.8283
                 ],
                 [
                    113.193,
                    49.815
                 ],
                 [
                    113.1854,
                    49.8017
                 ],
                 [
                    113.181,
                    49.7964
                 ],
                 [
                    113.178,
                    49.7909
                 ],
                 [
                    113.1751,
                    49.7885
                 ],
                 [
                    113.1623,
                    49.7805
                 ],
                 [
                    113.1591,
                    49.7725
                 ],
                 [
                    113.1574,
                    49.7644
                 ],
                 [
                    113.1571,
                    49.759
                 ],
                 [
                    113.1597,
                    49.7536
                 ],
                 [
                    113.1568,
                    49.751
                 ],
                 [
                    113.1483,
                    49.7484
                 ],
                 [
                    113.1441,
                    49.7467
                 ],
                 [
                    113.1369,
                    49.7414
                 ],
                 [
                    113.1327,
                    49.7388
                 ],
                 [
                    113.1283,
                    49.7335
                 ],
                 [
                    113.1144,
                    49.7014
                 ],
                 [
                    113.1057,
                    49.6935
                 ],
                 [
                    113.0999,
                    49.6882
                 ],
                 [
                    113.0926,
                    49.6775
                 ],
                 [
                    113.0736,
                    49.6563
                 ],
                 [
                    113.0746,
                    49.6464
                 ],
                 [
                    113.0686,
                    49.6348
                 ],
                 [
                    113.0598,
                    49.6241
                 ],
                 [
                    113.0439,
                    49.6082
                 ],
                 [
                    113.0356,
                    49.6066
                 ],
                 [
                    113.0269,
                    49.5978
                 ],
                 [
                    113.0143,
                    49.5935
                 ],
                 [
                    112.9977,
                    49.5956
                 ],
                 [
                    112.9936,
                    49.5958
                 ],
                 [
                    112.9753,
                    49.588
                 ],
                 [
                    112.9683,
                    49.5853
                 ],
                 [
                    112.96,
                    49.5845
                 ],
                 [
                    112.9475,
                    49.5847
                 ],
                 [
                    112.9433,
                    49.583
                 ],
                 [
                    112.9376,
                    49.5777
                 ],
                 [
                    112.9347,
                    49.5751
                 ],
                 [
                    112.9318,
                    49.5724
                 ],
                 [
                    112.9234,
                    49.5698
                 ],
                 [
                    112.9151,
                    49.5691
                 ],
                 [
                    112.9068,
                    49.5685
                 ],
                 [
                    112.8997,
                    49.563
                 ],
                 [
                    112.8785,
                    49.5517
                 ],
                 [
                    112.8629,
                    49.5421
                 ],
                 [
                    112.8516,
                    49.5351
                 ],
                 [
                    112.8474,
                    49.5324
                 ],
                 [
                    112.8431,
                    49.5307
                 ],
                 [
                    112.836,
                    49.5253
                 ],
                 [
                    112.8234,
                    49.5193
                 ],
                 [
                    112.8108,
                    49.5159
                 ],
                 [
                    112.8066,
                    49.5151
                 ],
                 [
                    112.8025,
                    49.516
                 ],
                 [
                    112.7985,
                    49.5179
                 ],
                 [
                    112.7943,
                    49.5169
                 ],
                 [
                    112.7901,
                    49.5153
                 ],
                 [
                    112.78580479999997,
                    49.51512830000004
                 ],
                 [
                    112.7776,
                    49.5148
                 ],
                 [
                    112.7691,
                    49.513
                 ],
                 [
                    112.761,
                    49.5129
                 ],
                 [
                    112.7553,
                    49.5096
                 ],
                 [
                    112.7442,
                    49.5079
                 ],
                 [
                    112.7361,
                    49.5108
                 ],
                 [
                    112.7322,
                    49.5179
                 ],
                 [
                    112.7226,
                    49.52
                 ],
                 [
                    112.7115,
                    49.5183
                 ],
                 [
                    112.7072,
                    49.5146
                 ],
                 [
                    112.7098,
                    49.5112
                 ],
                 [
                    112.707,
                    49.5078
                 ],
                 [
                    112.7055,
                    49.5067
                 ],
                 [
                    112.6972,
                    49.5042
                 ],
                 [
                    112.6889,
                    49.5041
                 ],
                 [
                    112.6766,
                    49.508
                 ],
                 [
                    112.6642,
                    49.51
                 ],
                 [
                    112.656,
                    49.511
                 ],
                 [
                    112.6436,
                    49.5139
                 ],
                 [
                    112.6354,
                    49.5139
                 ],
                 [
                    112.6145,
                    49.5098
                 ],
                 [
                    112.6021,
                    49.51
                 ],
                 [
                    112.5992286,
                    49.5107437
                 ],
                 [
                    112.5911,
                    49.5129
                 ],
                 [
                    112.576,
                    49.5159
                 ],
                 [
                    112.5692,
                    49.5168
                 ],
                 [
                    112.554,
                    49.517
                 ],
                 [
                    112.5333,
                    49.519
                 ],
                 [
                    112.5196,
                    49.521
                 ],
                 [
                    112.5154,
                    49.5209
                 ],
                 [
                    112.4948,
                    49.5231
                 ],
                 [
                    112.4865,
                    49.5241
                 ],
                 [
                    112.4741,
                    49.5225
                 ],
                 [
                    112.4615,
                    49.52
                 ],
                 [
                    112.4322,
                    49.5095
                 ],
                 [
                    112.4031,
                    49.5034
                 ],
                 [
                    112.3862,
                    49.502
                 ],
                 [
                    112.3657,
                    49.4995
                 ],
                 [
                    112.3574,
                    49.4996
                 ],
                 [
                    112.3555495,
                    49.49929480000004
                 ],
                 [
                    112.3477,
                    49.498
                 ],
                 [
                    112.3366,
                    49.4954
                 ],
                 [
                    112.3282,
                    49.4937
                 ],
                 [
                    112.3241,
                    49.4919
                 ],
                 [
                    112.3144,
                    49.4911
                 ],
                 [
                    112.295,
                    49.4887
                 ],
                 [
                    112.2783,
                    49.4844
                 ],
                 [
                    112.2532,
                    49.4729
                 ],
                 [
                    112.2407,
                    49.4686
                 ],
                 [
                    112.2323,
                    49.4642
                 ],
                 [
                    112.2197,
                    49.457
                 ],
                 [
                    112.1946,
                    49.4466
                 ],
                 [
                    112.1738,
                    49.4423
                 ],
                 [
                    112.1655,
                    49.4397
                 ],
                 [
                    112.128,
                    49.4311
                 ],
                 [
                    112.1183,
                    49.4283
                 ],
                 [
                    112.11,
                    49.425
                 ],
                 [
                    112.0906,
                    49.4196
                 ],
                 [
                    112.0781,
                    49.4163
                 ],
                 [
                    112.0698,
                    49.4137
                 ],
                 [
                    112.0559,
                    49.4102
                 ],
                 [
                    112.0448,
                    49.4076
                 ],
                 [
                    112.0323,
                    49.4021
                 ],
                 [
                    112.0212,
                    49.3988
                 ],
                 [
                    112.0074,
                    49.3944
                 ],
                 [
                    111.99605590000002,
                    49.39188559999998
                 ],
                 [
                    111.988,
                    49.3901
                 ],
                 [
                    111.9728,
                    49.3866
                 ],
                 [
                    111.9658,
                    49.3849
                 ],
                 [
                    111.9575,
                    49.3823
                 ],
                 [
                    111.9492,
                    49.3816
                 ],
                 [
                    111.9412,
                    49.3797
                 ],
                 [
                    111.9285,
                    49.3789
                 ],
                 [
                    111.9203,
                    49.3781
                 ],
                 [
                    111.9092,
                    49.3764
                 ],
                 [
                    111.9023,
                    49.3764
                 ],
                 [
                    111.8844,
                    49.3757
                 ],
                 [
                    111.8693,
                    49.3758
                 ],
                 [
                    111.84834590000004,
                    49.374585899999985
                 ],
                 [
                    111.8417,
                    49.3742
                 ],
                 [
                    111.8169,
                    49.3753
                 ],
                 [
                    111.8018,
                    49.3788
                 ],
                 [
                    111.7882,
                    49.3844
                 ],
                 [
                    111.7799,
                    49.3852
                 ],
                 [
                    111.7551,
                    49.3865
                 ],
                 [
                    111.7303,
                    49.3857
                 ],
                 [
                    111.7221,
                    49.3858
                 ],
                 [
                    111.7056,
                    49.3859
                 ],
                 [
                    111.689,
                    49.3842
                 ],
                 [
                    111.6683,
                    49.3798
                 ],
                 [
                    111.66,
                    49.3763
                 ],
                 [
                    111.6516,
                    49.3718
                 ],
                 [
                    111.6309,
                    49.3629
                 ],
                 [
                    111.6226,
                    49.3594
                 ],
                 [
                    111.6101,
                    49.354
                 ],
                 [
                    111.606,
                    49.3523
                 ],
                 [
                    111.5977,
                    49.3478
                 ],
                 [
                    111.5783,
                    49.3407
                 ],
                 [
                    111.5589,
                    49.3318
                 ],
                 [
                    111.5451,
                    49.3265
                 ],
                 [
                    111.5231,
                    49.3232
                 ],
                 [
                    111.5148,
                    49.323
                 ],
                 [
                    111.4888,
                    49.3293
                 ],
                 [
                    111.4778,
                    49.3322
                 ],
                 [
                    111.4627,
                    49.3368
                 ],
                 [
                    111.4407,
                    49.3413
                 ],
                 [
                    111.4215,
                    49.3477
                 ],
                 [
                    111.4078,
                    49.3504
                 ],
                 [
                    111.383,
                    49.3568
                 ],
                 [
                    111.3748,
                    49.3577
                 ],
                 [
                    111.3665,
                    49.3552
                 ],
                 [
                    111.3582,
                    49.3506
                 ],
                 [
                    111.3099,
                    49.3256
                 ],
                 [
                    111.2975,
                    49.3193
                 ],
                 [
                    111.2892,
                    49.3157
                 ],
                 [
                    111.2768,
                    49.3104
                 ],
                 [
                    111.2644,
                    49.3068
                 ],
                 [
                    111.2355,
                    49.3006
                 ],
                 [
                    111.2231,
                    49.2968
                 ],
                 [
                    111.2107,
                    49.2925
                 ],
                 [
                    111.1818,
                    49.2854
                 ],
                 [
                    111.1612,
                    49.2817
                 ],
                 [
                    111.1405,
                    49.2754
                 ],
                 [
                    111.1158,
                    49.2693
                 ],
                 [
                    111.0869,
                    49.2639
                 ],
                 [
                    111.0787,
                    49.2621
                 ],
                 [
                    111.0498,
                    49.2531
                 ],
                 [
                    111.0459,
                    49.2519
                 ],
                 [
                    111.0333,
                    49.2478
                 ],
                 [
                    110.9551,
                    49.2215
                 ],
                 [
                    110.9386,
                    49.2136
                 ],
                 [
                    110.9263,
                    49.21
                 ],
                 [
                    110.844,
                    49.1874
                 ],
                 [
                    110.8358,
                    49.1865
                 ],
                 [
                    110.8193,
                    49.182
                 ],
                 [
                    110.807,
                    49.1802
                 ],
                 [
                    110.7768,
                    49.1774
                 ],
                 [
                    110.7686,
                    49.1774
                 ],
                 [
                    110.7617,
                    49.1765
                 ],
                 [
                    110.7453,
                    49.1738
                 ],
                 [
                    110.7302,
                    49.1719
                 ],
                 [
                    110.7083,
                    49.1701
                 ],
                 [
                    110.6836,
                    49.1709
                 ],
                 [
                    110.6177,
                    49.1833
                 ],
                 [
                    110.5998,
                    49.1876
                 ],
                 [
                    110.5902,
                    49.1895
                 ],
                 [
                    110.5724,
                    49.1893
                 ],
                 [
                    110.5641,
                    49.1912
                 ],
                 [
                    110.5518,
                    49.1938
                 ],
                 [
                    110.5244,
                    49.1901
                 ],
                 [
                    110.5092,
                    49.1973
                 ],
                 [
                    110.5051,
                    49.199
                 ],
                 [
                    110.4968,
                    49.1983
                 ],
                 [
                    110.4803,
                    49.2007
                 ],
                 [
                    110.4762,
                    49.2016
                 ],
                 [
                    110.4721,
                    49.2034
                 ],
                 [
                    110.4624,
                    49.208
                 ],
                 [
                    110.4555,
                    49.2107
                 ],
                 [
                    110.4431,
                    49.2159
                 ],
                 [
                    110.4252,
                    49.223
                 ],
                 [
                    110.4142,
                    49.2283
                 ],
                 [
                    110.4099,
                    49.2364
                 ],
                 [
                    110.4057,
                    49.2444
                 ],
                 [
                    110.4043,
                    49.2489
                 ],
                 [
                    110.3905,
                    49.2515
                 ],
                 [
                    110.3796,
                    49.247
                 ],
                 [
                    110.3593,
                    49.2271
                 ],
                 [
                    110.3511,
                    49.2179
                 ],
                 [
                    110.3348,
                    49.2045
                 ],
                 [
                    110.3281,
                    49.1991
                 ],
                 [
                    110.3062,
                    49.1908
                 ],
                 [
                    110.2939,
                    49.1863
                 ],
                 [
                    110.2654,
                    49.1717
                 ],
                 [
                    110.2463,
                    49.1642
                 ],
                 [
                    110.234,
                    49.1607
                 ],
                 [
                    110.2258,
                    49.1581
                 ],
                 [
                    110.1998,
                    49.1542
                 ],
                 [
                    110.1751,
                    49.1583
                 ],
                 [
                    110.1778,
                    49.1612
                 ],
                 [
                    110.1571,
                    49.1656
                 ],
                 [
                    110.1475,
                    49.1665
                 ],
                 [
                    110.1352,
                    49.1664
                 ],
                 [
                    110.1365,
                    49.1708
                 ],
                 [
                    110.1199,
                    49.178
                 ],
                 [
                    110.1047,
                    49.1805
                 ],
                 [
                    110.0924,
                    49.1831
                 ],
                 [
                    110.08,
                    49.183
                 ],
                 [
                    110.0677,
                    49.1829
                 ],
                 [
                    110.0471,
                    49.1843
                 ],
                 [
                    110.0306,
                    49.1851
                 ],
                 [
                    110.014,
                    49.1905
                 ],
                 [
                    110.0058,
                    49.1922
                 ],
                 [
                    109.9895,
                    49.1948
                 ],
                 [
                    109.9563,
                    49.1954
                 ],
                 [
                    109.9439,
                    49.1971
                 ],
                 [
                    109.9191,
                    49.2013
                 ],
                 [
                    109.9026,
                    49.2057
                 ],
                 [
                    109.8943,
                    49.2056
                 ],
                 [
                    109.8739,
                    49.2009
                 ],
                 [
                    109.8533,
                    49.2016
                 ],
                 [
                    109.8409,
                    49.2042
                 ],
                 [
                    109.8201,
                    49.2112
                 ],
                 [
                    109.8049,
                    49.2136
                 ],
                 [
                    109.7939,
                    49.2163
                 ],
                 [
                    109.7733,
                    49.218
                 ],
                 [
                    109.7581,
                    49.2204
                 ],
                 [
                    109.7292,
                    49.2228
                 ],
                 [
                    109.7127,
                    49.2235
                 ],
                 [
                    109.6838,
                    49.226
                 ],
                 [
                    109.6509,
                    49.2256
                 ],
                 [
                    109.6399,
                    49.2245
                 ],
                 [
                    109.6304,
                    49.2235
                 ],
                 [
                    109.6221,
                    49.2234
                 ],
                 [
                    109.6057,
                    49.2205
                 ],
                 [
                    109.5988,
                    49.2213
                 ],
                 [
                    109.5838,
                    49.2202
                 ],
                 [
                    109.5701,
                    49.22
                 ],
                 [
                    109.5591,
                    49.2208
                 ],
                 [
                    109.5508,
                    49.2225
                 ],
                 [
                    109.5397,
                    49.2258
                 ],
                 [
                    109.5342,
                    49.2268
                 ],
                 [
                    109.5244,
                    49.2304
                 ],
                 [
                    109.5161,
                    49.2328
                 ],
                 [
                    109.5037,
                    49.2345
                 ],
                 [
                    109.4996,
                    49.2362
                 ],
                 [
                    109.487,
                    49.2432
                 ],
                 [
                    109.4841,
                    49.2486
                 ],
                 [
                    109.4768,
                    49.262
                 ],
                 [
                    109.4753,
                    49.2674
                 ],
                 [
                    109.4711,
                    49.269
                 ],
                 [
                    109.4586,
                    49.2716
                 ],
                 [
                    109.4462,
                    49.2731
                 ],
                 [
                    109.4325,
                    49.2729
                 ],
                 [
                    109.4254,
                    49.2784
                 ],
                 [
                    109.428,
                    49.2855
                 ],
                 [
                    109.4222,
                    49.2944
                 ],
                 [
                    109.4124,
                    49.2998
                 ],
                 [
                    109.3900023,
                    49.3002688
                 ],
                 [
                    109.3876,
                    49.3023
                 ],
                 [
                    109.3776,
                    49.3119
                 ],
                 [
                    109.3618,
                    49.3168
                 ],
                 [
                    109.3609,
                    49.3171
                 ],
                 [
                    109.3455,
                    49.3249
                 ],
                 [
                    109.3261,
                    49.3292
                 ],
                 [
                    109.2971,
                    49.3341
                 ],
                 [
                    109.2639,
                    49.3399
                 ],
                 [
                    109.2472,
                    49.3425
                 ],
                 [
                    109.2057,
                    49.3498
                 ],
                 [
                    109.1946,
                    49.3523
                 ],
                 [
                    109.1863,
                    49.3528
                 ],
                 [
                    109.1808,
                    49.353
                 ],
                 [
                    109.1712,
                    49.3529
                 ],
                 [
                    109.163,
                    49.351
                 ],
                 [
                    109.1521,
                    49.3481
                 ],
                 [
                    109.1357,
                    49.3442
                 ],
                 [
                    109.1289,
                    49.3423
                 ],
                 [
                    109.1194,
                    49.3377
                 ],
                 [
                    109.1113,
                    49.3348
                 ],
                 [
                    109.1045,
                    49.334
                 ],
                 [
                    109.0867,
                    49.3299
                 ],
                 [
                    109.0758,
                    49.3281
                 ],
                 [
                    109.0581,
                    49.3232
                 ],
                 [
                    109.0388,
                    49.3246
                 ],
                 [
                    109.0263,
                    49.328
                 ],
                 [
                    109.0137,
                    49.3314
                 ],
                 [
                    108.9665,
                    49.3423
                 ],
                 [
                    108.9624,
                    49.3431
                 ],
                 [
                    108.9554,
                    49.3448
                 ],
                 [
                    108.9499,
                    49.3454
                 ],
                 [
                    108.9416,
                    49.3462
                 ],
                 [
                    108.9278,
                    49.3461
                 ],
                 [
                    108.9182,
                    49.3459
                 ],
                 [
                    108.9086,
                    49.345
                 ],
                 [
                    108.9006,
                    49.3402
                 ],
                 [
                    108.8993,
                    49.3375
                 ],
                 [
                    108.8953,
                    49.3347
                 ],
                 [
                    108.8912,
                    49.3337
                 ],
                 [
                    108.883,
                    49.3333
                 ],
                 [
                    108.8662,
                    49.3378
                 ],
                 [
                    108.8621,
                    49.3377
                 ],
                 [
                    108.8555,
                    49.3322
                 ],
                 [
                    108.8542,
                    49.3293
                 ],
                 [
                    108.8488,
                    49.3266
                 ],
                 [
                    108.8432,
                    49.331
                 ],
                 [
                    108.8375,
                    49.3345
                 ],
                 [
                    108.8331,
                    49.3398
                 ],
                 [
                    108.8276,
                    49.3415
                 ],
                 [
                    108.8206,
                    49.3432
                 ],
                 [
                    108.8137,
                    49.3431
                 ],
                 [
                    108.7962,
                    49.3346
                 ],
                 [
                    108.7742,
                    49.3342
                 ],
                 [
                    108.7699,
                    49.3386
                 ],
                 [
                    108.7574,
                    49.3412
                 ],
                 [
                    108.7464,
                    49.3416
                 ],
                 [
                    108.7382,
                    49.3398
                 ],
                 [
                    108.7301,
                    49.337
                 ],
                 [
                    108.7178,
                    49.3349
                 ],
                 [
                    108.7094,
                    49.3366
                 ],
                 [
                    108.6929,
                    49.3362
                 ],
                 [
                    108.6763,
                    49.3386
                 ],
                 [
                    108.6721,
                    49.3403
                 ],
                 [
                    108.6692,
                    49.3429
                 ],
                 [
                    108.6608,
                    49.3464
                 ],
                 [
                    108.6525,
                    49.3473
                 ],
                 [
                    108.6471,
                    49.3443
                 ],
                 [
                    108.6363,
                    49.3414
                 ],
                 [
                    108.6267,
                    49.3404
                 ],
                 [
                    108.6284,
                    49.3331
                 ],
                 [
                    108.6162,
                    49.3294
                 ],
                 [
                    108.6081,
                    49.3273
                 ],
                 [
                    108.5984,
                    49.328
                 ],
                 [
                    108.5871,
                    49.3332
                 ],
                 [
                    108.5815,
                    49.3348
                 ],
                 [
                    108.5789,
                    49.3319
                 ],
                 [
                    108.57140180000003,
                    49.33114329999999
                 ],
                 [
                    108.568,
                    49.3308
                 ],
                 [
                    108.5584,
                    49.3299
                 ],
                 [
                    108.5379,
                    49.3267
                 ],
                 [
                    108.5299,
                    49.3212
                 ],
                 [
                    108.5175,
                    49.3227
                 ],
                 [
                    108.5089,
                    49.3297
                 ],
                 [
                    108.4904,
                    49.3419
                 ],
                 [
                    108.4844,
                    49.3508
                 ],
                 [
                    108.4701,
                    49.3612
                 ],
                 [
                    108.4662,
                    49.3567
                 ],
                 [
                    108.4452,
                    49.3634
                 ],
                 [
                    108.4409,
                    49.3669
                 ],
                 [
                    108.4392,
                    49.3722
                 ],
                 [
                    108.431,
                    49.3721
                 ],
                 [
                    108.4346,
                    49.382
                 ],
                 [
                    108.4381,
                    49.3938
                 ],
                 [
                    108.4258,
                    49.4135
                 ],
                 [
                    108.4107,
                    49.4184
                 ],
                 [
                    108.3983,
                    49.4181
                 ],
                 [
                    108.39,
                    49.4187
                 ],
                 [
                    108.3774,
                    49.4221
                 ],
                 [
                    108.3648,
                    49.4253
                 ],
                 [
                    108.3564,
                    49.4279
                 ],
                 [
                    108.3533,
                    49.4333
                 ],
                 [
                    108.3515,
                    49.4413
                 ],
                 [
                    108.3511,
                    49.4494
                 ],
                 [
                    108.3508,
                    49.4548
                 ],
                 [
                    108.3504,
                    49.4629
                 ],
                 [
                    108.3502,
                    49.4656
                 ],
                 [
                    108.3456,
                    49.4735
                 ],
                 [
                    108.3369,
                    49.4814
                 ],
                 [
                    108.334,
                    49.485
                 ],
                 [
                    108.3337,
                    49.4895
                 ],
                 [
                    108.3306,
                    49.4975
                 ],
                 [
                    108.3276,
                    49.5001
                 ],
                 [
                    108.3108,
                    49.506
                 ],
                 [
                    108.3065,
                    49.5077
                 ],
                 [
                    108.3036,
                    49.5103
                 ],
                 [
                    108.3039,
                    49.5294
                 ],
                 [
                    108.3038,
                    49.5319
                 ],
                 [
                    108.2978,
                    49.5399
                 ],
                 [
                    108.2936,
                    49.5416
                 ],
                 [
                    108.281,
                    49.5449
                 ],
                 [
                    108.2686,
                    49.5437
                 ],
                 [
                    108.2343,
                    49.5402
                 ],
                 [
                    108.2354,
                    49.5438
                 ],
                 [
                    108.2283,
                    49.5471
                 ],
                 [
                    108.2227,
                    49.5489
                 ],
                 [
                    108.2061,
                    49.5485
                 ],
                 [
                    108.2018,
                    49.5521
                 ],
                 [
                    108.1988,
                    49.5564
                 ],
                 [
                    108.1959,
                    49.559
                 ],
                 [
                    108.1849,
                    49.558
                 ],
                 [
                    108.1709,
                    49.5611
                 ],
                 [
                    108.168,
                    49.5629
                 ],
                 [
                    108.1666,
                    49.5628
                 ],
                 [
                    108.1653,
                    49.5619
                 ],
                 [
                    108.1611,
                    49.5618
                 ],
                 [
                    108.1569,
                    49.5635
                 ],
                 [
                    108.1525,
                    49.5671
                 ],
                 [
                    108.1384,
                    49.5729
                 ],
                 [
                    108.1356,
                    49.5727
                 ],
                 [
                    108.1243,
                    49.5771
                 ],
                 [
                    108.1308,
                    49.5835
                 ],
                 [
                    108.094,
                    49.5988
                 ],
                 [
                    108.0771,
                    49.6038
                 ],
                 [
                    108.0727,
                    49.6083
                 ],
                 [
                    108.0641,
                    49.6124
                 ],
                 [
                    108.0513,
                    49.6175
                 ],
                 [
                    108.0318,
                    49.6206
                 ],
                 [
                    108.0275,
                    49.6223
                 ],
                 [
                    108.0258,
                    49.6276
                 ],
                 [
                    108.0338,
                    49.6323
                 ],
                 [
                    108.0349,
                    49.6379
                 ],
                 [
                    108.0304,
                    49.6429
                 ],
                 [
                    108.0242,
                    49.6535
                 ],
                 [
                    108.0239,
                    49.6582
                 ],
                 [
                    108.0277,
                    49.6646
                 ],
                 [
                    108.0275,
                    49.6681
                 ],
                 [
                    108.023,
                    49.6734
                 ],
                 [
                    108.0176,
                    49.6715
                 ],
                 [
                    108.0123,
                    49.6669
                 ],
                 [
                    108.0069,
                    49.6649
                 ],
                 [
                    108,
                    49.6634
                 ],
                 [
                    107.988,
                    49.6586
                 ],
                 [
                    107.9753,
                    49.6555
                 ],
                 [
                    107.9683,
                    49.6548
                 ],
                 [
                    107.9603,
                    49.6593
                 ],
                 [
                    107.9471,
                    49.6706
                 ],
                 [
                    107.9446,
                    49.6752
                 ],
                 [
                    107.9423,
                    49.6824
                 ],
                 [
                    107.9399,
                    49.6886
                 ],
                 [
                    107.9362,
                    49.6969
                 ],
                 [
                    107.9339,
                    49.7042
                 ],
                 [
                    107.9343,
                    49.7114
                 ],
                 [
                    107.9416,
                    49.7166
                 ],
                 [
                    107.9576,
                    49.7289
                 ],
                 [
                    107.9677,
                    49.735
                 ],
                 [
                    107.9694,
                    49.7402
                 ],
                 [
                    107.9698,
                    49.7474
                 ],
                 [
                    107.9633,
                    49.7546
                 ],
                 [
                    107.9582,
                    49.7611
                 ],
                 [
                    107.9556,
                    49.7647
                 ],
                 [
                    107.9496,
                    49.7802
                 ],
                 [
                    107.9485,
                    49.7848
                 ],
                 [
                    107.9526,
                    49.7883
                 ],
                 [
                    107.9585,
                    49.789
                 ],
                 [
                    107.9726,
                    49.7922
                 ],
                 [
                    107.9784,
                    49.7975
                 ],
                 [
                    107.9816,
                    49.8028
                 ],
                 [
                    107.9806,
                    49.8091
                 ],
                 [
                    107.9738,
                    49.8129
                 ],
                 [
                    107.9601,
                    49.815
                 ],
                 [
                    107.9449,
                    49.8165
                 ],
                 [
                    107.9367,
                    49.8192
                 ],
                 [
                    107.9329,
                    49.8256
                 ],
                 [
                    107.9319,
                    49.8319
                 ],
                 [
                    107.9378,
                    49.8381
                 ],
                 [
                    107.9439,
                    49.8469
                 ],
                 [
                    107.9444,
                    49.8549
                 ],
                 [
                    107.9364,
                    49.8597
                 ],
                 [
                    107.9268,
                    49.8627
                 ],
                 [
                    107.92332129999996,
                    49.86554639999998
                 ],
                 [
                    107.9202,
                    49.8681
                 ],
                 [
                    107.9192,
                    49.8754
                 ],
                 [
                    107.9237,
                    49.8798
                 ],
                 [
                    107.9381,
                    49.8875
                 ],
                 [
                    107.9527,
                    49.8989
                 ],
                 [
                    107.96,
                    49.905
                 ],
                 [
                    107.9659,
                    49.9102
                 ],
                 [
                    107.9704,
                    49.9155
                 ],
                 [
                    107.9736,
                    49.9226
                 ],
                 [
                    107.9745,
                    49.937
                 ],
                 [
                    107.96905479999997,
                    49.9413946
                 ],
                 [
                    107.96350030000002,
                    49.94217860000003
                 ],
                 [
                    107.95817739999995,
                    49.9432217
                 ],
                 [
                    107.95610019999995,
                    49.94365990000002
                 ],
                 [
                    107.9512,
                    49.9439
                 ],
                 [
                    107.9442,
                    49.9433
                 ],
                 [
                    107.9357,
                    49.9407
                 ],
                 [
                    107.9299,
                    49.9372
                 ],
                 [
                    107.9257,
                    49.9364
                 ],
                 [
                    107.9146,
                    49.9367
                 ],
                 [
                    107.90845470000002,
                    49.940023499999974
                 ],
                 [
                    107.89883030000003,
                    49.93596560000004
                 ],
                 [
                    107.8878636,
                    49.933583899999974
                 ],
                 [
                    107.877552,
                    49.935167700000015
                 ],
                 [
                    107.8647798,
                    49.93650159999998
                 ],
                 [
                    107.8531964,
                    49.93645580000001
                 ],
                 [
                    107.84854340000004,
                    49.9388817
                 ],
                 [
                    107.84973079999996,
                    49.95222710000002
                 ],
                 [
                    107.84493980000003,
                    49.95416429999999
                 ],
                 [
                    107.8417222,
                    49.95919170000003
                 ],
                 [
                    107.83796259999997,
                    49.961266
                 ],
                 [
                    107.835867,
                    49.9611988
                 ],
                 [
                    107.82874240000002,
                    49.9609702
                 ],
                 [
                    107.82250050000005,
                    49.9568684
                 ],
                 [
                    107.8159853,
                    49.95018750000002
                 ],
                 [
                    107.8143181,
                    49.944334
                 ],
                 [
                    107.81022569999996,
                    49.9372286
                 ],
                 [
                    107.8035005,
                    49.93275680000002
                 ],
                 [
                    107.79422069999998,
                    49.93317049999997
                 ],
                 [
                    107.7911444,
                    49.936514199999955
                 ],
                 [
                    107.78495449999997,
                    49.94258450000004
                 ],
                 [
                    107.781052,
                    49.94623740000004
                 ],
                 [
                    107.77793939999997,
                    49.948741800000036
                 ],
                 [
                    107.766251,
                    49.953472
                 ],
                 [
                    107.76012820000001,
                    49.9588619
                 ],
                 [
                    107.75921659999999,
                    49.9614359
                 ],
                 [
                    107.75940329999999,
                    49.9646253
                 ],
                 [
                    107.7585661,
                    49.967677899999984
                 ],
                 [
                    107.75430480000001,
                    49.97058410000001
                 ],
                 [
                    107.74748979999995,
                    49.97387850000001
                 ],
                 [
                    107.73814180000002,
                    49.977934400000045
                 ],
                 [
                    107.7297546,
                    49.980193199999974
                 ],
                 [
                    107.72915639999997,
                    49.9826979
                 ],
                 [
                    107.7208536,
                    49.98443710000002
                 ],
                 [
                    107.71198950000003,
                    49.98687520000004
                 ],
                 [
                    107.706604,
                    49.9879545
                 ],
                 [
                    107.70207190000004,
                    49.988129800000024
                 ],
                 [
                    107.69455640000001,
                    49.98658610000002
                 ],
                 [
                    107.68831049999997,
                    49.98543909999997
                 ],
                 [
                    107.68582029999999,
                    49.9853633
                 ],
                 [
                    107.6833428,
                    49.98490190000002
                 ],
                 [
                    107.6802331,
                    49.9788528
                 ],
                 [
                    107.67966919999996,
                    49.97708439999997
                 ],
                 [
                    107.68173109999995,
                    49.974646200000024
                 ],
                 [
                    107.68211190000002,
                    49.973142500000044
                 ],
                 [
                    107.68105910000004,
                    49.972113399999984
                 ],
                 [
                    107.67883619999996,
                    49.9717817
                 ],
                 [
                    107.67522949999997,
                    49.9724034
                 ],
                 [
                    107.672092,
                    49.97415489999997
                 ],
                 [
                    107.66477040000002,
                    49.9756024
                 ],
                 [
                    107.6607962,
                    49.975692299999984
                 ],
                 [
                    107.65770470000001,
                    49.9745537
                 ],
                 [
                    107.6560771,
                    49.9724792
                 ],
                 [
                    107.65329960000004,
                    49.97194
                 ],
                 [
                    107.6500648,
                    49.972374
                 ],
                 [
                    107.64791229999997,
                    49.9732902
                 ],
                 [
                    107.64676080000001,
                    49.974996400000045
                 ],
                 [
                    107.64595150000001,
                    49.97697719999998
                 ],
                 [
                    107.645286,
                    49.9774197
                 ],
                 [
                    107.6421842,
                    49.977717
                 ],
                 [
                    107.63900760000003,
                    49.977372800000026
                 ],
                 [
                    107.63711119999998,
                    49.97634389999998
                 ],
                 [
                    107.63411790000002,
                    49.97675560000003
                 ],
                 [
                    107.62813260000004,
                    49.975977
                 ],
                 [
                    107.62217529999998,
                    49.97571220000003
                 ],
                 [
                    107.61929370000004,
                    49.9749197
                 ],
                 [
                    107.614336,
                    49.97182840000003
                 ],
                 [
                    107.60732739999999,
                    49.9701565
                 ],
                 [
                    107.5987044,
                    49.969779200000026
                 ],
                 [
                    107.5895169,
                    49.970444
                 ],
                 [
                    107.58502380000004,
                    49.9710025
                 ],
                 [
                    107.58238539999996,
                    49.97306410000004
                 ],
                 [
                    107.57849089999996,
                    49.97486769999997
                 ],
                 [
                    107.57637729999996,
                    49.97680319999997
                 ],
                 [
                    107.5741748,
                    49.977081099999964
                 ],
                 [
                    107.56718490000002,
                    49.97906790000004
                 ],
                 [
                    107.5654039,
                    49.978936
                 ],
                 [
                    107.56126429999998,
                    49.979485899999986
                 ],
                 [
                    107.5576955,
                    49.9807667
                 ],
                 [
                    107.5538574,
                    49.98365519999999
                 ],
                 [
                    107.54967690000002,
                    49.985121
                 ],
                 [
                    107.54482769999998,
                    49.98568580000004
                 ],
                 [
                    107.541986,
                    49.985634200000035
                 ],
                 [
                    107.539231,
                    49.98386410000004
                 ],
                 [
                    107.53562440000002,
                    49.981083
                 ],
                 [
                    107.53169240000003,
                    49.97716479999996
                 ],
                 [
                    107.52850430000004,
                    49.9755756
                 ],
                 [
                    107.5239769,
                    49.97550329999998
                 ],
                 [
                    107.513904,
                    49.976238499999965
                 ],
                 [
                    107.50409070000003,
                    49.9768529
                 ],
                 [
                    107.49778550000005,
                    49.9767037
                 ],
                 [
                    107.49141530000003,
                    49.97701349999996
                 ],
                 [
                    107.48544370000002,
                    49.9781722
                 ],
                 [
                    107.48199109999999,
                    49.98002019999996
                 ],
                 [
                    107.4762343,
                    49.983634200000026
                 ],
                 [
                    107.47250340000004,
                    49.985259
                 ],
                 [
                    107.4687845,
                    49.985396
                 ],
                 [
                    107.46489480000004,
                    49.98393460000001
                 ],
                 [
                    107.461157,
                    49.9802386
                 ],
                 [
                    107.45653870000001,
                    49.978392099999986
                 ],
                 [
                    107.45110459999998,
                    49.97793579999998
                 ],
                 [
                    107.44644070000001,
                    49.97866430000004
                 ],
                 [
                    107.4405376,
                    49.9811925
                 ],
                 [
                    107.43488789999998,
                    49.983486
                 ],
                 [
                    107.43050819999998,
                    49.98283489999997
                 ],
                 [
                    107.42626530000001,
                    49.9813797
                 ],
                 [
                    107.42471770000004,
                    49.9788378
                 ],
                 [
                    107.42634260000003,
                    49.9758858
                 ],
                 [
                    107.43012530000001,
                    49.9735176
                 ],
                 [
                    107.43005609999997,
                    49.972146
                 ],
                 [
                    107.42825819999997,
                    49.971669
                 ],
                 [
                    107.42528349999996,
                    49.9724755
                 ],
                 [
                    107.4196429,
                    49.97493989999999
                 ],
                 [
                    107.41551939999997,
                    49.977600799999976
                 ],
                 [
                    107.41099220000001,
                    49.979297699999954
                 ],
                 [
                    107.40557269999996,
                    49.979124900000045
                 ],
                 [
                    107.39728090000001,
                    49.976494700000025
                 ],
                 [
                    107.39461150000002,
                    49.97632129999998
                 ],
                 [
                    107.38758,
                    49.97761159999998
                 ],
                 [
                    107.38296670000003,
                    49.977592800000025
                 ],
                 [
                    107.37837440000003,
                    49.981578
                 ],
                 [
                    107.37556679999999,
                    49.9822081
                 ],
                 [
                    107.36964830000002,
                    49.984504
                 ],
                 [
                    107.36085049999996,
                    49.98960490000004
                 ],
                 [
                    107.3532864,
                    49.995924200000026
                 ],
                 [
                    107.350848,
                    49.9986773
                 ],
                 [
                    107.34894670000003,
                    49.99971719999998
                 ],
                 [
                    107.34450229999999,
                    49.999564599999964
                 ],
                 [
                    107.34068010000003,
                    49.99988559999997
                 ],
                 [
                    107.33916679999996,
                    50.000245299999975
                 ],
                 [
                    107.33836160000004,
                    50.001019700000015
                 ],
                 [
                    107.336993,
                    50.0016195
                 ],
                 [
                    107.3362346,
                    50.0015348
                 ],
                 [
                    107.33597070000002,
                    50.00112549999997
                 ],
                 [
                    107.3365619,
                    50.000055
                 ],
                 [
                    107.33619679999998,
                    49.999390200000015
                 ],
                 [
                    107.33559630000003,
                    49.99935940000004
                 ],
                 [
                    107.33450629999999,
                    50.0002112
                 ],
                 [
                    107.332045,
                    50.00160549999999
                 ],
                 [
                    107.33078310000005,
                    50.00165970000003
                 ],
                 [
                    107.33003020000001,
                    50.00123150000004
                 ],
                 [
                    107.329021,
                    50.000551199999975
                 ],
                 [
                    107.32695370000002,
                    50.0009219
                 ],
                 [
                    107.32519230000003,
                    50.00120059999997
                 ],
                 [
                    107.3246734,
                    50.0019406
                 ],
                 [
                    107.3244878,
                    50.00314559999996
                 ],
                 [
                    107.32361369999997,
                    50.003420699999985
                 ],
                 [
                    107.3226489,
                    50.00319709999996
                 ],
                 [
                    107.3207478,
                    50.00196250000004
                 ],
                 [
                    107.31965550000002,
                    50.00185590000001
                 ],
                 [
                    107.3195446,
                    50.00231570000004
                 ],
                 [
                    107.31977169999996,
                    50.003341
                 ],
                 [
                    107.3194405,
                    50.00383389999998
                 ],
                 [
                    107.31734039999996,
                    50.003533
                 ],
                 [
                    107.31585110000002,
                    50.00393479999998
                 ],
                 [
                    107.31411410000001,
                    50.00379810000004
                 ],
                 [
                    107.3132552,
                    50.003472
                 ],
                 [
                    107.31306020000002,
                    50.00218870000001
                 ],
                 [
                    107.312055,
                    50.0006643
                 ],
                 [
                    107.31004959999997,
                    49.99956029999996
                 ],
                 [
                    107.30809240000004,
                    49.9994566
                 ],
                 [
                    107.3051056,
                    50.00008849999997
                 ],
                 [
                    107.30045770000001,
                    50.00123990000002
                 ],
                 [
                    107.29538050000004,
                    50.00177030000003
                 ],
                 [
                    107.291232,
                    50.00130950000001
                 ],
                 [
                    107.2859187,
                    50.00061420000004
                 ],
                 [
                    107.27943660000004,
                    50.000544
                 ],
                 [
                    107.27605830000003,
                    49.9985214
                 ],
                 [
                    107.2739788,
                    49.9958145
                 ],
                 [
                    107.27344030000003,
                    49.99190320000004
                 ],
                 [
                    107.2711385,
                    49.990116700000016
                 ],
                 [
                    107.26668380000002,
                    49.98971850000002
                 ],
                 [
                    107.2618884,
                    49.988668199999985
                 ],
                 [
                    107.25716689999996,
                    49.98824619999997
                 ],
                 [
                    107.25288619999998,
                    49.989676199999984
                 ],
                 [
                    107.24905479999995,
                    49.992185
                 ],
                 [
                    107.24774480000002,
                    49.995502899999984
                 ],
                 [
                    107.24573460000005,
                    49.997117
                 ],
                 [
                    107.24215030000005,
                    49.99827510000003
                 ],
                 [
                    107.23586639999996,
                    49.99862710000002
                 ],
                 [
                    107.23036,
                    49.9975609
                 ],
                 [
                    107.22692359999998,
                    49.998086
                 ],
                 [
                    107.2249239,
                    49.99898389999997
                 ],
                 [
                    107.2235598,
                    50.00115740000004
                 ],
                 [
                    107.22085930000003,
                    50.00516049999997
                 ],
                 [
                    107.2195332,
                    50.005272
                 ],
                 [
                    107.218632,
                    50.004001399999986
                 ],
                 [
                    107.21799750000004,
                    50.000807400000035
                 ],
                 [
                    107.21587850000003,
                    49.99910219999999
                 ],
                 [
                    107.21298660000004,
                    49.99892919999997
                 ],
                 [
                    107.21089670000003,
                    49.99979979999998
                 ],
                 [
                    107.2092162,
                    50.00283810000001
                 ],
                 [
                    107.2068353,
                    50.0060905
                 ],
                 [
                    107.204475,
                    50.00785360000004
                 ],
                 [
                    107.2022269,
                    50.00818329999996
                 ],
                 [
                    107.19947949999995,
                    50.0072915
                 ],
                 [
                    107.19525359999997,
                    50.00608440000003
                 ],
                 [
                    107.19296880000003,
                    50.00658629999999
                 ],
                 [
                    107.19099409999998,
                    50.008055499999955
                 ],
                 [
                    107.19073839999996,
                    50.0122114
                 ],
                 [
                    107.18868069999996,
                    50.01479870000003
                 ],
                 [
                    107.18608380000003,
                    50.015277799999964
                 ],
                 [
                    107.1836531,
                    50.01452280000002
                 ],
                 [
                    107.18190790000003,
                    50.0122372
                 ],
                 [
                    107.17763780000003,
                    50.01103039999999
                 ],
                 [
                    107.1728444,
                    50.012009099999965
                 ],
                 [
                    107.1690013,
                    50.013399
                 ],
                 [
                    107.16733639999998,
                    50.015863899999964
                 ],
                 [
                    107.16642179999998,
                    50.0192306
                 ],
                 [
                    107.1642752,
                    50.0218477
                 ],
                 [
                    107.16013850000004,
                    50.0236437
                 ],
                 [
                    107.15582640000004,
                    50.0244981
                 ],
                 [
                    107.15137209999999,
                    50.025154400000034
                 ],
                 [
                    107.14797969999996,
                    50.02673550000003
                 ],
                 [
                    107.14611690000002,
                    50.028774399999975
                 ],
                 [
                    107.1461272,
                    50.032009
                 ],
                 [
                    107.14779160000003,
                    50.034497
                 ],
                 [
                    107.1520775,
                    50.037021400000036
                 ],
                 [
                    107.15345969999997,
                    50.03914250000003
                 ],
                 [
                    107.15342959999997,
                    50.0414619
                 ],
                 [
                    107.15200270000003,
                    50.04332089999998
                 ],
                 [
                    107.15065379999999,
                    50.04394749999996
                 ],
                 [
                    107.1475452,
                    50.043948599999965
                 ],
                 [
                    107.1428458,
                    50.04211869999999
                 ],
                 [
                    107.1405272,
                    50.04190460000004
                 ],
                 [
                    107.13833760000003,
                    50.042604
                 ],
                 [
                    107.13636479999995,
                    50.04418670000003
                 ],
                 [
                    107.13554179999998,
                    50.04566209999999
                 ],
                 [
                    107.13547040000003,
                    50.048068200000024
                 ],
                 [
                    107.13463230000005,
                    50.04920019999999
                 ],
                 [
                    107.13138619999997,
                    50.05011960000003
                 ],
                 [
                    107.12697520000003,
                    50.049801
                 ],
                 [
                    107.12331810000005,
                    50.0474356
                 ],
                 [
                    107.12118810000003,
                    50.04747519999999
                 ],
                 [
                    107.117613,
                    50.046969
                 ],
                 [
                    107.11472290000005,
                    50.047909899999986
                 ],
                 [
                    107.11059850000002,
                    50.04804350000003
                 ],
                 [
                    107.10715820000004,
                    50.048593599999975
                 ],
                 [
                    107.10256429999998,
                    50.049164799999986
                 ],
                 [
                    107.09935820000001,
                    50.0499968
                 ],
                 [
                    107.09809380000002,
                    50.05156579999996
                 ],
                 [
                    107.09617619999999,
                    50.053433200000015
                 ],
                 [
                    107.09242749999997,
                    50.05507629999999
                 ],
                 [
                    107.09048459999997,
                    50.05637150000004
                 ],
                 [
                    107.09009030000004,
                    50.0585543
                 ],
                 [
                    107.09155169999998,
                    50.06050290000001
                 ],
                 [
                    107.0919663,
                    50.0628998
                 ],
                 [
                    107.0912234,
                    50.0652323
                 ],
                 [
                    107.0876016,
                    50.0667585
                 ],
                 [
                    107.08431879999998,
                    50.0668757
                 ],
                 [
                    107.0825456,
                    50.06593470000002
                 ],
                 [
                    107.08107950000002,
                    50.06284110000003
                 ],
                 [
                    107.0798835,
                    50.061889499999985
                 ],
                 [
                    107.07869369999997,
                    50.0621116
                 ],
                 [
                    107.07797960000003,
                    50.0630693
                 ],
                 [
                    107.07695350000004,
                    50.06503449999997
                 ],
                 [
                    107.0754942,
                    50.066234699999974
                 ],
                 [
                    107.07185230000003,
                    50.068333399999986
                 ],
                 [
                    107.071593,
                    50.069540300000014
                 ],
                 [
                    107.07317990000001,
                    50.07131510000004
                 ],
                 [
                    107.07304150000002,
                    50.07223370000002
                 ],
                 [
                    107.0708462,
                    50.072846
                 ],
                 [
                    107.069542,
                    50.0745586
                 ],
                 [
                    107.06788490000004,
                    50.076334899999985
                 ],
                 [
                    107.0664373,
                    50.07885160000003
                 ],
                 [
                    107.06740470000001,
                    50.08172540000004
                 ],
                 [
                    107.06961469999999,
                    50.083517399999984
                 ],
                 [
                    107.07313529999996,
                    50.08477050000002
                 ],
                 [
                    107.075104,
                    50.0861088
                 ],
                 [
                    107.07580440000001,
                    50.0879283
                 ],
                 [
                    107.07525019999997,
                    50.0895128
                 ],
                 [
                    107.0720753,
                    50.09114490000004
                 ],
                 [
                    107.06852689999995,
                    50.0913237
                 ],
                 [
                    107.06580010000002,
                    50.090972200000046
                 ],
                 [
                    107.063887,
                    50.08989029999997
                 ],
                 [
                    107.06185859999995,
                    50.0871501
                 ],
                 [
                    107.06018839999996,
                    50.08655040000003
                 ],
                 [
                    107.05877579999996,
                    50.086776299999975
                 ],
                 [
                    107.05699880000002,
                    50.087838899999966
                 ],
                 [
                    107.0537235,
                    50.0913044
                 ],
                 [
                    107.05223270000002,
                    50.09387880000002
                 ],
                 [
                    107.05052070000004,
                    50.096486
                 ],
                 [
                    107.05030639999998,
                    50.09772080000003
                 ],
                 [
                    107.0510377,
                    50.100284100000025
                 ],
                 [
                    107.0502234,
                    50.102045
                 ],
                 [
                    107.04969649999997,
                    50.105346400000016
                 ],
                 [
                    107.04992710000003,
                    50.10763249999999
                 ],
                 [
                    107.05094470000003,
                    50.108558899999984
                 ],
                 [
                    107.052142,
                    50.11056990000002
                 ],
                 [
                    107.0507833,
                    50.11208279999997
                 ],
                 [
                    107.0479708,
                    50.1128775
                 ],
                 [
                    107.04493249999996,
                    50.113590500000015
                 ],
                 [
                    107.043954,
                    50.11463840000003
                 ],
                 [
                    107.0442008,
                    50.11626570000003
                 ],
                 [
                    107.04539470000002,
                    50.11925019999996
                 ],
                 [
                    107.042182,
                    50.12426
                 ],
                 [
                    107.0393639,
                    50.12914830000003
                 ],
                 [
                    107.03807740000005,
                    50.13028769999998
                 ],
                 [
                    107.03519649999998,
                    50.13053930000002
                 ],
                 [
                    107.034502,
                    50.130952500000014
                 ],
                 [
                    107.03375469999997,
                    50.131166300000025
                 ],
                 [
                    107.0321879,
                    50.13093640000001
                 ],
                 [
                    107.03090120000003,
                    50.1310165
                 ],
                 [
                    107.0303833,
                    50.1313979
                 ],
                 [
                    107.0298884,
                    50.1323227
                 ],
                 [
                    107.02965189999996,
                    50.13410169999999
                 ],
                 [
                    107.02863419999996,
                    50.13423430000003
                 ],
                 [
                    107.027602,
                    50.1340236
                 ],
                 [
                    107.0269387,
                    50.1341212
                 ],
                 [
                    107.0263967,
                    50.1349896
                 ],
                 [
                    107.0254873,
                    50.135578300000034
                 ],
                 [
                    107.0252,
                    50.13615590000004
                 ],
                 [
                    107.02458119999999,
                    50.137312
                 ],
                 [
                    107.02330740000002,
                    50.13770670000002
                 ],
                 [
                    107.021959,
                    50.1384461
                 ],
                 [
                    107.02099039999997,
                    50.13869230000003
                 ],
                 [
                    107.019886,
                    50.13888359999996
                 ],
                 [
                    107.01954840000003,
                    50.139319
                 ],
                 [
                    107.0188717,
                    50.140161099999965
                 ],
                 [
                    107.01796429999997,
                    50.140806800000014
                 ],
                 [
                    107.01762270000005,
                    50.14221559999996
                 ],
                 [
                    107.01654089999997,
                    50.1429504
                 ],
                 [
                    107.01562759999999,
                    50.14345310000003
                 ],
                 [
                    107.01561529999995,
                    50.14422629999997
                 ],
                 [
                    107.01593380000001,
                    50.144392500000045
                 ],
                 [
                    107.0166224,
                    50.14489560000001
                 ],
                 [
                    107.01656219999995,
                    50.1455835
                 ],
                 [
                    107.01622920000003,
                    50.14613350000003
                 ],
                 [
                    107.01642980000003,
                    50.146673799999974
                 ],
                 [
                    107.0170282,
                    50.1471498
                 ],
                 [
                    107.016736,
                    50.14761309999997
                 ],
                 [
                    107.01638389999995,
                    50.14770510000004
                 ],
                 [
                    107.01541860000002,
                    50.148037
                 ],
                 [
                    107.01490289999998,
                    50.14847550000001
                 ],
                 [
                    107.01505549999999,
                    50.14893090000003
                 ],
                 [
                    107.01372590000003,
                    50.15012790000004
                 ],
                 [
                    107.0132824,
                    50.15016440000004
                 ],
                 [
                    107.01135330000001,
                    50.14979759999998
                 ],
                 [
                    107.00997459999998,
                    50.149821900000035
                 ],
                 [
                    107.00861620000002,
                    50.1503324
                 ],
                 [
                    107.00790189999998,
                    50.1513469
                 ],
                 [
                    107.00702110000003,
                    50.15370980000002
                 ],
                 [
                    107.00743210000002,
                    50.153960100000035
                 ],
                 [
                    107.0079268,
                    50.15515370000003
                 ],
                 [
                    107.00761639999999,
                    50.156247
                 ],
                 [
                    107.00745519999998,
                    50.156650599999985
                 ],
                 [
                    107.00498939999997,
                    50.157295
                 ],
                 [
                    107.00299120000001,
                    50.157416
                 ],
                 [
                    107.00065440000003,
                    50.15688439999997
                 ],
                 [
                    107.00029869999999,
                    50.15689060000003
                 ],
                 [
                    106.999999,
                    50.1571821
                 ],
                 [
                    106.99994180000004,
                    50.16116209999996
                 ],
                 [
                    107.00040459999997,
                    50.1615834
                 ],
                 [
                    107.0012568,
                    50.16174030000002
                 ],
                 [
                    107.00212590000001,
                    50.16229760000004
                 ],
                 [
                    107.0023203,
                    50.162694899999956
                 ],
                 [
                    107.00233579999997,
                    50.1630668
                 ],
                 [
                    107.00258680000005,
                    50.163749399999986
                 ],
                 [
                    107.0037145,
                    50.16410180000001
                 ],
                 [
                    107.00584589999995,
                    50.1650377
                 ],
                 [
                    107.00777670000002,
                    50.16543310000003
                 ],
                 [
                    107.00924359999998,
                    50.1653787
                 ],
                 [
                    107.01104130000004,
                    50.164717399999965
                 ],
                 [
                    107.01144409999998,
                    50.164767500000025
                 ],
                 [
                    107.01160150000003,
                    50.16533730000001
                 ],
                 [
                    107.01197530000005,
                    50.1657601
                 ],
                 [
                    107.01292869999997,
                    50.1662013
                 ],
                 [
                    107.01344910000005,
                    50.16693639999998
                 ],
                 [
                    107.0132951,
                    50.16751169999998
                 ],
                 [
                    107.01263139999998,
                    50.16760929999997
                 ],
                 [
                    107.01112219999999,
                    50.16772170000003
                 ],
                 [
                    107.01065810000004,
                    50.1683311
                 ],
                 [
                    107.01044819999998,
                    50.169708800000045
                 ],
                 [
                    107.01007529999995,
                    50.1703737
                 ],
                 [
                    107.0085396,
                    50.170916
                 ],
                 [
                    107.007622,
                    50.17133290000004
                 ],
                 [
                    107.005176,
                    50.171404500000016
                 ],
                 [
                    107.003811,
                    50.171772
                 ],
                 [
                    107.00237129999996,
                    50.1724842
                 ],
                 [
                    107.00187659999999,
                    50.1734375
                 ],
                 [
                    107.00170880000005,
                    50.174757300000046
                 ],
                 [
                    107.000291,
                    50.17921920000001
                 ],
                 [
                    106.99950610000005,
                    50.1806929
                 ],
                 [
                    106.9987336,
                    50.18139330000004
                 ],
                 [
                    106.997186,
                    50.182737100000026
                 ],
                 [
                    106.99681519999999,
                    50.18345940000002
                 ],
                 [
                    106.99726909999997,
                    50.18473949999997
                 ],
                 [
                    106.9978739,
                    50.18535879999997
                 ],
                 [
                    106.999447,
                    50.185703399999966
                 ],
                 [
                    107.001298,
                    50.18630080000001
                 ],
                 [
                    107.00235380000004,
                    50.1870552
                 ],
                 [
                    107.0035106,
                    50.188094
                 ],
                 [
                    107.0062467,
                    50.189620599999984
                 ],
                 [
                    107.00791740000003,
                    50.19016369999998
                 ],
                 [
                    107.01130280000001,
                    50.190161399999965
                 ],
                 [
                    107.0128292,
                    50.1904495
                 ],
                 [
                    107.01356529999998,
                    50.191009
                 ],
                 [
                    107.01311410000004,
                    50.191379
                 ],
                 [
                    107.0124738,
                    50.19906589999998
                 ],
                 [
                    107.00346560000001,
                    50.20173459999999
                 ],
                 [
                    106.99856240000004,
                    50.2033343
                 ],
                 [
                    106.98533659999997,
                    50.2072276
                 ],
                 [
                    106.97951490000003,
                    50.20892930000002
                 ],
                 [
                    106.92325369999998,
                    50.238271
                 ],
                 [
                    106.919065,
                    50.240450499999966
                 ],
                 [
                    106.87543969999996,
                    50.26313430000002
                 ],
                 [
                    106.84124130000002,
                    50.28319410000004
                 ],
                 [
                    106.83612430000002,
                    50.2861982
                 ],
                 [
                    106.82084060000004,
                    50.295143600000024
                 ],
                 [
                    106.81572780000005,
                    50.2981781
                 ],
                 [
                    106.7887128,
                    50.305222300000025
                 ],
                 [
                    106.75324510000002,
                    50.31255180000002
                 ],
                 [
                    106.73202930000002,
                    50.31634560000004
                 ],
                 [
                    106.7314527,
                    50.3133855
                 ],
                 [
                    106.711086,
                    50.317039
                 ],
                 [
                    106.671727,
                    50.324111900000034
                 ],
                 [
                    106.62767810000003,
                    50.33201230000002
                 ],
                 [
                    106.59161450000003,
                    50.33846439999998
                 ],
                 [
                    106.572357,
                    50.331248
                 ],
                 [
                    106.56832370000004,
                    50.334588800000034
                 ],
                 [
                    106.56254630000002,
                    50.335578
                 ],
                 [
                    106.55831270000003,
                    50.33493809999998
                 ],
                 [
                    106.55457060000003,
                    50.33566550000003
                 ],
                 [
                    106.5511814,
                    50.33970089999997
                 ],
                 [
                    106.5446409,
                    50.34320579999998
                 ],
                 [
                    106.52458209999998,
                    50.34405589999997
                 ],
                 [
                    106.51246509999999,
                    50.3429249
                 ],
                 [
                    106.5102322,
                    50.3413158
                 ],
                 [
                    106.5031035,
                    50.331952
                 ],
                 [
                    106.49727029999998,
                    50.32363989999997
                 ],
                 [
                    106.4911615,
                    50.3232797
                 ],
                 [
                    106.48469789999997,
                    50.3225353
                 ],
                 [
                    106.48321639999996,
                    50.32194470000004
                 ],
                 [
                    106.482027,
                    50.321445599999954
                 ],
                 [
                    106.47979009999997,
                    50.3209094
                 ],
                 [
                    106.47490359999996,
                    50.319738
                 ],
                 [
                    106.4722011,
                    50.31921309999998
                 ],
                 [
                    106.46871079999997,
                    50.31813790000001
                 ],
                 [
                    106.44748730000003,
                    50.31473239999997
                 ],
                 [
                    106.4289528,
                    50.31649719999997
                 ],
                 [
                    106.41062229999999,
                    50.3182396
                 ],
                 [
                    106.40666419999997,
                    50.319194600000046
                 ],
                 [
                    106.39532879999999,
                    50.3201762
                 ],
                 [
                    106.38695579999998,
                    50.319373099999986
                 ],
                 [
                    106.3488667,
                    50.3112687
                 ],
                 [
                    106.3256211,
                    50.30766039999996
                 ],
                 [
                    106.32235050000001,
                    50.3033789
                 ],
                 [
                    106.3179287,
                    50.301092199999985
                 ],
                 [
                    106.31239880000004,
                    50.303961200000046
                 ],
                 [
                    106.30542990000004,
                    50.30387920000001
                 ],
                 [
                    106.30277970000004,
                    50.303318299999965
                 ],
                 [
                    106.29868340000004,
                    50.300416
                 ],
                 [
                    106.29473610000002,
                    50.30129580000004
                 ],
                 [
                    106.2770989,
                    50.2994909
                 ],
                 [
                    106.26123040000002,
                    50.29813410000001
                 ],
                 [
                    106.2586411,
                    50.2999084
                 ],
                 [
                    106.25688620000003,
                    50.3028378
                 ],
                 [
                    106.25153009999997,
                    50.303841700000014
                 ],
                 [
                    106.24872460000002,
                    50.3074323
                 ],
                 [
                    106.24607210000002,
                    50.30774280000003
                 ],
                 [
                    106.242574,
                    50.30768590000003
                 ],
                 [
                    106.240123,
                    50.30926109999998
                 ],
                 [
                    106.2372208,
                    50.3132352
                 ],
                 [
                    106.22916559999996,
                    50.31641889999998
                 ],
                 [
                    106.20971430000002,
                    50.31920490000001
                 ],
                 [
                    106.20857149999996,
                    50.322455500000025
                 ],
                 [
                    106.20556159999997,
                    50.32520939999998
                 ],
                 [
                    106.20178289999996,
                    50.3294734
                 ],
                 [
                    106.19742660000003,
                    50.3312082
                 ],
                 [
                    106.194994,
                    50.33362729999997
                 ],
                 [
                    106.18840559999997,
                    50.3341172
                 ],
                 [
                    106.1845942,
                    50.33622250000003
                 ],
                 [
                    106.18300050000003,
                    50.3396936
                 ],
                 [
                    106.1648856,
                    50.3388048
                 ],
                 [
                    106.1504402,
                    50.338100900000015
                 ],
                 [
                    106.14872070000001,
                    50.33785730000002
                 ],
                 [
                    106.1266385,
                    50.33672390000003
                 ],
                 [
                    106.117644,
                    50.3344392
                 ],
                 [
                    106.10568849999996,
                    50.33603849999999
                 ],
                 [
                    106.0955707,
                    50.334653599999974
                 ],
                 [
                    106.09255580000004,
                    50.334306400000024
                 ],
                 [
                    106.09066239999996,
                    50.33488759999997
                 ],
                 [
                    106.0886093,
                    50.334906699999955
                 ],
                 [
                    106.0854201,
                    50.33334050000004
                 ],
                 [
                    106.08410130000004,
                    50.333399799999974
                 ],
                 [
                    106.08294940000003,
                    50.33434940000003
                 ],
                 [
                    106.08077330000002,
                    50.33540240000003
                 ],
                 [
                    106.08029939999997,
                    50.33714360000003
                 ],
                 [
                    106.08041649999998,
                    50.342306
                 ],
                 [
                    106.07936420000004,
                    50.34442810000004
                 ],
                 [
                    106.07806449999998,
                    50.345332
                 ],
                 [
                    106.07316839999997,
                    50.346175400000014
                 ],
                 [
                    106.0701889,
                    50.34742329999997
                 ],
                 [
                    106.06780859999996,
                    50.349229
                 ],
                 [
                    106.0670533,
                    50.351536
                 ],
                 [
                    106.068679,
                    50.3553233
                 ],
                 [
                    106.0688139,
                    50.36133050000002
                 ],
                 [
                    106.07069930000004,
                    50.36685230000002
                 ],
                 [
                    106.07317680000004,
                    50.36931720000004
                 ],
                 [
                    106.07454640000003,
                    50.37146390000002
                 ],
                 [
                    106.07413979999995,
                    50.3729698
                 ],
                 [
                    106.07092320000001,
                    50.3768017
                 ],
                 [
                    106.07015319999998,
                    50.3800556
                 ],
                 [
                    106.07276130000004,
                    50.38209259999997
                 ],
                 [
                    106.07245909999999,
                    50.3833249
                 ],
                 [
                    106.075839,
                    50.3866089
                 ],
                 [
                    106.07466550000002,
                    50.3895535
                 ],
                 [
                    106.0656235,
                    50.397344
                 ],
                 [
                    106.0573709,
                    50.4045045
                 ],
                 [
                    106.05037650000003,
                    50.40296030000004
                 ],
                 [
                    106.04546860000002,
                    50.40199319999996
                 ],
                 [
                    106.03275519999998,
                    50.40591310000002
                 ],
                 [
                    106.01827490000002,
                    50.4113801
                 ],
                 [
                    106.008725,
                    50.4115794
                 ],
                 [
                    105.9887557,
                    50.416039
                 ],
                 [
                    105.9773695,
                    50.4157973
                 ],
                 [
                    105.9597157,
                    50.41555419999997
                 ],
                 [
                    105.9325627,
                    50.41517519999996
                 ],
                 [
                    105.9238384,
                    50.41599630000004
                 ],
                 [
                    105.90989710000004,
                    50.4126595
                 ],
                 [
                    105.89905229999997,
                    50.41263149999999
                 ],
                 [
                    105.8828907,
                    50.416840399999984
                 ],
                 [
                    105.8601079,
                    50.426925299999965
                 ],
                 [
                    105.84422650000003,
                    50.4403964
                 ],
                 [
                    105.81971520000002,
                    50.44022969999997
                 ],
                 [
                    105.80344270000002,
                    50.43328720000002
                 ],
                 [
                    105.79217450000002,
                    50.4391199
                 ],
                 [
                    105.77656020000002,
                    50.43849590000004
                 ],
                 [
                    105.76524730000004,
                    50.43468270000002
                 ],
                 [
                    105.7591785,
                    50.435080200000044
                 ],
                 [
                    105.72398179999996,
                    50.42755
                 ],
                 [
                    105.70511030000003,
                    50.426373
                 ],
                 [
                    105.6758025,
                    50.42988240000003
                 ],
                 [
                    105.64460090000003,
                    50.43548919999996
                 ],
                 [
                    105.628757,
                    50.427396
                 ],
                 [
                    105.60888890000001,
                    50.42876219999999
                 ],
                 [
                    105.60275589999998,
                    50.43077980000003
                 ],
                 [
                    105.60040169999996,
                    50.43425589999997
                 ],
                 [
                    105.5918522,
                    50.43626859999996
                 ],
                 [
                    105.578317,
                    50.44032439999999
                 ],
                 [
                    105.57267750000004,
                    50.448469499999966
                 ],
                 [
                    105.52513,
                    50.45802339999998
                 ],
                 [
                    105.52140409999996,
                    50.4575287
                 ],
                 [
                    105.47872929999998,
                    50.44438719999997
                 ],
                 [
                    105.44352949999995,
                    50.44210939999998
                 ],
                 [
                    105.42661280000004,
                    50.44417459999997
                 ],
                 [
                    105.40993330000002,
                    50.451050800000026
                 ],
                 [
                    105.4022289,
                    50.4590498
                 ],
                 [
                    105.38114990000003,
                    50.46286769999997
                 ],
                 [
                    105.37188629999997,
                    50.468350800000046
                 ],
                 [
                    105.36030479999997,
                    50.473409700000026
                 ],
                 [
                    105.35871289999997,
                    50.476888199999976
                 ],
                 [
                    105.3547168,
                    50.47949889999998
                 ],
                 [
                    105.34752669999999,
                    50.4761241
                 ],
                 [
                    105.33553140000004,
                    50.47531429999996
                 ],
                 [
                    105.32721259999998,
                    50.47313280000003
                 ],
                 [
                    105.298419,
                    50.47214310000003
                 ],
                 [
                    105.27759,
                    50.46612620000001
                 ],
                 [
                    105.26823289999999,
                    50.46535760000001
                 ],
                 [
                    105.26669539999996,
                    50.46427409999998
                 ],
                 [
                    105.26358259999998,
                    50.4599161
                 ],
                 [
                    105.26963340000005,
                    50.4540604
                 ],
                 [
                    105.27051870000003,
                    50.45126330000002
                 ],
                 [
                    105.2696178,
                    50.44797950000002
                 ],
                 [
                    105.26399850000001,
                    50.443477799999954
                 ],
                 [
                    105.25855119999997,
                    50.44068220000002
                 ],
                 [
                    105.25296409999999,
                    50.44046
                 ],
                 [
                    105.237999,
                    50.43162
                 ],
                 [
                    105.23363009999998,
                    50.4304562
                 ],
                 [
                    105.23158819999996,
                    50.42806559999999
                 ],
                 [
                    105.22179839999997,
                    50.424470200000044
                 ],
                 [
                    105.21581819999997,
                    50.4251974
                 ],
                 [
                    105.20803510000002,
                    50.419144799999955
                 ],
                 [
                    105.20074560000003,
                    50.41530859999998
                 ],
                 [
                    105.19808340000003,
                    50.41153930000004
                 ],
                 [
                    105.19411939999998,
                    50.41170540000002
                 ],
                 [
                    105.18632590000004,
                    50.40988749999997
                 ],
                 [
                    105.17421090000003,
                    50.4092599
                 ],
                 [
                    105.1712736,
                    50.411459600000015
                 ],
                 [
                    105.16767909999996,
                    50.41277910000004
                 ],
                 [
                    105.16322639999996,
                    50.41247540000004
                 ],
                 [
                    105.16194320000001,
                    50.411003600000036
                 ],
                 [
                    105.1595715,
                    50.404717
                 ],
                 [
                    105.15629859999999,
                    50.402941
                 ],
                 [
                    105.15096520000003,
                    50.400551
                 ],
                 [
                    105.1478785,
                    50.39985089999998
                 ],
                 [
                    105.14523129999998,
                    50.39872750000003
                 ],
                 [
                    105.141634,
                    50.399060499999955
                 ],
                 [
                    105.1388392,
                    50.39760890000002
                 ],
                 [
                    105.1361909,
                    50.394843
                 ],
                 [
                    105.12016739999999,
                    50.389931700000034
                 ],
                 [
                    105.11614059999997,
                    50.3908383
                 ],
                 [
                    105.11290309999997,
                    50.3918636
                 ],
                 [
                    105.1045296,
                    50.39794859999997
                 ],
                 [
                    105.097789,
                    50.397369
                 ],
                 [
                    105.09440920000003,
                    50.39643309999999
                 ],
                 [
                    105.0929339,
                    50.395206799999954
                 ],
                 [
                    105.08804160000003,
                    50.387879400000045
                 ],
                 [
                    105.08066409999998,
                    50.3886045
                 ],
                 [
                    105.07478970000003,
                    50.388139
                 ],
                 [
                    105.07150060000002,
                    50.38750140000003
                 ],
                 [
                    105.06413130000001,
                    50.388625799999986
                 ],
                 [
                    105.0475336,
                    50.3879046
                 ],
                 [
                    105.02706679999999,
                    50.387041
                 ],
                 [
                    105.01495369999996,
                    50.391823399999964
                 ],
                 [
                    105.0081195,
                    50.38692170000003
                 ],
                 [
                    105.00416,
                    50.38877320000001
                 ],
                 [
                    104.99756670000002,
                    50.39103
                 ],
                 [
                    104.98740760000004,
                    50.39139380000004
                 ],
                 [
                    104.98330690000003,
                    50.3931375
                 ],
                 [
                    104.97699730000004,
                    50.39428870000002
                 ],
                 [
                    104.97258239999996,
                    50.400458100000044
                 ],
                 [
                    104.9680572,
                    50.402783100000036
                 ],
                 [
                    104.9652361,
                    50.40388259999997
                 ],
                 [
                    104.96016870000003,
                    50.403458499999964
                 ],
                 [
                    104.95561429999997,
                    50.40448960000001
                 ],
                 [
                    104.94797569999997,
                    50.40476819999997
                 ],
                 [
                    104.93850159999998,
                    50.40452869999996
                 ],
                 [
                    104.933262,
                    50.40321049999996
                 ],
                 [
                    104.92801560000004,
                    50.4058711
                 ],
                 [
                    104.92491020000004,
                    50.4071495
                 ],
                 [
                    104.91675779999998,
                    50.4069091
                 ],
                 [
                    104.91003,
                    50.4036049
                 ],
                 [
                    104.90802020000001,
                    50.4054943
                 ],
                 [
                    104.90103550000003,
                    50.40896219999997
                 ],
                 [
                    104.8983944,
                    50.408974
                 ],
                 [
                    104.88980009999997,
                    50.4077785
                 ],
                 [
                    104.88875180000002,
                    50.3996481
                 ],
                 [
                    104.88622209999997,
                    50.39899739999998
                 ],
                 [
                    104.88269670000004,
                    50.39918180000004
                 ],
                 [
                    104.88011509999995,
                    50.398282
                 ],
                 [
                    104.87904,
                    50.39321679999997
                 ],
                 [
                    104.87618319999997,
                    50.39058520000001
                 ],
                 [
                    104.8738844,
                    50.387698800000024
                 ],
                 [
                    104.87479930000003,
                    50.38635920000003
                 ],
                 [
                    104.876281,
                    50.381244200000026
                 ],
                 [
                    104.8756275,
                    50.3784743
                 ],
                 [
                    104.876517,
                    50.37518939999998
                 ],
                 [
                    104.87340749999998,
                    50.370927599999966
                 ],
                 [
                    104.86860129999997,
                    50.3710031
                 ],
                 [
                    104.8592854,
                    50.36930229999996
                 ],
                 [
                    104.85190040000003,
                    50.367094
                 ],
                 [
                    104.85152680000003,
                    50.36258039999996
                 ],
                 [
                    104.8499066,
                    50.359299
                 ],
                 [
                    104.84749969999999,
                    50.35985260000004
                 ],
                 [
                    104.84273579999999,
                    50.358156500000035
                 ],
                 [
                    104.8350244,
                    50.360258200000025
                 ],
                 [
                    104.8322761,
                    50.36004169999997
                 ],
                 [
                    104.8275638,
                    50.353675800000026
                 ],
                 [
                    104.82028529999997,
                    50.350501800000046
                 ],
                 [
                    104.82061460000003,
                    50.3482201
                 ],
                 [
                    104.81709809999995,
                    50.3469942
                 ],
                 [
                    104.81168489999997,
                    50.340956799999965
                 ],
                 [
                    104.80539009999997,
                    50.339887500000046
                 ],
                 [
                    104.79701839999998,
                    50.342314
                 ],
                 [
                    104.77427380000005,
                    50.34368029999998
                 ],
                 [
                    104.7695168,
                    50.345056
                 ],
                 [
                    104.7688534,
                    50.34212069999999
                 ],
                 [
                    104.76754009999996,
                    50.340663
                 ],
                 [
                    104.76332,
                    50.33879350000003
                 ],
                 [
                    104.76196679999997,
                    50.34046370000002
                 ],
                 [
                    104.75057759999999,
                    50.344383
                 ],
                 [
                    104.74785289999996,
                    50.34639560000002
                 ],
                 [
                    104.74813009999997,
                    50.34938080000002
                 ],
                 [
                    104.74328689999999,
                    50.349718299999964
                 ],
                 [
                    104.73931530000002,
                    50.35158690000003
                 ],
                 [
                    104.73402980000003,
                    50.35218519999997
                 ],
                 [
                    104.7285077,
                    50.35564589999996
                 ],
                 [
                    104.72337910000003,
                    50.357456
                 ],
                 [
                    104.71691790000003,
                    50.35435679999998
                 ],
                 [
                    104.71370060000001,
                    50.35340420000003
                 ],
                 [
                    104.70973569999995,
                    50.35391090000003
                 ],
                 [
                    104.69916019999997,
                    50.3672149
                 ],
                 [
                    104.69638280000002,
                    50.36695210000001
                 ],
                 [
                    104.69278819999997,
                    50.3652269
                 ],
                 [
                    104.68787990000001,
                    50.3639463
                 ],
                 [
                    104.6793939,
                    50.36002730000002
                 ],
                 [
                    104.6731465,
                    50.35919550000003
                 ],
                 [
                    104.67134909999996,
                    50.356155700000045
                 ],
                 [
                    104.6679972,
                    50.352860399999976
                 ],
                 [
                    104.6675249,
                    50.34694459999997
                 ],
                 [
                    104.668356,
                    50.34347360000002
                 ],
                 [
                    104.6679497,
                    50.341674700000034
                 ],
                 [
                    104.6661272,
                    50.33703649999996
                 ],
                 [
                    104.66030119999996,
                    50.332694100000026
                 ],
                 [
                    104.6595553,
                    50.32939259999998
                 ],
                 [
                    104.65268590000004,
                    50.328511900000024
                 ],
                 [
                    104.65064109999996,
                    50.32719050000003
                 ],
                 [
                    104.65017060000002,
                    50.3241071
                 ],
                 [
                    104.6468911,
                    50.3238452
                 ],
                 [
                    104.641455,
                    50.322452100000014
                 ],
                 [
                    104.63693459999999,
                    50.323280199999964
                 ],
                 [
                    104.6311143,
                    50.31902470000002
                 ],
                 [
                    104.62267320000004,
                    50.31654779999999
                 ],
                 [
                    104.6133495,
                    50.3182329
                 ],
                 [
                    104.6088466,
                    50.32041920000003
                 ],
                 [
                    104.6068436,
                    50.3193121
                 ],
                 [
                    104.60604459999996,
                    50.317719
                 ],
                 [
                    104.60414320000002,
                    50.3165809
                 ],
                 [
                    104.59626229999998,
                    50.316461899999986
                 ],
                 [
                    104.59140709999997,
                    50.314426200000035
                 ],
                 [
                    104.58353180000003,
                    50.3123346
                 ],
                 [
                    104.5806902,
                    50.3109316
                 ],
                 [
                    104.5778453,
                    50.308566300000045
                 ],
                 [
                    104.5718416,
                    50.307608400000014
                 ],
                 [
                    104.56887910000002,
                    50.3071263
                 ],
                 [
                    104.56637720000005,
                    50.303905399999955
                 ],
                 [
                    104.5636608,
                    50.3032528
                 ],
                 [
                    104.56120710000002,
                    50.30114430000003
                 ],
                 [
                    104.55916330000002,
                    50.30418430000003
                 ],
                 [
                    104.5568456,
                    50.3068975
                 ],
                 [
                    104.53374310000004,
                    50.314645599999984
                 ],
                 [
                    104.5294443,
                    50.31526259999996
                 ],
                 [
                    104.52526070000005,
                    50.31390220000002
                 ],
                 [
                    104.517244,
                    50.31717009999996
                 ],
                 [
                    104.5115991,
                    50.31385539999997
                 ],
                 [
                    104.50795369999997,
                    50.3147453
                 ],
                 [
                    104.50691210000004,
                    50.314156700000034
                 ],
                 [
                    104.50206530000003,
                    50.311417
                 ],
                 [
                    104.4973483,
                    50.31029550000003
                 ],
                 [
                    104.49462479999998,
                    50.3084519
                 ],
                 [
                    104.49002149999995,
                    50.307602499999966
                 ],
                 [
                    104.48283660000003,
                    50.309528499999985
                 ],
                 [
                    104.4791823,
                    50.311744899999965
                 ],
                 [
                    104.47616859999997,
                    50.3121596
                 ],
                 [
                    104.47187030000003,
                    50.3135443
                 ],
                 [
                    104.46419789999999,
                    50.314766200000015
                 ],
                 [
                    104.45577390000003,
                    50.314406
                 ],
                 [
                    104.45175820000004,
                    50.31093869999996
                 ],
                 [
                    104.4481412,
                    50.30863590000003
                 ],
                 [
                    104.4471773,
                    50.305805
                 ],
                 [
                    104.4422673,
                    50.3070469
                 ],
                 [
                    104.435579,
                    50.3071981
                 ],
                 [
                    104.432054,
                    50.306416200000044
                 ],
                 [
                    104.42626780000002,
                    50.307419
                 ],
                 [
                    104.42352510000002,
                    50.3086989
                 ],
                 [
                    104.41971789999997,
                    50.30757879999996
                 ],
                 [
                    104.4154343,
                    50.307857
                 ],
                 [
                    104.41198880000003,
                    50.3098872
                 ],
                 [
                    104.40266089999997,
                    50.311897
                 ],
                 [
                    104.39673339999999,
                    50.312436199999965
                 ],
                 [
                    104.391917,
                    50.3116272
                 ],
                 [
                    104.38745669999999,
                    50.30560880000003
                 ],
                 [
                    104.38742170000002,
                    50.3008981
                 ],
                 [
                    104.38445609999997,
                    50.29839750000004
                 ],
                 [
                    104.38155770000004,
                    50.2964625
                 ],
                 [
                    104.379362,
                    50.28904870000004
                 ],
                 [
                    104.37365380000004,
                    50.2775141
                 ],
                 [
                    104.371766,
                    50.2735294
                 ],
                 [
                    104.36808379999997,
                    50.271859
                 ],
                 [
                    104.36630079999996,
                    50.26955519999998
                 ],
                 [
                    104.365553,
                    50.2647796
                 ],
                 [
                    104.36327069999999,
                    50.26317850000004
                 ],
                 [
                    104.34937230000004,
                    50.25342739999996
                 ],
                 [
                    104.3475019,
                    50.251802399999974
                 ],
                 [
                    104.34067280000002,
                    50.24918160000002
                 ],
                 [
                    104.34132,
                    50.246904
                 ],
                 [
                    104.3401541,
                    50.24497030000001
                 ],
                 [
                    104.33693429999995,
                    50.2432697
                 ],
                 [
                    104.32596970000002,
                    50.24180170000003
                 ],
                 [
                    104.32252190000003,
                    50.243829
                 ],
                 [
                    104.31474819999995,
                    50.2437529
                 ],
                 [
                    104.30361220000005,
                    50.24473469999997
                 ],
                 [
                    104.3001131,
                    50.243206800000024
                 ],
                 [
                    104.29545580000001,
                    50.242730200000025
                 ],
                 [
                    104.29317040000002,
                    50.24178829999999
                 ],
                 [
                    104.29200289999997,
                    50.23688720000001
                 ],
                 [
                    104.28845959999998,
                    50.23463259999998
                 ],
                 [
                    104.28326360000003,
                    50.228146699999975
                 ],
                 [
                    104.27354829999999,
                    50.22134490000003
                 ],
                 [
                    104.27552730000004,
                    50.219473
                 ],
                 [
                    104.2758112,
                    50.21647719999997
                 ],
                 [
                    104.2798439,
                    50.20824350000004
                 ],
                 [
                    104.28004589999998,
                    50.20432950000002
                 ],
                 [
                    104.2783297,
                    50.20119889999999
                 ],
                 [
                    104.27499920000004,
                    50.1996631
                 ],
                 [
                    104.27202760000002,
                    50.19944850000002
                 ],
                 [
                    104.26829789999996,
                    50.199667899999966
                 ],
                 [
                    104.26307170000001,
                    50.1986561
                 ],
                 [
                    104.254327,
                    50.19475210000002
                 ],
                 [
                    104.2512034,
                    50.192738
                 ],
                 [
                    104.2437818,
                    50.185991199999975
                 ],
                 [
                    104.240055,
                    50.185106400000045
                 ],
                 [
                    104.2360056,
                    50.1866743
                 ],
                 [
                    104.22941319999997,
                    50.18779310000003
                 ],
                 [
                    104.22396639999997,
                    50.18772240000004
                 ],
                 [
                    104.21735490000005,
                    50.18226790000002
                 ],
                 [
                    104.20881909999997,
                    50.1800423
                 ],
                 [
                    104.2062807,
                    50.177879800000035
                 ],
                 [
                    104.20137640000003,
                    50.174909799999966
                 ],
                 [
                    104.1961751,
                    50.1754856
                 ],
                 [
                    104.194171,
                    50.1728717
                 ],
                 [
                    104.18881649999999,
                    50.169871
                 ],
                 [
                    104.18733350000002,
                    50.16884760000002
                 ],
                 [
                    104.18779080000003,
                    50.166309
                 ],
                 [
                    104.18103689999997,
                    50.163878700000026
                 ],
                 [
                    104.16989789999998,
                    50.1554185
                 ],
                 [
                    104.15545080000003,
                    50.1485237
                 ],
                 [
                    104.1493156,
                    50.14820359999998
                 ],
                 [
                    104.14598339999998,
                    50.14697280000002
                 ],
                 [
                    104.14450270000003,
                    50.14315189999998
                 ],
                 [
                    104.13919250000004,
                    50.139350300000025
                 ],
                 [
                    104.13542609999998,
                    50.1388069
                 ],
                 [
                    104.1321899,
                    50.13969580000001
                 ],
                 [
                    104.12005150000002,
                    50.144988
                 ],
                 [
                    104.11494679999998,
                    50.14426709999997
                 ],
                 [
                    104.108238,
                    50.14034029999996
                 ],
                 [
                    104.09880690000003,
                    50.14326520000003
                 ],
                 [
                    104.09412550000002,
                    50.1443341
                 ],
                 [
                    104.08898519999997,
                    50.14162849999997
                 ],
                 [
                    104.08593779999998,
                    50.14169639999999
                 ],
                 [
                    104.08267060000001,
                    50.143001699999985
                 ],
                 [
                    104.07976429999997,
                    50.14593
                 ],
                 [
                    104.0744061,
                    50.14811830000003
                 ],
                 [
                    104.06614310000005,
                    50.1487577
                 ],
                 [
                    104.0565662,
                    50.15108359999998
                 ],
                 [
                    104.05170490000003,
                    50.14708079999998
                 ],
                 [
                    104.04805119999996,
                    50.146256399999984
                 ],
                 [
                    104.045708,
                    50.1470315
                 ],
                 [
                    104.0349031,
                    50.14700349999998
                 ],
                 [
                    104.0284933,
                    50.148540699999984
                 ],
                 [
                    104.019975,
                    50.14932060000003
                 ],
                 [
                    104.01787889999999,
                    50.1484819
                 ],
                 [
                    104.01493370000001,
                    50.148181800000046
                 ],
                 [
                    104.01205409999997,
                    50.14931960000004
                 ],
                 [
                    104.01153029999998,
                    50.1516399
                 ],
                 [
                    104.010159,
                    50.15308979999997
                 ],
                 [
                    104.00361059999999,
                    50.15016520000002
                 ],
                 [
                    103.99855659999997,
                    50.15029740000003
                 ],
                 [
                    103.9945732,
                    50.15207549999998
                 ],
                 [
                    103.9910707,
                    50.153851799999956
                 ],
                 [
                    103.98964530000002,
                    50.1577529
                 ],
                 [
                    103.98623080000003,
                    50.1571113
                 ],
                 [
                    103.97660449999998,
                    50.15622620000004
                 ],
                 [
                    103.9727447,
                    50.16512009999998
                 ],
                 [
                    103.96088530000002,
                    50.164442699999974
                 ],
                 [
                    103.95273749999998,
                    50.1666969
                 ],
                 [
                    103.94685970000002,
                    50.1724123
                 ],
                 [
                    103.945599,
                    50.17916289999997
                 ],
                 [
                    103.94364079999997,
                    50.1813648
                 ],
                 [
                    103.93902060000005,
                    50.1831392
                 ],
                 [
                    103.93644569999998,
                    50.18145469999997
                 ],
                 [
                    103.93401519999998,
                    50.17765239999996
                 ],
                 [
                    103.93120560000003,
                    50.1773447
                 ],
                 [
                    103.92196029999997,
                    50.17775869999996
                 ],
                 [
                    103.91602539999998,
                    50.17869689999996
                 ],
                 [
                    103.91287640000003,
                    50.1830178
                 ],
                 [
                    103.9064933,
                    50.183515699999965
                 ],
                 [
                    103.8999302,
                    50.1818823
                 ],
                 [
                    103.89033639999997,
                    50.176147399999955
                 ],
                 [
                    103.88331990000002,
                    50.17862849999999
                 ],
                 [
                    103.8817052,
                    50.18108920000002
                 ],
                 [
                    103.88238510000004,
                    50.18464990000002
                 ],
                 [
                    103.88322340000003,
                    50.18788130000004
                 ],
                 [
                    103.88136760000005,
                    50.18975879999996
                 ],
                 [
                    103.8796549,
                    50.1902059
                 ],
                 [
                    103.86114289999998,
                    50.18951790000001
                 ],
                 [
                    103.8533854,
                    50.193099200000034
                 ],
                 [
                    103.85018340000002,
                    50.1964942
                 ],
                 [
                    103.84663060000004,
                    50.197560600000045
                 ],
                 [
                    103.83614329999997,
                    50.1943193
                 ],
                 [
                    103.83596680000002,
                    50.1894973
                 ],
                 [
                    103.83705679999997,
                    50.18431759999997
                 ],
                 [
                    103.83182389999999,
                    50.17993159999998
                 ],
                 [
                    103.82465560000001,
                    50.17592309999996
                 ],
                 [
                    103.8220995,
                    50.16826170000004
                 ],
                 [
                    103.81957549999997,
                    50.16537879999998
                 ],
                 [
                    103.8154603,
                    50.1636465
                 ],
                 [
                    103.8128721,
                    50.1611476
                 ],
                 [
                    103.80554090000004,
                    50.15737790000003
                 ],
                 [
                    103.80041170000001,
                    50.151682
                 ],
                 [
                    103.79746469999998,
                    50.15429439999998
                 ],
                 [
                    103.78462489999995,
                    50.16207830000001
                 ],
                 [
                    103.77812070000004,
                    50.1645139
                 ],
                 [
                    103.771776,
                    50.1630571
                 ],
                 [
                    103.7641128,
                    50.164720500000044
                 ],
                 [
                    103.75842190000004,
                    50.163837
                 ],
                 [
                    103.75150950000001,
                    50.16381459999997
                 ],
                 [
                    103.74947369999997,
                    50.161911
                 ],
                 [
                    103.74877790000004,
                    50.15945930000003
                 ],
                 [
                    103.74630770000002,
                    50.159082500000025
                 ],
                 [
                    103.74043930000003,
                    50.1596779
                 ],
                 [
                    103.73782870000004,
                    50.158804699999955
                 ],
                 [
                    103.73583989999997,
                    50.156644900000046
                 ],
                 [
                    103.73580840000004,
                    50.15347360000002
                 ],
                 [
                    103.7324686,
                    50.1507
                 ],
                 [
                    103.7316375,
                    50.14626049999997
                 ],
                 [
                    103.72986609999998,
                    50.143193500000045
                 ],
                 [
                    103.73227790000004,
                    50.137331100000026
                 ],
                 [
                    103.7284623,
                    50.13465380000002
                 ],
                 [
                    103.72541720000002,
                    50.12794060000003
                 ],
                 [
                    103.71754680000004,
                    50.1279187
                 ],
                 [
                    103.7141403,
                    50.1272102
                 ],
                 [
                    103.71023259999997,
                    50.12446350000001
                 ],
                 [
                    103.71023340000004,
                    50.12650010000004
                 ],
                 [
                    103.70971580000003,
                    50.1274819
                 ],
                 [
                    103.702108,
                    50.1311241
                 ],
                 [
                    103.6967256,
                    50.13563539999996
                 ],
                 [
                    103.6913712,
                    50.137087700000045
                 ],
                 [
                    103.68687160000002,
                    50.141048500000025
                 ],
                 [
                    103.6841803,
                    50.14293670000003
                 ],
                 [
                    103.6725138,
                    50.1438831
                 ],
                 [
                    103.66407260000003,
                    50.14278110000003
                 ],
                 [
                    103.65014990000002,
                    50.142319600000036
                 ],
                 [
                    103.64753359999999,
                    50.14354340000004
                 ],
                 [
                    103.64552929999998,
                    50.145354200000035
                 ],
                 [
                    103.64486650000003,
                    50.1480364
                 ],
                 [
                    103.642691,
                    50.14801110000004
                 ],
                 [
                    103.63888789999999,
                    50.14946530000002
                 ],
                 [
                    103.63530779999996,
                    50.1497914
                 ],
                 [
                    103.61883520000002,
                    50.14660839999998
                 ],
                 [
                    103.60845840000005,
                    50.14405530000003
                 ],
                 [
                    103.60990210000003,
                    50.149255399999966
                 ],
                 [
                    103.6101291,
                    50.15418
                 ],
                 [
                    103.6095752,
                    50.15676859999998
                 ],
                 [
                    103.60537239999996,
                    50.16109940000002
                 ],
                 [
                    103.6023371,
                    50.16620160000002
                 ],
                 [
                    103.5946147,
                    50.16683070000003
                 ],
                 [
                    103.59155120000001,
                    50.16734480000002
                 ],
                 [
                    103.5874966,
                    50.1670847
                 ],
                 [
                    103.58342,
                    50.16850190000002
                 ],
                 [
                    103.57993540000001,
                    50.168766300000044
                 ],
                 [
                    103.5752377,
                    50.1681236
                 ],
                 [
                    103.57155649999997,
                    50.1671084
                 ],
                 [
                    103.56628039999995,
                    50.1636841
                 ],
                 [
                    103.56245660000003,
                    50.16259939999996
                 ],
                 [
                    103.55724949999997,
                    50.162678800000045
                 ],
                 [
                    103.546992,
                    50.1713966
                 ],
                 [
                    103.5415826,
                    50.1710414
                 ],
                 [
                    103.53927940000001,
                    50.177097400000044
                 ],
                 [
                    103.53502279999998,
                    50.178647799999986
                 ],
                 [
                    103.53018,
                    50.181221
                 ],
                 [
                    103.5297203,
                    50.18412009999997
                 ],
                 [
                    103.52703490000005,
                    50.18821810000002
                 ],
                 [
                    103.5231765,
                    50.19283180000002
                 ],
                 [
                    103.52313309999997,
                    50.19570369999997
                 ],
                 [
                    103.52056390000003,
                    50.195580299999975
                 ],
                 [
                    103.51824090000002,
                    50.196376
                 ],
                 [
                    103.51419310000003,
                    50.19907180000003
                 ],
                 [
                    103.507886,
                    50.19673160000002
                 ],
                 [
                    103.4999433,
                    50.19782880000003
                 ],
                 [
                    103.49349609999996,
                    50.196894700000016
                 ],
                 [
                    103.49043149999997,
                    50.1969171
                 ],
                 [
                    103.483396,
                    50.1981704
                 ],
                 [
                    103.4803016,
                    50.19953670000003
                 ],
                 [
                    103.47676969999998,
                    50.20407540000004
                 ],
                 [
                    103.47096779999998,
                    50.20522969999998
                 ],
                 [
                    103.46411120000003,
                    50.20638860000004
                 ],
                 [
                    103.45768130000005,
                    50.20624480000004
                 ],
                 [
                    103.45207469999995,
                    50.20470090000002
                 ],
                 [
                    103.4427138,
                    50.204859099999965
                 ],
                 [
                    103.44145949999998,
                    50.20673740000003
                 ],
                 [
                    103.43648629999998,
                    50.20898119999997
                 ],
                 [
                    103.4345318,
                    50.210069200000014
                 ],
                 [
                    103.42807589999998,
                    50.2106873
                 ],
                 [
                    103.42475439999997,
                    50.211652
                 ],
                 [
                    103.42209009999996,
                    50.210089
                 ],
                 [
                    103.41831149999996,
                    50.209061200000015
                 ],
                 [
                    103.4154394,
                    50.20942039999998
                 ],
                 [
                    103.41140269999997,
                    50.21178079999997
                 ],
                 [
                    103.40937450000001,
                    50.21164840000003
                 ],
                 [
                    103.40392780000002,
                    50.2101228
                 ],
                 [
                    103.39818840000004,
                    50.21068780000004
                 ],
                 [
                    103.39587480000002,
                    50.210045600000015
                 ],
                 [
                    103.39005729999998,
                    50.207339500000025
                 ],
                 [
                    103.3821805,
                    50.2076913
                 ],
                 [
                    103.37908129999998,
                    50.208660100000046
                 ],
                 [
                    103.37270709999999,
                    50.2073489
                 ],
                 [
                    103.37288680000002,
                    50.203562300000044
                 ],
                 [
                    103.37186950000005,
                    50.201607500000044
                 ],
                 [
                    103.36871230000003,
                    50.2002662
                 ],
                 [
                    103.36114389999997,
                    50.20000980000001
                 ],
                 [
                    103.3533649,
                    50.2030196
                 ],
                 [
                    103.35084350000002,
                    50.2024797
                 ],
                 [
                    103.34562279999997,
                    50.19958180000003
                 ],
                 [
                    103.34123820000002,
                    50.19958209999997
                 ],
                 [
                    103.33942980000005,
                    50.19856249999999
                 ],
                 [
                    103.32085690000004,
                    50.189855
                 ],
                 [
                    103.31829750000001,
                    50.18972339999996
                 ],
                 [
                    103.31651340000002,
                    50.19006870000002
                 ],
                 [
                    103.31401790000004,
                    50.189239
                 ],
                 [
                    103.3081154,
                    50.18979490000002
                 ],
                 [
                    103.2998941,
                    50.18848860000002
                 ],
                 [
                    103.29408050000002,
                    50.185899799999966
                 ],
                 [
                    103.290529,
                    50.186885
                 ],
                 [
                    103.28783930000002,
                    50.188499400000026
                 ],
                 [
                    103.28159019999997,
                    50.1899986
                 ],
                 [
                    103.27325870000001,
                    50.189817
                 ],
                 [
                    103.2562904,
                    50.1928927
                 ],
                 [
                    103.257609,
                    50.201904
                 ],
                 [
                    103.25547419999997,
                    50.2041951
                 ],
                 [
                    103.25544869999999,
                    50.206033
                 ],
                 [
                    103.25630719999997,
                    50.209982599999954
                 ],
                 [
                    103.26080919999997,
                    50.21197360000002
                 ],
                 [
                    103.26374949999997,
                    50.2148211
                 ],
                 [
                    103.27199120000004,
                    50.220406800000035
                 ],
                 [
                    103.27624520000003,
                    50.22255660000001
                 ],
                 [
                    103.27601469999996,
                    50.2262201
                 ],
                 [
                    103.27309430000001,
                    50.227828
                 ],
                 [
                    103.27244679999998,
                    50.22986590000002
                 ],
                 [
                    103.27467140000002,
                    50.233122
                 ],
                 [
                    103.27038559999997,
                    50.235305699999984
                 ],
                 [
                    103.26711630000001,
                    50.2398724
                 ],
                 [
                    103.26284879999997,
                    50.24258769999996
                 ],
                 [
                    103.25645080000004,
                    50.2444839
                 ],
                 [
                    103.25187110000002,
                    50.24704479999997
                 ],
                 [
                    103.24983969999997,
                    50.24940170000003
                 ],
                 [
                    103.2492528,
                    50.2558068
                 ],
                 [
                    103.25048570000004,
                    50.2566923
                 ],
                 [
                    103.25808039999995,
                    50.2569754
                 ],
                 [
                    103.268621,
                    50.2641665
                 ],
                 [
                    103.2661822,
                    50.266321300000016
                 ],
                 [
                    103.267671,
                    50.2704378
                 ],
                 [
                    103.26853450000003,
                    50.272375199999985
                 ],
                 [
                    103.2749757,
                    50.2742399
                 ],
                 [
                    103.2769372,
                    50.276194299999965
                 ],
                 [
                    103.27719329999996,
                    50.27830640000001
                 ],
                 [
                    103.27495490000004,
                    50.28074909999997
                 ],
                 [
                    103.27502240000003,
                    50.282797399999986
                 ],
                 [
                    103.27642470000005,
                    50.28489550000003
                 ],
                 [
                    103.26572510000001,
                    50.29048260000002
                 ],
                 [
                    103.26370009999997,
                    50.29583380000002
                 ],
                 [
                    103.26496249999997,
                    50.299121700000015
                 ],
                 [
                    103.2644893,
                    50.300458300000024
                 ],
                 [
                    103.25855240000001,
                    50.30324520000004
                 ],
                 [
                    103.25321129999996,
                    50.302591100000015
                 ],
                 [
                    103.24866380000003,
                    50.302650300000025
                 ],
                 [
                    103.24550140000002,
                    50.3061097
                 ],
                 [
                    103.2445521,
                    50.3089421
                 ],
                 [
                    103.24388949999997,
                    50.309129900000045
                 ],
                 [
                    103.24405929999999,
                    50.30951810000001
                 ],
                 [
                    103.24318510000003,
                    50.31053260000002
                 ],
                 [
                    103.24057579999997,
                    50.3158373
                 ],
                 [
                    103.2379302,
                    50.3177458
                 ],
                 [
                    103.23851430000002,
                    50.3202878
                 ],
                 [
                    103.23805749999997,
                    50.32194560000003
                 ],
                 [
                    103.2355105,
                    50.3226816
                 ],
                 [
                    103.23352759999997,
                    50.32368619999998
                 ],
                 [
                    103.2282678,
                    50.3188044
                 ],
                 [
                    103.22092480000003,
                    50.3190791
                 ],
                 [
                    103.21734810000004,
                    50.3210147
                 ],
                 [
                    103.21679960000003,
                    50.32509549999997
                 ],
                 [
                    103.21519360000002,
                    50.325722200000044
                 ],
                 [
                    103.21380030000003,
                    50.32756290000002
                 ],
                 [
                    103.212171,
                    50.32943930000002
                 ],
                 [
                    103.20644209999998,
                    50.33145
                 ],
                 [
                    103.20283129999999,
                    50.33124949999996
                 ],
                 [
                    103.19937830000002,
                    50.332860699999955
                 ],
                 [
                    103.196572,
                    50.3331168
                 ],
                 [
                    103.1821793,
                    50.33317209999996
                 ],
                 [
                    103.1766627,
                    50.332542800000034
                 ],
                 [
                    103.1754424,
                    50.33141939999999
                 ],
                 [
                    103.17606869999996,
                    50.329672500000044
                 ],
                 [
                    103.17280869999996,
                    50.327424
                 ],
                 [
                    103.17201490000002,
                    50.32567079999996
                 ],
                 [
                    103.1758245,
                    50.322215599999986
                 ],
                 [
                    103.17554560000002,
                    50.3207451
                 ],
                 [
                    103.1724136,
                    50.31803710000002
                 ],
                 [
                    103.1722681,
                    50.3164772
                 ],
                 [
                    103.16807870000002,
                    50.31612320000003
                 ],
                 [
                    103.16234860000003,
                    50.317246100000034
                 ],
                 [
                    103.15745719999997,
                    50.31602770000003
                 ],
                 [
                    103.15368189999997,
                    50.31508210000004
                 ],
                 [
                    103.15274140000004,
                    50.31361719999998
                 ],
                 [
                    103.15087040000004,
                    50.3088234
                 ],
                 [
                    103.14844249999997,
                    50.308031600000035
                 ],
                 [
                    103.14723540000003,
                    50.306018299999984
                 ],
                 [
                    103.1428802,
                    50.3066847
                 ],
                 [
                    103.12826640000002,
                    50.31059639999997
                 ],
                 [
                    103.11664970000004,
                    50.3142508
                 ],
                 [
                    103.11523910000003,
                    50.3161225
                 ],
                 [
                    103.1137456,
                    50.31662939999999
                 ],
                 [
                    103.1103681,
                    50.316285
                 ],
                 [
                    103.10814780000003,
                    50.317549700000015
                 ],
                 [
                    103.10450350000002,
                    50.31879510000003
                 ],
                 [
                    103.10128789999997,
                    50.31722269999996
                 ],
                 [
                    103.09523129999997,
                    50.31603789999998
                 ],
                 [
                    103.09268830000005,
                    50.316165200000036
                 ],
                 [
                    103.08967570000002,
                    50.31740590000002
                 ],
                 [
                    103.086081,
                    50.318710300000035
                 ],
                 [
                    103.08002760000002,
                    50.31745239999996
                 ],
                 [
                    103.074565,
                    50.31899289999997
                 ],
                 [
                    103.0708366,
                    50.31831359999998
                 ],
                 [
                    103.06593139999995,
                    50.314234
                 ],
                 [
                    103.0584507,
                    50.31434379999997
                 ],
                 [
                    103.05016320000003,
                    50.3124679
                 ],
                 [
                    103.04447410000004,
                    50.31320250000004
                 ],
                 [
                    103.02594029999999,
                    50.30642020000002
                 ],
                 [
                    103.02285580000004,
                    50.305926899999974
                 ],
                 [
                    103.01604990000003,
                    50.3065347
                 ],
                 [
                    103.0084967,
                    50.308794
                 ],
                 [
                    103.00371290000004,
                    50.3069748
                 ],
                 [
                    102.99936330000004,
                    50.30559729999997
                 ],
                 [
                    102.9899654,
                    50.30409439999996
                 ],
                 [
                    102.98081840000005,
                    50.30585570000003
                 ],
                 [
                    102.96812060000005,
                    50.304453600000016
                 ],
                 [
                    102.957328,
                    50.30595750000002
                 ],
                 [
                    102.94941889999996,
                    50.3067534
                 ],
                 [
                    102.94187450000004,
                    50.30549070000003
                 ],
                 [
                    102.933231,
                    50.30737140000003
                 ],
                 [
                    102.92308519999997,
                    50.3103541
                 ],
                 [
                    102.92184280000004,
                    50.311945300000026
                 ],
                 [
                    102.91143379999997,
                    50.3186347
                 ],
                 [
                    102.90203909999998,
                    50.321670900000036
                 ],
                 [
                    102.90061549999996,
                    50.32653130000002
                 ],
                 [
                    102.89882360000003,
                    50.32911139999998
                 ],
                 [
                    102.89350819999997,
                    50.330326
                 ],
                 [
                    102.8854009,
                    50.3295636
                 ],
                 [
                    102.8803619,
                    50.328458500000046
                 ],
                 [
                    102.87079040000003,
                    50.32765010000004
                 ],
                 [
                    102.865218,
                    50.33082320000004
                 ],
                 [
                    102.8617324,
                    50.334161
                 ],
                 [
                    102.8587974,
                    50.33632
                 ],
                 [
                    102.85520370000002,
                    50.33649879999997
                 ],
                 [
                    102.84772750000005,
                    50.33197619999996
                 ],
                 [
                    102.84182880000004,
                    50.3341911
                 ],
                 [
                    102.8373392,
                    50.337183799999984
                 ],
                 [
                    102.83810319999998,
                    50.34304379999998
                 ],
                 [
                    102.83703790000004,
                    50.343819
                 ],
                 [
                    102.83138170000002,
                    50.343840199999974
                 ],
                 [
                    102.82985330000004,
                    50.3451146
                 ],
                 [
                    102.8313354,
                    50.348069599999974
                 ],
                 [
                    102.82815759999997,
                    50.355262600000046
                 ],
                 [
                    102.8194083,
                    50.35830919999997
                 ],
                 [
                    102.8129418,
                    50.358694399999955
                 ],
                 [
                    102.80514479999997,
                    50.360936
                 ],
                 [
                    102.80353779999996,
                    50.3627321
                 ],
                 [
                    102.79562129999998,
                    50.363795799999984
                 ],
                 [
                    102.78225080000001,
                    50.36977440000003
                 ],
                 [
                    102.76906150000003,
                    50.3763662
                 ],
                 [
                    102.76151950000003,
                    50.3770016
                 ],
                 [
                    102.75835429999998,
                    50.37904270000004
                 ],
                 [
                    102.75623050000003,
                    50.38327210000004
                 ],
                 [
                    102.75356810000001,
                    50.38691210000003
                 ],
                 [
                    102.7526006,
                    50.3925696
                 ],
                 [
                    102.7505267,
                    50.4001882
                 ],
                 [
                    102.74249440000001,
                    50.4007754
                 ],
                 [
                    102.73614339999997,
                    50.40691280000004
                 ],
                 [
                    102.73181920000003,
                    50.40852930000003
                 ],
                 [
                    102.72690959999997,
                    50.408125899999966
                 ],
                 [
                    102.71658849999999,
                    50.404938
                 ],
                 [
                    102.71263460000004,
                    50.4035576
                 ],
                 [
                    102.70659329999997,
                    50.39967479999998
                 ],
                 [
                    102.70161789999997,
                    50.39718810000002
                 ],
                 [
                    102.70010950000004,
                    50.39560209999998
                 ],
                 [
                    102.69805460000002,
                    50.394188299999975
                 ],
                 [
                    102.688157,
                    50.393410700000025
                 ],
                 [
                    102.6859866,
                    50.39380189999998
                 ],
                 [
                    102.68067809999997,
                    50.39419090000001
                 ],
                 [
                    102.6752265,
                    50.396193
                 ],
                 [
                    102.66631969999997,
                    50.39771540000004
                 ],
                 [
                    102.65846790000002,
                    50.3984985
                 ],
                 [
                    102.65625079999998,
                    50.399829099999955
                 ],
                 [
                    102.65307190000004,
                    50.401140099999985
                 ],
                 [
                    102.64769759999999,
                    50.402189699999965
                 ],
                 [
                    102.6439382,
                    50.4062928
                 ],
                 [
                    102.64283339999996,
                    50.4078312
                 ],
                 [
                    102.642831,
                    50.410419400000016
                 ],
                 [
                    102.642828,
                    50.4135994
                 ],
                 [
                    102.62506640000002,
                    50.43339890000002
                 ],
                 [
                    102.62507219999998,
                    50.435676
                 ],
                 [
                    102.62346280000004,
                    50.43839360000002
                 ],
                 [
                    102.62267939999997,
                    50.4427204
                 ],
                 [
                    102.62065289999998,
                    50.44470550000002
                 ],
                 [
                    102.61611290000005,
                    50.4536664
                 ],
                 [
                    102.62005130000003,
                    50.458952
                 ],
                 [
                    102.62043269999997,
                    50.46067090000001
                 ],
                 [
                    102.6204965,
                    50.4681534
                 ],
                 [
                    102.61847249999997,
                    50.47006629999997
                 ],
                 [
                    102.61927850000004,
                    50.47187020000001
                 ],
                 [
                    102.61976729999998,
                    50.473639300000016
                 ],
                 [
                    102.61665260000002,
                    50.48595249999998
                 ],
                 [
                    102.6129554,
                    50.48862260000002
                 ],
                 [
                    102.609706,
                    50.4934711
                 ],
                 [
                    102.6090168,
                    50.49712469999996
                 ],
                 [
                    102.61031850000005,
                    50.499068
                 ],
                 [
                    102.60985479999997,
                    50.500941
                 ],
                 [
                    102.610525,
                    50.5033039
                 ],
                 [
                    102.61063,
                    50.5063847
                 ],
                 [
                    102.60882190000004,
                    50.5071802
                 ],
                 [
                    102.60648989999999,
                    50.507457700000046
                 ],
                 [
                    102.59635580000003,
                    50.51291190000004
                 ],
                 [
                    102.59287819999997,
                    50.51661530000004
                 ],
                 [
                    102.58984909999998,
                    50.5187947
                 ],
                 [
                    102.5877078,
                    50.51966390000004
                 ],
                 [
                    102.58244119999998,
                    50.520006599999974
                 ],
                 [
                    102.572612,
                    50.5183474
                 ],
                 [
                    102.5749617,
                    50.52026749999998
                 ],
                 [
                    102.574557,
                    50.52373330000004
                 ],
                 [
                    102.56074119999997,
                    50.524813
                 ],
                 [
                    102.546919,
                    50.52684530000004
                 ],
                 [
                    102.541005,
                    50.53156280000002
                 ],
                 [
                    102.5412134,
                    50.53340529999996
                 ],
                 [
                    102.55420460000002,
                    50.5494517
                 ],
                 [
                    102.55330810000001,
                    50.5540472
                 ],
                 [
                    102.553936,
                    50.55602539999999
                 ],
                 [
                    102.546704,
                    50.56754820000002
                 ],
                 [
                    102.53943739999997,
                    50.582022600000016
                 ],
                 [
                    102.5362347,
                    50.588504200000024
                 ],
                 [
                    102.5298328,
                    50.59241710000004
                 ],
                 [
                    102.52542909999998,
                    50.59464440000003
                 ],
                 [
                    102.51608869999995,
                    50.5949521
                 ],
                 [
                    102.50555480000004,
                    50.59488189999996
                 ],
                 [
                    102.49859560000004,
                    50.596749
                 ],
                 [
                    102.47897260000002,
                    50.597430299999964
                 ],
                 [
                    102.46959520000004,
                    50.60104410000001
                 ],
                 [
                    102.44993170000004,
                    50.615167
                 ],
                 [
                    102.4469202,
                    50.6194677
                 ],
                 [
                    102.44991,
                    50.630111100000015
                 ],
                 [
                    102.44834720000003,
                    50.63694949999997
                 ],
                 [
                    102.44837039999999,
                    50.6438296
                 ],
                 [
                    102.44590390000002,
                    50.64567869999996
                 ],
                 [
                    102.42182359999997,
                    50.6406737
                 ],
                 [
                    102.4118861,
                    50.64252079999998
                 ],
                 [
                    102.403708,
                    50.64099510000004
                 ],
                 [
                    102.39993909999997,
                    50.6415839
                 ],
                 [
                    102.395419,
                    50.64458709999997
                 ],
                 [
                    102.39276380000003,
                    50.64830640000004
                 ],
                 [
                    102.3920185,
                    50.65357189999997
                 ],
                 [
                    102.38985270000003,
                    50.659312300000025
                 ],
                 [
                    102.38423830000002,
                    50.6629618
                 ],
                 [
                    102.37666230000005,
                    50.66674659999999
                 ],
                 [
                    102.37083020000004,
                    50.667183
                 ],
                 [
                    102.35988239999999,
                    50.66541539999998
                 ],
                 [
                    102.347908,
                    50.66292680000004
                 ],
                 [
                    102.331123,
                    50.6725047
                 ],
                 [
                    102.3249009,
                    50.675763900000014
                 ],
                 [
                    102.30576270000003,
                    50.6802627
                 ],
                 [
                    102.30234639999998,
                    50.68185490000003
                 ],
                 [
                    102.30218510000005,
                    50.691489399999966
                 ],
                 [
                    102.30590850000002,
                    50.7020708
                 ],
                 [
                    102.30841640000004,
                    50.7047669
                 ],
                 [
                    102.31450390000003,
                    50.7092179
                 ],
                 [
                    102.325327,
                    50.71532950000004
                 ],
                 [
                    102.3323411,
                    50.71864960000003
                 ],
                 [
                    102.33182170000002,
                    50.72141360000002
                 ],
                 [
                    102.3345361,
                    50.72581859999998
                 ],
                 [
                    102.3383491,
                    50.728895
                 ],
                 [
                    102.34031319999997,
                    50.73253719999997
                 ],
                 [
                    102.33702370000003,
                    50.73825239999998
                 ],
                 [
                    102.333719,
                    50.74082480000001
                 ],
                 [
                    102.32857509999998,
                    50.742401800000025
                 ],
                 [
                    102.32543729999998,
                    50.74527269999997
                 ],
                 [
                    102.32352930000002,
                    50.748584900000026
                 ],
                 [
                    102.32329980000004,
                    50.75163189999998
                 ],
                 [
                    102.32191209999998,
                    50.753749300000024
                 ],
                 [
                    102.3082375,
                    50.7615724
                 ],
                 [
                    102.30482939999999,
                    50.7629507
                 ],
                 [
                    102.296187,
                    50.7714309
                 ],
                 [
                    102.29352349999996,
                    50.77508049999999
                 ],
                 [
                    102.29502070000004,
                    50.77972389999996
                 ],
                 [
                    102.29234990000003,
                    50.7824305
                 ],
                 [
                    102.28777739999998,
                    50.7837359
                 ],
                 [
                    102.28572350000003,
                    50.784322400000036
                 ],
                 [
                    102.27377370000002,
                    50.7931325
                 ],
                 [
                    102.26175779999996,
                    50.796398
                 ],
                 [
                    102.2501661,
                    50.7979913
                 ],
                 [
                    102.24186840000002,
                    50.797463600000036
                 ],
                 [
                    102.23909890000002,
                    50.79852679999998
                 ],
                 [
                    102.23652339999998,
                    50.79981259999997
                 ],
                 [
                    102.23334120000004,
                    50.80574349999998
                 ],
                 [
                    102.22999080000002,
                    50.8092509
                 ],
                 [
                    102.2222873,
                    50.81171519999998
                 ],
                 [
                    102.21957669999998,
                    50.81105660000002
                 ],
                 [
                    102.218636,
                    50.811310200000044
                 ],
                 [
                    102.209737,
                    50.8170343
                 ],
                 [
                    102.210317,
                    50.8199284
                 ],
                 [
                    102.2140751,
                    50.823542
                 ],
                 [
                    102.21908069999998,
                    50.82647350000002
                 ],
                 [
                    102.22054220000001,
                    50.8281467
                 ],
                 [
                    102.2140851,
                    50.83222630000004
                 ],
                 [
                    102.2110173,
                    50.83706039999998
                 ],
                 [
                    102.209628,
                    50.8416572
                 ],
                 [
                    102.21087460000001,
                    50.846118
                 ],
                 [
                    102.212762,
                    50.85002239999998
                 ],
                 [
                    102.2161783,
                    50.8522
                 ],
                 [
                    102.22102110000003,
                    50.85181250000004
                 ],
                 [
                    102.2169749,
                    50.85680649999997
                 ],
                 [
                    102.21356280000003,
                    50.8639141
                 ],
                 [
                    102.21221670000003,
                    50.86804740000004
                 ],
                 [
                    102.2111373,
                    50.87280380000003
                 ],
                 [
                    102.21197390000002,
                    50.87584619999996
                 ],
                 [
                    102.218327,
                    50.878166600000014
                 ],
                 [
                    102.2255368,
                    50.8826276
                 ],
                 [
                    102.23657410000001,
                    50.88884630000001
                 ],
                 [
                    102.2421076,
                    50.89845769999999
                 ],
                 [
                    102.2454385,
                    50.90197329999999
                 ],
                 [
                    102.24544330000003,
                    50.904199599999984
                 ],
                 [
                    102.243024,
                    50.90988930000002
                 ],
                 [
                    102.24114960000004,
                    50.91745319999996
                 ],
                 [
                    102.23642180000002,
                    50.91945119999998
                 ],
                 [
                    102.22524779999998,
                    50.92540830000003
                 ],
                 [
                    102.21771319999998,
                    50.9300443
                 ],
                 [
                    102.22336809999997,
                    50.9346761
                 ],
                 [
                    102.22498330000003,
                    50.93764680000003
                 ],
                 [
                    102.2153976,
                    50.94807750000003
                 ],
                 [
                    102.2137847,
                    50.95384109999996
                 ],
                 [
                    102.22198339999997,
                    50.96061919999999
                 ],
                 [
                    102.23105570000003,
                    50.970156300000035
                 ],
                 [
                    102.2430083,
                    50.9748866
                 ],
                 [
                    102.237995,
                    50.97489589999997
                 ],
                 [
                    102.2315386,
                    50.97537840000001
                 ],
                 [
                    102.23022080000004,
                    50.97663750000001
                 ],
                 [
                    102.2232214,
                    50.99281289999997
                 ],
                 [
                    102.22369,
                    50.996096
                 ],
                 [
                    102.22561159999998,
                    50.9990446
                 ],
                 [
                    102.216466,
                    51.0094078
                 ],
                 [
                    102.215178,
                    51.013388799999966
                 ],
                 [
                    102.21263239999996,
                    51.02006010000004
                 ],
                 [
                    102.2090358,
                    51.0219801
                 ],
                 [
                    102.2068854,
                    51.0256812
                 ],
                 [
                    102.20360149999996,
                    51.02865459999999
                 ],
                 [
                    102.2025677,
                    51.031111
                 ],
                 [
                    102.20362409999997,
                    51.039491899999966
                 ],
                 [
                    102.197596,
                    51.03929
                 ],
                 [
                    102.18700880000003,
                    51.0416499
                 ],
                 [
                    102.18351450000002,
                    51.0427857
                 ],
                 [
                    102.18111489999998,
                    51.04816229999997
                 ],
                 [
                    102.17128150000002,
                    51.0522235
                 ],
                 [
                    102.16425409999997,
                    51.0533247
                 ],
                 [
                    102.15545829999998,
                    51.05674940000002
                 ],
                 [
                    102.151096,
                    51.05730350000004
                 ],
                 [
                    102.15010340000002,
                    51.061218799999985
                 ],
                 [
                    102.15144750000003,
                    51.06505209999998
                 ],
                 [
                    102.14791690000003,
                    51.068030500000035
                 ],
                 [
                    102.14711620000004,
                    51.071513099999976
                 ],
                 [
                    102.1489534,
                    51.076197100000016
                 ],
                 [
                    102.1491747,
                    51.0794537
                 ],
                 [
                    102.15132410000004,
                    51.080745500000035
                 ],
                 [
                    102.1530134,
                    51.0827644
                 ],
                 [
                    102.15750360000001,
                    51.083250099999965
                 ],
                 [
                    102.16408960000004,
                    51.08443759999999
                 ],
                 [
                    102.16842010000005,
                    51.08963140000002
                 ],
                 [
                    102.17083769999998,
                    51.09502870000002
                 ],
                 [
                    102.16986380000004,
                    51.09618170000003
                 ],
                 [
                    102.16938820000004,
                    51.096737199999986
                 ],
                 [
                    102.16650360000003,
                    51.09689589999997
                 ],
                 [
                    102.16456820000002,
                    51.094971
                 ],
                 [
                    102.15977430000004,
                    51.093583399999986
                 ],
                 [
                    102.1451716,
                    51.096784900000024
                 ],
                 [
                    102.14016919999997,
                    51.0994232
                 ],
                 [
                    102.1376275,
                    51.102456900000014
                 ],
                 [
                    102.13711709999995,
                    51.105954599999954
                 ],
                 [
                    102.132848,
                    51.11054570000004
                 ],
                 [
                    102.13359079999995,
                    51.1143953
                 ],
                 [
                    102.13257710000002,
                    51.11722980000002
                 ],
                 [
                    102.13216529999997,
                    51.120048
                 ],
                 [
                    102.13380180000001,
                    51.124632400000024
                 ],
                 [
                    102.13196090000001,
                    51.127716899999974
                 ],
                 [
                    102.13230919999997,
                    51.1293007
                 ],
                 [
                    102.13352840000003,
                    51.12949579999999
                 ],
                 [
                    102.13503240000004,
                    51.1312865
                 ],
                 [
                    102.13341340000002,
                    51.133140600000026
                 ],
                 [
                    102.1279762,
                    51.136549100000046
                 ],
                 [
                    102.12454150000003,
                    51.13747549999996
                 ],
                 [
                    102.12332459999999,
                    51.139101400000015
                 ],
                 [
                    102.1254095,
                    51.141397600000026
                 ],
                 [
                    102.12544510000002,
                    51.14549390000003
                 ],
                 [
                    102.12398459999997,
                    51.14887139999997
                 ],
                 [
                    102.12497960000005,
                    51.15092360000004
                 ],
                 [
                    102.130987,
                    51.154298599999954
                 ],
                 [
                    102.13555539999997,
                    51.15916450000004
                 ],
                 [
                    102.13402210000002,
                    51.16347369999998
                 ],
                 [
                    102.136094,
                    51.1691086
                 ],
                 [
                    102.1351134,
                    51.17421840000002
                 ],
                 [
                    102.13823730000001,
                    51.176326800000034
                 ],
                 [
                    102.1413556,
                    51.1754923
                 ],
                 [
                    102.14622640000005,
                    51.17611989999997
                 ],
                 [
                    102.1539409,
                    51.1746603
                 ],
                 [
                    102.1663187,
                    51.17868820000002
                 ],
                 [
                    102.16566229999995,
                    51.18143750000004
                 ],
                 [
                    102.15976110000001,
                    51.18924040000003
                 ],
                 [
                    102.15208850000005,
                    51.19303339999998
                 ],
                 [
                    102.14620570000002,
                    51.1931128
                 ],
                 [
                    102.13834519999996,
                    51.20045939999997
                 ],
                 [
                    102.13359519999997,
                    51.201649
                 ],
                 [
                    102.13354450000003,
                    51.20445780000004
                 ],
                 [
                    102.12776060000002,
                    51.2099995
                 ],
                 [
                    102.13092630000003,
                    51.21037
                 ],
                 [
                    102.13317640000004,
                    51.211721099999984
                 ],
                 [
                    102.14298440000003,
                    51.2204173
                 ],
                 [
                    102.13345379999998,
                    51.22623570000002
                 ],
                 [
                    102.122014,
                    51.23220630000002
                 ],
                 [
                    102.11970149999996,
                    51.23460780000004
                 ],
                 [
                    102.11359970000004,
                    51.237312099999976
                 ],
                 [
                    102.11095990000001,
                    51.241176100000025
                 ],
                 [
                    102.10641190000004,
                    51.24453749999997
                 ],
                 [
                    102.10448720000004,
                    51.24646920000002
                 ],
                 [
                    102.10529550000004,
                    51.247699699999956
                 ],
                 [
                    102.11440560000001,
                    51.25102349999999
                 ],
                 [
                    102.1166233,
                    51.25340859999998
                 ],
                 [
                    102.11733340000005,
                    51.25587720000004
                 ],
                 [
                    102.11579490000003,
                    51.258156399999976
                 ],
                 [
                    102.11388029999998,
                    51.25934550000004
                 ],
                 [
                    102.11806459999997,
                    51.26310350000003
                 ],
                 [
                    102.11940610000003,
                    51.2659574
                 ],
                 [
                    102.115561,
                    51.26810189999997
                 ],
                 [
                    102.11543479999999,
                    51.270703899999965
                 ],
                 [
                    102.11788329999999,
                    51.27336990000002
                 ],
                 [
                    102.11780589999998,
                    51.2757999
                 ],
                 [
                    102.119488,
                    51.277860500000045
                 ],
                 [
                    102.11953529999998,
                    51.27945259999997
                 ],
                 [
                    102.1163839,
                    51.28204030000003
                 ],
                 [
                    102.1141597,
                    51.28403429999999
                 ],
                 [
                    102.11562120000005,
                    51.2877508
                 ],
                 [
                    102.11785610000004,
                    51.28870159999998
                 ],
                 [
                    102.11979709999997,
                    51.288663799999966
                 ],
                 [
                    102.124695,
                    51.285953599999985
                 ],
                 [
                    102.13199860000003,
                    51.283937399999964
                 ],
                 [
                    102.13892360000004,
                    51.2843587
                 ],
                 [
                    102.1436024,
                    51.28377769999999
                 ],
                 [
                    102.14742530000004,
                    51.28041239999998
                 ],
                 [
                    102.1501484,
                    51.2794605
                 ],
                 [
                    102.14916950000003,
                    51.28203470000001
                 ],
                 [
                    102.150732,
                    51.2829299
                 ],
                 [
                    102.15417960000005,
                    51.28364490000001
                 ],
                 [
                    102.159105,
                    51.28313259999999
                 ],
                 [
                    102.17345340000004,
                    51.28505829999998
                 ],
                 [
                    102.1736237,
                    51.286647
                 ],
                 [
                    102.17518519999999,
                    51.2882357
                 ],
                 [
                    102.17664919999996,
                    51.29191380000002
                 ],
                 [
                    102.1839684,
                    51.29502519999998
                 ],
                 [
                    102.18191569999998,
                    51.29632309999998
                 ],
                 [
                    102.18101179999996,
                    51.299003200000044
                 ],
                 [
                    102.17950960000002,
                    51.30003030000003
                 ],
                 [
                    102.17887239999997,
                    51.30146780000004
                 ],
                 [
                    102.17360509999997,
                    51.303035900000026
                 ],
                 [
                    102.16908660000004,
                    51.305965699999966
                 ],
                 [
                    102.16802959999998,
                    51.3081946
                 ],
                 [
                    102.17012640000002,
                    51.31516910000002
                 ],
                 [
                    102.16826430000003,
                    51.31862069999997
                 ],
                 [
                    102.16533680000002,
                    51.32006549999996
                 ],
                 [
                    102.1605093,
                    51.320076100000044
                 ],
                 [
                    102.1578869,
                    51.3226569
                 ],
                 [
                    102.1569357,
                    51.32643830000003
                 ],
                 [
                    102.152462,
                    51.3283037
                 ],
                 [
                    102.14726550000005,
                    51.33288220000003
                 ],
                 [
                    102.14716140000004,
                    51.33858210000002
                 ],
                 [
                    102.14466860000003,
                    51.34182879999997
                 ],
                 [
                    102.1371927,
                    51.346360300000015
                 ],
                 [
                    102.12501050000003,
                    51.3487518
                 ],
                 [
                    102.12727860000003,
                    51.35151329999996
                 ],
                 [
                    102.12631939999997,
                    51.355218800000046
                 ],
                 [
                    102.12686239999996,
                    51.3582959
                 ],
                 [
                    102.12650480000003,
                    51.3592727
                 ],
                 [
                    102.12429110000002,
                    51.361612
                 ],
                 [
                    102.11649580000002,
                    51.36352930000003
                 ],
                 [
                    102.1119731,
                    51.367019599999985
                 ],
                 [
                    102.1031686,
                    51.36945360000002
                 ],
                 [
                    102.0952712,
                    51.3725836
                 ],
                 [
                    102.0868897,
                    51.373412
                 ],
                 [
                    102.07991360000003,
                    51.376934
                 ],
                 [
                    102.06867230000002,
                    51.379239799999986
                 ],
                 [
                    102.06047240000004,
                    51.379523900000045
                 ],
                 [
                    102.05765909999997,
                    51.38043840000004
                 ],
                 [
                    102.05390730000005,
                    51.384178
                 ],
                 [
                    102.05005049999997,
                    51.386268499999964
                 ],
                 [
                    102.04647819999997,
                    51.38733039999998
                 ],
                 [
                    102.03879410000003,
                    51.387568
                 ],
                 [
                    102.03574130000004,
                    51.3896921
                 ],
                 [
                    102.03027920000001,
                    51.38991319999999
                 ],
                 [
                    102.02801149999996,
                    51.390464799999975
                 ],
                 [
                    102.02205870000002,
                    51.39581570000004
                 ],
                 [
                    102.01892320000002,
                    51.39608639999997
                 ],
                 [
                    102.01524140000004,
                    51.394750500000015
                 ],
                 [
                    102.00199339999999,
                    51.3866587
                 ],
                 [
                    102.00099489999997,
                    51.3843701
                 ],
                 [
                    102.00143240000003,
                    51.382190399999956
                 ],
                 [
                    101.99894950000002,
                    51.384080799999964
                 ],
                 [
                    101.99737790000002,
                    51.38712790000002
                 ],
                 [
                    101.9964063,
                    51.3899548
                 ],
                 [
                    101.993598,
                    51.3930618
                 ],
                 [
                    101.98779149999997,
                    51.39509410000002
                 ],
                 [
                    101.98400530000005,
                    51.39529170000003
                 ],
                 [
                    101.97929680000003,
                    51.39567690000002
                 ],
                 [
                    101.97601120000004,
                    51.39403350000002
                 ],
                 [
                    101.9743552,
                    51.391653
                 ],
                 [
                    101.97149009999998,
                    51.39268310000002
                 ],
                 [
                    101.97088750000003,
                    51.395000800000034
                 ],
                 [
                    101.9703053,
                    51.397632199999975
                 ],
                 [
                    101.966943,
                    51.39996770000003
                 ],
                 [
                    101.96302540000002,
                    51.40666070000004
                 ],
                 [
                    101.96125940000002,
                    51.409112699999966
                 ],
                 [
                    101.9556161,
                    51.41229620000003
                 ],
                 [
                    101.951935,
                    51.413743799999985
                 ],
                 [
                    101.94655880000003,
                    51.414139
                 ],
                 [
                    101.93967270000005,
                    51.4130581
                 ],
                 [
                    101.93446930000002,
                    51.4141304
                 ],
                 [
                    101.92816530000005,
                    51.4167314
                 ],
                 [
                    101.92554570000001,
                    51.41592379999997
                 ],
                 [
                    101.92115430000003,
                    51.41399230000003
                 ],
                 [
                    101.9171973,
                    51.413620199999976
                 ],
                 [
                    101.9122873,
                    51.41438960000004
                 ],
                 [
                    101.9096918,
                    51.4113996
                 ],
                 [
                    101.90407560000001,
                    51.4086085
                 ],
                 [
                    101.9014037,
                    51.4060581
                 ],
                 [
                    101.893732,
                    51.401172
                 ],
                 [
                    101.888839,
                    51.400795300000034
                 ],
                 [
                    101.88785060000004,
                    51.402102
                 ],
                 [
                    101.88826260000002,
                    51.4046561
                 ],
                 [
                    101.88469440000001,
                    51.40847849999999
                 ],
                 [
                    101.87393310000002,
                    51.40830549999997
                 ],
                 [
                    101.866504,
                    51.4085922
                 ],
                 [
                    101.86518069999997,
                    51.409568
                 ],
                 [
                    101.86728840000002,
                    51.411765
                 ],
                 [
                    101.86714910000003,
                    51.413547800000025
                 ],
                 [
                    101.86369260000004,
                    51.416968
                 ],
                 [
                    101.8582519,
                    51.415806
                 ],
                 [
                    101.85271740000002,
                    51.41562720000003
                 ],
                 [
                    101.84694929999998,
                    51.417075799999985
                 ],
                 [
                    101.84015920000003,
                    51.419595500000014
                 ],
                 [
                    101.8316915,
                    51.41530009999997
                 ],
                 [
                    101.8285311,
                    51.415154700000016
                 ],
                 [
                    101.83047389999999,
                    51.41726559999997
                 ],
                 [
                    101.829883,
                    51.4185358
                 ],
                 [
                    101.82690989999998,
                    51.419183300000014
                 ],
                 [
                    101.81789719999998,
                    51.420970299999986
                 ],
                 [
                    101.81519720000003,
                    51.423113799999975
                 ],
                 [
                    101.81271210000003,
                    51.425124299999965
                 ],
                 [
                    101.81076,
                    51.43150330000001
                 ],
                 [
                    101.805127,
                    51.43703830000003
                 ],
                 [
                    101.80405480000005,
                    51.43820520000004
                 ],
                 [
                    101.80098089999998,
                    51.4372322
                 ],
                 [
                    101.79652430000002,
                    51.43692
                 ],
                 [
                    101.78756980000004,
                    51.441110399999985
                 ],
                 [
                    101.784718,
                    51.440119
                 ],
                 [
                    101.77844,
                    51.4410656
                 ],
                 [
                    101.77031270000002,
                    51.44513039999998
                 ],
                 [
                    101.75695,
                    51.44989380000002
                 ],
                 [
                    101.74905689999999,
                    51.455341700000034
                 ],
                 [
                    101.74352920000004,
                    51.4551278
                 ],
                 [
                    101.73681819999996,
                    51.453557799999956
                 ],
                 [
                    101.72098579999997,
                    51.45310020000002
                 ],
                 [
                    101.71654030000002,
                    51.45410820000002
                 ],
                 [
                    101.70486010000002,
                    51.45134339999997
                 ],
                 [
                    101.7004915,
                    51.44827810000001
                 ],
                 [
                    101.6970558,
                    51.44846610000002
                 ],
                 [
                    101.6939685,
                    51.451986599999984
                 ],
                 [
                    101.6910701,
                    51.453257
                 ],
                 [
                    101.68395250000003,
                    51.451144
                 ],
                 [
                    101.67805439999998,
                    51.45067120000004
                 ],
                 [
                    101.67193110000004,
                    51.44678769999997
                 ],
                 [
                    101.66616049999998,
                    51.446919
                 ],
                 [
                    101.66033069999999,
                    51.44459220000004
                 ],
                 [
                    101.6540362,
                    51.44290350000002
                 ],
                 [
                    101.64931789999997,
                    51.4438474
                 ],
                 [
                    101.64416219999997,
                    51.44302209999998
                 ],
                 [
                    101.63798840000003,
                    51.4424341
                 ],
                 [
                    101.63363440000002,
                    51.44341619999999
                 ],
                 [
                    101.62594320000002,
                    51.442176
                 ],
                 [
                    101.61903780000004,
                    51.440028
                 ],
                 [
                    101.62049909999998,
                    51.44331040000003
                 ],
                 [
                    101.61563040000001,
                    51.45072680000002
                 ],
                 [
                    101.614998,
                    51.45433939999997
                 ],
                 [
                    101.62208530000004,
                    51.46277769999996
                 ],
                 [
                    101.62144589999998,
                    51.46598119999999
                 ],
                 [
                    101.62226269999996,
                    51.46921070000003
                 ],
                 [
                    101.61165040000004,
                    51.470490799999986
                 ],
                 [
                    101.60566790000004,
                    51.47182220000001
                 ],
                 [
                    101.6023257,
                    51.4730007
                 ],
                 [
                    101.5981136,
                    51.4755534
                 ],
                 [
                    101.59667990000004,
                    51.47935419999998
                 ],
                 [
                    101.59218149999998,
                    51.48028970000002
                 ],
                 [
                    101.5847737,
                    51.481185900000014
                 ],
                 [
                    101.5799881,
                    51.481029699999965
                 ],
                 [
                    101.57238860000002,
                    51.478528
                 ],
                 [
                    101.56833610000001,
                    51.47500640000004
                 ],
                 [
                    101.56771639999998,
                    51.46994419999997
                 ],
                 [
                    101.56492690000002,
                    51.473982
                 ],
                 [
                    101.56479539999997,
                    51.476495300000046
                 ],
                 [
                    101.56682519999995,
                    51.4794349
                 ],
                 [
                    101.56650490000001,
                    51.4826863
                 ],
                 [
                    101.56163180000004,
                    51.48844419999998
                 ],
                 [
                    101.5562597,
                    51.49279980000003
                 ],
                 [
                    101.55104390000001,
                    51.494136799999985
                 ],
                 [
                    101.54768750000004,
                    51.496911
                 ],
                 [
                    101.54221720000004,
                    51.499541599999986
                 ],
                 [
                    101.53570660000003,
                    51.499940899999984
                 ],
                 [
                    101.52932730000005,
                    51.499925
                 ],
                 [
                    101.52600089999997,
                    51.49889790000002
                 ],
                 [
                    101.52212870000004,
                    51.496226100000015
                 ],
                 [
                    101.5070086,
                    51.50189329999996
                 ],
                 [
                    101.49975159999997,
                    51.503332700000044
                 ],
                 [
                    101.49555090000003,
                    51.501643
                 ],
                 [
                    101.4918569,
                    51.49847749999996
                 ],
                 [
                    101.48831360000003,
                    51.49788829999997
                 ],
                 [
                    101.48634410000004,
                    51.495769
                 ],
                 [
                    101.4807594,
                    51.493271
                 ],
                 [
                    101.47263940000005,
                    51.491925100000046
                 ],
                 [
                    101.46519050000003,
                    51.49213430000003
                 ],
                 [
                    101.4595599,
                    51.4911656
                 ],
                 [
                    101.4575352,
                    51.479283399999986
                 ],
                 [
                    101.45902729999999,
                    51.477298499999975
                 ],
                 [
                    101.46006050000003,
                    51.47314309999997
                 ],
                 [
                    101.45879170000003,
                    51.4707121
                 ],
                 [
                    101.4550256,
                    51.466760200000046
                 ],
                 [
                    101.44782220000005,
                    51.461690100000034
                 ],
                 [
                    101.43893639999997,
                    51.455689
                 ],
                 [
                    101.4354952,
                    51.4552511
                 ],
                 [
                    101.4290084,
                    51.45641850000004
                 ],
                 [
                    101.41720879999998,
                    51.4566102
                 ],
                 [
                    101.41385209999999,
                    51.4556396
                 ],
                 [
                    101.40538960000003,
                    51.4532428
                 ],
                 [
                    101.39906209999998,
                    51.4527563
                 ],
                 [
                    101.39629249999999,
                    51.4538478
                 ],
                 [
                    101.39244509999997,
                    51.4555485
                 ],
                 [
                    101.38827909999998,
                    51.459204
                 ],
                 [
                    101.38529609999998,
                    51.45658380000004
                 ],
                 [
                    101.3826535,
                    51.455695700000014
                 ],
                 [
                    101.37573330000004,
                    51.45532980000002
                 ],
                 [
                    101.37009809999998,
                    51.45584559999998
                 ],
                 [
                    101.3684357,
                    51.45771070000002
                 ],
                 [
                    101.3694563,
                    51.459626
                 ],
                 [
                    101.3696495,
                    51.461081699999966
                 ],
                 [
                    101.3651109,
                    51.4614406
                 ],
                 [
                    101.360029,
                    51.46465339999999
                 ],
                 [
                    101.35837049999996,
                    51.4655532
                 ],
                 [
                    101.35781139999997,
                    51.4677097
                 ],
                 [
                    101.36094310000001,
                    51.469949
                 ],
                 [
                    101.36607540000003,
                    51.4716083
                 ],
                 [
                    101.362409,
                    51.47237879999997
                 ],
                 [
                    101.36052450000003,
                    51.47320160000002
                 ],
                 [
                    101.35610950000003,
                    51.478575400000025
                 ],
                 [
                    101.349831,
                    51.481857299999966
                 ],
                 [
                    101.34596830000001,
                    51.4834358
                 ],
                 [
                    101.34142060000005,
                    51.48954410000004
                 ],
                 [
                    101.33821630000004,
                    51.490664
                 ],
                 [
                    101.3312738,
                    51.4927287
                 ],
                 [
                    101.32395469999997,
                    51.4941068
                 ],
                 [
                    101.314983,
                    51.49545870000003
                 ],
                 [
                    101.3092569,
                    51.49787610000003
                 ],
                 [
                    101.29916309999996,
                    51.50424659999999
                 ],
                 [
                    101.29533040000001,
                    51.50583609999997
                 ],
                 [
                    101.28418280000002,
                    51.5081498
                 ],
                 [
                    101.28229269999996,
                    51.51059350000004
                 ],
                 [
                    101.28247649999997,
                    51.51652869999996
                 ],
                 [
                    101.27862379999998,
                    51.517728600000034
                 ],
                 [
                    101.26789840000002,
                    51.529584099999965
                 ],
                 [
                    101.26333530000002,
                    51.53414050000002
                 ],
                 [
                    101.2600926,
                    51.5425947
                 ],
                 [
                    101.25627779999998,
                    51.54411339999998
                 ],
                 [
                    101.24293210000002,
                    51.547456700000026
                 ],
                 [
                    101.23820839999998,
                    51.54662729999997
                 ],
                 [
                    101.23392939999997,
                    51.545273899999984
                 ],
                 [
                    101.2285378,
                    51.54312660000004
                 ],
                 [
                    101.22375820000003,
                    51.538037200000026
                 ],
                 [
                    101.21799179999996,
                    51.5350592
                 ],
                 [
                    101.2101998,
                    51.5332406
                 ],
                 [
                    101.20529509999997,
                    51.531008200000024
                 ],
                 [
                    101.19646910000004,
                    51.524047
                 ],
                 [
                    101.1934716,
                    51.52238730000003
                 ],
                 [
                    101.1885786,
                    51.5217986
                 ],
                 [
                    101.17629350000004,
                    51.52448639999998
                 ],
                 [
                    101.17196970000005,
                    51.524270399999956
                 ],
                 [
                    101.15916459999997,
                    51.520564800000045
                 ],
                 [
                    101.15368560000005,
                    51.519985399999975
                 ],
                 [
                    101.14639550000003,
                    51.520333800000024
                 ],
                 [
                    101.14328990000001,
                    51.5200711
                 ],
                 [
                    101.13664230000003,
                    51.521396
                 ],
                 [
                    101.13249550000003,
                    51.52419229999998
                 ],
                 [
                    101.132985,
                    51.5345545
                 ],
                 [
                    101.13479890000002,
                    51.541155400000015
                 ],
                 [
                    101.13392190000002,
                    51.54572349999997
                 ],
                 [
                    101.13043450000004,
                    51.5480896
                 ],
                 [
                    101.1275551,
                    51.549554100000016
                 ],
                 [
                    101.12473240000004,
                    51.552012
                 ],
                 [
                    101.12499720000002,
                    51.555045399999976
                 ],
                 [
                    101.12501059999997,
                    51.56158210000001
                 ],
                 [
                    101.10244989999998,
                    51.5791597
                 ],
                 [
                    101.10159090000005,
                    51.581624900000044
                 ],
                 [
                    101.10182930000005,
                    51.5849693
                 ],
                 [
                    101.10439370000005,
                    51.591220200000016
                 ],
                 [
                    101.10174940000002,
                    51.5905168
                 ],
                 [
                    101.0984053,
                    51.58539680000003
                 ],
                 [
                    101.093938,
                    51.58139549999998
                 ],
                 [
                    101.09092209999997,
                    51.580832
                 ],
                 [
                    101.08914749999997,
                    51.57033630000003
                 ],
                 [
                    101.0786775,
                    51.5598484
                 ],
                 [
                    101.07617269999996,
                    51.56227179999997
                 ],
                 [
                    101.0657639,
                    51.569752300000026
                 ],
                 [
                    101.05980210000001,
                    51.57124760000002
                 ],
                 [
                    101.04671150000003,
                    51.5725791
                 ],
                 [
                    101.03972530000001,
                    51.57290649999997
                 ],
                 [
                    101.02338379999998,
                    51.56984079999997
                 ],
                 [
                    101.01742690000005,
                    51.57027
                 ],
                 [
                    101.01692450000004,
                    51.57565510000001
                 ],
                 [
                    101.01457450000002,
                    51.5804307
                 ],
                 [
                    101.01670889999997,
                    51.58311470000003
                 ],
                 [
                    101.01946970000004,
                    51.58476780000003
                 ],
                 [
                    101.02232810000004,
                    51.587153
                 ],
                 [
                    101.01405160000002,
                    51.596502099999974
                 ],
                 [
                    100.99821310000004,
                    51.60384830000002
                 ],
                 [
                    100.99015189999999,
                    51.6127877
                 ],
                 [
                    100.97821179999998,
                    51.616186
                 ],
                 [
                    100.957916,
                    51.6158622
                 ],
                 [
                    100.939076,
                    51.609813400000014
                 ],
                 [
                    100.929841,
                    51.6068022
                 ],
                 [
                    100.9223345,
                    51.609476600000036
                 ],
                 [
                    100.90111050000002,
                    51.61839890000002
                 ],
                 [
                    100.8881054,
                    51.623054
                 ],
                 [
                    100.86588469999998,
                    51.63042060000002
                 ],
                 [
                    100.85688169999999,
                    51.6336386
                 ],
                 [
                    100.86193480000001,
                    51.6398474
                 ],
                 [
                    100.86173679999997,
                    51.641083599999966
                 ],
                 [
                    100.86273189999997,
                    51.6417421
                 ],
                 [
                    100.85069580000001,
                    51.64802989999997
                 ],
                 [
                    100.84849850000002,
                    51.648460599999986
                 ],
                 [
                    100.84325360000003,
                    51.647705899999984
                 ],
                 [
                    100.834872,
                    51.648412600000015
                 ],
                 [
                    100.82284510000002,
                    51.648543700000026
                 ],
                 [
                    100.8210748,
                    51.65040649999997
                 ],
                 [
                    100.81545120000003,
                    51.65279570000003
                 ],
                 [
                    100.8107158,
                    51.6611211
                 ],
                 [
                    100.81018580000001,
                    51.662085200000014
                 ],
                 [
                    100.8070221,
                    51.6629187
                 ],
                 [
                    100.79732520000005,
                    51.663107500000045
                 ],
                 [
                    100.79413780000002,
                    51.66366560000004
                 ],
                 [
                    100.78913240000003,
                    51.6658087
                 ],
                 [
                    100.7788473,
                    51.66743
                 ],
                 [
                    100.77338179999995,
                    51.669877
                 ],
                 [
                    100.76800140000003,
                    51.669791
                 ],
                 [
                    100.75993560000003,
                    51.6722666
                 ],
                 [
                    100.75221550000005,
                    51.67573740000002
                 ],
                 [
                    100.749006,
                    51.6768771
                 ],
                 [
                    100.73903919999998,
                    51.67812380000003
                 ],
                 [
                    100.73448710000002,
                    51.67967510000004
                 ],
                 [
                    100.72981430000002,
                    51.68338719999996
                 ],
                 [
                    100.7250931,
                    51.68539249999997
                 ],
                 [
                    100.7223043,
                    51.68731709999996
                 ],
                 [
                    100.71781880000005,
                    51.6889
                 ],
                 [
                    100.711315,
                    51.69049980000004
                 ],
                 [
                    100.70712590000002,
                    51.6955434
                 ],
                 [
                    100.7058193,
                    51.69885850000002
                 ],
                 [
                    100.6995964,
                    51.70130290000003
                 ],
                 [
                    100.696275,
                    51.7048041
                 ],
                 [
                    100.6928573,
                    51.7102423
                 ],
                 [
                    100.68777150000003,
                    51.711623299999964
                 ],
                 [
                    100.685906,
                    51.711489299999975
                 ],
                 [
                    100.68409380000001,
                    51.71010659999997
                 ],
                 [
                    100.68225750000005,
                    51.7105515
                 ],
                 [
                    100.68087980000001,
                    51.709734
                 ],
                 [
                    100.6775893,
                    51.70957189999996
                 ],
                 [
                    100.67551510000004,
                    51.70813660000004
                 ],
                 [
                    100.67277550000003,
                    51.70692
                 ],
                 [
                    100.668545,
                    51.706714200000036
                 ],
                 [
                    100.66311,
                    51.709725400000046
                 ],
                 [
                    100.65727150000002,
                    51.714349700000035
                 ],
                 [
                    100.65903550000004,
                    51.715747200000024
                 ],
                 [
                    100.65847109999997,
                    51.7187375
                 ],
                 [
                    100.65637330000004,
                    51.719668500000026
                 ],
                 [
                    100.65897239999998,
                    51.720927800000034
                 ],
                 [
                    100.6584423,
                    51.724859099999975
                 ],
                 [
                    100.6562396,
                    51.726826
                 ],
                 [
                    100.6473255,
                    51.7272651
                 ],
                 [
                    100.6418682,
                    51.725458099999976
                 ],
                 [
                    100.63123439999998,
                    51.72492620000002
                 ],
                 [
                    100.62308359999997,
                    51.7255098
                 ],
                 [
                    100.61247989999995,
                    51.72581290000002
                 ],
                 [
                    100.6094844,
                    51.72449380000003
                 ],
                 [
                    100.603927,
                    51.7198088
                 ],
                 [
                    100.59739809999998,
                    51.71858980000003
                 ],
                 [
                    100.5928511,
                    51.7258191
                 ],
                 [
                    100.5931831,
                    51.730471
                 ],
                 [
                    100.59156919999997,
                    51.737294
                 ],
                 [
                    100.58645539999998,
                    51.74045
                 ],
                 [
                    100.582588,
                    51.74071140000002
                 ],
                 [
                    100.57386130000003,
                    51.739363599999976
                 ],
                 [
                    100.56884089999997,
                    51.73796589999997
                 ],
                 [
                    100.56458450000004,
                    51.736714800000016
                 ],
                 [
                    100.549766,
                    51.737068
                 ],
                 [
                    100.53549260000001,
                    51.73610449999997
                 ],
                 [
                    100.53159759999997,
                    51.73803870000004
                 ],
                 [
                    100.53300980000003,
                    51.742415
                 ],
                 [
                    100.531109,
                    51.7485086
                 ],
                 [
                    100.52888550000002,
                    51.75005480000003
                 ],
                 [
                    100.50385209999997,
                    51.75006389999999
                 ],
                 [
                    100.49967050000002,
                    51.743420700000016
                 ],
                 [
                    100.49354489999999,
                    51.7416676
                 ],
                 [
                    100.48534779999997,
                    51.7435503
                 ],
                 [
                    100.47691169999997,
                    51.7432906
                 ],
                 [
                    100.46817049999997,
                    51.741412
                 ],
                 [
                    100.46082130000003,
                    51.738259900000045
                 ],
                 [
                    100.4528935,
                    51.73804439999998
                 ],
                 [
                    100.45051979999998,
                    51.737602700000025
                 ],
                 [
                    100.4453717,
                    51.732068299999966
                 ],
                 [
                    100.443945,
                    51.72695879999998
                 ],
                 [
                    100.4394291,
                    51.728008200000026
                 ],
                 [
                    100.435955,
                    51.7300388
                 ],
                 [
                    100.42809620000004,
                    51.7319657
                 ],
                 [
                    100.423545,
                    51.7319162
                 ],
                 [
                    100.412952,
                    51.72995139999996
                 ],
                 [
                    100.40976829999997,
                    51.73041360000001
                 ],
                 [
                    100.41083590000002,
                    51.7431607
                 ],
                 [
                    100.41198809999997,
                    51.744978
                 ],
                 [
                    100.41629440000003,
                    51.74524
                 ],
                 [
                    100.41708870000002,
                    51.746381400000026
                 ],
                 [
                    100.41435590000003,
                    51.74793140000002
                 ],
                 [
                    100.41038940000003,
                    51.7477699
                 ],
                 [
                    100.4017586,
                    51.7466699
                 ],
                 [
                    100.39932840000002,
                    51.744397
                 ],
                 [
                    100.39180380000005,
                    51.7408765
                 ],
                 [
                    100.38709049999997,
                    51.7383163
                 ],
                 [
                    100.3845586,
                    51.73818929999998
                 ],
                 [
                    100.37316139999997,
                    51.74025879999997
                 ],
                 [
                    100.36912109999999,
                    51.7404105
                 ],
                 [
                    100.36129080000003,
                    51.73783330000001
                 ],
                 [
                    100.35808359999996,
                    51.737661199999984
                 ],
                 [
                    100.35584840000003,
                    51.738995
                 ],
                 [
                    100.35348680000003,
                    51.7417426
                 ],
                 [
                    100.34971150000004,
                    51.7423092
                 ],
                 [
                    100.34294250000005,
                    51.7413928
                 ],
                 [
                    100.3318939,
                    51.73822259999998
                 ],
                 [
                    100.32314240000002,
                    51.73863540000003
                 ],
                 [
                    100.31247250000004,
                    51.736819200000035
                 ],
                 [
                    100.30857819999997,
                    51.7370796
                 ],
                 [
                    100.30249660000001,
                    51.73860440000004
                 ],
                 [
                    100.29605820000003,
                    51.74283240000001
                 ],
                 [
                    100.28521420000004,
                    51.73832390000001
                 ],
                 [
                    100.28064169999998,
                    51.7375368
                 ],
                 [
                    100.274891,
                    51.7397965
                 ],
                 [
                    100.27177790000005,
                    51.7429693
                 ],
                 [
                    100.26663509999996,
                    51.74590219999999
                 ],
                 [
                    100.26428529999995,
                    51.74927560000004
                 ],
                 [
                    100.26204089999997,
                    51.75039840000004
                 ],
                 [
                    100.25341430000003,
                    51.74933980000002
                 ],
                 [
                    100.24702119999998,
                    51.746739800000014
                 ],
                 [
                    100.24108780000003,
                    51.74858119999996
                 ],
                 [
                    100.23721619999998,
                    51.748779
                 ],
                 [
                    100.217391,
                    51.7449578
                 ],
                 [
                    100.21598119999997,
                    51.74267050000003
                 ],
                 [
                    100.20097820000004,
                    51.7430212
                 ],
                 [
                    100.19814689999998,
                    51.747038899999986
                 ],
                 [
                    100.18991010000005,
                    51.7479073
                 ],
                 [
                    100.1856554,
                    51.74957219999998
                 ],
                 [
                    100.18171810000001,
                    51.7517742
                 ],
                 [
                    100.1782743,
                    51.750954300000025
                 ],
                 [
                    100.17519959999997,
                    51.74936240000003
                 ],
                 [
                    100.1607349,
                    51.75395379999996
                 ],
                 [
                    100.15771370000002,
                    51.756597
                 ],
                 [
                    100.1545884,
                    51.75751950000002
                 ],
                 [
                    100.14631180000005,
                    51.75692040000001
                 ],
                 [
                    100.13870180000002,
                    51.75934860000002
                 ],
                 [
                    100.1339116,
                    51.75784570000001
                 ],
                 [
                    100.131388,
                    51.75680340000002
                 ],
                 [
                    100.1270717,
                    51.75611249999996
                 ],
                 [
                    100.11977050000003,
                    51.75743790000002
                 ],
                 [
                    100.11487069999998,
                    51.7570136
                 ],
                 [
                    100.111837,
                    51.75505720000004
                 ],
                 [
                    100.1061747,
                    51.753886499999986
                 ],
                 [
                    100.10343079999998,
                    51.75455819999996
                 ],
                 [
                    100.09891479999997,
                    51.75072150000004
                 ],
                 [
                    100.09199409999997,
                    51.74870110000003
                 ],
                 [
                    100.086331,
                    51.7445161
                 ],
                 [
                    100.08052549999998,
                    51.74137829999996
                 ],
                 [
                    100.07500229999995,
                    51.740847099999975
                 ],
                 [
                    100.07291559999996,
                    51.74029710000003
                 ],
                 [
                    100.06542360000002,
                    51.73345969999998
                 ],
                 [
                    100.06141669999995,
                    51.73271950000002
                 ],
                 [
                    100.057321,
                    51.73285270000003
                 ],
                 [
                    100.054147,
                    51.7340322
                 ],
                 [
                    100.0497092,
                    51.73702940000002
                 ],
                 [
                    100.04014280000003,
                    51.733267599999984
                 ],
                 [
                    100.0359497,
                    51.7330745
                 ],
                 [
                    100.0330672,
                    51.73333
                 ],
                 [
                    100.02881140000002,
                    51.7351461
                 ],
                 [
                    100.0281886,
                    51.73740110000004
                 ],
                 [
                    100.023698,
                    51.74019439999997
                 ],
                 [
                    100.01753180000004,
                    51.739377300000044
                 ],
                 [
                    100.008695,
                    51.739872
                 ],
                 [
                    100.0046409,
                    51.74227059999998
                 ],
                 [
                    99.9959786,
                    51.744060899999965
                 ],
                 [
                    99.990508,
                    51.74824009999998
                 ],
                 [
                    99.98307409999997,
                    51.75133679999997
                 ],
                 [
                    99.97979760000003,
                    51.754720399999975
                 ],
                 [
                    99.97790029999999,
                    51.75373409999999
                 ],
                 [
                    99.9744301,
                    51.7491591
                 ],
                 [
                    99.96824520000004,
                    51.74371730000004
                 ],
                 [
                    99.96452350000003,
                    51.74175360000002
                 ],
                 [
                    99.95769669999997,
                    51.743019
                 ],
                 [
                    99.95086629999997,
                    51.7428129
                 ],
                 [
                    99.94194059999998,
                    51.74691089999996
                 ],
                 [
                    99.93570120000003,
                    51.747067400000034
                 ],
                 [
                    99.92940040000005,
                    51.7479967
                 ],
                 [
                    99.92754559999997,
                    51.748021500000014
                 ],
                 [
                    99.92634329999997,
                    51.74693239999996
                 ],
                 [
                    99.9239177,
                    51.743707899999976
                 ],
                 [
                    99.920952,
                    51.738761299999965
                 ],
                 [
                    99.91646239999999,
                    51.73759310000003
                 ],
                 [
                    99.91091249999998,
                    51.7381594
                 ],
                 [
                    99.9085059,
                    51.7400091
                 ],
                 [
                    99.90934030000001,
                    51.74572250000004
                 ],
                 [
                    99.90644259999998,
                    51.750592500000025
                 ],
                 [
                    99.90048010000001,
                    51.75592220000002
                 ],
                 [
                    99.898193,
                    51.7596017
                 ],
                 [
                    99.89347570000001,
                    51.75979449999996
                 ],
                 [
                    99.8837211,
                    51.7614717
                 ],
                 [
                    99.88074729999997,
                    51.764180400000036
                 ],
                 [
                    99.8781146,
                    51.771890299999974
                 ],
                 [
                    99.8769854,
                    51.7727365
                 ],
                 [
                    99.87051680000002,
                    51.77581809999998
                 ],
                 [
                    99.86918170000004,
                    51.7774565
                 ],
                 [
                    99.86933430000005,
                    51.780116600000014
                 ],
                 [
                    99.86827609999997,
                    51.78211230000002
                 ],
                 [
                    99.86318439999995,
                    51.78618950000004
                 ],
                 [
                    99.86865609999998,
                    51.7906072
                 ],
                 [
                    99.8713733,
                    51.79401710000004
                 ],
                 [
                    99.86888879999997,
                    51.796442
                 ],
                 [
                    99.8633674,
                    51.805183200000045
                 ],
                 [
                    99.8575203,
                    51.806464900000044
                 ],
                 [
                    99.8451992,
                    51.806449500000014
                 ],
                 [
                    99.835811,
                    51.8053135
                 ],
                 [
                    99.83017880000003,
                    51.806016399999976
                 ],
                 [
                    99.83919729999998,
                    51.813921
                 ],
                 [
                    99.8393027,
                    51.81611749999997
                 ],
                 [
                    99.84538110000004,
                    51.82067749999997
                 ],
                 [
                    99.8458067,
                    51.8298817
                 ],
                 [
                    99.8477593,
                    51.83494210000001
                 ],
                 [
                    99.8465002,
                    51.8355162
                 ],
                 [
                    99.84355449999998,
                    51.83586159999997
                 ],
                 [
                    99.83569730000002,
                    51.83565630000004
                 ],
                 [
                    99.83133180000004,
                    51.837047200000015
                 ],
                 [
                    99.8267444,
                    51.84016619999998
                 ],
                 [
                    99.81954730000002,
                    51.84774090000001
                 ],
                 [
                    99.82397139999998,
                    51.850177299999984
                 ],
                 [
                    99.8279027,
                    51.8572644
                 ],
                 [
                    99.82857080000004,
                    51.86394849999998
                 ],
                 [
                    99.82692119999997,
                    51.87072609999996
                 ],
                 [
                    99.8241414,
                    51.873351
                 ],
                 [
                    99.82343330000003,
                    51.87623319999999
                 ],
                 [
                    99.82447790000005,
                    51.878443199999964
                 ],
                 [
                    99.8273208,
                    51.8808094
                 ],
                 [
                    99.82795570000003,
                    51.883159099999986
                 ],
                 [
                    99.82519990000002,
                    51.88500949999997
                 ],
                 [
                    99.82247179999997,
                    51.88837650000002
                 ],
                 [
                    99.8178444,
                    51.889612
                 ],
                 [
                    99.81629690000003,
                    51.89286619999997
                 ],
                 [
                    99.81713010000004,
                    51.89847690000004
                 ],
                 [
                    99.812809,
                    51.90105109999996
                 ],
                 [
                    99.81048970000005,
                    51.9040765
                 ],
                 [
                    99.80834609999997,
                    51.9093602
                 ],
                 [
                    99.80462259999999,
                    51.913659500000016
                 ],
                 [
                    99.80118260000002,
                    51.915384500000016
                 ],
                 [
                    99.79794950000003,
                    51.915862200000014
                 ],
                 [
                    99.7952226,
                    51.915002
                 ],
                 [
                    99.78446490000005,
                    51.914367200000044
                 ],
                 [
                    99.7742784,
                    51.912733699999976
                 ],
                 [
                    99.76853609999996,
                    51.91296349999998
                 ],
                 [
                    99.75915170000003,
                    51.91078749999998
                 ],
                 [
                    99.7489664,
                    51.9080162
                 ],
                 [
                    99.74397539999995,
                    51.90302290000004
                 ],
                 [
                    99.73574319999997,
                    51.90358159999999
                 ],
                 [
                    99.7292428,
                    51.90429089999997
                 ],
                 [
                    99.7175818,
                    51.9042762
                 ],
                 [
                    99.70221949999998,
                    51.9002937
                 ],
                 [
                    99.695417,
                    51.90003960000002
                 ],
                 [
                    99.690354,
                    51.89700390000002
                 ],
                 [
                    99.68761020000002,
                    51.89159
                 ],
                 [
                    99.6859415,
                    51.88873769999997
                 ],
                 [
                    99.68258929999999,
                    51.88752620000001
                 ],
                 [
                    99.68049890000005,
                    51.88861979999999
                 ],
                 [
                    99.6755915,
                    51.89363870000003
                 ],
                 [
                    99.67103210000005,
                    51.8969714
                 ],
                 [
                    99.6699415,
                    51.900823400000036
                 ],
                 [
                    99.66760179999999,
                    51.9044147
                 ],
                 [
                    99.66497179999999,
                    51.904392700000045
                 ],
                 [
                    99.6526682,
                    51.89702340000004
                 ],
                 [
                    99.63543879999997,
                    51.893453499999985
                 ],
                 [
                    99.628978,
                    51.8936975
                 ],
                 [
                    99.625723,
                    51.8926936
                 ],
                 [
                    99.6225234,
                    51.889055599999985
                 ],
                 [
                    99.6179204,
                    51.88584540000003
                 ],
                 [
                    99.6172592,
                    51.88240280000002
                 ],
                 [
                    99.615944,
                    51.8802137
                 ],
                 [
                    99.61384710000004,
                    51.87910779999998
                 ],
                 [
                    99.61271220000002,
                    51.8850035
                 ],
                 [
                    99.6128248,
                    51.889454300000025
                 ],
                 [
                    99.60895039999998,
                    51.89641839999999
                 ],
                 [
                    99.60085219999998,
                    51.90222430000004
                 ],
                 [
                    99.59984830000005,
                    51.904317699999964
                 ],
                 [
                    99.6008376,
                    51.91018969999998
                 ],
                 [
                    99.59693930000005,
                    51.91534869999998
                 ],
                 [
                    99.59601290000005,
                    51.919065
                 ],
                 [
                    99.59806339999997,
                    51.921326100000016
                 ],
                 [
                    99.60258049999997,
                    51.921943
                 ],
                 [
                    99.600979,
                    51.92418820000004
                 ],
                 [
                    99.59664730000004,
                    51.925045
                 ],
                 [
                    99.59366180000004,
                    51.92593220000001
                 ],
                 [
                    99.59064030000005,
                    51.92863730000001
                 ],
                 [
                    99.58686270000004,
                    51.92979370000003
                 ],
                 [
                    99.58111249999997,
                    51.92990019999996
                 ],
                 [
                    99.57302619999997,
                    51.9315204
                 ],
                 [
                    99.56892139999998,
                    51.933372600000034
                 ],
                 [
                    99.56558130000002,
                    51.93528340000004
                 ],
                 [
                    99.559092,
                    51.94132550000001
                 ],
                 [
                    99.54721790000004,
                    51.94908590000004
                 ],
                 [
                    99.54003540000004,
                    51.9522649
                 ],
                 [
                    99.53853150000003,
                    51.95435520000002
                 ],
                 [
                    99.53916810000004,
                    51.956245600000024
                 ],
                 [
                    99.54335819999999,
                    51.95834550000004
                 ],
                 [
                    99.5468654,
                    51.959989
                 ],
                 [
                    99.5467375,
                    51.96007680000003
                 ],
                 [
                    99.5454128,
                    51.960986
                 ],
                 [
                    99.53870349999997,
                    51.96302650000002
                 ],
                 [
                    99.53264939999998,
                    51.96366210000003
                 ],
                 [
                    99.53015980000005,
                    51.96294319999996
                 ],
                 [
                    99.5219871,
                    51.9679932
                 ],
                 [
                    99.51991079999998,
                    51.96845759999997
                 ],
                 [
                    99.51880260000004,
                    51.9679706
                 ],
                 [
                    99.51799010000005,
                    51.966649
                 ],
                 [
                    99.514138,
                    51.962123
                 ],
                 [
                    99.50970290000004,
                    51.9599475
                 ],
                 [
                    99.5051028,
                    51.9592874
                 ],
                 [
                    99.502033,
                    51.9585822
                 ],
                 [
                    99.49956640000005,
                    51.95711669999998
                 ],
                 [
                    99.49955120000003,
                    51.95582920000003
                 ],
                 [
                    99.50094350000003,
                    51.95449739999997
                 ],
                 [
                    99.50062379999996,
                    51.95336259999996
                 ],
                 [
                    99.49833370000002,
                    51.95136340000001
                 ],
                 [
                    99.4906058,
                    51.953895299999985
                 ],
                 [
                    99.4859427,
                    51.9543765
                 ],
                 [
                    99.48001679999996,
                    51.955463299999955
                 ],
                 [
                    99.47750379999997,
                    51.9552095
                 ],
                 [
                    99.47620389999996,
                    51.95396550000002
                 ],
                 [
                    99.47464480000002,
                    51.9514351
                 ],
                 [
                    99.47045950000002,
                    51.94944640000003
                 ],
                 [
                    99.46588960000001,
                    51.948871
                 ],
                 [
                    99.46261859999998,
                    51.9489502
                 ],
                 [
                    99.4571291,
                    51.950753700000014
                 ],
                 [
                    99.44887580000004,
                    51.95177309999998
                 ],
                 [
                    99.44265449999999,
                    51.95391969999996
                 ],
                 [
                    99.43949,
                    51.95571259999998
                 ],
                 [
                    99.43741240000004,
                    51.95609970000003
                 ],
                 [
                    99.43153339999998,
                    51.955934300000024
                 ],
                 [
                    99.424441,
                    51.956871500000034
                 ],
                 [
                    99.41928849999998,
                    51.95590740000002
                 ],
                 [
                    99.4141233,
                    51.95365540000003
                 ],
                 [
                    99.4113631,
                    51.953136
                 ],
                 [
                    99.409044,
                    51.9538644
                 ],
                 [
                    99.40544590000003,
                    51.961527900000014
                 ],
                 [
                    99.40303429999997,
                    51.9653997
                 ],
                 [
                    99.40101839999998,
                    51.96589959999997
                 ],
                 [
                    99.394515,
                    51.964598599999974
                 ],
                 [
                    99.38954790000001,
                    51.963859700000036
                 ],
                 [
                    99.39258610000005,
                    51.961387
                 ],
                 [
                    99.39304850000002,
                    51.958507200000014
                 ],
                 [
                    99.39155709999999,
                    51.95620260000002
                 ],
                 [
                    99.38852449999997,
                    51.9549846
                 ],
                 [
                    99.38560239999997,
                    51.954482399999975
                 ],
                 [
                    99.38253979999996,
                    51.9543044
                 ],
                 [
                    99.37189129999997,
                    51.95475890000003
                 ],
                 [
                    99.36794209999998,
                    51.95383660000001
                 ],
                 [
                    99.36910879999996,
                    51.9407371
                 ],
                 [
                    99.3649033,
                    51.940014399999974
                 ],
                 [
                    99.3601963,
                    51.940636500000025
                 ],
                 [
                    99.35378520000005,
                    51.94240040000004
                 ],
                 [
                    99.35025870000003,
                    51.945100900000014
                 ],
                 [
                    99.3486397,
                    51.94904480000002
                 ],
                 [
                    99.34737249999996,
                    51.95135280000002
                 ],
                 [
                    99.343174,
                    51.9542131
                 ],
                 [
                    99.33639979999998,
                    51.95681020000002
                 ],
                 [
                    99.3332193,
                    51.9571992
                 ],
                 [
                    99.32763460000002,
                    51.9555508
                 ],
                 [
                    99.32371770000005,
                    51.95571449999996
                 ],
                 [
                    99.31920260000003,
                    51.95766
                 ],
                 [
                    99.31535059999999,
                    51.95831540000004
                 ],
                 [
                    99.3089744,
                    51.95719870000001
                 ],
                 [
                    99.30554830000003,
                    51.957512
                 ],
                 [
                    99.30053960000004,
                    51.95911720000004
                 ],
                 [
                    99.2951054,
                    51.96114
                 ],
                 [
                    99.28998540000003,
                    51.9643735
                 ],
                 [
                    99.2851653,
                    51.96681079999996
                 ],
                 [
                    99.27649230000004,
                    51.97009149999998
                 ],
                 [
                    99.27131619999996,
                    51.9686856
                 ],
                 [
                    99.2692051,
                    51.97018679999996
                 ],
                 [
                    99.265308,
                    51.973529599999964
                 ],
                 [
                    99.2631228,
                    51.976489
                 ],
                 [
                    99.26374790000001,
                    51.9783809
                 ],
                 [
                    99.26689,
                    51.981099200000024
                 ],
                 [
                    99.2697774,
                    51.9823033
                 ],
                 [
                    99.2767084,
                    51.9835722
                 ],
                 [
                    99.28193230000002,
                    51.98594370000002
                 ],
                 [
                    99.2826125,
                    51.98685060000001
                 ],
                 [
                    99.27260380000001,
                    51.992482599999974
                 ],
                 [
                    99.26912959999997,
                    51.99518060000001
                 ],
                 [
                    99.26859240000002,
                    51.9973027
                 ],
                 [
                    99.26981449999997,
                    51.99870629999996
                 ],
                 [
                    99.2727133,
                    51.9995259
                 ],
                 [
                    99.27486570000002,
                    52.00061829999998
                 ],
                 [
                    99.27556670000003,
                    52.003103099999954
                 ],
                 [
                    99.2751597,
                    52.007661099999964
                 ],
                 [
                    99.2759689,
                    52.0094767
                 ],
                 [
                    99.28046639999997,
                    52.012721
                 ],
                 [
                    99.28096820000002,
                    52.014348
                 ],
                 [
                    99.2794512,
                    52.0165485
                 ],
                 [
                    99.27609370000002,
                    52.01860260000004
                 ],
                 [
                    99.26923210000001,
                    52.0193406
                 ],
                 [
                    99.26504389999995,
                    52.02075619999997
                 ],
                 [
                    99.26053029999997,
                    52.019969299999964
                 ],
                 [
                    99.25545179999997,
                    52.01962420000001
                 ],
                 [
                    99.2563901,
                    52.0149813
                 ],
                 [
                    99.25443189999997,
                    52.01163040000003
                 ],
                 [
                    99.24760350000004,
                    52.0117846
                 ],
                 [
                    99.23657489999995,
                    52.0126444
                 ],
                 [
                    99.23394920000004,
                    52.01435480000003
                 ],
                 [
                    99.22993210000003,
                    52.019211300000045
                 ],
                 [
                    99.2271924,
                    52.02251229999997
                 ],
                 [
                    99.22283379999998,
                    52.02562490000002
                 ],
                 [
                    99.21790110000005,
                    52.02756990000001
                 ],
                 [
                    99.21348920000004,
                    52.0281097
                 ],
                 [
                    99.20606690000001,
                    52.027671299999966
                 ],
                 [
                    99.2020777,
                    52.027036
                 ],
                 [
                    99.19528440000003,
                    52.02958710000003
                 ],
                 [
                    99.19224359999997,
                    52.031579
                 ],
                 [
                    99.187814,
                    52.031798500000036
                 ],
                 [
                    99.18236029999997,
                    52.03271789999999
                 ],
                 [
                    99.17360529999998,
                    52.0355885
                 ],
                 [
                    99.1711494,
                    52.035314100000036
                 ],
                 [
                    99.169245,
                    52.03463589999997
                 ],
                 [
                    99.16494020000005,
                    52.03203059999996
                 ],
                 [
                    99.16163380000003,
                    52.02861330000001
                 ],
                 [
                    99.1568919,
                    52.028636499999976
                 ],
                 [
                    99.15548590000002,
                    52.029661400000045
                 ],
                 [
                    99.15585860000003,
                    52.03075879999999
                 ],
                 [
                    99.15353360000003,
                    52.0320503
                 ],
                 [
                    99.14861949999997,
                    52.03299830000001
                 ],
                 [
                    99.14550229999998,
                    52.032669399999975
                 ],
                 [
                    99.13941359999997,
                    52.028210399999985
                 ],
                 [
                    99.13252690000002,
                    52.0241551
                 ],
                 [
                    99.128117,
                    52.0245544
                 ],
                 [
                    99.1207102,
                    52.0281247
                 ],
                 [
                    99.11489819999997,
                    52.0321845
                 ],
                 [
                    99.112761,
                    52.0349518
                 ],
                 [
                    99.11118370000003,
                    52.04025559999998
                 ],
                 [
                    99.10999239999997,
                    52.04211430000003
                 ],
                 [
                    99.10739059999999,
                    52.04332779999998
                 ],
                 [
                    99.10525009999998,
                    52.0454511
                 ],
                 [
                    99.10379029999999,
                    52.0494292
                 ],
                 [
                    99.09962,
                    52.0500023
                 ],
                 [
                    99.0944644,
                    52.04925109999998
                 ],
                 [
                    99.08924850000001,
                    52.0488027
                 ],
                 [
                    99.08538750000002,
                    52.05032190000003
                 ],
                 [
                    99.08275640000002,
                    52.05301320000004
                 ],
                 [
                    99.08307110000004,
                    52.056004700000024
                 ],
                 [
                    99.0841871,
                    52.06028250000003
                 ],
                 [
                    99.08303010000002,
                    52.063729799999976
                 ],
                 [
                    99.07873320000003,
                    52.06327989999997
                 ],
                 [
                    99.07468549999996,
                    52.064420099999985
                 ],
                 [
                    99.07303310000002,
                    52.06631510000001
                 ],
                 [
                    99.07169050000005,
                    52.06949749999998
                 ],
                 [
                    99.06733359999998,
                    52.06988030000004
                 ],
                 [
                    99.0624224,
                    52.06943050000003
                 ],
                 [
                    99.0577297,
                    52.068014500000025
                 ],
                 [
                    99.0463392,
                    52.06819419999997
                 ],
                 [
                    99.0426831,
                    52.06783840000002
                 ],
                 [
                    99.03903260000003,
                    52.066495500000016
                 ],
                 [
                    99.0355961,
                    52.0668387
                 ],
                 [
                    99.03019529999997,
                    52.06695590000001
                 ],
                 [
                    99.02546779999997,
                    52.065671300000034
                 ],
                 [
                    99.02209589999995,
                    52.06847539999997
                 ],
                 [
                    99.0193388,
                    52.072490900000034
                 ],
                 [
                    99.01553409999997,
                    52.0731368
                 ],
                 [
                    99.009518,
                    52.072798800000015
                 ],
                 [
                    99.00460930000004,
                    52.074732300000036
                 ]
              ]
           ]
        },
        "properties":{
           "shapeName":"Mongolia",
           "Level":"ADM0",
           "shapeISO":"MNG",
           "shapeID":"MNG-ADM0-74336971B11002168",
           "shapeGroup":"MNG",
           "shapeType":"ADM0"
        }
     }
  ]
}