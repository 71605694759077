export default () => {
  return [
    {
      label: "Компаний нэр",
      name: "name",
      rules: [{ required: true, message: "Компаний нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-4 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Уриа",
      name: "slogan",
      rules: [{ required: true, message: "Уриа оруулна уу!" }],
      className: "col-span-12 md:col-span-4 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "И-Мэйл хаяг",
      name: "email",
      rules: [{ required: true, message: "И-Мэйл хаяг оруулна уу!" }],
      className: "col-span-12 md:col-span-4 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Утасны дугаар",
      name: "mobile",
      // type: 'number',
      rules: [{ required: true, message: "Утасны дугаар оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Вэб сайт",
      name: "website",
      // rules: [{required: true, message: 'Илчлэг оруулна уу!'}],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хаяг байршил",
      name: "address",
      type: "textarea",
      // rules: [{required: true, message: 'Нүүрс ус оруулна уу!'}],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Компаний тайлбр",
      name: "descr",
      type: "textarea",
      // rules: [{required: true, message: 'Өөх тос оруулна уу!'}],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Лого",
      name: "image",
      type: "file",
      rules: [{ required: true, message: "Лого оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
