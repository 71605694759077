import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react'

function TinyEditor({form, name, height, width, editData, ...restProps}) {
  const editorRef = useRef()

  return (
    <Editor
      // {...restProps}
      onInit={(e, editor) => editorRef.current = editor}
      apiKey='a22ry67l2lvwvfa9lvrr2yeeih0vpyw7brx1cc0g2lbm41rx'
      onChange={() => {}}
      init={{
        selector: "#editor",
        height: height ? height : '400px',
        width: width ? width :'100%',
        branding: false,
        menubar: false,
        toolbar: 'undo redo | fontselect | formatselect | fontsizeselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | lineheight | bullist numlist outdent indent | removeformat | image | media',
        content_style: "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); body { font-family:Roboto; font-size:14px }",
        plugins: [
          'advlist autolink lists link image',
          'charmap print preview anchor help',
          'searchreplace visualblocks code','lineheight textcolor',
          'insertdatetime media table paste wordcount twitter'
        ],
        block_formats: 'Paragraph=p; Header 1=h2; Header 2=h3; Twitter=customtwitter',
        fontsize_formats: "8pt 10pt 12pt 14pt 16px 18pt 24pt 36pt 40px",
        font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Roboto=roboto, sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; ",
        // formats: {
        //   customtwitter: { inline: 'span', styles: {backgroundColor: '#e9ebe4'}, classes: 'shareable', id: 'ww' }
        // },
        // style_formats: [
        //   { title: 'My PDF file', selector: 'a', classes: 'mypdffile' },
        //   { title: 'Twitter format', format: 'customtwitter'}
        // ],
        // file_picker_callback: function (callback, value, meta) {
        //   var input = document.createElement('input');
        //   input.setAttribute('type', 'file');
        //   input.setAttribute('accept', 'image/*');
        //   input.onchange = function () {
        //     var file = this.files[0];
        //     var reader = new FileReader();
        //     reader.onload = function (e) {
        //       callback(e.target.result, {
        //         alt: file.name
        //     });

        //     };
        //     reader.readAsDataURL(file);
        //   };
        //   input.click();
        // },
      }}
      onEditorChange={(e, editor) => {
        form.setFieldValue(name, e)
      }}
    />
  )
}

export default TinyEditor