import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form as AntForm, DatePicker, Select } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import moment from "moment";
import columns from "./columns";
import { AuthContext } from "contexts";
function Inpatient() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [status, setStatus] = useState("Хэвтсэн");
  const [wards, setWards] = useState([]);
  const [roomBeds, setRoomBeds] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    action.handleSidebarKey("inpatient");
    instance({
      method: "get",
      url: `/wards`,
    })
      .then((res) => {
        setWards(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/rooms`,
    })
      .then((res) => {
        setRooms(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.room_number,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/room_beds`,
    })
      .then((res) => {
        setRoomBeds(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.bed_number,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/systemusers?role=Хэвтэн&status=Идэвхитэй&hospital=2`,
    })
      .then((res) => {
        setDoctors(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.lastname.charAt(0)}. ${item.firstname}`,
            text: `${item.lastname.charAt(0)}. ${item.firstname}`,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [date, status]);
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/inpatients?status=${status}&date=${date}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const handleSubmit = (values) => {
    setLoading(true);

    if (!editData.isAdd) {
      values.inpatientData.id = editData.inpatientData.id;
      instance({
        method: "put",
        url: "/inpatient",
        data: values,
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/inpatient",
        data: values,
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData({
      customerData: null,
      inpatientData: null,
      isAdd: true,
    });
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData({
      customerData: row.customer,
      inpatientData: row,
      isAdd: false,
    });
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleCancel = (values) => {
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Мэдээллийг цуцлахдаа итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id

          instance({
            method: "delete",
            url: `/inpatient/cancel/${values.id}`,
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const handleSetDoctor = (value, row) => {
    var data = {
      id: row.id,
      doctorId: value ?? null,
    };

    instance({
      method: "put",
      url: `/inpatient/setdoctor`,
      data: data,
    })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        if (err.response.status == 403) {
          modal.warning({
            title: "Анхаарна уу !",
            content: "Хэрэглэгчийн эрх хүрэхгүй байна",
          });
        }
      });
  };
  const handleDischarge = (row) => {
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Үйлдэл хийхдээ итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: "put",
            url: `/inpatient/discharge/${row.id}`,
          })
            .then(() => {
              resolve();
              fetchData();
            })
            .catch((err) => {
              reject();
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
            });
        });
      },
      onCancel() {},
    });
  };
  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <DatePicker picker="month" value={date} onChange={(e) => setDate(e)} />
        <div>
          <Select
            className="mb-0 w-[100px]"
            value={status}
            options={[
              {
                value: "Бүгд",
                label: "Бүгд",
              },
              {
                value: "Гарсан",
                label: "Гарсан",
              },
              {
                value: "Хэвтсэн",
                label: "Хэвтсэн",
              },
            ]}
            onChange={(e) => {
              setStatus(e);
            }}
          />
        </div>
      </div>
      <MainTable
        dataTable={data}
        columns={columns({
          handleEdit,
          doctors,
          handleCancel,
          handleSetDoctor,
          handleDischarge,
        })}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Хэвтэн эмчлүүлэх"}
        handleAdd={handleAdd}
        handleRefresh={fetchData}
      />
      <Modal
        title={
          editData
            ? "Үйлчлүүлэгчийн мэдээлэл засах"
            : "Үйлчлүүлэгчийн мэдээлэл нэмэх"
        }
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({
            wards,
            rooms,
            roomBeds,
            handleEdit,
            form,
            currentPage,
            doctors,
          })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
      {contextHolder}
    </div>
  );
}

export default Inpatient;
