import React, { useEffect, useState } from 'react';
import { Button, Modal, Tag, Form as AntForm } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import { Link } from 'react-router-dom';
import moment from 'moment';

function Income({incomeType, startDate, endDate}) {
  const [ data, setData ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ open, setOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ form ] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  },[currentPage])

  const fetchData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `/transactionincome?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
    }).then(() => setLoading(false))
  }

  const columns = [
    {
      title: 'Төрөл',
      dataIndex: 'incometype',
      key: 'incometype',
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: 'Дүн',
      dataIndex: 'amount',
      key: 'amount',
      render:(text, row) => (
        <div>{Intl.NumberFormat().format(text)} ₮</div>
      )
    },
    {
      title: 'Тайлбар',
      dataIndex: 'descr',
      key: 'descr',
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: 'Огноо',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: 'Бүртгэсэн ажилтан',
      dataIndex: 'username',
      key: 'username',
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Button size='small' onClick={() => handleEdit(row)}>
          <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
        </Button>
      )
    },
  ]

  const handleSubmit = (values) => {
    setLoading(true)
    if(editData){
      instance({
        method: 'put',
        url: '/transactionincome',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {
        
      }).then(() => setLoading(false))
    }
    else{
      instance({
        method: 'post',
        url: '/transactionincome',
        data: {
          ...values
        }
      }).then((res) => {
        handleCloseModal()
        fetchData()
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  }

  const handleAdd = () => {
    setIsEdit(true)
    setEditData(null)
    setOpen(true)
  }

  const handleEdit = (row) => {
    setEditData(row)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    form.resetFields()
  }


  const fields = [
    {
      label: 'Тайлбар',
      name: 'descr',
      className: 'col-span-12 md:col-span-12 mb-0',
      type: 'textarea',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Дүн',
      name: 'amount',
      rules: [{required: true, message: 'Дүн оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      type: 'number',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Зарлагын төрөл',
      name: 'incomeId',
      rules: [{required: true, message: 'Зарлагын төрөл оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      type: 'select',
      inputProps: {
        className: 'w-full',
        options: incomeType
      }
    },
  ]

  return (
      <div>
        <MainTable
          dataTable={data} 
          columns={columns} 
          setCurrentPage={setCurrentPage} 
          loading={loading} 
          pagination={true}
          // title={"Зарлагын төрөл"}
          handleAdd={handleAdd}
        />
        <Modal 
          title={editData ? 'Орлого засах' : 'Орлого нэмэх'} 
          open={open} 
          destroyOnClose 
          width={600}
          okText='Хадгалах'
          confirmLoading={loading}
          onOk={form.submit}
          onCancel={loading ? null : handleCloseModal}
          cancelButtonProps={{disabled: loading}}
        >
          <Form
            form={form}
            fields={fields}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
      </div>
  )
}

export default Income