import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Form as AntForm,
  Table,
  Tooltip,
  Tag,
  Modal,
  Select,
} from "antd";
import { SearchOutlined, ClearOutlined, EditOutlined, } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import { CompanySelect } from "components";
import { Link } from "react-router-dom";

import { AuthContext } from "contexts";

function Employee() {
  const authContext = useContext(AuthContext);
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const hospitalSecondId = authContext.state.userInfo.profile.hospitalSecondId;
    const ownRole =
      authContext.state.userInfo.profile.role;
  const [hospitals, setHospitals] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterForm] = AntForm.useForm();
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState(ownRole);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
   const [selectedHostpital, setSelectedHospital] = useState(hospitalId);

  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [role,selectedHostpital]);

  useEffect(() => {
    fetchRoles();
    getHospitals();
  }, []);
  const getHospitals = () => {
    instance({
      method: "get",
      url: `/hospitals`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          if (item.id == hospitalId || item.id == hospitalSecondId)
            tmp.push({
              label: item.name,
              value: item.id,
            });
        });
        setHospitals(tmp);
      })
      .catch((err) => {});
  };
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/systemusers?pageindex=${currentPage}&hospital=${selectedHostpital}&role=${role}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const fetchRoles = () => {
    instance({
      method: "get",
      url: `/systemusers/role`,
    })
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {});
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Овог Нэр",
      dataIndex: "lastname",
      key: "lastname",
      render: (text, row) => (
        <Link
          className="text-link hover:underline hover:text-link"
          to={`${row.id}`}
        >
          {text} {row.firstname}
        </Link>
      ),
    },
    {
      title: "Үндсэн Эмнэлэг",
      dataIndex: "hospitalName",
      key: "hospitalName",
    },
    {
      title: "2 дахь эмнэлэг",
      dataIndex: "hospitalSecondName",
      key: "hospitalSecondName",
    },
    {
      title: "Утасны дугаар",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "И-Мэйл хаяг",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Албан тушаал",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Системийн эрх",
      dataIndex: "role",
      key: "role",
      filters: roles.map((item) => ({ value: item, label: item, text: item })),
      onFilter: (value, record) => {
        return record.role == value;
      },
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <Tag color={text === "Идэвхитэй" ? "success" : "default"}>{text}</Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const fields = [
    {
      label: "Овог",
      name: "lastname",
      rules: [{ required: true, message: "Овог оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэр",
      name: "firstname",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Эмнэлэг",
      name: "hospitalId",
      type: "select",
      rules: [{ required: true, message: "Эмнэлэг сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: hospitals,
      },
    },
    {
      label: "Шинжилгээний бичих 2 дахь эмнэлэг",
      name: "hospitalSecondId",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: hospitals,
      },
    },
    {
      label: "Төрөл",
      name: "workType",
      type: "select",
      rules: [{ required: true, message: "Эмнэлэг сонгоно уу!" }],
      className: "col-span-12 md:col-span-4 mb-0",
      inputProps: {
        className: "w-full",
        options: [
          { value: "Үндсэн", label: "Үндсэн" },
          { value: "Гэрээт", label: "Гэрээт" },
        ],
      },
    },
    {
      label: "Албан тушаал",
      name: "position",
      rules: [{ required: true, message: "Албан тушаал оруулна уу!" }],
      className: "col-span-12 md:col-span-4 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Системийн эрх",
      name: "role",
      type: "select",
      rules: [{ required: true, message: "Системийн эрх оруулна уу!" }],
      className: "col-span-12 md:col-span-4 mb-0",
      inputProps: {
        className: "w-full",
        options: roles.map((item) => ({ value: item, label: item })),
      },
    },
    {
      label: "MRI эмч нарын нэр өөрчлөх эрх",
      name: "mriSetPermission",
      type: "checkbox",
      className: "col-span-12 md:col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Утасны дугаар",
      name: "mobile",
      rules: [{ required: true, message: "Утасны дугаар оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "И-Майл хаяг",
      name: "email",
      rules: [{ required: true, message: "И-Майл хаяг оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нууц үг",
      name: "password",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төлөв",
      name: "status",
      type: "select",
      rules: [{ required: true, message: "Төлөв cонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Идэвхитэй", value: "Идэвхитэй" },
          { label: "Идэвхигүй", value: "Идэвхигүй" },
        ],
      },
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: `/systemusers`,
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
 
      instance({
        method: "post",
        url: `/systemusers`,
        data: values,
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <CompanySelect
          // className="mb-0 w-[150px]"
          value={selectedHostpital}
          onChange={(e) => {
            setSelectedHospital(e);
          }}
        />
        <div>
          <Select
            className="mb-0 w-[100px]"
            value={role}
            options={roles.map((item) => ({
              value: item,
              label: item,
              text: item,
            }))}
            onChange={(e) => {
              setRole(e);
            }}
          />
        </div>
      </div>
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Ажилчид"}
          handleAdd={handleAdd}
          handleRefresh={fetchData}
        />
      </div>
      <Modal
        title={editData ? "Ажилтны мэдээлэл засах" : "Шинэ ажилтан"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          // isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Employee;
