export default ({ expenseTypes }) => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Зарлага оруулна уу!" }],
      className: "col-span-12 md:col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төрөл",
      name: "typeId",
      type: "select",
      rules: [{ required: true, message: "Төрөл сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: expenseTypes,
      },
    },
  ];
};
