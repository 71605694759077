import React, { useEffect, useState, useContext, useModal } from "react";
import { Button, Modal, Tag, Form as AntForm, Select } from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, MainTable, CompanySelect } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts";
function Devices() {
  const authContext = useContext(AuthContext);
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const secondHospitalId = authContext.state.userInfo.profile.hospitalSecondId;
  const [selectedHostpital, setSelectedHospital] = useState(hospitalId);
  const [data, setData] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceType, setServiceType] = useState(1);

  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = AntForm.useForm();
  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    // fetchData();
    getServiceTypes();
    getHospitals();
  }, [currentPage]);
  useEffect(() => {
    fetchData();
    hospitals.find((e) => e.value == selectedHostpital);
  }, [selectedHostpital, serviceType]);
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/devices?hospital=${selectedHostpital}&servicetypeid=${serviceType}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const getServiceTypes = () => {
    instance({
      method: "get",
      url: `/servicetype?active=1`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          tmp.push({
            label: item.name,
            value: item.id,
          });
        });
        setServiceTypes(tmp);
      })
      .catch((err) => {});
  };

  const getHospitals = () => {
    instance({
      method: "get",
      url: `/hospitals`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          console.log(secondHospitalId);
          if (item.id == hospitalId || secondHospitalId != null)
            tmp.push({
              label: item.name,
              value: item.id,
            });
        });
        setHospitals(tmp);
      })
      .catch((err) => {});
  };
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },

    {
      title: "Эмнэлэг",
      dataIndex: "hospitalName",
      key: "hospitalName",
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: "Аппарат",
      dataIndex: "deviceName",
      key: "deviceName",
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: "Төхөөрөмж",
      dataIndex: "serviceTypeName",
      key: "serviceTypeName",
      // render:(text, row) => (
      //   <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
      // )
    },
    {
      title: "Дэлгэрэнгүй мэдээлэл",
      dataIndex: "note",
      key: "note",
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    
    if (editData.id) {
      instance({
        method: "put",
        url: "/device",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/device",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData({
      id: null,
      serviceTypeId: serviceType,
      hospitalId: selectedHostpital,
    });
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="flex gap-5 mb-3 ">
        <div>
          <CompanySelect
            // className="mb-0 w-[150px]"
            value={selectedHostpital}
            onChange={(e) => {
              setSelectedHospital(e);
            }}
          />
        </div>
        <div>
          <Select
            className="mb-0 w-[100px]"
            value={serviceType}
            options={serviceTypes}
            onChange={(e) => {
              setServiceType(e);
            }}
          />
        </div>
      </div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Аппарат"}
        handleAdd={handleAdd}
      />
      <Modal
        title={editData ? "Аппаратний мэдээлэл засах" : "Шинэ Аппарат"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({ serviceTypes, hospitals })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Devices;
